import { rightMenuClsPrefix } from '../../constants/index';
import Loadable from "../../react-loadable";
import { show, ContextMenu } from './contextMenu';
import { RightMenuType } from "./types";

const RightMenu = Loadable({
  name: 'RightMenu',
  loader: () =>
    import(
      /* webpackChunkName: 'ui_common' */
      "./RightMenu"
    ),
}) as RightMenuType;

RightMenu.defaultProps = {
  prefixCls: rightMenuClsPrefix,
  isScroll: true,
  scrollNum: 10,
  isUnique: true,
  bindTriggerContainer: false,
  enable: true,
  showSensitive: true,
};

RightMenu.ContextMenu = ContextMenu
RightMenu.show = show
export type { RightMenuProps } from "./types";

export default RightMenu;

export { EVENT_SET_RIGHTMENU_VISIBLE }  from './constants'
