import { ComponentType } from 'react';
import Loadable from '../../react-loadable';
import { UploadType, SensitiveWordsDialogProps, BatchSecretValidityDialogProps, SecretSelectDialogProps, FilePreviewOptions, UploadRequestOption } from './types';
import { defaultProps } from './defaultProps';
import * as uploadTools from './utils/tools';
import { uploadMiddleware } from './utils/middleware';
import { download } from './utils/bath';
import getAttachmentLocalSecurityInfo from './utils/getLocalSecurityInfo';
import { checkFileTypeBySystem } from './utils/systemUploadCheck';
import { AnyObj } from '../../types/common';

const Upload = Loadable({
  name: 'Upload',
  propsMiddleware: uploadMiddleware,
  loader: () => import(
    /* webpackChunkName: "upload" */
    './Upload')
}) as UploadType;

Upload.defaultProps = defaultProps;

const FileList = Loadable({
  name: 'FileList',
  propsMiddleware: uploadMiddleware,
  loader: () => import(
    /* webpackChunkName: "upload" */
    './FileList')
}) as UploadType;

const SensitiveWordsDialog = Loadable({
  name: 'SensitiveWordsDialog',
  loader: () => import(
    /* webpackChunkName: "upload" */
    './components/pc/SensitiveWordsDialog')
}) as ComponentType<SensitiveWordsDialogProps>;

export const BatchSecretValidityDialog = Loadable({
  name: 'BatchSecretValidityDialog', loader: () => import(
    /* webpackChunkName: "upload" */
    './components/pc/BatchSecretValidityDialog')
}) as ComponentType<BatchSecretValidityDialogProps>;

export const SecretSelectDialog = Loadable({
  name: 'UploadSecretSelectDialog', loader: () => import(
    /* webpackChunkName: "upload" */
    './components/pc/SecretSelectDialog')
}) as ComponentType<SecretSelectDialogProps>;

const FilePreview = (option: FilePreviewOptions) => import(
  /* webpackChunkName: "upload" */
  './FilePreview'
).then((defaultModule) => defaultModule?.default?.(option));

const doLocalSecurityValidate = (files: any[], secretLevel: string, options?: {
  requestHeaderParams?: AnyObj;
}) => import(
  /* webpackChunkName: "upload" */
  './utils/doLocalSecurityValidate'
).then((defaultModule) => defaultModule?.default?.(files, secretLevel, options));

const upload = (props: UploadRequestOption, options?: { getCancelFn?: any, fetchUpload?: any }) => import(
  /* webpackChunkName: "upload" */
  './utils/request'
).then((defaultModule) => defaultModule?.default?.(props, options));

const uploadFile = (props: UploadRequestOption) => import(
  /* webpackChunkName: "upload" */
  './utils/request'
).then((defaultModule) => defaultModule?.uploadFile?.(props));

const uploadImage = (props: UploadRequestOption) => import(
  /* webpackChunkName: "upload" */
  './utils/request'
).then((defaultModule) => defaultModule?.uploadImage?.(props));


Upload.FileList = FileList;
Upload.SensitiveWordsDialog = SensitiveWordsDialog;

Upload.uploadTools = uploadTools;

Upload.filePreview = FilePreview;
Upload.download = download;

Upload.getAttachmentLocalSecurityInfo = getAttachmentLocalSecurityInfo;

Upload.doLocalSecurityValidate = doLocalSecurityValidate;

Upload.checkFileTypeBySystem = checkFileTypeBySystem;

Upload.uploadFile = uploadFile;
Upload.uploadImage = uploadImage;

export type { UploadProps, UploadData, IFile, FilePreviewOptions, UploadOptionType, UploadOptionHoverPosition, SensitiveWordsDialogProps } from './types';
export { upload };
export default Upload;
