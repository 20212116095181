import { request, RequestOptionConfig, RequestPromise } from '@weapp/utils';
import { AnyObj } from '../../../../types/common';

/**
 * 点击评论列表编辑时获取明文
 */
export const decryptCommentById = ( data: any = {}, url?: string, module = "cowork", otherParams?: RequestOptionConfig, otherActions?: AnyObj)=>{
  const doRequest = otherActions?.fetchData || request;
  return doRequest({
    url:url || `/api/${module}/common/comment/decryptCommentById`,
    method:'GET',
    params:{...data},
    ...otherParams
  }) as RequestPromise;
}