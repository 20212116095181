import { USESKELETON } from '../../constants/index';
import { mScopeClsPrefix } from "../../constants/index.m";
import Loadable, { LoadingType } from "../../react-loadable";
import { MScopeType, MScopeProps } from "./types";

const MScopeSkeleton: LoadingType<MScopeProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MScope = Loadable({
  name: 'MScope',
  loading: MScopeSkeleton,
  loader: () =>
    import(
      /* webpackChunkName: 'ui_m_common_less' */
      "./Scope.m"
    ),
}) as MScopeType;

MScope.MScopeSkeleton = MScopeSkeleton;

MScope.defaultProps = {
  prefixCls: mScopeClsPrefix,
};

export type { ScopeProps as MScopeProps } from "./types";

export default MScope;
