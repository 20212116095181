import Loadable from '../../react-loadable';
import { TitleProp } from './types';

const Title = Loadable({ name: 'Title', loader: () => import(
   /* webpackChunkName: "ui_common" */
  './Title') }) as TitleProp;

Title.defaultProps = {
  titleTipType: 'title',
}
export type { TitleProps } from './types';

export default Title;
