import axios, { AxiosResponse } from "axios";
import { request, RequestOptionConfig, getLabel, RequestPromise, ua, sparkMD5, corsImport } from '@weapp/utils';
import { AnyObj } from '../../../types/common';
import { blobToFile, fileParse, getUrl, message } from './util';
import { getRealUrl } from '../utils/util';
import { PreCheckDataType, UploadData, UploadFileRequestOption, UploadRequestHeader, UploadRequestMethod } from '../types';
import {
  PREVIEWURL, BATCHDELETEURL, EDITURL, RENAMEURL, EDITSECLEVELURL, EDITSECLEVELSURL, QUERYDOCNAMEURL, BATCHDOWNLOADURL,
  BATCHDOWNLOADPERCENTURL, UPLOADURL, PREUPLOADCHECK, DELETEURL, APPEALURL, GETINFOBYREFORDOCURL, SYSTEMUPLOADCHECKCONFIG
} from '../constants';
import type {
  UploadRequestOption, UploadRequestError, UploadProgressEvent, IFile, DeleteRequestOption, PreviewRequestOption,
  EditRequestOption, RenameRequestOption, AppealRequestOption, UpdateFileStatusRequestOption, EditSecRequestOption
} from "../types";
import { isValueEmpty } from "../../../utils";

function getError(option: {
  method?: UploadRequestMethod,
  url?: string
}, xhr: AxiosResponse | AnyObj) {
  const msg = `cannot ${option.method} ${option.url} ${xhr.status}'`;
  const err = new Error(msg) as UploadRequestError;
  err.status = xhr.status;
  err.method = option.method;
  err.url = option.url;
  return err;
}

// 计算 MD5
const getFilemd5 = async (props: {
  file: File,
  lastModified?: string,
  md5ChunkSize?: number,
  onFileParsingProgress?: (params: {
    origin: IFile,
    type: string,
    percent: number
  }) => void;
}) => {
  return new Promise<string>(async (resolve, reject) => {
    const { file, lastModified, md5ChunkSize, onFileParsingProgress } = props;
    const SparkMD5 = await sparkMD5();
    let spark = new SparkMD5.ArrayBuffer();
    const size = md5ChunkSize || 50;
    let modified = lastModified || (+new Date()).toString();
    let md5 = null;

    const onProgress = (type: 'success' | 'error' | 'analyzing', currentChunk?: number, chunks?: IFile[]) => {
      // 大于 400m 的文件才需要触发解析进度
      let show = false;
      let percent = 0;
      if (file.size > 1024 * 1024 * 400 && currentChunk && chunks) {
        percent = Math.floor((currentChunk + 1) / chunks.length * 100);
        show = true;
      } else if(type === 'error') {
        show = true;
      }
      if(show) {
        onFileParsingProgress?.({
          origin: file as IFile,
          type: type,
          percent: percent
        });
      }
    }

    if (file.size > 1024 * 1024 * size) {
      const chunks = await sliceFile(file, size, modified);
      const fileReader = new FileReader();
      let currentChunk = 0;
      fileReader.onload = (e) => {
        const buffer = e.target?.result;
        if (buffer) {
          spark.append(buffer);
          if (currentChunk < chunks.length - 1) {
            onProgress('analyzing', currentChunk, chunks);
            // 读取下一个分片
            currentChunk++;
            readChunk();
          } else {
            md5 = spark.end(); // 得到md5
            spark.destroy(); // 释放缓存
            onProgress('success', currentChunk, chunks);
            resolve(md5);
          }
        } else {
          onProgress('error', currentChunk, chunks);
          console.error(getLabel('228366', '$s md5 解析失败，文件分片读取失败').replace('$s', file.name));
          reject('md5');
        }
      }

      fileReader.onerror = (e) => {
        onProgress('error', currentChunk, chunks);
        console.error(getLabel('228367', '$s md5 解析失败，文件读取异常').replace('$s', file.name), e);
        reject('md5');
      }

      const readChunk = () => {
        fileReader.readAsArrayBuffer(chunks[currentChunk])
      }

      readChunk();
    } else {
      try {
        let buffer = await fileParse(file, "buffer");
        spark.append(buffer);
        md5 = spark.end();
        spark.destroy(); //释放缓存
        resolve(md5);
      } catch (e) {
        onProgress('error');
        console.error(getLabel('228367', '$s md5 解析失败，文件读取异常').replace('$s', file.name), e);
        reject('md5');
      }
    }
  })
}

/** 附件切片 */
const sliceFile = async (file: File | IFile, chunkSize: number, lastModified: string): Promise<IFile[]> => {
  // IE 不支持 new File()，这里 File 不识别的话重写一下
  let File: any = window.File;
  try {
    new File([], '')
  } catch (e) {
    File = class File extends Blob {
      lastModifiedDate: string;
      lastModified: string;
      name: string;
      constructor(chunks: BlobPart[], filename: string, opts?: FilePropertyBag) {
        super(chunks, opts);
        this.lastModifiedDate = lastModified;
        this.lastModified = lastModified;
        this.name = filename;
      }
    }
  }

  return new Promise(rs => {
    const chunks = [];
    const BIG_FILE_SIZE = 1024 * 1024 * (chunkSize || 5);
    let start = 0, end = BIG_FILE_SIZE;
    while (true) {
      let part: any | IFile = new File([file.slice(start, end)], file.name, {
        lastModified: lastModified
      })
      // 处理密级和密级期限
      if ('secretLevel' in file && file?.secretLevel) part.secretLevel = file.secretLevel;
      if ('secretLevelValidity' in file && file?.secretLevelValidity) part.secretLevelValidity = file.secretLevelValidity;
      // 文件替换标识
      if ('replaceFlag' in file && file?.replaceFlag) part.replaceFlag = file.replaceFlag;

      if (part.size) {
        chunks.push(part);
      } else {
        break;
      }
      start += BIG_FILE_SIZE;
      end += BIG_FILE_SIZE;
    };
    rs(chunks)
  })
}

/** formData 请求（适配文档接口） */
const formDataRequest = (props: RequestOptionConfig & {
  fetchUpload?: <T = any>(props: RequestOptionConfig) => RequestPromise<T>;
}, file?: IFile): Promise<{
  data: AnyObj,
  message?: string,
  msg?: string,
  status: boolean,
  code: number
}> => {
  const { fetchUpload, onUploadProgress, params, ...restProps } = props;
  const formData = new FormData();
  if (params && Object.keys(params).length > 0) {
    Object.keys(params).forEach((key) => {
      let value = params?.[key];
      if (typeof value === 'object') {
        if (Array.isArray(value)) {  // 数组
          value.forEach((item) => {
            formData.append(`${key}[]`, item);
          });
        } else if (key === 'customParam') {  // 仅针对 customParam 序列化处理
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      } else if (typeof value !== 'undefined') {  // 非对象
        formData.append(key, value);
      }
    });
  }
  if (file) formData.append(params.fileDataName || "file", file, params.name || file.name); // 附件

  const customfetch = fetchUpload || request;
  return customfetch({
    ...restProps,
    data: formData,
    onUploadProgress: (e: UploadProgressEvent) => {
      if (e.total > 0) {
        e.percent = Math.floor((e.loaded / e.total) * 100);
      }
      onUploadProgress?.(e);
    }
  });
}

/******************** 附件上传 ********************/
export default async function upload(props: UploadRequestOption, options?: { getCancelFn?: any, fetchUpload?: any }) {
  const {
    uploadUrl, uploadParams, method: uploadMethod, timeout, withCredentials,
    loginFree, file, fileDataName, filename, name, headers: uploadHeaders,
    md5ChunkSize, onError, onProgress, onSuccess, onFileParsingProgress
  } = props;
  const { fetchUpload, getCancelFn } = options || {};

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  getCancelFn?.(source.cancel);
  let pId = '';
  try {
    const lastModified = (+new Date()).toString();
    const MD5 = await getFilemd5({ file, lastModified, md5ChunkSize, onFileParsingProgress });
    const url = uploadUrl || getRealUrl(UPLOADURL, { loginFree });
    const method = uploadMethod || 'POST';
    const headers: UploadRequestHeader = uploadHeaders || {};
    const params: AnyObj = { ...uploadParams };
    params['MD5'] = MD5;
    params[filename || 'name'] = name ?? file.name;
    params['size'] = file.size;
    params['lastModified'] = lastModified;
    params['lastModifiedDate'] = lastModified;
    if (file.secretLevel) { params["secretLevel"] = file.secretLevel };
    if (file.secretLevelValidity) { params["secretLevelValidity"] = file.secretLevelValidity };
    if (loginFree && !params?.tenantKey) { params["tenantKey"] = 'all_teams' };

    // 1. 先校验 url 是否为标准 url
    const uploadUrl_Regex = new RegExp('/{api}/file/module/upload'.replace(/{api}/, '(api|papi|api\\/app|papi\\/app)').replace('/', '\\/') + '$');  // 普通上传
    const reUploadUrl_Regex = new RegExp('/{api}/file/reUpload'.replace(/{api}/, '(api|papi|api\\/app|papi\\/app)').replace('/', '\\/') + '$');  // 重上传
    const isBreakpointUpload = !isValueEmpty(file.continueUploadParam);
    if (uploadUrl_Regex.test(url) || reUploadUrl_Regex.test(url)) {
      // 预上传
      const preUploadUrl = getRealUrl(PREUPLOADCHECK, { loginFree: loginFree });
      if (isBreakpointUpload) params['preUploadId'] = file.continueUploadParam.preUploadId;
      const res = await formDataRequest({
        url: preUploadUrl,
        method: 'POST',
        headers,
        params,
        withCredentials,
        timeout,
        cancelToken: source.token,
        fetchUpload: fetchUpload,
      });
      const { status, code, data, message } = res;
      if (!status) {
        // 上传失败
        await onError?.(getError({ ...props, url }, res), res, code);
        return;
      }
      const { uploadType, preUploadId, chunkDto, uploadModuleDto } = data as PreCheckDataType;
      pId = preUploadId;
      params['preUploadId'] = preUploadId;
      params['uploadType'] = uploadType;
      if (file) { params[fileDataName || 'file'] = file };
      // 实际上传
      if (uploadType === 'second' && !isValueEmpty(uploadModuleDto)) {  // 秒传（直接结束）
        if (uploadModuleDto && !uploadModuleDto?.name) {
          uploadModuleDto.name = name || getLabel("28550", "未命名");
        }
        await onSuccess?.(uploadModuleDto, res as any, file);
      } else if (uploadType === 'common') {  // 普通上传
        const res = await formDataRequest({
          url: url,
          method: method,
          headers: headers,
          params: params,
          timeout: timeout,
          withCredentials: withCredentials,
          cancelToken: source.token,
          fetchUpload: fetchUpload,
          onUploadProgress: onProgress
        })
        const { status, code, data, message } = res;
        if (!status) {
          await onError?.(getError({ ...props, url }, res), res, code);
        } else {
          // 当上传接口没有返回文件名时，直接使用文件名或"未命名"。
          if (data && !data?.name) {
            data.name = name || getLabel("28550", "未命名");
          }
          await onSuccess?.(data, res as any, file);
        }
      } else if (uploadType === 'chunk' && chunkDto) {  // 分片上传
        // 获取所有切片
        const chunks = await sliceFile(file, chunkDto.chunkSize, lastModified);
        params['chunks'] = chunks.length;
        let percent = 0;
        let uploadedChunkTotal = 0;
        let uploadingChunkLoaded = 0;
        let index = isBreakpointUpload ? chunkDto.nowChunk : 0;
        const optinos = {
          url: url,
          method: method,
          params: params,
          headers: headers,
          timeout: timeout,
          withCredentials: withCredentials,
          cancelToken: source.token,
          fetchUpload: fetchUpload,
        }
        // 分片上传
        await chunksUpload({
          num: index,
          fileDataName,
          chunks,
          optinos,
          params,
          // eslint-disable-next-line no-loop-func
          onProgress: (index, chunk, e: UploadProgressEvent) => {
            if (e.percent === 100) { // 当一个chunk加载完要进入下一个chunk的时候，累计上一个文件的chunk的大小
              uploadedChunkTotal += chunk.size;
              uploadingChunkLoaded = 0
            } else { // 在同一个chuck的时候实时记录已上传当前chunk大小
              uploadingChunkLoaded = e.loaded;
            }
            // 实时地计算第一个chunk上传的进度同步更新进度条
            percent = Math.floor((uploadedChunkTotal + uploadingChunkLoaded) / file.size * 100);
            e.chunk = index;
            e.percent = file.percent
              ? percent > file.percent ? percent : file.percent
              : percent > 100 ? 99 : percent;
            onProgress?.(e);
          },
          onError: async (type, res) => {
            if (type === 1) {
              const { code } = res;
              await onError?.(getError({ ...props, url }, res), res, code);
            } else {
              throw res;
            }
          },
          onSuccess: async (res: any) => {
            // 当上传接口没有返回文件名时，直接使用文件名或"未命名"。
            if (data && !data?.name) {
              data.name = name || getLabel("28550", "未命名");
            }
            await onSuccess?.(res.data, res, file)
          },
        })
      } else {  // 失败
        await onError?.(getError({ ...props, url }, {}), {}, -3);
      }
    } else {
      if (file) { params[fileDataName || 'file'] = file };
      const res = await formDataRequest({
        url: url,
        method: method,
        params: params,
        headers: headers,
        timeout: timeout,
        withCredentials: withCredentials,
        onUploadProgress: onProgress,
        cancelToken: source.token,
        fetchUpload: fetchUpload,
      })
      const { status, code, data } = res;
      if (!status) {
        await onError?.(getError({ ...props, url }, res), res, code);
      } else {
        // 当上传接口没有返回文件名时，直接使用文件名或"未命名"。
        if (data && !data?.name) {
          data.name = name || getLabel("28550", "未命名");
        }
        await onSuccess?.(data, res as any, file);
      }
    }
  } catch (error: any) {
    const response = error?.response;
    if(error === 'md5') {
      await onError?.(error, {}, -4);
    } else if (error && 'message' in error && error?.message === undefined) {  // 取消上传不触发报错提示
      console.error(getLabel('222205', '上传已取消！'))
    } else if (response === undefined) {  // 接口超时/网络异常：-1
      await onError?.(error as any, { preUploadId: pId }, -1);
    } else {
      await onError?.(error as any, response?.data || {}, -3);
    }
  }
}

// 延时处理
const delay = (retryDelay: number) => new Promise<void>((resolve, reject) => {
  setTimeout(() => {
    resolve();
  }, retryDelay);
})

/** 分片上传 */
export const chunksUpload = async (props: {
  chunks: any[],
  optinos: AnyObj,
  params: AnyObj,
  num?: number,
  reNum?: number,
  fileDataName?: string,
  retryCount?: number;
  retryDelay?: number;
  onProgress: (index: number, chunk: any, e: any) => void;
  onError: (type: number, res: any) => void;
  onSuccess: (res: any) => void;
}) => {
  const { chunks, num = 0, reNum = 0, params, fileDataName, optinos, retryCount = 2, retryDelay = 300,
    onProgress, onError, onSuccess } = props;
  for (let i = num; i < chunks.length; i++) {
    const chunk = chunks[i];
    params[fileDataName || "file"] = chunk;; // 附件
    params['uploadShardSize'] = chunk.size;
    params['chunk'] = i;
    try {
      const res = await formDataRequest({
        ...optinos,
        params: params,
        onUploadProgress: (e: any) => {
          onProgress(i, chunk, e);
        }
      });
      if (!res?.status) {
        await onError?.(1, res);
        break;
      } else if (res?.data?.endChunk) {
        await onSuccess?.(res as any)
        break;
      }
    } catch (error: any) {
      const response = error.response;
      if (response === undefined && error?.message && reNum < retryCount) {
        // 重试延迟
        await delay(retryDelay);
        // 网络异常失败重试（此时不需要触发 preUpload 接口）
        await chunksUpload({
          ...props,
          reNum: reNum + 1,
          num: i,
        });
        break;
      } else {
        await onError(0, error);
        break;
      }
    }
  }
}

/** 上传的 Promise 方法 */
export async function uploadFile(props: UploadRequestOption): Promise<UploadData> {
  return new Promise((resolve, reject) => {
    upload({
      ...props,
      onError: (event: ProgressEvent<EventTarget> | UploadRequestError, res?: any, code?: number) => {
        reject(res.message || getLabel('222461', '上传失败，请重新上传！'));
      },
      onSuccess: (data: UploadData, res: any, file?: IFile) => {
        if (!data?.fileid) {
          reject(getLabel('222461', '上传失败，请重新上传！'))
        } else {
          resolve(data);
        }
      }
    })
  })
}

/** 提供给编辑器用于上传图标 */
export async function uploadImage(props: UploadRequestOption): Promise<{
  imgUrl: string,
  data: UploadData,
}> {
  return new Promise((resolve, reject) => {
    const { loginFree, file, uploadParams } = props;
    if (!file || !/^image/.test((file as File).type)) {
      reject(getLabel('222458', '请传递图片文件！'));
    } else {
      if (file instanceof Blob && !(file instanceof File)) {
        const mimeTypeMap: Record<string, string> = {
          'image/jpeg': 'jpg',
          'image/png': 'png',
          'image/gif': 'gif',
        };
        const suffix = mimeTypeMap[(file as Blob).type] || 'png';
        props.file = blobToFile(file, uploadParams?.name || `image.${suffix}`) as IFile;
      }
      uploadFile(props)
        .then(data => {
          if (!data?.fileid) {
            reject(getLabel('222461', '上传失败，请重新上传！'))
          } else {
            const url = getRealUrl(PREVIEWURL!, { loginFree: loginFree });
            const imgUrl = getUrl({ url: url, params: { type: 'redirect', imgFormat: 'image', fileId: data.fileid } })
            resolve({
              imgUrl: imgUrl,
              data: data
            })

            // // 企业微信图片预览加密传输 eteamsid（no. 2616247）
            // // 该方法不需要处理，这个方法是提供给 richText 进行图片上传的，richText 中的图片预览会自行拼接参数
            // if (ua.browser === 'wxwork' && !loginFree) {
            // const url = getRealUrl(PREVIEWURL!, { loginFree: true });
            //   corsImport('@weapp/ecode').then(esm => {
            //     esm.EcodeCrypto.encrypt(data.fileid + '_' + window.ETEAMSID, true).then((res: any) => {
            //       const imgUrl = getUrl({
            //         url: url,
            //         params: {
            //           type: 'redirect',
            //           imgFormat: 'image',
            //           fileId: data.fileid,
            //           wuuid: res.encrypted,
            //           kuuid: res.key,
            //         }
            //       })
            //       resolve({
            //         imgUrl: imgUrl,
            //         data: data
            //       })
            //     })
            //   })
            // } else {
            // const url = getRealUrl(PREVIEWURL!, { loginFree: loginFree });
            //   const imgUrl = getUrl({ url: url, params: { type: 'redirect', imgFormat: 'image', fileId: data.fileid } })
            //   resolve({
            //     imgUrl: imgUrl,
            //     data: data
            //   })
            // }
          }
        })
        .catch(error => {
          reject(error || getLabel('222460', '上传接口异常，请稍后再试！'));
        })
    }
  })
}
/******************** 其他附件相关接口 ********************/
//#region 其他附件相关接口
/**
 * 文件删除接口
 * @param option DeleteRequestOption
 */
export const deleteRequest = (option: DeleteRequestOption) => {
  const { url, params, method, fileId } = option;
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const _url = getRealUrl(url || DELETEURL, { fileId: fileId });
  let _params = url ? { ...params, fileId } : params;
  let deleteConfig: RequestOptionConfig = {
    method: method ? method : "post",
    url: _url,
    params: {
      ..._params,
    },
    headers: option.headers,
    cancelToken: source.token,
  }
  request(deleteConfig)
    .then((res) => {

      if (res.code !== 200) {
        return option?.onError?.(res);
      }
      return option?.onSuccess?.(res);
    })
    .catch(function (error) {
      option?.onError?.(error);
    });
}

/**
 * 预览接口
 * @param option PreviewRequestOption
 * @returns RequestPromise<any>
 */
export const previewRequest = (option: PreviewRequestOption) => {
  const { url, fileId, method, loginFree, params, headers } = option;
  const _url = getRealUrl(url || PREVIEWURL, { loginFree });
  const options: RequestOptionConfig = {
    url: _url,
    method: method ? method : 'GET',
    params: {
      fileId: fileId,
      ...params
    },
    headers,
  }
  if (params?.type === 'imgs') {
    options.responseType = 'blob';
  }
  return request(options);
}


/**
 * 编辑接口
 * @param option EditRequestOption
 * @returns RequestPromise<any>
 */
export const editRequest = (option: EditRequestOption) => {
  const { url, fileId, method, params, headers } = option;
  let _url = getRealUrl(url || EDITURL);
  const options: RequestOptionConfig = {
    url: _url,
    method: method ? method : 'GET',
    params: {
      id: fileId,
      ...params,
    },
    headers,
  }
  return request(options);
}

/**
 * 重命名
 * @param option EditRequestOption
 * @returns RequestPromise<any>
 */
export const renameRequest = (option: RenameRequestOption) => {
  const { url, fileId, method, params, module, headers } = option;
  let _url = getRealUrl(url || RENAMEURL);
  const options: RequestOptionConfig = {
    url: _url,
    method: method ? method : 'POST',
    data: {
      fileid: fileId,
      module,
      ...params
    },
    headers,
  }
  return request(options);
}

/**
 * 申述
 * @param option EditRequestOption
 * @returns RequestPromise<any>
 */
export const appealRequest = (option: AppealRequestOption) => {
  const { url, fileId, method, params, headers } = option;
  const _url = getRealUrl(url || APPEALURL, { fileId, isMobile: false });
  const options: RequestOptionConfig = {
    url: _url,
    method: method ? method : 'GET',
    data: {
      fileid: fileId,
      ...params
    },
    headers
  }
  return request(options);
}


/**
 * 获取文件信息
 * @param option EditRequestOption
 * @returns RequestPromise<any>
 */
export const updateFileStatusRequest = (option: UpdateFileStatusRequestOption) => {
  const { url, docId, refId, method, params } = option;
  const _url = getRealUrl(url || GETINFOBYREFORDOCURL, { isMobile: false })
  let parmas: any = { ...params };
  if (docId) parmas.docId = docId;
  if (refId) parmas.refId = refId;
  const options: RequestOptionConfig = {
    url: _url,
    method: method ? method : 'GET',
    params: parmas
  }
  return request(options);
}

/**
 * 修改附件密级
 * @param option Object
 * link: http://10.10.27.86:4000/web/#/49?page_id=1833
 * link: http://10.10.27.86:4000/web/#/49/2439
 * @returns RequestPromise<any>
 */
export const editSecRequest = (option: EditSecRequestOption) => {
  const { params, module } = option;
  const url = getRealUrl(EDITSECLEVELURL);
  const reqConfig: RequestOptionConfig = {
    url,
    data: {
      module,
      ...params
    },
    method: 'POST'
  }
  return request(reqConfig)
}

/**
 * 修改附件密级（批量）
 * @param option Object
 * @returns RequestPromise<any>
 */
export const fileEditSecLevelsRequest = (option: EditSecRequestOption) => {
  const { params, module } = option;
  const url = getRealUrl(EDITSECLEVELSURL);
  const reqConfig: RequestOptionConfig = {
    url,
    data: {
      module,
      ...params
    },
    method: 'POST'
  }
  return request(reqConfig)
}

/**
 * 附件重复名称校验
 * @param option Object
 * @returns RequestPromise<any>
 */
export const checkDuplicateNameRequest = (option: EditSecRequestOption) => {
  const { params, module } = option;
  const url = getRealUrl(QUERYDOCNAMEURL, { isMobile: false });
  const reqConfig: RequestOptionConfig = {
    url,
    data: {
      ...params,
      module: module
    },
    method: 'POST'
  }
  return request(reqConfig)
}

// 获取批量下载数据key
// https://showdoc.alphaxx.cn:9443/web/#/51/4551
export const getBatchDownloadDataKey = (data: AnyObj, isMobile?: boolean) => {
  const url = getRealUrl(BATCHDOWNLOADURL);
  return request({
    url: url,
    method: 'POST',
    data: data,
  })
};

// 获取批量下载数据处理、打包进度
// https://showdoc.alphaxx.cn:9443/web/#/51/4552、https://showdoc.alphaxx.cn:9443/web/#/51/4553
export const getBatchDownloadPercent = (data: AnyObj, isMobile?: boolean) => {
  const url = getRealUrl(BATCHDOWNLOADPERCENTURL);
  return request({
    url: url,
    method: 'GET',
    params: data,
  })
};


/**
 * 批量删除
 * http://10.10.27.86:4000/web/#/51?page_id=3161
 */
export const batchDeleteReq = (config: { url?: string, params?: AnyObj }) => {
  let deleteUrl = getRealUrl(config.url || BATCHDELETEURL);
  let batchDelConfig: RequestOptionConfig = {
    url: deleteUrl,
    method: 'POST',
    data: config.params
  }
  return request(batchDelConfig);
}

/** 获取系统附件大小限制、附件类型白名单/黑名单配置 */
export const getSystemUploadCheckConfigRequest = (option: AnyObj) => {
  // 免登陆环境不请求
  const url = getRealUrl(SYSTEMUPLOADCHECKCONFIG, { loginFree: false, isMobile: false });
  return request({
    url: url,
    method: 'POST',
    data: {
      setType: []
    }
  })
}
//#endregion
