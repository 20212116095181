import { classnames, getLabel, request, RequestOptionConfig, moduleRouter, RequestPromise } from "@weapp/utils";
import { Method } from "axios";
import { action, observable, toJS, computed } from "mobx";
import { ReactText } from "react";
import { AnyObj, SingleType } from "../../../types/common";
import { BrowserValueType } from '../../browser/index';
import { FormCustomLayoutProps, FormDatas, FormGroupProps, FormLayoutProps, FormLayoutType, FormStore, FormStoreType } from '../../form/index';
import { FormItemProps, FormSingleItemProps } from '../../form-item/index';
import { CardDetailFormAllDatas, CardDetailFormProps, GroupListType, MCardDetailFormProps } from "../types";
import Dialog from '../../dialog/index';
import { registerCustomRenderProjectItem, registerAssociativeTemplate, payModuleRules, isDisabledModule } from '../template';
import { deepClone, isEmpty, needRTL, needSync } from '../../../utils/index';
import { mCardDetailFormClsPrefix } from "../../../constants/index.m";
import { cardDetailFormClsPrefix } from "../../../constants/index";
import { commonRelationsRules } from "../template";

import { register as ConfigRegister } from '../link-form-data-template/config';
import { SecretLevelStore, SecretLevelStoreType } from "./SecretLevelStore";
import { browserFetchData, updateSortArr } from "../utils";
import { getUtils } from "./common";
import { IFile } from "../../../lib";

const { message } = Dialog;
const initGroups: Array<FormGroupProps> = [
  { id: 'normal', title: '', custom: false, visible: true },
  { id: 'alwaysShow', title: '', custom: true, visible: true },
  { id: 'collapsible', title: '', custom: true, visible: true },
  { id: 'follow', title: '', custom: true, visible: true },
]
const initAllGroups: Array<FormGroupProps> = [
  ...initGroups,
  { id: 'tags', title: '', custom: true, visible: true },
]
interface FormStores {
  [key: string]: FormStoreType,
}

export interface SingleRelevancesData {
  id: string,
  objectid: string,
  objectModule: string,
  targetId: string,
  targetModule: string,
  [key: string]: any,
}
export interface RelevancesData {
  [key: string]: Array<SingleRelevancesData>
}

interface RelevanceDatasSingleUrl {
  url: string,
  type: string,
}
export interface RelevanceDatasUrl {
  [key: string]: RelevanceDatasSingleUrl,
}

interface InitItemsOtherParams {
  [key: string]: any,
}

export default class CardDetailFormStore {
  afterUpdateRelevanceDatas?: (datas: AnyObj) => void;
  canChangeLayout?: (type: string, keys: Set<string>) => Set<string>;
  afterHideTag?: (id: string) => void;
  // new version
  newVersion = false;
  formStores: FormStores = {
    'normal': new FormStore(),
    'alwaysShow': new FormStore(),
    'collapsible': new FormStore(),
    'tags': new FormStore(),
    'follow': new FormStore(),
  }
  allGroups: Array<FormGroupProps> = initAllGroups;
  @observable groups: Array<FormGroupProps> = initGroups;
  @observable items: FormItemProps = {};
  @observable customLayout: FormCustomLayoutProps = {};
  @observable layout: FormLayoutType[] = [];
  @observable tags: FormLayoutType = [];
  @observable visible: boolean = false;
  @observable innerVisible: boolean = true;
  @observable isMobile: boolean = false;
  @observable pass: boolean = true;
  @observable collapsiblePass: boolean = true;

  // 顶部输入框
  @observable description: ReactText = '';
  @observable module: string = 'common'; // 接口标识
  @observable saveParams: any = {}; // 删除/保存用一个
  @observable initRelevanceDatas: FormDatas = {};
  @observable relevanceDatas: FormDatas = {}; // 关联字段配置值存储
  @observable relevanceDatasUrl: RelevanceDatasUrl = {}; // 关联字段自定义保存url配置
  @observable saveDataParams: any = {};
  @observable dataParams: any = {};
  @observable requestHeaderParams: AnyObj = {}; // 接口headers自定义参数

  @observable customGrouping: boolean = false;
  @observable commonRelations = new Map(); // commonRelations: 接口保存的数据key与布局items key的对应关系
  @observable targetModuleRelations = new Map(); // targetModuleRelations: 布局items key的对应关系与接口保存的数据key的对应关系

  @observable showFreeComponent?: boolean = false; // 展示移动端无权限提示信息页

  @observable validateHiddenField?: boolean = false; // 校验隐藏字段(layout纬度)，默认不校验
  @observable validateHiddenItems?: boolean = false; // 校验隐藏字段(items纬度)，默认不校验
  @observable defaultValidate?: boolean = false; // 默认初始化校验

  @observable registerRules: AnyObj = {}; // 自定义行为注册规则

  @observable lockSave: boolean = false; // 锁定保存事件(用于解决在已选列表删除数据也触发了onChange的问题)
  @observable loading: boolean = false; // 是否显示正在加载中(暂不考虑配置了queryUrl接口的字段，以免单个字段接口问题引起整个表单无法展示的问题)

  @observable history?: AnyObj = undefined;

  secretLevelStore: SecretLevelStoreType = new SecretLevelStore(this);

  @observable hasInit: boolean = false; // 布局初始化

  @observable fetchData: any;

  @observable groupList?: GroupListType[] = [];

  groupListItems?: string[] = [];

  @observable searchValue: ReactText = '';

  @observable requestNumber: number = 0;

  @computed get groupLists() {
    return this.groupList?.map((da) => ({
      id: da.id,
      title: da.name,
      custom: false,
      visible: true,
    })) as FormGroupProps[];
  }

  prohibitRealTimeSaving?: boolean = false;
  innerRefs: AnyObj = {};

  @action
  clear = () => {
    Object.values(this.formStores).forEach((store) => store?.clear());
  }
  @action
  register = (registerRules: AnyObj) => {
    this.registerRules = {
      ...this.registerRules,
      ...registerRules,
    }
  }

  /**
   * 注册自定义规则
   */
  @action
  initRegisterRules = (key: string, item: FormSingleItemProps, col: FormLayoutProps, otherParams: InitItemsOtherParams) => {
    ConfigRegister(item, this, key, col, otherParams);
    return item;
  }

  @action
  setState = (params: Partial<CardDetailFormStoreType>) => {
    Object.keys(params).forEach((key) => {
      const storeKey = key as keyof CardDetailFormStoreType;
      // @ts-ignore
      this[storeKey] = params[storeKey];
    })
  }

  @action
  setDescription = (description: ReactText) => {
    this.description = description;
  }

  @action
  doRelevancesDeselete = (data: SingleType<BrowserValueType>, item: FormItemProps, otherParams?: any) => {
    return getUtils().then((defaultModule) => defaultModule.doRelevancesDeselete(this, data, item, otherParams));
  }

  @action
  onRelevancesDeselete = (data: SingleType<BrowserValueType>, item: FormItemProps, otherParams?: AnyObj) => {
    return getUtils().then((defaultModule) => defaultModule.onRelevancesDeselete(this, data, item, otherParams));
  }

  @action
  onRelevancesBatchDelete = (data: SingleType<BrowserValueType>, otherParams?: any) => {
    return getUtils().then((defaultModule) => defaultModule.onRelevancesBatchDelete(this, data, otherParams));
  }

  /**
   * getFreeConfigs 公共关联浏览框字段是否付费字段配置
   * @param type 
   * @param item 
   */
  @action
  getFreeConfigs = (type: string, item: FormSingleItemProps, otherParams: InitItemsOtherParams) => {
    // 公共关联浏览框字段是否付费判断
    const payModule = payModuleRules[type];
    if (payModule === false) return item;
    if (Array.isArray(payModule) && payModule.find((mo: string) => {
      return window.TEAMS?.isPayModule?.(mo);
    })) {
      return item;
    } else if (window.TEAMS?.isPayModule?.(payModule)) {
      return item;
    }
    // 未付费模块，字段只读，否则按照业务配置的item为准(按照et的原有逻辑，仅判断payModules中是否存在即可)
    item.free = true;
    if (!otherParams?.isMobile) {
      item.readOnly = otherParams?.isMobile ? item.readOnly : true; // 未付费模块，字段只读，否则按照业务配置的item为准
      item.className = classnames(item.className, `${mCardDetailFormClsPrefix}-free-field`);
      item.otherParams = {
        ...item.otherParams,
        readOnly: otherParams?.isMobile ? item.readOnly : true,
        className: classnames(item.otherParams?.className, `${mCardDetailFormClsPrefix}-free-field`),
      }
    } else {
      item.browserBean = {
        ...(item.browserBean || {}),
        browserAssociativeProps: {
          ...(item.browserBean?.browserAssociativeProps || {}),
          onBeforeInputClick: () => { },
          onBeforeFocusCheck: null,
        }
      }
    }
    return item;
  }

  @action
  getCommonBrowserConfig = (key: string, item: FormSingleItemProps, col: FormLayoutProps, otherConfig: InitItemsOtherParams) => { // 仅修改公共关联字段的浏览框，其余浏览框不在组件内部处理
    const otherParams = otherConfig?.isMobile ? {
      isAssociative: item.common,
    } : {};
    const enableAddDataProps = this.getEnableAddDataProps(key);
    const labelParams = otherConfig?.isMobile && item.common ? {
      label: col.carddetailform_label || col.label, labelSpan: col.carddetailform_labelSpan || col.labelSpan, placeholder: getLabel('76040', '未关联'),
      formItemItemProps: {
        required: item?.required || item?.otherParams?.required,
        isMobile: otherConfig?.isMobile,
      }
    } : {};
    let customBrowserAssociateProps = {}, customBrowserProps = {};
    if (!item.browserBean?.browserAssociativeProps?.onSelectedItemLink) {
      // 新增已选数据自定义打开逻辑，优先外部配置
      /*
        公共浏览框特殊处理项: 关联表单
      */
      if (this.targetModuleRelations.get(key) === 'biaoge') {
        customBrowserAssociateProps = {
          onSelectedItemLink: (data: SingleType<BrowserValueType>, viewDialogPath: string) => {
            this.history?.push(`${viewDialogPath}/${moduleRouter.generateId?.(data.id, {
              params: {
                ...otherConfig?.cardDetailDialogParams,
                detailId: data.detailId,
                type: data.type || data.detailType,
              },
            })}`)
          },
        }
        customBrowserProps = {
          destDataParams: this.dataParams || {},
        }
      }
      /**
       * 公共关联流程浏览框特殊处理项：配合流程接口
       */
      if (this.targetModuleRelations.get(key) === 'workflow') {
        customBrowserProps = {
          commonParams: { ...item?.browserBean?.commonParams, needRichContent: true }
        }
      }
    }
    if (!otherConfig?.isMobile && 
      (item?.browserBean?.browserAssociativeProps?.useBoxSelection || window?.TEAMS?.globalConfig?.useBoxSelection === 'true')
    ) {
      item = {
        ...item,
        wrapMaxWidth: '80%',
      }
    }

    let browserBeanRequestHeaderParams = otherConfig?.allowRequestHeaderParamsTrans ? {
      requestHeaderParams: {
        ...(item?.requestHeaderParams || item?.browserBean?.requestHeaderParams || item?.otherParams?.requestHeaderParams),//原配置保留
        ...otherConfig?.requestHeaderParams,
      }
    } : {};
    if (otherConfig?.requestHeaderParamsFields) {
      browserBeanRequestHeaderParams = {
        requestHeaderParams: {
          ...browserBeanRequestHeaderParams?.requestHeaderParams,
          ...otherConfig?.requestHeaderParamsFields,
        }
      }
    }
    item = {
      ...item,
      browserBean: {
        ...(item.browserBean || {}),
        defaultHideMore: false,
        cardDetailDialogParams: otherConfig?.cardDetailDialogParams,
        disabledSelectedSort: !this.newVersion, // 开启浏览框排序功能
        ...customBrowserProps,
        fetchData: (options: RequestOptionConfig) => browserFetchData(options, item),
        browserAssociativeProps: {
          customRenderSelectedItem: (templateCom: React.ReactNode, data: BrowserValueType) => registerCustomRenderProjectItem(item?.browserBean.type, data),
          ...(item.browserBean?.browserAssociativeProps || {}),
          onBeforeDeselect: (data: SingleType<BrowserValueType>) => this.onRelevancesDeselete(data, item, { key }),
          associativeTemplate: registerAssociativeTemplate(item?.browserBean.type),
          wrapDisplay: item.common && key !== 'common' && key !== 'my',
          ...enableAddDataProps,
          ...labelParams,
          ...customBrowserAssociateProps,
        },
        ...browserBeanRequestHeaderParams,
      },
      ...otherParams,
    };
    return item;
  }

  /* 公共关联字段自定义item配置处理 */
  @action
  setUploadInnerRef = (el: any) => this.innerRefs['upload'] = el;

  @action
  changeInitItems = (key: string, item: FormSingleItemProps, col: FormLayoutProps, module: string, otherParams: InitItemsOtherParams) => {
    const { saveDataParams, dataParams, globalReadOnly, requestHeaderParams, allowRequestHeaderParamsTrans, requestHeaderParamsFields } = otherParams;
    if ((col.hoverEdit || (col.otherParams && col.otherParams.hoverEdit)) && !item.common) {
      item.readOnly = true;
    }
    // 支持全局只读状态
    if(globalReadOnly){
      item.readOnly = true;
    }
    if (item.common && key === 'upload') {
      const uploadParams = {
        ...item?.uploadParams,
        // 支持otherParams中的uploadParams兼容
        ...item?.otherParams?.uploadParams,
        module: module || this.module,
        refId: (saveDataParams && saveDataParams.objectId) || (this.saveDataParams && this.saveDataParams.objectId),
      }
      let itemOtherParams = allowRequestHeaderParamsTrans ? {
        ...item?.otherParams,
        uploadParams,
        requestHeaderParams: {
          ...(item?.requestHeaderParams || item?.otherParams?.requestHeaderParams),//原配置保留
          ...requestHeaderParams,
          ...requestHeaderParamsFields,
        },
      } : {
        ...item?.otherParams,
        uploadParams,
        requestHeaderParams: {
          ...(item?.requestHeaderParams || item?.otherParams?.requestHeaderParams),//原配置保留
          ...requestHeaderParamsFields,
        }
      } as AnyObj;

      if (otherParams?.prohibitRealTimeSaving) {
        itemOtherParams['autoUpload'] = false;
        itemOtherParams['getInstance'] = this.setUploadInnerRef;
      }
      return this.secretLevelStore.filterInitItems({
        ...item,
        otherParams: itemOtherParams,
        isDeleteInterface: true,
        label: col.label,
        labelSpan: col.labelSpan,
        isShowLabel: true,
        isCardDetailForm: true,
        className: classnames(item.className, {
          [`${mCardDetailFormClsPrefix}-common-upload`]: otherParams.isMobile,
        }, {
          [`${cardDetailFormClsPrefix}-common-upload`]: !otherParams.isMobile,
        }, {
          needRTL: needRTL?.(),
        }),
        onStart: (file: IFile) => {
          item?.onStart?.(file);
          this.setState({ requestNumber: this.requestNumber + 1 });
        },
        onUploadFinally: () => {
          item?.onUploadFinally?.();
          this.setState({ requestNumber: this.requestNumber - 1 });
        }
      });
    }
    if (item.common && item.itemType === 'BROWSER') {
      const type = item?.browserBean?.type as string;
      if (type && commonRelationsRules[type]) {
        this.commonRelations.set(commonRelationsRules[type], key); // commonRelations: 接口保存的数据key与布局items key的对应关系
        this.targetModuleRelations.set(key, commonRelationsRules[type]); // targetModuleRelations: 布局items key的对应关系与接口保存的数据key的对应关系        
      }
      // 公共关联浏览框字段特殊配置
      item = this.getCommonBrowserConfig(key, item, col, otherParams);
      // 公共关联浏览框字段是否付费判断
      item = this.getFreeConfigs(type, item, otherParams);
    }
    if (item.common && item.itemType === 'PLUGIN') {
      item = {
        ...item,
        pluginParams: {
          ...item?.pluginParams,
          ...dataParams,
        }
      }
    }
    if (!otherParams?.isMobile) { // 目前仅需对pc端组件支持，移动端后续需要单独再开一个，避免影响
      item = this.initRegisterRules(key, item, col, otherParams); // 注册字段自定义规则
    }
    return this.secretLevelStore.filterInitItems(item);
  }
  @action
  getAddInfo = (type: string) => {
    switch (type) {
      case 'document': return getLabel('63190', '添加文档');
      case 'mainlineBrowser': return getLabel('63193', '添加项目');
      case 'common': return getLabel('63198', '新建标签');
      case 'my': return getLabel('63198', '新建标签');
      case 'taskBrowser': return getLabel('63199', '添加任务');
      case 'marketactivity': return getLabel('63200', '添加活动'); // 关联活动
      case 'calendarBrowser': return getLabel('63201', '添加日程');  // 关联日程
      case 'customer': return getLabel('63202', '添加客户'); // 关联客户
      default: return '';
    }
  }
  @action
  getEnableAddDataProps = (itemKey: string) => {
    const type = this.items[itemKey]?.browserBean?.type;
    // const disabledAddDataKeys = ['contact', 'clue', 'saleChance', 'contract', 'orderform',
    //   'mailResource', 'wflRequest ', 'blogBrowser', 'performanceBrowser', 'production',
    //   'competitor', 'planBrowser', 'price', 'frpt_form',
    //   'customer', 'marketactivity',
    //   // 'calendarBrowser', 'taskBrowser', 'mainlineBrowser'
    // ];
    const include = [
      'common', 'my',
    ];
    return include.indexOf(type) >= 0 ? {
      enableAddData: true,
      addInfo: this.getAddInfo(type),
    } : {};
    // return disabledAddDataKeys.indexOf(type) >= 0 ? {} : {
    //   enableAddData: true,
    //   addInfo: this.getAddInfo(type),
    // }
  }

  @action
  needHideLabel = (item: FormItemProps, key: string, isMobile?: boolean) => {
    if (!isMobile) return false;
    if (item.browserBean || (key === 'upload')) return !!item.common; // 浏览框或者附件上传类型，公共关联字段需要隐藏label，特殊布局
    return false;
  }

  @action
  hasGroupList = (id: string) => this.groupList?.find((d) => d.layoutIds?.indexOf(id) > -1)
  // init 初始化，存在使用者单独调用场景，不可异步
  @action
  init = (allDatas: CardDetailFormAllDatas) => {
    let {
      layout, items, data, hoverEdit, isMobile, module, saveDataParams, dataParams,
      customGrouping, groups, cardDetailDialogParams, globalReadOnly,
      defaultValidate, defaultValidateGroupIds, prohibitRealTimeSaving, afterUpdateRelevanceDatas,
      groupList, openGroupList, requestHeaderParams, allowRequestHeaderParamsTrans, requestHeaderParamsFields
    } = allDatas;
    this.groupList = groupList?.filter((data) => data?.layoutIds?.length > 0);
    let groupListItems: string[] = [];
    /* 分类 */
    const enableGroupList = isMobile && openGroupList && groupList && groupList?.length > 0;
    if (enableGroupList) {
      this.formStores['groupList'] = new FormStore();
    }

    this.prohibitRealTimeSaving = prohibitRealTimeSaving;
    if (!cardDetailDialogParams) {
      console.error('未配置打开事项卡片附加的参数(cardDetailDialogParams)')
    }
    if (customGrouping && groups) {
      this.groups = groups; // 分组仅用于处理展示分组标题需求
      this.customGrouping = customGrouping;
    }
    if (afterUpdateRelevanceDatas) this.afterUpdateRelevanceDatas = afterUpdateRelevanceDatas;
    const layoutA: FormLayoutType[] = [], layoutB: FormLayoutType[] = [], layoutC: FormLayoutType[] = [],
      layoutD: FormLayoutType[] = [], tags: FormLayoutType = [], layoutE: FormLayoutType[] = [], layoutGroupList: FormLayoutType[] = [];
    const dataA: FormDatas = {}, dataB: FormDatas = {}, dataC: FormDatas = {}, dataD: FormDatas = {}, dataE: FormDatas = {}, dataGroupList: FormDatas = {};
    const itemsA: FormItemProps = {}, itemsB: FormItemProps = {}, itemsC: FormItemProps = {}, itemsD: FormItemProps = {}, itemsE: FormItemProps = {}, itemsGroupList: FormItemProps = {};
    const relevanceDatas: FormDatas = {}, relevanceDatasUrl: RelevanceDatasUrl = {};
    this.items = items;
    const otherConfig = { saveDataParams, dataParams, isMobile, cardDetailDialogParams, hoverEdit, prohibitRealTimeSaving,globalReadOnly, requestHeaderParams, allowRequestHeaderParamsTrans, requestHeaderParamsFields };
    /* D区，必填字段配置。Tag区必填的字段必须在对应区(A/B/C/E/F),执行一次doChangeLayout */
    const tagRequired: AnyObj[] = [];

    layout.forEach((row) => {
      let tempA: FormLayoutType = [], tempB: FormLayoutType = [], tempC: FormLayoutType = [],
        tempD: FormLayoutType = [], tempE: FormLayoutType = [], tempGroupList: FormLayoutType = [];
      row.forEach((col) => {
        const groupListId = this.hasGroupList(col.id)?.id;
        const _hasGroupList = enableGroupList && groupListId;
        if (col.groupId === 'normal') {
          let hideLabel = false, disabledModule = false; // disabledModule: 标记后台管理中心->应用设置 是否禁用当前模块
          if (col.items) col.items.forEach((key) => {
            items[key] = this.changeInitItems(key, items[key], col, module || this.module, otherConfig);
            if (!hideLabel) hideLabel = this.needHideLabel(items[key], key, isMobile);
            if (!disabledModule) disabledModule = isDisabledModule(items[key]);
            if (_hasGroupList) {
              data[key] && (dataGroupList[key] = this.secretLevelStore.filterData('groupList', key, items[key], data[key]));
              itemsGroupList[key] = items[key];
              groupListItems?.push(key);
            } else {
              data[key] && (dataA[key] = this.secretLevelStore.filterData('normal', key, items[key], data[key]));
              itemsA[key] = items[key];
            }
            if (items[key]?.common) {
              data[key] && (relevanceDatas[key] = data[key]);
              if (items[key].otherParams?.addUrl) {
                relevanceDatasUrl[key] = {
                  url: items[key].otherParams?.addUrl,
                  type: items[key].browserBean?.type,
                };
              }
            }
          });
          if (hideLabel) {
            col.carddetailform_label = col.label;
            col.carddetailform_labelSpan = col.labelSpan;
            col.label = '';
            col.labelSpan = 0;
          }
          if (disabledModule) {
            col.carddetailform_hide = col.hide; // 原本配置隐藏的字段，标记一下
            col.hide = true;
          }
          // 分类的字段单独处理
          if (_hasGroupList) {
            tempGroupList.push({ ...col, groupId: groupListId });
          } else {
            tempA.push(col);
          }
        } else if (col.groupId === 'alwaysShow') {
          let hideLabel = false, disabledModule = false; // disabledModule: 标记后台管理中心->应用设置 是否禁用当前模块
          if (col.items) col.items.forEach((key) => {
            items[key] = this.changeInitItems(key, items[key], col, module || this.module, otherConfig);
            if (!hideLabel) hideLabel = this.needHideLabel(items[key], key, isMobile);
            if (!disabledModule) disabledModule = isDisabledModule(items[key]);
            if (_hasGroupList) {
              data[key] && (dataGroupList[key] = this.secretLevelStore.filterData('groupList', key, items[key], data[key]));
              itemsGroupList[key] = items[key];
              groupListItems?.push(key);
            } else {
              data[key] && (dataB[key] = this.secretLevelStore.filterData('alwaysShow', key, items[key], data[key]));
              itemsB[key] = items[key];
            }
            if (items[key]?.common) {
              data[key] && (relevanceDatas[key] = data[key]);
              if (items[key].otherParams?.addUrl) {
                relevanceDatasUrl[key] = {
                  url: items[key].otherParams?.addUrl,
                  type: items[key].browserBean?.type,
                };
              }
            }
          });
          if (hideLabel) {
            col.carddetailform_label = col.label;
            col.carddetailform_labelSpan = col.labelSpan;
            col.label = '';
            col.labelSpan = 0;
          }
          if (disabledModule) {
            col.carddetailform_hide = col.hide; // 原本的hide参数备份一下
            col.hide = true;
          }
          // 分类的字段单独处理
          if (_hasGroupList) {
            tempGroupList.push({ ...col, groupId: groupListId });
          } else {
            tempB.push(col);
          }
        } else if (col.groupId === 'collapsible') {
          let hideLabel = false, disabledModule = false; // disabledModule: 标记后台管理中心->应用设置 是否禁用当前模块
          if (col.items) col.items.forEach((key) => {
            items[key] = this.changeInitItems(key, items[key], col, module || this.module, otherConfig);
            if (!hideLabel) hideLabel = this.needHideLabel(items[key], key, isMobile);
            if (!disabledModule) disabledModule = isDisabledModule(items[key]);
            if (_hasGroupList) {
              data[key] && (dataGroupList[key] = this.secretLevelStore.filterData('groupList', key, items[key], data[key]));
              itemsGroupList[key] = items[key];
              groupListItems?.push(key);
            } else {
              data[key] && (dataC[key] = this.secretLevelStore.filterData('collapsible', key, items[key], data[key]));
              itemsC[key] = items[key];
            }
            if (items[key]?.common) {
              data[key] && (relevanceDatas[key] = data[key]);
              if (items[key].otherParams?.addUrl) {
                relevanceDatasUrl[key] = {
                  url: items[key].otherParams?.addUrl,
                  type: items[key].browserBean?.type,
                };
              }
            }
          });
          if (hideLabel) {
            col.carddetailform_label = col.label;
            col.carddetailform_labelSpan = col.labelSpan;
            col.label = '';
            col.labelSpan = 0;
          }
          if (disabledModule) {
            col.carddetailform_hide = col.hide; // 原本的hide参数备份一下
            col.hide = true;
          }
          // 分类的字段单独处理
            if (_hasGroupList) {
            tempGroupList.push({ ...col, groupId: groupListId });
          } else {
            tempC.push(col);
          }
        } else if (col.groupId === 'follow') {
          let hideLabel = false, disabledModule = false; // disabledModule: 标记后台管理中心->应用设置 是否禁用当前模块
          if (col.items) col.items.forEach((key) => {
            items[key] = this.changeInitItems(key, items[key], col, module || this.module, otherConfig);
            if (!hideLabel) hideLabel = this.needHideLabel(items[key], key, isMobile);
            if (!disabledModule) disabledModule = isDisabledModule(items[key]);
            if (_hasGroupList) {
              data[key] && (dataGroupList[key] = this.secretLevelStore.filterData('groupList', key, items[key], data[key]));
              itemsGroupList[key] = items[key];
              groupListItems?.push(key);
            } else {
              data[key] && (dataE[key] = this.secretLevelStore.filterData('follow', key, items[key], data[key]));
              itemsE[key] = items[key];
            }
            if (items[key]?.common) {
              data[key] && (relevanceDatas[key] = data[key]);
              if (items[key].otherParams?.addUrl) {
                relevanceDatasUrl[key] = {
                  url: items[key].otherParams?.addUrl,
                  type: items[key].browserBean?.type,
                };
              }
            }
          });
          if (hideLabel) {
            col.carddetailform_label = col.label;
            col.carddetailform_labelSpan = col.labelSpan;
            col.label = '';
            col.labelSpan = 0;
          }
          if (disabledModule) {
            col.carddetailform_hide = col.hide; // 原本的hide参数备份一下
            col.hide = true;
          }
          // 分类的字段单独处理
          if (_hasGroupList) {
            tempGroupList.push({ ...col, groupId: groupListId });
          } else {
            tempE.push(col);
          }
        } else {
          if (col.hide) this.innerVisible = false; // 有一行为默认隐藏字段，则内部展开面板模式是收起的
          let hideLabel = false, disabledModule = false; // disabledModule: 标记后台管理中心->应用设置 是否禁用当前模块
          if (col.items) {
            col.items.forEach((key) => {
              if (_hasGroupList) {
                data[key] && (dataD[key] = this.secretLevelStore.filterData('groupList', key, items[key], data[key]));
                groupListItems?.push(key);
              } else {
                data[key] && (dataD[key] = this.secretLevelStore.filterData('tags', key, items[key], data[key]));
              }
              if (items[key]) {
                items[key] = this.changeInitItems(key, items[key], col, module || this.module, otherConfig);
                if (!hideLabel) hideLabel = this.needHideLabel(items[key], key, isMobile);
                if (!disabledModule) disabledModule = isDisabledModule(items[key]);
                if (_hasGroupList) {
                  itemsGroupList[key] = { ...items[key] };
                } else {
                  itemsD[key] = {
                    ...items[key],
                  };
                }
                if (items[key]?.common) {
                  data[key] && (relevanceDatas[key] = data[key]);
                  items[key]?.required && (tagRequired.push({
                    key,
                    groupId: items[key].showGroupId || items[key].otherParams?.showGroupId || 'normal',
                  }));
                  if (items[key].otherParams?.addUrl) {
                    relevanceDatasUrl[key] = {
                      url: items[key].otherParams?.addUrl,
                      type: items[key].browserBean?.type,
                    };
                  }
                }
              }
            });
          }
          if (hideLabel) {
            col.carddetailform_label = col.label;
            col.carddetailform_labelSpan = col.labelSpan;
            col.label = '';
            col.labelSpan = 0;
          }
          if (disabledModule) {
            col.carddetailform_hide = col.hide; // 原本的hide参数备份一下
            col.hide = true;
          }
          // 分类的字段单独处理
          if (_hasGroupList) {
            tempGroupList.push({ ...col, groupId: groupListId });
          } else {
            tempD.push(col);
            tags.push(col);
          }
        }
      });
      if (tempA.length > 0) layoutA.push(tempA);
      if (tempB.length > 0) layoutB.push(tempB);
      if (tempC.length > 0) layoutC.push(tempC);
      if (tempD.length > 0) layoutD.push(tempD);
      if (tempE.length > 0) layoutE.push(tempE);
      if (tempGroupList.length > 0) layoutGroupList.push(tempGroupList);
    });
    this.items = items;
    this.layout = layout;
    this.formStores.normal.initForm({ layout: layoutA, items: { ...itemsA, ...deepClone(itemsD) }, data: dataA, groups: [], hoverEdit });
    this.formStores.alwaysShow.initForm({ layout: layoutB, items: { ...itemsB, ...deepClone(itemsD) }, data: dataB, groups: [], hoverEdit });
    this.formStores.collapsible.initForm({ layout: layoutC, items: { ...itemsC, ...deepClone(itemsD) }, data: dataC, groups: [], hoverEdit });
    this.formStores.follow.initForm({ layout: layoutE, items: { ...itemsE, ...deepClone(itemsD) }, data: dataE, groups: [], hoverEdit });
    enableGroupList && this.formStores.groupList?.initForm({ layout: layoutGroupList, items: { ...itemsGroupList, ...deepClone(itemsD) }, data: dataGroupList, groups: this.groupLists, hoverEdit});
    this.formStores.tags.initForm({ layout: layoutD, items: itemsD, data: dataD, groups: [] });
    this.tags = tags;
    this.groupListItems = groupListItems;
    this.initValidate(defaultValidate, defaultValidateGroupIds);
    this.relevanceDatas = relevanceDatas;
    this.initRelevanceDatas = { ...relevanceDatas };
    this.relevanceDatasUrl = relevanceDatasUrl;
    if (isMobile === false) { // pc端更新D区布局 --- 适用于配置在D区的非公共关联字段
      tagRequired.forEach((tagConfig: AnyObj) => {
        this.doChangeLayout(tagConfig.groupId, {
          [tagConfig.key]: [],
        }, true)
      })
      this.updateDatas(dataD);
    }
    this.hasInit = true;
  }

  @action
  hideTag = (tagId: string) => {
    for (let index = 0; index < this.tags.length; index++) {
      if (this.tags[index].id === tagId) {
        this.tags[index] = {
          ...this.tags[index],
          hide: true,
        };
        this.afterHideTag?.(tagId);
        break;
      }
    }
  }

  @action
  setVisible = (visible: boolean) => {
    this.visible = visible;
  }

  @action
  getFormDatas = () => {
    let resDatas = {};
    Object.keys(this.formStores).forEach((formKey) => {
      let result = this.formStores[formKey].getFormDatas();
      Object.keys(result).forEach((resKey) => {
        if (isEmpty(result[resKey])) {
          delete result[resKey]; // 删除空数据
        }
      })
      resDatas = {
        ...resDatas,
        ...result,
      }
    });
    return resDatas;
  }

  /**
   * updateDatasNotReFormat 更新表单数据，且不包含重新排版功能(即除D区外的字段使用)
   * @param groupId 分组id
   * @param data 表单数据
   */
  @action
  updateDatasNotReFormat = (groupId: string, data: FormDatas) => {
    this.formStores[groupId]?.updateDatas(data);
  }

  /**
   * updateDatasMobileInnerInit 内部移动端表单公共关联数据初始化更新 
   * @param groupId
   * @param data 
   */
  @action
  updateDatasMobileInnerInit = (groupId: string, data: FormDatas) => getUtils().then((defaultModule) => defaultModule.updateDatasMobileInnerInit(this, groupId, data))

  /**
   * updateDatas 更新表单数据，且重新排版(D区字段，pc端)
   * @param data 表单数据 
   */
  @action
  updateDatas = (data: FormDatas) => getUtils().then((defaultModule) => defaultModule.updateDatas(this, data))

  @action
  getLayoutConfig = (dataKey: string) => {
    let col = null;
    for (let i = 0; i < this.tags.length && !col; i++) {
      if (this.tags[i].items.indexOf(dataKey) >= 0) {
        col = this.tags[i];
        break;
      }
    }
    return col;
  }
  /**
   * doChangeLayout 关联字段布局
   * @param type 
   * @param datas 
   */
  @action
  doChangeLayout = (type: string, datas: FormDatas, mustChangeLayout?: boolean) => getUtils().then((defaultModule) => defaultModule.doChangeLayout(this, type, datas, mustChangeLayout))
  @action
  validate = () => {
    Object.keys(this.formStores).forEach((formKey) => {
      this.validateByGroupId(formKey);
    });
  }
  /**
   * 按照分组校验
   * @param groupId 分组id
   * @returns 
   */
  @action
  validateByGroupId = (groupId: string, customRules?: AnyObj) => getUtils().then((defaultModule) => defaultModule.validateByGroupId(this, groupId, customRules))
  /**
   * 初始化设置校验规则及默认校验(可配置)
   * @param defaultValidate 
   */
  @action
  initValidate = (defaultValidate?: boolean, defaultValidateGroupIds?: Array<string>) => getUtils().then((defaultModule) => defaultModule.initValidate(this, defaultValidate, defaultValidateGroupIds))
  @action
  reset = () => {
    this.pass = true;
    Object.keys(this.formStores).forEach((formKey) => {
      this.formStores[formKey].resetForm();
    });
    this.relevanceDatas = {};
  }
  @action
  request = (url?: string, method?: Method, params?: any) => {
    const options: RequestOptionConfig = {
      url,
      method,
      data: params,
      ...this.requestHeaderParams,
    }
    const doRequest = this.fetchData || request;
    return doRequest(options) as RequestPromise;
  }
  @action
  changeDataIds = (datas: RelevancesData) => {
    let result: RelevancesData = {};
    Object.keys(datas).forEach((key) => {
      const dataKey = this.commonRelations.get(key);
      if (Object.prototype.toString.call(datas[key]) === '[object Array]') {
        result[dataKey] = datas[key]?.map((data) => {
          return {
            ...data,
            carddetailform_id: data.id || data.targetId,
            id: data.targetId, // 回显数据的targetId相当于浏览框数据的id
          }
        }) as unknown as SingleRelevancesData[];
      }
    })
    return result;
  }
  @action
  updateRelevances = (datas: RelevancesData) => {
    return getUtils().then((defaultModule) => defaultModule.updateRelevances(this, datas));
  }

  /**
   * 通过items获取当前layout的数据
   * @param items的key 
   * @returns layout
   */
  @action
  getLayoutFromItemKey = (itemKey: string, layout: FormLayoutType[]) => {
    let nowLayout = null;
    if (layout) {
      const rowLen = layout.length;
      for (let rowIndex = 0; rowIndex < rowLen; rowIndex++) {
        if (nowLayout) break;
        const colLen = layout[rowIndex].length;
        for (let colIndex = 0; colIndex < colLen; colIndex++) {
          if (nowLayout) break;
          if (layout[rowIndex][colIndex].items?.indexOf(itemKey) >= 0) {
            nowLayout = layout[rowIndex][colIndex];
          }
        }
      }
    }
    return nowLayout;
  }

  @action
  getRelevances = (params?: any, queryUrl?: string, method?: Method, otherParams?: AnyObj) => {
    return getUtils().then((defaultModule) => defaultModule.getRelevances(this, params, queryUrl, method, otherParams));
  }
  @action
  initForm = (url?: string, method?: Method, params?: any) => {
    return this.request(url, method, params);
  }
  @action
  save = (params?: any, addUrl?: string, config?: any) => {
    return getUtils().then((defaultModule) => defaultModule.save(this, params, addUrl, config));
  }
  @action
  transValue = (datas: any, temp: string | boolean) => {
    const targetModule = temp as string;
    return datas.map((data: any) => {
      let tempData = {
        ...this.saveDataParams,
        targetId: data.targetId || data.id, // 修复从浏览框选中数据，无targetId的问题
        targetName: data.targetName || data.content || data.name,
        targetModule,
        sort: data.sort,
      }
      if (temp === 'biaoge') { // 关联表单浏览框单独处理(筛选条件)
        tempData = {
          ...(this.dataParams || {}), // 兼容没有objectId的情况
          ...tempData,
          type: data.detailType,
          detailId: data.detailId,
          detailName: data.detailContent,
          sort: data.sort,
        }
      }
      return tempData;
    })
  }
  @action
  doSave = (config?: any) => {
    return getUtils().then((defaultModule) => defaultModule.doSave(this, config));
  }
  @action
  afterRequest = (initParams: CardDetailFormAllDatas, dataParams?: any, otherParams?: AnyObj) => {
    return getUtils().then((defaultModule) => defaultModule.afterRequest(this, initParams, dataParams, otherParams));
  }
  @action
  doRequest = (url: string, isMobile: boolean, props: CardDetailFormProps & MCardDetailFormProps) => {
    return getUtils().then((defaultModule) => defaultModule.doRequest(this, url, isMobile, props));
  }
  /**
   * judgeAssociateSelf
   * @param objectId 事项卡片自身id
   */
  @action
  judgeAssociateSelf = (objectId: string, result: Array<any>) => {
    if (!objectId) {
      console.error('未设置saveDataParams或saveDataParams未设置objectId');
      return result;
    }
    const resIds = new Set(result.map((data) => data.targetId || data.id));
    if (resIds.has(objectId)) {
      message({
        type: 'info',
        content: getLabel('72533', '无法关联自己'),
      });
      return result.filter((res) => !(res.targetId === objectId || res.id === objectId)); // 过滤掉关联自身的数据
    }
    return result;
  }
  /**
   * onCommonFieldsChange: 公共关联字段值改变，触发保存关联关系
   * @param value 当前字段值
   * @param otherParams 
   * @param props 
   * @returns 
   */
  @action
  onCommonFieldsChange = (value?: FormDatas, otherParams?: any, props?: CardDetailFormProps & MCardDetailFormProps) => {
    if (!props) return;
    if (this.lockSave) return;
    const { saveParams = this.saveParams, saveDataParams = this.saveDataParams, prohibitRealTimeSaving } = props;
    if (otherParams && otherParams.itemProps && otherParams.itemProps.common && value) {
      const keys = Object.keys(value);
      if (prohibitRealTimeSaving) {
        this.setState({
          relevanceDatas: {
            ...toJS(this.relevanceDatas),
            ...value,
          }
        })
        return;
      }
      if (keys && keys[0] && otherParams.itemProps.itemType === 'BROWSER') { // 处理公共关联浏览框
        const targetModule = commonRelationsRules[otherParams.itemProps.browserBean?.type] || keys[0];
        const result = this.transValue(value ? value[keys[0]] : [], targetModule) || [];
        // 方案1.0: 新增、删除、更新统一走update方法，非公共关联浏览框字段及配置了自定义addUrl、deleteUrl的除外
        if (this.newVersion && !(otherParams.itemProps.otherParams?.addUrl || otherParams.itemProps.otherParams?.deleteUrl)) {
          return this.update({ [keys[0]]: value[keys[0]] }, otherParams.preValue, {
            saveDataParams,
            saveParams,
            targetModule
          });
        }
        // 初始方案
        let diffNewIds = new Set(); // 新增数据id
        if (otherParams && otherParams.preValue) {
          const nowOldDatas = otherParams.preValue;
          if (nowOldDatas) {
            const olddatas = nowOldDatas as BrowserValueType; // 浏览框更新前数据
            const newdatas = value[keys[0]] as BrowserValueType; // 浏览框当前数据
            const newdataIds = new Set(newdatas.map((data) => data.targetId || data.id));
            const olddataIds = new Set(olddatas.map((data) => data.targetId || data.id));

            const diffIds = new Set(Array.from(olddataIds).filter((id) => !newdataIds.has(id))); // 前后数据不同，删除的数据
            diffNewIds = new Set(Array.from(newdataIds).filter((id) => !olddataIds.has(id))); // 新增的数据
            if (diffIds.size > 0) {
              const datas = olddatas.filter((data) => diffIds.has(data.targetId || data.id));
              if (otherParams.itemProps.browserBean
                && (['common', 'my', 'mainlineBrowser'].indexOf(otherParams.itemProps.browserBean?.type) >= 0)
                && otherParams.itemProps?.otherParams?.deleteUrl) { // 特殊浏览框(公共标签、我的标签、关联项目)且设置了deleteUrl，走自定义删除逻辑
                this.doRelevancesDeselete({
                  id: datas ? datas.map((da) => da.id).join() : '',
                  projectIds: datas ? datas.map((da) => da.targetId || da.id) : [],
                }, otherParams.itemProps, {
                  hideTip: diffNewIds.size > 0, // 有新增的数据，删除不提示，避免出现多个message信息重叠的情况(同et)
                });
              } else {
                this.onRelevancesBatchDelete(this.transValue(datas, commonRelationsRules[otherParams.itemProps.browserBean?.type] || keys[0]), {
                  hideTip: diffNewIds.size > 0, // 有新增的数据，删除不提示，避免出现多个message信息重叠的情况(同et)
                  key: keys[0],
                });
              }
            }
          }
        }
        const itemOtherParams = otherParams.itemProps.otherParams;
        let res = diffNewIds.size > 0 ? result.filter((data: { targetId: any; id: any; }) => diffNewIds.has(data.targetId || data.id)) : result;
        if (res && res.length > 0) {
          res = this.judgeAssociateSelf(saveDataParams?.objectId, res); // 排查是否关联自身
          const nowVal = value[keys[0]] as BrowserValueType;
          const resVal = nowVal?.filter((data) => !(data.targetId === saveDataParams?.objectId || data.id === saveDataParams?.objectId)); // 过滤掉关联自身的数据
          if (res.length === 0) {
            return {
              forbidRefresh: false,
              value: {
                ...value,
                [`${keys}`]: resVal
              },
            }; // 禁止更新form数据, 目前仅处理关联自身的情况
          }
          let url = '', params = {
            ...saveParams,
            relevances: res,
          };
          if (itemOtherParams && itemOtherParams.addUrl) {
            url = itemOtherParams.addUrl || '';
            if (otherParams.itemProps.browserBean?.type === 'mainlineBrowser') {
              params = {
                projectIds: res.map((re: { targetId: any; }) => re.targetId),
                entityId: saveDataParams?.objectId,
                module: saveDataParams?.objectModule,
              }
            } else {
              params = {
                id: res.map((re: { targetId: any; }) => re.targetId).join(),
                entityId: saveDataParams?.objectId,
                module: saveDataParams?.objectModule,
                type: otherParams.itemProps.browserBean?.type === 'common' ? 0 : 1,
              }
            }
          }
          this.save(params, url);
          return {
            forbidRefresh: false,
            value: {
              ...value,
              [`${keys}`]: resVal,
            },
          }
        }
      }
    }
    return false;
  }

  @action
  update = (formValue: FormDatas, preValue: AnyObj[], otherParams: AnyObj) => {
    const key = Object.keys(formValue)?.[0];
    let originValue = formValue[key] as AnyObj[];
    originValue = this.judgeAssociateSelf(otherParams?.saveDataParams?.objectId, originValue); // 排查是否关联自身
    let value = originValue;
    if (value && key) {
      const datas = updateSortArr(this.relevanceDatas?.[key] as AnyObj[], value);
      this.batchSave(preValue, value, otherParams, datas);
      this.setState({
        relevanceDatas: {
          ...this.relevanceDatas,
          [key]: datas?.datas,
        }
      })
      return {
        forbidRefresh: false,
        value: {
          ...formValue,
          [`${key}`]: datas?.datas as AnyObj,
        },
      }
    }
    return false;
  }

  @action
  batchSave = (preValue: AnyObj[], value: AnyObj[], otherParams: AnyObj, sortDatas?: AnyObj) => {
    return getUtils().then((defaultModule) => defaultModule.batchSave(this, preValue, value, otherParams, sortDatas));
  }
}

export type CardDetailFormStoreType = Pick<CardDetailFormStore, keyof CardDetailFormStore>;
