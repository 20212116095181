import React, { ReactNode } from "react";
import { getLabel } from '@weapp/utils';
import { DialogHeaderProps, DialogHeaderRef } from "../types";
import Icon, { IconNames } from '../../icon/index';
import { Title, Menu, MenuItemData, Button } from '../../../lib';

const DialogHeader = React.forwardRef<DialogHeaderRef, DialogHeaderProps>(
  (props, ref) => {
    const { title, buttons, buttonsLeftContent, getTitle, icon, iconBgcolor, headerStyle,
      leftCol, rightCol, customRenderLeft, customRenderRight,
      closable, closeIcon, prefixCls, scale, innerScale, getTitleLabel,
      menus, menuProps, menuValue, hideUnderLine, onMenuChange, doScale, noIcon, onClose, setInnerScale, customRenderIcon, customRenderDialogTitle, windowOpenUrl,
      iconBorderColor, iconStyle, needProgress, progressProps, titleTipType, enableAdaptiveLayout, customStyleLeft, customStyleRight
    } = props;

    function doClose(e: any) {
      e.stopPropagation && e.stopPropagation();
      onClose?.();
      const initScaleTimeout = window.setTimeout(() => {
        setInnerScale?.(false);
        if (initScaleTimeout) window.clearTimeout(initScaleTimeout);
      }, 500);
    }

    function onChange(value: string, item: object, e: React.MouseEvent) {
      if (value === 'newWindowOpen') window.open(windowOpenUrl)
      // @ts-ignore
      if (value === 'max') doScale?.(e as unknown as HTMLElement)
    }

    const btn = [];
    buttons && btn.push(buttons);

    const menuData = [
      {
        id: 'max',
        content: innerScale ? getLabel('123893', '还原') : getLabel('123894', '最大化'),
        icon: innerScale ? 'Icon-reduction02' : 'Icon-Maximize-1'
      },
      {
        id: 'newWindowOpen',
        content: getLabel('123892', '新窗口打开'),
        icon: 'Icon-New-window'
      },
    ]

    if (scale) {
      btn.push(windowOpenUrl ? <div className={`${prefixCls}-tool`} key="scale">
        <Menu weId={`${props.weId || ''}_sju25b`}
          data={menuData}
          type="select"
          selectType="normal"
          customSelectContent={
            <div className={`${prefixCls}-tool-scale`}>
              <Icon weId={`${props.weId || ''}_hhnki6`} name={`${innerScale ? 'Icon-narrow01' : 'Icon-enlarge01'}` as IconNames} size="s" />
            </div>
          }
          overlayClassName={`${prefixCls}-menureset`}
          triggerProps={{
            popupPlacement: 'bottomRight'
          }}
          onChange={onChange}
        />
      </div> :
        <div className={`${prefixCls}-icon`} key="scale" onClick={doScale}>
          <Icon weId={`${props.weId || ''}_d9mlmv`} name={`${innerScale ? 'Icon-narrow01' : 'Icon-enlarge01'}` as IconNames} size="s" />
        </div>
      );
    }
    if (closable) {
      btn.push(<div key="close" className={`${prefixCls}-icon ${prefixCls}-closeIcon`} onClick={doClose}>
        {closeIcon || <Icon weId={`${props.weId || ''}_aza7qb`} name='Icon-error01' size={'s'} />}
      </div>)
    }
    return (
      <Title weId={`${props.weId || ''}_pnhv5o`}
        title={title}
        getTitle={getTitle}
        getTitleLabel={getTitleLabel}
        icon={icon}
        customRenderIcon={customRenderIcon}
        iconBgcolor={iconBgcolor}
        iconBorderColor={iconBorderColor}
        iconStyle={iconStyle}
        buttonsLeftContent={buttonsLeftContent}
        buttons={btn}
        leftCol={leftCol}
        rightCol={rightCol}
        customRenderLeft={customRenderLeft}
        customRenderRight={customRenderRight}
        menus={menus}
        menuValue={menuValue}
        onMenuChange={onMenuChange}
        menuProps={menuProps}
        noIcon={noIcon}
        inDialog
        hideUnderLine={hideUnderLine}
        needProgress={needProgress}
        progressProps={progressProps}
        titleTipType={titleTipType}
        enableAdaptiveLayout={enableAdaptiveLayout}
        customStyleLeft={customStyleLeft}
        customStyleRight={customStyleRight}
        style={headerStyle}
        customRender={customRenderDialogTitle}
      />
    )
  }
)

export default DialogHeader;
