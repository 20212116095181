import Loadable from '../../react-loadable';
import { MenuContentType, MMenuType } from './types';
import { mMenuClsPrefix } from '../../constants/index.m';

const MMenu = Loadable({ name: 'MMenu', loader: () => import(
  /* webpackChunkName: "m_menu" */
  './mb/index') }) as MMenuType;

const MMenuContent = Loadable({
  name: 'MMenuContent', loader: () => import(
    /* webpackChunkName: "m_menu" */
    './public/MenuContent'
  )
}) as MenuContentType;

MMenu.defaultProps = {
  prefixCls: mMenuClsPrefix,
};

MMenu.MenuContent = MMenuContent;

export type { MMenuProps, MenuItemData as MMenuItemData } from './types';

export default MMenu;
