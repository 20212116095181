
// 当前页面是否是打印模式
let printMode = false;

// 开启打印模式
export function enablePrintMode() {
  printMode = true;
}

// 关闭打印模式
export function disabledPrintMode() {
  printMode = false;
}

// 判断当前是否开启打印模式
export function isPrintMode () {
  return printMode;
}

/** 匹配独立部署后 ebuilder module */
export function extractEbuilderAndNumber(module: string) {
  const ebRegex = /ebuilder\d+/;
  const match = module.match(ebRegex);
  if(match) {
    const regex = /\d+/;
    const appId = module.match(regex)?.[0];
    let newModule = appId ? module.replace(appId, '') : module;
    return { module: newModule, appId };
  } else {
    return { module, appId: '' };
  }
}

const printUtils = {
  isPrintMode,
  enablePrintMode,
  disabledPrintMode,
}

export type PrintUtils = typeof printUtils;

export { printUtils };
