import Loadable, { LoadingType } from '../../react-loadable';
import { RadioType, RadioProps } from './types';
import { mRadioClsPrefix } from '../../constants/index.m';
import { USESKELETON } from '../../constants/index';

const MRadioSkeleton: LoadingType<RadioProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MRadio = Loadable({
  name: 'MRadio',
  loading: MRadioSkeleton,
  loader: () => import(
    /* webpackChunkName: "ui_m_common_form" */
    './Radio')
}) as RadioType;

MRadio.MRadioSkeleton = MRadioSkeleton;

MRadio.defaultProps = {
  prefixCls: mRadioClsPrefix,
  isHoldRight: false,
  canReversedChoose: true,
  isMobile: true
};

export type {
  RadioProps as MRadioProps,
  RadioOptionProps as MRadioOptionProps,
  ValueType as MRadioValueType,
  OptionData as MRadioOptionType,
  OptionsType as MRadioDataType,
  RadioEvent as MRadioEvent
} from './types';

export default MRadio;
