import { mShareClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { mshareListMiddleware } from './utils/middleware';
import { MShareType, MShareListType } from './types';

const MShare = Loadable({
  name: 'MShare', propsMiddleware: mshareListMiddleware, loader: () => import(
    /* webpackChunkName: 'm_share' */
    './MShare')
}) as MShareType;

MShare.defaultProps = {
  prefixCls: mShareClsPrefix,
  cacheAuthCondition: true,
};

const MShareList = Loadable({
  name: 'MShareList', propsMiddleware: mshareListMiddleware, loader: () => import(
    /* webpackChunkName: 'm_share' */
    './MShareList'
  )
}) as MShareListType;

MShare.MShareList = MShareList;

export { default as MShareListStore } from './store/MStore';


export type { MShareProps, MShareType, MShareListProps, MOperatesType as MShareOperatesType } from './types';

export default MShare;
