import Loadable from '../../react-loadable';
import { StepsType } from './types';
import { stepsClsPrefix } from '../../constants/index';


const Steps = Loadable({
  name: 'Steps',
  loader: () => import(
    /* webpackChunkName: "ui_common" */
    './Steps'
  )
}) as StepsType;

Steps.defaultProps = {
  ...Steps.defaultProps,
  prefixCls: stepsClsPrefix,
  isMobile: false,
  labelPlacement: "vertical"
};

export type { StepProps, StepBaseProps } from './types';


export default Steps;
