import Loadable from "../../react-loadable";
import { pickerViewClsPrefix } from "../../constants/index"
import { PickerViewType } from "./types"

const PickerView = Loadable({
  name: 'PickerView', loader: () => import(
    /* webpackChunkName: "ui_common_date" */
    './PickerView')
}) as PickerViewType;

PickerView.defaultProps = {
  prefixCls: pickerViewClsPrefix,
  columnHeight: 210,
  itemHeight: 30,
};

export type { PickerViewProps, optionType as PickerViewOptionType, optionGroupsType as PickerViewOptionGroupsType, valueType as PickerViewValueType } from "./types";
export default PickerView;
