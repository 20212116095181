import Loadable from "../../react-loadable";
import { alertClsPrefix } from '../../constants/index';
import { AlertType } from "./types";

const Alert = Loadable({
  name: 'Alert', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './Alert')
}) as AlertType;

Alert.defaultProps = {
  prefixCls: alertClsPrefix,
};

export type {
  AlertProps,
} from "./types";
export default Alert;

