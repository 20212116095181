import axios from 'axios';
import refreshNotice, { getDialogVisible } from "./RefreshNotice";
import { blackList, disableRefreshNoticeKey, neverNoticeKey, versionCacheKey } from "./constants";
import { appInfo, baseConfigInstance, ua } from '@weapp/utils';

let loopInterval = 15 * 60 * 1000; // 轮询间隔 5 分钟
let requestLock: boolean = false; // 轮询请求锁，确保同时间只会触发一次请求
let timmer: NodeJS.Timeout | null = null; // 轮询定时器

const publicUrl = appInfo('@weapp/ui').publicUrl;
// 使用 IM 的 websocket 通信监听项目构建更新
// corsImport(`@weapp/em`).then(em => {
//   em.registerImNotice({
//     cmd: 15422, // 支持15412, 15413, 15414, 15421, 15422
//     msgType: 227, // 唯一值, 用于调用对应的 callback,
//     isHide: true, // 是否在首次加载的时候需要隐藏em，默认为false(不隐藏)
//     callback: function (message) {
//       // 通知回调, message为接收到的消息内容
//       refreshNotice(message ? message.msg : message);
//     },
//     success: function (res) {
//       // 事件注册成功回调
//       console.log(prefix, res);
//     },
//     fail: function (error) { 
//       // 事件注册失败回调
//       console.log(prefix, error);
//     }
//   });
// });

function checkUpdateModule (oldVersions: Record<string, string>, newVersions: Record<string, string>) {
  let updateModules: string[] = [];
  Object.keys(oldVersions).forEach(htmlPath => {
    // 只对比上次已经记录的版本，新增模块不处理
    if (htmlPath in newVersions && oldVersions[htmlPath] !== newVersions[htmlPath]) {
      // 从 build 路径中解析出模块标识
      const matched = htmlPath.match(/^\/build\/[^\/]+/);
      if (matched && matched[0]) {
        updateModules.push(`weapp-${matched[0].replace(/^\/build\//, '')}`);
      }
    }
  });
  return updateModules;
}

// 取消轮询
function stopLoop () {
  if (timmer) { clearInterval(timmer); }
}

function startLoop () {
  // 获取配置失败后 默认 开启轮询
  loop();
  // 开启轮询
  timmer = setInterval(loop, loopInterval);  
}

// 轮询请求
function loop () {
  if (window.localStorage) {
    const disabledRefreshNoticeExpiration = Number(window.localStorage.getItem(disableRefreshNoticeKey));
    if (disabledRefreshNoticeExpiration) {
      const now = Date.now();
      if (now < disabledRefreshNoticeExpiration) {
        return; // 如果还在过期时间内，则不再弹出提示框
      }
    }
    // 不再提醒
    const neverNotice = window.localStorage.getItem(neverNoticeKey) === 'true';
    if (neverNotice) {
      stopLoop();
      return;
    }
  }
  if (requestLock || getDialogVisible()) return; // 如果正在请求或者提示框已经显示了，就取消本次处理
  requestLock = true;
  axios({ url: `${publicUrl}/build/htmlversion.json?v=${Date.now()}` }).then(res => {
    if (res?.data) {
      try {
        const oldHtmlVersionStr = window[versionCacheKey] || '';
        const newHtmlVersion = res.data as unknown as Record<string, string>;
        const newHtmlVersionStr = JSON.stringify(newHtmlVersion);
        if (oldHtmlVersionStr && oldHtmlVersionStr !== newHtmlVersionStr) {
          // 如果存在历史版本， 对比版本，找出更新的模块
          const oldHtmlVersion = JSON.parse(oldHtmlVersionStr);
          const updateModules = checkUpdateModule(oldHtmlVersion, newHtmlVersion);
          if (updateModules.length) {
            refreshNotice(JSON.stringify({ modules: updateModules }), window.weappUiGlogbalRefreshNoticeType || undefined);
          }
        }
        // 更新记录模块 HTML 版本
        window[versionCacheKey] = newHtmlVersionStr;
        requestLock = false;
      } catch (e) {
        // 如果执行错误，中断轮询
        stopLoop();
      }
    }
  }).catch((err: any) => {
    // 如果 htmlversion 文件请求不到，取消轮询
    stopLoop();
  });
}

// 检查黑名单
function checkBlackList() {
  return window.location.href && !blackList.find(route => route.test(window.location.href));
}

// 开启轮询检查模块更新
async function startCheckModuleLoop () {
  try {
    if (window.self !== window.top) return; // iframe 中不处理提示
    if (window.weappUiGlogbalRefreshNoticeStatus === false) return; // 全局参数强制关闭
    else if (window.weappUiGlogbalRefreshNoticeStatus === true) { // 全局参数强制开启
      startLoop();
      return;
    }
    if (!checkBlackList()) return;
    // 第一次加载请求版本信息
    try {
      const res: any = await baseConfigInstance?.ready(()=>{
        // console.log("配置初始化")
      })
      // 用户配置开关
      if (res?.configValue?.['common.refreshNotice'] !== 'false') {
        startLoop();
        // 取消不再提醒
        window.localStorage.setItem(neverNoticeKey, 'false');
      } else if (new Date().getDay() === 1 || new Date().getDate() === 1) {
        // 每周一或每月1号，默认开启
        startLoop();
      }
    } catch (error) {
      startLoop();
    }
  } catch (e) {
    stopLoop();
  }
}

// 结束轮询检查模块更新
function stopCheckModuleLoop () {
  stopLoop();
}

// 更新轮询时间
function updateLoopInterval (time: number) {
  try {
    if (!time || !checkBlackList()) return;
    loopInterval = time;
    // 结束上次轮询
    stopLoop();
    // 开启新的轮询
    timmer = setInterval(loop, loopInterval);
  } catch (e) {
    stopLoop();
  }
}

// PC端生效 其它端不生效
if (ua.device === 'PC') {
  // 组件库加载即开启轮询检查
  startCheckModuleLoop();
}

export {
  startLoop,
  stopLoop,
};

export {
  refreshNotice,
  updateLoopInterval,
  startCheckModuleLoop,
  stopCheckModuleLoop,
}
