import { dialogClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import _Dialog from './Dialog';
import { Message, message } from './dialog/message/index';
import { Confirm, confirm } from './dialog/confirm/index';
import { createDialog } from './create';
import { DialogDraggableType, DialogType } from './types';
import { EVENT_VISIBLE_CHANGE } from './constant/index';
export type { DialogProps, DialogConfig, FooterProps, DialogType, DialogDraggableInstance, DialogDraggableProps } from './types';
export type { DialogMessageProps } from './dialog/message/types';
export type { DialogConfirmProps } from './dialog/confirm/types';
export type { DialogToastProps } from './mDialog/toast/types';

export const DialogDraggable = Loadable({
  name: 'DialogDraggable', loader: () => import(
    /* webpackChunkName: "dialog_draggable" */
    './dialog/DialogDraggable'
  )
}) as DialogDraggableType;

const Dialog = Loadable({
  name: 'Dialog',
  component: _Dialog,
}) as DialogType;

Dialog.message = message;
Dialog.confirm = confirm;
Dialog.Message = Message;
Dialog.Confirm = Confirm;
Dialog.dialogConfig = {};
Dialog.DialogDraggable = DialogDraggable;

Dialog.customDialogEventName = 'ui-dialog-visible-change';
Dialog.EVENT_VISIBLE_CHANGE = EVENT_VISIBLE_CHANGE;
Dialog.defaultProps = {
  placement: 'middle',
  prefixCls: dialogClsPrefix,
  keepDialogAlive: true,
  isStopPropagation: true,
};
Dialog.dialogList = []
Dialog.createDialog = createDialog;

export default Dialog;
