// 名称[用户名][自定义...][时间][结束时间][已删除]
// content[username][custom...][time][timeEnd][isDeleted]
// icon 数据图标
// avatar 头像
// title 悬浮提示信息
// disableDelete 禁用数据删除
// isComplete 删除线

import { ReactNode } from "react";
import { classnames, getLabel } from "@weapp/utils";
import { AnyObj, constantsM, Icon, Encry, constants } from "../../../lib";
import { BrowserProps, SelectedData } from "../types";


// import DefaultImg from '../asset/meeting_default.png';
const { desensitization, encryption } = Encry;
const { mBrowserClsPrefix } = constantsM;

const { browserClsPrefix } = constants;

const getText = (text?: string) => `${text ? `[${text}]` : ''}`;

/* 人员模版 */
export const hrmTemplate = {
  default: [
    {
      key: 'col1',
      configs: [
        {
          key: 'col1_row1',
          render: (data: SelectedData) => {
            if (data) {
              const {
                richContent, content, name, title, customFields
              } = data;
              let context: ReactNode = `${richContent || content || name || title || ''}`;
              let fields = customFields || [];
              // 过滤掉
              let isEncry = fields.filter(item => desensitization(item) || encryption(item))
              fields = fields.filter(item => !(desensitization(item) || encryption(item)))
              context = fields.reduce((pContext, item) => `${pContext}${getText(item)}`, context) || context;
              const encryList = isEncry.map((item, index) => <Encry weId={`_7gkgms_${index}`} key={index} value={item} />)
              const encryCls = classnames(`${browserClsPrefix}-panel-list-template-content-wrapper`, {
                [`${browserClsPrefix}-panel-list-template-encry-wrapper`]: encryList?.length > 0
              });
              return (
                <span className={encryCls}>
                  <span dangerouslySetInnerHTML={{ __html: context as string }} />
                  {encryList}
                </span>
              );
            }
            return '';
          }
        }
      ]
    }
  ]
};

/* 流程选择节点 */
export const workflowNodeTreeTemplate = {
  default: [
    {
      key: 'col1',
      configs: [
        {
          key: 'col1_row1',
          render: (data: SelectedData) => {
            if (data) {
              const {
                richContent, content, name, title, workflowName,
              } = data;
              let context: ReactNode = `${richContent || content || name || title || ''}${workflowName ? `(${workflowName})` : ''}`;
              return <span dangerouslySetInnerHTML={{ __html: context as string }} />;
            }
            return '';
          }
        }
      ]
    }
  ]
}

/* 列表默认的template */
export const defaultTemplate = {
  default: [
    {
      key: 'col1',
      configs: [
        {
          key: 'col1_row1',
          render: (data: SelectedData) => {
            if (data) {
              const {
                wholePathName, richContent, content, name, title,
                username, time, timeEnd, isComplete, isDeleted, customFields,
              } = data;
              let context: ReactNode = `${wholePathName || richContent || content || name || title || ''}`;
              let fields = [username, ...customFields || [], time, timeEnd];
              // 过滤掉
              let isEncry = fields.filter(item => desensitization(item) || encryption(item))
              fields = fields.filter(item => !(desensitization(item) || encryption(item)))
              context = fields.reduce((pContext, item) => `${pContext}${getText(item)}`, context) || context;
              const encryList = isEncry.map((item, index) => <Encry weId={`_7gkgms_${index}`} key={index} value={item} />)
              // browserClsPrefix
              const encryCls = classnames(`${browserClsPrefix}-panel-list-template-content-wrapper`, {
                [`${browserClsPrefix}-panel-list-template-encry-wrapper`]: encryList?.length > 0
              });
              //  `${mBrowserClsPrefix}-share-list-item-row-span-highlight`: encryList?.length > 0
              if (isComplete || isDeleted) {
                const cls = classnames('browser-list-template', {
                  'is-finished': isComplete
                });
                const deletedCom = isDeleted ? (
                  // <>[<span className="is-deleted">{getLabel('14099', '已删除')}</span>]</>
                  `<span class="is-deleted">${getLabel('14099', '已删除')}</span>`
                ) : '';
                const html = `${context}${deletedCom}`;
                context = (
                  <span className={encryCls}>
                    <span className={cls} dangerouslySetInnerHTML={{ __html: html }} />
                    {encryList}
                  </span>
                );
                return context;
              }
              return (
                <span className={encryCls}>
                  <span dangerouslySetInnerHTML={{ __html: context as string }} />
                  {encryList}
                </span>
              )
            }
            return '';
          }
        },
      ],
    },
  ],
};


/* shareBrowser 已选列表 的 template */
export const seclevelSupportTemplate = {
  default: [
    {
      key: 'col1',
      configs: [
        {
          key: 'col1_row2',
          render: (data: SelectedData) => {
            if (data) {
              const {
                richContent, content, name, title, customFields, objIdList
              } = data;
              let context: ReactNode = `${richContent || content || name || title || ''}`;

              if (objIdList && objIdList?.length > 0) {
                context = objIdList.join('/');
              }
              const fields = customFields || [];
              context = fields.reduce((pContext, item) => `${pContext}${getText(item)}`, context) || context;
              return <span className={`${mBrowserClsPrefix}-share-list-item-row-span-highlight`} dangerouslySetInnerHTML={{ __html: context as string }} />;
            }
            return '';
          }
        },
        {
          key: 'col1_row2',
          render: (data: SelectedData) => {
            if (data) {
              const { seclevel, level } = data;
              let context: ReactNode = seclevel
                ? seclevel.min + '-' + seclevel.max
                : level
                  ? level
                  : '';
              return <span className={`${mBrowserClsPrefix}-share-list-item-row-span${context ? '' : '-void'}`} dangerouslySetInnerHTML={{ __html: context as string }} />;
            }
            return '';
          }
        }
      ]
    }
  ]
};
export const noSeclevelSupportTemplate = {
  default: [
    {
      key: 'col1',
      configs: [
        {
          key: 'col1_row2',
          render: (data: SelectedData) => {
            if (data) {
              const {
                richContent, content, name, title, customFields, objIdList
              } = data;
              let context: ReactNode = `${richContent || content || name || title || ''}`;

              if (objIdList && objIdList?.length > 0) {
                context = objIdList.join('/');
              }
              const fields = customFields || [];
              context = fields.reduce((pContext, item) => `${pContext}${getText(item)}`, context) || context;
              return <span className={`${mBrowserClsPrefix}-share-list-item-row-span-highlight`} dangerouslySetInnerHTML={{ __html: context as string }} />;
            }
            return '';
          }
        },
      ]
    }
  ]
};

export const getShareTemplate = (props: AnyObj) => {
  let template: AnyObj;
  const { seclevelSupport } = props;
  if (seclevelSupport) {
    template = seclevelSupportTemplate;
  } else {
    template = noSeclevelSupportTemplate;
  }
  return template;
}


export const getTemplate = (props: BrowserProps) => {
  let template: AnyObj;
  const { module, type } = props;
  if (type === 'resource') {
    template = hrmTemplate;
  } else if (module === 'workflow/pathdef' && type === 'nodeTreeBrowser') {
    template = workflowNodeTreeTemplate;
  } else {
    template = defaultTemplate;
  }
  return template;
}

export const projectTemplate = {
  default: [
    {
      key: 'col1',
      configs: [
        {
          key: 'col1_row1',
          render: (data: any) => {
            const { richContent, content, name, title } = data || {};
            const context = richContent || content || name || title || '';
            const isFinished = data?.status === 'finished';
            return (
              <span className={isFinished ? 'is-finished' : ''} dangerouslySetInnerHTML={{ __html: context }} />
            );
          }
        },
      ],
    },
  ],
};

export const taskTemplate = {
  default: [
    {
      key: 'col1',
      configs: [
        {
          key: 'col1_row1',
          render: (data: any) => {
            const { richContent, content, name, title } = data || {};
            const context = richContent || content || name || title || '';
            const isFinished = data?.status === 'finished';
            const finishedCom = isFinished ? (
              // <>[<span className="is-deleted">{getLabel('14099', '已删除')}</span>]</>
              `<span class="is-deleted">${getLabel('14099', '已删除')}</span>`
            ) : '';
            const html = `${context}${finishedCom}`;
            return (
              <span dangerouslySetInnerHTML={{ __html: html }} />
            );
          }
        },
      ],
    },
  ],
};

export const customRenderProjectItem = (templateCom: React.ReactNode, data: SelectedData) => {
  const { richContent, content, name, title } = data || {};
  const context = richContent || content || name || title || '';
  const isFinished = data?.status === 'finished';
  return (
    <span className={isFinished ? 'is-finished' : ''} dangerouslySetInnerHTML={{ __html: context }} />
  );
};

export const customRenderTaskItem = (templateCom: React.ReactNode, data: SelectedData) => {
  const { richContent, content, name, title } = data || {};
  const context = richContent || content || name || title || '';
  const isFinished = data?.status === 'finished';
  const finishedCom = isFinished ? (
    // <>[<span className="is-deleted">{getLabel('14099', '已删除')}</span>]</>
    `<span class="is-deleted">${getLabel('14099', '已删除')}</span>`
  ) : '';
  const html = `${context}${finishedCom}`;
  return (
    <span dangerouslySetInnerHTML={{ __html: html }} />
  );
};
