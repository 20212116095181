import { corsImport, eventEmitter, historyUtil, request, weappSDK } from "@weapp/utils";
import { matchPath } from 'react-router-dom';
import { AnyObj } from "../../types/common";
import { ActionValueType, FunctionConfigType, RootLayoutMenuDataType } from './types';
import { findMenus } from "./utils";

/* 渲染类型 */
export const RenderType = {
  MenuCustomRender: 'menuCustomRender', // 菜单配置 自定义渲染组件
  PluginCustomRender: 'pluginCustomRender', // 插件配置 自定义渲染组件
  EcodeCustomRender: 'ecodeCustomRender', // Ecode 自定义渲染页面
  ModuleMainRouteRender: 'moduleMainRouterRender', // 模块主入口渲染
  OtherRender: 'otherRender', // 其他渲染
}

/* 动作类型 */
export const ActionType = {
  PushRoute: 'pushRoute',
  OpenLink: 'openLink',
  OpenIframe: 'openIframe',
  OpenHref: 'openHref',
  DoRequest: 'doRequest',
  Refresh: 'refresh',
  SaveTEAMS: 'saveTEAMS',
  SaveLocalStorage: 'saveLocalStorage',
  CallFunction: 'callFunction',
}

/* 钩子名称 */
export const HooksName = {

}

// 初始化调度中心
export function init () {
  eventEmitter.emit('@weapp/utils', 'RootLayout_Init');
  return request({ url: '/api/getRootLayoutPlugins' });
}

// 切换菜单
export function changeMenu (menuKey: string) {
  eventEmitter.emit('@weapp/utils', 'RootLayout_Change_Menu', menuKey);
}

// 根据路由匹配配置数据
export const getPathMatchedConfig = (configs: RootLayoutMenuDataType, locationPath: string) => {
  const matchedConfig = findMenus(configs, (config: any) => {
    // 按路由规则渲染一定要设置 path 字段
    if (config.path && matchPath(locationPath, { path: config.path }) !== null) {
      // 1. corsComponent 异步加载组件
      if (config.corsComponentProps?.app && config.corsComponentProps?.compName) {
        config.renderType = 'corsComponent';
        return true;
      }
      // 2. ecodeComponent ecode导出组件
      if (config.ecodeComponentProps?.appId) {
        config.renderType = 'ecodeComponent';
        return true;
      }
      // 3. iframe 渲染
      if (config.iframeConfig?.src) {
        config.renderType = 'iframe';
        config.iframeConfig.path = config.path;
        return true;
      }
      // 4. component 同步组件
      if (config.componentType) {
        config.renderType = 'component';
        return true;
      }

      if (config.moduleMainRouterProps?.app) {
        config.renderType = 'moduleMainRouter';
        return true;
      }
    }
    return false;
  }, true);

  return matchedConfig;
}

// 根据开关匹配数据
export const getPluginEnableConfig = (plugins: RootLayoutMenuDataType) => {
  const pluginConfig = findMenus(plugins, (plugin) => {
    const { enablePlugin } = plugin || {};
    let flag = false;
    if (typeof enablePlugin === 'boolean') {
      flag = enablePlugin;
    } else if (typeof enablePlugin === 'function') {
      flag = enablePlugin() || false;
    }
    if (flag) {
      // 1. corsComponent 异步加载组件
      if (plugin.corsComponentProps?.app && plugin.corsComponentProps?.compName) {
        plugin.renderType = 'corsComponent';
        return true;
      }
      // 2. ecodeComponent ecode导出组件
      if (plugin.ecodeComponentProps?.appId) {
        plugin.renderType = 'ecodeComponent';
        return true;
      }
      // 3. iframe 渲染
      if (plugin.iframeConfig?.src) {
        plugin.renderType = 'iframe';
        return true;
      }
      // 4. component 同步组件
      if (plugin.componentType) {
        plugin.renderType = 'component';
        return true;
      }
    }
    return false;
  }, true);
  return pluginConfig;
}

// 执行动作
export function doAction (action: ActionValueType, params?: AnyObj) {
  eventEmitter.emit('@weapp/utils', 'RootLayout_do_action', action, params);
  switch (action) {
    case ActionType.PushRoute:
      if (params?.path) {
        const globalHistory = historyUtil.getMainHistory();
        globalHistory?.push(params?.path, params?.state);
      }
      break;
    case ActionType.OpenLink:
      if (params?.url) {
        weappSDK.openLink(params);
      }
      break;
    case ActionType.OpenHref:
      if (params?.url) {
        window.location.href = params.url;
      }
      break;
    case ActionType.DoRequest:
      if (params?.api) {
        return request(params);
      }
      break;
    case ActionType.Refresh:
      window.location.reload();
      break;
    case ActionType.SaveLocalStorage:
      if (params?.key) {
        window.localStorage?.setItem(params.key, params.value);
      }
      break;
    case ActionType.CallFunction:
      const functionConfig = params as FunctionConfigType | undefined;
      if (functionConfig?.functionType === 'corsFunction' && functionConfig?.corsFunctionConfig?.app && functionConfig?.corsFunctionConfig?.funcName) {
        // corsImport 异步调用方法
        corsImport(params?.functionConfig?.app).then(module => {
          if (module?.[params?.functionConfig?.funcName] && typeof module?.[params?.functionConfig?.funcName] === 'function') {
            module?.[params?.functionConfig?.funcName]?.();
          }
        });
      } else if (functionConfig?.functionType === 'ecodeFunction' && functionConfig?.ecodeFunctionConfig?.appId) {
        // 异步调用 ecode 方法
        window.weappEcodesdk?.asyncImport?.(functionConfig.ecodeFunctionConfig.appId, functionConfig.ecodeFunctionConfig.filePath || 'entry.js')?.then((Module: any) => {
          const funcName = functionConfig?.ecodeFunctionConfig?.funcName as string || 'default';
          if (Module?.[funcName] && typeof Module?.[funcName] === 'function') {
            Module[funcName]();
          }
        })
      } else if (functionConfig?.functionType === 'function' && typeof functionConfig?.function === 'function') {
        // 调用同步方法
        functionConfig?.function();
      }
      break;
  }
}

// 批量执行动作
export function doActions (actions: { action: ActionValueType, params: AnyObj }[]) {
  return actions.map(action => doAction(action.action, action.params));
}
