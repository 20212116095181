import { middleware } from "@weapp/utils";
import { Component, Suspense } from "react";
import { Route } from "react-router-dom";
import { uiAppName } from "../../constants";
import { CorsComponent } from "../cors-link";
import EcodeComponent from "./EcodeComponent";
import { ModuleMainRouteContent } from "./ModuleContent";
import { DynamicRenderComponentProps } from "./types";

export class IframeContent extends Component<any> {
  render () {
    const { path, src } = this.props;
    const iframeCom = (
      <iframe src={src} title="content-iframe" width="100%" height="100%" />
    );
    return path ? (
      <Route weId={`${this.props.weId || ''}_fq2lzb`} path={path}>
        {iframeCom}
      </Route>
    ) : iframeCom;
  }
}

@middleware(uiAppName, 'DynamicRenderComponent')
class DynamicRenderComponent extends Component<DynamicRenderComponentProps> {
  render() {
    const { renderType, corsComponentProps, ecodeComponentProps, iframeConfig, componentType, componentProps, moduleMainRouterProps } = this.props;
    // 1. corsComponent 异步加载组件
    if (renderType === 'corsComponent' && corsComponentProps?.app && corsComponentProps?.compName) {
      return (
        <CorsComponent
          weId={`${this.props.weId || ''}_l1fwfa`}
          {...corsComponentProps}
          {...componentProps}
        />
      );
    }
    // 2. ecode 异步加载组件
    if (renderType === 'ecodeComponent' && ecodeComponentProps?.appId) {
      return (
        <EcodeComponent
          weId={`${this.props.weId || ''}_vdyvnb`}
          {...ecodeComponentProps}
          {...componentProps}
        />
      )
    }
    // 3. iframe 渲染
    if (renderType === 'iframe' && iframeConfig?.src) {
      return (
        <IframeContent
          weId={`${this.props.weId || ''}_de9xix`}
          path={iframeConfig.path}
          src={iframeConfig.src}
          {...componentProps}
        />
      );
    }
    // 4. component 同步组件
    if (renderType === 'component' && componentType) {
      const Com = componentType;
      return (
        <Com
          weId={`${this.props.weId || ''}_2rp6gh`}
          {...componentProps}
        />
      );
    }

    if (renderType === 'moduleMainRouter' && moduleMainRouterProps?.app) {
      const parentPath = moduleMainRouterProps?.parentPath
      const content = (
        <Suspense weId={`${this.props.weId || ''}_wkzdli`} fallback={null}>
          <ModuleMainRouteContent weId={`${this.props.weId || ''}_j7p2ld`}  app={moduleMainRouterProps.app} />
        </Suspense>
      )
      return parentPath ? (
        <Route weId={`${this.props.weId || ''}_hkhxt0`} path={parentPath}>
          { content }
        </Route>
      ) : content;
    }

    return null;
  }
}

export default DynamicRenderComponent;
