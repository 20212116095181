import { DateType } from '../../types'

/**
 * @description: 将回调返回的日期类型转换为标准日期类型
 * @param {DateType} date
 * @return {*}
 */
export function functionDateToDate(date?: DateType | (() => DateType)) {
  if (date && typeof (date) === 'function') {
    date = date()
  }
  return date
}

/**
 * @description: 将回调返回的日期类型转换为标准日期类型
 * @param {DateType} date
 * @return {*}
 */
export function isMultiDatePickerType(type?: string) {
  const multiTypeAry = ['days', 'months', 'years', 'seasons', 'halfyears']
  if (type) {
    return multiTypeAry.indexOf(type) !== -1
  }
  return false
}

/**
 * 从系统变量中获取指定参数
 * @param key string
 * @returns any | undefined
 */
export function getParamFromSystem(key: string) {
  if (window?.TEAMS?.globalConfig && (key in window.TEAMS.globalConfig)) {
    return window.TEAMS.globalConfig[key]
  }
  return undefined;
}


