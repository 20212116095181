
import Loadable from '../../react-loadable';
import { AppIconType, RichAppIconType } from './types';

const AppIcon = Loadable({
  name: 'AppIcon', loader: () => import(
    /* webpackChunkName: 'ui_common' */
    './AppIcon')
}) as AppIconType;

AppIcon.defaultProps = {
  value: 1,
  borderType: 'circle',
  size: 24,
}


/** RichAppIcon */
const RichAppIcon = Loadable({
  name: 'RichAppIcon', loader: () => import(
    /* webpackChunkName: 'ui_common' */
    './RichAppIcon')
}) as RichAppIconType;

AppIcon.RichAppIcon = RichAppIcon;

RichAppIcon.defaultProps = {
  borderType: 'circle',
  size: 24,
}

export type { AppIconProps, moduleType as AppIconModuleType } from './types';

/** RichIconSelection 组件的类型声明 */
export type { RichAppIconProps, RichAppIconType } from './types'


export default AppIcon;
