import { layoutClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { LayoutType, BoxType, RglType, RowType, ColType } from './types';
import _Layout from './Layout';
import _Box from './Box';
import _Row from './Row';
import _Col from './Col';
import _RGL from './RGL';

const Layout = Loadable({
  name: 'Layout',
  component: _Layout,
  // loader: () => import(
  // /* webpackChunkName: "layout" */
  // './Layout'),
}) as LayoutType;

const Box = Loadable({
  name: 'LayoutBox',
  component: _Box,
  // loader: () => import(
  // /* webpackChunkName: "layout" */
  // './Box')
}) as BoxType;

const Row = Loadable({
  name: 'LayoutRow',
  component: _Row,
  // loader: () => import(
  // /* webpackChunkName: "layout" */
  // './Row'),
}) as RowType;

const Col = Loadable({
  name: 'LayoutCol',
  component: _Col,
  loader: () => import(
  /* webpackChunkName: "layout" */
  './Col'),
}) as ColType;

const RGL = Loadable({
  name: 'LayoutRGL',
  component: _RGL,
  loader: () => import(
  /* webpackChunkName: "layout" */
  './RGL'),
}) as RglType;

Layout.defaultProps = {
  prefixCls: layoutClsPrefix,
  draggable: true,
  memoryWidth: true,
};

Box.defaultProps = {
  prefixCls: `${layoutClsPrefix}-box`
};

Layout.Box = Box;
Layout.RGL = RGL;
Layout.Row = Row;
Layout.Col = Col;

export type { LayoutProps, BoxProps as LayoutBoxProps, LayoutBlock as LayoutBoxInfoType, RowProps, ColProps, LayoutDragWidthStopType } from './types';

export { Row, Col, Box, RGL };

export default Layout;
