import Loadable from "../../react-loadable";
import { mCityPickerClsPrefix } from "../../constants/index.m";
import { MCityPickerType } from "./types";

const MCityPicker = Loadable({
  name: 'MCityPicker', loader: () => import(
    /* webpackChunkName: "ui_m_common" */
    './MCityPicker')
}) as MCityPickerType;

MCityPicker.defaultProps = {
  prefixCls: mCityPickerClsPrefix,
  level: 'district',
  mode: 'union'
};

export type { MCityPickerProps } from "./types";
export default MCityPicker;

