import { getLabel } from '@weapp/utils'

/**
 * @description: 根据月份获取对应的月份多语言标签（缩写）
 * @param {number} month
 * @return {*}
 */
export function getMonLabel(month: number) {
  const monthLabels = [getLabel('170942', '1 月'), getLabel('170943', '2 月'), getLabel('170944', '3 月'),
  getLabel('170945', '4 月'), getLabel('170946', '5 月'), getLabel('170947', '6 月'),
  getLabel('170949', '7 月'), getLabel('170950', '8 月'), getLabel('170951', '9 月'),
  getLabel('170952', '10 月'), getLabel('170953', '11 月'), getLabel('170954', '12 月')]
  return monthLabels[month] || ''
}

/**
 * @description: 根据月份获取对应的月份多语言标签
 * @param {number} month
 * @return {*}
 */
export function getMonthLabel(month: number) {
  const monthLabels = [getLabel('65503', '1 月'), getLabel('65505', '2 月'), getLabel('65506', '3 月'),
  getLabel('65508', '4 月'), getLabel('65510', '5 月'), getLabel('65512', '6 月'),
  getLabel('65513', '7 月'), getLabel('65514', '8 月'), getLabel('65516', '9 月'),
  getLabel('65517', '10 月'), getLabel('65519', '11 月'), getLabel('65522', '12 月')]
  return monthLabels[month] || ''
}

/**
 * @description: 根据季度获取对应的季度多语言标签
 * @param {number} season
 * @return {*}
 */
export function getSeasonLabel(season: number) {
  let content = ''
  switch (season) {
    case 1:
      content = getLabel('121384', '第一季度')
      break;
    case 2:
      content = getLabel('121387', '第二季度')
      break;
    case 3:
      content = getLabel('121388', '第三季度')
      break
    case 4:
      content = getLabel('121389', '第四季度')
      break;
    default:
      break;
  }
  return content
}

/**
 * @description: 根据季度获取对应的季度多语言标签
 * @param {number} halfYear
 * @return {*}
 */
export function getHalfYearLabel(halfYear: number) {
  let content = ''
  switch (halfYear) {
    case 1:
      content = getLabel('18856', '上半年')
      break;
    case 2:
      content = getLabel('19143', '下半年')
      break;
    default:
      break;
  }
  return content
}

/**
 * @description: 根据类型获取对应的placeholder多语言标签
 * @param {string} type
 * @return {*}
 */
export function getPlaceholder(type?: string) {
  let content = getLabel('28636', '选择日期')
  switch (type) {
    case 'date':
      content = getLabel('28636', '选择日期')
      break;
    case 'year':
      content = getLabel('58251', '选择年份')
      break
    case 'month':
      content = getLabel('58250', '选择月份')
      break;
    case 'day':
      content = getLabel('28636', '选择日期')
      break;
    case 'time':
      content = getLabel('58262', '选择时间')
      break;
    case 'season':
      content = getLabel('121382', '选择季度')
      break
    case 'halfyear':
      content = getLabel('128487', '选择半年度')
      break;
    default:
      break;
  }
  return content
}

/**
 * @description: 根据类型获取对应的范围placeholder多语言标签
 * @param {string} type
 * @return {*}
 */
export function getRangePlaceholder(type?: string) {
  let s = getLabel('15205', '开始日期')
  let e = getLabel('16746', '结束日期')
  switch (type) {
    case 'date':
      s = getLabel('15205', '开始日期')
      e = getLabel('16746', '结束日期')
      break;
    case 'year':
      s = getLabel('58254', '开始年份')
      e = getLabel('58255', '结束年份')
      break
    case 'month':
      s = getLabel('58257', '开始月份')
      e = getLabel('58258', '结束月份')
      break;
    case 'day':
      s = getLabel('15205', '开始日期')
      e = getLabel('16746', '结束日期')
      break;
    case 'time':
      s = getLabel('19504', '开始时间')
      e = getLabel('58265', '结束时间')
      break;
    case 'season':
      s = getLabel('128511', '开始季度')
      e = getLabel('128512', '结束季度')
      break
    case 'halfyear':
      s = getLabel('128513', '开始半年度')
      e = getLabel('128514', '结束半年度')
      break;
    default:
      break;
  }
  return {
    startPlaceholder: s,
    endPlaceholder: e
  }
}

/**
 * @description: 根据类型获取对应的多选placeholder多语言标签
 * @param {string} type
 * @return {*}
 */
export function getMultiPlaceholder(type?: string) {
  let content = getLabel('58252', '选择一个或多个日期')
  switch (type) {
    case 'days':
      content = getLabel('58252', '选择一个或多个日期')
      break;
    case 'years':
      content = getLabel('97378', '选择一个或多个年份')
      break
    case 'months':
      content = getLabel('97377', '选择一个或多个月份')
      break;
    case 'seasons':
      content = getLabel('121383', '选择一个或多个季度')
      break;
    default:
      break;
  }
  return content
}