
import Loadable from '../../react-loadable';

import { searchAdvancedClsPrefix } from '../../constants/index';
import { SearchAdvancedType, SearchAdvancedPanelType, SimpleSearchType, SimpleSearchPanelType, SearchAdvancedLayoutType } from './types';

const SearchAdvanced = Loadable({
  name: 'SearchAdvanced', loader: () => import(
    /* webpackChunkName: "searchadvanced" */
    './SearchAdvanced')
}) as SearchAdvancedType;

SearchAdvanced.defaultProps = {
  prefixCls: searchAdvancedClsPrefix,
  enableBrowserIncludeSub: true,
  enablePanelEnterSearch:true
}

const Panel = Loadable({
  name: 'SearchAdvancedPanel', loader: () => import(
    /* webpackChunkName: "searchadvanced" */
    './searchAdvanced/Panel')
}) as SearchAdvancedPanelType;

Panel.defaultProps = {
  prefixCls: searchAdvancedClsPrefix,
  colSpan: 12,
  disabledCustomDateSaveFormat: true,
  enableBrowserIncludeSub: true,
  enablePanelEnterSearch:true
}

SearchAdvanced.Panel = Panel;

const Layout = Loadable({
  name: 'SearchAdvancedLayout', loader: () => import(
    /* webpackChunkName: "searchadvanced" */
    './searchAdvanced/Layout')
}) as SearchAdvancedLayoutType;

Layout.defaultProps = {
  prefixCls: searchAdvancedClsPrefix,
  colSpan: 12,
}

SearchAdvanced.Layout = Layout;

const SimpleSearch = Loadable({
  name: 'SimpleSearch', loader: () => import(
    /* webpackChunkName: "searchadvanced" */
    './top/SimpleSearch')
}) as SimpleSearchType;

SearchAdvanced.SimpleSearch = SimpleSearch;

const SimpleSearchPanel = Loadable({
  name: 'SimpleSearchPanel', loader: () => import(
    /* webpackChunkName: "searchadvanced" */
    './top/SimpleSearchPanel')
}) as SimpleSearchPanelType;

SearchAdvanced.SimpleSearchPanel = SimpleSearchPanel;

export type { SearchAdvancedProps, SearchAdvancedPanelProps, SearchAdvancedLayoutProps, FieldProps, SearchDatas, QuickSearchDatas, SimpleSearchType, SimpleSearchPanelType, QuikSearchPanelList as SnapDataType } from './types';

export type { SearchAdvancedStoreType, SearchAdvancedInitAllDatas } from './store/Store';

export { default as SearchAdvancedStore } from './store/Store';

export default SearchAdvanced;
