
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import { classnames } from "@weapp/utils";
import Loadable from "../../react-loadable";
import { hrmCardClsPrefix } from "../../constants";
import { triggerGetRegion } from "../trigger";
import { HrmCardPanelProps, HrmCardPopupType, HrmCardType } from "./types";
import { openHrmLink, isListType } from './utils';
import { needRTL } from '../../utils/index';
const rtl = needRTL?.()

const HrmCardPopup = Loadable({
  name: 'HrmCardPopup', loader: () => import(
    /* webpackChunkName: 'hrmcard' */
    './HrmCardPopup')
}) as HrmCardPopupType;


interface HrmCardProp {
  id: string,
  type: string,
  hasLoad: boolean,
  instance: React.RefObject<HrmCardPanelProps>,
  target: any,
  wrap?: HTMLSpanElement,
  customData?: any
}

const hrmCardProps: HrmCardProp = {
  id: '',
  type: '',
  hasLoad: false,
  instance: React.createRef<HrmCardPanelProps>(),
  target: null,
}

const showHrmCard = (target: any, id: string, type: HrmCardType, module: string, otherParams?: any) => {

  let bodyWrap: HTMLSpanElement | null | undefined = null;

  if (otherParams && otherParams.bindTriggerContainer && target !== hrmCardProps.target) {
    bodyWrap = window.document.createElement('span');
    // bodyWrap.className = `${hrmCardClsPrefix}-popup ${hrmCardClsPrefix}-popup-relative`;
    bodyWrap.className = classnames(`${hrmCardClsPrefix}-popup`, `${hrmCardClsPrefix}-popup-relative`, {
      [`${hrmCardClsPrefix}-popup-rtl`]: rtl
    });

    bodyWrap.onclick = (e) => {
      e.stopPropagation && e.stopPropagation();
    };
    target.appendChild(bodyWrap);
  } else if (hrmCardProps.instance.current && hrmCardProps.wrap && target === hrmCardProps.target) {
    bodyWrap = hrmCardProps.wrap;
  } else {
    //第一次点开卡片
    bodyWrap = window.document.createElement('span');
    bodyWrap.className = classnames(`${hrmCardClsPrefix}-popup`, {
      [`${hrmCardClsPrefix}-popup-rtl`]: rtl
    });
    window.document.body.appendChild(bodyWrap);
  }
  // 卸载卡片
  function destroy() {
    if (otherParams && otherParams.bindTriggerContainer) {
      hrmCardProps.hasLoad = false;
      if (bodyWrap) {
        const unmount = ReactDOM.unmountComponentAtNode(bodyWrap);
        if (unmount && bodyWrap.parentNode) {
          bodyWrap.parentNode.removeChild(bodyWrap);
        }
      }
      hrmCardProps.target = null;
    }
  }

  // 弹层显示定位计算
  const targetPosition = triggerGetRegion(target);
  let { left, top, height } = targetPosition;
  const doc = target.ownerDocument;
  const win = doc.defaultView;
  const innerWidth = win?.document.body.clientWidth || 0;

  // type: inside  = 510; department、group = 310; external、wechat = 410

  let contentWidth = 510;
  if (isListType(type)) {
    contentWidth = 310;
  } else if (type === 'external' || type === 'wechat') {
    contentWidth = 410;
  }

  if (left + contentWidth + 10 > innerWidth) { // 450为目前卡片组件宽度
    left = innerWidth - contentWidth - 10;
  }

  if (otherParams && otherParams.bindTriggerContainer) { // 相对定位
    left = 0;
    top = 0;
  }

  bodyWrap.style.left = `${left}px`;
  if (rtl) {
    bodyWrap.style.right = `${left}px`;
  }
  bodyWrap.style.top = `${top + height}px`;
  hrmCardProps.wrap = bodyWrap;
  if (id !== hrmCardProps.id || type !== hrmCardProps.type) {
    hrmCardProps.id = id;
    hrmCardProps.type = type;
  }
  const doreload = !(otherParams && otherParams.bindTriggerContainer && target !== hrmCardProps.target);
  if (hrmCardProps.instance.current && hrmCardProps.wrap && doreload) {
    // openHrmCard方法打开的相对定位模式，不同卡片重新生成节点
    hrmCardProps.instance.current.reload?.(id, type, target, otherParams);
    hrmCardProps.target = target;
    return;
  }
  hrmCardProps.target = target;
  ReactDOM.render(
    <Router weId={`7nqkm8`}>
      <HrmCardPopup
        weId={`_b9f1yz`}
        prefixCls={hrmCardClsPrefix}
        onVisibleChange={destroy}
        id={id}
        type={type}
        module={module}
        ref={hrmCardProps.instance}
        target={target}
        wrap={bodyWrap}
        openStatisticsRequest={otherParams?.openStatisticsRequest}
        otherParams={otherParams}
        {...otherParams}
      />
    </Router>, bodyWrap);
}

export const openPCHrmCard = (e: any, id: string, type: HrmCardType, module: string, otherParams?: any) => {
  otherParams = {
    // openStatisticsRequest: true,
    ...otherParams
  }
  const target: Element = e ? e.target || null : null;
  //如果是分部或者部门卡片，在@和共享组件中使用时会打先开小卡片，否则直接跳转sp路由
  if (type === 'department' || type === 'subcompany' || type === 'organization' || type === 'position') {
    if (otherParams?.path === 'card') {
      showHrmCard(otherParams?.target || target, id, type, module, otherParams);
    } else {
      openHrmSP(e, id, type, module, otherParams)
      // showHrmCard(otherParams?.target || target, id, type, module, otherParams);
    }
  } else {
    showHrmCard(otherParams?.target || target, id, type, module, otherParams);
  }
  e.stopPropagation && e.stopPropagation();
}

export const openHrmCard = (e: any, id: string, type: string, module: string, otherParams?: any) => {
  return import(
    /* webpackChunkName: 'hrmcard' */
    './utils/utils').then((defaultModule) => {
      return defaultModule.openHrmCard(e, id, type, module, otherParams);
    })
}

export const openMobileHrmCard = (id: string, type?: string, module?: string, otherParams?: any) => {
  return import(
    /* webpackChunkName: 'hrmcard' */
    './utils/utils').then((defaultModule) => {
      return defaultModule.openMobileHrmCard(id, type, module, otherParams);
    })
}

export const openHrmSP = (e: any, id: string, type: string, module: string, otherParams?: any) => {
  if (window?.NOT_OPEN_HRM_CARD) return
  let url = `/sp/hrm/profileInfo/${id}`
  if (type === 'inside' || type === 'external') {
    url = `/sp/hrm/profileInfo/${id}`
  } else if (type === 'department' || type === 'subcompany' || type === 'organization') {
    url = `/sp/hrm/orgCard/${id}`
  } else if (type === 'position') {
    url = `/sp/hrm/positionCard/${id}`
  } else if (type === 'group' || type === 'role') {
    url = ''
  }
  //跳转大卡片sdk 2022-08-26 添加跳转链接前接口判断操作
  // weappSDK.openLink({
  //   url: url
  // })
  openHrmLink(id, type, url)
  e.stopPropagation && e.stopPropagation();
}

export const openCustomCard = (e: any, id: string, type: string, module: string, otherParams?: any) => {
  if (window?.NOT_OPEN_HRM_CARD) return
  const target: Element = e ? e.target || null : null;
  showHrmCard(otherParams?.target || target, id, type, module, otherParams);
}

window.openHrmCard = openHrmCard;
window.openHrmSP = openHrmSP
window.openMobileHrmCard = openMobileHrmCard;
window.openCustomCard = openCustomCard;
