import { encryClsPrefix } from '../../constants/index';
import Loadable from "../../react-loadable";
import { MEncryType } from "./type";
import { desensitization, encryption } from './utils/index';

// pc mb 目前采用一套代码
const MEncry = Loadable({
  name: 'MEncry',
  loader: () => import(
    /* webpackChunkName: "ui_form" */
    './compoment/index'
  )
}) as MEncryType;

MEncry.defaultProps = {
  prefixCls: encryClsPrefix,
  isMobile: true,
}

MEncry.desensitization = desensitization;
MEncry.encryption = encryption;

export default MEncry;

export type { MEncryType, MEncryProps } from './type';