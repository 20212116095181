import { ComponentType } from 'react';
import { BaseProps } from "../../types/common";

export enum type { CIRCLE = "circle", CURVE = "curve" }
export interface SpinProps extends BaseProps {
  spinning?: boolean;
  tip?: string;
  /**
   * @title 尺寸
   * @format SELECT
   * @formatdata { data: [{ id: 'small', content: '小' }, { id: 'large', content: '大' }], allowCancel: true }
   */
  size?: string;
  wrapperClassName?: string;
  /**
   * @title 加载文字
   */
  text?: string;
  /**
   * @title 加载模式
   */
  type?: type;
  weId?: string | undefined;
  /**
   * @title 颜色
   * @format COLORPICKER
   */
  color?: string;
  /**
   * @title 全局加载
   */
  globalLoading?: boolean
  /**
   * @title 使用SVG加载
   */
  useSvgSpin?: boolean
  /**
   * @title id
   */
  id?: string;
}

export type SpinType = ComponentType<SpinProps> & { globalSpin: { start: (options?: SpinProps) => void, end: (options?: SpinProps) => void, destroy: () => void } };
