import { request, RequestOptionConfig } from "@weapp/utils";
import ss from './ss';
import ls from './ls';
import { AnyObj } from "../types/common";

export type CacheType = 'localStorage' | 'sessionStorage';

/**
 * 更新缓存数据,用于Menu、ActionSheet
 */
class CacheStorage {

  type: CacheType = 'sessionStorage';

  setType = (type: CacheType) => {
    this.type = type;
  }

  getItem = (key: string) => this.type === 'sessionStorage' ? ss.getItem(key) : ls.getItem(key);

  removeItem = (key: string) => this.type === 'sessionStorage' ? ss.removeItem(key) : ls.removeItem(key);

  setItem = (key: string, value: any) => this.type === 'sessionStorage' ? ss.setItem(key, value) : ls.setItem(key, value)

  request = (key: string, apiParams: RequestOptionConfig): Promise<AnyObj> => {
    const value = this.getItem(key);
    if (value === null || value === undefined) {
      return request(apiParams).then((result) => {
        return { cacheStorage: false, data: result };
      });
    }
    return new Promise((resolve) => resolve({ cacheStorage: true, data: value }));
  }

}

export type CacheStorageType = Pick<CacheStorage, keyof CacheStorage>;

export default CacheStorage;