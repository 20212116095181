import { getLabel } from "@weapp/utils";

// 已选数据最大可显示数据
export const MAX_SELECTED_DATA = 80;

// 默认面板高度
export const defaultPanelHeight = 550;

// 弹框头部默认高度
export const defaultDialogHeight = 51;

// PC 端默认分页大小
export const defaultPageSize = 10;

// 移动端默认分页大小
export const defaultMPageSize = 20;

// 没有tab页签时，默认的activeKey
export const DEFAULTACTIVEKEY = '__default_active_key__';

// 菜单按钮key值前缀
export const MENUKEYPREFIX = '__menu_btn_key__';

/* props参数优先的字段 */
export const defaultPriorityKeys = ['hasAdvanceSearch'];

/* 接口返回数据类型 */
export const DataContextTypeMap = {
  'list': 1, // 分页列表 带总数
  'tree': 1, // 树形
  'table': 1, // 表格
  'single-list': 1, // 不分页列表
  'group-list': 1, // 分组列表
  'group-tree': 1, // 分组树形
  'index-list': 1, // 索引列表
  'card-list': 1,  // 卡片列表
  'tag-list': 1, // 标签列表
  'condition-list': 1, // 带条件的列表
  'mult-type': 1, // 多种类型数据
  'custom': 1, // 自定义类型
};

export enum DataContextType {
  List = 'list',
  Tree = 'tree',
  Table = 'table',
  SingleList = 'single-list',
  GroupList = 'group-list',
  GroupTree = 'group-tree',
  IndexList = 'index-list',
  CardList = 'card-list',
  TagList = 'tag-list',
  ConditionList = 'condition-list',
  MultType = 'mult-type',
  Custom = 'custom',
};

/* 状态按钮在params中的key值 */
export enum StatusBtnParamKeys {
  checkStrictly = 'checkStrictly',
  leftCheckStrictly = 'leftAllLevel',
  hasAccount = 'showNoAccount',
  stopDepartment = 'stopDepartment',
  groupData = 'groupData',
  leftGroupData = 'leftGroupData',
};

/* 分页类型 */
export enum PaginationType {
  Normal = 'normal', // 普通分页
  Scrolling = 'scrolling', // 滚动分页
  Scroll = 'scroll', // 滚动分页
  More = 'more', // 加载更多
};

/** 数据变化类型 */
export const BROWSERDATACHANGETYPE = {
  ADD: 'add',
  DELETE: 'delete',
  // DELETEALL: 'DELETEALL',
  EDIT: 'edit',
  SORT: 'sort',
}

/**
 * 浏览按钮接口返回数据常量
 */
export const BROWSERESULTCONST = {
  //浏览按钮接口返回的数据类型
  BROWSER_RESULT_DATA_TYPE: "dataType",
  //浏览按钮接口返回的数据
  BROWSER_RESULT_DATA: "data",
  // 模版
  BROWSER_RESULT_TEMPLATE: "template",
  //浏览按钮接口返回Tablet头
  BROWSER_RESULT_COLUMN: "columns",
  // 索引列表
  BROWSER_INDEX_LIST: 'indexList',
  // 分组列表
  BROWSER_GROUP_TITLE: 'groupTitle',
  // 下拉框配置数据
  BROWSER_DROPDOWNS: 'dropdowns',
  // 常用组下拉框
  BROWSER_GROUP_DATA: 'groupData',
  // 虚拟纬度下拉框
  BROWSER_VIRTUAL_ORG: 'virtualOrg',
  // 虚拟纬度默认值
  BROWSER_DEFAULT_VIRTUAL_ORG: 'defaultVirtualOrg',
  // 我的下属下拉框
  BROWSER_SUBORDINATE_DATA: 'subordinateData',
  // 我的下属默认值
  BROWSER_DEFAULT_SUBORDINATE_DATA: 'defaultSubordinateData',
  // 左侧常用组下拉框
  BROWSER_LEFT_GROUP_DATA: 'leftGroupData',
  // 左侧虚拟纬度下拉框
  BROWSER_LEFT_VIRTUAL_ORG: 'leftVirtualOrg',
  // 我的下属下拉框
  BROWSER_LEFT_SUBORDINATE_DATA: 'leftSubordinateData',
  // 左侧树形默认选中数据
  BROWSER_LEFT_DEFAULT_TREE_SELECTED_DATA: 'defaultLeftTreeSelectedData',
  // 分页类型
  BROWSER_PAGINATION_TYPE: 'paginationType',
  //数据总数
  BROWSER_RESULT_TOTAL: "total",
  // 是否可以选择全部
  BROWSER_CAN_SELECT_ALL: 'canSelectAll',
  //当前第几页
  BROWSER_RESULT_CURRENT_PAGE: "current",
  //每页显示行数
  BROWSER_RESULT_PAGE_SIZE: "pageSize",
  //联想搜索最多一次查询多少条数据
  BROWSER_AUTO_COMPLETE_MAX_DATA_COUNT: 30,
  //浏览框多选已选中数据ID串
  BROWSER_MULT_DEST_SELECTIDS: "selectIds",
  //浏览按钮聚合模式选中数据ID集合
  BROWSER_DEST_GROUP_SELECTIDS: 'selectIdsWithGroup',
  //浏览框分割符
  BROWSER_SPLIT: ",",
  //浏览按钮支持移动端的模版
  BROWSER_MOBILE_TEMPLATE: "mobileTemplate",
  //联想搜素最近数据，类型是boolean类型
  BROWSER_AUTO_COMPLETE_NEWLY: "newly",
  //已选列表搜索字段
  BROWSER_SEARCH_KEYS: "searchKeys",
  //已选列表搜索提示文字，支持自定义设置
  BROWSER_SEARCH_PLACEHOLDER: "placeholder",
  // 联想搜索启用新增功能
  BROWSER_ENABLE_ADD_DATA: 'enableAddData',
  // 联想搜索新增提示
  BROWSER_ADD_INFO: 'addInfo',
  // 联想搜索启用附加按钮功能
  BROWSER_ENABLE_EXTEND_BUTTON: 'enableExtendButton',
  // 联想搜索附加按钮
  BROWSER_EXTEND_BUTTON: 'extendButton',
  // 联想搜索结果为空时提示文字
  BROWSER_EMPTY_NOTICE_INFO: 'emptyNoticeInfo',
  // 联想搜索新增按钮
  BROWSER_ADD_BUTTON: 'addButton',

  // 是否展示树形搜索框
  BROWSER_SHOW_TREE_SEARCH: 'showTreeSearch',
  // 树形搜索关键字
  BROWSER_TREE_SEARCH_KEY: 'treeSearchKey',
  // 树形搜索提示文字
  BROWSER_TREE_SEARCH_PLACEHOLDER: 'treeSearchPlaceholder',
  // 树形默认选中节点
  BROWSER_TREE_DEFAULT_SELECTED_DATA: 'defaultTreeSelectedData',
  // 树形默认选中的节点自动展开
  BROWSER_TREE_DEFAULT_SELECTED_EXPAND_INCLUDE: 'defaultTreeSelectedExpandInclude',
  // 树形搜索结果自动展开
  BROWSER_TREE_SEARCH_EXPANDED: 'treeSearchExpanded',
};

/**
 * 多类型浏览按钮内部设置的keys
 */
export const TYPESBROWSERKEYS = {
  ENTITYTYPE: '_entityType',          // 组合类型
  ENTITYCONTENT: '_entityContent',    // 组合类型名称
  ENTITYIMID: '_entityImId',          // Im消息id
  SECRETLEVEL: '_secretLevel',        // 安全级别 起
  SECRETLEVELEND: '_secretLevelEnd',  // 安全级别 止
  CHECKSTRICTLY: '_checkStrictly',    // 包含下级
  ROLELEVEL: '_roleLevel',            // 角色级别
  ROLELEVELCONTENT: '_roleLevelContent', // 角色级别名称
  JOBLEVEL: '_jobLevel',              // 岗位级别
  JOBLEVELCONTENT: '_jobLevelContent',  // 岗位级别名称
  JOBFIELDDATA: '_jobFieldData',      // 岗位级别范围
};

/* 人员组合选的类型 */
export const TYPESBROWSERHRMTYPES = {
  RESOURCE: 'user', // 人员
  DEPARTMENT: 'dept', // 部门
  SUBCOMPANY: 'subcompany', // 分部
  GROUP: 'group', // 常用组
  JOB: 'position', // 岗位
  ROLE: 'role', // 角色
  ALL: 'all', // 所有人
  EXTERNAL: 'external', // 外部联系人
  ORGANIZATION: 'organization',  // 组织
};

/** 数据变化类型 */
export const TYPESBROWSERDATACHANGETYPE = {
  ADD: 'add',
  DELETE: 'delete',
  // DELETEALL: 'DELETEALL',
  EDIT: 'edit',
  SORT: 'sort',
}

/**
 * 分享权限浏览按钮内部设置的 keys
 */
export const SHAREBROWSERKEYS = {
  SHAREKEY: 'shareKey',
  OBJTYPE: 'objType',  // 对象类型
  OBJTYPESPAN: 'objTypeSpan',    // 对象类型名称

  TARGETID: 'targetId',
  TARGETTYPE: 'targetType',
  PERMISSIONID: 'permissionId',
  PERMISSIONTYPE: 'permissionType',
  SOURCEID: 'sourceId',
  SOURCETYPE: 'sourceType',

  ISTEMP: '_temp',

  ENTITYTYPE: '_entityType',          // 组合类型
  ENTITYCONTENT: '_entityContent',    // 组合类型名称

  OBJID: 'obj_id',  // 对象
  OBJIDSPAN: 'obj_id_span',  // 对象名称

  OBJIDLIST: 'objIdList',

  LEVEL: 'seclevel',  // 安全级别
  // HASSUB: 'has_sub',  // 包含下级
  ROLELEVEL: 'role_level',  // 角色级别
  ROLELEVELSPAN: 'role_level_span', // 角色级别名称
  POSITIONLEVEL: 'position_level', // 岗位级别
  POSITIONLEVELSPAN: 'position_level_span', // 岗位级别名称
  POSITIONLEVELSUBCOMPANY: 'position_level_subcompany',  // 指定分部
  POSITIONLEVELDEPARTMENT: 'position_level_department',  // 指定部门

  DEPARTMENTATTRIBUTE: 'department_attribute',  // 部门/分部（选项配置）
  POSITIONATTRIBUTE: "position_attribute",  // 岗位（选项配置）
  SUBORDINATE: "subordinate",  // 含下级
  PARTTIMESTAFF: "partTimeStaff",  // 含兼职
  INCLUDE_SUPERIOR: "include_superior",  // 含上级
}

export const SHAREBROWSEROBJTYPES = {
  RESOURCE: 'resource', // 人员
  DEPARTMENT: "department", // 部门
  SUBCOMPANY: 'subcompany', // 分部
  GROUP: 'group', // 常用组
  JOB: 'position', // 岗位
  ROLE: 'role', // 角色
  ALL: 'all', // 所有人
  EXTERNAL: 'external', // 外部联系人
  ALLEXTERNAL: 'allExternal', // 所有外部联系人
  ORGANIZATION: 'organization',  // 组织
}

/* 人员组合选的类型 */
export const SHAREBROWSERHRMTYPES = {
  RESOURCE: 'user', // 人员
  DEPARTMENT: 'dept', // 部门（简写）
  SUBCOMPANY: 'subcompany', // 分部
  GROUP: 'group', // 常用组
  JOB: 'position', // 岗位
  ROLE: 'role', // 角色
  ALL: 'all', // 所有人
  EXTERNAL: 'external', // 外部联系人
  ALLEXTERNAL: 'allExternal', // 所有外部联系人
  ORGANIZATION: 'organization',  // 组织
};

/** 数据变化类型 */
export const SHAREBROWSERDATACHANGETYPE = {
  ADD: 'add',
  DELETE: 'delete',
  // DELETEALL: 'DELETEALL',
  EDIT: 'edit',
  SORT: 'sort',
}

/* 多类型数据的key值（主要是浏览按钮内部使用的主键） */
export const multDataKey = '_multDataKey';
/* 多类型列表数据默认显示几条数据 */
export const MultTypeMaxLen = 3;

/* 组件内部路由 */
export const MBrowserDialogRoute = '/browser'; // 移动端浏览按钮 弹框页面 路由
export const MBrowserHRMDialogRoute = '/hrm'; // 移动端人力浏览按钮 弹框页面 路由
export const MBrowserSelectedRoute = '/selected'; // 移动端浏览按钮 已选列表页面 路由
export const MBrowserShareSettingRoute = '/share'; // 移动端浏览按钮 共享编辑页面 路由
export const MBrowserAddDialogRouter = '/addDialog'; // 移动端浏览按钮 新增弹框页面 路由
export const MAssociativeBrowserDialogRoute = '/associative_browser'; // 事项关联按钮 弹框页面 路由
export const MTypesBrowserSelectedRoute = '/types_selected'; // 人员范围选择已选列表
export const MShareBrowserSelectedRouter = '/share_selected'; // 共享范围选择已选列表

// 清除缓存的数据
export const clearCacheData = {
  listData: undefined,
  tableData: undefined,
  treeData: undefined,
  count: undefined,
  current: undefined,
  // sortKey: undefined,
  // sortType: undefined,
  pageSize: undefined,
  listTemplate: undefined,
  tableColumns: undefined,
  listIndexList: undefined,
};

export const emptyStyle = { width: 100, height: 100 };

export const freeStyle = { width: 150, height: 150 };

export const HANDLE_INPUT_SEARCH_ICON_CLICK = 'handle_input_search_icon_click';
