import Loadable from "../../react-loadable";
import { colorPickerPrefix } from '../../constants/index'
import { ColorPickerType } from "./types";

const ColorPicker = Loadable({ name: "ColorPicker", loader: () => import(
   /* webpackChunkName: "colorpicker" */
  "./ColorPicker") }) as ColorPickerType;

ColorPicker.defaultProps = {
  prefixCls: colorPickerPrefix,
  value: "#000000",
};

export type { ColorPickerProps } from "./types";
export default ColorPicker;