/*
 * @Date: 2022-03-29 10:52:54
 * @LastEditors: 屈正巍
 * @LastEditTime: 2022-05-19 20:34:51
 */
import Loadable from "../../react-loadable";
import { colorBoardClsPrefix } from '../../constants/index';
import { ColorBoardType } from "./types";

const ColorBoard = Loadable({
  name: 'ColorBoard', loader: () => import(
    /* webpackChunkName: "ui_common_less" */
    './ColorBoard')
}) as ColorBoardType;

ColorBoard.displayName = "ColorBoard";

ColorBoard.defaultProps = {
  prefixCls: colorBoardClsPrefix,
  colorCellShape: 'square'
};


export type { ColorBoardProps } from "./types";
export default ColorBoard;

