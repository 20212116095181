// 脱敏数据前缀：desensitization__
export const DESENSITIZATION = "desensitization__";

// 加密数据前缀：encryption__
export const ENCRYPTION = "encryption__";

// 加密展示标记
export const ENCRYPTION_CODE = "******";

// 脱敏图标标记
export const ENCRYPTION_CODE_ICON = "ENCRYPTION_CODE_ICON";

// 图标匹配规则
export const ENCRYPTION_CODE_ICON_MATCH = /\${ENCRYPTION_CODE_ICON_[0-9]}/;

export const ICON_CLOSE_PASSWORD = "<svg viewBox=\"0 0 16 16\"><g fill-rule=\"nonzero\" fill=\"none\"><path fill-opacity=\".01\" fill=\"#FFF\" d=\"M0 0h16v16H0z\"/><path d=\"M8 8.439c-2.338 0-4.673-.713-6.336-2.136a.48.48 0 1 1 .633-.722c2.934 2.534 8.485 2.534 11.406 0a.48.48 0 0 1 .633.722C12.673 7.726 10.338 8.44 8 8.44zm-1.736 2.097a.39.39 0 0 1-.086 0 .472.472 0 0 1-.396-.573l.317-1.87a.482.482 0 0 1 .95.165l-.316 1.87a.475.475 0 0 1-.47.408zm7.122-.95a.469.469 0 0 1-.336-.143l-1.562-1.584a.475.475 0 1 1 .675-.665l1.562 1.584a.475.475 0 0 1 0 .671.494.494 0 0 1-.34.137zm-10.772 0a.494.494 0 0 1-.317-.137.475.475 0 0 1 0-.671L3.86 7.194a.475.475 0 1 1 .656.665L2.953 9.443a.469.469 0 0 1-.339.143zm7.144.95a.475.475 0 0 1-.465-.393l-.317-1.87a.475.475 0 1 1 .925-.18l.317 1.87a.472.472 0 0 1-.384.55.39.39 0 0 1-.076.023z\" fill=\"currentColor\"/></g></svg>";

export const ICON_OPEN_PASSWORD = "<svg viewBox=\"0 0 16 16\"><g fill-rule=\"nonzero\" fill=\"none\"><path fill-opacity=\".01\" fill=\"#FFF\" d=\"M0 0h16v16H0z\"/><path d=\"M9.908 6.818a.742.742 0 1 1-1.484 0c0-.41.334-.745.745-.745.051 0 .096.02.148.026A2.283 2.283 0 0 0 8 5.675c-1.272 0-2.3 1.04-2.3 2.325A2.31 2.31 0 0 0 8 10.32 2.31 2.31 0 0 0 10.3 8c0-.495-.161-.957-.425-1.33.013.052.033.097.033.148zm4.47.957C13.305 5.81 10.774 3.825 8 3.825c-2.775 0-5.306 1.985-6.372 3.95L1.5 8l.128.225c1.066 1.959 3.597 3.95 6.372 3.95 2.774 0 5.305-1.991 6.378-3.95L14.5 8l-.122-.225zM8 11.25c-2.287 0-4.451-1.638-5.44-3.25C3.549 6.381 5.713 4.75 8 4.75c2.286 0 4.451 1.631 5.44 3.25-.989 1.612-3.154 3.25-5.44 3.25z\" fill=\"currentColor\"/></g></svg>";

export const ICON_CLOSE_PASSWORD_NAME = 'Icon-Close-password';

export const ICON_OPEN_PASSWORD_NAME = 'Icon-Open-password';

export const ENCRYPTION_SPACE = 'ENCRYPTION_SPACE';
