import { mCalendarClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { MCalendarType } from './types';

const MCalendar = Loadable({
  name: 'MCalendar',
  loader: () => import(
    /* webpackChunkName: "m_calendar" */
    './Calendar.m')
}) as MCalendarType;

MCalendar.defaultProps = {
  prefixCls: mCalendarClsPrefix,
  mode: 'month',
  type: 'one',
  rowSize: 'normal',
  disabledChangeMode: false,
  className: '',
  hideLunar: false,
  monthChangeAutoSelectFirstDate: true,
  isShowWeekStartBar: true,
  pageChangeAnimation: true
};

export type { MCalendarProps } from './types';

export default MCalendar;
