import ReactDOM from "react-dom";
import { getRandom } from '../../../../utils/index';
import { DialogMessageProps, MessageConfig, MessageType } from "./types";
import { Message } from './index';

class Notice {
  messageWrap: HTMLDivElement | null = null;
  messageRef: any = null;
  messageNum = 0;
  messageConfig: Array<MessageConfig> = [];
  getMessageId = () => `message-id-${getRandom()}`;
  matchOptionRule = (options: DialogMessageProps) => {
    const content = options.content as any;
    /**
     * http://10.12.101.12/FRONTEND/weapp-ui/-/issues/2609
     * 拦截异常数据
     */
    if (Object.prototype.toString.call(content) === '[object Object]' && !(content.type && content.$$typeof)) return false;
    else if (Object.prototype.toString.call(content) === '[object Error]') return false;
    return true;
  }
  add = (options: DialogMessageProps) => {
    if (!this.matchOptionRule(options)) return;
    if (!this.messageWrap) {
      this.messageWrap = document.createElement('div');
      document.body.appendChild(this.messageWrap);
    }
    this.messageNum ++;
    const nowOptions = {
      ...options,
      messageId: this.getMessageId(),
    }
    this.messageConfig.push(nowOptions);
    if (this.messageNum === 1) this.render(nowOptions);
    else {
      this.refreshConfig(nowOptions);
    }
  }
  refreshConfig = (options?: DialogMessageProps) => {
    this.messageRef?.refreshConfig(this.messageConfig, options?.messageId);
  }
  pop = (options: any) => {
    if (options) {
      this.messageConfig = this.messageConfig.filter((op) => op.messageId !== options.messageId);
    } else {
      this.messageConfig.shift();
    }
    this.messageNum --;
    if (this.messageNum <= 0) {
      this.destroy();
    } else {
      this.refreshConfig();
    }
    options?.destroy?.();
  }
  destroy = () => {
    if (this.messageWrap) {
      const unmount = ReactDOM.unmountComponentAtNode(this.messageWrap);
      if (unmount && this.messageWrap.parentNode) {
        this.messageWrap.parentNode.removeChild(this.messageWrap);
      }
    }
    this.messageConfig = [];
    this.messageRef = null;
    this.messageNum = 0;
    this.messageWrap = null;
  }
  setMessageRef = (el: any) => this.messageRef = el;
  render = (options: DialogMessageProps) => {
    if (this.messageWrap) {
      ReactDOM.render(<Message weId={`${options.weId || ''}_38qeqa`} {...options} destroy={this.pop} ref={this.setMessageRef} messageConfig={this.messageConfig}/>, this.messageWrap);
    }
  }
}

export default Notice;