
import { useCallback, useMemo, ReactNode } from 'react';
import { ua, weappSDK, RequestOptionConfig, getLabel, getSyncHook } from '@weapp/utils';
import { Dialog, MDialog, AnyObj } from '../../../lib';
import DatePicker from '../../date-picker';
import { isMobile, isValueEmpty } from '../../../utils';
import { batchOption, IFile, IUPLOADBATCHOPTTYPE } from '../types';
import { BatchEleProps } from '../components/pc/BatchEdit';
import { InsidePanelProps } from '../components/mb/InsidePanel';

const { getLocaleDateTime } = DatePicker;

/**
 * 文件处理
 * @param {文件} file file
 * @param {类型}} type base64 || buffer
 * @returns Promise<any>
 */
export function fileParse(file: File, type = "base64") {
  return new Promise((resolve, reject) => {
    let fileRead = new FileReader();
    if (type === "base64") {
      fileRead.readAsDataURL(file);
    } else if (type === "buffer") {
      fileRead.readAsArrayBuffer(file);
    }
    fileRead.onload = (ev) => {
      resolve(ev?.target?.result);
    };
    fileRead.onerror = (error) => {
      reject(error);
    }
  });
}

/**
 * 获取时间
 * @param {时间} time string
 * @returns string
 */
export function getdate(time: string | number | Date) {
  return getLocaleDateTime(time)
}

/**
 * 根据文件类型获取文件图标
 * @param {文件类型} fileExtendName string
 * @returns string
 */
export function getFileExtendIcon(fileExtendName: string, type?: string) {
  const isDeleted = type === 'deleted'
  let props = isDeleted ? 'Icon-other1-mcolor' : 'Icon-other';
  if (/^\.?(doc|docx|wps)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-word1-mcolor' : 'Icon-word';
  }
  if (/^\.?(txt|md)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-txt04-mcolor' : 'Icon-txt';
  }
  if (/^\.?(rar|zip|gz|z|7z)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-zip03-mcolor' : 'Icon-zip';
  }
  if (/^\.?(ppt|pptx)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-ppt1-mcolor' : 'Icon-ppt';
  }
  if (/^\.?(png|jpg|jpeg|gif|bmp|tiff|tif)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-image1-mcolor' : 'Icon-image';
  }
  if (/^\.?(pdf)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-pdf1-mcolor' : 'Icon-pdf';
  }
  if (/^\.?(html|htm|jsp|php|js|css|xml)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-html1-mcolor' : 'Icon-html';
  }
  if (/^\.?(xls|slsx|xlsx|exl)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-excel1-mcolor' : 'Icon-exl';
  }
  if (/^\.?(mp4|wmv|avi|flv|mov)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-video05-mcolor' : 'Icon-video';
  }
  if (/^\.?(oct)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-oct1-mcolor' : 'Icon-oct';
  }
  if (/^\.?(ofd)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-ofd1-mcolor' : 'Icon-ofd';
  }
  if (/^\.?(mp3)$/ig.test(fileExtendName)) {
    props = isDeleted ? 'Icon-audio1-mcolor' : 'Icon-audio';
  }
  return props;
}

export function getExtendName(name: string) {
  const index = name.lastIndexOf('.');
  const suffix = index === -1 ? '' : name.substr(index + 1);
  return suffix;
}

/**
 * 返回文件大小
 * @param {文件大小} size string
 * @returns 返回文件大小（M、KB）
 */
export const calcFileSize = (size: number) => {
  // 1 KB = 1024 B
  // 1 MB = 1024 KB
  // 1 GB = 1024 MB
  // 1TB = 1024GB
  if (!size) return '';
  const num = 1024.00; // byte
  if (size >= Math.pow(num, 3)) {  // GB
    return `${parseFloat((size / Math.pow(num, 3)).toFixed(2))}GB`
  } else if (size >= Math.pow(num, 2)) {  // MB
    return `${Math.floor(size / Math.pow(num, 2))}MB`
  } else if (size >= 1024) {  // KB
    return `${Math.floor(size / 1024)}KB`
  } else if (size >= 10) {  // KB
    return `${parseFloat((size / 1024).toFixed(2))}KB`
  } else {  // B
    return `${size}B`;
  }
}

export const signButtons = ['sign', 'signFirst', 'signBack', 'signinv', 'share', 'apply']


export const isSafari = ua.browser === 'Safari';
export const isIphone = ua.os === 'iOS';
export const isIos = isSafari || isIphone;
/**
  * 是否 em 中且 sdk 正常使用
  * @returns boolean
  */
export const isEmSDK = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let canChooseImage = await weappSDK.checkApi('chooseImage')
      let canChooseFile = await weappSDK.checkApi('chooseFile')
      let canUploadFile = await weappSDK.checkApi('uploadFile')
      if (weappSDK && canChooseImage === 'success' && canChooseFile === 'success' && canUploadFile === 'success') {
        resolve(true);
      }
      // return false;
      resolve(false);
    } catch (err) {
      // return false;
      resolve(false);
    }
  })
}

/**
 * 打开链接（企业微信中会拦截 window.open，切换为 href）
 * @param url string
 */
export const openLink = (url: string) => {
  const hooks = getSyncHook('weappUi', 'Upload.openLink.overwrite');
  if (typeof hooks === 'function') {
    hooks({ url, isMobile });
  } else {
    if (isMobile) {
      // openType: 2 在 e10 app 中，会走 open 逻辑
      weappSDK.openLink({ url, type: 'href', openType: 2 })
    } else {
      weappSDK.openLink({ url })
    }
  }
}

/**
 * 处理 url
 * @param url string
 * @param loginFree boolean
 * @param ismobile boolean
 * @returns string
 */
export const getRealUrl = (url: string, params?: {
  loginFree?: boolean,
  isMobile?: boolean,
  fileId?: string,
}) => {
  let apiPrefix = params?.loginFree ? 'papi' : 'api';
  const _isMobile = typeof params?.isMobile !== 'undefined' ? params.isMobile : isMobile;
  if (_isMobile) apiPrefix += '/app';
  let newUrl = url?.replace('{api}', apiPrefix);
  if (params?.fileId) {
    newUrl = newUrl.replace('{fileId}', params?.fileId);
  }
  return newUrl;
}

export const getRealConfig = (config: RequestOptionConfig) => {
  const newConfig: RequestOptionConfig = {
    url: config.url,
    method: config.method,
  }

  if (config.method === 'get' || config.method === 'GET') {
    newConfig.params = config.params
  } else if (config.method === 'post' || config.method === 'POST') {
    newConfig.data = config.params
  }

  return newConfig
}

export const progressFile = (file: File | IFile, newName?: string) => {
  // IE 不支持 new File()，这里 File 不识别的话重写一下
  let File: any = window.File;
  try {
    new File([], '')
  } catch (e) {
    File = class File extends Blob {
      lastModifiedDate: number;
      lastModified: number;
      name: string;
      constructor(chunks: BlobPart[], filename: string, opts?: FilePropertyBag) {
        super(chunks, opts);
        let _lastModified = opts?.lastModified || (+ new Date());
        this.lastModifiedDate = _lastModified;
        this.lastModified = _lastModified;
        this.name = filename;
      }
    }
  }
  let newFile: any | IFile = new File([file], newName || file.name);
  if ('uid' in file && file?.uid) newFile.uid = file.uid;
  if ('flag' in file && file?.flag) newFile.flag = file.flag;
  // 处理文件替换
  if ('replaceFlag' in file && file?.replaceFlag) newFile.replaceFlag = file.replaceFlag;
  // 处理密级和密级期限
  if ('secretLevel' in file && file?.secretLevel) newFile.secretLevel = file.secretLevel;
  if ('secretLevelValidity' in file && file?.secretLevelValidity) newFile.secretLevelValidity = file.secretLevelValidity;
  // 名称
  if ('flag' in file && (file as any)?.rename) newFile.rename = (file as any).rename;

  return newFile;
}

export const isJSONString = (str: string) => {
  try {
    var obj = JSON.parse(str);
    if (obj && typeof obj === 'object') {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

export const subCalcGlobalVariable = (key: string) => {
  if (window?.ui_upload_ecCom?.[key] > 0) {
    window.ui_upload_ecCom[key] -= 1;
  } else if (window?.ui_upload_ecCom) {
    window.ui_upload_ecCom[key] = 0
  } else {
    window.ui_upload_ecCom = { [key]: 0 }
  }
}

export const addCalcGlobalVariable = (key: string) => {
  if (window?.ui_upload_ecCom?.[key]) {
    window.ui_upload_ecCom[key] += 1;
  } else if (window?.ui_upload_ecCom) {
    window.ui_upload_ecCom[key] = 1
  } else {
    window.ui_upload_ecCom = { [key]: 1 }
  }
}


export const useBatchEditList = (props: BatchEleProps | InsidePanelProps) => {

  const { showBatchSign, batchDownload, batchDelete, listT, listB, listType, readOnly, disabled } = props;

  const fliterBatchFileld = useCallback((key: string, condition?: boolean) => {
    let use = false;
    if (key === IUPLOADBATCHOPTTYPE.SHOWBATCHSIGN && listT.length > 0) {  // 批量签署需要判断 ECODE 逻辑
      use = condition === true || (condition === void 0 && window.ECODE?.signcenter_signBatch === true)
    } else if (key === IUPLOADBATCHOPTTYPE.BATCHDOWNLOAD && listT.length > 0) {
      use = !!condition
    } else if (key === IUPLOADBATCHOPTTYPE.BATCHDELETE && (listT.length > 0 || listB.length > 0)) {
      use = !!condition
    }
    const status = listType !== 'img' && use;
    return status
  }, [listType, listT.length, listB.length]);

  const batchEditList = useMemo(() => {
    const list: batchOption[] = [
      {
        id: IUPLOADBATCHOPTTYPE.SHOWBATCHSIGN,
        content: getLabel("59799", "批量签署"),
        icon: "Icon-Batch-editing-o",
        visible: fliterBatchFileld(IUPLOADBATCHOPTTYPE.SHOWBATCHSIGN, showBatchSign)
      },
      {
        id: IUPLOADBATCHOPTTYPE.BATCHDOWNLOAD,
        content: getLabel("140340", "批量下载"),
        icon: "Icon-Batch-download",
        visible: fliterBatchFileld(IUPLOADBATCHOPTTYPE.BATCHDOWNLOAD, batchDownload)
      },
      {
        id: IUPLOADBATCHOPTTYPE.BATCHDELETE,
        content: getLabel("19635", "批量删除"),
        icon: "Icon-Batch-delete03",
        visible: fliterBatchFileld(IUPLOADBATCHOPTTYPE.BATCHDELETE, !(readOnly || disabled) && batchDelete)
      }
    ];
    return list;
  }, [showBatchSign, batchDownload, batchDelete, readOnly, disabled, fliterBatchFileld]);

  const batchOptions = batchEditList.filter(item => item.visible);

  const length = batchOptions?.length;

  return {
    batchOptions,
    length
  }
}

/** message 提示信息 */
export const message = (option: {
  type: 'info' | 'error' | 'success' | 'warn',
  content?: string | ReactNode,
  delay?: number
}) => {
  if (isMobile) {
    let type: any = option.type === 'error' ? 'fail' : option.type;
    let delay = option.delay ? option.delay : isMobile ? 2000 : undefined;
    MDialog.toast({ type: type, content: option.content, delay: delay })
  } else {
    let type: any = option.type === 'warn' ? 'info' : option.type;
    Dialog.message({ type: type, content: option.content, delay: option.delay })
  }
}

export const confirm = (option: {
  content?: string | React.ReactNode,
  onCancel?: () => void,
  onOk?: () => void,
  cancelText?: string,
  okText?: string,
  mask?: boolean,
}) => {
  if (isMobile) {
    MDialog.prompt({
      title: option.content as any,
      mask: true,
      maskClosable: true,
      prompt: false,
      onClose: option?.onCancel,
      onOk: option?.onOk,
      okText: option?.okText,
      cancelText: option?.cancelText
    })
  } else {
    Dialog.confirm(option)
  }
}

export const getParams = (params?: AnyObj) => {
  // return qs.stringify(params);
  let url = '';
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      if (typeof value === 'object') {
        url += `&${key}=${encodeURIComponent(JSON.stringify(value))}`;
      } else {
        url += `&${key}=${encodeURIComponent(value)}`;
      }
    }
  }
  return url ? url.substring(1) : '';
}

/** 拼接 params 到 url 上 */
export const getUrl = (data: {
  url: string,
  params?: AnyObj
}) => {
  let url = data.url;
  const params = data?.params;
  return url += (url.indexOf('?') < 0 ? '?' : '') + getParams(params);
}

/**
 * @description: 实现Promise的串行
 * @param {*}: 接收一个包含多个返回Promise对象的函数的数组
 * @return {*}: 返回一个Promise对象
 */
export function promiseInOrder(arr: Promise<unknown>[]) {
  const res: any[] = [];
  return new Promise((resolve, reject) => {
    arr
      .reduce((pre, cur) => {
        return pre.then(cur as any).then(data => res.push(data))
      }, Promise.resolve())
      .then(() => resolve(res))
  })
}

/**
 * 数字大小比较
 * @param versionA string
 * @param versionB string
 * @returns 比较 A 和 B 的大小，返回 GTR（A大于B），EQU（相等），LSS（A小于B）
 */
export function compareNumber(A: number, B: number) {
  if (A > B) {
    return 'GTR';
  } else if (A === B) {
    return 'EQU';
  } else {
    return 'LSS';
  }
}

/**
 * 比对 ua 大小
 * @param browser 用于比对的 ua 信息
 * @returns boolea
 */
export function compareUA(compareUa?: {
  os?: string,
  browser?: string,
  version?: string
}) {
  const { os, browser, version } = compareUa || {};
  if (isValueEmpty(compareUa) || !version) {
    return false;
  }
  let needCompare = false;
  if (os && browser) {
    if (os === ua.os && browser === ua.browser) {
      needCompare = true;
    } else {
      needCompare = false
    }
  } else {
    if ((os && os === ua.os) || (browser && browser === ua.browser)) {
      needCompare = true;
    } else {
      needCompare = false;
    }
  }
  if (needCompare) {
    const versionArr = version.split('.');
    const uaVersionArr = ua.version.split('.');
    const len = versionArr.length > uaVersionArr.length ? uaVersionArr.length : versionArr.length;
    for (let i = 0; i < len; i++) {
      const res = compareNumber(Number(uaVersionArr[i]), Number(versionArr[i]));
      if (res === 'GTR') {
        return true;
      } else if (res === 'LSS') {
        return false;
      }
    }
  } else {
    return false;
  }
}

/** 两个数组取交集 */
export const intersection = (arr1: any[], arr2: any[]) => {
  return arr1.filter(item => arr2.indexOf(item) > -1);
}

/** 两个数组取并集 */
export const union = (arr1: any[], arr2: any[]) => {
  return arr1.concat(arr2.filter(item => !(arr1.indexOf(item) > -1)));
}

/** 两个数组取差集（数组 arr1 相对于 arr2 所没有的集合 */
export const diff = (arr1: any[], arr2: any[]) => {
  return arr1.filter(val => arr2.indexOf(val) === -1)
}

/**
 * Blob 转 File
 * @param blob Blob
 * @param fileName string
 * @returns File
 */
export function blobToFile(blob: Blob, fileName: string): File {
  let file: any;
  try {
    // IE11 不支持直接使用 Blob 对象创建 File 对象，需要通过 File 构造函数来创建
    file = new File([blob], fileName, {
      type: blob.type
    });
  } catch (error) {
    // 如果在 IE11 中出现错误，则使用旧的 BlobBuilder API 创建 File 对象
    const blobBuilder = new (window.BlobBuilder || (window as any).MSBlobBuilder)();
    blobBuilder.append(blob);
    file = blobBuilder.getBlob();
    file.name = fileName;
    if (blob.type) file.type = blob.type;
  }
  return file;
}

/** url 拼接参数 */
export function urlSplicingParams(url: string, params: AnyObj) {
  let prefix = '?', connector = '&', paramsStr = '';
  if (url.indexOf('?') !== -1) { prefix = '&' };
  Object.keys(params).forEach((key, index) => {
    const identify = index === 0 ? prefix : connector;
    if (params[key] && typeof params[key] === 'object') {
      paramsStr += identify + `${key}=${encodeURIComponent(JSON.stringify(params?.[key]))}`;
    } else if (params[key]) {
      paramsStr += identify + `${key}=${params[key]}`;
    }
  })
  return url += paramsStr;
}
