import React, { MouseEventHandler, Component } from 'react';
import { classnames, ua } from '@weapp/utils';
import { DialogWrapProps, DialogWrapState, DialogContentRef } from '../types';
import DialogContent from './DialogContent';
import { addEventListenerWrap, closest, contains, hideBodyOverflow, isEqual, isNil, needRTL, resetBodyOverflowCss, setWeappUiOverflowCss } from '../../../utils';
import { hrmCardClsPrefix, messageClsPrefix, richTextClsPrefix } from '../../../constants';
import { getDefaultDraggable } from '../util';
interface AddEventListerner {
  remove?: () => void;
}
class DialogWrap extends Component<DialogWrapProps, DialogWrapState> {

  constructor(props: DialogWrapProps) {
    super(props);
    this.state = {
      innerScale: false,
    }
    setWeappUiOverflowCss();
  }
  /*  -------------------------Refs-----------------------*/

  maskRef = React.createRef<HTMLDivElement>();
  wrapperRef = React.createRef<DialogContentRef>();
  clickOutsideHandler: AddEventListerner | null = null;

  /*  ---------------------Dialog body滚动层高度-----------------------*/

  componentDidMount() {
    const { noMaskClose } = this.props;
    if (noMaskClose) this.addOutsideHandler();
    const { visible, defaultInnerScale,innerScale } = this.props;
    if(!isNil(innerScale)){
      if (innerScale) { // 放大
        hideBodyOverflow();
      } else {
        resetBodyOverflowCss();
      }
      return
    }
    if (visible && defaultInnerScale) { // 打开弹框，默认最大化
      this.onScale(true);
    }
  }
  getSnapshotBeforeUpdate(preProps: DialogWrapProps) {
    const { visible: preVisible,noMaskClose:preNoMaskClose } = preProps;
    const { visible, defaultInnerScale,innerScale,noMaskClose } = this.props;
    if(!isEqual(preNoMaskClose,noMaskClose)){
      if(noMaskClose&&!this.clickOutsideHandler){
        this.addOutsideHandler()
      }
      if(!noMaskClose&&this.clickOutsideHandler){
        this.clearOutsideHandler()
      }
    }
    if(!isNil(innerScale)&& visible){
      if (innerScale) { // 放大
        hideBodyOverflow();
      } else {
        resetBodyOverflowCss();
      }
      return
    }
    if (!preVisible && visible && defaultInnerScale) { // 打开弹框，默认最大化
      this.onScale(true);
    }

  }
  componentWillUnmount() {
    this.clearOutsideHandler();
  }
  // ---------------------add/clear document click event-------------------------------
  addOutsideHandler = () => {
    const currentDocument = window.document.body;
    this.clickOutsideHandler = addEventListenerWrap(currentDocument, 'mousedown', this.onDocumentClick);
  }
  clearOutsideHandler = () => {
    if (this.clickOutsideHandler && this.clickOutsideHandler.remove) {
      this.clickOutsideHandler.remove();
      this.clickOutsideHandler = null;
    }
  }
  onDocumentClick = (event: any) => {
    const { noMaskClose, onClose, visible, dialogId, prefixCls, dialogConfig } = this.props;
    const closestDom = closest(event.target, `div[id="${dialogId}"]`);
    const cloestContainer = closest(event.target, `div[class="${prefixCls}-container"]`);
    const closetPortal = closest(event.target, `div[class="${prefixCls}-portal"]`);
    const closetConfirmPortal = closest(event.target, `div[class="${prefixCls}"]`);
    const closetMessagePortal = closest(event.target, `div[class="${messageClsPrefix}"]`);
    const closetPhotoViewPortal = closest(event.target, `div[id="PhotoView_Slider"]`);
    const ckDialog = window.document.body.className === 'cke_dialog_open' || event.target?.id?.indexOf?.('cke') >= 0 || event.target?.className?.indexOf?.('cke_') >= 0;
    const closestRichAt = closest(event.target, `div.${richTextClsPrefix}-atmention-container`);
    const closestHrmCard = closest(event.target, `span[class="${hrmCardClsPrefix}-popup"]`)
    let pass = false;
    if (dialogConfig) {
      const whitelist = dialogConfig?.whitelist;
      if (whitelist) {
        const len = whitelist?.length || 0;
        for (let i = 0; i < len; i++) {
          const closestDom = closest(event.target, whitelist[i]);
          if (closestDom) {
            pass = true;
            break;
          }
        }
      }
    }

    if (!pass && (closestDom || cloestContainer || closetPortal || closetConfirmPortal || closetMessagePortal || ckDialog || closetPhotoViewPortal || closestHrmCard || closestRichAt)) {
      return;
    }
    if (dialogConfig) {
      const defaultBlacklist =
        ['span[class="cke_button_icon"]',
          'ul[class~="ui-cascader-menu"]',
          'ul[class~="rc-cascader-menu"]',
          'li[class~="ui-cascader-menu-item"]',
          'li[class~="rc-cascader-menu-item"]',
          'div[class~="ui-cascader-city-picker-header"]',
          'span[class~="ui-cascader-multi-suffix-icon"]',
          'div[class~="rc-cascader-selection-overflow-item"]',
          'div[class~="tippy-content"]', // 模板编辑器工具栏
          'div[class*="svelte-"]', // 模板编辑器弹层内按钮
        ]; // 默认黑名单设置
      const cloestContainers = defaultBlacklist.concat(dialogConfig?.blacklist || dialogConfig?.cloestContainers || []);
      if (cloestContainers) {
        let cloest = false;
        const len = cloestContainers?.length || 0;
        for (let i = 0; i < len; i++) {
          const closestDom = closest(event.target, cloestContainers[i]);
          if (closestDom) {
            cloest = true;
            break;
          }
        }
        if (cloest) return;
      }
    }
    if (noMaskClose) {
      const currentContain = contains(this.wrapperRef.current?.getElement(), event.target)
      //页签模式下需要配置全局点击关闭黑名单
      //ui组件库的popver
      const closePopover = closest(event.target, 'div[class="ui-trigger-popupInter-container"]')
      //判断是否触发onClose
      if (onClose && visible) {
        if (!currentContain && !closePopover) {
          onClose();
          this.setInnerScale(false);
        }
      }
    }
  }

  /* --------------------------关闭弹框--------------------------------------- */

  close = (e: any) => {
    const { maskClosable, onClose, placement, isStopPropagation } = this.props;
    if (isStopPropagation) {
      if (e.stopPropagation) e.stopPropagation()
      else e.cancelBubble = true
    }
    if (maskClosable && onClose) {
      if ((placement === 'middle' && e.target === this.wrapperRef.current?.getElement()) || (e.target === this.maskRef.current)) {
        onClose();
        this.setInnerScale(false);
      }
    }
  }

  /* --------------------------放大/缩小--------------------------------------- */
  setInnerScale = (innerScale: boolean) => {
    if (innerScale) { // 放大
      hideBodyOverflow();
    } else {
      resetBodyOverflowCss();
    }
    this.setState((prevState) => {
      return {
        innerScale: prevState.innerScale
      }
    }
    );
    // this.setState({ innerScale });
  }

  onScale = (scale: boolean) => {
    const { onScale, innerScale } = this.props;
    if (!isNil(innerScale)) {
      if (typeof onScale === 'function') onScale(!innerScale);
      return
    }
    if (scale) { // 放大
      hideBodyOverflow();
    } else {
      resetBodyOverflowCss();
    }
    this.setState({ innerScale: scale }, () => {
      if (typeof onScale === 'function') onScale(scale);
    });
  }

  doScale: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation && e.stopPropagation();
    e.preventDefault && e.preventDefault();
    const { innerScale: _innerScale } = this.state;
    const { innerScale: _scale } = this.props
    const innerScale = isNil(_scale) ? _innerScale : _scale //优先取props
    const contentDom = this.wrapperRef.current?.getContentElement();
    !innerScale && contentDom && (contentDom.style.height = `${contentDom?.clientHeight}px`);
    this.onScale(!innerScale);
  }

  // 判断是否为拖拽区域
  canDrag = (e: any) => {
    if (!e.target) return false;
    const { prefixCls, getTitle } = this.props;
    const titleDom = closest(e.target, '.ui-title-title-top')
    const iconDom = closest(e.target, '.ui-title-icon')
    const rightContainer = closest(e.target, '.ui-title-right-container')
    const menuList = closest(e.target, '.ui-menu-list')
    const leftContainer = closest(e.target, '.ui-title-left')
    if (titleDom || menuList) return false;
    if (getTitle && !iconDom && leftContainer && !leftContainer?.className?.includes(e.target?.className) && !e.target?.className?.includes('ui-title-title')) return false
    if (rightContainer && rightContainer?.className !== e.target?.className) return false
    const closestDom = closest(e.target, `div[id="${prefixCls}-header-draggable"]`);
    return closestDom;
  }

  render() {
    const {
      prefixCls, children, width, height, placement, draggable: propDraggable,
      visible, destroyOnClose, // visible受控
      mask: propMask, maskStyle, // Mask
      scale, zIndex,// scale
      top, bottom, left, right, // position
      wrapClassName, wrapStyle, // 外层容器样式
      className, closeAnimation, resize, bodyStyle,
      maxResizeWidth, minResizeWidth, maxResizeHeight, minResizeHeight,
      dialogId, customRenderInDialogContainer,
      resetDragPosition,
      innerScale: _scale,
      ...resProps
    } = this.props;
    const { innerScale: _innerScale } = this.state;
    const innerScale = isNil(_scale) ? _innerScale : _scale //优先取props
    let mask = propMask;
    if (mask !== false && (!placement || placement === 'middle')) mask = true; // 从中间弹出的弹框默认打开蒙层

    let draggable = getDefaultDraggable(propDraggable, placement);

    const contentCls = classnames(`${prefixCls}-content`, `${prefixCls}-content-${placement}`, {
      [`${prefixCls}-content-scale`]: scale,
      [`${prefixCls}-content-scale-transition`]: innerScale,
      [`${prefixCls}-content-hasMask`]: mask,
    })

    const wrapCls = classnames(`${prefixCls}-wrap ${prefixCls}-wrap-${placement}`, {
      [`${prefixCls}-content-scale`]: scale,
      [`${prefixCls}-content-scale-transition`]: innerScale,
      [`${prefixCls}-content-scale-closeAnimation`]: closeAnimation,
      [`${prefixCls}-wrap-hasMask`]: (!placement || placement === 'middle') && mask,
      [`${prefixCls}-wrap-middle-center`]: innerScale || (placement === 'middle' && !top && top !== 0)
    })

    const maskStyles = maskStyle ? { ...maskStyle, zIndex } : { zIndex };

    const containerCls = classnames(`${prefixCls}-container`, {
      rtlSafari: needRTL() && ua.browser === 'Safari'
    }, wrapClassName, className);
    const maskCls = classnames(`${prefixCls}-mask`, {
      [`${prefixCls}-mask-hide`]: !visible,
      [`${prefixCls}-mask-${placement}`]: placement,
    })
    return (<div className={containerCls} style={wrapStyle}>
      {mask && (<div className={maskCls} ref={this.maskRef} onClick={this.close} style={maskStyles} />)}
      {visible && customRenderInDialogContainer}
      <DialogContent weId={`${this.props.weId || ''}_jqz4rv`}
        wrapCls={wrapCls}
        contentCls={contentCls}
        width={width}
        height={height}
        scale={scale}
        innerScale={innerScale}
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        placement={placement}
        onCloseContent={this.close}
        ref={this.wrapperRef}
        zIndex={zIndex}
        visible={visible}
        prefixCls={prefixCls}
        draggable={draggable}
        canDrag={this.canDrag}
        closeAnimation={closeAnimation}
        resize={resize}
        maxResizeWidth={maxResizeWidth}
        minResizeWidth={minResizeWidth}
        maxResizeHeight={maxResizeHeight}
        minResizeHeight={minResizeHeight}
        destroyOnClose={destroyOnClose}
        bodyStyle={bodyStyle}
        {...resProps}
        doScale={this.doScale}
        dialogId={dialogId}
        setInnerScale={this.setInnerScale}
        resetDragPosition={resetDragPosition}
      >
        {children}
      </DialogContent>
    </div >)
  }
}

export default DialogWrap;
