import { dayjs } from '@weapp/utils'
import { DatePickerProps, DateType, DateTimePickerProps, DateQuickFilterProps } from '../../types';
import { isValid, isValidRange, formatDate, formatDateTime, getTitleDate } from '../index';
import { DatePickerState } from '../../date-picker/DatePicker'
import { DateTimePickerState } from '../../date-time-picker/DateTimePicker'
import { DateQuickFilterState } from '../../date-quick-filter/DateQuickFilter'

export function getDatePickerNewState(value: DateType | DateType[], props: DatePickerProps) {
  const { type, isRange } = props
  const newState: Partial<DatePickerState> = {}
  const v = formatDate(value, type) as any
  const titleDate = getTitleDate(value, props)
  if (isValid(value)) {
    if (isRange) {
      if (isValidRange(value as DateType[], 'date')) {
        newState.titleDate = titleDate
        newState.inputStartDate = v[0]
        newState.inputEndDate = v[1]
        newState.rangeDate = v
      }
    } else {
      if (type === 'days' || type === 'months' || type === 'years' || type === 'seasons' || type === 'halfyears') {
        newState.titleDate = titleDate
        newState.inputDate = v.join(', ')
        newState.datesOption = v
        newState.dates = v
      } else {
        newState.titleDate = titleDate
        newState.inputDate = v
        newState.date = v
      }
    }
    return newState
  }
}

export function getDateTimePickerNewState(value: DateType | DateType[], props: DateTimePickerProps, state: DateTimePickerState, realValue?: DateType | DateType[] | null) {
  const { isRange, timeFormat = 'HH:mm:ss', openDateMode } = props
  let { dateMode } = state
  //非范围选择的时候需要根据内部dateMode的状态或者外部传入value的标准格式来进行改变 -> 注意：此处需要存真实的real 2021.12.01 为了兼容国际化时间校验
  if (dayjs(realValue as string, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm']).isValid()) { dateMode = false }
  const newState: Partial<DateTimePickerState> = {}
  const v = formatDateTime(value, timeFormat, openDateMode && dateMode) as any
  if (isValid(value)) {
    if (isRange) {
      if (isValidRange(value as DateType[], 'datetime')) {
        newState.titleDate = v[0]
        newState.inputStartDateTime = v[0]
        newState.inputEndDateTime = v[1]
        newState.dateTimeRange = v
      }
    } else {
      newState.titleDate = v
      newState.inputDateTime = v
      newState.dateTime = v
      newState.dateMode = dateMode
    }
  }
  return newState
}

export function getDateQuickFilterNewState(value: DateType, props: DateQuickFilterProps) {
  const { type } = props
  const v = formatDate(value, type) as string
  const newState: Partial<DateQuickFilterState> = {}
  if (isValid(value)) {
    newState.titleDate = v
    newState.date = v
  }
  return newState
}