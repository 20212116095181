import { Attributes } from "react";
import Loadable from "../../../react-loadable";
import { AnyObj } from "../../../types/common";
import { FormItemRenderProps } from "../../form-item";
import { FormSwitchProps } from "../../form-switch";
import { isNeedBrowserIncludingSub } from "../store/browser-including-subordinate";

const browserTypes = ['department', 'subcompany', 'organization']; // 部门、分部、组织架构

export const inBrowserTypes = (type?: string) => (type && browserTypes.indexOf(type) > -1);

const BrowserIncludingSub = Loadable({
  name: 'SearchAdvanced.BrowserIncludingSub', loader: () => import(
    /* webpackChunkName: "searchadvanced" */
    './BrowserIncludingSub')
})

export const customRenderAllFormSwitch = (id: string, props: FormSwitchProps, com: React.ReactNode, otherParams?: AnyObj) => {
  const allProps = props?.props || {};
  if (isNeedBrowserIncludingSub(allProps)) {
    return <BrowserIncludingSub weId={`${props.weId || ''}_ywg5sk`} {...otherParams} allProps={allProps} id={id} props={props}>{com}</BrowserIncludingSub>
  }
  return com;
}

export const customRenderFormItemM = (id: string, props: FormItemRenderProps & Attributes, com: React.ReactNode, otherParams?: AnyObj) => {
  const item = props?.item
  if (!item) return com;
  const hasSubBrowserKey = Object.keys(item).find((k) => isNeedBrowserIncludingSub(item[k]));
  if (!hasSubBrowserKey) return com;
  return <BrowserIncludingSub weId={`${props.weId || ''}_prklvw`} props={props} {...otherParams} isMobile allProps={item[hasSubBrowserKey]} id={hasSubBrowserKey}>{com}</BrowserIncludingSub>
}
