import { textareaClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { TextareaType } from './types';

const Textarea = Loadable({ name: 'Textarea', loader: () => import(
  /* webpackChunkName: 'ui_form' */
  './Textarea'
)}) as TextareaType;

Textarea.defaultProps = {
  prefixCls: textareaClsPrefix
}

export type { TextareaProps } from './types';

export default Textarea;
