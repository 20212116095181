type TArray<T> = T[];
export type TreeRootIdArray = TArray<string | number>;

// 默认节点渲染key
export const DEFAULT_RENDER_KEY: ITreeRenderKey = {
  /* 数据主键的键名 默认 id */
  dataKey: 'id',
  /* 数据提示的键名 默认 title */
  dataTitle: 'title',
  /* 数据类型的键名 默认 type */
  dataType: 'type',
  /* 下级节点的键名 默认 children */
  dataChildren: 'children',
  /* 父级节点的键名 默认 parentId */
  dataParentId: 'parentId',
  /* 禁用标示的键名 默认 disabled */
  dataDisabled: 'disabled',
  /* 可选标示的键名 默认 checkable */
  dataCheckable: 'checkable',
  /* 数据显示值的键名 默认 content */
  dataContent: 'content'
};

export interface ITreeRenderKey {
  dataKey: string;
  dataTitle: string;
  dataChildren: string;
  dataParentId: string;
  dataDisabled: string,
  dataCheckable: string,
  dataType: string,
  dataContent: string
}

export interface ITreeData {
  // DEFAULT_RENDER_KEY['id']?: string | number;
  // [DEFAULT_RENDER_KEY[parentId]]?: string | number;
  // [DEFAULT_RENDER_KEY[title]]?: React.ReactNode;
  // [DEFAULT_RENDER_KEY[children]]?: ITreeData[];
  // id: string | number;
  DEFAULT_RENDER_KEY?: string | number;
  expand?: boolean;
  isLeaf?: boolean;
  [key: string]: any;
}

export interface ITreeRootInfoMap {
  [key: string]: {
    id: string | number;
    parentId?: string | number;
    root: ITreeData;
    isExpand: boolean;
    isParent: boolean;
    son: TreeRootIdArray;
    includes: TreeRootIdArray;
    level: number;
    disabledCheck: boolean
    hiddenCheckboxByDisabled: boolean
    // 节点的选择联动集合 { nodeId: id[] }
    // id[]: [nodeId, nodeId.children[1]Id, nodeId.children[2]Id, .... nodeId.children[1][...].childrenn[N]Id]
    // 可以根据当前node的id, 查找它将会影响的子孙节点
  };
}

// 根据指定id，在其子类添加数据，兼容‘加载更多’场景
export const appendDataFromNode = (id: number | string, data: ITreeData[], appendData: ITreeData[]): ITreeData[] => {
  let arr = []
  if (data) {
    for (let item of data) {
      if (item.id == id) {
        const index = item?.children?.findIndex((item: ITreeData) => item.displayType === 'LOADMORE')
        if (index > -1) {
          item.children.splice(index, 0, ...appendData)
        } else if (item.children) {
          item.children = [
            ...item.children,
            ...appendData
          ]
        } else {
          item.children = [
            ...appendData
          ]
        }
      } else if (item.children) {
        appendDataFromNode(id, item.children, appendData)
      }
      arr.push(item)
    }
  }
  return arr
}

// 虚拟模式搜索过滤
export const filterSearchTree = (treeData: ITreeData[], filterData: TreeRootIdArray): ITreeData[] => {
  const result:ITreeData[] = [];
  function traverse(node: ITreeData) {
    if (filterData.includes(node.id)) {
      const newNode = { ...node };
      if (node.children) {
        newNode.children = node.children.map(traverse).filter((child: TreeRootIdArray) => child !== null);
      }
      return newNode;
    } else if (node.children) {
      const newChildren = node.children.map(traverse).filter((child: TreeRootIdArray) => child !== null);
      if (newChildren.length > 0) {
        const newNode = { ...node };
        newNode.children = newChildren;
        return newNode;
      }
    }
    return null;
  }

  treeData.forEach(node => {
    const newNode = traverse(node);
    if (newNode !== null) {
      result.push(newNode);
    }
  });

  return result;
}

// 开启虚拟列表，初始化组件所需要的数据结构，且配置需要默认展开的节点
// data 树形的初始数据
// expandedKeys 需要展开的节点
// renderKey 定制化key
// loadArr  根据pageInfo获取的‘加载更多’的节点（displayType: 'LOADMORE'）
export const initVirtualTree = (data: ITreeData[], expandedKeys: TreeRootIdArray, renderKey: ITreeRenderKey, loadArr?: (number | string)[]) => {
  return data.map((item: ITreeData) => {
    if (item[renderKey['dataChildren']] && item[renderKey['dataChildren']].length > 0) {
      if (expandedKeys.length > 0 && expandedKeys.includes(item[renderKey['dataKey']])) {
        item.state = {
          expanded: true
        }
        expandedKeys = expandedKeys.filter(i => i !== item[renderKey['dataKey']])
      } else {
        item.state = {
          expanded: false
        }
      }
      if (loadArr && loadArr.includes(item[renderKey['dataKey']])) {
        if (item[renderKey['dataChildren']].findIndex((item: ITreeData) => item.displayType === 'LOADMORE') < 0) {
          item[renderKey['dataChildren']].push({
            id: `loadMore-${item[renderKey['dataKey']]}`,
            displayId: item[renderKey['dataKey']],
            displayType: 'LOADMORE',
          })
        }
        loadArr = loadArr.filter(i => i !== item[renderKey['dataKey']])
      }
      initVirtualTree(item[renderKey['dataChildren']], expandedKeys, renderKey, loadArr)
    }
    if (!('id' in item)) {
      item.id = item[renderKey['dataKey']]
    }
    item.children = item[renderKey['dataChildren']]
    return item
  })
}
