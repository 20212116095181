import React from 'react';
import { classnames } from '@weapp/utils';
import { BAR_MAP, renderThumbStyle } from './utils/index';
import { scrollerClsPrefix } from '../../constants/index'
import { pauseEvent } from '../../utils/index';

interface IBarProps {
  vertical?: boolean;
  size: string;
  move: number;
  getParentWrap: () => any;
  randomId: string
}

export class Bar extends React.Component<IBarProps, any> {

  private thumbRef: HTMLDivElement | undefined | null;
  private rootRef: HTMLDivElement | undefined | null;

  private cursorDown: Boolean | undefined;

  get bar() {
    return BAR_MAP[this.props.vertical ? 'vertical' : 'horizontal'];
  }

  get wrap() {
    return this.props.getParentWrap();
  }

  setRootRef = (ref: HTMLDivElement) => {
    this.rootRef = ref;
  }

  setThumbRef = (ref: HTMLDivElement) => {
    this.thumbRef = ref;
  }

  componentWillUnmount() {
    this.thumbRef = null
    this.rootRef = null

    document.removeEventListener('mousemove', this.mouseMoveDocumentHandler);
    document.removeEventListener('mouseup', this.mouseUpDocumentHandler);
  }

  clickThumbHandler = (e: any) => {
    this.startDrag(e);
    // @ts-ignore
    this[this.bar.axis] = (e.currentTarget[this.bar.offset] - (e[this.bar.client] - e.currentTarget.getBoundingClientRect()[this.bar.direction]));
  }

  clickTrackHandler = (e: any) => {
    const offset = Math.abs(e.target.getBoundingClientRect()[this.bar.direction] - e[this.bar.client]);

    if (!(this.wrap && this.rootRef && this.thumbRef)) return;

    // @ts-ignore
    const thumbHalf = this.thumbRef[this.bar.offset] / 2;
    // @ts-ignore
    const thumbPositionPercentage = (offset - thumbHalf) * 100 / this.rootRef[this.bar.offset];
    // @ts-ignore
    this.wrap[this.bar.scroll] = thumbPositionPercentage * this.wrap[this.bar.scrollSize] / 100;
  }

  startDrag = (e: any) => {
    pauseEvent(e);
    this.cursorDown = true;

    document.addEventListener('mousemove', this.mouseMoveDocumentHandler);
    document.addEventListener('mouseup', this.mouseUpDocumentHandler);

    document.onselectstart = () => false;
  }

  mouseMoveDocumentHandler = (e: any) => {
    if (this.cursorDown === false) return;
    // @ts-ignore
    const prevPage = this[this.bar.axis];

    if (!prevPage) return;

    if (!(this.wrap && this.rootRef && this.thumbRef)) return;

    // @ts-ignore
    const offset = e[this.bar.client] - this.rootRef.getBoundingClientRect()[this.bar.direction];
    // @ts-ignore
    const thumbClickPosition = this.thumbRef[this.bar.offset] - prevPage;
    // @ts-ignore
    const thumbPositionPercentage = (offset - thumbClickPosition) * 100 / this.rootRef[this.bar.offset];
    // @ts-ignore
    this.wrap[this.bar.scroll] = thumbPositionPercentage * this.wrap[this.bar.scrollSize] / 100;
  }

  mouseUpDocumentHandler = () => {
    this.cursorDown = false;
    // @ts-ignore
    this[this.bar.axis] = 0;
    document.removeEventListener('mousemove', this.mouseMoveDocumentHandler);
    document.onselectstart = null;
  }

  render() {
    const { size, move, randomId } = this.props;

    return (
      <div
        ref={this.setRootRef}
        className={classnames(`${scrollerClsPrefix}__bar`, `is-${this.bar.key}`)}
        onMouseDown={this.clickTrackHandler}
      >
        <div
          ref={this.setThumbRef}
          id={randomId}
          className={`${scrollerClsPrefix}__thumb`}
          onMouseDown={this.clickThumbHandler}
          style={renderThumbStyle({ size, move, bar: this.bar })}
        />
      </div>
    );
  }
}

