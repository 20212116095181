import { AnyObj } from '../../../../types/common';
import { inBrowserTypes } from '../../browser-including-subordinate';
import { SearchAdvancedInitAllDatas, SearchAdvancedStoreType } from '../Store'

export const isNeedBrowserIncludingSub = (item: AnyObj) => {
  return item?.browserBean ? item?.browserBean?.multiple && inBrowserTypes(item?.browserBean?.type) : item?.multiple && inBrowserTypes(item?.type);
}

export const needBrowserIncludingSub = (allDatas: SearchAdvancedInitAllDatas) => {
  const { items } = allDatas || {};
  return Object.keys(items)?.find((itemKey) => isNeedBrowserIncludingSub(items[itemKey]));
}

export const BROWSER_SUB_SUFFIX = '_subBrowserData_fromBrowserConfig';

export const registerBrowserIncludingSub = (store: SearchAdvancedStoreType, allDatas: SearchAdvancedInitAllDatas, isReset?: boolean) => {
  if (needBrowserIncludingSub(allDatas)) {
    if (store.browserIncludingSubStore) {
      store.browserIncludingSubStore?.init(allDatas, isReset);
      return ;
    }
    return getBrowserIncludingSubStore().then((defaultModule) => {
      const BrowserIncludingSubStore = defaultModule.default;
      if (!store.browserIncludingSubStore) {
        store.setState({
          browserIncludingSubStore: new BrowserIncludingSubStore(store),
        })
      }
      store.browserIncludingSubStore?.init(allDatas, isReset);
    })
  }
}

export const getBrowserIncludingSubStore = () => import(
  /* webpackChunkName: "searchadvanced" */
  './BrowserIncludingSub'
)
