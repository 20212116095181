
import { mRepeatFrequencyClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { MRepeatFrequencyType } from "./types";

const MRepeatFrequency = Loadable({
  name: 'MRepeatFrequency',
  loader: () => import(
    /* webpackChunkName: "m_repeat_frequency" */
    './MRepeatFrequency')
}) as MRepeatFrequencyType;


MRepeatFrequency.defaultProps = {
  prefixCls: mRepeatFrequencyClsPrefix
};

export type { MRepeatFrequencyProps } from './types';

export default MRepeatFrequency;
