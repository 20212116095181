import React, { Component, PureComponent } from 'react';
import { BaseProps } from '../../types/common';
import { needRTL } from '../../utils';

class BreadSeparator extends PureComponent<BaseProps> {
  static displayName = 'BreadSeparator';
  render() {
    const { prefixCls, children, style } = this.props;
    let cls = `${prefixCls}-separator`

    if (needRTL()) cls += ` mirror-text-re`
    return (
      <span style={style} className={cls}>
        {children}
      </span>
    );
  }
}

export default BreadSeparator;
