import Loadable from "../../react-loadable";
import { mDateTimePickerClsPrefix } from "../../constants/index.m";
import { MDateTimePickerType } from "../date-picker/types";

const MDateTimePicker = Loadable({
  name: 'MDateTimePicker', loader: () => import(
    /* webpackChunkName: "m_date_time_picker" */
    './MDateTimePicker')
}) as MDateTimePickerType;

MDateTimePicker.defaultProps = {
  prefixCls: mDateTimePickerClsPrefix,
  type: 'date',
  timeFormat: 'HH:mm:ss',
  columnHeight: 308,
  itemHeight: 44,
  showClear: true,
  weekStart: 1,
  isLocale: true
};

export type { MDateTimePickerProps } from "../date-picker/types";
export default MDateTimePicker;