import Loadable from "../../react-loadable";
import { yearViewClsPrefix } from '../../constants/index';
import { YearViewType } from "./types";

const YearView = Loadable({
  name: 'YearView', loader: () => import(
    /* webpackChunkName: "year_view" */
    './YearView')
}) as YearViewType;

YearView.defaultProps = {
  prefixCls: yearViewClsPrefix,
  hideCurrentMonth: true
};


export type {
  YearViewProps,
} from "./types";
export default YearView;

