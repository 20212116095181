import Loadable from '../../react-loadable';
import { TableType, ITableProps } from './types';

const Table = Loadable<ITableProps>({ name: 'Table', loader: () => import(
    /* webpackChunkName: "table" */
    './Table') }) as TableType;

export type {
  ITableCellPos,
  ITableOnSortConfig,
  TableSortType,
  ITableColumn,
  ITableInnerColumn,
  ITableOnChangeConfig,
  TableTextAlign,
  ITableProps,
  ITableSelection,
  ITableRowClickHandler,
  ITableOnExpandHandler,
  ITablePaginationType,
  IVirtualModeProps,
  TableFixedType,
} from './types';

export default Table;

