import { MDialog, DialogMessageProps, AnyObj } from '../../../lib';
import { observable, action } from "mobx";
import { request, RequestOptionConfig, getLabel } from '@weapp/utils';
import { MOperatesType } from '../types';
import BaseStore from './BaseStore';
import { getTargetType, getTargetId } from '../utils/index';
import { getStore } from './m-store';

export default class MStore extends BaseStore {

  // list 配置
  @observable rowKey: string = 'id';
  @observable template: any = '';
  @observable data: any[] = [];
  @observable showCheck: boolean = false;

  /** 批量操作类型 */
  @observable batchType: 'none' | 'delete' | 'top' = 'none';

  // 操作列配置
  @observable operates: MOperatesType[] = [{
    text: getLabel('31479', '编辑'),
    index: 1,
    id: 'edit',
    key: 'edit',
    visible: true,
    outer: false,
    style: { backgroundColor: '#108ee9', color: 'white', minWidth: '80px' }
  }, {
    text: getLabel('21778', '删除'),
    index: 2,
    id: 'delete',
    key: 'delete',
    visible: true,
    outer: false,
    style: { backgroundColor: '#F4333C', color: 'white', minWidth: '80px' }
  }];


  // 已选内容
  @observable checkedKeys: string[] = [];
  @observable selectedRows: any[] = [];
  @observable tableType: string = 'CHECKBOX';  // NONE\RADIO\CHECKBOX

  // 启用搜索功能
  @observable showSearch?: boolean = false;
  // @observable btnVisibleType?: string = 'disabled';  // disabled / hidden

  @action init = (allDatas: Partial<MStore>) => {
    this.setState({
      ...allDatas,
      isInit: true,
    })
  }

  // 其他
  @action
  setState = (params: any, callback?: () => void) => {
    Object.keys(params).forEach((key) => {
      if (key in this) {
        // @ts-ignore
        this[key] = params[key];
      }
    });
    callback && callback();
  }

  @action
  getData = async (listConfig?: RequestOptionConfig, listType?: 'add' | 'edit' | 'delete' | string) => {
    return getStore().then((defaultModule) => defaultModule.getData(this, listConfig, listType));
  }

  @action
  searchData = (keywords: string | number) => {
    const prevSearchValue = this.searchValue;
    this.setState({ searchValue: keywords }, () => {
      // 调用数据请求
      this.getData({
        url: this.listConfig.url,
        method: this.listConfig.method,
        params: {
          ...this.listConfig.params,
          current: prevSearchValue !== keywords ? 1 : this.current,
          pageSize: this.pageSize,
          keywords: this.searchValue
        }
      })
    })
  }

  @action
  deleteReq = async (deleteConfig?: RequestOptionConfig, datas?: AnyObj[]) => {
    return getStore().then((defaultModule) => defaultModule.deleteReq(this, deleteConfig, datas));
  }

  @action
  authSave = async (saveConfig?: RequestOptionConfig, formDatas?: any) => {
    return getStore().then((defaultModule) => defaultModule.authSave(this, saveConfig, formDatas));
  }

  @action
  detailReq = async (detailConfig: RequestOptionConfig) => {
    return getStore().then((defaultModule) => defaultModule.detailReq(this, detailConfig));
  }


  @action
  authReq = async (authConfig: RequestOptionConfig) => {
    return getStore().then((defaultModule) => defaultModule.authReq(this, authConfig));
  }

  @action
  sortReq = async (params?: AnyObj, newDatas?: any[]) => {
    return getStore().then((defaultModule) => defaultModule.sortReq(this, params, newDatas));
  }


  getRealConfig = (config: RequestOptionConfig) => {
    const newConfig: RequestOptionConfig = {
      url: config.url?.replace('{module}', this.module),
      method: config.method,
    }

    let params = Object.assign({}, {
      sourceId: this.id,
      permissionId: this.permissionId,
      permissionType: this.permissionType,
      sourceType: this.sourceType
    }, config.params);
    if (config.method === 'get' || config.method === 'GET') {
      newConfig.params = params;
    } else if (config.method === 'post' || config.method === 'POST') {
      newConfig.data = params;
    }

    return newConfig
  }

  message = (options: DialogMessageProps) => {
    const _opeions: any = {
      type: options.type === 'error' ? 'fail' : options.type,
      content: options.content
    }
    if (!this.triggerMessage && _opeions.type === 'success') return;
    MDialog.toast(_opeions)
  }
}

export type MStoreType = Pick<MStore, keyof MStore>;