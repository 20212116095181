import { DateLimitedConfigType, DateType } from '../../types';
import { dayjs } from '@weapp/utils';
import { isEqual } from '../../../../utils';
import { getFormatDateTime } from '../index';

/**
 * @description: 判断日期格式是否合法
 * @param {DateType | DateType[]} value
 * @return {*}
 */
export function isValid(value: DateType | DateType[]) {
  if (value === '') {
    return true
  } else if (!!value) {
    if (Array.isArray(value)) {
      let flag = true
      value.forEach(item => {
        if (item !== '') {
          if (!(dayjs(item).isValid() || seasonIsValid(item) || halfYearIsValid(item))) {
            flag = false
          }
        }
      })
      return flag
    } else {
      return dayjs(value).isValid() || seasonIsValid(value) || halfYearIsValid(value)
    }
  }
  return false
}

/**
 * @description: 判断日期或时间范围是否合法(是否为空，长度是否为2，结束日期是否大于开始日期)
 * @param {DateType[]} value
 * @return {boolean}
 */
export function isValidRange(value: DateType[], type: 'date' | 'time' | 'datetime' = 'date') {
  if (isEqual(value, [])) {
    return true
  } else if (!!value) {
    if (value.length !== 2) {
      return false
    } else {
      let [v0, v1] = value
      let [s, e] = [dayjs(v0), dayjs(v1)]
      //如果是时间类型，则value一定为string[]类型，需要拼上相应的日期，再进行计算
      if (type === 'time') {
        // 这里需要兼容一下HH的格式，new Date(2012-12-12 12)无法创建正常日期数据
        if (typeof v0 === 'string' && v0?.length <= 2) v0 += ':00'
        if (typeof v1 === 'string' && v1?.length <= 2) v1 += ':00'
        s = dayjs(new Date().toDateString() + ' ' + v0)
        e = dayjs(new Date().toDateString() + ' ' + v1)
      }
      const dayjsType = type === 'date' ? 'day' : void 0
      if (v0 === '' && v1 === '') {
        return true
      } else if (v0 !== '' && v1 === '' && s.isValid()) {
        return true
      } else if (v0 === '' && v1 !== '' && e.isValid()) {
        return true
      } else if (v0 !== '' && v1 !== '' && s.isValid() && e.isValid() && s.isSameOrBefore(e, dayjsType)) {
        return true
      }
    }
  }
  return false
}

/**
 * @description: 判断是否在所给日期范围内
 * @param {DateType} value
 * @param {DateType} min
 * @param {DateType} max
 * @param {function} customDateFilter
 * @return {boolean}
 */
export function isInLimitedRange(value: DateType, dateLimitedConfig: DateLimitedConfigType, type: 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second') {
  const { minDate, maxDate, customDateFilter } = dateLimitedConfig

  //先对min和max进行格式化处理
  let min = minDate
  let max = maxDate
  if (typeof (minDate) === 'function') min = minDate()
  if (typeof (maxDate) === 'function') max = maxDate()
  //如果有自定义日期格式过滤，则优先走自定义处理
  if (customDateFilter) {
    return customDateFilter(value)
  } else {
    if (value === '') {
      return true
    }
    else {
      const minDate = (!!min && dayjs(min as DateType).isValid() ? dayjs(min as DateType) : '')
      const maxDate = (!!max && dayjs(max as DateType).isValid() ? dayjs(max as DateType) : '')
      if (minDate && maxDate) {
        return dayjs(minDate).isSameOrBefore(maxDate) && dayjs(value).isBetween(minDate, maxDate, type, '[]')
      } else if (minDate) {
        return dayjs(minDate).isSameOrBefore(dayjs(value), type)
      } else if (maxDate) {
        return dayjs(value).isSameOrBefore(maxDate, type)
      } else {
        return true
      }
    }
  }
}

export function commonValidMethod(min: Date, max: Date, value: DateType): boolean {
  const minDate = (!!min && dayjs(min as DateType).isValid() ? dayjs(min as DateType) : '')
  const maxDate = (!!max && dayjs(max as DateType).isValid() ? dayjs(max as DateType) : '')
  if (minDate && maxDate) {
    return dayjs(minDate).isSameOrBefore(maxDate) && dayjs(value).isBetween(minDate, maxDate, 'month', '[]')
  } else if (minDate) {
    return dayjs(minDate).isSameOrBefore(dayjs(value), 'month')
  } else if (maxDate) {
    return dayjs(value).isSameOrBefore(maxDate, 'month')
  } else {
    return true
  }
}

export function isInLimitedSeasonRange(value: DateType, dateLimitedConfig: DateLimitedConfigType) {
  const { minDate, maxDate, customDateFilter } = dateLimitedConfig
  const getSeasonOrHalfYearTypeDate = (date: any, type: "halfYear" | 'season') => {
    let spMaxDate = date as string;
    const count = type === 'season' ? 3 : 6;
    const index = spMaxDate.indexOf('-');
    const length = spMaxDate?.length;
    const year = Number(spMaxDate.slice(0, index))
    const monthForSeason = (Number(spMaxDate.slice(length - 1, length)) - 1) * count;
    const tempDate = new Date(year, monthForSeason, 1);
    return tempDate;
  }

  //先对min和max进行格式化处理
  let min = minDate
  let max = maxDate
  if (typeof (minDate) === 'function') min = minDate()
  if (typeof (maxDate) === 'function') max = maxDate()
  //如果有自定义日期格式过滤，则优先走自定义处理
  if (customDateFilter) {
    return customDateFilter(value)
  } else {
    if (value === '') {
      return true
    }
    //判断xxxx-Q1这样特殊的格式
    else if (seasonIsValid(min as Date) || seasonIsValid(max as Date)) {
      if (seasonIsValid(min as Date) && seasonIsValid(max as Date)) {
        min = getSeasonOrHalfYearTypeDate(min, 'season')
        max = getSeasonOrHalfYearTypeDate(max as Date, 'season');
      }
      else if (seasonIsValid(min as Date)) {
        min = getSeasonOrHalfYearTypeDate(min as Date, 'season');
      }
      else if (seasonIsValid(max as Date)) {
        max = getSeasonOrHalfYearTypeDate(max as Date, 'season');
      }
    }
    return commonValidMethod(min as Date, max as Date, value)
  }
}

export function isInLimitedHalfOfYearRange(value: DateType, dateLimitedConfig: DateLimitedConfigType) {
  const { minDate, maxDate, customDateFilter } = dateLimitedConfig
  const getSeasonOrHalfYearTypeDate = (date: any, type: "halfYear" | 'season') => {
    let spMaxDate = date as string;
    const count = type === 'season' ? 3 : 6;
    const index = spMaxDate.indexOf('-');
    const length = spMaxDate?.length;
    const year = Number(spMaxDate.slice(0, index))
    const monthForSeason = (Number(spMaxDate.slice(length - 1, length)) - 1) * count;
    const tempDate = new Date(year, monthForSeason, 1);
    return tempDate;
  }

  //先对min和max进行格式化处理
  let min = minDate
  let max = maxDate
  if (typeof (minDate) === 'function') min = minDate()
  if (typeof (maxDate) === 'function') max = maxDate()
  //如果有自定义日期格式过滤，则优先走自定义处理
  if (customDateFilter) {
    return customDateFilter(value)
  } else {
    if (value === '') {
      return true
    }
    //判断xxxx-H1这样特殊的格式
    else if (halfYearIsValid(min as Date) || halfYearIsValid(max as Date)) {
      if (halfYearIsValid(min as Date) && halfYearIsValid(max as Date)) {
        min = getSeasonOrHalfYearTypeDate(min, 'halfYear')
        max = getSeasonOrHalfYearTypeDate(max as Date, 'halfYear');
      }
      else if (halfYearIsValid(min as Date)) {
        min = getSeasonOrHalfYearTypeDate(min as Date, 'halfYear');
      }
      else if (halfYearIsValid(max as Date)) {
        max = getSeasonOrHalfYearTypeDate(max as Date, 'halfYear');
      }
    }
    return commonValidMethod(min as Date, max as Date, value)
  }
}

/**
 * @description: 根据format判断传入的00:00:00格式的时间是否合法
 * @param {string} time
 * @return {boolean}
 */
export function timeIsValid(time: string) {
  if (time === '') {
    return true
  } else {
    return !isNaN(getFormatDateTime(time).getTime())
  }
}

/**
 * @description: 在用户传入时间步长的时候判断手动输入的值是否合法
 * @param {string} value
 * @param {timeFormatType} format
 * @param {number} hourStep
 * @param {number} minuteStep
 * @param {number} secondStep
 * @return {*}
 */
export function stepIsValid(value: string, hourStep: number = 1, minuteStep: number = 1, secondStep: number = 1) {
  if (value === '') {
    return true
  } else {
    const t = dayjs(getFormatDateTime(value))
    const h = t.hour()
    const m = t.minute()
    const s = t.second()
    if ((h % hourStep === 0) && (m % minuteStep === 0) && (s % secondStep === 0)) { return true }
    return false
  }
}

/**
 * @description: 判断季度字符串是否合法
 * @param {string} value
 * @return {*}
 */
export function seasonIsValid(value: DateType) {
  if (typeof value !== 'string') {
    return false
  } else {
    if (value === '') {
      return true
    } else {
      const reg = /(\d{4})[-]Q([1|2|3|4])/g
      return reg.test(value)
    }
  }
}

/**
 * @description: 判断半年度字符串是否合法
 * @param {string} value
 * @return {*}
 */
export function halfYearIsValid(value: DateType) {
  if (typeof value !== 'string') {
    return false
  } else {
    if (value === '') {
      return true
    } else {
      const reg = /(\d{4})[-]H([1|2])/g
      return reg.test(value)
    }
  }
}

