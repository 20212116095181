import { appInfo, classUseMemo, corsImport, middleware } from "@weapp/utils";
import React, { Component, ComponentType, Suspense } from "react";
import { uiAppName } from '../../constants/index';
import { AnyObj } from "../../types/common";
import { CorsComponentProps, CorsComponentState } from './types';

function noop() { return null };

export const LoadableComponent = (Comp: ComponentType<any>) =>
  (props: any) => (
    <Suspense weId={`${props.weId || ''}_znaapn`} fallback={props.loading || noop}>
      <Comp weId={`${props.weId || ''}_uynjvv`} {...props} />
    </Suspense>
  );

// 缓存异步组件
export const corsCompsCache: Record<string, Record<string, React.ComponentType<any>>> = {};

// @ts-ignore
@middleware(uiAppName, 'CorsComponent')
export default class CorsComponent<P extends AnyObj = AnyObj> extends Component<CorsComponentProps<P>, CorsComponentState<P>> {
  static displayName = "CorsComponent";

  // 同步加载时是否调用过 ready
  syncReadyState = false;

  constructor(props: CorsComponentProps<P>) {
    super(props);
    this.state = {
      loaded: false,
      componentId: `${props.app}.${props.compName}`,
      LoadedComponent: undefined,
    };
  }

  static getDerivedStateFromProps(nextProps: CorsComponentProps, prevState: CorsComponentState) {
    const componentId = `${nextProps.app}.${nextProps.compName}`;
    const newState: Partial<CorsComponentState> = {};
    if (prevState.componentId !== componentId) {
      newState.loaded = false; // 需要重新请求组件
      newState.componentId = componentId;
    }
    return newState;
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount?.();
  }

  getComponentFromModule = (module: any, compName: string) => {
    let Comp;
    const compNames = typeof compName === 'string' && (compName as string).split('.');
    if (compNames && compNames.length > 1) {
      Comp = compNames.reduce((C, cName) => C?.[cName] ? C[cName] : undefined, module);
    } else {
      Comp = module[compName];
    }
    return Comp;
  }

  callComponent = (props?: CorsComponentProps<P>) => {
    const currProps = props || this.props;
    const { app, compName, propJson } = currProps || {};
    try {
      corsImport(app, propJson).then(
        module => {
          const newState: Partial<CorsComponentState<P>> = { loaded: true, module };
          if (compName) {
            if (app && compName && corsCompsCache[app]?.[compName as string]) {
              // 判断是否存在缓存
              const LoadedComponent = corsCompsCache[app][compName as string];
              newState.LoadedComponent = LoadedComponent;
            } else {
              const Comp = this.getComponentFromModule(module, compName);
              const LoadedComponent = LoadableComponent(Comp);
              // 将组件加入到缓存
              corsCompsCache[app] = corsCompsCache[app] || {};
              corsCompsCache[app][compName] =  LoadedComponent;
              newState.LoadedComponent = LoadedComponent;
            }
          }
          if (currProps.onReady) {
            this.syncReadyState = true;
            currProps.onReady(module);
          }
          this.setState(newState as CorsComponentState<P>);
        },
      );
    } catch (err: any) {
      console.error(err);
      currProps.onError?.(err);
    }
  }

  render() {
    const { app, compName, customRender } = this.props;
    const { loaded } = this.state;

    let module = this.state.module;
    let LoadedComponent = this.state.LoadedComponent;

    const { libraryName } = appInfo(app);

    if (app && compName && corsCompsCache[app]?.[compName as string]) {
      module = window[libraryName];
      // 判断是否存在缓存
      LoadedComponent = corsCompsCache[app][compName as string];
      if (this.props.onReady && !this.syncReadyState) {
        this.syncReadyState = true;
        this.props.onReady(module);
      }
    } else if (libraryName && window[libraryName]) {
      // 如果模块已经加载按照普通组件加载
      module = window[libraryName];
      if (compName) {
        LoadedComponent = classUseMemo('SyncLoadedComponent', this, () => {
          const Comp = this.getComponentFromModule(module, compName);
          return LoadableComponent(Comp);
        }, [module, compName]);
        // 将组件加入到缓存
        corsCompsCache[app] = corsCompsCache[app] || {};
        corsCompsCache[app][compName] =  LoadedComponent;
      }
      if (this.props.onReady && !this.syncReadyState) {
        this.syncReadyState = true;
        this.props.onReady(module);
      }
    } else if (!loaded) {
      this.callComponent();
      return null;
    }

    if (customRender) {
      return customRender(module, LoadedComponent);
    }

    return LoadedComponent ? (
      <LoadedComponent weId={`${this.props.weId || ''}_zz5g3q`} {...this.props} />
    ) : null;
  }
}

// export class CorsComponent extends Component<CorsComponentProps> {

//   LazyComponey: React.ComponentType<CorsComponentProps>;

//   constructor(props: CorsComponentProps) {
//     super(props);
//     this.LazyComponey = Loadable({
//       name: `CorsComponent(${props.app}.${props.compName})`,
//       loading: props.loading,
//       loader: () => corsImport(props.app, props.propJson).then(
//         Modules => {
//           return Promise.resolve({ default: LoadableComponent(Modules[props.compName]) });
//         },
//         err => {
//           return Promise.reject(err);
//         },
//       ),
//     });
//   }

//   componentWillUnmount() {
//     const { unmount } = this.props;
//     unmount?.();
//   }

//   render() {
//     const Comp = this.LazyComponey;
//     return (
//       <Comp weId={`${this.props.weId || ''}_3jwz4y`} {...this.props} />
//     );
//   }
// }
