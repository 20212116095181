import ReactDOM from 'react-dom';
import Loadable from '../../../../react-loadable';
import { DialogToastProps, ToastType } from './types';

const Toast = Loadable({
  name: 'Toast', loader: () => import(
    /* webpackChunkName: "m_dialog" */
    './Toast')
}) as ToastType;

export function toast(options: DialogToastProps) {
  const { onClose } = options;
  const div = document.createElement('div');
  document.body.appendChild(div);
  function destroy() {
    const unmount = ReactDOM.unmountComponentAtNode(div);
    if (unmount && div.parentNode) {
      div.parentNode.removeChild(div);
    }
    onClose?.();
    options?.destroy?.();
  }
  ReactDOM.render(<Toast weId={`${options.weId || ''}_o2v8ly`} {...options} destroy={destroy} />, div);
  return {
    destroy
  };
}
