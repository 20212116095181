import { RequestOptionConfig, RequestPromise, RequestResult } from "@weapp/utils";
import { observable } from "mobx";
import { AnyObj, FormInitAllDatas } from "../../../lib";
import { PermissionType } from "../types";

// 移动端、pc端公共逻辑
export default class BaseStore {
  @observable isInit: boolean = false;

  @observable authLoading: boolean = false;
  @observable loading: boolean = false;
  @observable id: string = '';  // 实际为 sourceId，资源 id
  @observable sourceType: number = 1;
  @observable permissionId: string = '';
  @observable permissionType?: number;
  @observable module: string = '';

  // 请求配置
  // 列表数据获取
  listConfig: RequestOptionConfig = {
    url: '/api/app/{module}/front/data/permission/table/list',
    method: 'post',
    params: {
      "keywords": "",
      "current": 1,
      "pageSize": 10
    }
  };

  // 删除
  deleteConfig: RequestOptionConfig = {
    url: "api/app/{module}/front/data/permission/table/delete",
    method: "POST",
    params: {
      "ids": "" //逗号隔开
    }
  }

  // 新增
  addConfig: RequestOptionConfig = {
    url: "api/app/{module}/front/data/permission/table/add",
    method: "POST",
    params: {
    }
  }

  // 编辑
  editConfig: RequestOptionConfig = {
    url: "",
    method: "POST",
    params: {
    }
  }

  // 排序
  sortConfig: RequestOptionConfig = {
    url: "",
    method: "POST",
    params: {
    }
  }

  // 获取 auth form （添加）
  authConfig: RequestOptionConfig = {
    url: "/api/app/{module}/component/data/permission/form/query",
    method: "POST",
    params: {
    }
  }

  // 获取 auth form （编辑）
  detailConfig: RequestOptionConfig = {
    // url: "/api/app/{module}/component/data/permission/form/query",
    url: '',
    method: "POST",
    params: {
    }
  }


  // auth form 配置
  @observable authType = 'add';  // 'add', 'edit'
  @observable condition: FormInitAllDatas | undefined = undefined;  // auth form（添加）
  @observable authCondition: FormInitAllDatas | undefined = undefined;  // 表单布局初始化后回调的布局 
  @observable authEditCondition: FormInitAllDatas | undefined = undefined;  // auth form（编辑）
  // 操作列配置
  @observable showOperates: boolean = true;   // 是否显示操作列

  @observable operatesPermission: PermissionType[][] = [];
  @observable checkBoxPermission: PermissionType[] = [];  // 数据 check 禁用控制
  // 拖拽配置
  @observable sortable: boolean = false;  // 是否启用拖拽
  @observable sortableType: string = 'icon';  // 拖拽类型 'row' | 'icon'
  // 分页配置
  @observable pageSize: number = 10;
  @observable current: number = 1;
  @observable total: number = 0;
  // loading...
  @observable hasMore: boolean = false;

  // 搜索相关
  @observable searchValue = '';
  // auth module 相关
  @observable dialogVisible: boolean = false;


  // 接口自定义
  fetchGetData?: (options: RequestOptionConfig) => (RequestPromise<any> | RequestResult);
  fetchAuthData?: (options: RequestOptionConfig) => (RequestPromise<any> | RequestResult);
  fetchDetailData?: (options: RequestOptionConfig) => (RequestPromise<any> | RequestResult);
  fetchDeleteData?: (options: RequestOptionConfig) => (RequestPromise<any> | RequestResult);
  fetchAddData?: (options: RequestOptionConfig, condition?: FormInitAllDatas) => (RequestPromise<any> | RequestResult);
  fetchEditData?: (options: RequestOptionConfig, condition?: FormInitAllDatas) => (RequestPromise<any> | RequestResult);
  fetchSortData?: (options: RequestOptionConfig, newDatas?: any[], selectedRows?: any[]) => (RequestPromise<any> | RequestResult);
  // 获取接口参数进行过滤
  getFetchParams?: (type: string, params: AnyObj) => AnyObj;

  @observable addVisible?: boolean = true;  // 是否显示添加按钮
  @observable deleteVisible?: boolean = true;  // 是否显示删除按钮（批量）
  @observable topVisible?: boolean = false;  // 是否显示置顶按钮（批量）
  @observable triggerMessage?: boolean = true;

}