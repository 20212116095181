import Loadable from '../../react-loadable';
import { FieldTemplateSelectType } from './types';
import { FieldTemplateSelectClsPrefix } from '../../constants/index'
const FieldTemplateSelect = Loadable({
  name: 'FieldTemplateSelect', loader: () => import(
    /* webpackChunkName: "ui_common_less" */
    './FieldTemplateSelect')
}) as FieldTemplateSelectType;

FieldTemplateSelect.defaultProps = {
  prefixCls: FieldTemplateSelectClsPrefix,
};

export default FieldTemplateSelect;
export type { FieldTemplateSelectData } from './types';
