import { mCalendarClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { CalendarType } from './types';
import Lunar from './utils/lunar/lunar';


const Calendar = Loadable({
  name: 'Calendar',
  loader: () => import(
    /* webpackChunkName: "calendar" */
    './Calendar')
}) as CalendarType;


Calendar.defaultProps = {
  prefixCls: mCalendarClsPrefix,
  needHoliday: true
};

Calendar.Lunar = Lunar


export type { CalendarProps } from './types';

export default Calendar;
