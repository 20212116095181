import Loadable, { LoadingType } from '../../react-loadable';
import { MCheckboxType, CheckboxProps } from './types';
import { mCheckboxClsPrefix } from '../../constants/index.m';
import { USESKELETON } from '../../constants/index';

const MCheckboxSkeleton: LoadingType<CheckboxProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MCheckbox = Loadable({
  name: 'MCheckbox',
  loading: MCheckboxSkeleton,
  loader: () => import(
    /* webpackChunkName: 'ui_m_common_form' */
    './Checkbox')
}) as MCheckboxType;

MCheckbox.MCheckboxSkeleton = MCheckboxSkeleton;

MCheckbox.defaultProps = {
  prefixCls: mCheckboxClsPrefix,
  isHoldRight: false,
  isMobile: true
};

export type {
  CheckboxProps as MCheckboxProps,
  ValueType as MCheckboxValueType,
  OptionData as MCheckboxOptionType,
  OptionsType as MCheckboxDataType,
  CheckboxEvent as MCheckboxEvent,
  CheckboxOptionProps as MCheckboxOptionProps
} from './types';

export default MCheckbox;
