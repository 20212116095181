import { corsImport } from "@weapp/utils";

/** iconMap 缓存记录 */
let iconMap: { [key: string]: boolean } | null = null;
/** iconMap 小写转换 */
let iconLowerKeysMap: { [key: string]: string } = {};

corsImport("@weapp/iconfont").then((iconfont) => {
  iconMap = iconfont?.RcIcon?.IconLoader?.getIconMap();
  iconLowerKeysMap = iconfont?.RcIcon?.IconLoader?.getIconLowerKeysMap();
});

const loadIconMap = (): Promise<{ [p: string]: boolean }> => {
  return corsImport("@weapp/iconfont").then((iconfont) =>
    iconfont?.RcIcon?.IconLoader?.loadIconMap()
  );
};

const getIconMap = () => {
  return iconMap || {};
};

const getIconLowerKeysMap = () => {
  return iconLowerKeysMap;
};

const loadIconChunk = (iconName: string): Promise<void> => {
  return corsImport("@weapp/iconfont").then((iconfont) =>
    iconfont?.RcIcon?.IconLoader?.loadIconChunk(iconName)
  );
};

const getIconChunk = (iconName: string): Promise<string> => {
  return corsImport("@weapp/iconfont").then((iconfont) =>
    iconfont?.RcIcon?.IconLoader?.getIconChunk(iconName)
  );
};

const loadImageIcon = (iconName: string): Promise<string> => {
  return corsImport("@weapp/iconfont").then((iconfont) =>
    iconfont?.RcIcon?.IconLoader?.loadImageIcon(iconName)
  );
};

const renderSvgSymbol = (iconName: string): Promise<string> => {
  return corsImport("@weapp/iconfont").then((iconfont) =>
    iconfont?.RcIcon?.IconLoader?.renderSvgSymbol(iconName)
  );
};

// 没人用，方法移除
// const checkIsIconLoaded = (iconName: string): Promise<string> => {
//   return corsImport("@weapp/iconfont").then((iconfont) =>
//     iconfont?.RcIcon?.IconLoader?.checkIsIconLoaded(iconName)
//   );
// };

const appendSymbolToRefs = (
  iconName: string,
  svgContent: string,
  options?: {
    onError?: () => void;
    onSuccess?: () => void;
  }
): Promise<string> => {
  return corsImport("@weapp/iconfont").then((iconfont) =>
    iconfont?.RcIcon?.IconLoader?.renderSvgSymbol(iconName, svgContent, options)
  );
};

const getSvgIconStr = (iconName: string): Promise<{ svgStr: string }> => {
  return corsImport("@weapp/iconfont").then((iconfont) =>
    iconfont?.RcIcon?.IconLoader?.getSvgIconStr(iconName)
  );
};

const getSvgIconDom = (iconName: string): Promise<{ svgDom: SVGElement }> => {
  return corsImport("@weapp/iconfont").then((iconfont) =>
    iconfont?.RcIcon?.IconLoader?.getSvgIconDom(iconName)
  );
};

const iconLoader = {
  loadIconMap,
  getIconMap,
  getIconLowerKeysMap,
  loadIconChunk,
  getIconChunk,
  loadImageIcon,

  renderSvgSymbol,
  appendSymbolToRefs,

  getSvgIconDom,
  getSvgIconStr,
};

export type IconLoaderType = typeof iconLoader;

export default iconLoader;
