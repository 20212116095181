import { FormSwitchType, FormSwitchProps } from './types';
import { ua } from "@weapp/utils";
import { Loadable, LoadingType, MTextareaSkeleton, MTextareaProps, constants, FormSwitchErrorType  } from '../../lib';
import { getInnerRequired, judgeEmpty } from "./utils";
import { ErrorsComponentType } from './Errors';

const { formSwitchClsPrefix, USESKELETON } = constants;
const FormSwitchSkeleton: LoadingType<FormSwitchProps> = (props) => {
  if (!USESKELETON) return <div/>
  const { itemType } = props;
  switch(itemType) {
    case 'TEXTAREA':
      return typeof MTextareaSkeleton === 'function' ? <MTextareaSkeleton weId={`${props.weId || ''}_lz0xk1`} {...props as MTextareaProps} /> : MTextareaSkeleton;
    default:
      return <div style={{ height: ua.device === 'PC' ? 30 : 44 }}></div>
  }
};

const FormSwitch = Loadable({
  name: 'FormSwitch',
  loading: FormSwitchSkeleton,
  loader: () => import(/* webpackChunkName: 'ui_form' */ "./FormSwitch") }) as FormSwitchType;

const Errors = Loadable({
  name: 'FormSwitchErrors',
  loader: () => import(/* webpackChunkName: 'ui_form' */ "./Errors") }) as ErrorsComponentType;

FormSwitch.getRequired = getInnerRequired;
FormSwitch.judgeEmpty = judgeEmpty;
FormSwitch.Errors = Errors;

FormSwitch.FormSwitchSkeleton = FormSwitchSkeleton;

FormSwitch.defaultProps = {
  prefixCls: formSwitchClsPrefix,
  newValidateTipType: false,
  // 私有化环境输入后隐藏必填符号
  hideRequiredMarkAfterEdit: window?.TEAMS?.globalConfig?.hiddenMarker ?
    Boolean(Number(window?.TEAMS?.globalConfig?.hiddenMarker)) : window?.TEAMS?.privateDeploy,
}
export type { ErrorType as FormSwitchErrorType, ErrorsProps as FormSwitchErrorsProps } from "./Errors";
export type { FormSwitchProps, showType as FormSwitchShowType, FormSwitchPluginParams } from "./types";
export default FormSwitch;
