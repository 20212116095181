import Loadable from '../../react-loadable';
import { SwipeActionType } from './types';

const SwipeAction = Loadable({
  name: 'SwipeAction', loader: () => import(
    /* webpackChunkName: "swipe-action" */
    './SwipeAction')
}) as SwipeActionType;

export type { SwipeActionProps, SwipeActionType, SwipeActionTypeBtnType } from './types';

export default SwipeAction;
