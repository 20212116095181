import { encryClsPrefix } from '../../constants/index';
import Loadable from "../../react-loadable";
import { EncryType } from "./type";
import { desensitization, encryption } from './utils/index';

const Encry = Loadable({
  name: 'Encry',
  loader: () => import(
    /* webpackChunkName: "ui_form" */
    './compoment/index'  
  )
}) as EncryType;

Encry.defaultProps = {
  prefixCls: encryClsPrefix,
}

Encry.desensitization = desensitization;
Encry.encryption = encryption;

export default Encry;

export type { EncryType, EncryProps, EncryCommonProps } from './type';