import { carouselClsPrefix } from '../../constants/index';
import Loadable from "../../react-loadable";
import { CarouselType } from "./types";

const Carousel = Loadable({
  name: "Carousel", loader: () => import(
    /* webpackChunkName: "carousel" */
    './Carousel'
  )
}) as CarouselType;

Carousel.defaultProps = {
  prefixCls: carouselClsPrefix,
  fillWays: 'backgroundFill'
};

export type { CarouselProps, Swiper } from "./types";

export default Carousel;