
//根据string判断是否设置过多语言，以及从特殊符号中截取实际的字符串
//如果设置过多语言、则以 ~`~ 开头 以 `~`~结尾
export function testStringSetLocale(value?: string, suitLineFeed?: boolean) {
  let v = value
  let isSetLocale = false
  if (!suitLineFeed) {
    const reg = /^~`~`(.*)`~`~$/
    if (typeof value === 'string') {
      isSetLocale = reg.test(value)
      const result = value.match(reg)
      if (isSetLocale) {
        v = result?.[1] || ''
      }
    }
  } else {
    const reg = /^(~`~`)|(`~`~)$/g
    if (typeof value === 'string') {
      let start = false
      let end = false
      v = value.replace(reg, ($0) => {
        if ($0 === '~`~`') start = true
        if ($0 === '`~`~') end = true
        return ''
      })
      if (start && end) isSetLocale = true
    }
  }
  return {
    isSetLocale,
    value: v
  }
}

