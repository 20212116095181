import React, { useMemo, useState, ComponentType } from "react";
import { FormItemRenderProps } from '../index';
import { Skeleton, getMobileLabelMinHeight, getMobileFieldMinHeight, FormLayoutProps } from '../../../lib';

export interface FormItemSkeletonRef {

}

export interface FormItemSkeletonProps extends FormItemRenderProps {
  adjuestHeight?: number;
  weId?: any;
}

export type FormItemSkeletonType = ComponentType<FormItemSkeletonProps>;

export const getFormItemSkeletonHeight = (props: FormItemSkeletonProps) => {
  const { item, com } = props;
  let minHeight = 0, hasCustomField = false;
  if (com && com.length > 0 && item) {
    com.forEach((itemKey) => {
      const itemType = item[itemKey]?.itemType;
      let itemHeight = 0;
      if (itemType === 'CUSTOM') hasCustomField = true; // 带自定义字段的不处理
      if (itemType) {
        itemHeight += getMobileLabelMinHeight(props as FormLayoutProps);
        itemHeight += getMobileFieldMinHeight(item[itemKey]);
      }
      if (minHeight <= itemHeight) minHeight = itemHeight;
    })
  }
  return hasCustomField ? -1 : minHeight;
}

const FormItemSkeleton = React.forwardRef<FormItemSkeletonRef, FormItemSkeletonProps>(
  (props, ref) => {
    const { children, item, com, adjuestHeight, isMobile, hide } = props;
    const [firstCal, setFirseCal] = useState(true);
    const [minHeight, setMinHeight] = useState(0);
    const [disabledSkeleton, setDisabledSkeleton] = useState(false);

    function doCal() {
      let minHeight = 0;
      if (com && com.length > 0 && item) {
        const nowHeight = getFormItemSkeletonHeight(props);
        if (nowHeight === -1) setDisabledSkeleton(true);
        minHeight += nowHeight;
        if (adjuestHeight) minHeight += adjuestHeight;
        setMinHeight(minHeight);
        setFirseCal(false);
      }
    }

    if (firstCal) {
      if (isMobile) doCal();
      else {
        setMinHeight(40);
        setFirseCal(false);
      }
    }

    const style = useMemo(() => {
      return minHeight > 0 && hide === false ? { minHeight, backgroundColor: '#fff' } : {};
    }, [minHeight]);

    return <>
      {(!minHeight && !disabledSkeleton) ? (
        <Skeleton weId={`${props.weId || ''}_atkl6w`} skeletonHeight={44} span={1} skeletonItemHeight={44} style={{ border: 0 }} />
      ) : (
        <div style={style}>
          {children}
        </div>
      )}
    </>
  }
)

FormItemSkeleton.displayName = "FormItemSkeleton";

export default FormItemSkeleton;