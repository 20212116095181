import Loadable from '../../react-loadable';
import { EmptyType } from './types';
import { emptyClsPrefix } from '../../constants/index';

const Empty = Loadable({
  name: 'Empty', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './Empty')
}) as EmptyType;

Empty.defaultProps = {
  prefixCls: emptyClsPrefix,
};

export type { EmptyProps, EmptyType } from './types';

export default Empty;
