import { Component } from "react";
import ReactDOM from "react-dom";
import { classnames } from "@weapp/utils";
import { AnyObj, BaseProps } from "../../../types/common";
import { DialogDraggableProps, DialogDraggableType, Placement } from "../types";
import { isNil, isNumber, needRTL } from '../../../utils/index';
import { transPercentHeight, transPercentWidth } from '../util/index';
import { DIALOG_DEFAULT_DRAGGABLE_PADDING, DIALOG_DEFAULT_MIN_TOP_MIDDLE, DIALOG_DEFAULT_TOP_MIDDLE, DIALOG_DEFAULT_WIDTH_MIDDLE } from '../constant/index';
class DialogDraggableContainer extends Component<BaseProps> {
  render() {
    return this.props.children;
  }
}

interface DialogDraggableState {
  startX: number;
  startY: number;
  x: number,
  y: number,
  lastX: number,
  lastY: number,
  dragging: boolean,
}

function isMiddle(placement?: Placement) {
  return !placement || placement === 'middle';
}
/* 转换配置的数据用于计算 */
function parseRange(x: number | string, position?: string) {
  if (isNumber(x)) return x as number;
  if (typeof x === 'string') {
    if (x.indexOf('px') >= 0) return parseInt(x);
    else if (position === 'y') return x.indexOf('%') >= 0 ? transPercentHeight(x.slice(0, x.indexOf('%'))) : transPercentHeight(x);
    return x.indexOf('%') >= 0 ? transPercentWidth(x.slice(0, x.indexOf('%'))) : transPercentWidth(x);
  }
}

function getWidth(width?: number | string, placement?: Placement) {
  if (isMiddle(placement)) {
    if (isNil(width)) return DIALOG_DEFAULT_WIDTH_MIDDLE; // width默认值
    return parseRange(width) as number;
  }
  return width as number;
}

function getLeft(left?: number | string, placement?: Placement, otherParams?: AnyObj) {
  if (isMiddle(placement)) {
    if (isNil(left)) { // left默认值
      const clientWidth = window.document.body.clientWidth;
      return (clientWidth - otherParams?.width) / 2;
    }
    return parseRange(left) as number;
  }
  return left as number;
}

function getTop(top?: number | string, placement?: Placement) {
  if (isMiddle(placement)) {
    if (isNil(top)) return window.document.body.clientHeight < 600 ? DIALOG_DEFAULT_MIN_TOP_MIDDLE : DIALOG_DEFAULT_TOP_MIDDLE; // top默认值
    return parseRange(top, 'y') as number;
  }
  return top as number;
}
class DialogDraggable extends Component<DialogDraggableProps, DialogDraggableState> {
  constructor(props: DialogDraggableProps) {
    super(props);
    this.state = {
      startX: 0,
      startY: 0,
      x: 0,
      y: 0,
      lastX: 0,
      lastY: 0,
      dragging: false,
    }
  }
  componentDidMount() {
    this.props?.onReady?.({
      reset: this.reset,
      onDragStop: this.onDragStop,
    });
  }
  static getDerivedStateFromProps(nextProps: Readonly<DialogDraggableProps>) {
    const { visible } = nextProps;
    const newState = {
      startX: 0,
      startY: 0,
      x: 0,
      y: 0,
      lastX: 0,
      lastY: 0,
      dragging: false,
    } as Partial<DialogDraggableState>;
    if (!visible) return newState;
    return null;
  }
  findDOMNode = () => ReactDOM.findDOMNode(this);
  reset = () => this.setState({
    startX: 0,
    startY: 0,
    x: 0,
    y: 0,
    lastX: 0,
    lastY: 0,
    dragging: false,
  })
  onDrag = (e: MouseEvent) => {
    const { placement, width: _propWidth, left: _propLeft, top: _propTop, innerScale, setDraggableStyle } = this.props;
    if (innerScale) return
    const { clientX, clientY } = e;
    const dom = this.findDOMNode();
    if (dom && dom.ownerDocument) {
      const offsetParent = dom.ownerDocument.body;
      const { startX, startY, lastX, lastY } = this.state;
      const nowX = clientX + offsetParent.scrollLeft - startX, nowY = clientY + offsetParent.scrollTop - startY;
      let newX = needRTL?.() ? lastX - nowX : nowX + lastX, newY = nowY + lastY
      /**
       * http://10.12.101.12/FRONTEND/weapp-ui/-/issues/2330 开启拖拽，控制拖拽范围
       * 注意：draggable + resize + placement(各方向) 同时使用场景
      */
      let width = 0, left = 0, top = 0;
      if (isMiddle(placement)) {
        width = getWidth(_propWidth);
        left = getLeft(_propLeft, 'middle', { width });
        top = getTop(_propTop, 'middle');
        if ((newX < DIALOG_DEFAULT_DRAGGABLE_PADDING - left - width) || newX >= window.document.body.clientWidth - left - DIALOG_DEFAULT_DRAGGABLE_PADDING) return;
        if ((clientY < DIALOG_DEFAULT_MIN_TOP_MIDDLE) || (clientY + DIALOG_DEFAULT_DRAGGABLE_PADDING >  window.innerHeight)) return
        // if (newY < -top || (newY >= (window.document.body.clientHeight - top - DIALOG_DEFAULT_DRAGGABLE_PADDING))) return;
      }

      this.setState({ // x，y 对应 偏移量
        x: newX,
        y: newY,
      }, () => {
        setDraggableStyle?.(this.getDraggableStyle());
      })
    }
  }
  onDragStop = () => {
    const { setDraggableStyle } = this.props;
    const dom = this.findDOMNode();
    if (dom && dom.ownerDocument) {
      const { x, y } = this.state;
      dom.ownerDocument.removeEventListener('mousemove', this.onDrag);
      dom.ownerDocument.removeEventListener('mouseup', this.onDragStop, true);
      this.setState({
        lastX: x,
        lastY: y,
        dragging: false,
      }, () => {
        setDraggableStyle?.(this.getDraggableStyle());
      })
    }
  }
  onMouseDown = (e: MouseEvent) => {
    const { draggable, canDrag, destroyOnClose, width: _propWidth, left: _propLeft, isStopPropagation, setDraggableStyle } = this.props;
    if (!draggable) return;
    if (destroyOnClose && isStopPropagation) { // 非默认销毁children模式(destroyOnClose!=true)，children包含Trigger弹层，通过顶部关闭按钮关闭Dialog，弹层不会消失(mousedown阻止冒泡引起)
      e.stopPropagation && e.stopPropagation();
    }
    if (canDrag && !canDrag(e)) return;
    const dom = this.findDOMNode();
    if (dom && dom.ownerDocument) {
      const { clientX, clientY } = e;
      const offsetParent = dom.ownerDocument.body;
      this.setState({ startX: clientX + offsetParent.scrollLeft, startY: clientY + offsetParent.scrollTop, dragging: true }, () => {
        dom.ownerDocument.addEventListener('mousemove', this.onDrag);
        dom.ownerDocument.addEventListener('mouseup', this.onDragStop, true);
        setDraggableStyle?.(this.getDraggableStyle());
      });
    }
  }
  onDragWidthStop = (e: MouseEvent) => {
    const dom = this.findDOMNode();
    if (dom && dom.ownerDocument) {
      dom.ownerDocument.removeEventListener('mousemove', this.onDragWidth);
      dom.ownerDocument.removeEventListener('mouseup', this.onDragWidthStop);
      this.setState({ dragging: false });
      this.props.changeDragging?.(false);
    }
  }
  onDragWidth = (e: MouseEvent) => {
    const { clientX, clientY } = e;
    const dom = this.findDOMNode();
    if (dom && dom.ownerDocument) {
      this.props.changeResizeWidth?.(clientX);
      this.props.changeResizeHeight?.(clientY);
    }
  }
  onDragWidthStart: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const { resize } = this.props;
    if (!resize) return;
    const dom = this.findDOMNode();
    if (dom && dom.ownerDocument) {
      this.setState({ dragging: true });
      this.props.changeDragging?.(true);
      dom.ownerDocument.addEventListener('mousemove', this.onDragWidth);
      dom.ownerDocument.addEventListener('mouseup', this.onDragWidthStop);
    }
  }
  getDraggableStyle = () => {
    const { draggable } = this.props;
    const { x, y, dragging } = this.state;
    const dragableStyle = draggable ? dragging ?
      { transition: 'none', transform: `translate(${x}px, ${y}px)` } :
      { transform: `translate(${x}px, ${y}px)` } : {};
    return dragableStyle;
  }
  render() {
    const { children, draggable, prefixCls, resize, placement } = this.props;
    const { x, y, dragging } = this.state;
    let motionChildren = null;
    if (children) {
      const dragableStyle = draggable ? dragging ?
        { transition: 'none', transform: `translate(${x}px, ${y}px)` } :
        { transform: `translate(${x}px, ${y}px)` } : {};
      const resizeStyle = resize && dragging ? {
        transition: 'none'
      } : {};
      motionChildren = children({ dragableStyle, resizeStyle, onMouseDown: this.onMouseDown });
    }
    const resizeCls = classnames(`${prefixCls}-resize ${prefixCls}-resize-${placement}`, { needRTL: needRTL?.() })
    return <DialogDraggableContainer weId={`${this.props.weId || ''}_w35pi7`}>
      {resize && (<div className={resizeCls} onMouseDown={this.onDragWidthStart} />)}
      {motionChildren}
    </DialogDraggableContainer>;
  }
}

export default DialogDraggable;