import { ComponentType } from 'react';
import { Loadable } from '../../lib';
import { MSecretSelectType, MSecretSelectDialogProps } from './types';
import { doValidate, getSecretLevelInfo } from './utils';
import { mSecretSelectDefaultProps } from './defaultProps';

const MSecretSelect = Loadable({
  name: 'MSecretSelect', loader: () => import(
    /* webpackChunkName: 'ui_m_common_form' */
    './MSecretSelect')
}) as MSecretSelectType;

MSecretSelect.defaultProps = mSecretSelectDefaultProps;

const MSecretSelectDialog = Loadable({
  name: 'MSecretSelectDialog',
  loader: () => import(
    /* webpackChunkName: 'ui_m_common_form' */
    './MSecretSelectDialog')
}) as ComponentType<MSecretSelectDialogProps>;

MSecretSelectDialog.defaultProps = mSecretSelectDefaultProps;

MSecretSelect.doValidate = doValidate;
MSecretSelect.getSecretLevelInfo = getSecretLevelInfo;
MSecretSelect.MSecretSelectDialog = MSecretSelectDialog;

export type { SecretSelectProps as MSecretSelectProps } from './types';

export default MSecretSelect;
