/**
 * emoji数据映射列表: https://www.e-cology.com.cn/sp/doc/docDetail/8139675512482110607?type=IM_View&_isEm=1&_emGid=1702371469707012994&fromWeappMsgGroupId=1702371469707012994&weappAuthStr=eyJtc2dpZCI6IjE3MDQ5NDAxOTA3MDcwMjQzOTEiLCJmbGFnIjoxLCJncm91cF9pZCI6IjE3MDIzNzE0Njk3MDcwMTI5OTQifQ==
 * E9老数据: 51个
 * E10老数据: 172个
 * 表情总数: 245个 （和IM一一对照）
 * */

/**数组格式说明：[tag, title, labelId, iconKey, iconName, oldName_e10, oldName_e9]
tag: "👍", // 表情tag图标
title: "赞", // 表情文本
labelId: "86328", // 表情文本对应的多语言id
iconKey: ":thumbsup:", // 表情唯一key
iconName: "thumbsup", // 表情名称
oldName_e10: "thumbsup", // 对应E10老数据表情名称
oldName_e9: "34_thumbs_up", // 对应E9老数据表情名称
*  */

const emojiData = [
  [
    "👍",
    "赞",
    "86328",
    ":thumbsup:",
    "thumbsup",
    "thumbsup",
    "34_thumbs_up"
  ],
  [
    "👌",
    "OK",
    "256948",
    ":ok_hand:",
    "ok_hand",
    "ok_hand",
    "36_ok"
  ],
  [
    "🙏",
    "双手合十",
    "256949",
    ":pray:",
    "pray",
    "pray",
    "41_pray"
  ],
  [
    "💪",
    "加油",
    "19481",
    ":muscle:",
    "muscle",
    "muscle",
    "40_strong"
  ],
  [
    "✌",
    "胜利",
    "30917",
    ":v:",
    "v",
    "v",
    "37_yeah"
  ],
  [
    "👏",
    "鼓掌",
    "81085",
    ":clap:",
    "clap",
    "clap",
    "38_applaud"
  ],
  [
    "🙌",
    "扬手欢呼",
    "256950",
    ":raised_hands:",
    "raised_hands",
    "",
    ""
  ],
  [
    "🉑",
    "可接受",
    "256951",
    ":accept:",
    "accept",
    "",
    ""
  ],
  [
    "💯",
    "一百分",
    "256952",
    ":100:",
    "100",
    "",
    ""
  ],
  [
    "❓",
    "疑问",
    "28558",
    ":question:",
    "question",
    "",
    ""
  ],
  [
    "❗",
    "感叹",
    "256953",
    ":exclamation:",
    "exclamation",
    "",
    ""
  ],
  [
    "‼️",
    "双感叹",
    "256954",
    ":bangbang:",
    "bangbang",
    "",
    ""
  ],
  [
    "⁉️",
    "感叹疑问",
    "256955",
    ":interrobang:",
    "interrobang",
    "",
    ""
  ],
  [
    "😀",
    "嘿嘿",
    "256956",
    ":grinning:",
    "grinning",
    "grinning",
    ""
  ],
  [
    "😃",
    "哈哈",
    "256957",
    ":smiley:",
    "smiley",
    "",
    ""
  ],
  [
    "😄",
    "微笑",
    "153712",
    ":smile:",
    "smile",
    "smile",
    "4_smirk"
  ],
  [
    "😁",
    "嘻嘻",
    "256961",
    ":grin:",
    "grin",
    "grin",
    ""
  ],
  [
    "😆",
    "斜眼笑",
    "256962",
    ":laughing:",
    "laughing",
    "laughing",
    "17_laugh"
  ],
  [
    "😅",
    "苦笑",
    "256963",
    ":sweat_smile:",
    "sweat_smile",
    "sweat_smile",
    "16_embarrassed"
  ],
  [
    "🤣",
    "笑得打滚",
    "256964",
    ":laughing_roll:",
    "laughing_roll",
    "",
    ""
  ],
  [
    "😂",
    "笑哭了",
    "256965",
    ":joy:",
    "joy",
    "joy",
    "15_tearsofjoy"
  ],
  [
    "🙂",
    "呵呵",
    "256966",
    ":hehe:",
    "hehe",
    "regular_smile",
    "1_smile"
  ],
  [
    "🙃",
    "倒脸",
    "256967",
    ":reverse_face:",
    "reverse_face",
    "",
    ""
  ],
  [
    "😉",
    "眨眼",
    "81055",
    ":wink:",
    "wink",
    "wink_smile",
    "3_wink"
  ],
  [
    "😊",
    "羞涩微笑",
    "256968",
    ":blush:",
    "blush",
    "blush",
    "7_shy"
  ],
  [
    "😇",
    "微笑天使",
    "256969",
    ":innocent:",
    "innocent",
    "innocent",
    "11_angel"
  ],
  [
    "🥰",
    "喜笑颜开",
    "256970",
    ":beaming_smiles:",
    "beaming_smiles",
    "",
    ""
  ],
  [
    "😍",
    "花痴",
    "256971",
    ":heart_eyes:",
    "heart_eyes",
    "heart_eyes",
    "31_lustfully"
  ],
  [
    "🤩",
    "好崇拜哦",
    "256972",
    ":eyes_stars:",
    "eyes_stars",
    "",
    ""
  ],
  [
    "😘",
    "飞吻",
    "28971",
    ":blow_kiss:",
    "kissing_heart",
    "kissing_heart",
    "21_flykiss"
  ],
  [
    "😗",
    "亲亲",
    "81064",
    ":kissing:",
    "kissing",
    "kiss",
    ""
  ],
  [
    "☺️",
    "微笑",
    "153712",
    ":relaxed:",
    "relaxed",
    "relaxed",
    ""
  ],
  [
    "😚",
    "羞涩亲亲",
    "256974",
    ":kissing_closed_eyes:",
    "kissing_closed_eyes",
    "kissing_closed_eyes",
    ""
  ],
  [
    "😙",
    "微笑亲亲",
    "256975",
    ":kissing_smiling_eyes:",
    "kissing_smiling_eyes",
    "",
    ""
  ],
  [
    "🥲",
    "含泪的笑脸",
    "256976",
    ":tearful_smile:",
    "tearful_smile",
    "",
    ""
  ],
  [
    "😋",
    "好吃",
    "256977",
    ":yum:",
    "yum",
    "yum",
    "30_greedy"
  ],
  [
    "😛",
    "吐舌",
    "256978",
    ":stuck_out_tongue:",
    "stuck_out_tongue",
    "",
    ""
  ],
  [
    "😜",
    "单眼吐舌",
    "256979",
    ":stuck_out_tongue_winking_eye:",
    "stuck_out_tongue_winking_eye",
    "wink",
    ""
  ],
  [
    "🤪",
    "滑稽",
    "256980",
    ":funny:",
    "funny",
    "",
    ""
  ],
  [
    "😝",
    "眯眼吐舌",
    "256984",
    ":stuck_out_tongue_closed_eyes:",
    "stuck_out_tongue_closed_eyes",
    "tongue_smile",
    "6_tongue"
  ],
  [
    "🤑",
    "发财",
    "256985",
    ":moneyout_mouth:",
    "moneyout_mouth",
    "",
    ""
  ],
  [
    "🤗",
    "抱抱",
    "256986",
    ":hug_hug:",
    "hug_hug",
    "",
    ""
  ],
  [
    "🤭",
    "捂嘴",
    "256987",
    ":no_say:",
    "no_say",
    "facepalm",
    "25_facepalm"
  ],
  [
    "🤫",
    "安静的脸",
    "256988",
    ":quiet_face:",
    "quiet_face",
    "",
    ""
  ],
  [
    "🤔",
    "想一想",
    "256989",
    ":thoughtful_face:",
    "thoughtful_face",
    "",
    ""
  ],
  [
    "🤐",
    "闭嘴",
    "20839",
    ":shut_up:",
    "shut_up",
    "",
    ""
  ],
  [
    "🤨",
    "挑眉",
    "256990",
    ":eyebrow_raising:",
    "eyebrow_raising",
    "",
    ""
  ],
  [
    "😐",
    "冷漠",
    "256991",
    ":neutral_face:",
    "neutral_face",
    "expressionless",
    ""
  ],
  [
    "😑",
    "无语",
    "256992",
    ":expressionless:",
    "expressionless",
    "whatchutalkingabout_smile",
    "9_poker_face"
  ],
  [
    "😶",
    "沉默",
    "256993",
    ":no_mouth:",
    "no_mouth",
    "",
    ""
  ],
  [
    "😶‍🌫️",
    "迷茫",
    "256994",
    ":puzzled:",
    "puzzled",
    "",
    ""
  ],
  [
    "😏",
    "得意",
    "31569",
    ":smirk:",
    "smirk",
    "smirk",
    "32_sinister_smile"
  ],
  [
    "😒",
    "不高兴",
    "256995",
    ":unamused:",
    "unamused",
    "unamused",
    "18_dissatisfied"
  ],
  [
    "🙄",
    "翻白眼",
    "154559",
    ":white_eyes:",
    "white_eyes",
    "roll_eye",
    ""
  ],
  [
    "😬",
    "龇牙咧嘴",
    "256996",
    ":grimacing:",
    "grimacing",
    "",
    ""
  ],
  [
    "😮‍💨",
    "呼气",
    "256997",
    ":sigh:",
    "sigh",
    "",
    ""
  ],
  [
    "🤥",
    "说谎",
    "256998",
    ":lying_face:",
    "lying_face",
    "",
    ""
  ],
  [
    "😌",
    "松了口气",
    "256999",
    ":relieved:",
    "satisfied",
    "satisfied",
    ""
  ],
  [
    "😔",
    "沉思",
    "257000",
    ":pensive:",
    "pensive",
    "pensive",
    "20_meditation"
  ],
  [
    "😪",
    "睡觉",
    "32534",
    ":sleepy:",
    "sleepy",
    "sleepy",
    ""
  ],
  [
    "🤤",
    "流口水",
    "257001",
    ":drool_face:",
    "drool_face",
    "",
    ""
  ],
  [
    "😴",
    "睡着了",
    "81069",
    ":sleeping:",
    "sleeping",
    "sleeping",
    "27_sleep"
  ],
  [
    "😷",
    "感冒",
    "257002",
    ":mask:",
    "mask",
    "mask",
    "29_facemask"
  ],
  [
    "🤒",
    "发烧",
    "257003",
    ":fever:",
    "fever",
    "",
    ""
  ],
  [
    "🤕",
    "受伤",
    "20188",
    ":injured_face:",
    "injured_face",
    "",
    ""
  ],
  [
    "🤢",
    "恶心",
    "257004",
    ":vomiting_face:",
    "vomiting_face",
    "",
    ""
  ],
  [
    "🤮",
    "呕吐",
    "21951",
    ":vomiting:",
    "vomiting",
    "",
    ""
  ],
  [
    "🤧",
    "打喷嚏",
    "257005",
    ":blow_nose:",
    "blow_nose",
    "sick",
    ""
  ],
  [
    "🥵",
    "脸发烧",
    "257006",
    ":facial_fever:",
    "facial_fever",
    "",
    ""
  ],
  [
    "🥶",
    "冷脸",
    "257007",
    ":cold_face:",
    "cold_face",
    "",
    ""
  ],
  [
    "🥴",
    "头昏眼花",
    "257008",
    ":slightly_tipsy_face:",
    "slightly_tipsy_face",
    "",
    ""
  ],
  [
    "😵",
    "晕头转向",
    "257009",
    ":dizzy_face:",
    "dizzy_face",
    "dizzy_face",
    "28_dizzy"
  ],
  [
    "😵‍💫",
    "晕",
    "257012",
    ":dizzy:",
    "dizzy",
    "",
    ""
  ],
  [
    "🤯",
    "爆炸头",
    "257013",
    ":explosive_head:",
    "explosive_head",
    "",
    ""
  ],
  [
    "🤠",
    "牛仔帽脸",
    "257014",
    ":cowboy_hat_Face:",
    "cowboy_hat_Face",
    "",
    ""
  ],
  [
    "🥳",
    "聚会笑脸",
    "257015",
    ":party_face:",
    "party_face",
    "",
    ""
  ],
  [
    "🥸",
    "伪装的脸",
    "257016",
    ":disguised_face:",
    "disguised_face",
    "",
    ""
  ],
  [
    "😎",
    "墨镜笑脸",
    "257017",
    ":sunglasses:",
    "sunglasses",
    "sunglasses",
    "12_cool"
  ],
  [
    "🤓",
    "书呆子脸",
    "257018",
    ":bookworm_face:",
    "bookworm_face",
    "",
    ""
  ],
  [
    "🧐",
    "带单片眼镜的脸",
    "257019",
    ":glasses_face:",
    "glasses_face",
    "",
    ""
  ],
  [
    "😕",
    "困扰",
    "257020",
    ":confused:",
    "confused",
    "confused",
    "5_confused"
  ],
  [
    "😟",
    "担心",
    "17333",
    ":worried:",
    "worried",
    "worried",
    ""
  ],
  [
    "🙁",
    "微微不满",
    "257022",
    ":furrowed_brow:",
    "furrowed_brow",
    "sad_smile",
    "2_sad"
  ],
  [
    "☹️",
    "不满",
    "29992",
    ":frown:",
    "frown",
    "",
    ""
  ],
  [
    "😮",
    "吃惊",
    "257023",
    ":open_mouth:",
    "open_mouth",
    "astonished",
    ""
  ],
  [
    "😯",
    "缄默",
    "257024",
    ":hushed:",
    "hushed",
    "hushed",
    ""
  ],
  [
    "😲",
    "震惊",
    "257025",
    ":astonished:",
    "astonished",
    "omg_smile",
    "8_surprised"
  ],
  [
    "😳",
    "脸红",
    "257026",
    ":flushed:",
    "flushed",
    "flushed",
    "26_blush"
  ],
  [
    "🥺",
    "恳求的脸",
    "257027",
    ":pleading_face:",
    "pleading_face",
    "",
    ""
  ],
  [
    "😦",
    "啊",
    "257028",
    ":frowning:",
    "frowning",
    "",
    ""
  ],
  [
    "😧",
    "极度痛苦",
    "257029",
    ":anguished:",
    "anguished",
    "anguished",
    ""
  ],
  [
    "😨",
    "害怕",
    "257030",
    ":fearful:",
    "fearful",
    "fearful",
    ""
  ],
  [
    "😰",
    "冷汗",
    "81043",
    ":cold_sweat:",
    "cold_sweat",
    "cold_sweat",
    ""
  ],
  [
    "😥",
    "失望但如释重负",
    "257040",
    ":disappointed_relieved:",
    "relieved",
    "relieved",
    ""
  ],
  [
    "😢",
    "哭",
    "81046",
    ":cry:",
    "cry",
    "cry",
    ""
  ],
  [
    "😭",
    "放声大哭",
    "257041",
    ":sob:",
    "sob",
    "sob",
    "14_cry"
  ],
  [
    "😱",
    "吓死了",
    "257042",
    ":scream:",
    "scream",
    "scream",
    "23_fear"
  ],
  [
    "😖",
    "困惑",
    "81045",
    ":confounded:",
    "confounded",
    "confounded",
    ""
  ],
  [
    "😣",
    "痛苦",
    "257043",
    ":persevere:",
    "persevere",
    "persevere",
    ""
  ],
  [
    "😞",
    "失望",
    "81049",
    ":disappointed:",
    "disappointed",
    "disappointed",
    ""
  ],
  [
    "😓",
    "汗",
    "257044",
    ":sweat:",
    "sweat",
    "sweat",
    "19_sweat"
  ],
  [
    "😩",
    "累死了",
    "257045",
    ":weary:",
    "weary",
    "weary",
    ""
  ],
  [
    "😫",
    "累",
    "257046",
    ":tired_face:",
    "tired_face",
    "crazy",
    "24_crazy"
  ],
  [
    "🥱",
    "打呵欠",
    "257047",
    ":yawn:",
    "yawn",
    "",
    ""
  ],
  [
    "😤",
    "傲慢",
    "257048",
    ":triumph:",
    "triumph",
    "triumph",
    "22_angry"
  ],
  [
    "😡",
    "怒火中烧",
    "257049",
    ":rage:",
    "rage",
    "rage",
    "10_anger"
  ],
  [
    "😡",
    "生气",
    "81040",
    ":angry:",
    "angry",
    "angry",
    ""
  ],
  [
    "🤬",
    "嘴上有符号的脸",
    "257050",
    ":symbols_face:",
    "symbols_face",
    "",
    ""
  ],
  [
    "😈",
    "恶魔微笑",
    "257051",
    ":smiling_imp:",
    "smiling_imp",
    "smiling_imp",
    "13_devil"
  ],
  [
    "👿",
    "生气的恶魔",
    "257052",
    ":imp:",
    "imp",
    "imp",
    ""
  ],
  [
    "🌚",
    "黑脸",
    "257053",
    ":new_moon_with_face:",
    "toasted",
    "",
    ""
  ],
  [
    "👀",
    "眼睛",
    "81118",
    ":eyes:",
    "eyes",
    "eyes",
    ""
  ],
  [
    "💢",
    "怒",
    "257054",
    ":anger:",
    "anger",
    "indignation",
    ""
  ],
  [
    "💤",
    "ZZZ",
    "257055",
    ":zzz:",
    "zzz",
    "zzz",
    ""
  ],
  [
    "👶",
    "小宝贝",
    "257056",
    ":baby:",
    "baby",
    "baby",
    ""
  ],
  [
    "👦",
    "男孩",
    "81092",
    ":boy:",
    "boy",
    "boy",
    ""
  ],
  [
    "👧",
    "女孩",
    "81091",
    ":girl:",
    "girl",
    "girl",
    ""
  ],
  [
    "👨",
    "男人",
    "81089",
    ":man:",
    "man",
    "man",
    ""
  ],
  [
    "👩",
    "女人",
    "81090",
    ":woman:",
    "woman",
    "woman",
    ""
  ],
  [
    "🤦",
    "捂脸",
    "257064",
    ":cover_face:",
    "cover_face",
    "",
    ""
  ],
  [
    "🙋",
    "举手",
    "257065",
    ":raising_hand:",
    "raising_hand",
    "",
    ""
  ],
  [
    "🙇",
    "鞠躬",
    "257066",
    ":bow:",
    "bow",
    "",
    ""
  ],
  [
    "🤷",
    "耸肩",
    "257067",
    ":shrug_shoulders:",
    "shrug_shoulders",
    "",
    ""
  ],
  [
    "🙅",
    "禁止",
    "12795",
    ":no_good:",
    "no_good",
    "",
    ""
  ],
  [
    "👯",
    "跳一跳",
    "257068",
    ":dancers:",
    "dancers",
    "",
    ""
  ],
  [
    "👼",
    "天使宝宝",
    "257069",
    ":angel:",
    "angel",
    "angel_baby",
    ""
  ],
  [
    "👨‍👩‍👧",
    "家庭",
    "154930",
    ":family:",
    "family",
    "family",
    ""
  ],
  [
    "👫",
    "情侣",
    "154929",
    ":couple:",
    "couple",
    "lovers",
    ""
  ],
  [
    "🙈",
    "不看",
    "154926",
    ":see_no_evil:",
    "see_no_evil",
    "not_look",
    ""
  ],
  [
    "🙉",
    "不听",
    "154927",
    ":hear_no_evil:",
    "hear_no_evil",
    "not_listen",
    ""
  ],
  [
    "🙊",
    "不说",
    "32628",
    ":speak_no_evil:",
    "speak_no_evil",
    "shut_up",
    ""
  ],
  [
    "🤙",
    "666",
    "257070",
    ":666:",
    "666",
    "",
    ""
  ],
  [
    "👎",
    "鄙视",
    "12262",
    ":thumbsdown:",
    "thumbsdown",
    "thumbsdown",
    "35_thumbs_down"
  ],
  [
    "👊",
    "出拳",
    "257071",
    ":facepunch:",
    "punch",
    "punch",
    ""
  ],
  [
    "✊",
    "拳头",
    "81086",
    ":fist:",
    "facepunch",
    "facepunch",
    ""
  ],
  [
    "🤜",
    "右碰拳",
    "257072",
    ":right_fist:",
    "right_fist",
    "",
    ""
  ],
  [
    "🤛",
    "左碰拳",
    "257073",
    ":left_fist:",
    "left_fist",
    "",
    ""
  ],
  [
    "✋",
    "手掌",
    "257074",
    ":palm:",
    "palm",
    "hand",
    ""
  ],
  [
    "🤟",
    "爱你",
    "81084",
    ":love_you:",
    "love_you",
    "",
    ""
  ],
  [
    "👋",
    "挥手",
    "257074",
    ":wave:",
    "wave",
    "",
    ""
  ],
  [
    "🤝",
    "握手",
    "153724",
    ":handshake:",
    "shake",
    "handshake",
    "39_handshake"
  ],
  [
    "☝️",
    "第一",
    "14735",
    ":point_up:",
    "point_up",
    "first",
    ""
  ],
  [
    "❤️",
    "爱心",
    "154963",
    ":heart:",
    "heart",
    "heart",
    "42_heart"
  ],
  [
    "💔",
    "心碎",
    "153725",
    ":broken_heart:",
    "brokenHeart",
    "broken_heart",
    "43_broken_heart"
  ],
  [
    "👄",
    "嘴唇",
    "257075",
    ":lips:",
    "lips",
    "mouth",
    "44_kiss"
  ],
  [
    "🌹",
    "玫瑰",
    "20189",
    ":rose:",
    "rose",
    "rose",
    "46_rose"
  ],
  [
    "🥀",
    "凋谢",
    "257076",
    ":withered_roses:",
    "wilt",
    "",
    ""
  ],
  [
    "🎉",
    "庆祝",
    "30822",
    ":tada:",
    "tada",
    "tada",
    "47_fireworks"
  ],
  [
    "☀",
    "太阳",
    "18459",
    ":sunny:",
    "sunny",
    "sunny",
    ""
  ],
  [
    "🌙",
    "月亮",
    "81135",
    ":moon:",
    "moon",
    "moon",
    "51_moon"
  ],
  [
    "☁",
    "云",
    "81136",
    ":cloud:",
    "cloud",
    "cloud",
    ""
  ],
  [
    "⚡",
    "闪电",
    "81137",
    ":zap:",
    "zap",
    "zap",
    ""
  ],
  [
    "⭐",
    "星星",
    "154940",
    ":star:",
    "star",
    "stars",
    ""
  ],
  [
    "❄",
    "雪花",
    "154941",
    ":snowflake:",
    "snowflake",
    "snowflake",
    ""
  ],
  [
    "☔",
    "雨伞",
    "154942",
    ":umbrella:",
    "umbrella",
    "rain",
    ""
  ],
  [
    "🌥",
    "多云",
    "154939",
    ":cloudy:",
    "cloudy",
    "cloudy",
    ""
  ],
  [
    "🎂",
    "生日蛋糕",
    "81077",
    ":birthday:",
    "birthday",
    "birthday",
    "49_cake"
  ],
  [
    "🍚",
    "米饭",
    "81127",
    ":rice:",
    "rice",
    "rice",
    ""
  ],
  [
    "🍱",
    "盒饭",
    "257077",
    ":bento:",
    "bento",
    "",
    ""
  ],
  [
    "🍜",
    "面条",
    "257078",
    ":ramen:",
    "ramen",
    "",
    ""
  ],
  [
    "🥮",
    "月饼",
    "257079",
    ":moon_cake:",
    "moon_cake",
    "",
    ""
  ],
  [
    "🥟",
    "饺子",
    "257087",
    ":dumpling:",
    "dumpling",
    "",
    ""
  ],
  [
    "🍺",
    "啤酒",
    "81078",
    ":beer:",
    "beer",
    "beer",
    ""
  ],
  [
    "🍻",
    "干杯",
    "154966",
    ":beers:",
    "beers",
    "cheers",
    ""
  ],
  [
    "🥂",
    "碰杯",
    "257088",
    ":cheers:",
    "cheers",
    "",
    ""
  ],
  [
    "☕",
    "热饮",
    "257089",
    ":coffee:",
    "coffee",
    "coffee",
    "50_coffee"
  ],
  [
    "🍖",
    "BBQ",
    "257090",
    ":meat_on_bone:",
    "meat_on_bone",
    "bbq",
    ""
  ],
  [
    "🍦",
    "甜筒冰激凌",
    "257091",
    ":icecream:",
    "icecream",
    "ice_cream",
    ""
  ],
  [
    "🍫",
    "巧克力",
    "154937",
    ":chocolate_bar:",
    "chocolate_bar",
    "chocolate",
    ""
  ],
  [
    "🍉",
    "红酒",
    "154953",
    ":wine_glass:",
    "red_Wine",
    "red_wine",
    ""
  ],
  [
    "🍓",
    "西瓜",
    "12206",
    ":watermelon:",
    "watermelon",
    "watermelon",
    ""
  ],
  [
    "🍓",
    "草莓",
    "81125",
    ":strawberry:",
    "strawberry",
    "strawberry",
    ""
  ],
  [
    "🍒",
    "樱桃",
    "81123",
    ":cherries:",
    "cherries",
    "cherries",
    ""
  ],
  [
    "🍐",
    "梨",
    "154936",
    ":pear:",
    "pear",
    "pear",
    ""
  ],
  [
    "🐭",
    "鼠",
    "257092",
    ":mouse:",
    "mouse",
    "mouse",
    ""
  ],
  [
    "🐮",
    "牛",
    "154932",
    ":cow:",
    "cow",
    "cow",
    ""
  ],
  [
    "🐯",
    "虎",
    "257093",
    ":tiger:",
    "tiger",
    "tiger",
    ""
  ],
  [
    "🐰",
    "兔",
    "257094",
    ":rabbit:",
    "rabbit",
    "",
    ""
  ],
  [
    "🐲",
    "龙",
    "257095",
    ":dragon_face:",
    "dragon_face",
    "",
    ""
  ],
  [
    "🐍",
    "蛇",
    "81108",
    ":snake:",
    "snake",
    "snake",
    ""
  ],
  [
    "🐴",
    "马",
    "154935",
    ":horse:",
    "horse",
    "horse",
    ""
  ],
  [
    "🐑",
    "羊",
    "257096",
    ":sheep:",
    "sheep",
    "",
    ""
  ],
  [
    "🐵",
    "猴",
    "257099",
    ":monkey_face:",
    "monkey_face",
    "",
    ""
  ],
  [
    "🐔",
    "鸡",
    "81104",
    ":chicken:",
    "chicken",
    "chicken",
    ""
  ],
  [
    "🐶",
    "狗",
    "81101",
    ":dog:",
    "dog",
    "dog",
    ""
  ],
  [
    "🐷",
    "猪",
    "154931",
    ":pig:",
    "pig",
    "pig",
    ""
  ],
  [
    "🐱",
    "猫",
    "81099",
    ":cat:",
    "cat",
    "cat",
    ""
  ],
  [
    "🐻",
    "熊",
    "81107",
    ":bear:",
    "bear",
    "bear",
    ""
  ],
  [
    "🐨",
    "考拉",
    "81105",
    ":koala:",
    "koala",
    "koala",
    ""
  ],
  [
    "🐞",
    "瓢虫",
    "257100",
    ":beetle:",
    "beetle",
    "beetle",
    ""
  ],
  [
    "🐛",
    "毛毛虫",
    "154933",
    ":bug:",
    "bug",
    "bug",
    ""
  ],
  [
    "🌻",
    "向日葵",
    "154972",
    ":sunflower:",
    "sunflower",
    "sunflower",
    ""
  ],
  [
    "🌲",
    "松树",
    "154971",
    ":evergreen_tree:",
    "evergreen_tree",
    "pine",
    ""
  ],
  [
    "🎄",
    "圣诞树",
    "154970",
    ":christmas_tree:",
    "christmas_tree",
    "christmas_tree",
    ""
  ],
  [
    "⛄",
    "雪人",
    "81132",
    ":snowman:",
    "snowman",
    "snowman",
    ""
  ],
  [
    "👻",
    "鬼",
    "257097",
    ":ghost:",
    "ghost",
    "ghost",
    ""
  ],
  [
    "👻",
    "外星人",
    "81133",
    ":alien:",
    "alien",
    "alien",
    ""
  ],
  [
    "⚽",
    "足球",
    "81096",
    ":soccer:",
    "soccer",
    "soccer",
    ""
  ],
  [
    "🏀",
    "篮球",
    "20157",
    ":basketball:",
    "basketball",
    "basketball",
    ""
  ],
  [
    "💊",
    "药丸",
    "81140",
    ":pill:",
    "pill",
    "pill",
    ""
  ],
  [
    "🚀",
    "火箭",
    "81130",
    ":rocket:",
    "rocket",
    "rocket",
    ""
  ],
  [
    "💍",
    "戒指",
    "81129",
    ":ring:",
    "ring",
    "ring",
    ""
  ],
  [
    "💣",
    "炸弹",
    "81131",
    ":bomb:",
    "bomb",
    "bomb",
    ""
  ],
  [
    "📚",
    "书籍",
    "81121",
    ":books:",
    "books",
    "books",
    ""
  ],
  [
    "💡",
    "灯泡",
    "257098",
    ":bulb:",
    "bulb",
    "bulb",
    "33_lightbulb"
  ],
  [
    "⌚",
    "手表",
    "81116",
    ":watch:",
    "watch",
    "watch",
    ""
  ],
  [
    "👓",
    "眼镜",
    "81117",
    ":eyeglasses:",
    "eyeglasses",
    "eyeglasses",
    ""
  ],
  [
    "🔥",
    "火",
    "81138",
    ":fire:",
    "fire",
    "fire",
    ""
  ],
  [
    "🍀",
    "四叶草",
    "81126",
    ":four_leaf_clover:",
    "four_leaf_clover",
    "four_leaf_clover",
    ""
  ],
  [
    "🎁",
    "礼物",
    "12672",
    ":gift:",
    "gift",
    "gift",
    "48_gift"
  ],
  [
    "💎",
    "钻石",
    "154944",
    ":gem:",
    "gem",
    "gem",
    ""
  ],
  [
    "🔑",
    "钥匙",
    "81120",
    ":key:",
    "key",
    "key",
    ""
  ],
  [
    "🚆",
    "火车",
    "35328",
    ":train2:",
    "train2",
    "train",
    ""
  ],
  [
    "💀",
    "骷髅",
    "81094",
    ":skull:",
    "skull",
    "skull",
    ""
  ],
  [
    "💩",
    "便便",
    "81097",
    ":hankey:",
    "poop",
    "poop",
    ""
  ],
  [
    "🔪",
    "菜刀",
    "257109",
    ":hocho:",
    "cleaver",
    "",
    ""
  ],
  [
    "🧧",
    "红包",
    "257110",
    ":red_envelope:",
    "packet",
    "",
    ""
  ],
  [
    "🎇",
    "烟花",
    "257111",
    ":sparkler:",
    "fireworks",
    "",
    ""
  ],
  [
    "🧨",
    "爆竹",
    "257112",
    ":firecracker:",
    "firecracker",
    "",
    ""
  ],
  [
    "✉",
    "信封",
    "153727",
    ":email:",
    "email",
    "envelope",
    "45_envelope"
  ],
  [
    "🕒",
    "离开",
    "150990",
    ":clock3:",
    "clock3",
    "leave",
    ""
  ],
  [
    "🏢",
    "办公楼",
    "257113",
    ":office:",
    "office",
    "business",
    ""
  ],
  [
    "💻",
    "忙碌",
    "150991",
    ":computer:",
    "computer",
    "busy",
    ""
  ],
  [
    "🖥",
    "会议",
    "32787",
    ":desktop_computer:",
    "desktop_computer",
    "meeting",
    ""
  ],
  [
    "🏖",
    "度假",
    "257114",
    ":holiday:",
    "holiday",
    "holiday",
    ""
  ],
  [
    "🎤",
    "麦克风",
    "154959",
    ":microphone:",
    "microphone",
    "microphone",
    ""
  ],
  [
    "🎲",
    "骰子",
    "154958",
    ":game_die:",
    "game_die",
    "dice",
    ""
  ],
  [
    "🎵",
    "音乐",
    "154987",
    ":musical_note:",
    "musical_note",
    "music",
    ""
  ],
  [
    "🏂",
    "单板滑雪",
    "154957",
    ":snowboarder:",
    "snowboarder",
    "snowboarding",
    ""
  ],
  [
    "🏡",
    "房子",
    "154968",
    ":house_with_garden:",
    "house_with_garden",
    "house",
    ""
  ],
  [
    "🀄",
    "麻将",
    "154956",
    ":mahjong:",
    "mahjong",
    "mahjong",
    ""
  ],
  [
    "📞",
    "电话听筒",
    "154955",
    ":telephone_receiver:",
    "telephone_receiver",
    "telephone",
    ""
  ],
  [
    "📣",
    "扩音器",
    "154954",
    ":mega:",
    "mega",
    "megaphone",
    ""
  ],
  [
    "🚫",
    "禁止",
    "12795",
    ":no_entry_sign:",
    "no_entry_sign",
    "prohibit",
    ""
  ],
  [
    "🚿",
    "淋浴",
    "154962",
    ":shower:",
    "shower",
    "shower",
    ""
  ],
  [
    "🌏",
    "地球",
    "154967",
    ":earth_asia:",
    "earth_asia",
    "earth",
    ""
  ],
  [
    "🔫",
    "水枪",
    "257086",
    ":gun:",
    "gun",
    "pistol",
    ""
  ],
  [
    "🕖",
    "表",
    "154951",
    ":clock7:",
    "clock7",
    "clock",
    ""
  ],
  [
    "👑",
    "王冠",
    "154960",
    ":crown:",
    "crown",
    "crown",
    ""
  ],
  [
    "🎓",
    "毕业帽",
    "154961",
    ":mortar_board:",
    "mortar_board",
    "graduation_cap",
    ""
  ],
  [
    "⏰",
    "闹钟",
    "154950",
    ":alarm_clock:",
    "alarm_clock",
    "alarm_clock",
    ""
  ],
  [
    "⏳",
    "沙漏",
    "154949",
    ":hourglass_flowing_sand:",
    "hourglass_flowing_sand",
    "hourglass",
    ""
  ],
  [
    "✏",
    "铅笔",
    "154948",
    ":pencil2:",
    "pencil2",
    "pencil",
    ""
  ],
  [
    "✏",
    "口红",
    "154947",
    ":lipstick:",
    "lipstick",
    "lipstick",
    ""
  ],
  [
    "💰",
    "钱袋",
    "154946",
    ":moneybag:",
    "moneybag",
    "wallet",
    ""
  ]
];

export const getEmojiData = () => emojiData.map(item => {
  return {
    tag: item[0], // 表情tag图标
    title: item[1], // 表情文本
    labelId: item[2], // 表情文本对应的多语言id
    iconKey: item[3], // 表情唯一key
    iconName: item[4], // 表情名称
    oldName_e10: item[5], // 对应E10老数据表情名称
    oldName_e9: item[6], // 对应E9老数据表情名称
  }
})
