// export   * as default from "d3";

const D3 = ()=> import(
  /* webpackChunkName: "d3" */
  'd3');

  


export default D3;

export const _D3 = () =>  import(
  /* webpackChunkName: "d3" */
  'd3');
