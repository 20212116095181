import { ua } from '@weapp/utils';
import { FormType, FormRenderProps } from './types';
import Skeleton, { formSkeletonHeight } from './skeleton/index';
import { Loadable, LoadingType, Skeleton as SkeletonLoading, constants  } from '../../lib';
import { getSensitiveWordScanContent } from './utils/index';
const { formClsPrefix, USESKELETON } = constants;
const skeletonStyle = { marginBottom: 5 }

export const FormLoading: LoadingType<FormRenderProps> = (props) => {
  if (!USESKELETON) return <div/>
  const isMobile = props.isMobile || ua.device !== 'PC';
  const layoutLen = props.store?.layout?.length || 0;
  const height = isMobile || !props.store?.isFormInit ? formSkeletonHeight : 40 * layoutLen;
  const skeletonProps = isMobile ? {} : { singleHeight: 40 };
  return <SkeletonLoading weId={`${props.weId || ''}_ju7xcv`} skeletonHeight={height} span={1} style={skeletonStyle} {...skeletonProps} />;
}

const Form = Loadable({
  name: 'Form',
  loader: () => import(
    /* webpackChunkName: "ui_form" */
    './Form'),
  loading: FormLoading,
}) as FormType;

Form.defaultProps = {
  prefixCls: formClsPrefix,
  useSkeleton: USESKELETON,
  skeletonHeight: 0,
  newValidateTipType: false,
  enabelValidateTipPosition: true,
}

Form.Skeleton = Skeleton;

Form.getSensitiveWordScanContent = getSensitiveWordScanContent;

export type {
  FormType, FormRenderProps, FormDatas, FormLayoutProps, FormValue,
  FormLayoutType, LabelIdsProps as FormLabelIdsProps,
  CustomHidesProps as FormCustomHidesProps,
  GroupProps as FormGroupProps,
  FormProps as FormCustomProps,
  FormStyleType,
  FormCascadeRules,
  DisplayLocationForRequiredMark as FormDisplayLocationForRequiredMark,
  FormHelpTipPostion,
  FormItemSingleProps,
  FormGroupItemProps
} from './types';

export { getMinHeight as getMobileFieldMinHeight, getLabelMinHeight as getMobileLabelMinHeight, formSkeletonHeight } from './skeleton/index';

export type { FormSkeletonProps, FormSkeletonType } from './skeleton/index';

export { default as FormStore } from './Store';

export type { FormStoreType } from './Store';

export type { AllDatas as FormInitAllDatas, CustomLayoutProps as FormCustomLayoutProps } from './Store';

export default Form;
