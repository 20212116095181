import { classnames } from "@weapp/utils";
import { searchAdvancedClsPrefix } from "../../../../constants";
import { mSearchAdvancedClsPrefix } from "../../../../constants/index.m";
import { AnyObj } from "../../../../types/common";
import { FormItemSingleProps, FormLayoutProps, FormLayoutType } from "../../../form";
import { SearchAdvancedInitAllDatas, SearchAdvancedStoreType } from "../../store/Store";
import { FormItemWrapperProps, FormSingleItemProps } from "../../../form-item";
import { ReactNode } from "react";
import { BROWSER_SUB_SUFFIX, isNeedBrowserIncludingSub } from "../../store/browser-including-subordinate";
import { isObservableArray } from "mobx";

// 去除后缀
export const removeSuffix = (dataKey: string, suffix?: string) => dataKey.slice(0, dataKey.indexOf(suffix || '_customField'));

// 是否其他分组字段
export const isCustomField = (dataKey: string) => dataKey.indexOf('_customField') >= 0;
// 是否只读设置字段
export const isReadOnlySetting = (dataKey: string) => dataKey.indexOf('_readonly') >= 0;
// 是否只读设置字段
export const isVisibleSetting = (dataKey: string) => dataKey.indexOf('_visible') >= 0;
// 是否只读设置字段
export const isSortSetting = (dataKey: string) => dataKey.indexOf('_sort') >= 0;

// 获取其他分组字段后缀
export const getCustomFieldId = (dataKey: string, key: string) => {
  const startIndex = dataKey.indexOf('_customField') + 1;
  const endIndex = dataKey.length - key.length;
  return dataKey.slice(startIndex, endIndex);
}
/**
 * 是否设置高级搜索默认配置
 * 1.设置后必有常用分组的配置数据
 * 2.设置后必有readonly等配置
 * 
 * @param data 高级搜索回显数据
 * @returns 
 */
export const isSetting = (data: AnyObj, content: SearchAdvancedInitAllDatas) => {
  const flag = data ? Object.keys(data).find((key) => isReadOnlySetting(key) || isVisibleSetting(key) || isSortSetting(key)) : false;
  // data为空，判断是否存在禁用的配置
  if (!flag) return content?.layout?.find((row) => row?.find((col) => col.disable))
  return flag;
}

export const getLayoutFromItemKey = (store: SearchAdvancedStoreType, layout: FormLayoutProps | null, otherParams?: AnyObj) => {
  const {
    browserConfigStore,
    isMobile
  } = store;
  const {
    hasDefaultConfig, readOnlyItems
  } = browserConfigStore || {};
  if (hasDefaultConfig) {
    const items = layout?.items;
    if (items && readOnlyItems && items?.find((itemKey: string) => readOnlyItems.has(`${itemKey}_${otherParams?.labelId}`))) {
      const prefix = isMobile ? mSearchAdvancedClsPrefix : searchAdvancedClsPrefix;
      return {
        ...layout,
        wrapClassName: classnames(layout?.wrapClassName, `${prefix}-browserConfig-form-item-readonly`)
      }
    };
  }
  return layout;
}

export const getSnapContent = (_snapContent: SearchAdvancedInitAllDatas, store: SearchAdvancedStoreType, otherParams?: AnyObj) => {
  const { browserConfigStore } = store;
  const { allDatas } = browserConfigStore || {};
  const { items } = allDatas || {};
  let snapData = {};
  // 过滤常用筛选老数据内存储的多余"_subBrowserData_fromBrowserConfig"标记
  Object.keys(_snapContent?.data).forEach((dataKey: string) => {
    let key = dataKey;
    if (isCustomField(dataKey)) key = removeSuffix(dataKey);
    // 过滤常用筛选老数据内存储的多余"_subBrowserData_fromBrowserConfig"标记
    if ((isNeedBrowserIncludingSub(items?.[key] as any) || isNeedBrowserIncludingSub(_snapContent?.items?.[key])) && (Array.isArray(_snapContent.data[dataKey]) || isObservableArray(_snapContent.data[dataKey]))) {
      if (store?.browserIncludingSubStore?.disabledAfterCheckSnaps && _snapContent?.browserIncludingSubDatas?.[dataKey] && store?.commonFilterStore?.id) {
        snapData = {
          ...snapData,
          [dataKey]: (_snapContent.data[dataKey] as any)?.filter((da: any) => da.id?.indexOf?.(BROWSER_SUB_SUFFIX) < 0)
        }
      } else {
        snapData = {
          ...snapData,
          [dataKey]: (_snapContent.data[dataKey] as any)?.filter((da: any) => da.id?.indexOf?.(BROWSER_SUB_SUFFIX) < 0 && !da[`_${BROWSER_SUB_SUFFIX}__hide`])
        }
      }
    } else {
      const nowItem = items?.[key] || _snapContent?.items?.[key];
      if (!_snapContent.data?.[dataKey] && nowItem?.itemType === 'SELECT' && (nowItem?.multiple || nowItem?.otherParams?.multiple)) {
        snapData = {
          ...snapData,
          [dataKey]: [],
        }
      } else {
        snapData = {
          ...snapData,
          [dataKey]: _snapContent.data[dataKey],
        }
      }
    }
  })
  const snapContent: SearchAdvancedInitAllDatas = {
    ..._snapContent,
    data: snapData,
  }
  return snapContent;
}

let browserConfigUtils = {
  transItems: (itemKey: string, item: FormSingleItemProps, readOnlyItems?: Set<string | undefined>, otherConfigs?: AnyObj): FormSingleItemProps => item,
  transLayout: (layout: FormLayoutType[], hideLayouts?: Set<string | undefined>, otherConfigs?: AnyObj) => layout,
  transBrowserConfigDatas: (content: SearchAdvancedInitAllDatas, store: SearchAdvancedStoreType, needTransBrowserConfigLayout?: boolean, isReset?: boolean) => ({ content, needInit: true }),
  customRenderLabel: (store: SearchAdvancedStoreType, id: string, label?: string, content?: JSX.Element, props?: FormItemSingleProps) => content,
  customRenderWrapper: (prefix?: string, store?: SearchAdvancedStoreType, props?: FormItemWrapperProps, com?: ReactNode, otherParams?: AnyObj) => com,
  updateSnapes: (_snapContent: SearchAdvancedInitAllDatas, store: SearchAdvancedStoreType, otherParams?: AnyObj) => {
    const snapContent = getSnapContent(_snapContent, store, otherParams);
    return { content: snapContent, needInit: true };
  }
};
export const getBrowserConfigUtils = () => browserConfigUtils as AnyObj;
export const setBrowserConfigUtils = (utilsFuncs: any) => browserConfigUtils = utilsFuncs;
export const loadBrowserConfigUtils = async() => {
  const utilFuncs = await import(
    /* webpackChunkName: "searchadvanced_browser_include_sub" */
    './index'
  );
  setBrowserConfigUtils(utilFuncs);
  return browserConfigUtils
}
