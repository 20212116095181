import Loadable from '../../react-loadable';
import { LocaleType } from './types';
import { localeClsPrefix } from "../../constants/index";
import { testStringSetLocale } from './utils/regexp'

const Locale = Loadable({
  name: 'Locale', loader: () => import(
    /* webpackChunkName: "locale" */
    './Locale')
}) as LocaleType;

Locale.defaultProps = {
  prefixCls: localeClsPrefix,
  filedType: 'INPUT',
  matchWindowIsEnableMultiLang: true,
  chooseMultiLang: false,
  type: 'dialog',
  hidePanelFooter: false,
  isSimpleTraditionalLink: true,
  getUrlType: 'POST',
  selectedWrap: true,
  isLocaleValueIncomeAutoChange: false, //新的多语言格式数据传入时组件内是否自动触发onchange -> 模块可选性开启是否和e9逻辑保持同步
  suitLineFeed: true
};

Locale.testStringSetLocale = testStringSetLocale

export type { LocaleProps, LocaleLangType } from "./types";
export default Locale;
export { testStringSetLocale } from './utils/regexp';
