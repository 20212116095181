
import { ComponentType } from 'react'; 
import { Loadable } from '../../lib';
import { SecretSelectType, SecretSelectDialogProps, SecretLevelValidityShowType } from './types';
import { doValidate, getSecretLevelInfo, getSecretLevelOption } from './utils';
import { secretSelectDefaultProps } from './defaultProps';

const SecretSelect = Loadable({
  name: 'SecretSelect', 
  loader: () => import(
    /* webpackChunkName: 'secret-select' */
    './SecretSelect')
}) as SecretSelectType;

SecretSelect.defaultProps = secretSelectDefaultProps;

const SecretSelectDialog = Loadable({
  name: 'SecretSelectDialog',
  loader: () => import(
    /* webpackChunkName: 'secret-select' */
    './SecretSelectDialog')
}) as ComponentType<SecretSelectDialogProps>;

SecretSelectDialog.defaultProps = secretSelectDefaultProps;

SecretSelect.doValidate = doValidate;
SecretSelect.getSecretLevelInfo = getSecretLevelInfo;
SecretSelect.getSecretLevelOption = getSecretLevelOption;
SecretSelect.SecretSelectDialog = SecretSelectDialog;

/* 工具方法 */
export { getSecretLevelOption, getSecretLevelInfo, SecretLevelValidityShowType };

export type { SecretSelectProps, SecretSelectOptionType, SecretSelectValueType } from './types';

export default SecretSelect;
