import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { AnyObj } from '../types/common';
import doValidator from './validator';
import { eventEmitter, ua, weappSDK, dayjs } from '@weapp/utils'
import { uiAppName } from '../constants/index';
import { isObservableArray } from 'mobx';

export {
  doValidator,
}
export { default as CacheStorage } from './cacheStorage';
export type { CacheStorageType } from './cacheStorage';
// import { SelectValueType, FormLayoutType } from '../lib';
/*
* 单级路由组件，可以动态挂在任何一级路由下
* 需要获取父级路由
*/
export function formatParentPath(props: RouteComponentProps) {
  return props.match.url.replace(/\/$/, () => '');
}

/**
 * 判断属性是否需要同步到state
 * @param name 属性名称
 * @param props 当前的props
 * @param prevProps 之前的props
 */
export function needSync<T extends AnyObj>(name: string, props: T, prevProps?: T) {
  return (!prevProps && name in props && props[name] !== undefined) || (prevProps && prevProps[name] !== props[name]);
}

/**
 * 批量克隆属性
 * @param props 被克隆的props
 * @param keys 需要克隆的属性名称
 */
export function cloneProps<T extends {}>(props: T, keys: string[]): Partial<T> {
  const cProps: Partial<T> = {};
  keys.forEach(k => {
    if (needSync(k, props)) {
      const key = k as keyof Partial<T>;
      cProps[key] = props[key] as any;
    }
  });
  return cProps;
}

/**
 * addEventListener 包装器
 * @param target EventTarget
 * @param eventType 表示监听事件类型的字符串。
 * @param cb 当所监听的事件类型触发时，会接收到一个事件通知（实现了 Event 接口的对象）对象。
 * @param option 一个指定有关 listener 属性的可选参数对象。
 * @returns 包含 remove 事件的对象
 */
export function addEventListenerWrap(target: any, eventType: unknown, cb: any, option?: unknown) {
  /* eslint camelcase: 2 */
  const callback = ReactDOM.unstable_batchedUpdates
    ? function run(e: unknown) {
      ReactDOM.unstable_batchedUpdates(cb, e);
    }
    : cb;
  if (target.addEventListener) {
    target.addEventListener(eventType, callback, option);
  }
  return {
    remove: () => {
      if (target.removeEventListener) {
        target.removeEventListener(eventType, callback);
      }
    },
  };
}

/*
* _union([arrays]) 创建一个按顺序排列的唯一值的数组, 所有给定数组的元素值使用SameValueZero做等值比较
* arrays:（数组）的并集，按顺序返回，返回数组的元素是唯一的
* _union([2], [1, 2])  => return [2, 1]
*/
export function _union<T>(
  ...arrays: Array<ArrayLike<T> | null | undefined>
): T[] {
  const exists = new Set<T>();
  const ret: T[] = [];

  for (let i = 0; i < arrays.length; i++) {
    const arr = arrays[i];
    if (arr === null || arr === undefined) {
      continue;
    }

    for (let j = 0; j < arr.length; j++) {
      const elem = arr[j];
      if (!exists.has(elem)) {
        ret.push(elem);
        exists.add(elem);
      }
    }
  }

  return ret;
}

/**
 * 阻止 react 事件
 * @param e react 事件
 */
export function pauseEvent(e: React.MouseEvent | React.KeyboardEvent | React.TouchEvent) {
  e.stopPropagation();
  e.preventDefault?.();
  e.nativeEvent?.preventDefault?.();
  e.nativeEvent?.stopImmediatePropagation?.()
}

/**
 * 是否是浏览器
 */
export const isBrowser = !!(typeof window !== 'undefined' && window);

/**
 * 检查 value 是否是 null 或者 undefined。
 * @param value 检查的 value
 * @returns boolean
 */
export function isNil(value: any): value is null | undefined {
  return value === undefined || value === null;
}

// 获得随机6位字符串
export function getHash(len: number = 6) {
  return Math.random().toString(36).substr(2, len);
}

/**
 * 判断对象
 * @param data 当前的data
 */
export function isObject(data: any) {
  return typeof data === 'object' && data !== null;
}

/**
 * 判断前后值是否相等
 * @param data 当前的data
 * @param preData 之前的preData
 */

export function isEqual(data: any, preData: any, dependencies?: Array<string | number>) {
  // 两者不均为对象
  if (!isObject(data) || !isObject(preData)) {
    return data === preData;
  }

  // 两者为同一对象或者数组
  if (data === preData) return true;

  if (Object.prototype.toString.call(data) !== Object.prototype.toString.call(preData)) return false;


  if (Object.prototype.toString.call(data) === "[object Array]") {
    if (data.length !== preData.length) return false;
  }

  // 2023.08.29 针对日期类型单独调整
  if (Object.prototype.toString.call(data) === '[object Date]') {
    const d1 = dayjs(data)
    const d2 = dayjs(preData)
    return d1.isValid() && d2.isValid() && +dayjs(data) === +dayjs(preData)
  }

  // 判断对象/数组 keys 长度
  const dataKeys = isObservableArray(data) ? Object.keys(data?.slice()) : Object.keys(data);
  const preDataKeys = isObservableArray(preData) ? Object.keys(preData?.slice()) : Object.keys(preData);
  if (dataKeys.length !== preDataKeys.length) return false;

  // 对象/数组 keys长度一致，比较内容
  for (let key in data) {
    if (dependencies && !dependencies?.includes(key)) continue;
    const res = isEqual(data[key], preData[key]);
    if (!res) return false;
  }
  return true;
}

/**
 * 防抖 debounce, 周期结束执行, 简易版
 * @param fn 需执行的function
 * @param wait 延时时间
 */

export function debounce(fn: any, wait: number) {
  let timer: NodeJS.Timeout | null;

  let lastTime: number = new Date().getTime();

  const run = (args: IArguments) => {
    timer = setTimeout(() => {
      fn(args[0]);
      if (timer) clearTimeout(timer);
      timer = null;
    }, wait);
  }

  const clean = () => {
    if (timer) clearTimeout(timer);
    timer = null;
  }

  return function () {
    const time = new Date().getTime();
    if (time - lastTime < wait) {
      clean();
      run(arguments);
    } else {
      run(arguments);
    }
    lastTime = time;
  }
}

/**
 * 节流
 * @param func 原方法
 * @param wait 延时执行时间
 * @returns 
 */
export function throttle<T extends any[] = any[], V extends any = any>(func: (...args: T) => V, wait: number) {
  let timeout: NodeJS.Timeout | null;
  let result: V | null = null;
  function throttled(...args: T): V {
    if (!result) result = func(...args);
    if (!timeout) {
      timeout = setTimeout(() => {
        if (timeout) clearTimeout(timeout);
        timeout = null;
        result = func(...args);
      }, wait)
    }
    return result;
  }
  return throttled;
}

/**
 * closest, 找到最近的某个dom节点
 * @param el 元素
 * @param selector 选择器字符串
 * @returns 返回最近父元素
 */
export function closest(el: any, selector: string) {
  const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
  while (el) {
    if (el.nodeType === 1 && matchesSelector.call(el, selector)) {
      break;
    }
    el = el.parentElement || el.parentNode;
  }
  return el;
}

/**
 * 获取六位随机字符
 * @returns 六位随机字符
 */
export function getRandom() {
  return Math.random().toString(36).substr(2, 6);
}

/**
 * 
 * @param 判断是否为内部节点 
 * @param target 目标节点
 * @param source 父节点 
 * @returns 返回结果
 */
export function contains(target?: HTMLElement | HTMLDivElement | null, source?: any) {
  if (target && source && typeof target.contains === 'function') {
    return target.contains(source);
  }
  return false;
}

/**
 * 获取字符串长度
 * @param 字符串
 * @returns 返回结果
 */
export function getStringLength(val: string = "") {
  let len = 0;
  for (let i = 0; i < val.length; i++) {
    let c = val.charCodeAt(i);
    //单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      len++;
    } else {
      len += 3;
    }
  }
  return len;
}

/**
 * 判断字符串长度
 * @param 字符串
 * @returns 返回结果
 */
export function getStringByLength(val: string = "", length: number) {
  let len = 0, str = "";
  for (let i = 0; i < val.length; i++) {
    let c = val.charCodeAt(i);
    let s = val.charAt(i);
    //单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      len++;
    } else {
      len += 3;
    }
    if (len > length) return str;
    str += s;
  }
  return str;
};

/**
 * 判断复杂数据类型
 * @param value 需要判断类型的值
 * @returns 数据类型
 */
export function getTag(value: any): string {
  const toString = Object.prototype.toString;
  return toString.call(value);
}

/**
 * 判断数据是否为空
 * @param value 被判断的数据
 * @returns 是否为空
 */
export function isEmpty(value: any): boolean {
  if (value == null) return true;
  const tag = getTag(value);
  if (Array.isArray(value) || typeof value === "string"
    || tag === "[object Arguments]" || (value.length && typeof value.length === "number")
  ) return !value.length; // 判断字符串或类数组

  if (tag === '[object Map]' || tag === '[object Set]') { // 判断Map或Set实例
    return !value.size;
  }

  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      return false;
    }
  }
  return false;
}

export function isNumber(v: any) {
  return isNaN(Number(v)) ? false : true;
}

/**
 * 判断数据是否为空，兼容{}等情况
 * @param value 被判断的数据
 * @returns 是否为空
 */
export function isValueEmpty(value: any): boolean {
  if (value == null) return true;
  const tag = getTag(value);
  if (Array.isArray(value) || typeof value === "string"
    || tag === "[object Arguments]" || (value.length && typeof value.length === "number")
  ) return !value.length; // 判断字符串或类数组

  if (tag === '[object Map]' || tag === '[object Set]') { // 判断Map或Set实例
    return !value.size;
  }

  // 对象
  if (Object.prototype.toString.call(value) === '[object Object]') {
    return Object.keys(value).length === 0;
  }

  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      return false;
    }
  }
  return false;
}



export function getDataAttr(props: { [key: string]: any }) {
  return Object.keys(props).reduce<{ [key: string]: string }>((prev, key) => {
    if (
      key.substr(0, 5) === 'aria-' ||
      key.substr(0, 5) === 'data-' ||
      key === 'role'
    ) {
      prev[key] = props[key];
    }
    return prev;
  }, {});
};

export function moveItem(arr: Array<any>, index: number, tindex: number) {
  if (index > tindex) {
    arr.splice(tindex, 0, arr[index]);
    arr.splice(index + 1, 1)
  } else {
    arr.splice(tindex + 1, 0, arr[index]);
    arr.splice(index, 1)
  }
}

// 深拷贝
export function deepClone(target: any) {
  /**
   * 遍历数据处理函数
   * @array 要处理的数据
   * @callback 回调函数，接收两个参数 value 每一项的值 index 每一项的下标或者key。
   */
  function handleWhile(array: any, callback: any) {
    const length = array.length;
    let index = -1;
    while (++index < length) {
      callback(array[index], index)
    }
  }
  function clone(target: any, map: any) {
    if (target instanceof Date) {
      return target;
    } else if (target !== null && typeof target === 'object') {
      let result = Object.prototype.toString.call(target) === "[object Array]" ? [] : {};
      // 解决循环引用
      if (map.has(target)) {
        return map.get(target);
      }
      map.set(target, result);

      const keys = Object.prototype.toString.call(target) === "[object Array]" ? undefined : Object.keys(target);

      // @ts-ignore
      function callback(value: any, key: any) {
        if (keys) {
          // 如果keys存在则说明value是一个对象的key，不存在则说明key就是数组的下标。
          key = value;
        }
        // @ts-ignore
        result[key] = clone(target[key], map)
      }
      handleWhile(keys || target, callback)
      return result;
    } else {
      return target;
    }
  }
  let map = new WeakMap();
  const result = clone(target, map);
  // @ts-ignore
  map = null;
  return result
}

// 用于某些只能在手机app中调用到的SDK方法使用,例如weappSDK.customSDK.ready
export const canUseSDK = ua.browser === 'wxwork' || ua.browser === 'MicroMessenger' || ua.browser === 'DingTalk' || ua.browser === 'ZheZhengTing' || ua.browser === 'Weapp' || ua.browser === 'Welink' || ua.browser === 'YunZhiJia' || ua.browser === 'Lark';

// 判断是否是手机端
export const isMobile = ua.device === 'Mobile';
// 判断是否是三星
export const isSamsung = window.navigator.userAgent.search(/sm-/i) > -1;
// 判断是否是安卓
export const isAndroid = ua?.os === 'Android';

// 记录window.document.body.style.overflow
export const setWeappUiOverflowCss = () => {
  if (!window.hasRecordWeappUiOverflowCss) {
    window.hasRecordWeappUiOverflowCss = true;
    window.weappUiOverflowCss = window.document.body.style.overflow;
  }
}

// 重置body.overflow
export const resetBodyOverflowCss = () => {
  window.document.body.style.overflow = window.weappUiOverflowCss;
}

export const hideBodyOverflow = () => {
  window.document.body.style.overflow = 'hidden';
}

/**
 * 获取当前系统使用的语言 https://weapp.mulinquan.cn/api/bs/i18n/languagemanage/getSys
 * 
 * 语言说明：
 * zh_CN: 简体中文
 * en_US: 英语
 * zh_TW: 繁体中文 
 * ko_KR: 韩语
 * ja_JP: 日语
 * de_DE: 德语
 * fr_FR: 法语
 * pt_PT: 葡萄牙语
 * ar_AE: 阿拉伯语
 * ru_RU: 俄语
 * th_TH: 泰语
 * es_ES: 西班牙语
 */
export const getLang = () => ((window.TEAMS?.locale?.lang) || document.cookie.match(/langType=(.*?)(;|$)/)?.[1] || window.navigator.language || 'zh-CN').replace(/-/g, () => '_');


export const sortArray = (ary: number[], type: 'up' | 'down' = 'up') => ary.sort((a, b) => type === 'up' ? (a - b) : (b - a))

export const isString = (value: any): boolean => typeof value === "string";

export const transToHTML = (val: string) => {
  let result = val
    .replace(/gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/\n/g, "<br/>")
    .replace(/\r/g, "<br/>")
    .replace(/&nbsp;/g, " ")
    .replace(/&apos;/g, "'")
    .replace(/&quot;/g, '"');
  return result;
};

// 取边界值: 不小于最小,不大于最大
export const bound = (position: number, min?: number, max?: number) => {
  let ret = position
  if (min) {
    ret = Math.max(position, min)
  }
  if (max) {
    ret = Math.min(ret, max)
  }
  return ret;
}

/**
 * 自定义事件监听 
 */
export const dispatchEvent = (eventType: string, detail: AnyObj, disabledEventEmitter?: boolean) => {
  const dispatch = (type: string) => {
    let event = null;
    if (ua.browser === 'IE') {
      event = document.createEvent('CustomEvent');
      event.initCustomEvent(type, false, true, detail);
    } else {
      event = new CustomEvent(type, { detail: detail });
    }
    event && document.dispatchEvent(event);
  }
  if (disabledEventEmitter) {
    dispatch(eventType);
    return;
  }
  eventEmitter.emit(uiAppName, eventType, { detail });
}

export function toFixed(num: number, digit: number) {
  let floatNum = parseFloat(String(num));
  return (Math.round((floatNum + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit)).toFixed(digit);
}

// 数据分组
export const groupData = (data: any[], len: number) => {
  const group = [];
  for (let i = 0; i < data.length; i += len) {
    group.push(data.slice(i, i + len));
  }
  return group;
}

// 判断是否是中文(包括是否是繁体)
export const isCnWord = () => {
  let locale = window.TEAMS?.locale?.lang || document.cookie.match(/langType=(.*?)(;|$)/)?.[1] || window.navigator.language;
  locale = locale?.toLocaleLowerCase();
  return locale === 'zh_cn' || locale === 'zh_tw' || locale === 'zh-cn' || locale === 'zh-tw';
}



let canVoiceToTextBtn: boolean | null = null;
/** 判断当前环境是否支持语音转文字 */
export const checkVoiceToTextBtn: () => Promise<boolean> = () => {
  return new Promise((resolve, reject) => {
    if (canVoiceToTextBtn === null) {
      weappSDK.checkApi("continueSpeech")
        .then(res => {
          canVoiceToTextBtn = true;
          resolve(true);
        })
        .catch(err => {
          canVoiceToTextBtn = false;
          resolve(false);
        })
    } else {
      resolve(!!canVoiceToTextBtn);
    }
  })
}

/** 过滤出中文、英文、数字 */
export const filterWords = (text: string) => {
  const reg = /[\u4e00-\u9fa5a-zA-Z0-9]/g;
  const newText = text.match(reg)?.join('') || '';
  return newText;
}

export function hasClass(node: HTMLElement, className: string) {
  if (node.classList) {
    return node.classList.contains(className);
  }
  const originClass = node.className;
  return ` ${originClass} `.indexOf(` ${className} `) > -1;
}

export function addClass(node: HTMLElement, className: string) {
  if (node.classList) {
    node.classList.add(className);
  } else {
    if (!hasClass(node, className)) {
      node.className = `${node.className} ${className}`;
    }
  }
}

export function removeClass(node: HTMLElement, className: string) {
  if (node.classList) {
    node.classList.remove(className);
  } else {
    if (hasClass(node, className)) {
      const originClass = node.className;
      node.className = ` ${originClass} `.replace(` ${className} `, '');
    }
  }
}

export function needRTL(): boolean {
  // return true;
  if (typeof window.rewriteLangRTL === 'function') return window.rewriteLangRTL()
  return /^ar/.test(getLang());
}

export function getChromeVersion() {
  const userAgent = navigator.userAgent;
  const match = userAgent.match(/Chrome\/(\d+)\./);
  if (match) {
    const version = parseInt(match?.[1]);
    return version;
  }
  return 100;
}

/**
 * 通过dom检索到的nodeList，转换为数组
*/
export function convertToArray(nodes: any[]) {
  let array = [];
  try {
    array = Array.prototype.slice.call(nodes, 0);
  } catch (ex) {
    // 兼容IE
    const len = nodes.length;
    for (let i = 0; i < len; i++) {
      array.push(nodes[i]);
    }
  }
  return array;
}

/**
 * 将数组array分成长度为subGroupLength的小数组并返回新数组
 */
export function groupArray(array: any[], subGroupLength: number) {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, index += subGroupLength))
  }
  return newArray;
}

//信创环境
export function isXinchuang() {
  return ua.browser === '360SE' || ua.browser === '360EE' || ua.browser === 'Qaxbrowser'
}

let style: any = null;
export function handleInputStyle(){
  if (ua.browser === "Chrome") {
    try {
      // 在谷歌版本127版本的 input未指定width的时候会有默认宽度。导致溢出
      const version = Number(ua.version.split(".")?.[0]) || 0;
      if (version >= 127 && !style) {
        style = document.createElement("style");
        style.innerHTML = `
          input {
            min-width: 0 ;
          }
        `
        document.head.appendChild(style);  
      }
    } catch (error) {
      console.error(error) 
    }
  }
}

handleInputStyle();