import Loadable from '../../react-loadable';
import { MListViewType } from './types.m'

const MListView = Loadable({ name: 'MListView', loader: () => import(
    /* webpackChunkName: "ui_m_common" */
    './components/listView.m') }) as MListViewType;

// export type { IListViewProps, ListViewData, OperatesType } from './types'

export { default as MListViewStore } from './store/Store.m';

export default MListView;
