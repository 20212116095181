import { toJS } from "mobx";
import { utils, FormItemFieldType as ItemType, FormSingleItemProps as SingleItemProps, FormSwitchProps, SecretSelect } from '../../lib';

const { isEmpty, isValueEmpty, isObject } = utils;

export const getInnerRequired = (item: SingleItemProps): boolean => {
  if (!item) return false;
  return item.required || item.rules?.indexOf('required') >= 0 || item.otherParams?.required || item.otherParams?.rules?.indexOf('required') >= 0;
}

export const judgeEmpty = (itemType?: ItemType, propValue?: any, itemProps?: FormSwitchProps) => {
  const value = toJS(propValue);
  if (itemType === 'PLUGIN' && itemProps?.pluginParams?.packageName === '@weapp/ui'
    && (itemProps.pluginParams.compName === "SecretSelect" || itemProps.pluginParams.compName === 'MSecretSelect')) {

    if (!value || isValueEmpty(value)) {
      return true;
    } else {
      const secretLevel = value.secretLevel;
      if (!secretLevel) {
        return true;
      } else {
        const secretLevelOption = SecretSelect.getSecretLevelOption(secretLevel, false, {
          empId: itemProps?.pluginParams?.requestHeaderParams?.secondEmpId
        });
        if (secretLevelOption.secretLevelValidityShowType === 'required' && !value.secretLevelValidity) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  if (itemType === 'SCOPE') return !value || (value && value.max === '' && value.min === '');
  if (itemType === 'DATEPICKER' || itemType === 'DATETIMEPICKER') {
    //日期多选为空时： value.selectedKey === '' && value.selectedName === ''
    //日期时间选择为空时：value.selectedKey === undefined && value.selectedName === undefined
    return isEmpty(value)
      || !value
      || (value && isObject(value) && isEmpty(value.selectedKey) && isEmpty(value.selectedName) && isEmpty(value.value))
  }
  return isEmpty(value);
}

export const getComponentFromModule = (module: any, compName: string) => {
  let Comp;
  const compNames = typeof compName === 'string' && (compName as string).split('.');
  if (compNames && compNames.length > 1) {
    Comp = compNames.reduce((C, cName) => C?.[cName] ? C[cName] : undefined, module);
  } else {
    Comp = module[compName];
  }
  return Comp;
}

/**
 * 从系统变量中获取指定参数
 * @param key string
 * @returns any | undefined
 */
 export function getParamFromSystem(key: string) {
  if (window?.TEAMS?.globalConfig && (key in window.TEAMS.globalConfig)) {
    return window.TEAMS.globalConfig[key]
  }
  return undefined;
}