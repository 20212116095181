import { Component, ComponentType, FC, Fragment, PureComponent, useCallback } from "react";
import { Route, withRouter } from "react-router-dom";
// @ts-ignore
import { middleware, moduleRouter } from '@weapp/utils';
import { AnyObj } from "../../types/common";
import CorsComponent from "./CorsComponent";
import { CorsSwitchProps, CorsSwitchPathType } from './types';
import { uiAppName } from '../../constants/index';
import { formatParentPath } from '../../utils/index';

const CorsRoute: FC<any> = ({ weId, rootPath, pathOption, renderCorsComponent }) => {
  let routePath;
  if (rootPath === "/") {
    routePath = `${pathOption.routePath}/:${moduleRouter.idKey}`;
  } else {
    routePath = `${rootPath}${pathOption.routePath}/:${moduleRouter.idKey}`;
  }

  const renderComponent = useCallback(() => {
    return renderCorsComponent?.(pathOption);
  }, [pathOption, renderCorsComponent]);

  return (
    <Route
      weId={`${weId || ''}_j4rrqz`}
      key={routePath}
      path={routePath}
      render={renderComponent}
    />
  )
}

const CorsRouteComponent: ComponentType<any> = withRouter((props: any) => {
  // 从路由中获取id参数
  const idFromParent = moduleRouter?.getId?.(props);
  // 从路由中获取params参数
  // @ts-ignore
  const paramsFromParent = moduleRouter?.getParams?.(props);
  return (
    <CorsComponent<CorsSwitchPathType>
      {...props}
      weId={`${props.weId || ''}_e400s2`}
      idFromParent={idFromParent || props.idFromParent}
      paramsFromParent={paramsFromParent || props.paramsFromParent}
    />
  );
});

// @ts-ignore
@middleware(uiAppName, 'CorsSwitch')
// @ts-ignore
@withRouter
export default class CorsSwitch extends PureComponent<CorsSwitchProps> {
  static displayName = "CorsSwitch";

  goBack: boolean = false;

  static checkPath = (path: string, paths: CorsSwitchPathType[]): boolean => {
    let flag = false;
    if (!(path && paths && paths.length)) return flag;
    for(let i = 0; i < paths.length; i ++) {
      const pathOption = paths[i];
      if (pathOption.routePath === path) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  shouldComponentUpdate(nextProps: CorsSwitchProps, nextState: any) {
    const { location, history, rootPath } = nextProps;
    const pathname = location?.pathname;
    const nextLocationKey = location?.key;
    const locationKey = this.props.location?.key;
    if (this.goBack) {
      // reg = new RegExp(`\\\/[^\\\/]+(\\\/[^\\\/]+)$`);
      const reg = /\/[^/]+(\/[^/]+)$/;
      if (
          pathname && rootPath
          && nextLocationKey !== locationKey
          && pathname !== rootPath
          && pathname.replace(reg, '') === rootPath
      ) {
        history?.go(-1);
        return false;
      } else {
        this.goBack = false;
      }
    }
    return true;
  }

  handleClose = () => {
    this.goBack = true;
    this.props.history?.go(-1);
  }

  renderCorsComponent = (pathOption: CorsSwitchPathType) => {
    const { paths, rootPath } = this.props;
    return (
      <CorsRouteComponent
        weId={`${this.props.weId || ''}_ke8f8d`}
        {...pathOption}
        paths={paths}
        rootPath={rootPath}
        onClose={this.handleClose}
        visible={this.goBack ? false : true}
      />
    );
  }

  render() {
    let { rootPath, paths } = this.props;
    return (
      <>
        {/* <div>
          <h4>加载的根路由：{rootPath}</h4>
          <h5>加载的路由列表：</h5>
          <ul>
            {paths.map((path, index) => (<li key={index}>{rootPath}{path.routePath}</li>))}
          </ul>
        </div> */}
        {paths.map((pathOption) => (
            <CorsRoute
              weId={`${this.props.weId || ''}_zbw97o@${pathOption.path}`}
              key={pathOption}
              rootPath={rootPath}
              pathOption={pathOption}
              renderCorsComponent={this.renderCorsComponent}
            />
          ))}
      </>
    );
  }
}
