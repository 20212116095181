import React, { PureComponent } from 'react';
import { classnames } from '@weapp/utils';
import { ButtonProps, ClickEvent } from './types';
import { pauseEvent } from '../../utils/index';

const noop = () => null;

/**
 * @title 按钮
 * @direction row
 * @config @weapp/ui-props-design
 */
class Button extends PureComponent<ButtonProps> {

  static defaultProps: ButtonProps = {
    type: 'default',
    size: 'middle',
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
    }
    this.props.onKeyDown?.(event);
  }

  handleClick = (event: ClickEvent) => {
    const {
      disabled, onClick, onBeforeClick, onAfterClick,
    } = this.props;
    if (disabled) {
      pauseEvent(event);
      return;
    }
    const handleClickInner = () => {
      onClick?.(event);
      onAfterClick?.();
    }
    if (typeof onBeforeClick === 'function') {
      onBeforeClick().then(() => {
        handleClickInner();
      }, noop);
    } else {
      handleClickInner();
    }
  }

  render() {
    const {
      className, prefixCls, href, children, disabled = false,
      type, size, inline, radius,
      onClick, onBeforeClick, onAfterClick, customRender,
      ...restProps
    } = this.props;

    const cls = classnames(prefixCls, {
      [`${prefixCls}-${type}`]: type,
      [`${prefixCls}-${size}`]: size,
      [`${prefixCls}-inline`]: inline,
      [`${prefixCls}-radius`]: radius,
      'disabled': type === 'link' && disabled
    }, className);

    let content;
    if (type === 'link') {
      content = (
        <a
          className={cls}
          href={href}
          onClick={this.handleClick}
          {...restProps}
          onKeyDown={this.handleKeyDown}
        >
          {children}
        </a>
      );
    } else {
      content = (
        <button
          className={cls}
          disabled={disabled}
          onClick={this.handleClick}
          {...restProps}
          onKeyDown={this.handleKeyDown}
        >
          {children}
        </button>
      );
    }
    return customRender ? customRender(this, content) : content;
  }
}

export type BrowserType = Pick<Button, Exclude<keyof Button, keyof React.PureComponent>>;

export default Button;
