
import IconMap from '../../../constants/iconMap.json';
import { richIconMap, transIcon_o } from '../constants/icons';
import { isValueEmpty } from '../../../utils';

const colorMap: {
  [key: string]: string
} = {
  'red': '#fa303d',
  'blue': '#0067ff',
  'yellow': '#ff8400',
  'indigo': '#218af8',
  'green': '#00a643'
}

export const getModuleColor = (key?: string) => {
  if (key) {
    return colorMap[key]
  }
  return '';
}

export const getIconInfo = (key?: string) => {
  let res: {
    icon_line?: string,
    icon_suf?: string,
    color?: string,
  } = {};
  // 获取 appIcon 对应的内容（IconSelection、AppIcon）
  const iconInfo = IconMap.find(val => val.key.toString() === key?.toString());
  if (!isValueEmpty(iconInfo)) {
    res = {
      icon_line: iconInfo?.icon_line,
      icon_suf: iconInfo?.icon_suf,
      color: getModuleColor(iconInfo?.moduleColor)
    }
  } else {
    // 获取 RichIconSelection 对应的内容
    const val = richIconMap.find(val => val === key);
    if (!isValueEmpty(val)) {
      const icon_suf = val;
      const icon_line = transIcon_o(val!);
      res = {
        icon_line,
        icon_suf 
      }
    }
  }
  return res;
}

export type GetIconInfoType = typeof getIconInfo;
