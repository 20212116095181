import { classnames, getLabel } from "@weapp/utils";
import { cardDetailFormClsPrefix } from '../../constants/index';
import { AnyObj } from "../../types/common";
import { BrowserValueType } from '../browser/index';
import { FormItemProps } from '../form-item/index';

export const delStatusTemplate = (data: any) => {
  return data && data.delete && (<span className={`${cardDetailFormClsPrefix}-template-delTip`}>[{getLabel('14099', '已删除')}]</span>);
}

export const getClassName = (data: any) => {
  return classnames('browser-list-template', {
    [`${cardDetailFormClsPrefix}-template-del`]: data.status === 'finished',
  });
}

export const renderContentTemplate = (data: any, keys: Array<string | Array<string>>) => {
  if (data && keys && keys.length > 0) {
    if (data.richContent) return data.richContent;
    else if (data.primaryName) return data.primaryName;
    else if (data.targetName) return data.targetName;
    let content = '';
    keys.forEach((key, index) => {
      let tempdata = data;
      if (typeof key === 'string') {
        const tempKeys = key.split('__');
        tempKeys.forEach((tempKey) => {
          if (tempdata) {
            tempdata = tempdata[tempKey];
          }
        })
      } else {
        const temp = key?.find((k) => data[k]);
        temp && (tempdata = data[temp]);
      }
      if (tempdata) content += index === 0 ? tempdata || '' : `[${tempdata}]` || '';
    });
    return content;
  }
  return '';
}

export const defaultTemplate = {
  default: [
    {
      key: 'col1',
      configs: [
        {
          key: 'col1_row1',
          render: (data: any) => {
            const className = getClassName(data);
            const value = data ? (data.content || data.name || data.title || data.primaryName || data.targetName) : '';
            return (<>
              <span dangerouslySetInnerHTML={value} className={className} />
              {delStatusTemplate(data)}
            </>)
          }
        },
      ],
    },
  ],
};

export const getDefaultTemplate = (data: any, isContent?: boolean) => {
  if (isContent) {
    const className = getClassName(data);
    return (<>
      <span className={className}>
        {
          data ? (data.content || data.name || data.title || data.primaryName || data.targetName) : ''
        }
      </span>
      {delStatusTemplate(data)}
    </>)
  }
  return defaultTemplate;
}

export const getTemplateContent = (data: any, keys: Array<string | Array<string>>) => {
  const className = getClassName(data);
  return (<>
    <span className={className}>
      <span dangerouslySetInnerHTML={{ __html: renderContentTemplate(data, keys) }} />
    </span>
    {delStatusTemplate(data)}
  </>)
}

export const getTemplate = (keys: Array<string | Array<string>>, data?: BrowserValueType, isContent?: boolean) => {
  if (isContent) {
    return getTemplateContent(data, keys);
  }
  return {
    default: [
      {
        key: 'col1',
        configs: [
          {
            key: 'col1_row1',
            render: (data: any) => getTemplateContent(data, keys),
          },
        ],
      },
    ]
  }
}



export const registerAssociativeTemplate = (type: String, data?: BrowserValueType, isContent?: boolean) => {
  switch (type) {
    case 'document': return getTemplate(['content', 'user', 'time'], data, isContent); // 关联文档
    case 'taskBrowser': return getTemplate(['name', 'manager', 'dueDate'], data, isContent); // 关联任务
    case 'mainlineBrowser': return getTemplate(['name', 'manager', 'endTime'], data, isContent); // 关联项目
    case 'contact': return getTemplate(['name', 'updateTime'], data, isContent); // 关联联系人
    case 'marketactivity': return getTemplate(['name', 'manager', 'updateTime'], data, isContent); // 关联活动
    case 'calendarBrowser': return getTemplate(['name', 'createUser', 'startDate'], data, isContent);  // 关联日程
    case 'clue': return getTemplate(['name', 'manager__name', 'updateTime'], data, isContent); // 关联线索

    case 'saleChance': return getTemplate(['name', 'manager__name', 'stage', 'updateTime'], data, isContent); // 关联商机
    case 'contract': return getTemplate(['name', 'manager', 'totalMoney', 'contractStatus', 'updateTime'], data, isContent); // 关联合同
    case 'orderform': return getTemplate(['name'], data, isContent); // 关联订单
    case 'mailResource': return getTemplate(["name", "sendDate"], data, isContent); // 关联邮件
    case 'wflRequest': return getTemplate([['richContent', 'name'], 'userid'], data, isContent); // 关联流程
    case 'wfcRequest': return getTemplate([['richContent', 'name'], 'userid'], data, isContent); // 关联流程(旧)
    case 'blogBrowser': return getTemplate(['name', 'blogTime'], data, isContent); // 关联日报 
    case 'performanceBrowser': return getTemplate(["name"], data, isContent); // 关联绩效

    case 'production': return getTemplate(['name', 'manager', 'price', 'updateTime'], data, isContent); // 关联产品
    case 'customer': return getTemplate(['name', 'manager', 'statusName', 'updateTime'], data, isContent); // 关联客户
    case 'competitor': return getTemplate(['name', 'manager__name', 'competitionLevelName', 'updateTime'], data, isContent); // 关联对手

    case 'planBrowser': return getTemplate(["name", "postTime"], data, isContent); //关联报告
    case 'price': return getTemplate(['name', "manager", "updateTime"], data, isContent); // 关联价格
    // case '': return getTemplate(); // 关联数据
    case 'frpt_form': return getTemplate(['name', 'creator', 'createTime'], data, isContent); // 关联表单
    case 'common': return getTemplate(['content'], data, isContent); // 公共标签
    case 'my': return getTemplate(['content'], data, isContent); // 我的标签
    case 'folder': return getTemplate(['title', 'username'], data, isContent); // 我的标签
    case 'coworkItemsBrowser': return getTemplate(['name'], data, isContent); // 关联协作
    case 'odocRequest': return getTemplate(['name', 'userid', 'createdatetime'], data, isContent); // 关联公文
    case 'quote': return getTemplate(['name'], data, isContent); // 关联报价
    case 'capital': return getTemplate(['name'], data, isContent); // 关联资金

    case 'workOrderBrowser': return getTemplate(['name', 'creatorName', 'createTime',], data, isContent); // 关联客服
    case 'mt_video': return getTemplate(['name'], data, isContent); //关联视频会议
    default: return getDefaultTemplate(data, isContent);
  }
}

export const registerCustomRenderProjectItem = (type: string, data: BrowserValueType) => {
  return registerAssociativeTemplate(type, data, true);
}


// 场景：浏览框的type固定，即对应的common（标记公共浏览框）不变，item内的key业务会修改
/*
  方案：
  1.表单更新对应关系：组件初始化：公共关联浏览框通过type与items的key做映射关系，用于表单数据更新
  2.保存数据对应关系：保存值的时候按照对应关系：按照标准的在组件内固定对应关系，用于保存数据
  3.组件内需处理上述两种对应关系，以浏览框的type为准，保存当前布局的字段的key和保存数据需存储的targetModule(老数据兼容）
*/
// type: targetModule
interface CommonRelationsRules {
  [key: string]: string | boolean,
}

/* 公共关联浏览框字段用于保存数据的key {browserBean.type: key} 兼容et老数据，且确保业务复写了items的key的情况也不影响公共关联保存的数据 */
export const commonRelationsRules: CommonRelationsRules = {
  'frpt_form': 'biaoge', // 关联表单
  'mainlineBrowser': 'mainline', // 关联项目
  'blogBrowser': 'blog', // 关联日报
  'document': 'document', // 关联文档
  'common': 'common', //公共标签
  'my': 'my', // 我的标签
  'planBrowser': 'workreport', // 关联报告
  'performanceBrowser': 'kpiFlow', // 关联绩效
  'mailResource': 'email', // 关联邮件
  'taskBrowser': 'task', // 关联任务
  'customer': 'customer', // 关联客户
  'clue': 'clue', // 关联线索
  'saleChance': 'saleChance', // 关联商机
  'contract': 'contract', // 关联合同
  'production': 'production', // 关联产品
  'competitor': 'competitor', // 关联
  'contact': 'contact', // 关联联系人
  'marketactivity': 'marketactivity', // 关联活动
  'calendarBrowser': 'calendar', // 关联日程
  'wflRequest': 'workflow', // 关联流程
  'wfcRequest': 'workflow', // 关联流程(旧)
  'frpt_report': 'formdatareport', // 关联数据
  'orderform': 'orderform', // 关联订单
  'price': 'price', // 关联价格
  'goalBrowser': 'goal', // 关联目标
  'folder': 'folder', // 关联文件夹
  'ebuilderMatter': 'ebuilderform', // 关联Ebuilder
  'mt': 'meeting', // 关联会议
  'odocRequest': 'odoc', // 关联公文
  'quote': 'quote', // 关联报价
  'capital': 'capital', // 关联资金
  'coworkItemsBrowser': 'cowork',// 关联协作
  'workOrderBrowser': 'workOrder',//关联客服(关联工单)
  'resource': 'hr', // 关联人员
  'articleBrowser': 'marketArticle',//关联文案
  'mt_video': 'meetingVideo',// 关联视频会议
  'relateApprove': 'fna' // 关联财务
}

export const payModuleRules: AnyObj = { //window.TEAMS.payModules 中存储的数据为付费模块
  ...commonRelationsRules,
  'common': false, // 标签均为tag
  'my': false,
  'performanceBrowser': 'kpi', // 关联绩效
  'goalBrowser': 'saleTarget', // 关联目标
  'frpt_form': 'form', // 关联表单
  'frpt_report': 'form', // 关联数据
  'folder': 'document', // 关联文件夹
  'ebuilderMatter': false, // 关联Ebuilder
  'coworkItemsBrowser': 'cowork',// 关联协作
  'mt': ['room', 'meeting'], // 关联会议特殊处理，存在一个（meeting or room）开启模块，如果都不存在(meeting不存在且room不存在) 不展示会议模块功能
  'odocRequest': 'odoc', // 关联公文
  'quote': 'quote', // 关联报价
  'capital': 'capital', // 关联资金
  'workOrderBrowser': 'customerservice', //关联客服(关联工单)
  'email': 'email', //关联邮件
  'cowork': 'cowork', //关联协作
  'resource': false,
  'articleBrowser': 'scrm',//关联文案
  'mt_video':['room', 'meeting']// 关联视频会议
}

export const disableModules: AnyObj = { // window.TEAMS.disableModules 中存储，后台管理中心->应用管理 禁用的模块
  ...payModuleRules,
  'common': 'tag', // 标签均为tag
  'my': 'tag',
  'resource': 'hr', // 关联人员
  'ebuilderMatter': 'ebuilder', // 关联Ebuilder
  'ebuilderSingleMatter': 'ebuilder',  // 关联Ebuilder(适配多个)
  'articleBrowser': 'scrm',//关联文案
  'relateApprove': 'fna', // 电子费控（关联按钮中使用）
}

export const checkWhiteList = (nowModule: AnyObj) => {
  const whiteList: AnyObj = { workflow: '2', form: '11' }; // 兼容老数据，workflow的moduleId对应2， form对应11
  const id = whiteList[nowModule.moduleStr]; // 特殊配置模块的moduleId
  return !id || (id && id === nowModule.moduleId);
}

/**
 * 按照浏览框的type判断
 * @param type 浏览框的type
 * @returns 
 */
export const checkDisabledModule = (type: string, module?: string, otherParams?: AnyObj) => {
  // 私有化特殊处理：window.TEAMS.deploy === "private", displayModules 不存在模块标记，则隐藏

  module = module || disableModules[type] as string; // 模块类型
  /*
   特殊处理
    1.电子费控：type=wflRequest module = 'fna/workflow' , 模块标识 fna
   */
  if (otherParams?.module === 'fna/workflow' && type === 'wflRequest') {
    module = 'fna';
  }
  if (Array.isArray(module)) {
    let ping = true;
    module.forEach((mo) => ping = ping && window.TEAMS?.displayModules?.indexOf(mo) < 0);
    return ping;
  }
  return window.TEAMS?.displayModules?.indexOf(module) < 0;
  // if (window.TEAMS?.deploy === 'private' && window.TEAMS?.displayModules?.indexOf(module) < 0) return true;
  // return window.TEAMS?.disableModules?.find((nowModule: { moduleStr: string; moduleId: string; }) => {
  //   return nowModule.moduleStr === module && checkWhiteList(nowModule);
  // });
}
/**
 * isDisabledModule 判断后台管理中心->应用管理 模块是否禁用，仅处理公共关联浏览框
 * @param item 字段id配置
 * @returns 
 */
export const isDisabledModule = (item: FormItemProps) => {
  const type = item?.common ? item?.browserBean?.type : '';
  return type ? checkDisabledModule(type) : false;
}

/**
 * 判断模块是否禁用 --- 公共方法
 * displayModules 展示的模块(不区分私有云、公有云) = license购买模块 + 租户付费模块 + 排除禁用模块
 * @param module 模块名
 * @returns 
 */
export const isModuleDisabled = (module: string) => module && window.TEAMS?.displayModules?.indexOf(module) < 0;