
import CorsLink from './CorsLink';
import CorsComponent, { LoadableComponent } from './CorsComponent';
import CorsSwitch from './CorsSwitch';
import CorsRouter from './CorsRouter';

export type { CorsLinkProps, CorsComponentProps, CorsDialogProps, CorsSwitchProps, CorsSwitchPathType } from './types';

export { CorsComponent, LoadableComponent, CorsSwitch, CorsRouter };

export default CorsLink;
