import { LoadingType, Loadable, constantsM, constants } from '../../lib';
import { MSelectType, MSelectProps } from './types';

const { USESKELETON } = constants;
const { mSelectClsPrefix } = constantsM;
const MSelectSkeleton: LoadingType<MSelectProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MSelect = Loadable({ name: 'MSelect', loading: MSelectSkeleton, loader: () => import(
  /* webpackChunkName: 'ui_m_common_form' */
  './Select.m') }) as MSelectType;

MSelect.defaultProps = {
  prefixCls: mSelectClsPrefix,
  dropdownType: 'popup',
  showArrow: true,
  isHorizontal: false,
  isHoldRight: false,
  horizontalDirection: 'anticlockwise'
};

export type { MSelectProps } from './types';

export default MSelect;
