import { FC, RefAttributes } from "react";
import { BaseProps } from '../../../types/common';
import { Icon, IconNames } from '../../../lib';
import { classnames } from "@weapp/utils";
interface SuffixProps extends BaseProps, RefAttributes<any> {
  isShow: boolean;
  disabled?: boolean;
  SuffixCls?: string;
  iconName: IconNames;
  size?: 'xxs' | 'xs' | 's' | 'sm' | 'md' | 'lg';
  onClick?: (e: React.MouseEvent) => void;
  allowClear?: boolean;
}

const DatePickerSuffix: FC<SuffixProps & RefAttributes<any>> = ({ weId, prefixCls, isShow, disabled, iconName, size = 's', allowClear, onClick }) => {
  function handleClick(e: React.MouseEvent) { onClick?.(e) }

  return (
    <>
      {allowClear && <Icon weId={`${weId || ''}_5mpqzh`}
        className={classnames(`${prefixCls}-clear`, {
          [`${prefixCls}-clear-show`]: isShow && !disabled,
        })}
        size={size}
        name={'Icon-cancel'}
        onClick={handleClick} />}
      <Icon weId={`${weId || ''}_ecuuec`}
        className={`${prefixCls}-icon`}
        size={size}
        name={iconName}
      />
    </>
  )
}

export default DatePickerSuffix