import { mBtnClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { MButtonType } from './types';
import _Button from './Button';

const MButton = Loadable({
  name: 'MButton',
  component: _Button,
  // loader: () => import(
  // /* webpackChunkName: "m_button" */
  // './Button'),
}) as MButtonType;

MButton.defaultProps = {
  prefixCls: mBtnClsPrefix
};


export type { MButtonProps, MBtnType } from './types';

export default MButton;
