import * as React from 'react';
import { classnames, getLabel } from '@weapp/utils';
import AnchorLink from './AnchorLink';
import { AnchorProps, AnchorState } from './types'
import { ConfigContext, ConfigConsumerProps } from './utils/context';
import { scrollTo, getScroll, addEventListener } from './utils/index';
import raf from './utils/raf'
import AnchorContext from './context';
import { anchorListPrefix } from '../../constants/index';
import Input from '../input/index'
import Icon from '../icon/index';
import { Draggable } from './index';
import { addClass, removeClass } from '../../utils/index'

// const Draggable = Loadable({ name: 'Draggable', loader: () => import(
//     /* webpackChunkName: "react_draggable" */
//     'react-draggable') })

// let Draggable: any;
// import(
//   /* webpackChunkName: "react_draggable" */
//   'react-draggable'
//   ).then(module => {
//   Draggable = module.default;
// })

export type AnchorContainer = HTMLElement | Window;

function getDefaultContainer() {
  return window;
}

function getOffsetTop(element: HTMLElement, container: AnchorContainer): number {
  if (!element.getClientRects().length) {
    return 0;
  }

  const rect = element.getBoundingClientRect();

  if (rect.width || rect.height) {
    if (container === window) {
      container = element.ownerDocument!.documentElement!;
      return rect.top - container.clientTop;
    }
    return rect.top - (container as HTMLElement).getBoundingClientRect().top;
  }

  return rect.top;
}

const sharpMatcherRegx = /#(\S+)$/;
const prefixCls = anchorListPrefix

type Section = {
  link: string;
  top: number;
};

export default class Anchor extends React.Component<AnchorProps, AnchorState, ConfigConsumerProps> {
  static displayName = 'AnchorList';
  static Link = AnchorLink;

  static defaultProps = {
    affix: true,
    highlight: true,
    showInkInFixed: false,
    draggable: true,
    needSearch: true,
    defaultCollapsed: false
  };

  static contextType = ConfigContext;

  constructor(props: AnchorProps) {
    super(props);
    this.state = {
      activeLink: null,
      searchValue: '',
      collapsed: props.defaultCollapsed || false,
      activeDrags: 0,
      dragging: false,
      isFirst: true,
    }
  }

  content: ConfigConsumerProps | undefined;

  private wrapperRef = React.createRef<HTMLDivElement>();

  private inkNode: HTMLSpanElement | undefined;

  // scroll scope's container
  private scrollContainer: HTMLElement | Window | undefined;

  private links: string[] = [];

  private scrollEvent: any;

  private animating: boolean | undefined;

  private collapseRef = React.createRef<HTMLDivElement>();

  // Context
  registerLink = (link: string) => {
    if (!this.links.includes(link)) {
      this.links.push(link);
    }
  };

  unregisterLink = (link: string) => {
    const index = this.links.indexOf(link);
    if (index !== -1) {
      this.links.splice(index, 1);
    }
  };

  getContainer = () => {
    const { getTargetContainer } = this.context;
    const { getContainer } = this.props;

    const getFunc = getContainer || getTargetContainer || getDefaultContainer;

    return getFunc();
  };

  componentDidMount() {
    this.scrollContainer = this.getContainer();
    // @ts-ignore
    this.scrollEvent = addEventListener(this.scrollContainer, 'scroll', this.handleScroll);
    this.handleScroll();
  }

  componentDidUpdate() {
    if (this.scrollEvent) {
      const currentContainer = this.getContainer();
      if (this.scrollContainer !== currentContainer) {
        this.scrollContainer = currentContainer;
        this.scrollEvent.remove();
        this.scrollEvent = addEventListener(this.scrollContainer, 'scroll', this.handleScroll);
        this.handleScroll();
      }
      this.updateInk();
    }
  }

  componentWillUnmount() {
    if (this.scrollEvent) {
      this.scrollEvent.remove();
    }
  }

  getCurrentAnchor(offsetTop = 0, bounds = 5): string {
    const { getCurrentAnchor } = this.props;

    if (typeof getCurrentAnchor === 'function') {
      return getCurrentAnchor();
    }

    const linkSections: Array<Section> = [];
    const container = this.getContainer();
    this.links.forEach(link => {
      const sharpLinkMatch = sharpMatcherRegx.exec(link.toString());
      if (!sharpLinkMatch) {
        return;
      }
      const target = document.getElementById(sharpLinkMatch[1]);
      if (target) {
        const top = getOffsetTop(target, container);
        if (top < offsetTop + bounds) {
          linkSections.push({ link, top });
        }
      }
    });

    if (linkSections.length) {
      const maxSection = linkSections.reduce((prev, curr) => (curr.top > prev.top ? curr : prev));
      return maxSection.link;
    }
    return '';
  }

  handleScrollTo = (link: string) => {
    const { offsetTop, highlight } = this.props;
    const { activeLink } = this.state

    this.setCurrentActiveLink(link);
    const container = this.getContainer();
    const targetOffset = container?.getBoundingClientRect().height / 2
    const scrollTop = getScroll(container, true);
    const sharpLinkMatch = sharpMatcherRegx.exec(link);
    if (!sharpLinkMatch) {
      return;
    }
    const targetElement = document.getElementById(sharpLinkMatch[1]);
    if (!targetElement) {
      return;
    }

    const eleOffsetTop = getOffsetTop(targetElement, container);
    let y = scrollTop + eleOffsetTop;
    if (highlight) {
      const highlightBorderWidth = 2.5 * 2
      const linkIndex = this.links.findIndex(i => i == link)
      const activeLinkIndex = this.links.findIndex(i => i == activeLink)

      if (linkIndex > activeLinkIndex) {
        y = y - highlightBorderWidth
      }
    }
    // y -= targetOffset !== undefined ? targetOffset : offsetTop || 0;
    this.animating = true;

    scrollTo(y, {
      callback: () => {
        // 这里暂时使用定时器延迟 确保在下一帧之后再更新animating状态
        setTimeout(() => {
          this.animating = false
        }, 60)
      },
      getContainer: this.getContainer,
    });
  };

  saveInkNode = (node: HTMLSpanElement) => {
    this.inkNode = node;
  };

  setCurrentActiveLink = (link: string) => {
    const { activeLink } = this.state;
    const { onChange, children, highlight } = this.props;
    if (activeLink !== link) {
      this.setState({ activeLink: link }, () => {
        if (!highlight) return false

        const links: string[] = Array.isArray(children) ? children.map((i: any) => {
          if (i.key) return `#${i.key}`
          return i?.props?.href || ''
        }) : ['']

        links.forEach(linkItem => {
          if (!linkItem) return;
          const node: HTMLElement = document.querySelector(linkItem) as HTMLElement
          if (!node) return

          if (link === linkItem) {
            raf(() => addClass(node, `${prefixCls}-content-link__active`))
          } else {
            raf(() => removeClass(node, `${prefixCls}-content-link__active`))
          }
        })
      });
      if (onChange) {
        onChange(link);
      }
    }
  };

  handleScroll = () => {
    const { isFirst } = this.state
    if (!isFirst) {
      if (this.animating) {
        return;
      }
      const { offsetTop, bounds } = this.props;
      const targetOffset = this.scrollContainer?.getBoundingClientRect().height / 2.5
      const currentActiveLink = this.getCurrentAnchor(
        targetOffset !== undefined ? targetOffset : offsetTop || 0,
        bounds,
      );
      this.setCurrentActiveLink(currentActiveLink);
    } else {
      this.setState({isFirst: false})
      // this.updateInk()
    }
  };

  updateInk = () => {
    const { wrapperRef } = this;
    const anchorNode = wrapperRef.current;
    const linkNode = anchorNode?.getElementsByClassName(`${prefixCls}-link-title-active`)[0];
    if (this.inkNode && !linkNode && this.links.length > 0) {
      const link = this.links[0]
      this.handleScrollTo(link)
    }
    if (
      linkNode &&
      this.inkNode &&
      !(this.inkNode.style.top === `${(linkNode as any).offsetTop - 4.5}px` &&
        this.inkNode.style.height === `${linkNode.clientHeight + 9}px`
      ) &&
      linkNode.clientHeight &&
      (linkNode as any).offsetTop > 0
    ) {
      // this.inkNode.style.top = `${(linkNode as any).offsetTop + linkNode.clientHeight / 2 - 4.5}px`;
      this.inkNode.style.top = `${(linkNode as any).offsetTop - 4.5}px`
      this.inkNode.style.height = `${linkNode.clientHeight + 9}px`;
    }
  };

  onSearchChange = (v: string | number) => {
    this.setState({ searchValue: v })
  }

  handleCollapse = () => {
    !this.state.dragging && this.setState({ collapsed: !this.state.collapsed })
  }

  renderIconBtn = () => {
    const {
      affix,
      showInkInFixed,
    } = this.props;
    const anchorClass = classnames(prefixCls, {
      fixed: !affix && !showInkInFixed,
    });

    return (
      <div onClick={this.handleCollapse}
        className={`${anchorClass}-collapsed-btn`}
        ref={this.collapseRef}
      >
        <Icon weId={`${this.props.weId || ''}_w8lsu3`}
          name='Icon-Anchor-point'
          size='lg'
        />
      </div>
    )
  }

  onDrag = () => {
    !this.state.dragging && this.setState({ dragging: true })
  }

  onStop = () => {
    if (this.state.dragging) {
      // 300ms 制造延迟 处理 drag and click
      setTimeout(() => {
        this.setState({ dragging: false })
      }, 300)
    }
  }

  render = () => {
    const { direction } = this.context;

    const {
      className = '',
      style,
      offsetTop,
      affix,
      showInkInFixed,
      children,
      draggableParent,
      draggable,
      needSearch
    } = this.props;
    const { activeLink, searchValue, collapsed } = this.state;

    const inkClass = classnames(`${prefixCls}-ink-ball`, {
      visible: activeLink,
    });

    const wrapperClass = classnames(
      `${prefixCls}-wrapper`,
      {
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-collapsed`]: collapsed,
      },
      className,
    );

    const anchorClass = classnames(prefixCls, {
      fixed: !affix && !showInkInFixed,
    });

    let wrapperStyle = {
      maxHeight: offsetTop ? `calc(100vh - ${offsetTop}px)` : '100vh',
      ...style,
    };

    const searchInputClass = classnames(
      `${anchorClass}-header-search`,
      {
        [`${anchorClass}-header-search-hidden`]: !needSearch,
      },
    );

    let anchorContent = (
      <div ref={this.wrapperRef}
        className={wrapperClass}
        style={wrapperStyle}
      >
        {
          collapsed ? this.renderIconBtn() : (
            <>
              <div className={`${anchorClass}-header`}>
                <Input
                  weId={`${this.props.weId || ''}_vhdcd5`}
                  value={searchValue}
                  className={searchInputClass}
                  suffix={<Icon weId={`${this.props.weId || ''}_528hvl`} name="Icon-search" />}
                  placeholder={getLabel('63385', '搜索关键词')}
                  onChange={this.onSearchChange}
                  allowClear
                />
                <div className={`${anchorClass}-header-btn`} onClick={this.handleCollapse}>
                  <Icon weId={`${this.props.weId || ''}_hddud7`} name='Icon-Right-arrow03' />
                </div>
              </div>
              <div className={classnames(
                anchorClass,
                {
                  [`${anchorClass}-no-search`]: !needSearch,
                },
              )}>
                {
                  searchValue ? null : (
                    <div className={`${prefixCls}-ink`}>
                      <span className={inkClass} ref={this.saveInkNode} />
                    </div>
                  )
                }
                {children}
              </div>
            </>
          )
        }
      </div>
    );

    let draggableContent = (
      <Draggable
        weId={`${this.props.weId || ''}_nsvhch`}
        bounds={draggableParent ? draggableParent : 'parent'}
        onDrag={this.onDrag}
        onStop={this.onStop}
      >
        {anchorContent}
      </Draggable>
    )

    return (
      <AnchorContext.Provider
        weId={`${this.props.weId || ''}_coqp5b`}
        value={{
          registerLink: this.registerLink,
          unregisterLink: this.unregisterLink,
          activeLink: this.state.activeLink,
          scrollTo: this.handleScrollTo,
          onClick: this.props.onClick,
          searchValue
        }}
      >
        {draggable ? draggableContent : anchorContent}
      </AnchorContext.Provider>
    );
  };
}

