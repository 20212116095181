import Loadable from '../../react-loadable';
import { mMapClsPrefix } from '../../constants/index.m';
import { MMapType } from './types';

const MMap = Loadable({
  name: 'MMap', loader: () => import(
    /* webpackChunkName: "ui_m_common_small" */
    './components/MMap')
}) as MMapType;

MMap.defaultProps = {
  prefixCls: mMapClsPrefix,
};

export type { MMapProps, mapType as MMapType } from './types';

export default MMap;

