import * as React from 'react';
import {classnames} from '@weapp/utils';
import RowContext from './RowContext';
import { RowProps, Gutter } from './types';
import { layoutClsPrefix } from '../../constants/index';
import Block from '../block/index';

class Row extends React.PureComponent<RowProps> {
  static displayName = 'Row';
  getGutters = (gutter: Gutter | [Gutter, Gutter]) => {
    const results: [number, number] = [0, 0];
    const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, 0];
    normalizedGutter.forEach((g, index) => {
      results[index] = g || 0;
    });
    return results;
  }
  render() {
    const {
      justify,
      align,
      className,
      style,
      children,
      gutter = 0,
      wrap,
      customRender,
      ...others
    } = this.props;
  
    const prefixCls = `${layoutClsPrefix}-row`;
  
    const gutters = this.getGutters(gutter);
  
    const classes = classnames(
      prefixCls,
      {
        [`${prefixCls}-no-wrap`]: wrap === false,
        [`${prefixCls}-${justify}`]: justify,
        [`${prefixCls}-${align}`]: align,
      },
      className,
    );
    const rowStyle = {
      ...(gutters[0]! > 0
        ? {
            marginLeft: gutters[0]! / -2,
            marginRight: gutters[0]! / -2,
          }
        : {}),
      ...(gutters[1]! > 0
        ? {
            marginTop: gutters[1]! / -2,
            marginBottom: gutters[1]! / 2,
          }
        : {}),
      ...style,
    };
  
    const content = (
      <RowContext.Provider weId={`${this.props.weId || ''}_s99dib`} value={{ gutter: gutters, wrap }}>
        <Block weId={`${this.props.weId || ''}_oze1fz`} {...others} className={classes} style={rowStyle}>
          {children}
        </Block>
      </RowContext.Provider>
    );
    return customRender ? customRender(this, content) : content;
  }
}

export default Row;
