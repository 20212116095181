
import Watermark from "./WatermarkCom";
import watermarkSDK from "./WatermarkSDK";
import WatermarkSetting, { systemWatermarkSetting } from './WatermarkSetting';

export {
  Watermark,
  watermarkSDK,
  WatermarkSetting,
  systemWatermarkSetting,
};

export default Watermark;
