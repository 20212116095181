import { ComponentType } from 'react';
import Loadable from '../../react-loadable';
import { SliderProps } from './types';

const Slider = Loadable({name: "Slider", loader: () => import(
  /* webpackChunkName: 'ui_common' */
  './Slider') });

const Range = Loadable({name: "Range", loader: () => import(
  /* webpackChunkName: 'ui_common' */
  './Range') }) as ComponentType<any>;

const Progress = Loadable({name: "Progress", loader: () => import(
  /* webpackChunkName: 'ui_common' */
'./Progress')}) as ComponentType<any>

export type { SliderProps } from './types';
export type { RangeProps } from './types';

interface CompoundedComponent extends React.ComponentClass<SliderProps> {
  Range: typeof Range;
  Progress: typeof Progress;
}

const InternalSlider = (Slider as unknown) as CompoundedComponent;

InternalSlider.Range = Range;
InternalSlider.Progress = Progress;

export default InternalSlider;
