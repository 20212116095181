import { action, observable } from "mobx";
import { getLabel, RequestResult } from "@weapp/utils";
import { FieldProps, QuikSearchPanelList } from "../types";
import { checkPermission } from "../apis";
import { FormCustomLayoutProps, FormInitAllDatas, FormLayoutType, FormStore, FormStoreType, FormValue } from '../../form/index';
import { RadioOptionType, RadioValueType } from '../../radio/index';
import Dialog from '../../dialog/index';
import { SelectValueType } from '../../select/index';
import { initConditionValue, SearchAdvancedStoreType } from "./Store";
import { AnyObj, FormItemProps, TypesBrowserOption } from "../../../lib";
import { getBrowserConfigUtils } from "../util/browser-config/index.async";
import getUtils from "./common";

const { message } = Dialog;
export default class CommonFilterStore {
  parent: SearchAdvancedStoreType;
  setSnapSavingCondition: ((store: SearchAdvancedStoreType) => boolean) | undefined;
  setPublicSnapSavingAuth: ((currentModule?: string) => boolean) | undefined;
  constructor(parent: SearchAdvancedStoreType) {
    this.parent = parent;
  }

  @observable datas: QuikSearchPanelList[] = [];
  @observable loading: boolean = false;
  @observable conditionId: string = '';
  @observable module: string = 'common';
  allDatas: FormInitAllDatas = { data: {}, groups: [], layout: [], items: {} };
  allFields: FieldProps[] = [];
  customLayout: FormCustomLayoutProps = {};
  @observable name?: FormValue = '';
  @observable id: string = '';
  @observable.shallow chooseData: QuikSearchPanelList = {};
  @observable visibleForTipDialog: boolean = false;
  @observable valueForTip: RadioValueType = 'add';
  optionsForTip: RadioOptionType[] = [
    { id: 'add', content: '新建常用筛选' },
    { id: 'edit', content: '替换原来的常用筛选' },
  ];
  @observable isMobile: boolean = false;
  @observable confirmVisible: boolean = false;
  confirmForm: FormStoreType = new FormStore();
  @observable conditionValue: SelectValueType = initConditionValue;
  @observable needSaveSearchValue: boolean = false; // 常用筛选存储searchValue数据
  @observable saveAsPublic?: boolean = false; // 存为公用标记
  @observable saveAsDefault?: boolean = false; // 存为默认标记
  @observable snapCustomData?: any = undefined; // 常用筛选保存自定义数据(业务通过setState处理)
  @observable setUseScope?: boolean = false; // 生效范围标记
  @observable isAdvanceSearchAdmin: boolean = false // 高级搜索公用权限校验
  @observable enableNewCheckPerssion: boolean = false // 启用新版权限校验方案(增设开关，兼容服务未同步更新的情况)
  @observable detailLoading: boolean = false;
  @observable detailDisabled: boolean = false;

  @action
  setState = (params: Partial<CommonFilterStoreType>) => {
    Object.keys(params).forEach((key) => {
      const storeKey = key as keyof CommonFilterStoreType;
      // @ts-ignore
      this[storeKey] = params[storeKey];
    })
  }

  @action
  setIsMobile = (isMobile: boolean) => {
    this.isMobile = isMobile;
  }
  @action
  setName = (name: FormValue) => {
    this.name = name;
  }

  @action
  setValueForTip = (valueForTip: RadioValueType) => {
    this.valueForTip = valueForTip;
  }

  @action
  setVisibleForTipDialog = (visibleForTipDialog: boolean) => {
    this.visibleForTipDialog = visibleForTipDialog;
  }

  @action
  setId = (id: string) => {
    this.id = id;
  }

  @action
  setDatas = (datas: QuikSearchPanelList[]) => {
    this.datas = datas;
  }

  // 高级搜索模块接口标识
  @action
  setModule = (module: string) => {
    this.module = module;
  }

  // 高级搜索面板唯一值
  @action
  setConditionId = (conditionId: string) => {
    this.conditionId = conditionId;
  }
  @action
  querySnapsName = () => {
    return getUtils().then((defaultModule) => defaultModule.querySnapsName(this));
  }

  // 获取当前默认的筛选的具体的参数
  @action
  queryDefaultSnaps = (module?: string, conditionId?: string) => {
    return getUtils().then((defaultModule) => defaultModule.queryDefaultSnaps(this, module, conditionId));
  }

  @action
  doSaveAsSnaps = (noRefresh?: boolean, otherParams?: any) => {
    return getUtils().then((defaultModule) => {
      return defaultModule.doSaveAsSnaps(this, noRefresh, otherParams);
    })
  }

  /**
   * updateRepeatData 更新重名数据
   * @param repeatData
   * @param otherParams
   */
  @action
  updateRepeatData = (repeatData: QuikSearchPanelList, otherParams?: any) => {
    return getUtils().then((defaultModule) => defaultModule.updateRepeatData(this, repeatData, otherParams));
  }

  /**
   * 取消重名保存
   */
  @action
  onCancelSaveRepeatData = () => {
    if (!this.id) { // 仅新增常用筛选的情况，取消重名保存时，需重置保存常用筛选弹框的选项
      this.name = '';
      this.initCommonFilterDialog();
    }
  }
  @action
  judgeDuplicationName = (value?: FormValue, otherParams?: any) => {
    return getUtils().then((defaultModule) => {
      return defaultModule.judgeDuplicationName(this, value, otherParams);
    })
  }
  @action
  judgeConditionNum = (allFields: FieldProps[], customLayout: FormCustomLayoutProps) => {
    // let ping = !customLayout || (customLayout && Object.keys(customLayout).length === 0) || customLayout?.otherGroup?.length === 0;
    // const dataEmpty = this.parent.isEmpty || (ping && !allFields.find((field) => !field.hide)) || this.parent.browserConfigStore.judgeConditionNum();
    // if (!this.setSnapSavingCondition?.(this.parent) && dataEmpty) {
    // 2022-11-18 产品确定，组件默认不做空条件判断拦截，由用户自行判断是否保存空条件。
    if (this.setSnapSavingCondition && !this.setSnapSavingCondition?.(this.parent)) {
      message({
        type: 'error',
        content: getLabel('63382', '请至少配置一个条件'),
      })
      return false;
    }
    return true;
  }
  @action
  replaceSnaps = (allDatas: FormInitAllDatas, allFields: FieldProps[], customLayout: FormCustomLayoutProps, isMobile?: boolean, otherParams?: any) => {
    return getUtils().then((defaultModule) => {
      return defaultModule.replaceSnaps(this, allDatas, allFields, customLayout, isMobile, otherParams);
    })
  }
  @action
  saveAsSnaps = (allDatas: FormInitAllDatas, allFields: FieldProps[], customLayout: FormCustomLayoutProps, isMobile?: boolean, mustSave?: boolean, otherParams?: any) => {
    return getUtils().then((defaultModule) => {
      return defaultModule.saveAsSnaps(this, allDatas, allFields, customLayout, isMobile, mustSave, otherParams);
    })
  }

  @action
  deleteSnap = (id: string) => {
    return getUtils().then((defaultModule) => {
      return defaultModule.deleteSnap(this, id);
    })
  }

  @action
  querySnapsDetail = (id: string) => {
    return getUtils().then((defaultModule) => {
      return defaultModule.querySnapsDetail(this, id);
    })
  }

  @action
  updateSnaps = () => {
    return getUtils().then((defaultModule) => {
      return defaultModule.updateSnaps(this);
    })
  }

  /**
   * initCommonFilterDialog 清空设置项，同时需重置表单
   */
  @action
  initCommonFilterDialog = (reset?: boolean) => {
    // this.valueForTip = 'add';
    // this.id = '';
    // this.name = '';
    // this.chooseData = {};
    this.confirmForm.resetForm();
    this.confirmForm.setHide('range', true);
    // 重置表单(相当于取消选中常用筛选项), 取消保存的时候处理
    if (reset) {
      // this.parent.formStore.initForm(this.parent.allDatas);
      // this.parent.formStore.setState({ customLayout: {} });
    }
  }

  @action
  initConfirmForm = (saveAsPublic?: boolean, saveAsDefault?: boolean, isMobile?: boolean, otherParams?: AnyObj) => {
    this.setState({
      saveAsPublic: saveAsPublic && this.isModuleAdmin(otherParams?.currentModule),
      saveAsDefault,
      setUseScope: otherParams?.setUseScope,
    })
    const layout: FormLayoutType[] = [
      [{ id: 'name', label: getLabel('53791', '常用筛选名称'), hide: false, items: ['name'], labelSpan: isMobile ? 8 : 6 }],
      [{ id: 'public', label: getLabel('53792', '存为公用'), hide: !this.saveAsPublic, items: ['isPublic'], labelSpan: isMobile ? 8 : 6 }],
      [{ id: 'default', label: getLabel('98105', '存为默认'), hide: this.saveAsPublic || !this.saveAsDefault, items: ['isDefault'], labelSpan: isMobile ? 8 : 6 }],
      [{ id: 'range', label: getLabel('13890', '使用范围'), hide: true, items: ['range'], labelSpan: isMobile ? 8 : 6 }],
      [{ id: 'description', label: getLabel('112500', '描述'), hide: !otherParams?.enableSnapDescription, items: ['snapDescription'], labelSpan: isMobile ? 8 : 6 }],
    ];
    const browserTypes: TypesBrowserOption[] = [
      { id: "user", content: getLabel('29511', '人员') },
      { id: "dept", content: getLabel('52148', '部门') },
      // { id: "subCom", content: getLabel('63384','分部') },
      { id: "group", content: getLabel('63383', '常用组') },
      // { id: "role", content: getLabel('14300','角色') },
      // { id: "position", content: getLabel('52139','岗位') },
      { id: "all", content: getLabel('59739', '所有人') },
    ];
    const items: FormItemProps = {
      'name': {
        itemType: 'INPUT',
        rules: 'required|stringLength:255',
      },
      'isPublic': {
        itemType: 'SWITCH',
      },
      'isDefault': {
        itemType: 'SWITCH',
      },
      'range': {
        itemType: 'TYPESBROWSER',
        multiple: true,
        module: 'hrm',
        type: 'hrmcombination',
        options: browserTypes,
        required: true,
      },
      'snapDescription': {
        itemType: 'TEXTAREA',
        required: true,
      }
    };
    this.confirmForm.initForm({
      groups: [],
      layout,
      items,
      data: {},
      hoverEdit: !isMobile,
    })
  }

  /**
   * 选中常用筛选项
   * @param id
   */
  @action
  checkSnaps = (id: string, customCheckSnap?: boolean) => {
    return getUtils().then((defaultModule) => {
      return defaultModule.checkSnaps(this, id, customCheckSnap);
    })
  }

  // 更新高级搜索中具体筛选的数据
  updateSnapes = (data: any) => {
    const content = JSON.parse(data.content);
    this.setId(data.id);
    this.setName(data.name);
    this.parent.setConditionValue(content.condition || 'and');
    this.setState({
      conditionValue: content.condition || 'and',
      chooseData: {
        ...data,
        range: typeof data.rangeContent === 'string' ? JSON.parse(data.rangeContent) : [],
      }
    });
    this.setValueForTip('edit');
    if (content && !content.layout) { // 无layout数据，即老数据
      this.parent.transDatasStore.transOldDatas(content); // 老数据转换逻辑
    } else {
      const { updateSnapes } = getBrowserConfigUtils();
      const { content: _content, needInit } = updateSnapes?.(content, this.parent) || { content, needInit: true };
      if (needInit) {
        const lostConditionDatas = this.parent.transDatasStore.__getLostConditionData(_content?.data); // 需在initForm之前获取数据处理
        this.parent.transDatasStore?.transCustomLayout(_content, false);
        this.parent.formStore.updateDatas(lostConditionDatas, true);
      }
      this.parent.browserIncludingSubStore?.afterCheckSnaps?.(_content, this.isMobile, {
        id: data?.id,
        conditionId: data?.conditionId,
      });
    }
  }
  /**
   * updateConfirmFormDatas 自定义confirmForm的字段数据
   */
  updateConfirmFormDatas = () => {
    this.confirmForm.updateDatas({ ...this.chooseData, name: this.name || '' });
    // 无保存公用条件的权限，隐藏public、range字段，不可查看已选内容(与et统一)
    this.confirmForm.setHide('public', !this.saveAsPublic);
    this.confirmForm.setHide('range', !(this.saveAsPublic && this.chooseData?.isPublic) || !this.setUseScope); // range字段在开启存为公用开关且开启生效范围设置权限后才可启用
  }
  /**
   * 取消选中常用筛选项
   */
  @action
  unCheckSnaps = () => {
    const oldId = this.id;
    this.setId('');
    this.setName('');
    this.setValueForTip('add');
    this.setState({ chooseData: {} });
    // 存在后台禁用设置
    if (this.parent.browserConfigStore?.hasDefaultConfig) {
      return this.parent.browserConfigStore?.reset?.();
    } else {
      this.parent.formStore.initForm(this.parent.allDatas);
      this.parent.formStore.setAllFields(this.parent.allFields);
      this.parent.formStore.setState({ customLayout: {} });
    }
    oldId && this.parent.browserIncludingSubStore?.reset?.();
  }

  @action
  getCurrentModule = (currentModule?: string) => {
    if (!currentModule) {
      console.error('未设置currentModule(当前访问的模块名，对应window.TEAMS.modules内的module)');
    }
    return currentModule;
  }
  /**
   * 存为公用功能权限校验
   * @param module 当前访问的模块，对应window.TEAMS.modules
   */
  @action
  isModuleAdmin = (currentModule?: string) => {
    if (typeof this.setPublicSnapSavingAuth === 'function') return this.setPublicSnapSavingAuth(currentModule);
    return this.enableNewCheckPerssion ? this.isAdvanceSearchAdmin : this.isModuleAdminOld(currentModule);
  }

  @action
  isModuleAdminOld = (currentModule?: string) => { // 旧版权限校验方案
    let flag = false;
    const module = this.getCurrentModule(currentModule);
    if (currentModule) {
      const currentUser = window.TEAMS?.currentUser;
      if (currentUser?.admin || currentUser?.moduleAdmin || (
        currentUser?.modules?.indexOf(module) >= 0
      )) {
        flag = true;
      }
    }
    return flag;
  }

  @action
  checkPermission = () => {
    checkPermission({ menuCode: 'advanceSearch' }, this.parent.requestParams, this.parent).then((result: RequestResult) => {
      this.setState({ isAdvanceSearchAdmin: result?.data[0]?.code === 'advanceSearchAdmin' })
    })
  }
}

export type CommonFilterStoreType = Pick<CommonFilterStore, keyof CommonFilterStore>;
