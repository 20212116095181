import { classnames } from '@weapp/utils';
import React, { FC, memo, ReactElement, RefAttributes, useCallback, useContext, useMemo, useRef, useState } from 'react';
import AnimateHeight from '../animate-height';
import Block from "../block";
import Icon, { IconNames } from '../icon';
import { LayoutContext } from './RowContext';
import { BoxProps } from "./types";

const Box: FC<BoxProps & RefAttributes<any>> = memo((props) => {
  const {
    prefixCls, className, style, type, outer, right, width,
    allowHidden, hidden, btnStyle, btnClassName,
    children, weId, onVisibleChange, customRender, ...restProps
  } = props;
  const changeLayoutVisible = useContext(LayoutContext);

  const changeVisible = useCallback(() => {
    changeLayoutVisible?.(type || 'content', outer || false, right || false, !hidden);
    onVisibleChange?.(!!hidden);
  }, [type, outer, right, hidden, changeLayoutVisible, onVisibleChange]);

  let showBtnCom;
  if (allowHidden) {
    let icon: IconNames | undefined;
    let position: string = '';
    if (type === 'side') {
      if (right) {
        icon = hidden ? "Icon-left-arrow01" :"Icon-Right-arrow01";
        position = 'right';
      } else {
        icon = hidden ? "Icon-Right-arrow01" : "Icon-left-arrow01";
        position = 'left';
      }
    } else if (type === 'header') {
      icon = hidden ? "Icon-Down-arrow01" : "Icon-up-arrow01";
      position = 'bottom';
    } else if (type === 'footer') {
      icon = hidden ? "Icon-up-arrow01" : "Icon-Down-arrow01";
      position = 'top';
    }
    if (icon) {
      const btnCls =  classnames(`${prefixCls}-show-btn`, {
        [`show-btn-${position}`]: position,
        'is-outer': outer
      }, btnClassName);
      showBtnCom = (
        <span className={btnCls} style={btnStyle} onClick={changeVisible}>
          <Icon weId={`${props.weId || ''}_kyclc5`} name={icon} /> 
        </span>
      );
    }
  }

  const boxKey = useMemo(() => {
    let key = `${type || 'content'}`;
    if (type !== 'content') {
      key += outer ? '-outer' : '-inner';
    }
    if (type === 'side') {
      key += right ? '-right' : '-left';
    }
    return key;
  }, [type, outer, right]);

  const cls = classnames(prefixCls, {
    'is-hidden': hidden,
  }, className);

  const mergedStyle = useMemo(() => {
    const mStyle = style || {};
    if (width && !hidden) {
      mStyle.width = '100%';
    }
    return mStyle;
  }, [width, hidden, style]);

  let content = (
    <Block
      weId={`${weId || ''}_hoftk2@${boxKey}`}
      className={cls}
      style={mergedStyle}
      {...restProps}
    >
      {children}
    </Block>
  );

  if (showBtnCom) {
    if (type === 'header' || type === 'footer') {
      content = (
        <AnimateHeight
          weId={`${weId || ''}_h5mm7v`}
          duration={300}
          easing="cubic-bezier(0.645, 0.045, 0.355, 1)"
          className={`${prefixCls}-wrap`}
          height={hidden ? 0 : 'auto'}
        >
          {content}
        </AnimateHeight>
      );
    }
    content = (
      <div className={`${prefixCls}-wrap`}>
        {content}
        {showBtnCom}
      </div>
    );
  }
  return customRender ? customRender(this, content) as ReactElement: content;
});

Box.displayName = 'LayoutBox';

Box.defaultProps = {
  type: 'content',
  outer: false,
  right: false,
};

export default Box;
