import React from "react";
import { ReactText } from "react";
import { isString } from '../../../utils/index';
import { DESENSITIZATION, ENCRYPTION, ENCRYPTION_CODE, ENCRYPTION_CODE_ICON } from '../contants/index';

// 是否为脱敏数据
export function desensitization(value: ReactText) {
  return typeof value === 'string' && value.indexOf(DESENSITIZATION) !== -1;
}

// 是否为加密数据
export function encryption(value: ReactText) {
  return typeof value === 'string' && value.indexOf(ENCRYPTION) !== -1;
}

// 匹配所有脱敏数据
export function matchDesensitization(value: string, bool?: boolean) {
  const exp = bool ? /\*\*\*\*/ : DESENSITIZATION + "[A-Za-z0-9-]{36}";
  const patt = bool ? exp : new RegExp(exp, "g");
  return value.match(patt);
}

// 替换数据(*** 与 脱敏数据)
export function replaceByIndex(newData: string, keys: string, newKeyWords: string, index: number) {
  let result = "";
  const datas = newData.split(keys);
  datas.forEach((data, i) => {
    if (i === index) {
      result += data + newKeyWords;
    } else if (i < datas.length - 1) {
      result += data + keys;
    } else {
      result += data;
    }
  });
  return result;
}
// 数据过滤
export function richfilter(
  data: string,
  keyWords: any,
  index: number,
  newKeyWord: string,
  noDesensitization?: boolean,
  noIcon?: boolean
) {
  let newData = data,
    newKeyWords = noDesensitization
      ? ""
      : newKeyWord
        ? newKeyWord
        : noIcon
          ? ENCRYPTION_CODE
          : ENCRYPTION_CODE + "${" + ENCRYPTION_CODE_ICON + "_" + index + "}",
    nodeNum = -1,
    charNum = -1,
    keys = newKeyWord ? keyWords : new RegExp(keyWords, "g"),
    nodes = new RegExp("<.*?>", "ig"), //匹配html元素
    chars = new RegExp("&.*?;", "ig"), //匹配转义字符
    oldNodes = newData.match(nodes), //存放html元素的数组
    oldChars = newData.match(chars); //存放转义字符的数组
  newData = newData.replace(nodes, "{~}"); //替换html标签
  newData = newData.replace(chars, "{~~}"); //替换转义字符
  if (newKeyWord) {
    newData = replaceByIndex(newData, keys, newKeyWords, index);
  } else {
    newData = newData.replace(keys, newKeyWords); //替换key
  }
  newData = newData.replace(/{~}/g, () => {
    //恢复html标签
    nodeNum++;
    return oldNodes ? oldNodes[nodeNum] : '';
  });
  newData = newData.replace(/{~~}/g, () => {
    //恢复html标签
    charNum++;
    return oldChars ? oldChars[charNum] : '';
  });
  return newData;
}

// 替换脱敏数据为**** + 图标
export function replaceDesensitization(value: string, noIcon?: boolean) {
  const matches = matchDesensitization(value);
  let dataFilter = value;
  if (matches) {
    matches.forEach((keyWords, index) => {
      dataFilter = richfilter(dataFilter, keyWords, index, "", false, noIcon);
    });
  }
  return dataFilter;
}

// 替换加密数据为****
export function replaceEncryption(value: string) {
  const patt = new RegExp(ENCRYPTION + "[A-Za-z0-9-]{36}", "g");
  const matches = value.match(patt);
  let dataFilter = value;
  if (matches) {
    matches.forEach((keyWords, index) => {
      dataFilter = richfilter(dataFilter, keyWords, index, ENCRYPTION_CODE);
    });
  }
  return dataFilter;
}

// 转换 ENCRYPTION_CODE 标记，渲染展示图标
export function transEncryptionIcon (value: string) {
  console.log(React.createElement('div', 1));
}

// 全局替换
export function doReplaceAll(datas: any[], value: string, replaceValue: any) {
  if (datas) {
    datas.forEach(data => {
      value = value.replace(data, replaceValue);
    });
  }
  return value;
}
// 获取当前处理的数据下标
export function getIndexFromShowArrays(index: number, showArrays: Array<string>) {
  let endIndex = 0;
  const showArray = showArrays.filter((v, i) => i < index && v !== ENCRYPTION_CODE);
  if (showArray) {
    endIndex = showArray.length;
  }
  return index - endIndex;
}