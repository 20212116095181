/* placeSearch 地点联想搜索 */
import { mapType } from '../types';

const placeSearch = (mapType?: mapType, place?: string, city?: string, instance?: any) => {
  return import(
  	/* webpackChunkName: "map_common" */
    './placeSearchAsync'
  ).then((defaultModule) => defaultModule.default?.(mapType, place, city, instance));
}

export default placeSearch;