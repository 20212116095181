import React from "react";
import { classnames, ua } from '@weapp/utils';
import { SpinProps, type as SpinType } from "./types";
import { spinClsPrefix } from '../../constants/index';
import Icon from '../icon/index';
import { needRTL } from '../../utils/index';

function renderIndicator(prefixCls: string, props: SpinProps): React.ReactNode {
  if (props.type === SpinType.CURVE) {
    return <span style={{ color: props.color }} className={'curve'}>
      <Icon weId={`${props.weId || ''}_3rq548`} name="Icon-Loading" spin={true} />
    </span>
  }
  const dotClassName = `${prefixCls}-dot ${needRTL() && 'ui-rtl'}`;

  return (
    <span style={{ color: props.color }} className={classnames(dotClassName, `${prefixCls}-dot-spin`)}>
      <div className="loading-bottom-layer">
        <div className="loading-left">
          <div style={{ borderLeftColor: props.color, borderBottomColor: props.color, boxSizing: 'content-box' }} className="loading-left-progress">
            <div style={{ background: props.color }} className="loading-ball-progress">
            </div>
          </div>
        </div>
        <div className="loading-right">
          <div style={{ borderRightColor: props.color, borderTopColor: props.color, boxSizing: 'content-box' }} className="loading-right-progress">
            <div style={{ background: props.color }} className="loading-ball-progress">
            </div>
          </div>
        </div>
      </div>
    </span>
  );
}
/**
 * @title 加载中
*/
class Spin extends React.Component<SpinProps, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      spinning: true
    }
  }

  static defaultProps = {
    spinning: true,
    useSvgSpin: false
  };

  componentDidMount() {
    !this.props.useSvgSpin && this.updateSpinning();
  }

  componentDidUpdate() {
    !this.props.useSvgSpin && this.updateSpinning();
  }

  updateSpinning = () => {
    const { spinning } = this.props;
    const { spinning: currentSpinning } = this.state;
    if (currentSpinning !== spinning && 'spinning' in this.props) {
      this.setState({ spinning });
    }
  };

  isNestedPattern() {
    return !!(this.props && typeof this.props.children !== 'undefined');
  }

  renderSvgSpin = () => {
    const {
      className,
      size,
      text,
      wrapperClassName,
      style,
      type,
      customRender,
      globalLoading,
      color,
      spinning,
      children,
      ...restProps
    } = this.props;

    const prefixCls = spinClsPrefix;
    const spinTextContainer = classnames(
      prefixCls,
      {
        [`${prefixCls}-svg-md`]: !size && (this.isNestedPattern() || globalLoading),
        [`${prefixCls}-svg-sm`]: size === 'small',
        [`${prefixCls}-svg-lg`]: size === 'large',
        [`${prefixCls}-svg-rtl`]: !text && needRTL(),
        [`${prefixCls}-svg-spinning`]: spinning,
        [`${prefixCls}-svg-show-text`]: !!text,
        [`${prefixCls}-svg-show-text-rtl`]: (!!text) && needRTL(),
      },
      className,
    );
    const spinClassName = classnames(
      {
        [`${prefixCls}-svg-md`]: !size,
        [`${prefixCls}-svg-sm`]: size === 'small',
        [`${prefixCls}-svg-lg`]: size === 'large',
      },
    )
    const spinContainer = classnames(
      `${prefixCls}-svg-nested-loading`,
      { [`${prefixCls}-svg-nested-loading-global`]: globalLoading && spinning },
      wrapperClassName
    )

    let spinElement = (
      <div {...restProps} style={style} className={spinTextContainer}>
        <svg className={spinClassName} viewBox="0 0 50 50" >
          <path fill={color || "#417ff9"} d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6"
              repeatCount="indefinite"
            />
          </path>
        </svg>
        {text ? <div className={`${prefixCls}-svg-text`}>{text}</div> : null}
      </div>
    );
    if (ua.browser === 'IE') {
      spinElement = <div className={`${prefixCls}-svg-nested-loading-global ie-svg-icon`}>
        <Icon weId={`${this.props.weId || ''}_bho03i`} name="Icon-Loading" size="md" {...this.props as any} />
      </div>
    };

    if (this.isNestedPattern() || globalLoading) {
      const containerClassName = classnames(`${prefixCls}-svg-container`, {
        [`${prefixCls}-svg-blur`]: spinning,
      });
      return (
        <div {...restProps} className={spinContainer}>
          {spinning && <div key="loading">{spinElement}</div>}
          <div className={containerClassName} key="container">
            {globalLoading ? null : this.props.children}
          </div>
        </div>
      );
    }
    return customRender ? customRender(this, spinElement) : spinElement;
  }

  renderSpin = () => {

    const {
      className,
      size,
      text,
      wrapperClassName,
      style,
      type,
      customRender,
      globalLoading,
      ...restProps
    } = this.props;

    const { spinning } = this.state;
    const prefixCls = spinClsPrefix;
    const spinClassName = classnames(
      prefixCls,
      {
        [`${prefixCls}-sm`]: size === 'small',
        [`${prefixCls}-lg`]: size === 'large',
        [`${prefixCls}-spinning`]: spinning,
        [`${prefixCls}-show-text`]: !!text,
        [`${prefixCls}-show-text-rtl`]: (!!text) && needRTL(),
      },
      className,
    );

    const spinElement = (
      <div {...restProps} style={style} className={spinClassName}>
        {spinning && renderIndicator(prefixCls, this.props)}
        {text ? <div className={`${prefixCls}-text`}>{text}</div> : null}
      </div>
    );
    if (this.isNestedPattern() || globalLoading) {
      const containerClassName = classnames(`${prefixCls}-container`, {
        [`${prefixCls}-blur`]: spinning,
      });
      return (
        <div {...restProps} className={classnames(
          `${prefixCls}-nested-loading`,
          { [`${prefixCls}-nested-loading-global`]: globalLoading && spinning },
          wrapperClassName
        )}>
          {spinning && <div key="loading">{spinElement}</div>}
          <div className={containerClassName} key="container">
            {globalLoading ? null : this.props.children}
          </div>
        </div>
      );
    }
    return customRender ? customRender(this, spinElement) : spinElement;
  };

  render() {
    const { useSvgSpin } = this.props
    return (
      <>
        {useSvgSpin ? this.renderSvgSpin() : this.renderSpin()}
      </>
    )
  }
}

export default Spin;
