import { ComponentType } from 'react';
import Loadable from '../../react-loadable';
import { MUploadType, MSensitiveWordsDialogProps } from './types';
import { mDefaultProps } from './defaultProps';
import getAttachmentLocalSecurityInfo from './utils/getLocalSecurityInfo';
import { muploadMiddleware } from './utils/middleware';

const MUpload = Loadable({
  name: 'MUpload',
  propsMiddleware: muploadMiddleware,
  loader: () => import(
    /* webpackChunkName: "m_upload" */
    './Upload.m')
}) as MUploadType;

MUpload.defaultProps = mDefaultProps;

const MUploadDetail = Loadable({
  name: 'MUploadDetail',
  propsMiddleware: muploadMiddleware,
  loader: () => import(
    /* webpackChunkName: "m_upload" */
    './MUploadDetail')
}) as MUploadType;

MUpload.MUploadDetail = MUploadDetail;

const MFileList = Loadable({
  name: 'MFileList',
  propsMiddleware: muploadMiddleware,
  loader: () => import(
    /* webpackChunkName: "m_upload" */
    './FileList.m')
}) as MUploadType;

const MSensitiveWordsDialog = Loadable({
  name: 'MSensitiveWordsDialog',
  loader: () => import(
    /* webpackChunkName: "m_upload" */
    './components/mb/MSensitiveWordsDialog')
}) as ComponentType<MSensitiveWordsDialogProps>;

MUpload.MFileList = MFileList;
MUpload.MSensitiveWordsDialog = MSensitiveWordsDialog;

MUpload.getAttachmentLocalSecurityInfo = getAttachmentLocalSecurityInfo;

export type { MUploadProps, UploadData as MUploadData, IFile as MIFile, UploadOptionType as MUploadOptionType, MSensitiveWordsDialogProps } from './types';

export default MUpload;
