import { action, observable } from "mobx";
import { isEmpty, needSync } from '../../../utils/index';
import { FormValue } from '../../form/index';
import { FormItemProps, FormSingleItemProps } from '../../form-item/index';
import { CardDetailFormStoreType } from "./Store";
import { CardDetailFormItemProps, CardDetailFormAllDatas } from '../types';
import { BrowserValueType } from '../../browser/index';
import { getShowGroupId as getShowGroupIdFunc } from '../utils/index';
import { getUtils } from "./common";
interface ConfigType {
  [key: string]: string[],
}

export interface SecretLevelStoreProps {
  secretLevel: number,
  secretLevelValidity: string,
  prohibitRealTimeSaving?: boolean, // 禁止公共关联字段实时保存功能
}
export class SecretLevelStore {
  parent: CardDetailFormStoreType;
  constructor(parent: CardDetailFormStoreType) {
    this.parent = parent;
  }

  @observable secretLevel: number = -999; // 记录当前密级，实时记录，用于更新字段items配置
  @observable dataSecretLevel: number = -999; // 记录上一次密级，用于判断数据是否需要过滤
  @observable secretLevelValidity: string = '';
  @observable prohibitRealTimeSaving?: boolean = false; // 禁止公共关联字段实时保存功能
  @observable browserTypes: string[] = ['document', 'goalBrowser', 'coworkItemsBrowser', 'mt', 'wfcRequest', 'wflRequest','taskBrowser', 'mainlineBrowser', 'calendarBrowser']; // 需过滤密级数据的浏览框字段类型
  @observable itemTypeRule: string[] = ['BROWSER', 'UPLOAD']; // 需过滤密级数据的字段类型
  @observable config: ConfigType = {}; // 需要过滤密级的分组配置{groupId: [item's key]}
  @observable hasInit: boolean = false; // 初始化成功标记

  @action
  setState = (params: Partial<SecretLevelStoreType>) => {
    Object.keys(params).forEach((key) => {
      const storeKey = key as keyof SecretLevelStoreProps;
      // @ts-ignore
      this[storeKey] = params[storeKey];
    })
  }

  /* ---------------------------------------------- 初始化需过滤密级数据的配置信息 ---------------------------------------- */

  /**
   * 初始化需过滤密级数据的配置信息
   * @param props SecretLevelStoreProps
   */
  @action
  init = (props: SecretLevelStoreProps, initParams?: CardDetailFormAllDatas) => {
    if (needSync('secretLevel', props) || needSync('secretLevelValidity', props)) { // 两者至少配置了一项
      const { secretLevel, secretLevelValidity, prohibitRealTimeSaving } = props;
      this.initConfig(initParams); // 首次调用，需初始化配置
      this.setState({
        secretLevel,
        dataSecretLevel: secretLevel,
        secretLevelValidity,
        prohibitRealTimeSaving
      })
    }
    // getUtils().then((defaultModule) => defaultModule.initSecretLevel(this, props, initParams));
  }

  /**
  * 初始化配置需要处理密级的字段
  */
  @action
  initConfig = (initParams?: CardDetailFormAllDatas) => {
    const items = initParams?.items || this.parent.items;
    const layout = initParams?.layout || this.parent.layout;
    layout?.forEach((row) => {
      row.forEach((col) => {
        const groupId = col.groupId;
        if (groupId) {
          col.items?.forEach((itemKey) => {
            if (this.matchRule(itemKey, items)) { // 符合密级规则
              let showGroupId = groupId;
              if (groupId === 'tags' && !this.parent.isMobile) { // pc端tag分组需转换成联动后的分组配置
                showGroupId = this.getShowGroupId(items[itemKey]);
              }
              !this.config[showGroupId] && (this.config[showGroupId] = []);
              this.config[showGroupId].push(itemKey);
            }
          })
        }
      })
    })
    this.setState({
      hasInit: Object.keys(this.config).length > 0, // config不为空，说明有需要过滤密级的字段
    })
    // getUtils().then((defaultModule) => defaultModule.initConfig(this, initParams));
  }

  /**
   * 满足密级规则
   * @param itemKey 
   * @returns 
   */
  @action
  matchRule = (itemKey: string, carddetailform_items: CardDetailFormItemProps) => {
    const items = carddetailform_items || this.parent.items;
    const itemType = items[itemKey]?.itemType;
    const browserBean = items[itemKey]?.browserBean;
    if (itemType && this.itemTypeRule.indexOf(itemType) >= 0) {
      if (itemType === 'BROWSER') return this.browserTypes.indexOf(browserBean?.type) >= 0; // 浏览框字段按照type类型划分
      return true;
    }
    return false;
  }

  /**
   * 获取分组id
   * @param items 当前字段配置
   * @returns 分组id
   */
  @action
  getShowGroupId = (items: FormItemProps) => {
    return getShowGroupIdFunc(items);
  }


  /* --------------------------------------------------- 过滤密级数据(密级改变) --------------------------------------------------- */

  @action
  setSecretProps = (groupId: string, datakey: string) => {
    getUtils().then((defaultModule) => defaultModule.setSecretProps(this, groupId, datakey));
  }

  /**
   * 过滤浏览类型数据
   */
  @action
  filterBrowserData = (groupId: string, datakey: string, data: BrowserValueType) => {
    const store = this.parent.formStores[groupId];
    const secretLevel = this.secretLevel;
    const filteredData: { secretLevel: number; }[] = [];
    const retainedData: any[] = [];
    let needUpdate: boolean = false;
    if (!isEmpty(data)) {
      const dataFirst = data[0];
      if (needSync('secretLevel', dataFirst)) { // 仅判断首条数据是否存在密级字段即可(接口约定)
        const browserItem = store?.items[datakey];
        data.forEach((da) => {
          if (da.secretLevel < secretLevel) {
            needUpdate = true;
            if (browserItem?.common && !this.prohibitRealTimeSaving) {
              const targetModule = this.parent.targetModuleRelations.get(datakey) || datakey;
              filteredData.push(this.parent.transValue([{
                ...da,
              }], targetModule)?.[0]); //公共关联字段触发保存更新
            }
          } else {
            retainedData.push(da);
          }
        })
        if (needUpdate) {
          store.updateDatas({
            [`${datakey}`]: retainedData,
          });
        }
      }
    }
    return {
      retainedData,
      filteredData,
    };
  }

  @action
  setBrowserSecretProps = (groupId: string, datakey: string) => {
    getUtils().then((defaultModule) => defaultModule.setBrowserSecretProps(this, groupId, datakey));
  }

  /**
   * 校验密级(主动调用，过滤整个表单数据)
   * @param secretLevel 密级等级
   * @param secretLevelValidity 保密期限
   * @param prohibitRealTimeSaving 禁止公共关联字段实时保存功能
   */
  @action
  validateSecretLevel = (secretLevel: number, secretLevelValidity: string, prohibitRealTimeSaving?: boolean) => {
    getUtils().then((defaultModule) => defaultModule.validateSecretLevel(this, secretLevel, secretLevelValidity, prohibitRealTimeSaving));
  }

  /* --------------------------------------------------- 过滤密级数据(初始化) --------------------------------------------------- */
  /**
   * 按照字段过滤items配置
   * @param groupId 
   * @param datakey 
   * @param item 
   * @param data 
   * @returns 
   */
  @action
  filterInitItems = (item: FormSingleItemProps) => {
    if (!this.hasInit) return item;
    const secretLevel = this.secretLevel;
    const secretLevelValidity = this.secretLevelValidity;
    if (item?.itemType === 'BROWSER' && this.browserTypes.indexOf(item?.browserBean?.type) >= 0) {
      const browserBean = item?.browserBean || {};
      return {
        ...item,
        browserBean: {
          ...browserBean,
          commonParams: {
            ...(browserBean.commonParams || {}),
            secretLevel,
            secretLevelValidity,
          }
        }
      }
    } else if (item?.itemType === 'UPLOAD') {
      return {
        ...item,
        secretLevel,
        secretLevelValidity,
        otherParams: {
          ...(item?.otherParams || {}),
          secretLevel,
          secretLevelValidity,
          secretLevelAutoAdjust: true, // 开启密级
          secretProtect: true,
        }
      }
    }
    return item;
  }

  /**
   * 按照字段过滤数据，用于过滤表单默认值
   * @param groupId 分组id
   * @param datakey 字段key
   * @param item 字段对应的item配置
   * @param data 数据 
   * @returns 
   */
  @action
  filterData = (groupId: string, datakey: string, item: FormSingleItemProps, data: FormValue) => {
    if (!this.hasInit) return data;
    if (item?.itemType === 'BROWSER' && this.browserTypes.indexOf(item?.browserBean?.type) >= 0) {
      const { filteredData, retainedData } = this.filterBrowserData(groupId, datakey, data as BrowserValueType);
      if (filteredData.length > 0) {
        // this.parent.onRelevancesBatchDelete(filteredData);
      }
      return retainedData;
    }
    return data;
  }
}

export type SecretLevelStoreType = Pick<SecretLevelStore, keyof SecretLevelStore>;