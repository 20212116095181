import { Loadable, constants } from '../../lib';
import { SelectGroupType } from './types';

const { selectGroupClsPrefix } = constants
const SelectGroup = Loadable({
  name: 'SelectGroup', loader: () => import(
    /* webpackChunkName: 'ui_common' */
    './components/index')
}) as SelectGroupType;

SelectGroup.displayName = 'SelectGroup';

SelectGroup.defaultProps = {
  prefixCls: selectGroupClsPrefix,
  leftWidth: 100, // 默认100px
};

export type { SelectGroupProps, SelectGroupType, SelectGroupDatas } from './types';

export default SelectGroup;
