/**
 * 传入ck的配置相关处理
*/
import { appInfo, ua } from "@weapp/utils";
import { getCkLang, mathLabel, getImageUploadUrl } from './index';
import { mRichTextClsPrefix } from '../../../constants/index.m';
import { richTextClsPrefix } from '../../../constants/index';
import { getDefaultConfig, fontFamilys_en, fontFamilys_zh_cn, fontFamilys_zh, simpleToolBar } from '../constants';
import { RichTextProps, ExtendItemConfig } from '../types';

const SECONDPATCH_BACKEND = window.publicUrlapi || window.publicUrl || '';

// 根据系统多语言,匹配对应的字体下拉框选项
export const getRealFontNames = (font_zh_cn: string, font_zh: string, font_en: string) => {
  const language = getCkLang();
  return language === 'zh-cn' ? font_zh_cn : language === 'zh' ? font_zh : font_en;
}

// 根据系统多语言,匹配对应的默认字体
export const getRealFontDefaultLabel = (font_names: string, font_defaultLabel: string) => {
  const language = getCkLang();
  const { label, value } = mathLabel(font_defaultLabel);
  if ((language === 'zh-cn' || language === 'zh')) {
    if (font_defaultLabel.indexOf('/') > -1) {
      const fontNamesList = font_names.split(';');
      const realFontName = fontNamesList.find(item => {
        const { label: _label, value: _value } = mathLabel(item);
        return _value === value;
      });
      if (realFontName) {
        return mathLabel(realFontName).label;
      }
    } else {
      return label
    }
  }
  return value;
}

export const getEditorBasePath = () => {
  const { publicUrl, publicDomain } = appInfo('@weapp/ckeditor4') as any;
  return (publicDomain || '') + (publicUrl || '') + '/build/ckeditor4/';
}

export const getCssFilePath = () => {
  const { publicUrl, publicDomain } = appInfo('@weapp/ckeditor4') as any;
  return (publicDomain || '') + (publicUrl || '') + '/build/ui/style/iframe.css';
}

const getContentsCss = (outerCss?: string | string[], enableAI?: boolean) => {
  // 组件默认加载的公共css样式文件
  const deafaultContentsCss = window.CKEDITOR?.config?.contentsCss || '';
  let contentsCss = [deafaultContentsCss];
  if (Array.isArray(outerCss)) {
    // '/build/ckeditor4/contents.css'路径不再使用,并且业务模块不会加hash处理换成。兼容处理下
    const abandonUrl = '/build/ckeditor4/contents.css';
    const idx = outerCss.findIndex(i => i.indexOf(abandonUrl) > -1);
    if (idx > -1) {
      outerCss.splice(idx, 1);
    }
    contentsCss = contentsCss.concat(outerCss);
  } else if (outerCss) {
    contentsCss = contentsCss.concat([outerCss]);
  }

  // AI样式
  if(enableAI){
    contentsCss = contentsCss.concat([getCssFilePath()]);
  }
  return contentsCss;
}

// 抛出获取富文本最终传入ck的配置的方法,方便EB公用
export const getConfig = (props: RichTextProps) => {
  const { ckConfig, isMobile, loginFree, customImagePreviewUrl, extendsConfig } = props;
  const { font_defaultLabel, font_defaultColor, fontSize_defaultLabel, font_names_zh_cn, font_names_zh, font_names_en, bodyClass, contentsCss } = ckConfig;
  const enableAI = props.enableAI || (extendsConfig || []).some((item: ExtendItemConfig) => item.name === 'ai');
  
  const defaultConfig = getDefaultConfig();
  const placeholder = ckConfig?.editorplaceholder || defaultConfig.editorplaceholder;
  const enableBreakPlaceholder = placeholder.indexOf('\n') >= 0;
  // 编辑器配置转换
  let config = {
    ...defaultConfig,
    isMobile,
    maximizeClass: isMobile ? mRichTextClsPrefix : richTextClsPrefix, // 支持放大后继承组件的样式
    loginFree,
    uploadUrl: getImageUploadUrl(isMobile, loginFree),
    enableContextMenu: !isMobile, // 是否启用右键菜单
    useBrowserContextMenu: isMobile, // 是否使用原生右键菜单
    customImagePreviewUrl, // 自定义图片预览地址
    ...ckConfig,
    editorplaceholder: placeholder, // 支持提示语换行
    enableBreakPlaceholder,
    uploadParams: {
      module: 'document',
      createDoc: false,
      source: "richText",
      ...(ckConfig?.uploadParams || {})
    },
    contentsCss: getContentsCss(contentsCss, enableAI),
    colorButton_TextColor_Style: font_defaultColor, // 默认字体颜色面板回显
    bodyClass: `${isMobile ? 'cke__mobile' : 'cke__pc'} ${enableBreakPlaceholder ? 'cke_placeholder_wrap' : ''} ${bodyClass || ''}`,  };

  // PC显示工具栏情况,默认加上最大化工具栏, mobile端默认去掉全屏按钮(存在兼容性问题)
  const toolbar = config['toolbar']?.[0];
  if (toolbar && toolbar.length > 0) {
    const maxToolIdx = toolbar.indexOf('Maximize');
    let extraPlugins = config['extraPlugins'] || [];
    extraPlugins = typeof extraPlugins === 'string' ? extraPlugins?.split(',') : extraPlugins;
    const maxPluginIdx = extraPlugins.indexOf('maximize');
    if (!isMobile && maxToolIdx < 0) {
      config['toolbar'] = [[
        ...toolbar,
        'Maximize'
      ]]
      if (maxPluginIdx < 0) {
        config['extraPlugins'] = [...extraPlugins, 'maximize'];
      }
    }
    if (isMobile && maxToolIdx >= 0) {
      const newTools = toolbar.slice(0, maxToolIdx).concat(toolbar.slice(maxToolIdx + 1, toolbar.length));
      config['toolbar'] = [newTools];
      if (maxPluginIdx >= 0) {
        config['extraPlugins'] = extraPlugins.slice(0, maxPluginIdx).concat(extraPlugins.slice(maxPluginIdx + 1, extraPlugins.length));
      }
    }
  }

  // 字体下拉框选项：支持中文、繁体、英文不同的效果
  if (font_names_zh_cn || font_names_zh || font_names_en) {
    const realFontNames_zh_cn = font_names_zh_cn || fontFamilys_zh_cn;
    const realFontNames_zh = font_names_zh || fontFamilys_zh;
    const realFontNames_en = font_names_en || fontFamilys_en;
    config['font_names'] = getRealFontNames(realFontNames_zh_cn, realFontNames_zh, realFontNames_en)
  }
  // 默认字体：根据字体下拉列表读
  if (font_defaultLabel) {
    config['font_defaultLabel'] = getRealFontDefaultLabel(config['font_names'], font_defaultLabel);
  }
  if (fontSize_defaultLabel) { // 默认字号
    config['fontSize_defaultLabel'] = mathLabel(fontSize_defaultLabel).label;
  }
  // 处理二级路径
  if (SECONDPATCH_BACKEND && config.uploadUrl.indexOf(SECONDPATCH_BACKEND) === -1) {
    config.uploadUrl = SECONDPATCH_BACKEND + config.uploadUrl;
  }
  return config;
}

// 通过外部传入的extraPlugins匹配对应的ckeditor_[simple|full].js文件
// export const getCkEditor4JsType = (extraPlugins?: string | string[]) => {
//   if (!extraPlugins) return 'simple';
//   const pluginsArr = typeof extraPlugins === 'string' ? extraPlugins.split(',') : extraPlugins;
//   const fullPlugins = ckPluginsTypeMap['full'];
//   if (pluginsArr.length > 0 && pluginsArr.some(name => fullPlugins.indexOf(name) > -1)) {
//     return 'full'
//   }
//   return 'simple';
// }
// 通过外部传入的toolbar匹配对应的ckeditor_[simple|full].js文件
export const getCkEditor4JsType = (toolbar?: string | string[]) => {
  const toolList = Array.isArray(toolbar?.[0]) ? toolbar?.[0] : Array.isArray(toolbar) ? toolbar : [];
  if (toolList && toolList.length > 0 && toolList.some((tool: string) => simpleToolBar.indexOf(tool) < 0)) {
    return 'full'
  }
  return 'simple';
}