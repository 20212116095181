import { action, observable } from "mobx";
import { AnyObj } from "../../../types/common";
import { SearchAdvancedStoreType } from "./Store";

export default class RewriteApiStore {
  parent: SearchAdvancedStoreType;
  constructor(parent: SearchAdvancedStoreType) {
    this.parent = parent;
  }
  /**
   * 自定义方法，object方便后续扩展
   * 1.第一种：{url：function} 按照接口url拦截，用于处理接口参数
   * 2.第二种：{functionName：function}
   */
  @observable registerFunc: AnyObj = {};
  /**
   * registerCallBackFunc 自定义方法，object方便后续扩展
   * 配置规则如下，rewriteApiStore仅处理存储及执行自定义内容
   * {
   *   方法路径: {
   *      beforeFunc: [{ func: function, order: 0 }], 
   *      afterFunc: [{ func: function, order: 0 }],  // order做排序，预留，暂时没有用到
   *    }
   * }
   * 方法路径：从根store开始，如{commonFilterStore/checkSnaps}
   */
  @observable registerCallBackFunc: AnyObj = {};

  @action
  register = (params: AnyObj) => {
    this.registerFunc = {
      ...this.registerFunc,
      ...params,
    }
  }

  @action
  rewriteApiParams = (url: string, params: AnyObj, otherParams: AnyObj) => {
    return new Promise((resolve) => {
      const func = this.registerFunc[`${url}`];
      if (typeof func === 'function') resolve(func(params, otherParams));
      resolve(params);
    })
  }

  @action
  registerCallBack = (key: string, params: AnyObj) => {
    const nowRegisterCB = this.registerCallBackFunc[key] || [];
    nowRegisterCB.push(params);
    this.registerCallBackFunc = {
      ...this.registerCallBackFunc,
      [`${key}`]: nowRegisterCB,
    }
  }
  @action
  execCallBack = (path: string, params?: any) => {
    let key = '', funcName = '';
    if (path.match('afterFunc')) {
      key = path.replace('/afterFunc', '');
      funcName = 'afterFunc';
    } else if (path.match('beforeFunc')) {
      key = path.replace('/beforeFunc', '');
      funcName = 'beforeFunc';
    }
    const callBacks = this.registerCallBackFunc[key];
    if (callBacks) {
      callBacks.forEach((cbs: { [x: string]: (arg0: AnyObj | undefined) => any; }) => {
        cbs[funcName] && cbs[funcName](params);
      })
    }
  }

  @action
  clear = () => {
    this.registerFunc = {};
    this.registerCallBackFunc = {};
  }
}

export type RewriteApiStoreType = Pick<RewriteApiStore, keyof RewriteApiStore>;
