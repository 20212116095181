
import { USESKELETON } from '../../constants/index';
import Loadable, { LoadingType } from '../../react-loadable';
import { SliderProps, RangeProps, MSliderType } from './types';

const MSliderSkeleton: LoadingType<SliderProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MSlider = Loadable<SliderProps>({
  name: "MSlider", loading: MSliderSkeleton, loader: () => import(
    /* webpackChunkName: 'ui_m_common' */
    './Slider')
}) as MSliderType;

MSlider.MSliderSkeleton = MSliderSkeleton;

const MRange = Loadable<RangeProps>({
  name: "MRange", loading: MSliderSkeleton, loader: () => import(
    /* webpackChunkName: 'ui_m_common' */
    './Range')
});

const MProgress = Loadable<SliderProps>({
  name: "MProgress", loading: MSliderSkeleton, loader: () => import(
    /* webpackChunkName: 'ui_m_common' */
    './Progress')
})

let MSliderProps: SliderProps;
let MRangeProps: RangeProps;

interface CompoundedComponent extends React.ComponentClass<SliderProps> {
  MRange: typeof MRange;
  MProgress: typeof MProgress;
}

const InternalSlider = (MSlider as unknown) as CompoundedComponent;

InternalSlider.MRange = MRange;
InternalSlider.MProgress = MProgress;
export type { MSliderProps, MRangeProps };

export default InternalSlider;
