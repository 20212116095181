import { uiPcClsPrefix } from './index';

export const uiMClsPrefix = 'ui-m';

/* Button */
export const mBtnClsPrefix = `${uiMClsPrefix}-btn`;

/* Dialog */
export const mDialogClsPrefix = `${uiMClsPrefix}-dialog`;

/* List */
export const mListClsPrefix = `${uiMClsPrefix}-list`;

/* Menu */
export const mMenuClsPrefix = `${uiMClsPrefix}-menu`;

/* MMultiMenu */
export const mMultiMenuClsPrefix = `${uiMClsPrefix}-multi-menu`;

/* RichText */
export const mRichTextClsPrefix = `${uiMClsPrefix}-rich-text`;

/* MMap */
export const mMapClsPrefix = `${uiMClsPrefix}-map`;

/* Checkbox */
export const mCheckboxClsPrefix = `${uiMClsPrefix}-checkbox`;

/* Radio */
export const mRadioClsPrefix = `${uiMClsPrefix}-radio`;

/* switch */
export const mSwitchClsPrefix = `${uiMClsPrefix}-switch`;
/* Input */
export const mInputClsPrefix = `${uiMClsPrefix}-input`;

/* Textarea */
export const mTextareaClsPrefix = `${uiMClsPrefix}-textarea`;

/* Upload */
export const mUploadClsPrefix = `${uiMClsPrefix}-upload`;

/* Select */
export const mSelectClsPrefix = `${uiMClsPrefix}-select`;

/* Steps */
export const mStepsClsPrefix = `${uiMClsPrefix}-steps`;

/* Tag */
export const mTagClsPrefix = `${uiMClsPrefix}-tag`;

/* ColorPicker */
export const mColorPickerPrefix = `${uiMClsPrefix}-colorpicker`;

/* Calendar */
export const mCalendarClsPrefix = `${uiMClsPrefix}-calendar`;

/* Avatar */
export const mAvatarClsPrefix = `${uiMClsPrefix}-avatar`;

/* EChart */
export const mEChartClsPrefix = `${uiMClsPrefix}-echarts`;

/* Scope */
export const mScopeClsPrefix = `${uiMClsPrefix}-scope`;

/** Searchbar */
export const mSearchbarClsPrefix = `${uiMClsPrefix}-searchbar`;

/* Rate */
export const mRateClsPrefix = `${uiMClsPrefix}-rate`;

/* Carousel */
export const mCarouselClsPrefix = `${uiMClsPrefix}-carousel`;

/* Tree */
export const mTreeClsPrefix = `${uiMClsPrefix}-tree`;

/* Browser */
export const mBrowserClsPrefix = `${uiMClsPrefix}-browser`;

/* SearchAdvanced */
export const mSearchAdvancedClsPrefix = `${uiMClsPrefix}-searchAdvanced`;

/* ButtonGroup */
export const mButtonGroupClsPrefix = `${uiMClsPrefix}-buttonGroup`;

/* Comment */
export const mCommentClsPrefix = `${uiMClsPrefix}-comment`;

/* QuickMenu */
export const mQuickMenuClsPrefix = `${uiMClsPrefix}-quick-menu`;

/* toast */
export const mToastClsPrefix = `${uiMClsPrefix}-toast`;

/* ActionSheet */
export const mActionSheetClsPrefix = `${uiMClsPrefix}-action-sheet`;

/* PhotoView */
export const mPhotoViewClsPrefix = `${uiMClsPrefix}-photo-view`;

/* Signature */
export const mSignatureClsPrefix = `${uiMClsPrefix}-signature`;

/* PickerView */
export const mPickerViewClsPrefix = `${uiMClsPrefix}-picker-view`;

/* Picker */
export const mPickerClsPrefix = `${uiMClsPrefix}-picker`;

/* DateTimePicker */
export const mDateTimePickerClsPrefix = `${uiMClsPrefix}-date-time-picker`;

/* Emoticon */
export const mEmoticonClsPrefix = `${uiMClsPrefix}-emoticon`;

/* Collapse */
export const mCollapseClsPrefix = `${uiMClsPrefix}-collapse`;

/* CascaderList */
export const mCascaderListClsPrefix = `${uiMClsPrefix}-cascader-list`;

/* CardDetailForm */
export const mCardDetailFormClsPrefix = `${uiMClsPrefix}-card-detail-form`;

/* IconSelection */
export const mIconSelectionClsPrefix = `${uiMClsPrefix}-icon-selection`;
export const mRichIconSelectionClsPrefix = `${uiMClsPrefix}-rich-icon-selection`;

/* MattersAssociated */
export const mMattersAssociatedClsPrefix = `${uiMClsPrefix}-matters-associated`;

/* ListView */
export const mListViewClsPrefix = `${uiMClsPrefix}-list-view`;

/* FilterButtons */
export const mFilterButtonsClsPrefix = `${uiMClsPrefix}-filter-buttons`;

/* SoundRecording */
export const mSoundRecordingClsPrefix = `${uiMClsPrefix}-sound-recording`;

/* Video */
export const mVideoClsPrefix = `${uiMClsPrefix}-video`;

/* Audio */
export const mAudioClsPrefix = `${uiMClsPrefix}-audio`;

/* DateTimePicker */
export const mCascaderClsPrefix = `${uiMClsPrefix}-cascader`;

/* Auth */
export const mAuthClsPrefix = `${uiMClsPrefix}-auth`;

/* Share */
export const mShareClsPrefix = `${uiMClsPrefix}-share`;

/* VoiceToText */
export const mVoiceToTextClsPrefix = `${uiMClsPrefix}-voice-to-text`;

/* CommonPhrase */
export const mcommonPhraseClsPrefix = `${uiMClsPrefix}-common-phrase`;

/* MIconDropdown */
export const mIconDropdownClsPrefix = `${uiMClsPrefix}-icon-dropdown`;

export const mAssociateListClsPrefix = `${uiMClsPrefix}-associate-list`;

/* MCityPicker */
export const mCityPickerClsPrefix = `${uiMClsPrefix}-city-picker`;

/* MWorkStatus */
export const mWorkStatusClsPrefix = `${uiMClsPrefix}-work-status`;

export const mRecordingClsPrefix = `${uiMClsPrefix}-recording`;

/* MSecretSelect */
export const mSecretSelectClsPrefix = `${uiMClsPrefix}-secret-select`;

/* MImageCropper */
export const mImageCropperClsPrefix = `${uiMClsPrefix}-image-cropper`;

/* MRepeatFrequency */
export const mRepeatFrequencyClsPrefix = `${uiMClsPrefix}-repeat-frequency`;

/* MGridCheckbox */

export const mGridCheckboxClsPrefix = `${uiMClsPrefix}-grid-checkbox`;
