import Loadable from '../../react-loadable';
import { TreeType } from './types';

const MTree = Loadable({
  name: 'MTree',
  loader: () => import(
    /* webpackChunkName: "tree" */
    './Tree.m')
}) as TreeType;

export type { TreeProps as MTreeProps, ITreeOncheckHelpInfo as MITreeOncheckHelpInfo } from './types';
export type { ITreeData as MITreeData, TreeRootIdArray as MTreeRootIdArray } from './utils/common';

export default MTree;
