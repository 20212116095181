import { DialogConfirmProps, ConfirmType } from "./types";
import ReactDOM from "react-dom";
import Loadable from '../../../../react-loadable';

export const Confirm = Loadable({
  name: 'Confirm', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './Confirm')
}) as ConfirmType;

export function confirm(options: DialogConfirmProps) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function destroy() {
    const unmount = ReactDOM.unmountComponentAtNode(div);
    if (unmount && div.parentNode) {
      div.parentNode.removeChild(div);
    }
    options?.destroy?.();
  }
  ReactDOM.render(<Confirm weId={`${options.weId || ''}_ztj71q`} {...options} destroy={destroy} />, div);

  return {
    destroy
  };
}
