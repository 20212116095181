import { skeletonClsPrefix } from '../../constants/index';
import Skeleton from "./Skeleton";


Skeleton.defaultProps = {
  displayType: "column",
  prefixCls: skeletonClsPrefix,
}

export type { SkeletonProps, SkeletonSingleProps } from './types';

export default Skeleton;