import { mIconDropdownClsPrefix } from "../../constants/index.m";
import Loadable from "../../react-loadable";
import { MIconDropdownType } from "./type";

const MIconDropdown = Loadable({
  name: 'MIconDropdown',
  loader: () => import(
    /* webpackChunkName: "ui_m_common" */
    './MIconDropdown'
  )
}) as MIconDropdownType;

MIconDropdown.defaultProps = {
  prefixCls: mIconDropdownClsPrefix,
  icon: 'Icon-more02',
  iconProps: {
    size: 'lg',
  }
}

export type { MIconDropdownType, MIconDropdownProps, MIconDropdownDataType, MIconDropdownItemDataType } from './type';

export default MIconDropdown;