import * as React from 'react';

export type DirectionType = 'ltr' | 'rtl' | undefined;

export interface ConfigConsumerProps {
  getTargetContainer?: () => HTMLElement;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  rootPrefixCls?: string;
  iconPrefixCls?: string;
  getPrefixCls?: (suffixCls?: string, customizePrefixCls?: string) => string;
  autoInsertSpaceInButton?: boolean;
  input?: {
    autoComplete?: string;
  };
  pageHeader?: {
    ghost: boolean;
  };
  direction?: DirectionType;
  virtual?: boolean;
  dropdownMatchSelectWidth?: boolean;
}

export const ConfigContext = React.createContext<ConfigConsumerProps>({});

export const ConfigConsumer = ConfigContext.Consumer;
