import Loadable from '../../react-loadable';
import { tagClsPrefix } from '../../constants/index';
import { TagType } from './types';

const Tag = Loadable({ name: 'Tag', loader: () => import(
  /* webpackChunkName: "ui_common" */
  './Tag') }) as TagType;

Tag.defaultProps = {
  prefixCls: tagClsPrefix,
};

export type { TagProps } from './types';

export default Tag;