import ReactDOM from 'react-dom';
import Video from './index';
import { VideoProps } from './types';

export default function showVideo(src?: string, otherParams?: VideoProps) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function handleClose() {
    ReactDOM.unmountComponentAtNode(div);
    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }
    otherParams?.onClose?.()
  };
  const render = () => {
    ReactDOM.render(<Video weId={`liveox`}
      controls
      src={src}
      {...otherParams}
      dialogProps={{
        visible: true
      }}
      isDialog
      onClose={handleClose}
    />, div);
  };
  render();
}
