import Loadable from '../../react-loadable';
import { PhotoEditorType } from './types';
import { photoEditorClsPrefix } from '../../constants'

const PhotoEditor = Loadable({
  name: 'PhotoEditor', loader: () => import(
    /* webpackChunkName: 'photo-view' */
    './PhotoEditor')
}) as PhotoEditorType;

PhotoEditor.defaultProps = {
  prefixCls: photoEditorClsPrefix,
  canRotate: true,
  canScale: true
};

export type { PhotoEditorType, PhotoEditorProps } from './types';

export default PhotoEditor;
