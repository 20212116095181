import { ua } from "@weapp/utils";
import React, { CSSProperties, ComponentType, useMemo, useState, useEffect } from "react";
import { FormLayoutProps, FormStoreType } from '../index';
import { FormSingleItemProps, Skeleton, AnyObj, constants } from '../../../lib';

const { formClsPrefix } = constants;
export interface FormSkeletonRef {

}
export interface FormSkeletonProps {
  store: FormStoreType;
  children: any;
  adjuestHeight?: number; // 允许外部调整的高度值
  isMobile?: boolean;
  stopSkeleton?: boolean; // 强制取消骨架屏loading效果（用于Form初始化失败情况，外部可强制取消骨架屏loading效果）
  noLine?: boolean;
  weId?: any;
}

export type FormSkeletonType = ComponentType<FormSkeletonProps>;

// 固定默认高度配置
const minHeightCollection: AnyObj = {
  default: 44,
  'RATE': 56,
  'SIGNATURE': 100,
  'CUSTOM': 0, // 自定义字段暂不考虑
}

export const formSkeletonHeight = 300;
/**
 * 根据item配置返回默认高度
 * @param item 
 * @returns 
 */
export const getMinHeight = (item: FormSingleItemProps) => {
  const itemType = item?.itemType;
  const defaultHeight = minHeightCollection.default;
  if (!itemType) return defaultHeight;
  if (itemType === 'TEXTAREA') {
    const rows = item.rows || item.otherParams?.rows || 0;
    if (rows) return 19 + 25.5 * rows;
  } else if (itemType === 'RADIO' || itemType === 'CHECKBOX') {
    const data = item.data || item.otherParams?.data || item.options || item.otherParams?.options || [];
    const rows = data.length;
    if (rows) return defaultHeight * rows;
  } else if (itemType === 'RICHTEXT') {
    const ckConfig = item.ckConfig || item.otherParams?.ckConfig || {};
    return ckConfig.height || 200;
  } else if (itemType === 'DATETIMEPICKER') {
    const isRange = item.isRange || item.otherParams?.isRange;
    if (isRange) return defaultHeight * 2;
  } else if (itemType === 'FILTERBUTTONS') {
    const numberPerLine = item.numberPerLine || item.otherParams?.numberPerLine || 4;
    const data = item.data || item.otherParams?.data || item.options || item.otherParams?.options || [];
    const rows = Math.ceil(data.length / numberPerLine);
    return 42 * rows;
  }
  return (minHeightCollection[itemType] || defaultHeight);
}

export const getLabelMinHeight = (col: FormLayoutProps) => {
  // label占据一行(未考虑字体大小自适应调整的情况)
  if (col.labelSpan === 24) {
    return col.label ? 42 : 26;
  }
  return 0;
}

const skeletonStyle = { marginBottom: 5 };

const FormSkeleton = React.forwardRef<FormSkeletonRef, FormSkeletonProps>(
  (props, ref) => {
    const { children, adjuestHeight, isMobile, stopSkeleton, noLine, store: { isFormInit, layout, items, customHides } } = props;
    const [firstCal, setFirstCal] = useState(true);
    const [minHeight, setMinHeight] = useState(0);
    const [appear, setAppear] = useState(0);
    const [appearHeight, setAppearHeight] = useState(0);
    const [isAllCustomFields, setIsAllCustomFields] = useState(true);
    const _isMobile = isMobile || ua.device !== 'PC';

    useEffect(() => {
      // 卸载Form数据
      if (!isFormInit) {
        setFirstCal(true);
        setMinHeight(0);
        setAppear(0);
        setAppearHeight(0);
        setIsAllCustomFields(true);
      }
    }, [isFormInit]);

    let appId = 0, appea = 0;;
    function changeHeight () {
      appea ++;
      setAppear(appea);
      window.cancelAnimationFrame(appId);
      const timeout = setTimeout(() => {
        setAppear(2); // 清除height，防止height计算错误导致内容显示不全
        clearTimeout(timeout);
      }, 500);
    }

    function doCal() {
      let minHeight = 0, appearHeight = 0, needAnimation = 0;
      if (layout.length > 0 && items) {
        layout.forEach((row, index) => {
          let maxHeight = 0, allHide = true;
          row.forEach((col) => {
            !(col.hide || customHides[col.id]) && col.items.forEach((itemKey) => {
              const itemType = items[itemKey]?.itemType;
              allHide = false;
              let colHeight = 0;
              if (itemType !== 'CUSTOM' && isAllCustomFields) setIsAllCustomFields(false);
              if (itemType) {
                if (!_isMobile && itemType !== 'CUSTOM') colHeight = 40; // pc暂定每行最小高度40
                else if (_isMobile){
                  colHeight += getLabelMinHeight(col);
                  colHeight += getMinHeight(items[itemKey]);
                }
              }
              if (maxHeight <= colHeight) maxHeight = colHeight; // 同一行取最高
              needAnimation ++;
            })
          })
          minHeight += maxHeight;
          appearHeight += maxHeight;
          // if (maxHeight === 0 && !allHide && !isMobile) { // 整行均为自定义字段(pc+仅一行一列的情况，其他情况暂不考虑)
          //   appearHeight += 40;
          // } 
          if (!noLine && !allHide) {
            appearHeight += 1;
            if (index === 0) appearHeight += 1;
          }
        })
        if (adjuestHeight) { minHeight += adjuestHeight; appearHeight += adjuestHeight; } // adjuestHeight 允许外部单独调整高度
        setMinHeight(minHeight);
        setFirstCal(false);
        setAppearHeight(appearHeight); // 自定义字段多的场景，appearHeight计算不准确，动画效果不好(adjuestHeight可调)
        if (needAnimation >= 3) { // 超过2行才需要动画
          appId = window.requestAnimationFrame(changeHeight);
        } else {
          setAppear(2);
        }
      }
    }

    
    if (isFormInit && firstCal) {
      doCal();
    }

    const style = useMemo(() => {
      let sty: CSSProperties = {};
      if (layout?.length === 0) {
        sty.height = 'auto';
      } else if (appear === 0) {
        sty.height = 80;
      } else if (appear === 1) {
        sty.height = appearHeight;
      } else {
        sty.height = 'auto';
      }
      return sty;
    }, [appearHeight, appear, layout]);

    const layoutLen = layout?.length || 0;
    const height = _isMobile || !isFormInit ? formSkeletonHeight : 40 * layoutLen;
    const skeletonProps = _isMobile ? {} : { singleHeight: 40 };

    const skeletonContainerCls = `${formClsPrefix}-skeleton-container`;

    return <>
      {!stopSkeleton && (!isFormInit || (!minHeight && !isAllCustomFields)) ? (
        <Skeleton weId={`${props.weId || ''}_eny6qz`} skeletonHeight={height} span={1} style={skeletonStyle} {...skeletonProps} />
      ) : appear > 1 ? children : (
        <div style={style} className={skeletonContainerCls}>
          {children}
        </div>
      )}
    </>
  }
)

FormSkeleton.displayName = "FormSkeleton";

export default FormSkeleton;


/**
 * minHeight 计算规则：遍历layout
 * 1.hide !== false 隐藏的字段不计高度
 * 2.itemType === 'CUSTOM' 自定义字段不计高度
 * 3.若Form整体设置了colSpan，以colSpan为准 ----- 暂未处理
 * 4.label高度：pc 40 / mobile: 42（36: 无label，也可为0，不影响） labelSpan === 24 时使用
 * 5.wrapper高度：pc 40(待定) / mobile: 按照配置计算
 */