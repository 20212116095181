/** 获取系统密级信息 url */
export const GETSECLEVELURL = '/api/bs/datasecurity/classifiedprotection/getResourceInfo4Component';

/** 保密期限校验规则 */
// export const CHECKVALIDITY = '((永久)|(^(\\d)+(天|个月|年)))$';
// export const CHECKVALIDITY = "^\\d*[^\\d]+\\d*$";
export const CHECKVALIDITY = '';

/** ★ 号编码 */
export const starSymbol = '\u2605'
