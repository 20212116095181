import { Component } from "react";

class Layout extends Component {

  static displayName: 'GridLayout';

  render() {
    return (
      null
    );
  }
}

export default Layout;
