import React, { FC, ReactElement, RefAttributes, useContext, useMemo} from 'react';
import {classnames} from '@weapp/utils';
import RowContext from './RowContext';
import { ColProps, FlexType } from './types';
import { layoutClsPrefix } from '../../constants/index';
import Block from '../block/index';

function parseFlex(flex: FlexType): string {
  if (typeof flex === 'number') {
    return `${flex} ${flex} auto`;
  }

  if (/^\d+(\.\d+)?(px|em|rem|%)$/.test(flex)) {
    return `0 0 ${flex}`;
  }

  return flex;
}

const Col: FC<ColProps & RefAttributes<any>> = (props) => {
  const {
    span,
    order,
    offset,
    push,
    pull,
    className,
    children,
    flex,
    style,
    customRender,
    ...others
  } = props;
  const { gutter, wrap } = useContext(RowContext);

  const prefixCls = `${layoutClsPrefix}-col`;

  const classes = classnames(
    prefixCls,
    {
      [`${prefixCls}-${span}`]: span !== undefined,
      [`${prefixCls}-order-${order}`]: order,
      [`${prefixCls}-offset-${offset}`]: offset,
      [`${prefixCls}-push-${push}`]: push,
      [`${prefixCls}-pull-${pull}`]: pull,
    },
    className,
  );

  const mergedStyle = useMemo(() => {
    let mergedStyle: React.CSSProperties = style || {};
    if (gutter) {
      mergedStyle = {
        ...(gutter[0]! > 0
          ? {
              paddingLeft: gutter[0]! / 2,
              paddingRight: gutter[0]! / 2,
            }
          : {}),
        ...(gutter[1]! > 0
          ? {
              paddingTop: gutter[1]! / 2,
              paddingBottom: gutter[1]! / 2,
            }
          : {}),
        ...mergedStyle,
      };
    }
    if (flex) {
      mergedStyle.flex = parseFlex(flex);
  
      if (flex === 'auto' && wrap === false && !mergedStyle.minWidth) {
        mergedStyle.minWidth = 0;
      }
    }
    return mergedStyle;
  }, [gutter, flex, wrap, style]);

  const content = (
    <Block weId={`${props.weId || ''}_ctk8l3`} {...others} style={mergedStyle} className={classes}>
      {children}
    </Block>
  );
  return customRender ? customRender(this, content) as ReactElement : content;
}

Col.displayName = 'Col';

export default Col;
