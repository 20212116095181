import React from 'react';
import { action, observable, toJS } from "mobx";
import { getLabel, request, RequestOptionConfig } from '@weapp/utils';
import { OperatesType, PermissionType, DisableColumnType } from '../types';
import { ITableColumn } from '../../table/index';
import { AnyObj } from '../../../types/common';
import { FormDatas, FormLayoutType, FormStore } from '../../form';
import { FormItemProps } from '../../form-item';

export const defaultDisableColumn = {
  hasright: false,
  disableColumnList: [],
  saveUrl: '',
  helpTip: []
}

const getStoreAsync = () => import(
  /* webpackChunkName: "listview" */
  './StoreAsync'
);
export default class Store<Data = any> {
  @observable loading: boolean = true
  @observable asyncList: RequestOptionConfig = {}
  @observable asyncCounts: RequestOptionConfig = {}
  @observable asyncOperates: RequestOptionConfig = {}

  // table 配置
  @observable rowKey: string = 'id';
  @observable tableType: string = 'NONE';
  @observable data: ReadonlyArray<Data> = [];
  @observable columns: ITableColumn[] = [];
  @observable initColumns: ITableColumn[] = [];
  @observable checkedKeys: string[] = [];
  @observable displayData: ReadonlyArray<Data> = [];
  @observable showOperates: boolean = true;   // 是否显示操作列
  @observable operates: OperatesType[] = [];
  @observable operatesPermission: PermissionType[][] = [];
  @observable checkBoxPermission: PermissionType[] = [];
  @observable ellipsis: boolean = false
  @observable useKeyInRequest: boolean = false  // 异步权限operates 接口是否传入主键参数 非默认data
  @observable operateColumnWidth: string = '' // 操作列列宽

  // 分页配置
  @observable pageSize: number = 0
  @observable current: number = 1
  @observable total: number = 0;
  @observable paginationType: string = 'part'
  @observable hasMore: boolean = false
  @observable autoJumpNextPage: boolean = false
  @observable totalLoading: boolean = false

  // 显示列定制dialog配置
  @observable needTableColSet: boolean = true
  @observable originalColumns: ITableColumn[] = [];
  @observable module: string = '';
  @observable moduleFormProps: string = '';
  @observable visible: boolean = false;
  @observable pageUid: string = '';
  @observable columnsNumConfig: { min?: number | string, max?: number | string } = { min: 0, max: Infinity }
  @observable disableColumn: DisableColumnType = defaultDisableColumn

  // 显示列同步设置dialog 相关配置
  @observable formStore = new FormStore();
  @observable querySyncInfo: AnyObj = {}
  @observable dialogType: string = ''
  @observable updateSyncTitle: string = ''
  @observable updateColDialogVisible: boolean = false
  @observable logVisible: boolean = false
  @observable isResetCol: boolean = false

  // 默认排序设置dialog配置
  @observable sortDialogVisible: boolean = false

  // getData时 是否返回所有response
  @observable needAllResFormProps: boolean | null = null;

  // 区分显示列定制数据更新来源：列定制 or 默认排序
  @observable sourceOfTableColUpdate: 'differ' | 'same' = 'same'

  @action
  setState = (params: any, callback?: () => void) => {
    Object.keys(params).forEach((key) => {
      if (key in this) {
        // @ts-ignore
        this[key] = params[key];

        if (key == 'data' || key == 'displayData') {
          this.loading = false
        }
      }
    });

    callback && callback()
  }

  @action
  initForm = () => {
    const { isDefault = false, syncObject = [], defaultObject = [], showSubCom = false } = this.querySyncInfo
    const objKey = this.dialogType === 'updateSyncTableCol' ? 'syncObject' : 'defaultObject'
    const data: FormDatas = this.dialogType === 'updateSyncTableCol' ?
      { isDefault, syncObject } :
      this.dialogType === 'resetTableCol' ? { defaultObject } : {}

    let otherParamsOptions = [
        {
          "id": "user",
          "content": getLabel('29511', '人员'),
          "messageId": 0,
          "disabled": false,
          "separator": false
        },
        {
          "id": "dept",
          "content": getLabel('52148', '部门'),
          "messageId": 0,
          "disabled": false,
          "separator": false
        },
        showSubCom ? {
          "id": "subcompany",
          "content": getLabel('63384','分部'),
          "messageId": 0,
          "disabled": false,
          "separator": false
        } : null,
        // {
        //   "id": "group",
        //   "content": getLabel('31518','群组'),
        //   "messageId": 0,
        //   "disabled": false,
        //   "separator": false
        // },
        {
          "id": "all",
          "content": getLabel('59739', '所有人'),
          "messageId": 0,
          "disabled": false,
          "separator": false
        },
      ].filter(i => !!i)

    let items: FormItemProps = {
      [objKey]: {
        itemType: 'TYPESBROWSER',
        required: true,
        typesBrowserBean: { // TYPESBROWSER 对应 typesBrowserBean
          type: 'hrmcombination',
          module: 'hrm',
        },
        otherParams: {
          autoFocus: true,
          displayInput: true,
          hideAvatar: false,
          multiple: true,
          options: otherParamsOptions
        }
      }
    }

    let layout: FormLayoutType[] = [
      [{ id: '1', label: this.dialogType === 'updateSyncTableCol' ? getLabel('206949','同步范围')  : getLabel('206950','设置范围') , items: [objKey], labelSpan: 9, hide: false }],
    ];

    if (this.dialogType === 'updateSyncTableCol') {
      items = { ...items, isDefault: { itemType: 'SWITCH' } }
      layout.push([{ id: '2', label: getLabel('206951','同时设为默认显示列')  , items: ['isDefault'], labelSpan: 9, hide: false }])
    }

    this.formStore.initForm({ data, items, groups: [], layout });
  }

  @action
  queryTableCol = (pageUid: string, _module: string, asyncListRequestConfig?: RequestOptionConfig): any => {
    return getStoreAsync()?.then(action((defaultModule) => defaultModule?.queryTableCol?.(this, pageUid, _module, asyncListRequestConfig)));
  }

  @action
  querySyncTableCol = (pageUid: string, _module: string, asyncListRequestConfig?: RequestOptionConfig): any => {
    return getStoreAsync()?.then(action((defaultModule) => defaultModule?.querySyncTableCol?.(this, pageUid, _module, asyncListRequestConfig)));
  }

  /* 显示列设置 */
  @action
  addTableCol = (data: { columns: object }, operateType: string): any => {
    return getStoreAsync()?.then(action((defaultModule) => defaultModule?.addTableCol?.(this, data, operateType)));
  }

  @action
  resetTableCol = (operateType: string): any  => {
    return getStoreAsync()?.then(action((defaultModule) => defaultModule?.resetTableCol?.(this, operateType)));
  }

  getRealColumns = (tableColResult: any, columns: ITableColumn[]) => {
    if (tableColResult && tableColResult.status && tableColResult.data && Array.isArray(tableColResult.data) && tableColResult.data.length > 0) {
      let realColumns: any[] = []
      const tableColData: ITableColumn[] = tableColResult.data

      columns.forEach((i: any) => {
        const column = tableColData.filter(column => column.dataIndex === i.dataIndex)[0] || undefined
        const order: number = column ? tableColData.findIndex(column => column.dataIndex === i.dataIndex) : realColumns.length

        realColumns.push({ ...i, ...column, order })
      })

      return realColumns.sort((a, b) => {
        return a?.['order'] - b['order']
      })
    } else {
      return columns
    }
  }

  @action
  getData = (
    asyncList: RequestOptionConfig,
    asyncCounts: RequestOptionConfig = {},
    asyncOperates: RequestOptionConfig = {},
    isAdd: boolean = false,
    getDataCallBack?: (apiData: any, tableColResult: any, updateData: any, asyncList: RequestOptionConfig) => void
  ): Promise<any> => {
    return getStoreAsync()?.then(action((defaultModule) => defaultModule?.getData?.(this, asyncList, asyncCounts, asyncOperates, isAdd, getDataCallBack)));
  }

  @action
  changePageSettings = (size: any, callBack?: () => void): any => {
    return getStoreAsync()?.then(action((defaultModule) => defaultModule?.changePageSettings?.(this, size, callBack)));
  }

  getRequestConfig = (options: RequestOptionConfig) => {
    const { method, params } = options
    // `data` 是作为请求主体被发送的数据
    // 只适用于这些请求方法 'PUT', 'POST', 和 'PATCH'
    // 在没有设置 `transformRequest` 时，必须是以下类型之一：
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - 浏览器专属：FormData, File, Blob
    // - Node 专属： Stream
    const needTransMethod = ['post', 'POST', 'put', 'PUT', 'patch', 'PATCH']

    if (method && needTransMethod.indexOf(method) > -1 && options.params && !options.data) {
      options.data = params
      delete options.params
    }

    return options
  }

  getRealConfig = (config: RequestOptionConfig) => {
    const newConfig: RequestOptionConfig = {
      ...config,
      url: config.url,
      method: config.method,
    }

    if (config.method === 'get' || config.method === 'GET') {
      newConfig.params = config.params
    } else if (config.method === 'post' || config.method === 'POST') {
      newConfig.data = config.params
      delete newConfig.params
    }

    return newConfig
  }

  requestData = (asyncCounts: RequestOptionConfig, asyncOperates: RequestOptionConfig): any => {
    return getStoreAsync()?.then(action((defaultModule) => defaultModule?.requestData?.(this, asyncCounts, asyncOperates)));
  }
}

export type StoreType = Pick<Store, keyof Store>;