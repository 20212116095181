import { mVoiceToTextClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { MVoiceToTextType } from './types';

const MVoiceToText = Loadable({ name: 'MVoiceToText', loader: () => import(
  /* webpackChunkName: "voice_to_text" */
  './VoiceToText') })  as MVoiceToTextType;

MVoiceToText.defaultProps = {
  prefixCls: mVoiceToTextClsPrefix,
  icon: "Icon-Voice-to-text01",
  needAudio: false,
  showIcon: true,
};

export type { MVoiceToTextProps, MVoiceToTextType } from './types';

export default MVoiceToText;