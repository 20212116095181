import Loadable from '../../react-loadable';
import { atClsPrefix } from '../../constants/index';
import { AtType } from './types';

const At = Loadable({ name: 'At', loader: () => import(
  /* webpackChunkName: "ui_common" */
  './At') }) as AtType;

At.defaultProps = {
  prefixCls: atClsPrefix,
};

export type { AtProps, AtItemData } from './types';

export default At;
