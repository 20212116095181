import Loadable from '../../react-loadable';
import { MenuContentType, MenuType } from './types';
import { menuClsPrefix } from '../../constants/index';
import { COMMON_MENU_GROUP_ID } from './constants';

const Menu = Loadable({
  name: 'Menu', loader: () => import(
    /* webpackChunkName: "ui_frequent" */
    './pc/Menu')
}) as MenuType;

const MenuContent = Loadable({
  name: 'MenuContent', loader: () => import(
    /* webpackChunkName: "ui_frequent" */
    './public/MenuContent'
  )
}) as MenuContentType;

Menu.defaultProps = {
  prefixCls: menuClsPrefix,
};

Menu.MenuContent = MenuContent;
Menu.COMMON_MENU_GROUP_ID = COMMON_MENU_GROUP_ID;
export type { MenuProps, MenuItemData, MenuContentProps } from './types';

/* --------------------- 事项群聊所需参数 --------------------- */
export type { EntityGroupUser, CreateEntityGroupParamsType, EntityGroupParamsType } from './types';

// export { menuEmit, menuListener } from './util/menuEvent';
const Sensitive = () => import(
  /* webpackChunkName: "ui_frequent" */
  './util/sensitive');

export { Sensitive };
export type { SensitiveType } from './util/sensitive';

const EntityGroupChat = () => import(
  /* webpackChunkName: "ui_frequent" */
  './util/entityGroupChat'
);
export { EntityGroupChat };
export type { EntityGroupChatType } from './util/entityGroupChat';

export default Menu;

// 复制粘贴
const CopyAndPaste = () => import(
  /* webpackChunkName: "ui_frequent" */
  './util/copyAndPaste');

  export { CopyAndPaste };
  export type { CopyAndPasteType } from './util/copyAndPaste';
  export type { ActiveElementProps } from './types';