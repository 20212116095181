import * as React from 'react';
import { PopupInnerRef, PopupProps } from '../types';
import PopupInner from './PopupInner';

const Popup = React.memo(React.forwardRef<PopupInnerRef, PopupProps>(
  (props, ref) => {
    const { prefixCls, stopPopupPropagation } = props;

    const onPopupClick = React.useCallback((e: React.MouseEvent) => {
      if (stopPopupPropagation) e.stopPropagation();
    }, [stopPopupPropagation]);

    return (
      <div className={`${prefixCls}-popupInter-container`} onClick={onPopupClick}>
        <PopupInner weId={`${props.weId || ''}_lumem0`} {...props} ref={ref} />
      </div>
    )
  }
));


Popup.displayName = 'Popup';

export default Popup;