import Loadable from '../../react-loadable';
import { BoardType } from './types';

const Board = Loadable({
  name: 'Board',
  loader: () => import(/* webpackChunkName: 'ui_common_less' */ './Board'),
}) as BoardType;

export type { BoardProps, BoardList as BoardData } from './types';
export default Board;
