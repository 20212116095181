import { DateLimitedConfigType, DateType } from '../../types';
import { dayjs } from '@weapp/utils';


export function getLimitDate(dateLimitedConfig?: DateLimitedConfigType) {
  let _minDate: DateType = ''
  let _maxDate: DateType = ''
  let valid = true
  if (dateLimitedConfig) {
    const { minDate, maxDate } = dateLimitedConfig

    //先对min和max进行格式化处理
    let min = minDate
    let max = maxDate
    if (typeof (minDate) === 'function') min = minDate()
    if (typeof (maxDate) === 'function') max = maxDate()

    _minDate = (!!min && dayjs(min as DateType).isValid() ? dayjs(min as DateType) : '')
    _maxDate = (!!max && dayjs(max as DateType).isValid() ? dayjs(max as DateType) : '')

  }
  if (_minDate && _maxDate) {
    valid = dayjs(_minDate).isSameOrBefore(_maxDate)
  }

  return {
    minDate: _minDate,
    maxDate: _maxDate,
    valid
  }
}