import { SecretSelectProps } from './types';
import { CHECKVALIDITY } from './constants';
import { constantsM, constants } from '../../lib';

const { secretSelectClsPrefix } = constants;
const { mSecretSelectClsPrefix } = constantsM;


export const bathDefaultProps: SecretSelectProps = {
  /** 密级校验规则 */
  checkValidity: CHECKVALIDITY,
  autoValidate: true,
  filterLevelType: 'up'
}

/** 密级组件（pc）默认值 */
export const secretSelectDefaultProps: SecretSelectProps = {
  ...bathDefaultProps,
  prefixCls: secretSelectClsPrefix,
}

/** 密级组件（mb）默认值 */
export const mSecretSelectDefaultProps: SecretSelectProps = {
  ...bathDefaultProps,
  prefixCls: mSecretSelectClsPrefix,
}
