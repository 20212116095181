import Loadable from '../../react-loadable';
import { editableTableClsPrefix } from '../../constants/index';
import { EditableTableType } from './types';

const EditableTable = Loadable({ name: 'EditableTable', loader: () => import(
  /* webpackChunkName: "editableTable" */
  './EditableTable') }) as EditableTableType;

EditableTable.defaultProps = {
  prefixCls: editableTableClsPrefix,
  displayLocationForRequiredMarkPriority: 'global',
  newValidateTipType: false,
};

export type { EditableTableProps, TableColumn as EditableTableColumn, ToolItemConfig, ComKeyType, EditableTableCellType, EditableTableValidatorType
, DisplayLocationForRequiredMarkPriority as EditableTableDisplayLocationForRequiredMarkPriority } from './types';

export default EditableTable;
