import { dayjs } from '@weapp/utils'
import { DateType, TitleDateChangeType, DatePickerProps } from '../../types'
import { isEqual } from '../../../../utils/index';

/**
 * @description: 根据受控value、是否是多选、是否为范围选择、是否为特殊的季度选择来获取相应的标题日期
 * @param {DateType} value
 * @param {DatePickerProps} props
 * @return {*}
 */
export function getTitleDate(value: DateType | DateType[], props: DatePickerProps) {
  const { type, isRange } = props
  let titleDate: DateType | undefined = undefined
  if (isRange) {
    //范围选择标题日期直接依据开始日期
    if (Array.isArray(value)) {
      titleDate = value[0]
    }
  } else {
    //如果为多选，标题日期依据最后一个日期
    if (Array.isArray(value)) {
      const date = value[value.length - 1]
      if (date !== void 0) {
        if (type === 'seasons' || type === 'halfyears') {
          if (typeof date === 'string' && date !== '') {
            titleDate = dayjs().year(Number(date.substring(0, 4)))
          }
        } else {
          titleDate = date
        }
      }
    } else {
      //如果为单选，则直接相等
      if (type === 'season' || type === 'halfyear') {
        if (typeof value === 'string' && value !== '') {
          titleDate = dayjs().year(Number(value.substring(0, 4)))
        }
      } else {
        titleDate = value
      }
    }
  }
  return titleDate
}

/**
 * @description: 根据开始日期或者结束日期以及聚焦状态计算出对应的结束日期与开始日期
 * @param {DateType} value
 * @param {*} type
 * @param {boolean} isEndFocus
 * @return {*}
 */
export function computeStartEndTitleDate(value: DateType, type: 'year' | 'month' | 'day', isEndFocus?: boolean) {
  let date: DateType = dayjs()
  function getMinus(value: number, flag: boolean) {
    return flag ? -value : value
  }
  if (type === 'year') {
    date = dayjs(value).year(dayjs(value).year() + getMinus(10, !!isEndFocus))
  } else if (type === 'month') {
    date = dayjs(value).year(dayjs(value).year() + getMinus(1, !!isEndFocus))
  } else {
    date = dayjs(value).month(dayjs(value).month() + getMinus(1, !!isEndFocus))
  }
  return date
}

/**
 * @description: 计算面板标题日期
 * @param {string} value
 * @param {*} type
 * @return {*}
 */
export function computeTitleDate(value: string[], type: 'year' | 'month' | 'day', isEndFocus?: boolean) {
  let [s, e]: any[] = value
  //先根据开始日期和结束日期的不限状态进行一次格式化
  if (isEqual(value, []) || isEqual(value, ['', ''])) {
    //不限开始与不限结束，则无视isEndFocus，只需要根据此刻的日期改变结束面板日期即可
    s = dayjs()
    e = computeStartEndTitleDate(dayjs(), type, false)
  } else if (s !== '' && e === '') {
    //不限结束(date->无穷)
    e = computeStartEndTitleDate(dayjs(), type, false)
  } else if (s === '' && e !== '') {
    //不限开始(无穷->date)
    s = dayjs()
  } else {
    //date->date
    [s, e] = [s, e]
  }
  //然后根据聚焦状态(以开始日期还是结束日期为准来进行相应的调整)
  if (!isEndFocus) {
    e = computeStartEndTitleDate(dayjs(s), type, false)
  } else {
    s = computeStartEndTitleDate(dayjs(e), type, true)
  }
  return {
    startTitleDate: dayjs(s),
    endTitleDate: dayjs(e),
  }
}


/**
 * @description: 通过传进来的变化key来获取改变后的titleDate
 * @param {TitleDateChangeType} type
 * @param {DateType} titleDate
 * @return {*}
 */
export function getNewTitleDate(type: TitleDateChangeType, titleDate: DateType) {
  let d = titleDate ? dayjs(titleDate) : dayjs()
  switch (type) {
    case 'next10Year':
      d.add(10, 'year')
      break
    case 'last10Year':
      d.subtract(10, 'year')
      break
    case 'nextYear':
      d.add(1, 'year')
      break
    case 'lastYear':
      d.subtract(1, 'year')
      break
    case 'nextMonth':
      d.add(1, 'month')
      break
    case 'lastMonth':
      d.subtract(1, 'month')
      break
  }
  return d
}
