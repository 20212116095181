import { Loadable, constants } from '../../lib';
import { InputAtType, InputNumberType, InputType, InputWrapType, PasswordType } from './types';

const { inputClsPrefix } = constants

const Input = Loadable({
  name: 'Input', loader: () => import(
    /* webpackChunkName: 'ui_form' */
    './Input')
}) as InputType;

const InputWrap = Loadable({
  name: 'InputWrap', loader: () => import(
    /* webpackChunkName: 'ui_form' */
    './InputWrap')
}) as InputWrapType;

const Password = Loadable({
  name: 'Password', loader: () => import(
    /* webpackChunkName: 'ui_form' */
    './Password')
}) as PasswordType;

const InputNumber = Loadable({
  name: 'InputNumber', loader: () => import(
    /* webpackChunkName: 'ui_form' */
    './InputNumber')
}) as InputNumberType;

const InputCleave = Loadable({
  name: 'InputCheav', loader: () => import(
    /* webpackChunkName: 'ui_form' */
    'cleave.js/react'
  )
});

const InputAt = Loadable({
  name: 'InputAt', loader: () => import(
    /* webpackChunkName: 'ui_form' */
    './InputAt')
}) as InputAtType;

Input.defaultProps = {
  prefixCls: inputClsPrefix,
  autoComplete: "off"
}

Password.defaultProps = {
  prefixCls: inputClsPrefix,
  autoComplete: "new-password"
}

InputAt.defaultProps = {
  prefixCls: inputClsPrefix,
}

Input.InputAt = InputAt;

Input.Password = Password;

const MAX_SAFE_INTEGER = 9007199254740991;
const MIN_SAFE_INTEGER = -9007199254740991;

InputNumber.defaultProps = {
  prefixCls: `${inputClsPrefix}-number`,
  hideOps: true,
  step: 1,
  autoComplete: "off",
  max: MAX_SAFE_INTEGER,
  min: MIN_SAFE_INTEGER
};

Input.InputNumber = InputNumber;
Input.InputWrap = InputWrap;

export type { InputProps, ValueType, InputType,InputNumberProps, InputAtProps } from './types';

export { InputWrap, Password, InputNumber, InputCleave, InputAt };

export default Input;
