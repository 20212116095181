import { dialogClsPrefix } from '../../../constants/index';

export const DIALOG_DEFAULT_WIDTH_MIDDLE = 500;

export const DIALOG_MIDDLE_MAX_COEFFICIENT = 0.9;   // 居中弹窗最大宽高（基于当前屏幕大小）

export const DIALOG_MIDDLE_DEFAULT_HEIGHT_COEFFICIENT = 0.7;  // 居中弹窗默认高度

export const DIALOG_MIDDLE_DEFAULT_WIDTH_COEFFICIENT = 0.7;    // 居中弹窗默宽度

export const DIALOG_MIDDLE_MIN_WIDTH = 380;  // 居中弹窗默认最小宽度

export const DIALOG_MIDDLE_MIN_HEIGHT = 200; // 居中弹窗默认最小高度

export const DIALOG_DEFAULT_DRAGGABLE_PADDING = 100;

export const DIALOG_DEFAULT_MIN_TOP_MIDDLE = 20;

export const DIALOG_DEFAULT_TOP_MIDDLE = 100;

export const DIALOG_SCALE_WIDTH_95 = '95%';

export const DIALOG_SCALE_HEIGHT_95 = '95%';

export const DIALOG_SCALE_WIDTH_100 = '100%';

export const DIALOG_SCALE_HEIGHT_100 = '100%';

export const DIALOG_SCALE_TOP = '2.5%';

export const DIALOG_SCALE_LEFT = '2.5%';

export const EVENT_VISIBLE_CHANGE = `${dialogClsPrefix}-visible-change`;