import Loadable from '../../react-loadable';
import { PopoverProp } from './types';
import { popoverClsPrefixCls } from '../../constants/index';
import { create } from './showPopover';
const Popover = Loadable({ name: 'Popover', loader: () => import(
   /* webpackChunkName: "ui_common" */
  './Popover') }) as PopoverProp;

Popover.defaultProps = {
  prefixCls: popoverClsPrefixCls,
}

Popover.create = create;

export type { PopoverProps, PopoverOptions, PopoverType } from './types';

export { Popover };

export default Popover;
