import { rightMenuClsPrefix } from '../../../constants';
export  enum CssClass {
  menu = 'ui-right-menu-context',
  animationWillEnter = 'context_willEnter-',
  animationWillLeave = 'context_willLeave-',
}

export  enum EVENT {
  HIDE_ALL,
}

export const NOOP = () => {};

export const hideOnEvents: (keyof GlobalEventHandlersEventMap)[] = [
  'resize',
  'contextmenu',
  'click',
  'scroll',
  'blur',
];
