import { createContext, Context } from 'react';
import { BoxItemType } from './types';

export type LayoutContextState = (type: BoxItemType, outer: boolean, right: boolean, hidden: boolean) => void;

export const LayoutContext: Context<LayoutContextState> = createContext((type: BoxItemType, outer: boolean, right: boolean, hidden: boolean) => {});

export interface RowContextState {
  gutter?: [number, number];
  wrap?: boolean;
}

const RowContext: Context<RowContextState> = createContext({});

export default RowContext;
