import Loadable from '../../react-loadable';
import { TreeType } from './types';

const Tree = Loadable({ name: 'Tree', loader: () => import(
    /* webpackChunkName: "tree" */
    './Tree') }) as TreeType;

export type { TreeProps, ITreeOncheckHelpInfo, TreeCheckboxProps } from './types';
export type { ITreeData, TreeRootIdArray } from './utils/common';

export { appendDataFromNode } from './utils/common';

export default Tree;