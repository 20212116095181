import { Component } from "react";
import { LoadableComponent } from "../cors-link";
import { EcodeComponentProps } from "./types";

export interface CorsComponentState {
  loaded: boolean;
  componentId: string;
  module?: any;
  LoadedComponent?: React.ComponentType<EcodeComponentProps>;
}

class EcodeComponent extends Component<EcodeComponentProps, CorsComponentState> {
  constructor(props: EcodeComponentProps) {
    super(props);
    this.state = {
      loaded: false,
      componentId: `${props.app}.${props.compName}`,
      LoadedComponent: undefined,
    };
  }

  static getDerivedStateFromProps(nextProps: EcodeComponentProps, prevState: CorsComponentState) {
    const componentId = `${nextProps.app}.${nextProps.compName}`;
    const newState: Partial<CorsComponentState> = {};
    if (prevState.componentId !== componentId) {
      newState.loaded = false; // 需要重新请求组件
      newState.componentId = componentId;
    }
    return newState;
  }

  componentWillUnmount() {
    const { unmount } = this.props;
    unmount?.();
  }

  callComponent = (props?: EcodeComponentProps) => {
    const currProps = props || this.props;
    try {
      window.weappEcodesdk?.asyncImport?.(currProps.appId, currProps.filePath || 'entry.js')?.then(
        (Module: any) => {
          const newState: Partial<any> = { loaded: true, module: Module };
          if (Module[currProps.compName || 'default']) {
            const Comp = Module[currProps.compName || 'default'];
            newState.LoadedComponent = LoadableComponent(Comp);
          }
          currProps.onReady?.(Module);
          this.setState(newState as CorsComponentState);
        }
      );
    } catch (err: any) {
      console.error(err);
      currProps.onError?.(err);
    }
  }

  render() {
    const { customRender } = this.props;
    const { loaded, module, LoadedComponent } = this.state;
    if (!loaded) {
      this.callComponent();
      return null;
    }

    if (customRender) {
      return customRender(module, LoadedComponent);
    }

    return LoadedComponent ? (
      <LoadedComponent weId={`${this.props.weId || ''}_hrxz5m`} {...this.props} />
    ) : null;
  }
}

export default EcodeComponent;
