import { MSignatureProps, MSignatureType } from './types';
import { LoadingType, Loadable, constants, constantsM } from '../../lib';

const { USESKELETON } = constants;
const { mSignatureClsPrefix } = constantsM
const MSignatureSkeleton: LoadingType<MSignatureProps> = () => USESKELETON && <div style={{ height: 100 }}></div>

const MSignature = Loadable({
  name: 'MSignature',
  loading: MSignatureSkeleton,
  loader: () => import(
    /* webpackChunkName: "m_signature" */
    './MSignature'
  )
}) as MSignatureType;

MSignature.MSignatureSkeleton = MSignatureSkeleton;

MSignature.defaultProps = {
  prefixCls: mSignatureClsPrefix,
  mode: 'name',
  inForm: false,
  showScaleControl: true,
  canvasBarShowClear: true,
  showHorizontalInitPlaceholder: true,
  showHorizontalTitle: true,
  showDelete: true,
};

export type { MSignatureProps, MSignatureType } from './types';


export default MSignature;
