import { ShareListProps, ShareBtnOptionsType, MShareListProps } from '../types';
import { getLabel } from "@weapp/utils";


function overwriteProps(originProps: any, targetPrpos: any | undefined, cover?: boolean) {
  if (Object.prototype.toString.call(originProps) === '[object Array]') {
    if (Object.prototype.toString.call(targetPrpos) === '[object Array]') {
      return cover
        ? [
          ...targetPrpos,
          ...originProps,
        ]
        : [
          ...originProps,
          ...targetPrpos,
        ];
    } else {
      return originProps;
    }
  } else if (Object.prototype.toString.call(originProps) === '[object Object]') {
    return cover
      ? {
        ...targetPrpos,
        ...originProps,
      }
      : {
        ...originProps,
        ...targetPrpos,
      };
  }
}

export function shareListMiddleware(props: ShareListProps): ShareListProps {
  const newProps: ShareListProps = { ...props };

  // 按钮配置
  if (!newProps.btnOptions) {
    const btnOptions: ShareBtnOptionsType[] = [
      {
        id: 'delete',
        icon: 'Icon-Batch-delete',
        title: getLabel('19635', '批量删除'),
        disabled: false,
        isMenu: true,
        isBatchOperator: true,
      }, {
        id: 'top',
        icon: 'Icon-Back-to-top',
        title: getLabel('223156', '批量移至最前'),
        disabled: false,
        isMenu: true,
        isBatchOperator: true,
      },
      {
        id: 'add',
        icon: 'Icon-add-to03',
        title: getLabel('59674', '添加'),
        disabled: false,
      }
    ]
    newProps.btnOptions = overwriteProps(btnOptions, newProps.btnOptions);
  }


  // 后端不支持排序，先临时屏蔽
  newProps.sortable = false;
  newProps.topVisible = false;
  newProps.topVisibleType = 'disabled';

  return newProps;
}

export function mshareListMiddleware(props: MShareListProps): MShareListProps {
  const newProps: MShareListProps = { ...props };

  // 后端不支持排序，先临时屏蔽
  newProps.sortable = false;
  newProps.topVisible = false;
  newProps.topVisibleType = 'disabled';

  return newProps;
}