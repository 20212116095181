import { getLabel } from "@weapp/utils";
import { UploadProps } from '../types';

export function uploadMiddleware(props: UploadProps): UploadProps {
  const newProps: UploadProps = { ...props };

  // 文档做编辑的插件优化，要求编辑接口默认添加 editLinkType=editPage 参数
  if (!newProps.editParams) {
    newProps.editParams = {
      editLinkType: 'editPage'
    };
  } else {
    if (!('editLinkType' in newProps.editParams)) {
      newProps.editParams.editLinkType = 'editPage';
    }
  }

  if (newProps.listType === 'img' && !newProps.limitType) {
    newProps.limitType = 'jpg,jpeg,png,gif,giff,tif,tiff,bmp,webp'
  }

  return newProps;
}

export function muploadMiddleware(props: UploadProps): UploadProps {
  const newProps: UploadProps = { ...props };

  if (!newProps.uploadTitle) {
    newProps.uploadTitle = getLabel("59802", "请点击此处上传");
  }

  // 文档做编辑的插件优化，要求编辑接口默认添加 editLinkType=editPage 参数
  if (!newProps.editParams) {
    newProps.editParams = {
      editLinkType: 'editPage'
    };
  } else {
    if (!('editLinkType' in newProps.editParams)) {
      newProps.editParams.editLinkType = 'editPage';
    }
  }
  
  if (newProps.listType === 'img' && !newProps.limitType) {
    newProps.limitType = 'jpg,jpeg,png,gif,giff,tif,tiff,bmp,webp'
  }

  return newProps;
}