import { Component } from 'react';
import { DialogConfig, DialogProps, DialogState } from './types';
import DialogWrap from './dialog/DialogWrap';
import DialogContainer from './public/DialogContainer';
import { getRandom, dispatchEvent as _dispatchEvent } from '../../utils/index';
import { EVENT_VISIBLE_CHANGE } from './constant/index';
import { default as _Dialog } from './index';
import { disabledUserSelect, enableUserSelect, getDefaultDraggable } from './util';
import React from 'react';

class Dialog extends Component<DialogProps, DialogState> {
  static dialogConfig: DialogConfig = {};
  dialogId?: string;
  visible: boolean | undefined;
  dialogContainerRef = React.createRef<any>();
  componentDidMount() {
    this.dialogId = `dialog_${getRandom()}`;
    this.dispatchEvent(this.props.visible || false);
    this.props.visible && this.changeUserSelect(true); // 默认关闭状态无需处理
  }
  getSnapshotBeforeUpdate() {
    if (this.visible !== !!this.props.visible) {
      this.dispatchEvent(this.props.visible || false);
      this.changeUserSelect(this.props.visible || false);
    }
  }
  componentWillUnmount() {
    this.dispatchEvent(false);
    this.changeUserSelect(false);
  }
  dispatchEvent = (visible?: boolean) => {
    this.visible = visible;
    let sort = 0
    if(visible){
      if(_Dialog.dialogList.length>0){
        const count = _Dialog.dialogList.reduce((max,obj)=>max.sort>obj.sort?max:obj)
        sort = count.sort  + 1 //从1开始计
      }
      _Dialog.dialogList.push({
        id:this.dialogId||'',
        sort,
        ref:this.dialogContainerRef 
      })
    }else{
      _Dialog.dialogList = _Dialog.dialogList.filter((i)=>i.id !== this.dialogId)
    }
    /* 自定义事件监听: 旧方法，不再维护 */
    _dispatchEvent(EVENT_VISIBLE_CHANGE, {
      visible: visible,
      dialogId: this.dialogId,
    });
    /* 自定义事件监听 */
    _dispatchEvent(EVENT_VISIBLE_CHANGE, {
      visible: visible,
      dialogId: this.dialogId,
    });
  }
  changeUserSelect = (visible?: boolean) => {
    const { draggable: _draggable, resize, placement } = this.props;
    /* 拖拽/宽度可变的场景，打开弹框，禁止弹框外 user-select */
    const draggable = getDefaultDraggable(_draggable, placement)
    if (draggable || resize) {
      if (visible) disabledUserSelect();
      else enableUserSelect();
    }
  }
  onAfterClose = () => {
    this.props.path && this.dispatchEvent(false);
    this.props.path && this.changeUserSelect(false);
    this.props?.onAfterClose?.();
  }
  onAfterShow = () => {
    this.props.path && this.dispatchEvent(true);
    this.props.path && this.changeUserSelect(true);
    this.props?.onAfterShow?.();
  }
  onClose = () => {
    const { onClose, onAfterClose } = this.props;
    this.dialogContainerRef?.current?.replacePath?.();
    if (typeof onClose === 'function') onClose();
    const afterClose = setTimeout(() => {
      if (typeof onAfterClose === 'function') onAfterClose();
      if (afterClose) clearTimeout(afterClose);
    }, 500); // 动画延迟
  }
  getPosition = () => {
    const { top: topProps, left: leftProps, right: rightProps, bottom: bottomProps, placement } = this.props;
    const nowPlacement = placement || 'middle';
    if ((typeof _Dialog.dialogConfig.placement === 'string' && _Dialog.dialogConfig.placement === nowPlacement) || (
      _Dialog.dialogConfig.placement instanceof Array && _Dialog.dialogConfig.placement.indexOf(nowPlacement) >= 0
    )) {
      return {
        top: topProps || _Dialog.dialogConfig?.top,
        left: leftProps || _Dialog.dialogConfig?.left,
        right: rightProps || _Dialog.dialogConfig?.right,
        bottom: bottomProps || _Dialog.dialogConfig?.bottom,
      }
    }
    return {
      top: topProps,
      left: leftProps,
      right: rightProps,
      bottom: bottomProps,
    }
  }
  getInstance = (el: any) => this.dialogContainerRef = el;
  render() {
    const { prefixCls, className, style, children, visible, path, forceRender, getContainer, customRenderDialogBody, dialogConfig, ...resProps } = this.props;
    const { top, left, right, bottom } = this.getPosition();
    const dialogId = this.props.dialogId || this.props.weId || `dialog_${getRandom()}`;
    return (
      <DialogContainer weId={`${this.props.weId || ''}_9sboj9`}
        prefixCls={prefixCls}
        className={className}
        style={style}
        path={path}
        visible={visible}
        forceRender={forceRender}
        getContainer={getContainer}
        onAfterShow={this.onAfterShow}
        onAfterClose={this.onAfterClose}
        getInstance={this.getInstance}
      >
        <DialogWrap weId={`${this.props.weId || ''}_i6m9o0`}
          prefixCls={prefixCls}
          visible={!!path || visible}
          className={className}
          style={style}
          {...resProps}
          onClose={this.onClose}
          dialogId={dialogId}
          top={top}
          left={left}
          right={right}
          bottom={bottom}
          dialogConfig={dialogConfig || _Dialog.dialogConfig}
        >
          {customRenderDialogBody ? customRenderDialogBody?.(this, children) : children}
        </DialogWrap>
      </DialogContainer>
    )
  }
}
export default Dialog;
