import { MShareListProps } from './../types';
import { isObservable, toJS } from 'mobx';
import { request, RequestOptionConfig } from "@weapp/utils";
import { AnyObj, FormInitAllDatas, FormLayoutType, FormDatas, FormItemProps, HrmCard } from '../../../lib';
import { needSync } from "../../../utils";
import { BROWSERESULTCONST, SHAREBROWSERKEYS, TYPESBROWSERKEYS, SHAREBROWSEROBJTYPES, SHAREBROWSERHRMTYPES } from '../../browser/PropsType';
import { ShareListProps } from '../types';

const { openHrmCard, openHrmSP } = HrmCard;

/**
 * 分享到对象的类型
 * 对应表（https://e-cloudstore.com/doc.html?appId=8afb167492de4d2cb4f746b4daad4912）
 * @param formDatas any
 */
export const getTargetType = (formDatas?: any) => {
  const objType = formDatas?.objType;
  let code = 0;
  switch (objType) {
    case 'resource':  // 人力资源
      code = 1;  // 默认（人力资源）
      break;
    case 'department':  // 部门
      if (formDatas?.department_attribute?.includes("subordinate")) {  // 部门+所有下级部门
        code = 3;
      } else {  // 默认（部门+安全级别）
        code = 2
      }
      break;
    case 'subcompany':  // 分部
      if (formDatas?.department_attribute?.includes("subordinate")) {  // 分部+所有下级分部
        code = 6;
      } else {  // 默认（分部+安全级别）
        code = 5
      }
      break;
    case 'role':  // 角色
      // 角色+总部（0）/分部（1）/部门（2）/分部以上（3）/部门以上（4）+安全级别 - 都是10，后端通过 role_level 区分
      code = 10;
      break;
    case 'group':  // 群组
      if (formDatas?.seclevel) {
        code = 14;  // 群组+安全级别
      } else {
        code = 1411;  // 默认（群组）
      }
      break;
    case 'position':  // 岗位
      if (formDatas?.position_level === '1') {
        code = 1311;  // 岗位+总部
      } else if (formDatas?.position_level === '2') {
        code = 13;  // 岗位+指定分部
      } else if (formDatas?.position_level === '3') {
        code = 12;  // 岗位+指定部门
      } else {
        code = 1311;  // 默认（岗位+总部）
      }
      break;
    case 'all':  // 所有人
      code = 11;  //  所有人+安全级别
      break;
    case 'external':  // 外部联系人
      code = 110;  // 人力资源-外部人员
      break;
    case 'allExternal':
      code = 1101;  // 所有外部人+安全级别
      break;
    default:  // 异常，无匹配数据
      code = 0;
  }
  return code;
}


/** 分享到对象的 id */
export const getTargetId = (formDatas?: any) => {
  const OBJ_ID_KEY = 'obj_id' + (formDatas.objType === "resource" ? '' : `_${formDatas.objType}`)
  let targetid = formDatas[OBJ_ID_KEY]?.map((item: any) => item.id).join(',')
  if (!targetid) targetid = 0;
  return targetid;
}

export const getHrmCardType = (data: any) => {
  let cardType = '';
  let openType = '';
  let type = '';
  if (SHAREBROWSERKEYS.OBJTYPE in data) {
    type = SHAREBROWSERKEYS.OBJTYPE;
  } else if (TYPESBROWSERKEYS.ENTITYTYPE in data) {
    type = TYPESBROWSERKEYS.ENTITYTYPE;
  }
  if (data && type) {
    // 外部 内部 部门 分部 群组 岗位 已支持
    // 角色 暂无小卡片
    if (data[type] === SHAREBROWSEROBJTYPES.ORGANIZATION || data[type] === SHAREBROWSERHRMTYPES.ORGANIZATION) {
      cardType = 'department';
      openType = `openHrmSP`;
    } else if (data[type] === SHAREBROWSEROBJTYPES.RESOURCE || data[type] === SHAREBROWSERHRMTYPES.RESOURCE) {
      cardType = 'inside';
      openType = `openHrmCard`;
    } else if (data[type] === SHAREBROWSEROBJTYPES.GROUP || data[type] === SHAREBROWSERHRMTYPES.GROUP) {
      cardType = 'group';
      openType = `openHrmCard`;
    } else if (data[type] === SHAREBROWSEROBJTYPES.DEPARTMENT || data[type] === SHAREBROWSERHRMTYPES.DEPARTMENT) {
      cardType = 'department';
      openType = `openHrmCard`;
    } else if (data[type] === SHAREBROWSEROBJTYPES.SUBCOMPANY || data[type] === SHAREBROWSERHRMTYPES.SUBCOMPANY) {
      cardType = 'subcompany';
      openType = `openHrmCard`;
    } else if (data[type] === SHAREBROWSEROBJTYPES.EXTERNAL || data[type] === SHAREBROWSERHRMTYPES.EXTERNAL) {
      cardType = 'external';
      openType = `openHrmCard`;
    } else if (data[type] === SHAREBROWSEROBJTYPES.JOB || data[type] === SHAREBROWSERHRMTYPES.JOB) {
      cardType = 'position';
      openType = `openHrmCard`;
    } else if (data[type] === SHAREBROWSEROBJTYPES.ROLE || data[type] === SHAREBROWSERHRMTYPES.ROLE) {
      cardType = 'role';
      openType = `openHrmCard`;
    }
  }
  return { cardType, openType };
}

export const openObjSpanHrmCard = (data: any, event: React.MouseEvent) => {
  let type = '';
  let id = '';
  if (SHAREBROWSERKEYS.OBJTYPE in data) {
    type = SHAREBROWSERKEYS.OBJTYPE;
    id = data.targetId;
  } else if (TYPESBROWSERKEYS.ENTITYTYPE in data) {
    type = TYPESBROWSERKEYS.ENTITYTYPE;
    id = data.id;
  }
  if (data && type) {
    // 外部 内部 部门 分部 群组 岗位 已支持
    // 角色 暂无小卡片
    if (data[type] === SHAREBROWSEROBJTYPES.ORGANIZATION || data[type] === SHAREBROWSERHRMTYPES.ORGANIZATION) {
      openHrmSP(event, id, 'department', 'bs/hrm', { path: 'card' });
    } else if (data[type] === SHAREBROWSEROBJTYPES.RESOURCE || data[type] === SHAREBROWSERHRMTYPES.RESOURCE) {
      openHrmCard(event, id, 'inside', 'bs/hrm', { path: 'card' });
    } else if (data[type] === SHAREBROWSEROBJTYPES.GROUP || data[type] === SHAREBROWSERHRMTYPES.GROUP) {
      openHrmCard(event, id, 'group', 'bs/hrm', { path: 'card' });
    } else if (data[type] === SHAREBROWSEROBJTYPES.DEPARTMENT || data[type] === SHAREBROWSERHRMTYPES.DEPARTMENT) {
      openHrmCard(event, id, 'department', 'bs/hrm', { path: 'card' });
    } else if (data[type] === SHAREBROWSEROBJTYPES.SUBCOMPANY || data[type] === SHAREBROWSERHRMTYPES.SUBCOMPANY) {
      openHrmCard(event, id, 'subcompany', 'bs/hrm', { path: 'card' });
    } else if (data[type] === SHAREBROWSEROBJTYPES.EXTERNAL || data[type] === SHAREBROWSERHRMTYPES.EXTERNAL) {
      openHrmCard(event, id, 'external', 'bs/hrm', { path: 'card' });
    } else if (data[type] === SHAREBROWSEROBJTYPES.JOB || data[type] === SHAREBROWSERHRMTYPES.JOB) {
      openHrmCard(event, id, 'position', 'bs/hrm', { path: 'card' });
    } else if (data[type] === SHAREBROWSEROBJTYPES.ROLE || data[type] === SHAREBROWSERHRMTYPES.ROLE) {
      openHrmCard(event, id, 'role', 'bs/hrm', { path: 'card' });
    }
  }
}

/**
 * 获取编辑布局
 * @param condition 编辑 condition（后台只更新了 data，layout 布局需要重新补充下）
 * @param module 模块标识
 * @returns condition
 */
export const getEditCondition = async (condition: FormInitAllDatas, params: AnyObj) => {
  const { module, showVoiceToTextBtn } = params;
  const { data, layout, items } = condition;
  let _data: FormDatas = isObservable(data) ? toJS(data) : data, _layout: FormLayoutType[] = [], _items: FormItemProps = { ...items };
  const keys = Object.keys(data);

  try {
    // 获取新的 items（将对象类型/对象设置为只读，更新 browser 语音转文字设置）
    const reg = /objType|obj_id/
    for (const key in _items) {
      if (Object.prototype.hasOwnProperty.call(_items, key)) {
        if (key.match(reg)) {
          _items[key].readOnly = true;
        } else if (_items[key].itemType === 'BROWSER') {
          if (showVoiceToTextBtn && !_items[key].readOnly && !_items[key].disabled) {
            _items[key].showVoiceToTextBtn = showVoiceToTextBtn;
          }
        }
      }
    }

    // 获取新的 data（主要是通过浏览按钮接口去获取完整的浏览按钮数据）
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (items[key]?.itemType === 'BROWSER' && (data[key] as any[]).length > 0) {
        let selectIds: string = '';
        if (typeof (data[key] as any[])[0] === 'string') {
          selectIds = (data[key] as []).join(',') || (data.targetId as string);
        } else {
          selectIds = (data[key] as []).map((item: any) => item.id).join(',')
        }
        const config: RequestOptionConfig = {
          url: `/api/${items[key]?.browserBean?.module}/common/browser/destData/${items[key]?.browserBean?.type}`,
          method: 'POST',
          data: {
            [BROWSERESULTCONST.BROWSER_MULT_DEST_SELECTIDS]: selectIds,
          }
        }
        // 1. 接口通过 id 获取浏览按钮数据
        const res_browser = await request(config);
        // 2. 填充浏览按钮数据
        _data[key] = res_browser?.data?.data || [];
      }
    }

    // 通过 objType 配置联动展示效果(Form 初始化不会执行 cascadeRules，因此编辑时需要特殊处理下)
    let type = data['objType'] as string;
    const objTypeLayout = layout.filter(ly => ly[0].id === 'objType');
    // 多行字段联动信息（objType）
    const { show, hide } = objTypeLayout?.[0]?.[0]?.['cascadeRulesOuter']?.['objType'][type];
    // 获取新的 layout
    _layout = layout.map((array: any) => {
      return array.map((l: any) => {
        // 通过 objTypeLayout 的联动配置，来调整初始布局
        let _l = { ...l }
        if (show.includes(_l.id)) {
          _l.hide = false
        } else if (hide.includes(_l.id)) {
          _l.hide = true;
        }
        // 简单行内字段联动配置
        if (_l.id !== 'objType' && _l.items?.length && _l.cascadeRules) {
          // cascadeRules 的 逻辑是根据 items 顺序查找
          const cascadeRules = _l['cascadeRules'];

          let index = 0;
          let newItems: string[] = [];
          let preItmes = _l['items'];

          // 从 preItmes 的第一个 item 开始，将当前的 item 放入 newItems 中
          // 如果有匹配到联动，就调整 preItems，然后匹配下一位 preItems 的 item
          while (index < preItmes.length) {
            const itemKey = preItmes[index];
            newItems.push(itemKey);
            const dataKey = data[itemKey] as string;
            if (dataKey && itemKey in cascadeRules && dataKey in cascadeRules[itemKey]) {
              const option = cascadeRules[itemKey][dataKey];
              const cascadeItems = Array.isArray(option) ? option : (option?.['items'] || []);
              preItmes = newItems.concat(cascadeItems);
            }
            index++;
          }
          _l['items'] = newItems;
        }
        return _l;
      })
    })

    // 返回新的 condition
    let _condition: FormInitAllDatas = {
      ...condition,
      data: _data,
      layout: _layout,
      items: _items
    }

    return _condition;
  } catch (error) {
    throw error;
  }
}

export const getAuthCondition = async (condition: FormInitAllDatas, params: AnyObj) => {
  const { module, showVoiceToTextBtn } = params;
  const { data, layout, items } = condition;
  let _data: FormDatas = data, _layout: FormLayoutType[] = layout, _items: FormItemProps = { ...items };
  const keys = Object.keys(data);
  try {
    // 获取新的 items（将对象类型/对象设置为只读，更新 browser 语音转文字设置）
    const reg = /obj_id/
    for (const key in _items) {
      if (Object.prototype.hasOwnProperty.call(_items, key)) {
        // 浏览按钮类型
        if (_items[key].itemType === 'BROWSER') {
          if (showVoiceToTextBtn && !_items[key].readOnly && !_items[key].disabled) {
            _items[key].showVoiceToTextBtn = showVoiceToTextBtn;
          }
        }
      }
    }

    // 返回新的 condition
    let _condition: FormInitAllDatas = {
      ...condition,
      data: _data,
      layout: _layout,
      items: _items
    }

    return _condition;
  } catch (error) {
    throw error;
  }
}

/**
 * 判断数组 arr1 是否包含数组 arr2
 * @param arr1 string[]
 * @param arr2 string[]
 * @returns boolean
 */
export const includes = (arr1: string[], arr2: string[]) => {
  return arr2.every(val => arr1.includes(val));
}

/**
 * 从 props 中同步数据到 store
 * @param props ShareListProps
 * @param keys string[]
 * @returns newState
 */
export function getStoreParamsFormProps<T extends ShareListProps | MShareListProps>(props: T, keys: string[]): Partial<T> {
  const { store } = props;
  const cProps: Partial<T> = {};
  keys.forEach(k => {
    if (needSync(k, props) && (k in store)) {
      const key = k as keyof Partial<T>;
      // @ts-ignore
      if (props[key] !== store[key]) {
        cProps[key] = props[key] as any;
      }
    }
  });
  return cProps;
}
