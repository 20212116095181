import React, { Suspense, ComponentType, forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';
import { corsImport, getSyncHook, middleware } from '@weapp/utils';
import { uiAppName } from './constants/index';
import { AnyObj } from './types/common';

const noop = () => null;

const formatApp = (m: string) => /^@weapp/.test(m) ? m : `@weapp/${m}`;

export type LoaderType<P extends AnyObj = any> = () => Promise<{
  default: React.ComponentType<P>;
}>;

export type LoadingType<P extends AnyObj = any> = ReactNode | ((props: P) => ReactNode);

export interface LoadableComponentProps<P extends AnyObj = any> {
  appName?: string;
  name: string;
  component?: React.ComponentType<P>;
  loader?: LoaderType<P>;
  loading?: LoadingType<P>;
  propsMiddleware?: (props: P) => any;
  prefetch?: string | string[];
  // render?: FunctionComponent;
};

const components = new Map();

export function AsyncLoadable<P extends AnyObj = any> ({ loader }: { loader: LoaderType<P> }) {
  const Com = React.lazy(loader);
  return React.forwardRef<unknown, any>((props, ref) => {
    return (
      <Com {...props} weId={`${props.weId || ''}_j2dytx`} ref={ref} wrappedComponentRef={props.wrappedComponentRef || ref} />
    )
  });
}

function Loadable<P extends AnyObj = any> (opts: LoadableComponentProps<P>): ComponentType<P> {

  const hooks = getSyncHook('weappUi', 'Loadable.opt.overwrite');
  if (hooks) {
    opts = hooks(opts) || opts;
  }

  const { appName = uiAppName, name = 'Test', loader, component, propsMiddleware, loading, prefetch } = opts;

  const cacheName = `${appName}.${name}`;
  if (components.has(cacheName)) {
    throw new Error(`[React Loadable]: name 字段已经使用过 '${cacheName}'，请检查重复！`);
  }
  components.set(cacheName, 1);

  if (loader) {

    const mergedLoader = () => {
      if (Array.isArray(prefetch)) {
        const promises = prefetch.map(m => corsImport(formatApp(m)));
        return Promise.all(promises).then(loader);
      } else if (typeof prefetch === 'string') {
        return corsImport(formatApp(prefetch)).then(loader);
      } else {
        return loader();
      }
    }
    const Com = middleware(appName, name)(React.lazy(mergedLoader));

    const LoadableComponent: ForwardRefRenderFunction<unknown, any> = (props, ref) => {
      const newProps = propsMiddleware?.(props) || props;
      const loadingEle = (typeof loading === 'function' ? loading(props) : loading) || noop;
      return (
        <Suspense weId={`${props.weId || ''}_ql6g4a`} fallback={loadingEle}>
          <Com weId={`${props.weId || ''}_j2dytt`} {...newProps} ref={ref} wrappedComponentRef={props.wrappedComponentRef || ref} />
        </Suspense>
      );
    };
  
    LoadableComponent.displayName = "LoadableComponent";
  
    const newCom = forwardRef(LoadableComponent);
    // newCom.displayName = name;
    return newCom;
  } else if (component) {
    const newCom = middleware(appName, name)(component);
    // newCom.displayName = name;
    return newCom;
  } else {
    throw new Error('[React Loadable]: 必须设置 loader 或 component ！');
  }
}

export default Loadable;
