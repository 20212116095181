import { request, RequestOptionConfig, RequestPromise } from "@weapp/utils";
import { ReactText } from "react";
import { AnyObj } from "../../types/common";
import { BrowserValueType } from '../browser/index';
import { FormValue } from '../form/index';
import { SearchAdvancedStoreType } from "./store/Store";
import { QuikSearchPanelList } from "./types";

export interface CommonCondition {
  conditionId: string,
  layoutId: string,
  open: boolean,
  sort: number,
}
export interface SnapsParams {
  name?: FormValue,
  content: any,
  conditionId: string,
  id?: string,
  searchValue?: any,
  isPublic?: boolean,
  isDefault?: boolean,
  range?: BrowserValueType,
  snapDescription?: ReactText,
}

export interface AllUrls {
  [key: string]: string,
}

export const allUrls: AllUrls = {
  queryCommonConditions: '/{module}/search/queryCommonConditions',
  updateCommonConditions: '/{module}/search/updateCommonConditions',
  queryHistories: '/{module}/search/queryHistories',
  clearHistory: '/{module}/search/clearHistory',
  addToHistory: '/{module}/search/addToHistory',
  deleteHistory: '/{module}/search/deleteHistory',
  saveAsSnaps: '/{module}/search/saveAsSnaps',
  querySnapsName: '/{module}/search/querySnapsName',
  queryDefaultSnaps: '/{module}/search/queryDefaultSnaps',
  deleteSnap: '/{module}/search/deleteSnap',
  querySnapsDetail: '/{module}/search/querySnapsDetail',
  updateSnaps: '/{module}/search/updateSnaps',
  queryPersonalMatchWords: '/{module}/search/queryPersonalMatchWords',
  updatePersonalMatchWords: '/{module}/search/updatePersonalMatchWords',
}

export const getUrlModule = (type: string) => allUrls[type]

export const getUrls = (type: string, module: string, isMobile?: boolean) => {
  // 统一处理接口中的模块标示
  const patt = /{module}/;
  const url = patt.test(allUrls[type]) ? allUrls[type].replace(patt, (module || 'public')!) : allUrls[type];
  if (isMobile) return `/api/app${url}`;
  return `/api${url}`;
}

export const queryCommonConditions = (url: string, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'get',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 更新用户自定义查询条件
export const updateCommonConditions = (url: string, data: CommonCondition[], params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'post',
    data,
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 查询所有历史记录
export const queryHistories = (url: string, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'get',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 清空历史记录
export const clearHistory = (url: string, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'post',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 保存历史搜索记录
export const addToHistory = (url: string, history: QuikSearchPanelList, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'post',
    data: {
      ...history,
    },
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 删除历史记录
export const deleteHistory = (url: string, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'post',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 存为常用筛选
export const saveAsSnaps = (url: string, snaps: SnapsParams, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'post',
    data: { ...snaps },
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 获取常用筛选名称
export const querySnapsName = (url: string, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'get',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 获取当前人默认常用筛选
export const queryDefaultSnaps = (url: string, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'get',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 删除常用筛选
export const deleteSnap = (url: string, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'post',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 获取具体的常用筛选内容

export const querySnapsDetail = (url: string, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'get',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 更新常用筛选条件

export const updateSnaps = (url: string, data: QuikSearchPanelList[], params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'post',
    data,
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

// 常用筛选公用权限校验

export const checkPermission = (data: object, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url: `/api/auth/menu/check-permission`, method: 'POST', data, ...params 
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

/* -----  快捷搜索设置条件 ----- */

export const get = (url: string, params?: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'get',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}

export const post = (url: string, params: AnyObj, store?: SearchAdvancedStoreType) => {
  const options: RequestOptionConfig = {
    url,
    method: 'post',
    ...params,
  }
  const doRequest = store?.rewriteApiStore?.registerFunc?.fetchData || request;
  return doRequest(options) as RequestPromise;
}