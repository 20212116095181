import Loadable from '../../react-loadable';
import { AnchorListType, AnchorLinkType }  from './types';
import AnchorList from './AnchorList'
//
// const AnchorList = Loadable({ name: 'AnchorList', loader: () => import(
//     /* webpackChunkName: "anchorlist" */
//     './AnchorList') }) as AnchorListType;
//
// const AnchorLink = Loadable({ name: 'AnchorLink', loader: () => import(
//     /* webpackChunkName: "anchorlink" */
//     './AnchorLink') }) as AnchorLinkType;

const Draggable = Loadable({
  name: 'Draggable',
  loader: () => import(
  /* webpackChunkName: "table" */
  'react-draggable')
});


export type { AnchorProps, AnchorLinkProps } from './types';

export { Draggable };

export default AnchorList;
