import Loadable from '../../react-loadable';
import { BatchOperatorClsPrefix } from '../../constants/index';
import { BatchOperatorType } from './types';

const BatchOperator = Loadable({
  name: 'BatchOperator', loader: () => import(
    /* webpackChunkName: "batch-operator" */
    './batchOperator')
}) as BatchOperatorType;

BatchOperator.defaultProps = {
  prefixCls: BatchOperatorClsPrefix,
  // okText: '确认',
  // cancelText: '取消'
  isBatchSelect: false,  // 默认关闭全选功能
  defaultSelectAll: false  // 默认全选状态为 true（该属性仅开启全选功能后生效）
};

export type { BatchOperatorProps, BatchOperatorType, BatchOperatorFooterBtnType } from './types';

export type { BatchOperatorStoreType } from './store';

export { default as BatchOperatorStore } from './store';

export default BatchOperator;
