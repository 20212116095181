import { WatermarkOptions } from "./types";

// 高水印层级
export const HighZIndex = 9999;

// 低水印层级
export const LowZIndex = 949; // EM弹框-950，Dialog组件-998，Trigger组件-999

// 水印默认宽度
export const DefaultWMWidth = 200;

// 水印默认高度
export const DefaultWMHeight = 150;

// 默认水印配置
export const defaultOptions: WatermarkOptions = {
  // text / img
  text: '',
  fontStyle: 'font-size:14px;color:#000;',
  src: '',
  width: DefaultWMWidth,
  height: DefaultWMHeight,
  rotate: -15,
  alpha: 0.15,
  zIndex: 9999,
};