import Loadable from '../../react-loadable';
import BreadCrumbItem from './BreadCrumbItem';
import BreadSeparator from './BreadSeparator';
import { BreadCrumbType } from './types';
import { breadCrumbClsPrefix } from '../../constants/index';

const index = Loadable({
  name: 'BreadCrumb',
  loader: () => import(
    /* webpackChunkName: "ui_common" */
    './BreadCrumb')
}) as BreadCrumbType;

index.defaultProps = {
  separator: '/',
  prefixCls: breadCrumbClsPrefix
}

index.Item = BreadCrumbItem;
index.Separator = BreadSeparator;

export type { BreadCrumbProps } from './types';

export default index;
