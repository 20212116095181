import Loadable, { LoadingType } from "../../react-loadable";
import { mColorPickerPrefix } from "../../constants/index.m";
import { MColorPickerProps, MColorPickerType } from "./types";
import { USESKELETON } from '../../constants/index';

const MColorPickerSkeleton: LoadingType<MColorPickerProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MColorPicker = Loadable({ name: "MColorPicker", loading: MColorPickerSkeleton, loader: () => import(
 /* webpackChunkName: "m_colorpicker" */
  './MColorPicker/index') }) as MColorPickerType;

MColorPicker.MColorPickerSkeleton = MColorPickerSkeleton;

MColorPicker.defaultProps = {
  prefixCls: mColorPickerPrefix,
  alpha: 100
};

export type { MColorPickerProps, ColorObject } from "./types";

export default MColorPicker;