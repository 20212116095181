import Loadable from '../../react-loadable';
import { ListViewType, OperatesType } from './types';

const ListView = Loadable({ name: 'ListView', loader: () => import(
    /* webpackChunkName: "listview" */
    './components/listView') }) as ListViewType;

export type  { IListViewProps, ListViewData, OperatesType } from './types'

export { default as ListViewStore } from './store/Store';

export default ListView;
