import { DateLimitedConfigType, DateType, MultiBtnDataType } from '../../types';
import { dayjs, getLabel } from '@weapp/utils';
import { Dialog } from '../../../../lib'
import { getLimitDate } from './limitDate'

const { message } = Dialog

/**
 * @description: 判断范围面板上方快捷选择按钮点击
 * @return {*}
 */
export function checkMultiBtnData(value: DateType[], dateLimitedConfig: DateLimitedConfigType, type?: 'day') {
  //如果有自定义日期格式过滤，则优先走自定义处理
  let outputValue: DateType[] = value
  let valid = true
  let msg = ''

  if (Array.isArray(value)) {
    const { minDate, maxDate } = getLimitDate(dateLimitedConfig)
    const startDate = value[0] || ''
    const endDate = value[1] || ''
    const format = type === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'
    const minDateText = minDate ? dayjs(minDate).format(format) : getLabel('58168', '不限')
    const maxDateText = maxDate ? dayjs(maxDate).format(format) : getLabel('58168', '不限')
    if (dayjs(minDate).isAfter(maxDate, type)) {
      valid = false
      msg = getLabel('153442', '最大日期最小日期数据有误！')
    }

    if (value.length === 0) {
      outputValue = value
    } else {
      if (minDate && maxDate) {
        //在同时存在最大最小日期的情况下
        if (startDate === '' && endDate === '') {
          outputValue = value
        } else if (startDate === '' && endDate !== '') {
          //开始日期为空 -> 判断结束日期是否在范围内
          if (dayjs(endDate).isBetween(minDate, maxDate, type, '[]')) {
            outputValue = [minDate, endDate]
          } else {
            if (dayjs(endDate).isAfter(maxDate, type)) {
              outputValue = [minDate, maxDate]
            } else {
              valid = false
            }
          }
        } else if (startDate !== '' && endDate === '') {
          //结束日期为空 -> 判断开始日期是否在范围内
          if (dayjs(startDate).isBetween(minDate, maxDate, type, '[]')) {
            outputValue = [startDate, maxDate]
          } else {
            if (dayjs(startDate).isBefore(minDate, type)) {
              outputValue = [minDate, maxDate]
            } else {
              valid = false
            }
          }
        } else {
          //取日期交集
          if (dayjs(startDate).isAfter(maxDate, type) || dayjs(endDate).isBefore(minDate, type)) {
            valid = false
          } else {
            outputValue = [dayjs.max([dayjs(startDate), dayjs(minDate)]), dayjs.min([dayjs(endDate), dayjs(maxDate)])]
          }
        }
      } else if (minDate) {
        //在只存在最小日期的情况下
        if (startDate === '' && endDate === '') {
          outputValue = value
        } else if (startDate === '' && endDate !== '') {
          //开始日期为空 -> 判断结束日期是否大于最小日期
          if (dayjs(endDate).isSameOrAfter(minDate, type)) {
            outputValue = [minDate, endDate]
          } else {
            valid = false
          }
        } else if (startDate !== '' && endDate === '') {
          //结束日期为空 -> 则谁的日期更大选谁
          outputValue = [dayjs.max([dayjs(startDate), dayjs(minDate)]), '']
        } else {
          //取日期交集
          if (dayjs(endDate).isSameOrAfter(minDate, type)) {
            outputValue = [dayjs.max([dayjs(startDate), dayjs(minDate)]), endDate]
          } else {
            valid = false
          }
        }
      } else if (maxDate) {
        //在只存在最大日期的情况下
        if (startDate === '' && endDate === '') {
          outputValue = value
        } else if (startDate === '' && endDate !== '') {
          //开始日期为空 -> 则谁的日期更小选谁
          outputValue = ['', dayjs.min([dayjs(endDate), dayjs(maxDate)])]
        } else if (startDate !== '' && endDate === '') {
          //结束日期为空 -> 判断开始日期是否小于最大日期
          if (dayjs(endDate).isSameOrBefore(maxDate, type)) {
            outputValue = [startDate, maxDate]
          } else {
            valid = false
          }
        } else {
          //取日期交集
          if (dayjs(startDate).isSameOrBefore(maxDate, type)) {
            outputValue = [startDate, dayjs.min([dayjs(endDate), dayjs(maxDate)])]
          } else {
            valid = false
          }
        }
      } else {
        outputValue = value
      }
    }
    //----------如果判断不合法，则抛出提示---------
    if (!valid) {
      if (!msg) msg = `${getLabel('153482', '超出可选范围，请重新选择! 可选范围为：')}${minDateText}~${maxDateText}`
      message({
        type: "info",
        content: msg
      })
    }
  }
  return {
    value: outputValue,
    valid,
    msg
  }
}

/**
 * @description: 针对最大最小值对数据进行过滤
 * @return {*}
 */
export function filterMultiBtnData(type: 'date' | 'datetime', multiBtnGroupData?: MultiBtnDataType[], dateLimitedConfig?: DateLimitedConfigType) {
  const dayjsType = (type === 'date' ? 'day' : void 0)
  if (dateLimitedConfig) {
    const { minDate, maxDate } = dateLimitedConfig
    let min = minDate
    let max = maxDate
    if (typeof (minDate) === 'function') min = minDate()
    if (typeof (maxDate) === 'function') max = maxDate()
    const _minDate = (!!min && dayjs(min as DateType).isValid() ? dayjs(min as DateType) : '')
    const _maxDate = (!!max && dayjs(max as DateType).isValid() ? dayjs(max as DateType) : '')
    const data = multiBtnGroupData?.filter(item => {
      const { startDate, endDate } = item
      let sd = startDate
      let ed = endDate
      if (typeof (sd) === 'function') sd = sd()
      if (typeof (ed) === 'function') ed = ed()
      if (_minDate && _maxDate) {
        return !(dayjs(sd).isAfter(_maxDate, dayjsType) || dayjs(ed).isBefore(_minDate, dayjsType))
      } else if (_minDate) {
        return !dayjs(ed).isBefore(_minDate, dayjsType)
      } else if (_maxDate) {
        return !dayjs(sd).isAfter(_maxDate, dayjsType)
      } else {
        return true
      }
    })
    return data
  } else {
    return multiBtnGroupData
  }
}