
import { dayjs } from '@weapp/utils'
import { DateCellValueType, DateType, DateLimitedConfigType } from '../../types'
import { isInLimitedRange, getMonthLabel, getSeasonLabel, getHalfYearLabel, getSeasonDateByYear, getHalYearByYear } from '../index'
import { isInLimitedHalfOfYearRange, isInLimitedSeasonRange } from './valid'


/**
 * @description: 根据年份创建出年份面板
 * @param {DateType} titleDate
 * @param {DateType} minDate
 * @param {DateType} maxDate
 * @param {function} customDateFilter
 * @return {*}
 */
export function getYearData(titleDate: DateType, dateLimitedConfig: DateLimitedConfigType) {
  const date = titleDate ? dayjs(titleDate) : dayjs()
  const y = date.year()
  let firstYear = y - y % 10
  let lastYear = firstYear + 9
  let year = firstYear
  const DateCellValueType: DateCellValueType[] = []
  while (year <= lastYear) {
    const date = new Date(+dayjs(year.toString())) //注意这里一定要用+先转成带有时区格式的日期后再进行new Date
    DateCellValueType.push({
      date: date,
      content: year.toString(),
      isValid: isInLimitedRange(dayjs(date).format('YYYY'), dateLimitedConfig, 'year')
    })
    year++
  }
  return DateCellValueType
}

/**
 * @description: 根据年份创建出年月份面板
 * @param {DateType} titleDate
 * @param {DateType} minDate
 * @param {DateType} maxDate
 * @param {function} customDateFilter
 * @return {*}
 */
export function getMonthData(titleDate: DateType, dateLimitedConfig: DateLimitedConfigType) {
  const date = titleDate ? dayjs(titleDate) : dayjs()
  let month = 0
  const DateCellValueType: DateCellValueType[] = []
  while (month < 12) {
    const content = getMonthLabel(month)
    DateCellValueType.push({
      date: new Date(+date.month(month)), //注意这里一定要用+先转成带有时区格式的日期后再进行new Date
      content,
      //月份满足需求应该是年份与最小日期与最小
      isValid: isInLimitedRange(date.format('YYYY-MM'), dateLimitedConfig, 'month')
    })
    month++
  }
  return DateCellValueType
}

/**
 * @description: 根据年份月份创建出天数面板
 * @param {number} weekStart
 * @param {DateType} titleDate
 * @param {DateType} minDate
 * @param {DateType} maxDate
 * @param {function} customDateFilter
 * @return {*}
 */
export function getDayData(weekStart: number = 0, titleDate: DateType, dateLimitedConfig: DateLimitedConfigType) {

  const date = titleDate ? dayjs(titleDate) : dayjs()
  const _date = date.clone()

  const firstDay = date.date(1)
  const lastDay = _date.add(1, 'month').date(0)

  const _firstDay = firstDay.clone()
  const _lastDay = lastDay.clone()
  const panelFirstDay = _firstDay.subtract((7 + firstDay.day() - weekStart) % 7, 'day')
  const panelLastDay = _lastDay.add((7 + (weekStart - 1) - lastDay.day()) % 7, 'day')

  let d = panelFirstDay
  const dateCellAry: DateCellValueType[] = []
  while (d.isSameOrBefore(panelLastDay)) {
    dateCellAry.push({
      date: new Date(+d), //注意这里一定要用+先转成带有时区格式的日期后再进行new Date
      content: d.date().toString(),
      isValid: isInLimitedRange(d.toString(), dateLimitedConfig, 'day'),
      isCurrentMonth: +firstDay <= +d && +d <= +lastDay,
    })
    d.add(1, 'day')
  }
  //为保证面板为6 * 7的矩阵，所以当行数不够的时候，利用下个月份的周来进行补全
  if (dateCellAry.length < 42) {
    //行数不够有两种情况，有可能只有4行(例如每周以周一开始的2021年2月份)，此时dateCellAry.length为28，否则dateCellAry.length为35
    const offset = dateCellAry.length === 28 ? 14 : 7
    const end = panelLastDay.add(offset, 'day')
    while (d.isSameOrBefore(end, 'day')) {
      dateCellAry.push({
        date: new Date(+d),
        content: d.date().toString(),
        isValid: isInLimitedRange(d.toString(), dateLimitedConfig, 'day'),
        isCurrentMonth: false,
      })
      d.add(1, 'day')
    }
  }
  return dateCellAry
}

/**
 * @description: 根据年份创建出年份面板
 * @param {DateType} titleDate
 * @param {DateType} minDate
 * @param {DateType} maxDate
 * @param {function} customDateFilter
 * @return {*}
 */
export function getSeasonData(titleDate: DateType, dateLimitedConfig: DateLimitedConfigType) {

  const date = titleDate ? dayjs(titleDate) : dayjs()
  const seasonAry = getSeasonDateByYear(date.year())
  const DateCellValueType: DateCellValueType[] = []

  seasonAry.forEach(item => {
    const content = getSeasonLabel(item.seasonNumber)
    DateCellValueType.push({
      date: item.start,
      content,
      isValid: isInLimitedSeasonRange(dayjs(item.start).format('YYYY-MM'), dateLimitedConfig),
      season: item.season
    })
  })
  return DateCellValueType
}

/**
 * @description: 根据年份创建出年份面板
 * @param {DateType} titleDate
 * @param {DateType} minDate
 * @param {DateType} maxDate
 * @param {function} customDateFilter
 * @return {*}
 */
export function getHalfYearData(titleDate: DateType, dateLimitedConfig: DateLimitedConfigType) {

  const date = titleDate ? dayjs(titleDate) : dayjs()
  const halfYearAry = getHalYearByYear(date.year())
  const DateCellValueType: DateCellValueType[] = []

  halfYearAry.forEach(item => {
    const content = getHalfYearLabel(item.halfYearNumber)
    DateCellValueType.push({
      date: item.start,
      content,
      // isValid: isInLimitedRange(dayjs(item.start).format('YYYY-MM'), dateLimitedConfig, 'month'),
      isValid: isInLimitedHalfOfYearRange(dayjs(item.start).format('YYYY-MM'), dateLimitedConfig),
      halfYear: item.halfYear
    })
  })
  return DateCellValueType
}


