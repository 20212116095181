import { SyntheticEvent } from "react";
import Loadable from "../../../react-loadable";
import { ContextMenuType, ShowContextMenuParams } from "../types";
import { ContextMenuProps } from "../types";
import { eventManager } from "./eventManager";
import { EVENT } from "./constants";
import { rightMenuClsPrefix } from "../../../constants";

export const ContextMenu = Loadable({ name: 'ContextMenu', loader: () => import(
    /* webpackChunkName: "ui_common" */
   './contextMenu') }) as ContextMenuType;

ContextMenu.defaultProps = {
  animation: false,
  prefixCls: rightMenuClsPrefix,
  disableBoundariesCheck: false
}
export function  show({ event, id, props, position }:ShowContextMenuParams) {
    if (event.preventDefault) event.preventDefault();

    eventManager.emit(EVENT.HIDE_ALL).emit(id, {
      event: (event as SyntheticEvent).nativeEvent || event,
      props,
      position,
    });
  }