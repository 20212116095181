import Loadable from '../../react-loadable';
import { RadioType } from './types';
import { radioClsPrefix } from '../../constants/index';

const Radio = Loadable({
  name: 'Radio',
  loader: () => import(
    /* webpackChunkName: "ui_form" */
    './Radio')
}) as RadioType;

Radio.defaultProps = {
  prefixCls: radioClsPrefix,
  vertical: false,
  canReversedChoose: true,
  isMobile: false,
  inForm: false,
  showTitle: true
};

export type { RadioProps, RadioOptionProps, ValueType as RadioValueType, OptionData as RadioOptionType, OptionsType as RadioDataType, RadioEvent } from './types';

export default Radio;
