import { hrmCardClsPrefix } from '../../constants/index'
import Loadable from '../../react-loadable'
import { HrmCardRenderType, HrmCardPanelType } from './types'
import { openCustomCard, openHrmCard, openHrmSP, openMobileHrmCard } from './openHrmCard'
const HrmCard = Loadable({
  name: 'HrmCard',
  loader: () =>
    import(
      /* webpackChunkName: 'hrmcard' */
      './HrmCard'
    ),
}) as HrmCardRenderType

const HrmCardPanel = Loadable({
  name: 'HrmCardPanel',
  loader: () =>
    import(
      /* webpackChunkName: 'hrmcard' */
      './Panel'
    ),
}) as HrmCardPanelType

HrmCard.defaultProps = {
  prefixCls: hrmCardClsPrefix,
  checkWorkStatus: true,
  // openStatisticsRequest: true
}

HrmCardPanel.defaultProps = {
  prefixCls: hrmCardClsPrefix,
}

HrmCard.HrmCardPanel = HrmCardPanel

HrmCard.openHrmCard = openHrmCard
HrmCard.openHrmSP = openHrmSP
HrmCard.openMobileHrmCard = openMobileHrmCard
HrmCard.openCustomCard = openCustomCard

export type {
  HrmCardProps,
  HrmCardType,
  HrmCardDatas,
  StatisticsType as HrmCardStatistics,
  Status as HrmCardStatus,
  WorkStatus as HrmCardWorkStatus,
  HrmCardRenderType,
} from './types'

export default HrmCard
