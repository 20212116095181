import Loadable from "../../react-loadable";
import { mattersAssociatedClsPrefix } from '../../constants/index';
import { MattersAssociatedType } from "./types";
import { associatedBrowserConfig } from "./constants/config";

const MattersAssociated = Loadable({
  name: 'MattersAssociated', loader: () => import(
    /* webpackChunkName: "ui_common_less" */
    './MattersAssociated')
}) as MattersAssociatedType;


/*------------------------*/
MattersAssociated.defaultProps = {
  prefixCls: mattersAssociatedClsPrefix,
  readOnly: false,
};

export type { MattersAssociatedProps } from "./types";
export default MattersAssociated;
export { associatedBrowserConfig };