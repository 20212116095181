import Loadable from '../../react-loadable';
import { SwitchType } from './types';
import { switchClsPrefix } from '../../constants/index';

const Switch = Loadable({
  name: 'Switch', loader: () => import(
    /* webpackChunkName: 'ui_form' */
    './Switch')
}) as SwitchType;

Switch.defaultProps = {
  prefixCls: switchClsPrefix,
  size: 'md',
  isMobile: false
  // isMobile: false
};

export type { SwitchProps, SwitchType, SwitchChangeEventHandler, SwitchClickEventHandler } from './types';

export default Switch;
