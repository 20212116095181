import { layoutAdaptClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { LayoutAdaptType, LeftType, RightType } from './types';

const LayoutAdapt = Loadable({
  name: 'LayoutAdapt', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './LayoutAdapt'
  )
}) as LayoutAdaptType;

LayoutAdapt.defaultProps = {
  prefixCls: layoutAdaptClsPrefix
}

const Left = Loadable({
  name: 'LayoutAdaptLeft', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './Left')
}) as LeftType;

Left.defaultProps = {
  prefixCls: layoutAdaptClsPrefix + '-left'
}

const Right = Loadable({
  name: 'LayoutAdaptRight', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './Right')
}) as RightType;

Right.defaultProps = {
  prefixCls: layoutAdaptClsPrefix + '-right'
}

LayoutAdapt.Left = Left;
LayoutAdapt.Right = Right;

export type { LayoutAdaptProps, LayoutAdaptType } from './types';
export default LayoutAdapt;
