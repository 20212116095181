
const publicUrlapi = window.publicUrlapi || window.publicUrl || '';

/** 上传前校验 url */
export const PREUPLOADCHECK = `${publicUrlapi}/{api}/file/preUploadCheck`;

/** 上传 url */
export const UPLOADURL = `${publicUrlapi}/{api}/file/module/upload`;

/** 重上传 url */
export const REUPLOADURL = `${publicUrlapi}/{api}/file/reUpload`;

/** 分片上传前校验 url */
export const CHUNKUPCHECKURL = `${publicUrlapi}/{api}/file/chunkUpCheck`;

/** 预览 url */
export const PREVIEWURL = `${publicUrlapi}/{api}/file/preview`;

/** 下载 url */
export const DOWNLOADURL = `${publicUrlapi}/{api}/file/remotedownload/{fileId}/upload/true`;

/** 文件删除接口 */
export const DELETEURL = `${publicUrlapi}/{api}/file/base/upload/{fileId}.json`

/**
 * 批量删除 url（业务模块）
 * http://10.10.27.86:4000/web/#/51?page_id=3161
 * 文档模块的删除和业务模块的删除接口不同，因此文档模块使用时，需要自行设置
 */
export const BATCHDELETEURL = `${publicUrlapi}/{api}/file/business/dl`;

/**
 * 批量删除 url（文档模块）
 * 文档模块的删除和业务模块的删除接口不同，组件默认使用的是业务模块 url，这个 url 仅用作记录
 */
export const BATCHDELETEURLDOC = `${publicUrlapi}/{api}/file/dl`;

/** 编辑接口 */
export const EDITURL = `${publicUrlapi}/{api}/file/getEditUrlWithAuth`;

/** 重命名接口 */
export const RENAMEURL = `${publicUrlapi}/{api}/file/fileRename`;

/** 修改附件密级 */
export const EDITSECLEVELURL = `${publicUrlapi}/{api}/file/fileEditSecLevel`;

/** 修改附件密级（批量） */
export const EDITSECLEVELSURL = `${publicUrlapi}/{api}/file/fileEditSecLevels`;

/** 附件重复名称校验 */
export const QUERYDOCNAMEURL = `${publicUrlapi}/{api}/doc/folders/queryDocName`;

/** 批量下载 */
// https://showdoc.alphaxx.cn:9443/web/#/51/4554
export const BATCHDOWNLOADURL = `${publicUrlapi}/{api}/file/batch/asynchronous/downloadByFileIds`;
// export const BATCHDOWNLOADURL = `${publicUrlapi}/{api}/file/batchDownloadByFileIds`;

/** 批量下载进度 */
// https://showdoc.alphaxx.cn:9443/web/#/51/4553
export const BATCHDOWNLOADPERCENTURL = `${publicUrlapi}/{api}/file/batchDownloadPercent`;

/** 申述 url */
export const APPEALURL = `${publicUrlapi}/{api}/file/appeal/file/{fileId}`;

/** 获取文件信息 url */
export const GETINFOBYREFORDOCURL = `${publicUrlapi}/{api}/file/getInfoByRefOrDoc`;

/**
 * 获取附件本地标密上传
 * @returns isAllow: boolean, verifyFormat: ''
 */
export const LOCALSECURITYINFOURL = `${publicUrlapi}/api/bs/datasecurity/classifiedprotection/getAttSetting`;

/** 获取系统附件大小限制、附件类型白名单/黑名单配置 */
export const SYSTEMUPLOADCHECKCONFIG = `${publicUrlapi}/{api}/file/tenant/setting`;

// 临时租户认证
export const WEA_LINK_KEEP_TEMPOTHERTOKEN = 'wea_link_keep_tempOtherToken';
