import Loadable from "../../react-loadable";
import { echartPrefix } from '../../constants/index';
import { EChartsType } from "./types";

const  EChart = Loadable({ name: "ECharts", loader: () => import(
  /* webpackChunkName: "echarts" */
  "./EChartsView") }) as EChartsType;

EChart.defaultProps = {
  prefixCls: echartPrefix,
  districtName: '中国',
  renderer: 'canvas'
};

export type { EChartsProps, DistrictName, EchartsInterface } from "./types";
export default EChart;
