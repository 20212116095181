import Loadable from "../../react-loadable";
import { mPickerClsPrefix } from "../../constants/index.m"
import { MPickerType } from "./types"

const MPicker = Loadable({
  name: 'MPicker', loader: () => import(
    /* webpackChunkName: "m_picker" */
    './MPicker')
}) as MPickerType;

MPicker.defaultProps = {
  prefixCls: mPickerClsPrefix,
  title: '',
  placement: 'bottom'
};

export type { MPickerProps } from "./types";
export default MPicker;
