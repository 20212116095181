import { PopupPlacement } from '../types';
import { POPUPPLACEMENT } from '../../../constants/index';
import { needRTL } from '../../../utils';
import { AnyObj } from '../../../types/common';
import { ua } from '@weapp/utils';
export interface Region {
  left: number,
  top: number,
  width: number,
  height: number,
}
interface Postion {
  left: number,
  top: number,
  position?: string,
}
interface Options {
  popupPlacement?: PopupPlacement,
  points: string[];
}
/* ------------------ 获取浏览框宽/高 ----------------------------- */
function getSize(win: any, type: 'width' | 'height') {
  if (!win) return 0;
  const body = win?.document?.body;
  if (!body) return 0;
  if (type === 'width') {
    const cw = body?.clientWidth || 0;
    const sw = body?.scrollWidth || 0;
    const ow = body?.offsetWidth || 0;
    return Math.max(cw, sw, ow);
  }
  const ch = body?.clientHeight || 0;
  const sh = body?.scrollHeight || 0;
  const oh = body?.offsetHeight || 0;
  return Math.max(ch, sh, oh);
}
/* ------------------ 获取source/target定位及宽度 ------------------ */
function getClientPosition(el: HTMLElement) {
  const doc = el?.ownerDocument;
  const body = doc?.body;
  const docElement = doc && doc.documentElement;
  const box = el?.getBoundingClientRect();
  // 窗口边框，标准设置documentElement，quirks设置body
  if (box?.left === -9999) {
    return {
      width: box?.width || 0,
      height: box?.height || 0,
      left: 0,
      top: 0,
    }
  }
  return {
    width: box?.width || 0,
    height: box?.height || 0,
    left: box?.left - docElement?.clientLeft || body?.clientLeft || 0,
    top: box?.top - docElement?.clientTop || body?.clientTop || 0,
  }
}

function getScroll(win: (Window & typeof globalThis), isTop?: boolean) {
  let ret = win[`page${isTop ? 'Y' : 'X'}Offset`];
  if (typeof ret !== 'number') {
    const doc = win.document;
    if (isTop) {
      ret = doc.documentElement.scrollTop;
    } else {
      ret = doc.documentElement.scrollLeft;
    }
    if (typeof ret !== 'number') {
      if (isTop) {
        ret = doc.body.scrollTop;
      } else {
        ret = doc.body.scrollLeft;
      }
    }
    if (typeof ret !== 'number') {
      return 0;
    }
  }
  return ret;
}

function getScrollLeft(win: (Window & typeof globalThis)) {
  return getScroll(win);
}

function getScrollTop(win: (Window & typeof globalThis)) {
  return getScroll(win, true);
}

export function getRegion(target: HTMLElement) { // 获取target节点宽高数据
  const position = getClientPosition(target);
  const doc = target.ownerDocument;
  const win = doc.defaultView;
  if (win) {
    position.left += getScrollLeft(win);
    position.top += getScrollTop(win);
  }
  return position;
}
/* ------------------ 计算source定位数据 ------------------ */

function getAlignOffset(region: Region, mode: string) {
  // a => 数据返回模式，t: top l: left
  // 数据返回模式包含五种, A:top/left, B: height/width
  // a: A + B/2
  // b: -(B/2)
  // c: A
  // d: A+B
  // e: B
  // f: A-B
  const { left, top, width, height } = region;
  switch (mode) {
    case 'at': return top + height / 2;
    case 'al': return left + width / 2;
    case 'bt': return height / 2;
    case 'bl': return width / 2;
    case 'ct': return top;
    case 'cl': return left;
    case 'dt': return top + height;
    case 'dl': return left + width;
    case 'et': return height;
    case 'el': return width;
    default: return 0;
  }
}

function getElFuturePostion(sourceRegion: Region, targetRegion: Region, options: Options) {
  const { points } = options;
  const leftAlignT = points[0];
  const topAlignT = points[1];
  const leftAlignS = points[2];
  const topAlignS = points[3];
  const p1 = {
    left: getAlignOffset(targetRegion, leftAlignT),
    top: getAlignOffset(targetRegion, topAlignT),
  };
  const p2 = {
    left: getAlignOffset(sourceRegion, leftAlignS),
    top: getAlignOffset(sourceRegion, topAlignS),
  }
  let position = {
    left: p1.left - p2.left,
    top: p1.top - p2.top,
  }
  return position;
}

/* -----------------  判断/修正弹层超出范围的情况 ---------------------- */

function fixedRectToBottom(sourceRegion: Region, targetRegion: Region, innerWidth: number, innerHeight: number, otherParams?: AnyObj) {
  const fit = innerHeight > (targetRegion.top + targetRegion.height + sourceRegion.height - (otherParams?.adaptivPositionHeightOffset || 0)) && sourceRegion.width < innerWidth;
  let left = sourceRegion.left;
  // 按照bottomLeft定位
  let position;
  if (left < 0 || sourceRegion.left + sourceRegion.width > innerWidth) {
    left = targetRegion.left;
    position = 'left';
    if (left + sourceRegion.width > innerWidth) {
      left = innerWidth - sourceRegion.width - 1;
    }
  }
  return {
    top: targetRegion.top + targetRegion.height,
    left,
    fit,
    position,
  }
}

function fixedRectToTop(sourceRegion: Region, targetRegion: Region, innerWidth: number) {
  const fit = targetRegion.top > sourceRegion.height && sourceRegion.width < innerWidth;
  let left = sourceRegion.left;
  // 按照topLeft定位
  let position;
  if (left < 0 || sourceRegion.left + sourceRegion.width > innerWidth) {
    left = targetRegion.left;
    position = 'left';
    if (left + sourceRegion.width > innerWidth) {
      left = innerWidth - sourceRegion.width - 1;
    }
  }
  return {
    top: targetRegion.top - sourceRegion.height,
    left,
    fit,
    position,
  }
}

function fixedRectToLeft(sourceRegion: Region, targetRegion: Region, innerHeight: number) {
  const fit = targetRegion.left > sourceRegion.width && innerHeight > sourceRegion.height;
  let top = sourceRegion.top;
  let position;
  if (top < 0 || sourceRegion.top + sourceRegion.height > innerHeight) {
    top = targetRegion.top;
    position = 'top';
    if (top + sourceRegion.height > innerHeight) {
      top = innerHeight - sourceRegion.height - 1;
    }
  }
  return {
    left: targetRegion.left - sourceRegion.width,
    top,
    fit,
    position,
  }
}

function fixedRectToRight(sourceRegion: Region, targetRegion: Region, innerWidth: number, innerHeight: number) {
  const fit = innerWidth > targetRegion.width + targetRegion.left + sourceRegion.width && innerHeight > sourceRegion.height;
  let top = sourceRegion.top;
  let position;
  if (top < 0 || sourceRegion.top + sourceRegion.height > innerHeight) {
    top = targetRegion.top;
    position = 'top';
    if (top + sourceRegion.height > innerHeight) {
      top = innerHeight - sourceRegion.height - 1;
    }
  }
  return {
    left: targetRegion.left + targetRegion.width,
    top,
    fit,
    position,
  }
}

function fixOutOfVisibleRect(futurePostion: Postion, sourceRegion: Region, targetRegion: Region, options: Options, target: HTMLElement, relativePostionAlign?: boolean, otherParams?: AnyObj) {
  if (relativePostionAlign) {
    // 相对定位，暂不需要调整位置
    return {
      ...futurePostion,
      widthSource: null,
      adjuestPlacement: null,
    }
  }
  // 保持弹层在window可见范围内
  const { left, top } = futurePostion;
  const { width, height } = sourceRegion;
  const doc = target.ownerDocument;
  const win = doc.defaultView;
  const innerWidth = getSize(win, 'width');
  const innerHeight = getSize(win, 'height');
  const { popupPlacement = POPUPPLACEMENT } = options;
  let widthSource, adjuestPlacement;
  // 原位置放不下, 需调整显示位置
  // 1.判断底部能否放下（无滚动情况）fixedRectToBottom
  // 2.判断顶部能否放下（无滚动情况）fixedRectToTop
  // 3.判断左侧能否放下（无滚动情况）fixedRectToLeft
  // 4.判断右侧能否放下（无滚动情况）fixedRectToRight
  // （5先不处理，后续有需求再说, widthSource目前先不处理）
  // 5.底部滚动/判断各方向滚动的范围，取最优 ---- 弹层内部有滚动的时候，会影响效果，目前各个方向都放不下，先保持原本效果，使用的时候内部处理滚动
  if (left < 0 || top < 0 || (((left + width) >= innerWidth) && innerWidth > 0) || (((top + height) >= innerHeight) && (innerHeight > 0))) {
    const bottomFixed = fixedRectToBottom({ ...sourceRegion, left, top }, targetRegion, innerWidth, innerHeight, otherParams);
    const topFixed = fixedRectToTop({ ...sourceRegion, left, top }, targetRegion, innerWidth);
    const leftFixed = fixedRectToLeft({ ...sourceRegion, left, top }, targetRegion, innerHeight);
    const rightFixed = fixedRectToRight({ ...sourceRegion, left, top }, targetRegion, innerWidth, innerHeight);
    if (['bottom', 'bottomLeft', 'bottomRight'].indexOf(popupPlacement) >= 0) {
      // 判断原方向能否调整放下，判断 2、3、4、5
      if (bottomFixed.fit) {
        futurePostion.left = bottomFixed.left;
        futurePostion.top = bottomFixed.top;
      } else if (topFixed.fit) {
        futurePostion.left = topFixed.left;
        futurePostion.top = topFixed.top;
        adjuestPlacement = popupPlacement.replace('bottom', 'top');
      } else if (rightFixed.fit) {
        futurePostion.left = rightFixed.left;
        futurePostion.top = rightFixed.top;
        adjuestPlacement = 'rightTop';
      } else if (leftFixed.fit) {
        futurePostion.left = leftFixed.left;
        futurePostion.top = leftFixed.top;
        adjuestPlacement = 'leftTop';
      }
    } else if (['top', 'topLeft', 'topRight'].indexOf(popupPlacement) >= 0) {
      // 判断原方向能否调整放下，判断 1、3、4、5
      if (topFixed.fit) {
        futurePostion.left = topFixed.left;
        futurePostion.top = topFixed.top;
      } else if (bottomFixed.fit) {
        futurePostion.left = bottomFixed.left;
        futurePostion.top = bottomFixed.top;
        adjuestPlacement = popupPlacement.replace('top', 'bottom');
      } else if (rightFixed.fit) {
        futurePostion.left = rightFixed.left;
        futurePostion.top = rightFixed.top;
        adjuestPlacement = 'rightTop';
      } else if (leftFixed.fit) {
        futurePostion.left = leftFixed.left;
        futurePostion.top = leftFixed.top;
        adjuestPlacement = 'leftTop';
      }
    } else if (['left', 'leftTop', 'leftRight'].indexOf(popupPlacement) >= 0) {
      // 判断原方向能否调整放下, 再判断 4、2、1、5
      if (leftFixed.fit) {
        futurePostion.left = leftFixed.left;
        futurePostion.top = leftFixed.top;
        adjuestPlacement = leftFixed.position === 'top' ? 'leftTop' : '';
      } else if (rightFixed.fit) {
        futurePostion.left = rightFixed.left;
        futurePostion.top = rightFixed.top;
        adjuestPlacement = leftFixed.position === 'top' ? 'rightTop' : popupPlacement.replace('left', 'right');
      } else if (topFixed.fit) {
        futurePostion.left = topFixed.left;
        futurePostion.top = topFixed.top;
        adjuestPlacement = 'topLeft';
      } else if (bottomFixed.fit) {
        futurePostion.left = bottomFixed.left;
        futurePostion.top = bottomFixed.top;
        adjuestPlacement = 'bottomLeft';
      }
    } else {
      // 判断原方向能否调整放下, 再判断 3、2、1、5
      if (rightFixed.fit) {
        futurePostion.left = rightFixed.left;
        futurePostion.top = rightFixed.top;
        adjuestPlacement = leftFixed.position === 'top' ? 'rightTop' : popupPlacement.replace('left', 'right');
      } else if (leftFixed.fit) {
        futurePostion.left = leftFixed.left;
        futurePostion.top = leftFixed.top;
        adjuestPlacement = leftFixed.position === 'top' ? 'leftTop' : popupPlacement.replace('right', 'left');
      } else if (topFixed.fit) {
        futurePostion.left = topFixed.left;
        futurePostion.top = topFixed.top;
        adjuestPlacement = 'topLeft';
      } else if (bottomFixed.fit) {
        futurePostion.left = bottomFixed.left;
        futurePostion.top = bottomFixed.top;
        adjuestPlacement = 'bottomLeft';
      }
    }
  }
  return {
    ...futurePostion,
    widthSource,
    adjuestPlacement,
  }
}

const triggerMap = new Map<string, boolean>();

export function alignElement(source: HTMLElement | null, target: HTMLElement | null, options: Options, relativePostionAlign?: boolean, cover?: boolean, otherParams?: AnyObj) {
  if (source && target) {
    const refNodeRegion = getRegion(target);
    const sourceRegion = getRegion(source);
    if (needRTL?.() && !sourceRegion?.width && !triggerMap.get(otherParams?.popupId)) {
      // 防止多次死循环，控制仅尝试一次重处理
      triggerMap.set(otherParams?.popupId, true);
      const timeout = setTimeout(() => {
        alignElement(source, target, options, relativePostionAlign, cover, otherParams);
        triggerMap.set(otherParams?.popupId, false);
        clearTimeout(timeout);
      }, 100);
      return ;
    }
    // 当前节点将要被放置的位置
    const futurePostion = getElFuturePostion(sourceRegion, refNodeRegion, options);
    let { left, top, adjuestPlacement } = fixOutOfVisibleRect(futurePostion, sourceRegion, refNodeRegion, options, target, relativePostionAlign && !otherParams?.enableFixedPosition, otherParams);
    // cover: 强制覆盖
    if (cover && source) {
      if (!relativePostionAlign) {
        const nowPlacement = adjuestPlacement || options.popupPlacement || '';
        if (['bottom', 'bottomLeft', 'bottomRight'].indexOf(nowPlacement) >= 0) {
          top = futurePostion.top - refNodeRegion.height;
          left = futurePostion.left;
        } else if (['top', 'topLeft', 'topRight'].indexOf(nowPlacement) >= 0) {
          top = futurePostion.top + refNodeRegion.height;
          left = futurePostion.left;
        } else if (['left', 'leftTop', 'leftBottom'].indexOf(nowPlacement) >= 0) {
          top = futurePostion.top;
          left = futurePostion.left + refNodeRegion.width;
        } else if (['right', 'rightTop', 'rightBottom'].indexOf(nowPlacement) >= 0) {
          top = futurePostion.top;
          left = futurePostion.left - refNodeRegion.width;
        } else {
          top = futurePostion.top - refNodeRegion.height;
          left = futurePostion.left;
        }
      } else {
        const { popupPlacement } = options;
        if (popupPlacement === 'bottomLeft') { left = -1; top = -1; }
        else if (popupPlacement === 'bottom' || popupPlacement === 'bottomRight') { top = -1; }
        else if (popupPlacement === 'top' || popupPlacement === 'topLeft' || popupPlacement === 'topRight') {
          const { height } = refNodeRegion;
          top = top + height;
          if (popupPlacement === 'topLeft') left = -1;
        }
      }
      if (otherParams?.coverOffset) {
        top += otherParams.coverOffset?.top || 0;
        left += otherParams.coverOffset?.left || 0;
      }
    }

    // body 上设置relative场景兼容

    const doc = target.ownerDocument;
    const win = doc.defaultView;
    if (win) {
      const position = window.getComputedStyle(win.document?.body).position;
      if (position === 'relative') {
        const clientPosition = win.document.body?.getBoundingClientRect();
        const extraTop = clientPosition.top;
        const extraLeft = clientPosition.left;
        if (extraLeft) {
          left -= extraLeft;
        }
        if (extraTop) {
          top -= extraTop;
        }
      }
    }
    // 当前节点将要所处的区域
    // const isIE = ua.browser === 'IE';
    const isIE = false;
    if (isIE) {
      if (source.style.transform !== `translateX(${left}px) translateY(${top}px)`) {
        source.style.transform = `translateX(${left}px) translateY(${top}px) translateZ(1px)`;
        //@ts-ignore
        source.style.msTransform = `translateX(${left}px) translateY(${top}px) translateZ(1px)`;
      }
    } else {
      if (needRTL?.()) {
        source.style.left = 'auto';
        if (source.style.right !== `${left}px`) source.style.right = `${left}px`;
      } else {
        if (source.style.left !== `${left}px`) source.style.left = `${left}px`;
      }
      if (source.style.top !== `${top}px`) source.style.top = `${top}px`;
    }

    return adjuestPlacement;
    // if (widthSource) { 超出滚动处理，考虑弹层内部可能已有滚动效果，处理后会变成两层滚动
    //   source.style.width = `${widthSource}px`;
    //   source.style.overflow = 'auto';
    // }
  }
}