import React, { Component, ReactElement } from "react";
import ReactDOM from "react-dom";
import { Route } from "react-router-dom";
import { classnames, setTitle, ua, historyUtil, documentTitle } from '@weapp/utils';
import { DialogContainerProps } from "../types";
import { Portal } from '../../../lib';

import { findDOMNode } from '../../trigger/utils/index';
import RouteLayout from "../route-layout/index";
import { needSync } from '../../../utils/index';
class DialogContainer extends Component<DialogContainerProps> {
	dialogRef = React.createRef<React.ReactInstance>();
	dialogWrapRef = React.createRef<HTMLDivElement>();
	componentDidMount() {
		this.props?.getInstance?.({ current: this });
	}
	getDocument = (element?: HTMLElement) => {
		if (element) {
			return element.ownerDocument;
		}
		return window.document;
	}
	getRootDomNode = (): HTMLElement => {
		try {
			if (this.dialogRef.current) {
				const domNode = findDOMNode<HTMLElement>(this.dialogRef.current);
				if (domNode) {
					return domNode;
				}
			}
		} catch (err) {
		}
		return ReactDOM.findDOMNode(this) as HTMLElement;
	}
	attachParent = (popupContainer: HTMLDivElement) => {
		let mountNode: HTMLElement;
		const { getContainer } = this.props;
		mountNode = getContainer ? getContainer() || this.getDocument(this.getRootDomNode()).body : this.getDocument(this.getRootDomNode()).body;
		mountNode.appendChild(popupContainer);
	}
	getContainer = () => {
		let dialogWrap: HTMLDivElement = this.getDocument(this.getRootDomNode()).createElement('div');
		this.attachParent(dialogWrap);
		return dialogWrap;
	}
	onAfterShow = () => {
		const { onAfterShow, visible, isRouteLayout, path, isMobile, title } = this.props;
		(isMobile && path && !isRouteLayout && needSync('title', this.props)) && setTitle({ title: title }); // 给移动端通过path打开的路由层设置title
		(visible || isRouteLayout || path) && onAfterShow?.();
	}
	onAfterClose = () => {
		const { onAfterClose, visible, isRouteLayout, path } = this.props;
		(!visible || isRouteLayout || path) && onAfterClose?.();
	}
	replacePath = () => {
		const { path } = this.props;
		const historyLength = historyUtil?.mainHistory?.length;
		if (path && historyLength === 1) {
			const url = path.slice(0, path.lastIndexOf('/'))
			historyUtil.replace(url);
		}
	}
	renderDom = () => {
		const { prefixCls, children, visible, path, forceRender } = this.props;
		let dialogWrap: ReactElement | null = null;
		if (path || visible || this.dialogWrapRef.current || forceRender) {
			dialogWrap = (<Portal weId={`${this.props.weId || ''}_jb904p`}
				key="portal"
				getContainer={this.getContainer}
				didUpdate={this.onAfterShow}
			>
				<div
					ref={this.dialogWrapRef}
					className={`${prefixCls}-portal`}
				>
					{children}
				</div>
			</Portal>)
		}
		return dialogWrap;
	}
	renderRouteLayout = () => {
		const { prefixCls, children, isMobile, className, style, isRouteLayout, queueAnimProps, closeQueueAnim, title } = this.props;
		const { device } = ua;
		if ((device === 'Mobile' || isMobile) && isRouteLayout) {
			//@ts-ignore 等待utils库发版
			const routeLayoutCls = classnames(`${prefixCls}-routeLayout`, className, documentTitle?.h5Title?.className);
			return (
				<RouteLayout
					weId={`${this.props.weId || ''}_ql0bg2`}
					onAfterShow={this.onAfterShow}
					queueAnimProps={queueAnimProps}
					closeQueueAnim={closeQueueAnim}
					title={title}
					onAfterClose={this.onAfterClose}
				>
					<div key="Frame" className={routeLayoutCls} style={style}>
						{children}
					</div>
				</RouteLayout>
			)
		}
		return this.renderDom();
	}
	render() {
		const { path } = this.props;
		return path ? (<Route weId={`${this.props.weId || ''}_kb64o0`}
			key={path}
			path={path}
			render={this.renderRouteLayout}
		/>) : this.renderDom();
	}
}

export default DialogContainer;