import Loadable from '../../react-loadable';
import { MEmoticonType, MEmoticonItemType } from './types';
import { mEmoticonClsPrefix } from '../../constants/index.m';

const MEmoticon = Loadable({
  name: 'MMEmoticon', loader: () => import(
    /* webpackChunkName: "ui_m_common" */
    './MEmoticon')
}) as MEmoticonType;

const MEmoticonItem = Loadable({
  name: 'MMEmoticonItem', loader: () => import(
    /* webpackChunkName: "ui_m_common" */
    './EmoticonItem')
}) as MEmoticonItemType;

MEmoticon.defaultProps = {
  prefixCls: mEmoticonClsPrefix,
};

MEmoticon.Item = MEmoticonItem;

export type { MEmoticonProps, MEmoticonType } from './types';

export default MEmoticon;