import React from 'react';
import ReactDOM from 'react-dom';

// findDOMNode
export function findDOMNode<T = Element | Text>(
  node: React.ReactInstance | HTMLElement
): T {
  if (node instanceof HTMLElement) {
    return (node as unknown) as T;
  }
  return (ReactDOM.findDOMNode(node) as unknown) as T;
}

export function getAlignByPlacement(popupPlacement?: string, relativePostionAlign?: boolean) {
  switch(popupPlacement) {
    case 'left': return { points: relativePostionAlign ? ['', 'bt', 'el', 'bt'] : ['cl', 'at', 'el', 'bt'] };
    case 'right': return { points: relativePostionAlign ? ['el', 'bt', '', 'bt'] : ['dl', 'at' , '', 'bt'] };
    case 'top': return { points: relativePostionAlign ? ['bl', '', 'bl', 'et'] : ['al', 'ct', 'bl', 'et'] };
    case 'bottom': return { points: relativePostionAlign ? ['bl', 'et', 'bl', ''] : ['al', 'dt', 'bl', ''] };
    case 'topLeft': return { points: relativePostionAlign ? ['', '', '', 'et'] : ['cl', 'ct', '', 'et'] };
    case 'topRight': return { points: relativePostionAlign ? ['el', '', 'el', 'et'] : ['dl', 'ct' , 'el', 'et'] };
    case 'bottomRight': return { points: relativePostionAlign ? ['el', 'et', 'el', ''] : ['dl', 'dt' , 'el', ''] };
    case 'bottomLeft': return { points: relativePostionAlign ? ['', 'et', '', ''] : ['cl', 'dt', '', ''] };
    case 'leftTop': return { points: relativePostionAlign ? ['', '', 'el', ''] : ['cl', 'ct', 'el', ''] };
    case 'rightTop': return { points: relativePostionAlign ? ['el', '', '', ''] : ['dl', 'ct', '', ''] };
    case 'leftBottom': return { points: relativePostionAlign ? ['', 'et', 'el', 'et'] : ['cl', 'dt', 'el', 'et'] };
    case 'rightBottom': return { points: relativePostionAlign ? ['el', 'et', '', 'et'] : ['dl', 'dt', '', 'et'] };
    default: return {}
  }
}
