import Loadable from '../../react-loadable';
import { MPopoverProp } from './types';
import { popoverClsPrefixCls } from '../../constants/index';
const MPopover = Loadable({
  name: 'MPopover',
  loader: () => import(
    /* webpackChunkName: "ui_m_common" */
    './Popover')
}) as MPopoverProp;

MPopover.defaultProps = {
  prefixCls: popoverClsPrefixCls,
}

export type { MPopoverProps } from './types';

export default MPopover;
