import Loadable from "../../react-loadable";
import { TimelineType, TimelineItemType } from "./types"
import { timelineClsPrefix } from '../../constants/index'

const Timeline = Loadable({
  name: 'Timeline', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './Timeline')
}) as TimelineType;

// const TimelineItem = Loadable({
//   name: 'Timeline', loader: () => import(
//     /* webpackChunkName: "timeline_item"*/
//     './TimelineItem')
// }) as TimelineItemType;

Timeline.defaultProps = {
  prefixCls: timelineClsPrefix,
  leftStyle: {width: '40px'},
  middleStyle:{width:'40px', minHeight: '60px'}
};

// Timeline.Item = TimelineItem;

// Timeline.Item.defaultProps = {
//   prefixCls: timelineClsPrefix,
//   dotStyle: {width: '40px', height: '40px'},
//   // middleStyle: {height: '80px'}
// };
export type { TimelineProps , TimelineItemProps, PaginationType } from "./types";
export default Timeline;
