import { USESKELETON } from '../../constants/index';
import { mListClsPrefix, mTextareaClsPrefix } from '../../constants/index.m';
import Loadable, { LoadingType } from '../../react-loadable';
import { MTextareaProps, MTextareaType } from './types';

/* 多行文本 骨架 */
const MTextareaSkeleton: LoadingType<MTextareaProps> = (props: MTextareaProps) => USESKELETON && (
  <div style={{ height: Math.max(44, (props.rows || 1) * 25.5 + 20) }}></div>
);

const MTextarea = Loadable({ name: 'MTextarea',
  loading: MTextareaSkeleton,
  loader: () => import(
  /* webpackChunkName: 'ui_m_common_form' */
  './Textarea.m'
)}) as MTextareaType;

MTextarea.MTextareaSkeleton = MTextareaSkeleton;

MTextarea.defaultProps = {
  prefixCls: mTextareaClsPrefix,
  prefixListCls: mListClsPrefix,
}

export { MTextareaSkeleton };

export type { MTextareaProps } from './types';

export default MTextarea;
