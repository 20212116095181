import ReactDOM from 'react-dom';
import { MPhotoViewData, PhotoViewProps } from './types';
import { MPhotoView } from './index.m';
import { ua, weappSDK } from '@weapp/utils'

export default function showMImage(data: Array<MPhotoViewData>, photoIndex?: number, otherParams?: PhotoViewProps | any) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function handleClose() {
    ReactDOM.unmountComponentAtNode(div);
    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }
  };
  const render = () => {
    ReactDOM.render(<MPhotoView weId={`liwxm8`}
      data={data}
      visible={true}
      onClose={handleClose}
      photoIndex={photoIndex}
      {...otherParams}
    />, div);
  };
  if (ua.browser === 'Weapp') {
    const urlList = data.map(item => item.src)
    weappSDK.checkApi('previewImage').then(() => {
      console.info('previewImageSDKin', urlList)
      weappSDK.exec('previewImage', {
        current: photoIndex ? urlList[photoIndex] : urlList[0], // 当前显示图片的http链接，如果为相对地址则会自动拼接当前服务地址
        urls: urlList, // 需要预览的图片http链接列表
        success: function () {
          console.info('previewImageSDKsuccess', urlList)
        },
        menu: ['share', 'save']
      }).then((res) => {
        // sdk调用成功
        console.info(res);
      }).catch((err) => {
        // sdk调用失败
        console.error('sdkExecFail', err);
        // 验证不通过 调用h5方法
        render();
      })
    }).catch((err) => {
      // sdk调用失败
      console.error('sdkCheckFail', err);
      // 验证不通过 调用h5方法
      render();
    })
  } else {
    render();
  }
}
