
import Loadable from '../../react-loadable';

import { searchGroupClsPrefix } from '../../constants/index';
import { SearchGroupType } from './types';

const SearchGroup = Loadable({
  name: 'SearchGroup', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './SearchGroup')
}) as SearchGroupType;

SearchGroup.defaultProps = {
  prefixCls: searchGroupClsPrefix,
}

export type { SearchGroupProps } from './types';
export default SearchGroup;
