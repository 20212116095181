import { commentClsPrefix } from '../../../constants/index';
import { EmoticonItemConfigType } from '../../emoticon/types';
import { getEmoticonImgSrc } from '../../emoticon/utils';

const prefixCls = commentClsPrefix;

export const getAtTemplate = (id: string, name: string, type: string = "user") => {
  // const atType = type === "user" ? "inside" : type;
  const atType = type;
  return `<a contenteditable="false" class="${prefixCls}-link" data-name="${name}" data-type="${atType}" pluginname="at" data-id="${id}">@${name}</a>&nbsp;`;
}

// export const getEmoticonTemplate = (url?: string, name?: string, title?: string) => {
//   return `<img data-id=${name} pluginname="emoticon" src="${url}" style="width: 25px; height: 25px" alt=":${name}:" title=${title} />&#8203;`;
// }

export const getEmoticonTemplate = (data: EmoticonItemConfigType) => {
  let { iconKey, iconName, title, url } = data;

  if (!url) {
    url = getEmoticonImgSrc(iconName);
  }
  return `<img pluginname="emoticon" data-id=${iconKey} data-name=${iconName} src="${url}" style="width:22px" alt="${iconKey}" title=${title} />&#8203;`;
}

export const getBrowserTemplate = (id: string, name: string, module: string) => {
  return `<a pluginname="typesBrowser" data-id="${id}" data-module=${module} href="javascript:void(0)">${name}</a>&nbsp;`;
}