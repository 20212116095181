
import { FormItemItemType, FormItemType, FormItemProps, FormItemItemProps, FormRequiredMarkType } from './types';
import Skeleton, { getFormItemSkeletonHeight } from './skeleton/index';
import { Loadable, LoadingType, constants, Skeleton as SkeletonLoading } from '../../lib';
import { ua } from '@weapp/utils';

const { formItemClsPrefix, USESKELETON } = constants;
const FormItemSkeleton: LoadingType<FormItemProps> = (props) => {
  if (!USESKELETON) return <div />
  const minHeight = ua.device === 'PC' ? 40 : getFormItemSkeletonHeight(props);
  return <SkeletonLoading weId={`${props.weId || ''}_eat19n`} skeletonHeight={minHeight} skeletonItemHeight={minHeight} span={1} style={{ border: 0 }} />
};

const FormItem = Loadable({
  name: 'FormItem',
  loading: FormItemSkeleton,
  loader: () => import(
    /* webpackChunkName: "ui_form" */
    './FormItem')
}) as FormItemType;

const ItemSkeleton: LoadingType<FormItemItemProps> = () => USESKELETON && <div style={{ height: 44 }}></div>;

const Item = Loadable({
  name: 'FormItemItem',
  loading: ItemSkeleton,
  loader: () => import(
    /* webpackChunkName: "ui_form" */
    './Item'
  )
}) as FormItemItemType;

Item.ItemSkeleton = ItemSkeleton;

Item.defaultProps = {
  prefixCls: formItemClsPrefix,
  
  // 私有化环境输入后隐藏必填符号
  hideRequiredMarkAfterEdit: window?.TEAMS?.globalConfig?.hiddenMarker ?
    Boolean(Number(window?.TEAMS?.globalConfig?.hiddenMarker)) : window?.TEAMS?.privateDeploy,
}

FormItem.Item = Item;

FormItem.FormItemSkeleton = FormItemSkeleton;

FormItem.Skeleton = Skeleton;

FormItem.defaultProps = {
  prefixCls: formItemClsPrefix,
  // useSkeleton: USESKELETON,
  useSkeleton: false, // FormItem 骨架屏单独关闭
  hoverConfig: {
    fieldPropsConfig: ['readOnly'],
  },
  // 私有化环境输入后隐藏必填符号
  hideRequiredMarkAfterEdit: window?.TEAMS?.globalConfig?.hiddenMarker ?
  Boolean(Number(window?.TEAMS?.globalConfig?.hiddenMarker)) : window?.TEAMS?.privateDeploy,
  displayLocationForRequiredMark: window?.TEAMS?.globalConfig?.markPosition,
  validateHiddenItems: true,
  formItemHoverEditable:true
}

const RequiredMark = Loadable({
  name: 'RequiredMark', loader: () => import(
    /* webpackChunkName: "ui_form" */
    './RequiredMark'
  )
}) as FormRequiredMarkType;

FormItem.RequiredMark = RequiredMark;

export type {
  FormItemType, FormItemRenderProps, FormItemRenderBaseProps, FormItemProps, ItemType as FormItemFieldType,
  SingleItemProps as FormSingleItemProps, FormItemOtherParams, FormItemItemProps, FormItemItemType, FormItemWrapperProps,
  FormRequiredMarkType, FormRequiredMarkProps, FormRequiredMarkRef,
} from './types';

export default FormItem;
