import Loadable from '../../react-loadable';
import { MMultiMenuType } from './types';
import { mMultiMenuClsPrefix } from '../../constants/index.m';

const MMultiMenu = Loadable({
  name: 'MMultiMenu', loader: () => import(
    /* webpackChunkName: "m_multi_menu" */
    './MultiMenu')
}) as MMultiMenuType;

MMultiMenu.defaultProps = {
  prefixCls: mMultiMenuClsPrefix
}

export type { MMultiMenuType } from './types';

export default MMultiMenu;