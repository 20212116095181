
import Loadable from '../../../../react-loadable';
import Notice from './Notice';
import { DialogMessageProps, MessageType } from './types';

export const Message = Loadable({ name: 'Message', loader: () => import(
  /* webpackChunkName: "ui_common" */
 './Message') }) as MessageType;
 
let notice: Notice | null = null;
export function message(options: DialogMessageProps) {
  if (!notice) {
    notice = new Notice();
  }
  notice.add(options);
  function destroy() {
    notice?.pop(options);
    if (notice?.messageConfig.length === 0) notice = null;
  }
  return {
    destroy,
  };
}
