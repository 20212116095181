import { classUseMemo, middleware } from "@weapp/utils";
import React, { Component, FC, useMemo, useState } from "react";
import { uiAppName } from "../../constants";
import { AnyObj } from "../../types/common";
import { CorsComponent } from "../cors-link";
import DynamicRenderComponent from "./DynamicRenderComponent";
import { RenderType } from "./RootLayoutUtils";
import { RenderConfigType } from "./types";
import { Route } from 'react-router-dom';
import { needLayout } from "./utils";

export interface ModuleContentProps {
  module?: any;
  renderConfig?: AnyObj;
  pluginConfig?: AnyObj;
  /* 渲染内容区域包裹组件 */
  ModuleWrapComponent?: React.ComponentType<any>;
}

export interface ModuleContentState {
  module: any;
}

const ErrorContent: FC<any> = () => {
  return null;
}

export class ModuleMainRouteContent extends Component<any> {

  customRender = (module: any) => {
    const moduleCom = module.RouterMain || module.MainRouter || module.RouteMain || module.MainRoute;
    if (typeof moduleCom === 'function') {
      const Com = moduleCom;
      if(needLayout()){
        return  (
          <Route weId={`va4war`} path={`/splayout`}>
            <Com weId={`${this.props.weId || ''}_z11iqp`} />
          </Route>
        )
      }
      return (
          <Com weId={`${this.props.weId || ''}_z11iqp`} />
      );
    }
    return null;
  }

  render() {
    const { app } = this.props;
    return (
      <CorsComponent weId={`${this.props.weId || ''}_ufzqcb`}
        app={app}
        customRender={this.customRender}
      />
    )
  }
}

const PluginContent: FC<any> = (props) => {

  const [enable, setEnabled] = useState(props.enablePlugin);

  const componentProps = useMemo(() => {
    return {
      ...props.componentProps,
      onStatusChange: setEnabled,
    }
  }, [props.componentProps]);

  return enable ? (
    <DynamicRenderComponent weId={`${props.weId || ''}_rzsyou`}
      {...props}
      componentProps={componentProps}
    />
  ) : null;
}

@middleware(uiAppName, 'RootLayoutModuleContent')
class ModuleContent extends Component<ModuleContentProps> {

  renderPlugin () {
    const { pluginConfig } = this.props;

    return pluginConfig?.map((plugin: any) => {
      return <PluginContent key={plugin.id} weId={`${this.props.weId || ''}_rlswc8@${plugin.id}`} {...plugin} />
    });
  }

  renderContent () {
    const { renderConfig } = this.props;

    switch(renderConfig?.renderType) {
      case RenderType.ModuleMainRouteRender:
        return (
          <ModuleMainRouteContent key={renderConfig.id} weId={`${this.props.weId || ''}_btg0z6`} app={renderConfig.app} />
        );
        break;
      case RenderType.EcodeCustomRender:
        // ecode 自定义路由，不做渲染
        return null;
        break;
      case RenderType.MenuCustomRender:
        if (renderConfig.menuConfig?.length) {
          return (renderConfig.menuConfig as RenderConfigType[]).map((menu) => {
            return (
              <DynamicRenderComponent
                key={menu.id}
                weId={`${this.props.weId || ''}_ss7pdo@${menu.id}`}
                {...menu}
              />
            );
          });
        }
        break;
      case RenderType.PluginCustomRender:
        if (renderConfig.pluginConfig?.length) {
          return (renderConfig.pluginConfig as RenderConfigType[]).map((plugin) => {
            return (
              <DynamicRenderComponent
                key={plugin.id}
                weId={`${this.props.weId || ''}_dit2gh@${plugin.id}`}
                {...plugin}
              />
            );
          });
        }
        break;
      case RenderType.OtherRender:
        return (
          <ErrorContent key={'other'} weId={`${this.props.weId || ''}_h6pjms`} />
        );
        break;
    }
  }

  render() {
    const { ModuleWrapComponent } = this.props;
    // 渲染路由加载的内容
    let content = this.renderContent() || null;
    if (ModuleWrapComponent) {
      content = (
        <ModuleWrapComponent
          {...this.props}
          weId={`${this.props.weId || ''}_ydxs2j`}
        >
          {content}
        </ModuleWrapComponent>
      );
    }

    // 渲染插件加载的内容
    const plugin = this.renderPlugin() || null;

    return (
      <>
        {content}
        {plugin}
      </>
    );
  }
}

export default ModuleContent