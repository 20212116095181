import Loadable from '../../react-loadable';
import { QrcodeType } from './types';


const Qrcode = Loadable({
  name: 'Qrcode',
  loader: () => import(
    /* webpackChunkName: 'qrcode' */
    './Qrcode')
}) as QrcodeType;

export type { QrcodeProps, QrcodeType } from './types';

export default Qrcode;
