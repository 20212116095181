import ReactDOM from 'react-dom';
import { weappSDK } from '@weapp/utils';
import MVideo from './index.m';
import { MVideoProps } from './types';
import { getHash } from '../../utils/index';

export default function showVideo(src?: string, otherParams?: MVideoProps) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function handleClose() {
    ReactDOM.unmountComponentAtNode(div);
    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }
    otherParams?.onClose?.()
  };
  const render = () => {
    ReactDOM.render(<MVideo weId={`liveox`}
      controls
      src={src}
      {...otherParams}
      dialogProps={{
        visible: true,
        ...otherParams?.dialogProps,
        // path: path
      }}
      isDialog
      onClose={handleClose}
    />, div);
  };
  render();
}
