export const COMMON_MENU_GROUP_ID = 'common-menu';

export const SENSITIVE_KEY = 'showSensitive';

export const ENTITYGROUPCHAT_KEY = 'entityGroup';

export const HELP_KEY = 'help';

export const URL_KEY = 'url';

export const COPY_KEY = 'copy';

export const PASTE_KEY = 'paste';

export const COMMON_MENU_ORDER = [SENSITIVE_KEY, ENTITYGROUPCHAT_KEY, COPY_KEY, PASTE_KEY, HELP_KEY, URL_KEY];

