/** 敏感词匹配公共方法 */

// 获取纯文本中所有匹配的敏感词下标
const getMatcheIndexArr = (text: string, word: string) => {
  let indexArr: number[] = [];
  let newText = text;
  while ( true ){
    let index = newText.indexOf(word);
    if(index == -1) break;
    if (indexArr.length > 0) {
      index += indexArr[indexArr.length -1] + word.length;
    }
    indexArr.push(index);
    newText = text.substring(index + word.length, text.length);
  }
  return indexArr;
}

// 获取纯文本中应敏感词的开始下标和结束下标
const getMatcheArr = (text: string, word: string, len: number) => {
  let arr: any[] = [];
  const textIndexArr = getMatcheIndexArr(text, word);
  const wordLen = word.length;
  textIndexArr.forEach((i: number, idx: number) => {
    let startIdx = i - len;
    let endIdx = i + wordLen + len;
    // 开始下标: 不小于 0, 不小于 前一个敏感词下标+wordLen
    // 结束下标: 不大于 文本的长度-1, 不大于 下一个敏感词下标
    if (startIdx < 0) {
      startIdx = 0
    }
    if (endIdx > text.length) {
      endIdx = text.length
    }
    if (idx > 0 && textIndexArr[idx -1] + wordLen > startIdx) {
      startIdx = textIndexArr[idx -1] + wordLen;
    }
    if (idx < textIndexArr.length -1 && textIndexArr[idx + 1] < endIdx) {
      endIdx = textIndexArr[idx + 1];
    }
    arr.push({ startIdx, endIdx, idx: i });
  })
  return arr;
}

// 获取匹配后的文本
const getMatchedNewText = (data: string, word: string, len: number) => {
  const wordLen = word.length;
  const tagRegex = /<\/?.+?\/?>/g;
  const text = data.replace(tagRegex, '');
  // 1 - 事先取出html中的标签
  const matchTagList = data.match(tagRegex);
  // 2 - html中标签替换为 @$_$@
  const splitStr = data.replace(tagRegex, '@$_$@');
  // 3 - @$_$@ 截断替换后的html数组
  const textArr = splitStr.split('@$_$@');
  // 4 - 得到对应敏感词的本身下标、截取开始下标和结束下标 组成的数组
  const textIndexArr = getMatcheArr(text, word, len);
  
  // 5 - 对应的每段中符合匹配的敏感词和敏感词前后的文本,按照分段替换为高亮的敏感词和高亮的背景效果
  let itemLens = 0;
  const newTextArr = textArr.map(item => {
    let subArr: any[] = []; // 存储截取的下标和替代的标识
    if (item) {
      const itemLen = item.length;
      const itemEndIdx = itemLens + itemLen; // 当前文本的结束下标
      const itemStartIdx = itemLens > 0 ? itemLens : 0;  // 当前文本的开始下标
      textIndexArr.map(obj => {
        const { startIdx, endIdx, idx } = obj;
        let startStr: string = '';
        let middleStr: string = '';
        let endStr: string = '';
        // item替换成对应的高亮效果: 开始小标不大于结束的位置,结束下标不小于开始的位置
        if (!(startIdx >= itemEndIdx || endIdx <= itemStartIdx)) {
          // 敏感词对应的下标,和当前的文本小标进行比对
          const curStartIdex = startIdx <  itemStartIdx ? itemStartIdx : startIdx;
          const curEndIdex = endIdx > itemEndIdx ? itemEndIdx : endIdx;
          const curIdex = idx > itemEndIdx ? itemEndIdx : (idx < itemStartIdx ? itemStartIdx : idx);

          // 截取开始段
          const curStartStr = text.substring(curStartIdex, curIdex);
          // 截取敏感词段
          const subIdx = idx + wordLen > curEndIdex ? curEndIdex : idx + wordLen;
          const curStr = text.substring(curIdex, subIdx);
          // 截取结束段
          const curEndStr = text.substring(subIdx, curEndIdex);

          // 存储当前的开始下标、结束下标、替换的标识
          if (curStartStr || curStr || curEndStr) {
            startStr = curStartStr ? `<span class="rich-text-highlight-bg">${curStartStr}</span>` : '';
            middleStr = curStr ? `<span class="rich-text-highlight-active">${curStr}</span>` : '';
            endStr = curEndStr ? `<span class="rich-text-highlight-bg">${curEndStr}</span>` : '';
            subArr.push({
              start: curStartIdex - itemLens,
              end: curEndIdex - itemLens,
              replace: startStr+middleStr+endStr
            });
          }
        }
      })
      itemLens += itemLen;

      let newItem = '';
      const subArrLen = subArr.length;
      if (subArrLen === 1) {
        newItem = item.substring(0, subArr[0].start) + subArr[0].replace + item.substring(subArr[0].end, itemLen);
      } else if (subArrLen > 1) {
        subArr?.forEach(({ start, end, replace }, index) => {
          if (index === 0) {
            newItem += item.substring(0, start);
          }
          const realEnd = index === subArr.length - 1 ? item.length : subArr[index + 1].start;
          newItem += replace + item.substring(end, realEnd);
        })
      } else {
        newItem = item;
      }
      return newItem;
    }
    return item;
  });

  // 6 - 替换后的截断重新组装
  let newStr = newTextArr.join('@$_$@');
  // 7 - 按照顺序还原标签
  matchTagList?.forEach((matchTagStr) => {
    newStr = newStr.replace('@$_$@', matchTagStr);
  });
  // 8 - 返回新的替换完成的html
  return newStr;
}

// 获取匹配后的html - 行内匹配(例如标点匹配)
const getMatchedNewStr = (data: string, word: string, len: number) => {
  let newData = data;
  const tagRegex = /<\/?.+?\/?>/g;
  const text = data.replace(tagRegex, '');
  const textRegx = new RegExp(word,'g');
  
  if (textRegx.test(text)) { // 存在能匹配的文本
    // 1 - 匹配所有的标点
    const punctuationReg = /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g;
    const punctuationMatchList = data.match(punctuationReg);

    if (punctuationMatchList) {
      // 2 - 将标点替换为指定的字符@$_punctuation_$@
      const splitStr = data.replace(punctuationReg, '@$_punctuation_$@');
      // 3 - 按照标点截断,分段匹配
      const list = splitStr.split('@$_punctuation_$@');
      const newList = list.map(splitStr => {
        const newSplitStr = getMatchedNewText(splitStr, word, len);
        return newSplitStr;
      });
      newData = newList.join('@$_punctuation_$@');
      // 按照顺序还原标点
      punctuationMatchList?.forEach((punctuatio) => {
        newData = newData.replace('@$_punctuation_$@', punctuatio);
      });
    } else {
      newData = getMatchedNewText(newData, word, len);
    }
  }
  return newData;
}

// childNodes进行逐个匹配替换
const matchNodeList = (nodeList: NodeListOf<ChildNode>, word: string, len: number) => {
  nodeList.forEach((node: any) => {
    if (node.nodeType === 3) { // 纯文本 || br (nodeValue为null)
      const nodeValue = node.nodeValue;
      if (nodeValue && nodeValue.match(word)) {
        const newNodeValue = getMatchedNewStr(nodeValue, word, len);
        node.nodeValue = newNodeValue;
      }
    } else if (node.nodeType === 1) { // 元素
      if (node.nodeName === 'div' || node.nodeName === 'TABLE') { // div table 再往下取元素节点
        matchNodeList(node.childNodes, word, len);
      } else if (node.nodeName !== 'BR') { // br 不做处理
        const innerText = node.innerText || '';
        if (innerText.match(word)) {
          const newInnertml = getMatchedNewStr(node.innerHTML, word, len);
          node.outerHTML = newInnertml;
        }
      }
    }
  })
}

// 敏感词匹配 (匹配 敏感词和左右各两个字 的匹配逻辑,方法先保留)
const matchSensitiveWords_backup = (data: string, word: string, len: number = 2) => {
  if (!data) return '';
  if (!word || data.trim() === '' || !data.match(word)) return data;

  // html 换成 node节点 进行匹配和替换
  const container = document.createElement('div');
  container.innerHTML = data;
  const newData = matchNodeList(container.childNodes, word, len);
  return newData;
}

// 敏感词匹配
export const matchSensitiveWords = (data: string, wordList: string[]) => {
  if (!data) return '';

  // 1 - 匹配前先取消匹配
  let newData = unMatchSensitiveWords(data);

  // 2 - 遍历wordList 替换 符合条件的文本
  wordList.forEach((word: string) => {
    const reg = new RegExp(word, 'g');
    if (word && word.trim() !== '' && reg.test(newData)) {
      newData = newData.replace(reg, `<span class="rich-text-highlight-active">${word}</span>`);
    }
  })

  return newData;
}

// 去掉敏感词匹配效果
export const unMatchSensitiveWords = (data: string) => {
  let newData = data;
  // 1 - 匹配class为 rich-text-highlight-bg 和 rich-text-highlight-active 的完整span标签
  // const reg = /<span[\s\S]*?(class="rich-text-highlight-bg"|class="rich-text-highlight-active")[^>]*?>([\s\S]*?)<\/span>/g;
  const reg = /<span class="rich-text-highlight-active">([\s\S]*?)<\/span>/g;
  const matchedList = data.match(reg);
  // 2 - 编辑匹配的完整标签,去掉对应的span标签,只保留文本内容
  matchedList?.forEach(item => {
    const spanReg = /(<\/?span.*?>)/g;
    const newItem = item.replace(spanReg, '');
    newData = newData.replace(item, newItem);
  });
  return newData;
}