import { resourcePanelClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { ResourcePanelType } from './types';
// import ResourcePanel from './ResourcePanel';


const ResourcePanel = Loadable({
  name: 'ResourcePanel',
  loader: () => import( 
    /* webpackChunkName: "resource-panel" */
    './ResourcePanel')
}) as ResourcePanelType;


ResourcePanel.defaultProps = {
  prefixCls: resourcePanelClsPrefix
};

export type { ResourcePanelProps } from './types';

export default ResourcePanel;
