import Loadable from "../../react-loadable";
import { mPickerViewClsPrefix } from "../../constants/index.m"
import { MPickerViewType } from "./types"

const MPickerView = Loadable({
  name: 'MPickerView', loader: () => import(
    /* webpackChunkName: "m_picker" */
    './MPickerView')
}) as MPickerViewType;

MPickerView.defaultProps = {
  prefixCls: mPickerViewClsPrefix,
  columnHeight: 252,
  itemHeight: 36,
  // scrollerType: 'native'
};

export type { MPickerViewProps } from "./types";
export default MPickerView;
