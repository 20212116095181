import { Dialog } from "../../../lib";
import {
  FrequencyRuleType,
  FrequencyType,
  RepeatFrequencyDataProps,
  RegularRuleType,
  AppointRuleType,
} from "../types";
import {
  getHourOptions,
  getWeekDayOptions,
  getMonthOptions,
  getQuarterOptions,
  getSortSelectData,
  getDayOptions,
  getDateOptions,
  getWeekOptions,
  getQuarterMonthOptions,
} from "./constants";
import { getLabel } from "@weapp/utils";
import { isCnWord } from "../../../utils/index";

// 是否需要隐藏 触发时间 选项
export function hideTriggerTime(frequency: FrequencyType) {
  return frequency === "byHour" || frequency === "byMinute";
}

// 判断是否只有regular配置
export function isRegular(frequency: FrequencyType) {
  return (
    frequency === "byDay" || frequency === "byYear" || frequency === "byMinute"
  );
}

// 初始化的时候判断数据字段是否为空，补全默认值操作
export function compareFrequencyRuleData(
  defData: FrequencyRuleType,
  propData: FrequencyRuleType
) {
  const { type, regularRule, appointRule } = defData || {};
  const {
    type: _type,
    regularRule: _regularRule,
    appointRule: _appointRule,
  } = propData || {};
  return {
    type: _type || type,
    regularRule: {
      ...regularRule,
      ..._regularRule,
    },
    appointRule: {
      ...appointRule,
      ..._appointRule,
    },
  } as FrequencyRuleType;
}

// 配置初始化频率规则数据，默认展示第一个选项
export function initDefFrequencyRuleData() {
  return {
    type: "regular",
    regularRule: {
      times: 1,
      appointTimesDatas: ["MO"],
      quarterMonth: ["1"],
      appointMonths: ["1"],
      sortByDateOrWeek: "date",
      sort: "head",
      appointWeeks: ["1"],
      appointDays: ["MO"],
      appointMonthDays: ["1"],
      appointQuarters: ["one"],
      appointQuarterDays: ["1"],
    },
    appointRule: {
      quarterMonth: ["1"],
      appointQuarters: ["1"],
      appointHourDatas: ["0"],
      appointMonthDays: ["1"],
      appointMonths: ["1"],
      appointTimesDatas: ["MO"],
      sortByDateOrWeek: "date",
      sort: "head",
      appointWeeks: ["1"],
      appointDays: ["MO"],
    },
  } as FrequencyRuleType;
}

// 校验规则
export function validateRule(
  frequency: FrequencyType,
  type: "regular" | "appoint",
  sortByDateOrWeek?: "date" | "week"
): string[] {
  let regularValidateKey: string[] = [],
    appointValidateKey: string[] = [];
  switch (frequency) {
    case "byMinute":
      regularValidateKey = ["times"];
      break;

    case "byHour":
      regularValidateKey = ["times"];
      appointValidateKey = ["appointHourDatas"];
      break;

    case "byDay":
      regularValidateKey = ["times"];
      break;

    case "byWeek":
      regularValidateKey = ["times", "appointTimesDatas"];
      appointValidateKey = ["appointTimesDatas"];
      break;

    case "byMonth":
      regularValidateKey =
        sortByDateOrWeek === "date"
          ? ["times", "sortByDateOrWeek", "sort", "appointMonthDays"]
          : ["times", "sortByDateOrWeek", "appointWeeks", "appointDays"];
      appointValidateKey =
        sortByDateOrWeek === "date"
          ? ["appointMonths", "sortByDateOrWeek", "sort", "appointMonthDays"]
          : [
              "appointMonths",
              "sortByDateOrWeek",
              "appointWeeks",
              "appointDays",
            ];
      break;

    case "byQuarter":
      regularValidateKey =
        sortByDateOrWeek === "date"
          ? [
              "times",
              "quarterMonth",
              "sortByDateOrWeek",
              "sort",
              "appointMonthDays",
            ]
          : [
              "times",
              "quarterMonth",
              "sortByDateOrWeek",
              "appointWeeks",
              "appointDays",
            ];
      appointValidateKey =
        sortByDateOrWeek === "date"
          ? [
              "appointQuarters",
              "quarterMonth",
              "sortByDateOrWeek",
              "sort",
              "appointMonthDays",
            ]
          : [
              "appointQuarters",
              "quarterMonth",
              "sortByDateOrWeek",
              "appointWeeks",
              "appointDays",
            ];
      break;

    case "byYear":
      regularValidateKey =
        sortByDateOrWeek === "date"
          ? [
              "times",
              "appointMonths",
              "sortByDateOrWeek",
              "sort",
              "appointMonthDays",
            ]
          : [
              "times",
              "appointMonths",
              "sortByDateOrWeek",
              "appointWeeks",
              "appointDays",
            ];
      break;
  }
  // “分钟” “年” 和 “日” 没有指定规则
  if (isRegular(frequency)) return regularValidateKey;
  return type === "regular" ? regularValidateKey : appointValidateKey;
}

// 校验规则是否填写完毕
export function validateFrequency(data: RepeatFrequencyDataProps) {
  const {
    startTime,
    frequency,
    frequencyRule,
    showOverRepeat,
    overRepeatType,
  } = data;
  const { type, regularRule, appointRule } = frequencyRule || {};
  const ruleData = type === "regular" ? regularRule : appointRule;

  // 开始时间
  if (!startTime) {
    Dialog.message({
      type: "error",
      content: getLabel("236717", "请设置开始时间"),
    });
    return false;
  }

  // 没有规则数据
  if (!ruleData) {
    Dialog.message({
      type: "error",
      content: getLabel("236718", "请设置定时频率"),
    });
    return false;
  }

  // 结束重复的判断
  if (showOverRepeat && overRepeatType && overRepeatType !== "never") {
    const _key =
      overRepeatType.slice(0, 1).toUpperCase() + overRepeatType.slice(1);
    // @ts-ignore
    if (!data[`overRepeat${_key}`]) {
      Dialog.message({
        type: "error",
        content: getLabel("236719", "请设置结束重复的配置"),
      });
      return false;
    }
  }

  // 如果是 “年” 或 “月”，没有对应type的rule或者没有sortByDateOrWeek 则不需要进一步校验
  if (
    (frequency === "byYear" || frequency === "byMonth") &&
    (!ruleData || !ruleData.sortByDateOrWeek)
  ) {
    Dialog.message({
      type: "error",
      content: getLabel("236718", "请设置定时频率"),
    });
    return false;
  }

  // 规则判断是否控制以及多选下拉框是否为空数组
  const validateKeys = validateRule(frequency, type, ruleData.sortByDateOrWeek);
  for (let i = 0; i < validateKeys.length; i++) {
    // @ts-ignore
    if (!ruleData[validateKeys[i]] || ruleData[validateKeys[i]].length === 0) {
      Dialog.message({
        type: "error",
        content: getLabel("236718", "请设置定时频率"),
      });
      return false;
    }
  }
  return true;
}

// 格式化频率参数
export function getFormartFrequency(data: FrequencyType, appoint?: boolean) {
  let target;
  switch (data) {
    case "byMinute":
      target = "MINUTELY";
      break;
    case "byHour":
      target = !appoint ? "HOURLY" : "DAILY";
      break;
    case "byDay":
      target = "DAILY";
      break;
    case "byWeek":
      target = !appoint ? "WEEKLY" : "MONTHLY";
      break;
    case "byMonth":
      target = !appoint ? "MONTHLY" : "YEARLY";
      break;
    case "byQuarter":
      target = !appoint ? "QUARTERLY" : "YEARLY";
      break;
    case "byYear":
      target = "YEARLY";
      break;
  }
  return target;
}

// 格式化时间格式
export function getFormartTime(time: string | number) {
  const d = new Date(time);
  if (isNaN(d.getTime())) {
    return "";
  }
  const y = d.getFullYear();
  const m = d.getMonth() + 1;
  const D = d.getDate();
  const H = d.getHours();
  const M = d.getMinutes();
  const S = d.getSeconds();

  const hm = `${H > 9 ? H : "0" + H}:${M > 9 ? M : "0" + M}`;
  const date = `${y}-${m > 9 ? "" : "0"}${m}-${D > 9 ? "" : "0"}${D} ${hm}:00`;
  return date.replace(/-/g, "").replace(" ", "T").replace(/:/g, "");
}

// 格式化触发时间格式
export function getFormartTriggerTime(time: string | number) {
  const d = new Date(time);
  if (isNaN(d.getTime())) {
    return "";
  }
  const H = d.getHours();
  const M = d.getMinutes();

  const hm = `${H > 9 ? H : "0" + H}:${M > 9 ? M : "0" + M}`;
  return hm;
}

// 拼接week 、day
function getDay(weeks: string[], days: string[]) {
  const arr: string[] = [];
  weeks.map((week) => {
    days.map((day) => {
      arr.push(`${week}${day}`);
    });
  });
  return arr.join(",");
}

function getFormartRuleItem(key: string, value: any) {
  return `${key}=${value}`;
}

// 月 季度 年相同规则
function monthYearSameRule(
  rule: RegularRuleType | AppointRuleType | undefined
) {
  if (!rule) return [];
  const {
    sortByDateOrWeek,
    appointWeeks,
    appointDays,
    sort,
    appointMonthDays,
  } = rule || {};
  appointWeeks?.sort((a: any, b: any) => a - b);
  const targetStrArr = [];
  if (sortByDateOrWeek === "week") {
    // targetStrArr.push(getFormartRuleItem("BYSETPOS", appointWeeks?.join(",")));
    appointWeeks &&
      appointDays &&
      targetStrArr.push(
        getFormartRuleItem("BYDAY", getDay(appointWeeks, appointDays))
      );
  } else if (sortByDateOrWeek === "date") {
    let _appointMonthDays = appointMonthDays?.sort((a: any, b: any) => a - b);
    if (sort === "tail") {
      _appointMonthDays = _appointMonthDays?.map((item) => `-${item}`);
    }
    targetStrArr.push(
      getFormartRuleItem("BYMONTHDAY", _appointMonthDays?.join(","))
    );
  }
  return targetStrArr;
}

// 季度相同规则
function quarterSameRule(rule: RegularRuleType | AppointRuleType | undefined) {
  if (!rule) return [];
  const { quarterMonth } = rule;
  quarterMonth?.sort((a: any, b: any) => a - b);

  const targetStrArr = [];
  if (quarterMonth) {
    targetStrArr.push(
      getFormartRuleItem("BYQUARTERMONTH", quarterMonth.join(","))
    );
  }
  return targetStrArr;
}

// 格式化数据
export function getFormartRuleString(data: RepeatFrequencyDataProps) {
  const {
    startTime,
    frequency,
    showOverRepeat,
    overRepeatType,
    overRepeatDate,
    overRepeatTimes,
    frequencyRule: { regularRule, appointRule, type },
  } = data;
  const targetStrArr = [];
  // 固定字段 结束时间
  targetStrArr.push(getFormartRuleItem("DTSTART", getFormartTime(startTime)));
  // 固定字段 结束重复
  if (showOverRepeat) {
    if (overRepeatType === "date" && overRepeatDate) {
      targetStrArr.push(`UNTIL=${getFormartTime(overRepeatDate)}`);
    } else if (overRepeatType === "times" && overRepeatTimes) {
      targetStrArr.push(`COUNT=${overRepeatTimes}`);
    }
  }
  // 根据frequency 配置对应的规则
  if (type === "regular") {
    targetStrArr.push(`FREQ=${getFormartFrequency(frequency)}`);
    const { times, appointMonths, appointTimesDatas } = regularRule || {};
    appointMonths?.sort((a: any, b: any) => a - b);
    switch (frequency) {
      case "byMinute":
        targetStrArr.push(getFormartRuleItem("INTERVAL", times));
        break;
      case "byHour":
        targetStrArr.push(getFormartRuleItem("INTERVAL", times));
        break;
      case "byDay":
        targetStrArr.push(getFormartRuleItem("INTERVAL", times));
        break;
      case "byWeek":
        targetStrArr.push(getFormartRuleItem("INTERVAL", times));
        targetStrArr.push(getFormartRuleItem("BYDAY", appointTimesDatas));
        break;
      case "byMonth":
        targetStrArr.push(getFormartRuleItem("INTERVAL", times));
        targetStrArr.push(...monthYearSameRule(regularRule));
        break;
      case "byQuarter":
        targetStrArr.push(getFormartRuleItem("INTERVAL", times));
        targetStrArr.push(...quarterSameRule(regularRule));
        targetStrArr.push(...monthYearSameRule(regularRule));
        break;
      case "byYear":
        targetStrArr.push(getFormartRuleItem("INTERVAL", times));
        targetStrArr.push(
          getFormartRuleItem("BYMONTH", appointMonths?.join(","))
        );
        targetStrArr.push(...monthYearSameRule(regularRule));
        break;
    }
  } else if (type === "appoint") {
    targetStrArr.push(`FREQ=${getFormartFrequency(frequency, true)}`);
    const {
      appointMonths,
      appointTimesDatas,
      appointQuarters,
      appointHourDatas,
    } = appointRule || {};
    appointMonths?.sort((a: any, b: any) => a - b);
    appointQuarters?.sort((a: any, b: any) => a - b);
    switch (frequency) {
      case "byMinute":
        break;
      case "byHour":
        targetStrArr.push(getFormartRuleItem("BYHOUR", appointHourDatas));
        targetStrArr.push(getFormartRuleItem("BYMINUTE", 0));
        targetStrArr.push(getFormartRuleItem("BYSECOND", 0));
        break;
      case "byDay":
        break;
      case "byWeek":
        targetStrArr.push(getFormartRuleItem("INTERVAL", 1));
        targetStrArr.push(getFormartRuleItem("BYDAY", appointTimesDatas));
        break;
      case "byMonth":
        targetStrArr.push(
          getFormartRuleItem("BYMONTH", appointMonths?.join(","))
        );
        targetStrArr.push(...monthYearSameRule(appointRule));
        break;
      case "byQuarter":
        targetStrArr.push(
          getFormartRuleItem("BYQUARTER", appointQuarters?.join(","))
        );
        targetStrArr.push(...quarterSameRule(appointRule));
        targetStrArr.push(...monthYearSameRule(appointRule));
        break;
      case "byYear":
        break;
    }
  }
  return targetStrArr.join(";");
}

export function formatFormData(data: RepeatFrequencyDataProps) {
  const keys = Object.keys(data);
  keys.map((item: string) => {
    // @ts-ignore
    if (!data[item] && data[item] !== 0) delete data[item];
  });
  return data;
}

// 校验通过抛出数据
export function getFormatFrequencyData(data: RepeatFrequencyDataProps) {
  if (validateFrequency(data)) {
    const targetRuleString = getFormartRuleString(data);
    const triggerTime = hideTriggerTime(data.frequency)
      ? ""
      : data.triggerTime || "";
    return {
      basicRule: targetRuleString,
      restDayConfig: data.restDayConfig,
      formData: formatFormData(data),
      triggerTime,
    };
  }
}

// 展示数据格式化
export const formartContentShow = (
  value: any[],
  data: any[],
  sign?: boolean
): any => {
  if (value.length === 0) return "";
  let target: any[] = [];
  if (Array.isArray(data) && data.length > 0) {
    data.map((item) => {
      if (value.includes(item.id)) target.push(item.content);
    });
    let _target = target.join(",");
    return sign ? getLabel("236748", "第$x天").replace("$x", _target) : _target;
  }
  return "";
};

// 格式化数据释义
export const definitionRule = (data: RepeatFrequencyDataProps) => {
  const {
    startTime,
    frequency,
    frequencyRule,
    restDayConfig,
    overRepeatType,
    overRepeatDate,
    overRepeatTimes,
    triggerTime = "",
  } = data;
  const { regularRule, appointRule, type } =
    frequencyRule || initDefFrequencyRuleData();
  const targetData = type === "appoint" ? appointRule : regularRule;
  const {
    sortByDateOrWeek,
    sort,
    appointWeeks,
    appointDays,
    appointMonthDays,
  } = targetData || {};

  let _triggerTime = triggerTime || getFormartTriggerTime(startTime);
  let sortWord: string = "",
    lastWord: string = "";
  if (sortByDateOrWeek === "date") {
    getSortSelectData().map((item) => {
      if (item.id === sort) {
        // @ts-ignore
        sortWord = item.content;
      }
    });
    if (sort !== "appoint") {
      lastWord = formartContentShow(appointMonthDays || [], getDayOptions(), true);
    } else {
      lastWord = formartContentShow(appointMonthDays || [], getDateOptions());
    }
  } else if (sortByDateOrWeek === "week") {
    sortWord = `[${formartContentShow(appointWeeks || [], getWeekOptions())}]`;
    lastWord = formartContentShow(appointDays || [], getWeekDayOptions());
  }

  let frequencyWord = "",
    overRepeatWord = "",
    restDayConfigWord = "";
  if (type === "regular") {
    const { times, appointTimesDatas, quarterMonth, appointMonths } =
      regularRule || {};
    switch (frequency) {
      case "byMinute":
        frequencyWord = getLabel("236725", "每$x分钟触发一次").replace(
          "$x",
          `[${times}]`
        );
        break;
      case "byHour":
        frequencyWord = getLabel("236726", "每$x小时触发一次").replace(
          "$x",
          `[${times}]`
        );
        break;
      case "byDay":
        frequencyWord = getLabel("236727", "每$x日$y触发一次")
          .replace("$x", `[${times}]`)
          .replace("$y", `[${_triggerTime}]`);
        break;
      case "byWeek":
        frequencyWord = getLabel("236728", "每$x周$y触发一次")
          .replace("$x", `[${times}]`)
          .replace(
            "$y",
            `[${formartContentShow(
              appointTimesDatas || [],
              getWeekDayOptions()
            )}][${_triggerTime}]`
          );
        break;
      case "byMonth":
        frequencyWord = getLabel("236799", "每$a月$b触发一次")
          .replace("$a", `[${times}]`)
          .replace("$b", `${sortWord}[${lastWord}][${_triggerTime}]`);
        break;
      case "byQuarter":
        frequencyWord = getLabel("236800", "每$a季度$b触发一次")
          .replace("$a", `[${times}]`)
          .replace(
            "$b",
            `[${formartContentShow(
              quarterMonth || [],
              getQuarterMonthOptions()
            )}]${sortWord}[${lastWord}][${_triggerTime}]`
          );
        break;
      case "byYear":
        frequencyWord = getLabel("236801", "每$a年$b触发一次")
          .replace("$a", `[${times}]`)
          .replace(
            "$b",
            `[${formartContentShow(
              appointMonths || [],
              getMonthOptions()
            )}]${sortWord}[${lastWord}][${_triggerTime}]`
          );
        break;
    }
  } else if (type === "appoint") {
    const {
      appointHourDatas,
      appointTimesDatas,
      appointMonths,
      appointQuarters,
      quarterMonth,
    } = appointRule || {};
    switch (frequency) {
      case "byHour":
        frequencyWord = getLabel("236732", "指定$a触发").replace(
          "$a",
          `[${formartContentShow(appointHourDatas || [], getHourOptions())}]`
        );
        break;
      case "byWeek":
        frequencyWord = getLabel("236732", "指定$a触发").replace(
          "$a",
          `[${formartContentShow(
            appointTimesDatas || [],
            getWeekDayOptions()
          )}][${_triggerTime}]`
        );
        break;
      case "byMonth":
        frequencyWord = getLabel("236732", "指定$a触发").replace(
          "$a",
          `[${formartContentShow(
            appointMonths || [],
            getMonthOptions()
          )}]${sortWord}[${lastWord}][${_triggerTime}]`
        );
        break;
      case "byQuarter":
        frequencyWord = getLabel("236732", "指定$a触发").replace(
          "$a",
          `[${formartContentShow(
            appointQuarters || [],
            getQuarterOptions()
          )}][${formartContentShow(
            quarterMonth || [],
            getQuarterMonthOptions()
          )}]${sortWord}[${lastWord}][${_triggerTime}]`
        );
        break;
    }
  }
  if (restDayConfig) {
    if (restDayConfig === "never") {
      restDayConfigWord = getLabel("236720", "非工作日不触发");
    } else if (restDayConfig === "nextWorkDay") {
      restDayConfigWord = getLabel("236721", "非工作日推迟到下一工作日触发");
    } else if (restDayConfig === "normal") {
      restDayConfigWord = getLabel("236722", "非工作日正常触发");
    }
  }

  if (overRepeatType) {
    if (overRepeatType === "never") {
      overRepeatWord = getLabel("236723", "永不结束");
    } else if (overRepeatType === "date") {
      overRepeatWord = `[${overRepeatDate}]${getLabel("236747", "结束")}`;
    } else if (overRepeatType === "times") {
      overRepeatWord = getLabel("236724", "重复$x次后结束").replace(
        "$x",
        `[${overRepeatTimes}]`
      );
    }
  }
  return isCnWord()
    ? `${frequencyWord}，${overRepeatWord}，${restDayConfigWord}`
    : `${frequencyWord}. ${overRepeatWord} ${restDayConfigWord}`;
};
