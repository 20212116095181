import { Component } from "react";
// @ts-ignore
import { middleware, moduleRouter } from '@weapp/utils';
import { CorsSwitch } from './index';
import { CorsRouterProps } from './types';
import { uiAppName } from '../../constants/index';
import { getHash } from '../../utils/index';

@middleware(uiAppName, 'CorsRouter')
export default class CorsRouter extends Component<CorsRouterProps, any> {
  static displayName = "CorsRouter";

  uuid: string;

  constructor(props: CorsRouterProps) {
    super(props);
    let pathIsUsed: boolean = true;
    // 添加监听事件
    window.document.addEventListener(moduleRouter.rootPathHander.eventName, this.updateEffact);
    // 生成唯一标识
    this.uuid = props.weId + getHash(6);
    // 将当前组件的weId和rootPath注册到moduleRouter中，如果已经注册过，则无需再渲染
    moduleRouter.rootPathHander.registerRootPath(this.uuid, props.rootPath);
    pathIsUsed = !moduleRouter.rootPathHander.isRootPathEffect(this.uuid, props.rootPath);
    this.state = {
      pathIsUsed,
    }
  }

  componentWillUnmount() {
    // @ts-ignore
    moduleRouter.rootPathHander.deleteRootPath(this.uuid, this.props.rootPath);
    // @ts-ignore
    window.document.removeEventListener(moduleRouter.rootPathHander.eventName, this.updateEffact);
  }

  updateEffact = () => {
    // @ts-ignore
    const pathIsUsed = !moduleRouter.rootPathHander.isRootPathEffect(this.uuid, this.props.rootPath);
    if (pathIsUsed !== this.state?.pathIsUsed) {
      this.setState({ pathIsUsed });
    }
  }

  render() {
    const { rootPath } = this.props;
    const { pathIsUsed } = this.state;
    const paths = moduleRouter?.getPaths?.() || [];
    return (
      <>
        {!pathIsUsed && <CorsSwitch weId={`${this.uuid || ''}_b5mnlx`} rootPath={rootPath} paths={paths} />}
      </>
    );
  }
}

