import Loadable from '../../react-loadable';
import { CollapseType, PanelType }  from './types';

const Collapse = Loadable({ name: 'Collapse', loader: () => import(
    /* webpackChunkName: "ui_common_less" */
    './Collapse') }) as CollapseType;

const Panel = Loadable({ name: 'Panel', loader: () => import(
    /* webpackChunkName: "ui_common_less" */
    './Panel') }) as PanelType;

Collapse.Panel = Panel;

export type { CollapseType, PanelType, ICollapseProps, ICollapsePanelProps } from './types';

export default Collapse;
