import Loadable from "../../../react-loadable";
import { AnyObj } from "../../../types/common"
import { FormLayoutProps } from "../../form";
import { FormSingleItemProps } from '../../form-item/index';
import { commonRelationsRules } from "../template";
import { LinkFormDataTemplateType } from "./LinkFormDataTemplate";
import { LinkFormSingleDataTemplateType } from "./LinkFormSingleDataTemplate";

const LinkFormDataTemplate = Loadable({
  name: 'LinkFormDataTemplate', loader: () => import(
    /* webpackChunkName: "card_detail_form" */
    './LinkFormDataTemplate')
}) as LinkFormDataTemplateType;

const LinkFormSingleDataTemplate = Loadable({
  name: 'LinkFormSingleDataTemplate', loader: () => import(
    /* webpackChunkName: "card_detail_form" */
    './LinkFormSingleDataTemplate')
}) as LinkFormSingleDataTemplateType;

const CustomComponent: AnyObj = {
  'LinkFormDataTemplate': LinkFormDataTemplate,
  // 'LinkEbuilder': LinkFormDataTemplate,
}

const CustomFormItemComponent: AnyObj = {
  'LinkFormSingleDataTemplate': LinkFormSingleDataTemplate,
  // 'linkEbuilderSingle': LinkFormSingleDataTemplate,
}

/* 注册字段联动规则 */
const register = (item: FormSingleItemProps, _this: any, key: string, col: FormLayoutProps, otherParams: AnyObj) => {
  if (item.common && item.itemType === 'BROWSER') {
    const type = item?.browserBean?.type as string;
    if (type && commonRelationsRules[type]) {
      // 关联数据字段浏览框和布局item key的对应关系(browserBean传参规则固定)
      if (type === 'frpt_report') { // 关联数据字段配置
        import(
          /* webpackChunkName: "card_detail_form" */
          '../link-form-data/store').then((defaultModule) => {
            const storeName = 'LinkFormDataStore'; // store名称
            const storeInstanceName = 'linkFormData'; // store实例key值, 对应registerRules中存的key值
            const storeInstance = new defaultModule.default(_this, { // store实例，注册时创建
              storeName,
              storeInstanceName, // 用于绑定store与组件
            });
            storeInstance?.init(storeInstanceName, item, key, col, otherParams);
          })
      } else if (type === 'ebuilderMatter') {
        import(
          /* webpackChunkName: "card_detail_form" */
          '../link-ebuilder-matter/store').then((defaultModule) => {
            const storeName = 'LinkEbuilderMatterStore'; // store名称
            const storeInstanceName = 'linkEbuilderMatter'; // store实例key值, 对应registerRules中存的key值
            const storeInstance = new defaultModule.default(_this, { // store实例，注册时创建
              storeName,
              storeInstanceName, // 用于绑定store与组件
            });
            storeInstance?.init(storeInstanceName, item, key, col, otherParams);
          })
      }
    }
  }
}

export {
  CustomComponent,
  CustomFormItemComponent,
  register,
}