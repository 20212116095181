
import ReactDOM from 'react-dom';
import { mDialogClsPrefix, mButtonGroupClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { MDialogType, MDialogProps, ButtonGroupType } from './types';

import { toast } from './mDialog/toast/index';
import { resetBodyOverflowCss, setWeappUiOverflowCss } from '../../utils/index';
import { mCreateDialog } from './create';

const MDialog = Loadable({
  name: 'MDialog',
  loader: () => import(
    /* webpackChunkName: "m_dialog" */
    './MDialog')
}) as MDialogType;

function prompt(options: MDialogProps) {
  const { enableHistoryListen} = options
  const div = document.createElement('div');
  document.body.appendChild(div);
  setWeappUiOverflowCss();
  function destroy() {
    const unmount = ReactDOM.unmountComponentAtNode(div);
    resetBodyOverflowCss();
    if (unmount && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }
  function onClose() {
    destroy();
    const { onClose } = options;
    onClose?.();
  }
  function onOk(...arg: any[]) {
    destroy();
    const { onOk } = options;
    onOk?.(...arg);
  }
  ReactDOM.render(<MDialog weId={`${options.weId || ''}_2gm1f0`} prompt {...options} visible={true} onClose={onClose} onOk={onOk} destroyOnClose enableHistoryListen={enableHistoryListen} />, div);
}

const RouteLayout = Loadable({
  name: 'RouteLayout',
  loader: () => import(
    /* webpackChunkName: "route_layout" */
    './route-layout/index'
  )
})
MDialog.prompt = prompt;

MDialog.toast = toast;

MDialog.RouteLayout = RouteLayout;

MDialog.defaultProps = {
  prefixCls: mDialogClsPrefix,
}

const ButtonGroup = Loadable({
  name: 'ButtonGroup',
  loader: () => import(
    /* webpackChunkName: "m_dialog" */
    './route-layout/ButtonGroup')
}) as ButtonGroupType;

ButtonGroup.defaultProps = {
  prefixCls: mButtonGroupClsPrefix,
}

MDialog.ButtonGroup = ButtonGroup;

MDialog.createDialog = mCreateDialog;

export type { MDialogProps, ButtonGroupProps, ButtonProps as MDialogButtonProps, RouteLayoutProps, RouteLayoutType, BtnType as MDialogButtonType } from './types';

export default MDialog;
