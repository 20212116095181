import Loadable from "../../react-loadable";
import { cityPickerClsPrefix } from "../../constants";
import { CityPickerType } from "./types";

const CityPicker = Loadable({
  name: 'CityPicker', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './CityPicker')
}) as CityPickerType;

CityPicker.defaultProps = {
  prefixCls: cityPickerClsPrefix,
  level: 'district',
  mergeChild: false,
  mode: 'union',
};

export type { CityPickerProps, CityLevel, CityMode } from "./types";
export default CityPicker;

