import Loadable from "../../react-loadable";
import { cascaderClsPrefix } from "../../constants"
import { CascaderType, CascaderProps, MultiCascaderType, MultiCascaderProps } from "./types"


const Cascader = Loadable<CascaderProps>({
  name: 'Cascader', loader: () => import(
    /* webpackChunkName: "cascader" */
    './Cascader')
}) as CascaderType;

Cascader.defaultProps = {
  prefixCls: cascaderClsPrefix,
  selectedWrap: true,
  isFilterIDMatchNoLabelValue: true,
  isFilterOnChange: true
};

const MultiCascader = Loadable({
  name: 'MultiCascader', loader: () => import(
    /* webpackChunkName: "cascader" */
    './MultiCascader')
}) as MultiCascaderType;

MultiCascader.defaultProps = {
  prefixCls: cascaderClsPrefix,
  mergeChild: true,
  isFilterIDMatchNoLabelValue: true,
  isFilterOnChange: true
}

Cascader.MultiCascader = MultiCascader


export type {
  CascaderProps,
  CascaderOptionType,
  CascaderValueType,
  ShowSearchType as CascaderShowSearchType,
  FieldNamesType as CascaderFieldNamesType,
} from "./types";
export type {
  MultiCascaderProps
}
export default Cascader;