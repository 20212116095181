import { scopeClsPrefix } from '../../constants/index';
import Loadable from "../../react-loadable";
import { ScopeType } from "./types";

const Scope = Loadable({
  name: 'Scope',
  loader: () =>
    import(
      /* webpackChunkName: 'ui_common_less' */
      "./Scope"
    ),
}) as ScopeType;

Scope.defaultProps = {
  prefixCls: scopeClsPrefix,
};

export type { ScopeProps, ScopeValue } from "./types";

export default Scope;
