import Loadable from '../../react-loadable';
import { getVideoType } from './utils';
import { VideoType } from './types';
import showVideo from './showVideo';
import showMVideo from './showVideo.m'
import { ua } from '@weapp/utils'

const Video = Loadable({
  name: 'Video', loader: () => import(
    /* webpackChunkName: "ui_common_other" */
    './Video')
}) as VideoType;

Video.showVideo = ua.device === 'PC' ? showVideo : showMVideo
Video.getVideoType = getVideoType

export type { VideoProps } from './types';

export default Video;
