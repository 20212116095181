import { middleware } from "@weapp/utils";
import { Component, ComponentType } from "react";
import { uiAppName } from "../../constants";

export class Container extends Component<any> {
  render() {
    return this.props.children;
  }
}

export function generatorContainer<P>(name: string) {
  return middleware(uiAppName, `RootLayout${name}Container`)(Container) as ComponentType<P>;
}

export const NavigationContainer = generatorContainer('Navigation');

export const AsideContainer = generatorContainer('Aside');

export const ModuleContentContainer = generatorContainer('ModuleContent');
