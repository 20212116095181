import { ua } from '@weapp/utils';
import { scrollerClsPrefix } from '../../../constants/index'

let scrollBarWidth: any;

export function getScrollBarWidth() {
  const isIE = ua.browser === 'IE';
  if (isIE) return 17 // ie浏览器偶发出现滚动条宽度获取失败问题

  if (scrollBarWidth !== undefined) return scrollBarWidth;

  const outer: HTMLDivElement = document.createElement('div');
  outer.className = `${scrollerClsPrefix}__wrap`;
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.position = 'absolute';
  outer.style.top = '-9999px';
  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  outer.style.overflow = 'scroll';

  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;
  scrollBarWidth = widthNoScroll - widthWithScroll;
  outer.parentNode?.removeChild(outer);

  return scrollBarWidth;
}

export const BAR_MAP = {
  vertical: {
    offset: 'offsetHeight',
    scroll: 'scrollTop',
    scrollSize: 'scrollHeight',
    size: 'height',
    key: 'vertical',
    axis: 'Y',
    client: 'clientY',
    direction: 'top'
  },
  horizontal: {
    offset: 'offsetWidth',
    scroll: 'scrollLeft',
    scrollSize: 'scrollWidth',
    size: 'width',
    key: 'horizontal',
    axis: 'X',
    client: 'clientX',
    direction: 'left'
  }
}

// @ts-ignore
export function renderThumbStyle({ size, move, bar }) {
  const style: any = {};
  const translate = `translate${bar.axis}(${ move }%)`;

  style[bar.size] = size;
  style.transform = translate;
  style.msTransform = translate;
  style.WebkitTransform = translate;

  return style;
}

export function isWebKit() {
  const agent = navigator.userAgent
  return  false
  // return agent.indexOf('AppleWebKit') > -1
}
