let raf = function raf(callback?: any) {
  return +setTimeout(callback, 16);
};

let caf = function caf(num: any) {
  return clearTimeout(num);
};

if (typeof window !== 'undefined' && 'requestAnimationFrame' in window) {
  raf = function raf(callback) {
    return window.requestAnimationFrame(callback);
  };

  caf = function caf(handle) {
    return window.cancelAnimationFrame(handle);
  };
}

function wrapperRaf(callback: any) {
  return raf(callback);
}

wrapperRaf.cancel = caf;

export default raf;