import { dayjs, getLabel } from "@weapp/utils"
import { HrmCardType } from '../types'

export const isListType = (type: HrmCardType) => ['department', 'subcompany', 'group', 'position', 'role'].indexOf(type) !== -1

export const toDateTime = (date: number) => typeof date !== 'number' ? '' : dayjs(date).format('YYYY-MM-DD HH:mm')

export const toDate = (date: number) => typeof date !== 'number' ? '' : dayjs(date).format('YYYY-MM-DD')

export const getHrmData = (id: string, type: string, module: string) => {
  return import(
    /* webpackChunkName: 'hrmcard' */
    './utils').then((defaultModule) => {
      return defaultModule.getHrmData(id, type, module);
    })
}

export const getStatisticsData = (id: string, type: string, module: string) => {
  return import(
    /* webpackChunkName: 'hrmcard' */
    './utils').then((defaultModule) => {
      return defaultModule.getStatisticsData(id, type, module);
    })
}

export const getWorkStatusData = (id: string) => {
  return import(
    /* webpackChunkName: 'hrmcard' */
    './utils').then((defaultModule) => {
      return defaultModule.getWorkStatusData(id);
    })
}

export const getDelMsg = (type?: string) => {
  let errorMsg = ''
  switch (type) {
    case 'department':
      errorMsg = getLabel('143675', '部门已经删除')
      break;
    case 'subcompany':
      errorMsg = getLabel('143676', '分部已经删除')
      break;
    case 'position':
      errorMsg = getLabel('143677', '岗位已经删除')
      break;
    default:
      errorMsg = getLabel('143678', '人员已经删除')
      break;
  }
  return errorMsg
}

export const openHrmLink = (id: string, type: string, url: string) => {
  return import(
    /* webpackChunkName: 'hrmcard' */
    './utils').then((defaultModule) => {
      return defaultModule.openHrmLink(id, type, url);
    })
}

export const isUserNameWechatConvert = (name: string = '') => {
  if (!name) return false
  const reg = new RegExp(/^\$userName=/, 'g')
  return reg.test(name)
}
