import { authClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { AuthType, AuthFormType } from './types';

const Auth = Loadable({ name: 'Auth', loader: () => import(
  /* webpackChunkName: 'ui_common_less' */
  './Auth') }) as AuthType;

Auth.defaultProps = {
  prefixCls: authClsPrefix,
};

const AuthForm = Loadable({ name: 'AuthFrom', loader: () => import(
  /* webpackChunkName: 'ui_common_less' */
  './AuthForm') }) as AuthFormType;

AuthForm.defaultProps = {
  prefixCls: authClsPrefix,
};

Auth.AuthForm = AuthForm;

export type { AuthType, AuthProps, AuthFormType, AuthFormProps, ValueType as AuthValueType } from './types';

export default Auth;
