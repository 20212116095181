
import Loadable, { LoadingType } from '../../react-loadable';
import { MCardDetailFormType, MCardDetailFormProps } from './types';

import { mCardDetailFormClsPrefix } from '../../constants/index.m';
import { getTemplate } from './template';
import { getShowGroupId } from './utils/index';
import Skeleton from '../skeleton/index';
import { formSkeletonHeight } from '../form/index';
import { USESKELETON } from '../../constants/index';

const MCardDetailFormkeleton: LoadingType<MCardDetailFormProps> = (props) => {
  const layoutLen = props.store?.layout?.length || 0;
  const height = !props.store?.hasInit ? formSkeletonHeight : 40 * layoutLen;
  return USESKELETON && <Skeleton weId={`${props.weId || ''}_8eu5ov`} skeletonHeight={height} span={1} />;
};

const MCardDetailForm = Loadable({
  name: 'MCardDetailForm', loader: () => import(
    /* webpackChunkName: "m_card_detail_form" */
    './MCardDetailForm'),
    loading: MCardDetailFormkeleton,
}) as MCardDetailFormType;


MCardDetailForm.defaultProps = {
  prefixCls: mCardDetailFormClsPrefix,
  useSkeleton: USESKELETON,
  globalReadOnly: false
}

MCardDetailForm.getShowGroupId = getShowGroupId;

MCardDetailForm.getTemplate = getTemplate;

export type { MCardDetailFormProps, MCardDetailFormType } from './types';

export default MCardDetailForm;
