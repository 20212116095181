import Loadable from '../../react-loadable';
import { MSettingType, MGroupListType } from './types';
import { mcommonPhraseClsPrefix } from '../../constants/index.m';

const MCommonPhrase = Loadable({
  name: 'MCommonPhrase', loader: () => import(
    /* webpackChunkName: "mcommon_phrase" */
    './McommonPhrase')
}) as any;

const MSetting = Loadable({
  name: 'MCommonPhrase_MSetting', loader: () => import(
    /* webpackChunkName: "mcommon_phrase" */
    './component/mobile/MSetting')
});

const MEditPhrase = Loadable({
  name: 'MCommonPhrase_MEditPhrase', loader: () => import(
    /* webpackChunkName: "mcommon_phrase" */
    './component/mobile/MEditPhrase')
});

const MContent = Loadable({
  name: 'MCommonPhrase_MContent', loader: () => import(
    /* webpackChunkName: "mcommon_phrase" */
    './component/mobile/MContent')
});

const MGroupSetting = Loadable({
  name: 'MCommonPhrase_MGroupSetting', loader: () => import(
    /* webpackChunkName: "mcommon_phrase" */
    './component/mobile/MGroupSetting')
});

const MGroupEdit = Loadable({
  name: 'MCommonPhrase_MGroupEdit', loader: () => import(
    /* webpackChunkName: "mcommon_phrase" */
    './component/mobile/MGroupEdit')
});

const MGroupList = Loadable({
  name: 'MCommonPhrase_MGroupList', loader: () => import(
    /* webpackChunkName: "mcommon_phrase" */
    './component/mobile/MGroupList')
}) as MGroupListType;

MCommonPhrase.defaultProps = {
  prefixCls: mcommonPhraseClsPrefix,
};

MSetting.defaultProps = {
  prefixCls: `${mcommonPhraseClsPrefix}-setting`,
};

MEditPhrase.defaultProps = {
  prefixCls: `${mcommonPhraseClsPrefix}-edit`,
};

MContent.defaultProps = {
  prefixCls: `${mcommonPhraseClsPrefix}-content`,
};

MGroupSetting.defaultProps = {
  prefixCls: `${mcommonPhraseClsPrefix}-group-setting`,
};

MGroupEdit.defaultProps = {
  prefixCls: `${mcommonPhraseClsPrefix}-group-edit`,
};

MGroupList.defaultProps = {
  prefixCls: `${mcommonPhraseClsPrefix}-group-list`,
};

MCommonPhrase.MContent=MContent;
MCommonPhrase.MSetting = MSetting as MSettingType;

export default MCommonPhrase;
export { MSetting, MEditPhrase, MContent, MGroupSetting, MGroupEdit, MGroupList };

export type { MCommonPhraseType, CommonPhraseType } from './types';
