import Loadable from "../../react-loadable";
import { mGridCheckboxClsPrefix } from "../../constants/index.m";
import { MGridCheckboxType } from "./types";

const MGridCheckbox = Loadable({
  name: 'MGridCheckbox', loader: () => import(
    /* webpackChunkName: "ui_m_common_less" */
    './M_GridCheckbox')
}) as MGridCheckboxType;

MGridCheckbox.defaultProps = {
  prefixCls: mGridCheckboxClsPrefix,
  column: 4
};

export type { MGridCheckboxProps, OptionsType as MGridCheckboxOptionsType, OptionData as MGridCheckboxOptionData } from "./types";
export default MGridCheckbox;