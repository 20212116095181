import Loadable from "../../react-loadable";
import { mCascaderClsPrefix } from "../../constants/index.m";
import { MCascaderType, MCascaderProps, MCascaderViewType, MCascaderViewProps, MultiCascaderType, MMultiCascaderProps, MMultiCascaderType, MMultiCascaderViewType } from "./types"
// import { MCascaderView } from './components/cascader-view'

const MCascader = Loadable<MCascaderProps>({
  name: 'MCascader', loader: () => import(
    /* webpackChunkName: "m_cascader" */
    './M_Cascader')
}) as MCascaderType;

MCascader.defaultProps = {
  prefixCls: mCascaderClsPrefix,
  showClear: true,
  isFilterIDMatchNoLabelValue: true,
  isFilterOnChange: true
};

const MCascaderView = Loadable({
  name: 'MCascaderView', loader: () => import(
    /* webpackChunkName: "m_cascader" */
    './m-cascader/cascader-view')
}) as MCascaderViewType;

MCascader.MCascaderView = MCascaderView

MCascader.MCascaderView.defaultProps = {
  prefixCls: mCascaderClsPrefix,
}

// ====================级联多选====================
const MMultiCascader = Loadable({
  name: 'MMultiCascader', loader: () => import(
    /* webpackChunkName: "m_cascader" */
    './M_MultiCascader')
}) as MMultiCascaderType;

MMultiCascader.defaultProps = {
  prefixCls: mCascaderClsPrefix,
  showClear: true,
  isFilterIDMatchNoLabelValue: true,
  isFilterOnChange: true
};

const MMultiCascaderView = Loadable({
  name: 'MMultiCascaderView', loader: () => import(
    /* webpackChunkName: "m_cascader" */
    './m-cascader/cascader-multi-view')
}) as MMultiCascaderViewType;

MMultiCascader.MMultiCascaderView = MMultiCascaderView

MCascader.MMultiCascader = MMultiCascader


export type {
  MCascaderProps,
  MCascaderViewProps,
} from "./types";

export default MCascader;