import Loadable from "../../react-loadable";
import { mCommentClsPrefix } from "../../constants/index.m";
import { MCommentType, MCommentEditType, MCommentMenuType, MCommentViewLogType, MCommentEditTextType, MCommentGroupType, MUpvoteType, MUpvoteListType, MCommentItemType, MAddressType, MCommentAvatarType, MCommentItemContentType, MCommentFooterType, MCommentContentType } from "./types";

const MComment = Loadable({
  name: 'MComment', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './MComment')
}) as MCommentType;

const MCommentMenu = Loadable({
  name: 'MCommentMenu', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './MCommentMenu')
}) as MCommentMenuType;

const MCommentEdit = Loadable({
  name: 'MCommentEdit', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/main/MCommentEdit')
}) as MCommentEditType;

const MCommentViewLog = Loadable({
  name: 'MCommentViewLog', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './MCommentViewLog')
}) as MCommentViewLogType;

const MCommentEditText = Loadable({
  name: 'MCommentEditText', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/main/MEditText')
}) as MCommentEditTextType;

const MCommentItem = Loadable({
  name: 'MCommentItem', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/main/MCommentItem')
}) as MCommentItemType;

const MCommentGroup = Loadable({
  name: 'MCommentGroup', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './MCommentGroup')
}) as MCommentGroupType;

const MUpvote = Loadable({
  name: 'MUpvote', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/upvote/MUpvote')
}) as MUpvoteType;

const MAddress = Loadable({
  name: 'MAddress', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/main/MAddress')
}) as MAddressType;

const MCommentAvatar = Loadable({
  name: 'MCommentAvatar', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/item/MCommentAvatar')
}) as MCommentAvatarType;

const MCommentItemContent = Loadable({
  name: 'MCommentItemContent', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/item/MItemContent')
}) as MCommentItemContentType;

const MCommentFooter = Loadable({
  name: 'MCommentFooter', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/item/MItemFooter')
}) as MCommentFooterType;

const MCommentContent = Loadable({
  name: 'MCommentContent', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/item/MCommentContent')
}) as MCommentContentType;

const MUpvoteList = Loadable({
  name: 'MUpvoteList', loader: () => import(
    /* webpackChunkName: "m_comment" */
    './component/mobile/upvote/MUpvoteListWithName')
}) as MUpvoteListType;


MComment.defaultProps = {
  prefixCls: mCommentClsPrefix,
  paginationType: "more",
  hasHeader: true,
  module: "share",
  readOnly: false,
  objectModule: "bcw",
  showSource: true,
  hasEchart: false,
  hasSearch: false,
};

MCommentMenu.defaultProps = {
  prefixCls: `${mCommentClsPrefix}-menu`,
  menu: [],
  module: "share",
  showSource: true,
  objectModule: "bcw",
  paginationType: "more",
  hasSearch: false,
};

MCommentViewLog.defaultProps = {
  prefixCls: mCommentClsPrefix,
  hasButton: false,
  hasPullToRefresh: false,
};

MCommentEdit.displayName = "MCommentEdit";
MCommentEdit.defaultProps = {
};

MCommentEditText.displayName = "MCommentEditText";
MCommentEditText.defaultProps = {
  prefixCls: mCommentClsPrefix,
};

MCommentItem.defaultProps = {
  prefixCls: mCommentClsPrefix,
};

MCommentGroup.defaultProps = {
  prefixCls: mCommentClsPrefix,
  paginationType: "more",
  module: "share",
  showSource: true,
  objectModule: "bcw",
};

MComment.MCommentEdit = MCommentEdit;
MComment.MCommentMenu = MCommentMenu;
MComment.MCommentViewLog = MCommentViewLog;
MComment.MCommentEditText = MCommentEditText;
MComment.MCommentGroup = MCommentGroup;
MComment.MCommentItem = MCommentItem;
MComment.MCommentAvatar = MCommentAvatar;
MComment.MCommentItemContent = MCommentItemContent;
MComment.MCommentFooter = MCommentFooter;
MComment.MAddress = MAddress;
MComment.MCommentContent = MCommentContent;

export type {
  MCommentProps,
  MCommentType,
  MCommentMenuProps,
  MCommentMenuType,
  MCommentViewLogProps,
  CommentEditTextSubmitFunc as MCommentEditTextSubmitFunc, 
  MCommentAction,
  MMoreOptionType,
  MVoteListModuleType,
  MUpvoteType,
  MUpvoteListType,
  MUpvoteProps,
  MUpvoteListProps,
  AddressInfoType as MCommentLocationType,
} from "./types";
export default MComment;
export { MCommentMenu, MCommentEdit, MCommentViewLog, MCommentEditText, MUpvote, MUpvoteList, MCommentItem };