import Loadable from '../../react-loadable';
import { mAvatarClsPrefix } from '../../constants/index.m';
import { MAvatarType } from './types';

const MAvatar = Loadable({ name: 'MAvatar',loader: () => import(
  /* webpackChunkName: "ui_common" */
  './Avatar') }) as MAvatarType;

MAvatar.defaultProps = {
  prefixCls: mAvatarClsPrefix,
  isMobile: true
};

export type { MAvatarProps } from './types';

export default MAvatar;
