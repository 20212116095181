import Loadable from '../../react-loadable';
import { MAudioType } from './types';
import showAudio from './showAudio';
import showMAudio from './showAudio.m';
import { ua } from '@weapp/utils'

const MAudio = Loadable({
  name: 'MAudio', loader: () => import(
    /* webpackChunkName: "ui_m_common_other" */
    './Audio.m')
}) as MAudioType;

MAudio.showAudio = ua.device === 'PC' ? showAudio : showMAudio

export type { MAudioProps } from './types';

export default MAudio;
