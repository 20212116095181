import { mStepsClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { StepsType } from './types';

const MSteps = Loadable({
  name: 'MSteps',
  loader: () => import(
    /* webpackChunkName: "ui_common" */
    './Steps')
}) as StepsType;

MSteps.defaultProps = {
  ...MSteps.defaultProps,
  prefixCls: mStepsClsPrefix,
  isMobile: true,
  labelPlacement: "vertical"
};

export type { MStepProps, MStepBaseProps } from './types';

export default MSteps;
