import React from "react";
import ReactDOM from "react-dom";
import Spin from './Spin'
import { SpinProps } from './types'

let instance: any = null;
let times: number = 0;

function newInstance(props: SpinProps, callback: (args: any) => void) {
  const div = document.createElement("div");
  document.body.appendChild(div);
  function ref(spin: any) {
    callback({
      component: spin,
      destroy() {
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
      }
    });
  }
  ReactDOM.render(<Spin weId={`${props?.weId || ''}_g2dov5`} ref={ref} {...props} globalLoading />, div);
}

function getInstance(props: SpinProps, callback: (args: any) => void) {
  times++;
  if (instance) {
    callback(instance);
    return;
  }
  newInstance(props, ins => {
    if (instance) {
      callback(instance);
      return;
    }
    instance = ins;
    callback(ins);
  });
}

export function start(options: SpinProps = {}) {
  if (!instance) {
    times === 0 && getInstance(options, function () { });
  } else {
    if (!options.useSvgSpin) {
      instance.component.setState({ spinning: true });
    }
  }
}

export function end(options: SpinProps = {}) {
  if (instance) {
    if (!options.useSvgSpin) { 
      instance.component.setState({ spinning: false });
    }
    destroy()
  }

  // instance.component.setState({ spinning: false });
}

export function destroy() {
  if (instance) {
    instance.destroy();
    instance = null;
    times = 0;
  }
}