import { ua } from "@weapp/utils";
import { AnyObj } from "../../../types/common";
import { TypesBrowserValueType } from '../../browser/index';
import { SelectedData } from "../../browser/types";
import { UploadData } from "../../upload/types";
import { AssociatedTypeData, ClientType, ReplyOption } from "../types";
import { getAtTemplate, getEmoticonTemplate } from "./richTemplate";
import { decryptCommentById } from "../component/api/common";
import { getEmojiData } from "../../emoticon/constants";

export const getUserData = (userDatas?: any) => {
  let user: any[] = [];
  if (typeof userDatas === "string") {
    try {
      user = JSON.parse(userDatas);
    } catch (e) {
      console.error("userDatas类型不匹配");
      return [];
    }
  } else if (userDatas) {
    user = userDatas;
  }
  return user;
}

/**
 * 获取当前设备标志
 * @returns 
 */
export const getClient = (): keyof ClientType => {
  const { device, browser, os } = ua;

  if (browser === "MicroMessenger") return "micro_msg";     // 微信
  if (browser === "wxwork") return "wechat";    // 企业微信

  // HarmonyOS
  if (os === "Harmony OS") return "HarmonyOS";
  // ios
  if (os === "iOS") return "iphone";
  if (os === "Android") return "android";

  if (device === 'Mobile') return "H5";   // 移动端h5页面
  return "pc";
}

/**
 * 将浏览按钮的数据转换成事件关联的数据
 * @param data 需要转换的数据
 * @returns 返回的数据
 */
export const getAssociatedData = (data: SelectedData[]): AssociatedTypeData[] => data.map(item => {
  const { id, _entityType, name, content, objectId, richContent, isComplete } = item;
  return {
    ...item,
    targetId: id,
    targetModule: _entityType,
    objectId,
    targetName: name || content,
    richContent,
    isComplete,
  }
})


/**
 * 将事件关联的数据转换成浏览按钮数据
 * @param data 
 * @returns 
 */
export const getBrowserData = (data: AssociatedTypeData[]): TypesBrowserValueType => {
  return data.map(value => {
    const { targetModule, targetName, targetId, objectId, richContent, isComplete } = value;
    return {
      ...value,
      id: targetId,
      content: targetName,
      _entityType: targetModule,
      objectId,
      _entityContent: "",
      richContent,
      isComplete,
    }
  })
}

/**
 * 格式化评论附件数据
 * @param fileList 待格式化的数据
 * @param isReadonly 数据是否需要只读，默认false
 * @returns 格式化后的数据
 */
export const getUploadData = (fileList: UploadData[] = [], isReadonly: boolean = false, blacklist: string[] = []): UploadData[] => {
  const commentFileList: UploadData[] = fileList.map(data => {
    const { options = [], id, image, map, signTip: dataSignTip } = data;
    const signTip = map && map.optionMap && map.optionMap.signTip ? map.optionMap.signTip : undefined;

    const hiddenOption = ["reupload", "rename", "version"].concat(blacklist);

    return {
      fileid: id,
      img: image,
      signTip: dataSignTip || signTip,
      ...data,
      options: [
        ...(options || []).filter((option: any) => !hiddenOption.includes(option.id) /* && option.id !== "editSecLevel" */ && (!isReadonly || option.id !== "delete")),
      ]
    }
  })
  return commentFileList.sort((a, b) => a.commonTextObj?.order - b.commonTextObj?.order)
}

export const getEmojiRegExpString = () => {
  let emojiNamesArr: string[] = [];
  const emojiData = getEmojiData();
  emojiData.forEach(item => {
    const iconKey = item.iconKey.replace(/\:/g, '');
    emojiNamesArr.push(iconKey);
    if (item.oldName_e10 && item.oldName_e10 !== iconKey) { // E10 老的表情图片数据兼容
      emojiNamesArr.push(item.oldName_e10);
    }
  })
  const emojiNames = emojiNamesArr.join("|");
  return `( :|:)(${emojiNames})(: |:)`;
}

/**
 * 获取emoji表情正则
*/
export const getEmojiRegExp = () => {
  // const emojiNames = Object.keys(emojiModule).join("|");
  const emojiRegExpString = getEmojiRegExpString();
  return new RegExp(emojiRegExpString, 'g');
  
}

/**
 * 评论纯文本转换为富文本
 * @param commentValue 纯文本内容
 * @param userDatas at用户信息
 * @returns 转换后的富文本内容
 */
export const getRichTextFromText = (commentValue: string, userDatas: string | AnyObj[]) => {

  // 数据本身是富文本时不进行处理
  if (/^_weaverMte_/.test(commentValue)) {
    return commentValue;
  }

  const userDataList: AnyObj[] = typeof userDatas === "string" ? JSON.parse(userDatas) : userDatas;

  const emojiRegExp = getEmojiRegExp();
  const atExp = /:@([0-9]*?); /g;  // @人员正则
  const newLineExp = /\n/g;  // 换行正则

  let richTextValue = commentValue;
  const emojiData = getEmojiData();

  richTextValue = richTextValue.replace(emojiRegExp, value => {
    const emojiName = value?.replace(/^(\s?):|:(\s?)$/g, "") || "";
    const emojiData = getEmojiData();
    const itemData = emojiData.filter(item => item.iconKey.replace(/\:/g, '') === emojiName || item.oldName_e10 === emojiName)?.[0];
    // return getEmoticonTemplate(emojiUrl, emojiName, emojiName)
    return getEmoticonTemplate(itemData);
  })

  richTextValue = richTextValue.replace(atExp, value => {
    const userId = value.match(/\d+/)?.[0] || "";
    const userData = userDataList?.find(item => item.userId === userId) || {};
    const { userName, atType } = userData;

    return getAtTemplate(userId, userName, atType);
  })

  richTextValue = richTextValue.replace(newLineExp, "<br >");
  // 过滤标签内的空格以及首尾非标签的空格
  richTextValue = richTextValue.replace(/(>[^<]*<)|(^[^<>]*)|([^<>]*$)/g, (value) => {
    return value.replace(/ /g, "\u00A0");
  });

  return `_weaverMte_${richTextValue}`;

}

/**
   * 评论解密获取明文
   * @param key 
   * @param callBack 
   */
export const getDesensitizationComment = async (data: ReplyOption, otherActions?: AnyObj) => {
  // originContent 包含@数据，优先originContent
  let comment = data?.originContent || data?.content;
  if (data?.desensitization) {
    try {
      const { data: result } = await decryptCommentById({ commentId: data?.id }, undefined, undefined, undefined, otherActions)
      const { content, originContent } = result;
      comment = originContent || content
    } catch (e) {
      console.error(e);
    }
  }
  return comment
}

/**
 * 获取评论原纯文本内容
 * @param content 评论后端返回的内容
 * @param userDatas at用户信息
 * @returns 
 */
export const getAtValue = (content: string = "", userDatas: any) => {
  let user: any[] = getUserData(userDatas);
  return content.replace(/:@([0-9]*?); /g, value => {
    const id = value.replace(/^:@|; $/g, "");
    const userFilter = Array.isArray(user) ? user.filter(({ userId }) => userId === id)[0] : undefined;
    if (userFilter && userFilter.userName != null) {
      return `@${userFilter.userName} `;
    }
    return value;
  })
}