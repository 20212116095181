import { FC, createRef, memo, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { appInfo, getLabel, request, ua } from '@weapp/utils';
import { Dialog, MDialog, Popover, Icon, Button, Menu, DialogProps } from '../../lib';
import { prefix, disableRefreshNoticeKey, neverNoticeKey } from './constants';
import { getLang } from '../../utils';

// 弹框显影状态
let dialogVisible = false;

function noop () {
  return null;
}

export function getDialogVisible () {
  return dialogVisible;
}

// 弹框实例，主要用于关闭弹框
let dialogRef: any = null;

// 刷新
function reload () {
  dialogVisible = false;
  dialogRef = null;
  window.location.reload();
}

// 取消
function cancel () {
  dialogVisible = false;
  if (dialogRef && typeof dialogRef.destroy === 'function') {
    dialogRef.destroy();
    dialogRef = null;
  }
  if (dialogRef && typeof dialogRef.onClose === 'function') {
    dialogRef.onClose();
    dialogRef = null;
  }
}

function noRemind () {
  if (window.localStorage) {
    // 设置不再提醒
    window.localStorage.setItem(neverNoticeKey, 'true');
  }
  request({
    url: '/api/component/base/syncSetting/savePersonalSetting',
    method: 'POST',
    data: {
      data: [
        {
          "configKey": "common.refreshNotice",
          "configValue": false,
        }
      ]
    }
  }).catch(err => {
    console.error(err);
  });
  cancel();
}

// 设置多长时间不显示
function cancelWithDisabled (v: string | number) {
  const value = Number(v);
  if (window.localStorage && (value === 3 || value === 24)) {
    const now = Date.now();
    const expTime = now + (value * 60 * 60 * 1000);
    // 设置过期时间
    window.localStorage.setItem(disableRefreshNoticeKey, String(expTime));
  }
  cancel();
}

// const RefreshNotice: FC<any> = memo(({ weId, isMobile }) => {
//   const dialogData = useMemo(() => [
//     { id: '3', content: getLabel('228970','3小时内不再提醒') },
//     { id: '24', content: getLabel('228971','24小时内不再提醒') },
//   ], []);
//   return (
//     <div className={`${prefix}-dialog-content ${isMobile ? 'is-mobile' : ''}`}>
//       <div className={`${prefix}-dialog-content-left`}>
//         <Icon weId={`${weId || ''}_zj7liy`} name="Icon-Version-upgrade" />
//       </div>
//       <div className={`${prefix}-dialog-content-right`}>
//         <p>{getLabel('228973', '检测到系统有升级，本地缓存与服务器文件版本不一致，如继续使用，可能会有功能异常，建议您立即刷新页面') }</p>
//         {!isMobile && (
//           <>
//             <Button weId={`${weId || ''}_smgj5p`} type="primary" style={{ marginRight: 12 }} onClick={reload}>{getLabel('228972','立即刷新')}</Button>
//             <Button weId={`${weId || ''}_cjhfms`} onClick={cancel}>{getLabel('228969','稍后手动刷新')}</Button>
//             <Menu weId={`${weId || ''}_9wpgdi`}
//               defaultValue={'24'}
//               data={dialogData}
//               type="select"
//               selectType="normal"
//               className={`${prefix}-dialog-content-disabled-select`}
//               triggerProps={{
//                 popupPlacement: 'bottomRight',
//                 popupClassName: `${prefix}-dialog-content-disabled-select-popup menuDemo-select`
//               }}
//               onChange={cancelWithDisabled}
//             />
//           </>
//         )}
//       </div>
//     </div>
//   );
// });

const RefreshNotice: FC<any> = memo(({ weId, isMobile }) => {
  const popup = useMemo(() => {
    return getLabel('257834', '关闭提醒之后，您可以在个人设置->偏好设置开启');
  }, []);
  return (
    <div className={`${prefix}-dialog-content ${isMobile ? 'is-mobile' : ''}`}>
      <Icon className={`${prefix}-dialog-close`} weId={`${weId || ''}_1gojjj`} name="Icon-error01" title={getLabel('19876', '关闭')} onClick={cancel}/>
      <div className={`${prefix}-dialog-content-left`}>
        <Icon weId={`${weId || ''}_zj7liy`} name="Icon-upgrade-mcolor" />
      </div>
      <div className={`${prefix}-dialog-content-right`}>
        <p>{getLabel('257833', '检测到系统升级，可能会功能异常，建议您刷新页面后使用') }</p>
        {!isMobile && (
          <div className={`${prefix}-dialog-button-group`}>
            <Button weId={`${weId || ''}_smgj5p`} type="primary" style={{ marginRight: 12, marginTop: 5 }} onClick={reload}>{getLabel('228972','立即刷新')}</Button>
            <Button weId={`${weId || ''}_smgj5p`} style={{ marginRight: 12, marginTop: 5 }} onClick={cancel}>{getLabel('260570','暂不刷新')}</Button>
            <Popover weId={`${weId || ''}_977kkz`}
              action="hover"
              popup={popup}
              placement={'topRight'}
              popoverType="tooltip"
              triggerProps={{
                zIndex: 2999,
              }}
            >
              <Button weId={`${weId || ''}_cjhfms`} style={{ marginTop: 5}} onClick={noRemind}>{getLabel('184760','不再提醒')}</Button>
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
});

export function notice(options: any) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function destroy() {
    const unmount = ReactDOM.unmountComponentAtNode(div);
    if (unmount && div.parentNode) {
      div.parentNode.removeChild(div);
    }
    options?.onClose?.();
  }
  ReactDOM.render(<Dialog
    weId={`${options.weId || ''}_ztj71q`}
    placement="right"
    customRenderDialogTitle={noop}
    {...options}
    visible={true}
    onClose={destroy}
  >{options?.content || options?.children}</Dialog>, div);

  return {
    destroy
  };
}


function refreshNotice (msg: string, type: 'confirm' | 'notice' = 'confirm') {
  if (!(Dialog && MDialog) || dialogVisible) return;

  let data: any = msg;
  try {
    /**
     * 消息格式形如：
     * '{"modules": ["weapp-workflow"]}'
     */
    data = JSON.parse(data);
  } catch (e) { }

  const updateModules = [];

  if (data.modules?.length) {
    // 检查更新的模块是否被使用到
    data.modules.forEach((module: any) => {
      const libraryName = appInfo(module.replace('weapp-', '@weapp/')).libraryName;
      if (window[libraryName]) {
        // 当前页面已经使用该模块，需要刷新
        updateModules.push(module);
      }
    });
  }

  if (!updateModules.length) return;

  const isMobile = ua.device !== 'PC';
  if (isMobile) {
    dialogVisible = true;
    const prompt = MDialog.prompt;
    const mDialogFooter = [
      { key: 'cancel', text: getLabel('228969','稍后手动刷新'), onPress: cancel },
      { key: '3', text: getLabel('228970','3小时内不再提醒'), onPress: () => cancelWithDisabled(3) },
      { key: '24', text: getLabel('228971','24小时内不再提醒'), onPress: () => cancelWithDisabled(24) },
      { key: 'reload', text: getLabel('228972','立即刷新'), onPress: reload },
    ];
    prompt({
      prompt: false,
      message: (<RefreshNotice weId={`_e0y1l9`} isMobile />),
      className: `${prefix}-dialog is-mobile`,
      getInstance: (el: any) => { dialogRef = el; },
      footer: mDialogFooter,
    });
  } else {
    const confirm = Dialog.confirm;
    dialogVisible = true;
    const isChinese = (getLang() || 'zh-CN').indexOf('zh') !== -1;
    const width = isChinese ? 510: 620;
    const height = isChinese ? 160 : 200;
    const cls = `${prefix}-dialog ${isChinese ? 'is-chinese' : ''}`;
    const content = (<RefreshNotice weId={`_h8q2sz`} />);
    dialogRef = type === 'notice' ? notice({
      className: cls, width, height, content,
    }) : confirm({
      className: cls, width, height, content,
    });
  }
}

export default refreshNotice;