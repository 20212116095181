import { zoomClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { ZoomType } from './types';

const Zoom = Loadable({
  name: 'Zoom', loader: () => import(
    /* webpackChunkName: "ui_zoom" */
    './Zoom')
}) as ZoomType;


Zoom.defaultProps = {
  prefixCls: zoomClsPrefix,
  zoomMode: true,
};

export type { ZoomProps, ZoomType } from './types';

export default Zoom;
