import { createContext, ReactNode, ComponentType, useContext } from "react"
import { AnyObj, SearchAdvancedStore } from "../../../lib";
import { BrowserProps, SelectedData, BrowserPanelState, UpdateStateSign, BrowserDialogProps } from "../types";
import { RequestPromise } from "@weapp/utils";

/**
 * 记录browser props的context
 * 主要是为了深层级的子节点更方便获取props属性
 */
export interface IBrowserPropsContext extends BrowserProps {
  displayInDialog?: boolean;
  isMenuTab?: boolean;
  isMobile?: boolean;
  activeKey?: string;
  dialogId?: string;
  browserId?: string;
  menuBindKey?: string;
  iframeInBrowserKey?: string;
  headerExtraEle?: ReactNode;
  footerExtraEle?: ReactNode;
  showVoiceToTextBtn?: boolean;
  browserDialogProps?: BrowserDialogProps,
  isTriggerSearchAdvancePanel?: boolean;
  triggerSearchAdvancePanelWidth?: number | string;
  /** tree: 移动端树形展示， list: 列表树形展示 */
  treeType?: string;
  getDataKey?: (data: SelectedData) => string;
  isDataEqual?: (oD: SelectedData, tD: SelectedData) => boolean;
  getDataInfo?: (data: SelectedData) => { id: string, type: string | undefined, key: string };
  checkMaxLength?: (data: SelectedData[]) => boolean;
  onChange?: (data: SelectedData[], closeModal?: boolean, callback?: (newData: SelectedData[]) => void) => void;
  onTabChange?: (activeKey: string, isSearch?: boolean, cleanSearch?: boolean) => void;
  getPropsByTab?: (tab?: string, props?: BrowserProps) => Partial<BrowserProps>;
  updateState?: (newState: Partial<BrowserPanelState>, sign?: UpdateStateSign) => void;
  callDataUrl?: (params?: object, activeKey?: string) => RequestPromise<any>;
  callDestDataUrl?: (params?: object, activeKey?: string) => RequestPromise<any>;
  callQuickDataUrl?: (params?: object, activeKey?: string) => RequestPromise<any>;
  callDataConditionUrl?: (params?: object, activeKey?: string) => RequestPromise<any>;
  callLeftDataUrl?: (params?: object, activeKey?: string) => RequestPromise<any>;
  getTabCache?: (activeKey?: string) => AnyObj;
  setTabCache?: (data?: AnyObj, activeKey?: string) => void;
  getTabCacheMult?: (multTypeKey: string, activeKey?: string) => AnyObj;
  setTabCacheMult?: (multTypeKey: string, data?: AnyObj, activeKey?: string) => void;
  changeVisible?: (visible: boolean) => void;
  setPanelCache?: (data: AnyObj) => void;
  getPanelCache?: () => AnyObj;
  onPanelSearch?: (activeKey?: string,clearSearch?:boolean) => void;
  // onSelectedClick?: () => void;
  /* 判断数据是否可以选中 */
  isDisabledCheck?: (data: AnyObj) => boolean;
  getCommonTabCache?: () => AnyObj;
  setCommonTabCache?: (data: AnyObj) => void;
}

const BrowserPropsContext = createContext<IBrowserPropsContext>({});

/**
 * 记录browser state的context
 * 主要是记录浏览按钮中公共的state
 */
export interface IBrowserStateContext {
  loading?: boolean;
  leftLoading?: boolean;
  countLoading?: boolean;
  selectedLoading?: boolean;
  showCheckedOnly?: boolean;
  activeKey?: string;
  activeIndex?: number;
  conditionId?: string;
  selectedData?: SelectedData[];
  selectedListTemplate?: AnyObj;
  searchKeys?: string | string[];
  searchPlaceholder?: string;
  searchAdvancedStore?: SearchAdvancedStore;
  selectedVisible?: boolean;
  isShowGroup?: boolean;
  selectedDataMap?: AnyObj;
  groupKeyMap?: AnyObj;
  showTreeSearch?: boolean;
}

const stateContextKeys = [
  'loading', 'leftLoading', 'countLoading', 'selectedLoading', 'searchAdvancedStore', 'conditionId', 'activeKey', 'showCheckedOnly',
  'selectedData', 'selectedListTemplate', 'searchKeys', 'searchPlaceholder', 'selectedVisible', 'activeIndex',
  'selectedDataMap', 'groupKeyMap', 'showTreeSearch',
];

const BrowserStateContext = createContext<IBrowserStateContext>({});

export { BrowserPropsContext, BrowserStateContext };

export { stateContextKeys };

export function useBrowserProps() {
  const browserProps = useContext(BrowserPropsContext);

  return browserProps;
}

export function useBrowserState() {
  const browserState = useContext(BrowserStateContext);

  return browserState;
}

export function withBrowser(target: ComponentType<any>): ComponentType<any> {
  const Com = target;

  const NewCom = ((props: any) => {
    return (
      <BrowserPropsContext.Consumer weId={`${props.weId || ''}_g0llys`}>
        {browserProps => (
          <BrowserStateContext.Consumer weId={`${props.weId || ''}_7nf1md`}>
            {browserState => (
              <Com
                weId={`${props.weId || ''}_p0zzyw`}
                {...props}
                browserProps={browserProps}
                browserState={browserState}
              />
            )}
          </BrowserStateContext.Consumer>
        )}
      </BrowserPropsContext.Consumer>
    );
  }) as ComponentType<any>;

  return NewCom;
}
