import Loadable from '../../react-loadable';
import { MilkdownType } from './types';

const Milkdown = Loadable({
  name: 'Milkdown', loader: () => import(
    /* webpackChunkName: "ui_common_useless" */
    './main/Milkdown')
}) as MilkdownType;

Milkdown.defaultProps = {
  prefixCls: '',
};

export type { MilkdownProps, MilkdownType, PluginConfig as MilkdownPluginConfig, ValueType as MilkdownValueType } from './types';

export default Milkdown;
