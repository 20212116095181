import { moduleRouter } from "@weapp/utils";
import { toJS } from "mobx";
import { BrowserTabsData } from '../index';
import { AnyObj, checkDisabledModule, utils, payModuleRules, SingleType, ListData } from "../../../lib";
import { defaultDialogHeight, defaultPanelHeight, TYPESBROWSERKEYS } from "../PropsType";
import { BrowserProps, SelectedData, ValueType } from "../types";
import { TypesBrowserData, TypesBrowserOption } from "../types-browser/types";
import { personnelOrgBrowser, personnelOrgOptionKey } from "../defaultProps";
import { extractEbuilderAndNumber } from "../shareUtils";

const { needSync, isNumber } = utils;
export function mergetObj<T extends AnyObj>(keys: string[], originProps: T | Partial<T>, targetProps: T | Partial<T>): Partial<T> {
  const obj: Partial<T> = {};
  keys.forEach(key => {
    if (needSync(key, targetProps) && needSync(key, originProps, targetProps)) {
      obj[key as keyof T] = {
        ...(originProps[key] || {}),
        ...(targetProps[key] || {})
      };
    } else if (targetProps?.[key] === undefined) {
      obj[key as keyof T] = originProps?.[key];
    }
  });
  return obj;
}

/* 合并props */
export function mergeProps<VT extends ValueType, P extends BrowserProps<VT>>(originProps: P, targetProps: Partial<P>, objItems?: string[]): P {
  objItems = [
    'commonParams', 'dataParams', 'destDataParams', 'conditionDataParams', 'completeParams', 'searchParams', 'leftDataParams',
    'browserTreeProps', 'browserTableProps', 'browserListProps', 'browserLeftTreeProps', 'browserLeftListProps', 'browserCustomContentProps', 'browserCustomPanelContentProps',
    'browserSelectedPanelProps', 'browserDialogProps', 'browserSearchAdvancedProps', 'browserSearchAdvancedPanelProps',
    'browserSearchAdvancedLayoutProps', 'browserLeftListProps', 'browserLeftTreeProps', 'noneTabProps'
  ].concat(objItems || []);
  const newProps: P = {
    ...originProps,
    ...targetProps,
    ...mergetObj<P>(objItems, originProps, targetProps),
  };
  return newProps;
}

/* 判断改类型在系统中是否被禁用掉了 */
export function isModuleDisabled(type: string, browser: AnyObj) {
  return checkDisabledModule(type, undefined, browser);
}

/* 过滤被禁用掉的模块 */
export function filterDisabledBrowser(options: TypesBrowserOption[], browsers: TypesBrowserData)
  : { options: TypesBrowserOption[], browsers: TypesBrowserData, defaultOption: TypesBrowserOption | null, parentOption: TypesBrowserOption | null } {
  const newOptions: TypesBrowserOption[] = [];
  const newBrowsers: TypesBrowserData = {};
  let defaultOption: TypesBrowserOption | null = null;
  let parent: TypesBrowserOption | null = null;
  if (options && browsers) {
    for (let i = 0; i < options.length; i++) {
      let option = options[i];
      const browser = browsers[option.id];
      if (option.children?.length) {
        const {
          options: subOption, browsers: subBrowsers, defaultOption: dOption
        } = filterDisabledBrowser(option.children, browsers);
        newOptions.push({ ...option, children: subOption });
        Object.assign(newBrowsers, subBrowsers);
        if (!defaultOption && dOption && !dOption.hidden) {
          defaultOption = dOption;
          parent = option;
        }
      } else {
        if (browser?.type) {
          option = { ...option, payInfo: isPayModules(option.id, browsers) };
        }
        if (browser?.type && !isModuleDisabled(browser.type, browser)) {
          newOptions.push(option);
          newBrowsers[option.id] = browser;
          if (option.prevBrowser) {
            newBrowsers[option.prevBrowser] = browsers[option.prevBrowser];
          }
          // 将第一个非禁用的选项作为默认选项
          if (!defaultOption && option.payInfo && !option.hidden) {
            defaultOption = option;
          }
        } else {
          newOptions.push({ ...option, hidden: true });
          newBrowsers[option.id] = browser;
          if (option.prevBrowser) {
            newBrowsers[option.prevBrowser] = browsers[option.prevBrowser];
          }
        }
      }
    }
  }
  return { options: newOptions, browsers: newBrowsers, defaultOption, parentOption: parent };
}

/** 判断模块是否已经付费 */
export function isPayModules(type: string, browsers: TypesBrowserData) {
  let payInfo = false;
  // const payModules = window.TEAMS?.payModules;
  // 非接口请求浏览框配置 默认为付费
  if (!browsers) return true;
  if (type && browsers) {
    const browser = browsers[type];
    if (browser?.type) {
      const payModule = payModuleRules[browser.type];
      // 模块自定义事项,没有对照关系的直接返回已付费
      if (!payModule) return true;
      // if (payModules) {
      //   const module = payModules.find((k: { module: string, moduleStr: string }) => {
      //     if (Array.isArray(payModule)) {
      //       let ping = false;
      //       payModule.forEach((mo) => ping = ping || (k.module === mo || k.moduleStr === mo)); // 多个标记，仅一个存在即为已付费
      //       return ping;
      //     }
      //     return k.module === payModule || k.moduleStr === payModule;
      //   })
      //   if (module) payInfo = true;
      // }

      if (Array.isArray(payModule) && payModule.find((mo: string) => {
        return window.TEAMS?.isPayModule?.(mo);
      })) {
        payInfo = true;
      } else if (window.TEAMS?.isPayModule?.(payModule)) {
        payInfo = true;
      }
  
    } else {
      // 未找到结果的 默认返回可展示
      return true;
    }
  }
  return payInfo;
}

/* 多类型浏览按钮 合并browsers */
export function mergeBrowsers(originProps?: TypesBrowserData, targetProps?: TypesBrowserData): TypesBrowserData {
  let newProps: TypesBrowserData = {};
  if (originProps && targetProps) {
    Object.keys(originProps).forEach(type => {
      // 处理被禁用的模块
      // if (isModuleDisabled(type)) return;
      // 将target和origin共有的值合并
      if (type in targetProps) {
        newProps[type] = mergeProps(originProps[type], targetProps[type]);
      }
      // 将origin独有的加入到newProps中
      else {
        newProps[type] = originProps[type];
      }
    });
    Object.keys(targetProps).forEach(type => {
      // 处理被禁用的模块
      // if (isModuleDisabled(type)) return;
      // 将target独有的加入到newProps中
      if (!(type in newProps)) {
        newProps[type] = targetProps[type];
      }
    });
  } else if (originProps) {
    // 直接返回origin
    return originProps;
  } else if (targetProps) {
    // 直接返回target
    return targetProps;
  }
  return newProps;
}

export function getTabById(tabs: BrowserTabsData[], id: string): BrowserTabsData | undefined {
  let resultTab: BrowserTabsData | undefined;
  for (let i = 0; i < tabs.length; i++) {
    const tab = tabs[i];
    if (tab.id === id) {
      resultTab = tab;
      break;
    }
    if (tab.children && tab.children.length) {
      resultTab = getTabById(tab.children, id);
      if (resultTab) break;
    }
  }
  return resultTab;
}

export function getOptionById(options: TypesBrowserOption[], id?: string): TypesBrowserOption | undefined {
  let resultOption: TypesBrowserOption | undefined;
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option.id === id) {
      resultOption = option;
      break;
    }
    if (option.children?.length) {
      resultOption = getOptionById(option.children, id);
      if (resultOption) break;
    }
  }
  return resultOption;
}

export function expandTabs(tabs: BrowserTabsData[] | undefined, handler?: (tab: BrowserTabsData) => any) {
  let result: any[] = [];
  if (!tabs || tabs.length === 0) return result;
  for (let i = 0; i < tabs.length; i++) {
    const tab = tabs[i];
    result.push(handler ? handler(tab) : tab);
    if (tab.children && tab.children.length) {
      result = result.concat(expandTabs(tab.children, handler));
    }
  }
  return result;
}

export function isEffectValue(key: string, data: any) {
  if (needSync(key, data)) {
    const v = data[key];
    return v !== null && v !== '';
  }
  return false;
}

export function toArray(arr: any) {
  let newArr = [];
  if (arr) {
    newArr = toJS(arr);
    if (!Array.isArray(newArr)) {
      newArr = [newArr];
    }
  }
  return newArr;
}

// 遍历树形数据，格式化每个节点
export function formatTreeData<T extends AnyObj = AnyObj>(node: T | T[], format?: (node: T, level: number, parent?: T) => any, level = 1, parent?: T) {
  if (!node) return;
  if (Array.isArray(node)) {
    node.forEach(n => {
      format?.(n, level, parent);
      if (n.children) {
        formatTreeData(n.children, format, level + 1, n);
      }
    });
  } else {
    format?.(node, level, parent);
    if (node.children) {
      formatTreeData(node.children, format, level + 1, node);
    }
  }
}

// 获取树节点路径
export const getTreePathByKey = (curKey: string, data: AnyObj[], getDataKey: (data: SelectedData) => string, isInclude?: boolean) => {
  /** 存放搜索到的树节点到顶部节点的路径节点 */
  let result: any[] = [];
  /**
   * 路径节点追踪
   * @param {*} curKey 树节点标识的值
   * @param {array} path 存放搜索到的树节点到顶部节点的路径节点
   * @param {*} data 树
   * @returns undefined
   */
  let traverse = (curKey: string, path: any[], data: AnyObj[]) => {
    // 树为空时，不执行函数
    if (data.length === 0) {
      return;
    }

    // 遍历存放树的数组
    for (let item of data) {

      if (isInclude) {
        // 遍历的数组元素存入path参数数组中
        path.push(getDataKey(item));
      }
      // 如果目的节点的id值等于当前遍历元素的节点id值
      if (getDataKey(item) === curKey) {
        // 把获取到的节点路径数组path赋值到result数组
        result = JSON.parse(JSON.stringify(path));
        return;
      }

      if (!isInclude) {
        // 遍历的数组元素存入path参数数组中
        path.push(getDataKey(item));
      }

      // 当前元素的children是数组
      const children = Array.isArray(item.children) ? item.children : [];
      // 递归遍历子数组内容
      traverse(curKey, path, children);
      // 利用回溯思想，当没有在当前叶树找到目的节点，依次删除存入到的path数组路径
      path.pop();
    }
  };
  traverse(curKey, [], data);
  // 返回找到的树节点路径
  return result;
};

export const getTreePathByData = (curKey: string, data: AnyObj[], getDataKey: (data: SelectedData) => string, isInclude?: boolean) => {
  /** 存放搜索到的树节点到顶部节点的路径节点 */
  let result: any[] = [];
  /**
   * 路径节点追踪
   * @param {*} curKey 树节点标识的值
   * @param {array} path 存放搜索到的树节点到顶部节点的路径节点
   * @param {*} data 树
   * @returns undefined
   */
  let traverse = (curKey: string, path: any[], data: AnyObj[]) => {
    // 树为空时，不执行函数
    if (data.length === 0) {
      return;
    }

    // 遍历存放树的数组
    for (let item of data) {

      if (isInclude) {
        // 遍历的数组元素存入path参数数组中
        path.push(item);
      }
      // 如果目的节点的id值等于当前遍历元素的节点id值
      if (getDataKey(item) === curKey) {
        // 把获取到的节点路径数组path赋值到result数组
        // result = JSON.parse(JSON.stringify(path));
        result = [...path];
        return;
      }

      if (!isInclude) {
        // 遍历的数组元素存入path参数数组中
        path.push(item);
      }

      // 当前元素的children是数组
      const children = Array.isArray(item.children) ? item.children : [];
      // 递归遍历子数组内容
      traverse(curKey, path, children);
      // 利用回溯思想，当没有在当前叶树找到目的节点，依次删除存入到的path数组路径
      path.pop();
    }
  };
  traverse(curKey, [], data);
  // 返回找到的树节点路径
  return result;
};

export type RGBType = [number, number, number];

/**
 * 转换字体颜色
 *
 * @param {array} rgbArr rgb数组
 */
export function resBgColor(rgbArr: RGBType) {
  const [red, green, blue] = rgbArr;
  var color = (0.299 * red) + (0.587 * green) + (0.114 * blue);
  return color > 186 ? '#666666' : '#ffffff';
}

/**
 * 将颜色统一转化为rgb数组，形如【15, 25, 35]
 * @param colorValue 需要转化的颜色值
 * @returns [0-255, 0-255, 0-255]
 */
export function findTextColor(colorValue: string) {
  // #123456或者rgb(12,34,56)转为rgb数组[12,34,56]
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  var that = colorValue;
  var i;
  if (/^(rgb|RGB)/.test(that)) {
    // 处理rgb转为数组
    var aColor = that.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",") as unknown as RGBType;
    return resBgColor(aColor);
  } else if (reg.test(that)) {
    // 处理十六进制色值
    var sColor = colorValue.toLowerCase();
    if (sColor && reg.test(sColor)) {
      if (sColor.length === 4) {
        var sColorNew = "#";
        for (i = 1; i < 4; i += 1) {
          sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
        }
        sColor = sColorNew;
      }
      //处理六位的颜色值
      var sColorChange = [];
      for (i = 1; i < 7; i += 2) {
        sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
      }
      return resBgColor(sColorChange as RGBType);
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * 查找浏览按钮对应的事项模块标识
 * @param module 浏览按钮模块标识
 * @param type 浏览按钮类型
 * @returns 对应事项卡片的模块标识
 */
export function getModule(m: string, type: string) {
  const modules = moduleRouter.getModules?.() || {};
  const { module } = extractEbuilderAndNumber(m);
  return Object.entries(modules).find(([key, item]) => {
    if (item.module !== module) return false;
    if (Array.isArray(item.type) && item.type.length) {
      return item.type.indexOf(type) !== -1;
    } else if (item.type === type) {
      return true;
    }
    return false;
  });
}


/**
 * 处理params中的特殊参数
 * eg.
 * data: { a: 1 }
 * params: { _@a: 'a' }
 * => { a: 1 }
 *
 * eg.
 * data: { a: 1, b: 2 }
 * params: { _@a: ['c', 'b', 'a'] }
 * => { a: 2 }
 *
 * @param params 待处理的参数
 * @param data 源数据
 * @returns 处理后的参数
 */
export function formatParams(params: AnyObj, data: AnyObj) {
  if (!params || !data) return params;
  let newParams: AnyObj = {};
  const specialKeyPatt = /^_@/;
  function getDataValue(data: AnyObj, key: string | string[]) {
    let dataValue;
    if (Array.isArray(key)) {
      for (let k in key) {
        if (data[k] !== undefined) {
          dataValue = data[k];
          break;
        }
      }
    } else {
      dataValue = data[key];
    }
    return dataValue;
  }
  Object.keys(params).forEach(key => {
    if (specialKeyPatt.test(key)) {
      newParams[key.replace(specialKeyPatt, '')] = getDataValue(data, params[key]);
    } else {
      newParams[key] = params[key];
    }
  });
  return newParams;
}


/** 宽度高度格式化 */
export function parseParam(value: number | string) {
  if (isNumber(value)) return value as number;
  if (typeof value === 'string') {
    if (value.indexOf('px') > -1) return parseInt(value.replace('px', ''))
    if (value.indexOf('%') > -1) return parseInt(value.replace('%', '')) / 100 * window.innerHeight
  }
  return 0
}

/* 获取面板高度 */
export function getPanelHeight(panelHeight = defaultPanelHeight, detal: number = 0, top?: number | string, scale?: boolean) {
  let bodyHeight = panelHeight;
  // TODO: 目前无法动态获取 dialog 头部高度，所以取固定值 51
  let parentHeight = document?.documentElement?.clientHeight * 0.9 - defaultDialogHeight - detal;
  if (top) {
    // TODO: dialog 有 top 时，dialog-content 的 maxHeight 根据 top 计算
    parentHeight = parentHeight - 2 * parseParam(top);
  }
  if (parentHeight < (panelHeight || defaultPanelHeight)) {
    bodyHeight = parentHeight;
  }
  if(scale) {
    bodyHeight = Math.floor(document?.documentElement?.clientHeight * 0.95)  - defaultDialogHeight - detal;
  }
  return bodyHeight;
}

/** 获取面板宽度 */
export function getPanelWidth(width?: string | number, scale?: boolean) {
  // 弹框自适应宽度
  let bodyWidth = parseParam(width || 0);
  if (scale) {
    bodyWidth = Math.floor(document?.documentElement?.clientWidth * 0.95);
  }
  if (!width && !scale) {
    bodyWidth = window.innerWidth * 0.7;
  }
  return bodyWidth;
}

/**
 * 从组件 props 和 props.browserAssociativeProps 中获取指定参数
 * @param key string
 * @param props props
 * @returns any | undefined
 */
export function getParamFromProps(key: string, props?: AnyObj) {
  if (props?.browserAssociativeProps && (key in props.browserAssociativeProps)) {
    return props.browserAssociativeProps[key]
  } else if (props && (key in props)) {
    return props[key];
  } else {
    return undefined;
  }
}

/**
 * 从系统变量中获取指定参数
 * @param key string
 * @returns any | undefined
 */
export function getParamFromSystem(key: string, type?: string) {
  if (window?.TEAMS?.globalConfig && (key in window.TEAMS.globalConfig)) {
    return window.TEAMS.globalConfig[key]
  }
  return undefined;
}

/**
 * 从系统变量和组件 props 中获取指定参数
 * @param key string
 * @param props props
 * @returns any | undefined
 */
function _getParamFromSystemAndProps(key: string, props?: AnyObj) {
  if (props?.browserAssociativeProps && (key in props.browserAssociativeProps)) {
    return props.browserAssociativeProps[key]
  } else if (props && (key in props)) {
    return props[key];
  } else if (window?.TEAMS?.globalConfig && (key in window.TEAMS.globalConfig)) {
    return window.TEAMS.globalConfig[key]
  } else {
    return undefined;
  }
}

/**
 * 从系统变量和组件 props 中获取指定参数(可指定多个 key，依次查询，返回第一个不为 undefined 的值)
 * @param key string
 * @param props props
 * @returns any | undefined
 */
export function getParamFromSystemAndProps(key: string | string[], props?: AnyObj) {
  let params;
  if (Array.isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      const k = key[i];
      params = _getParamFromSystemAndProps(k, props)
      if (typeof params !== 'undefined') break;
    }
  } else {
    params = _getParamFromSystemAndProps(key, props);
  }
  if (params === 'false') return false;
  else if (params === 'true') return true;
  else return params;
}

/**
 * 从系统变量和组件 props 中获取 useBoxSelection 的值
 * @param props AnyObj
 * @returns boolean
 */
export function getUseBoxSelectionFromSystemAndProps(props?: AnyObj) {
  if (props?.selectedDisplayByTextarea) return true

  const useBoxSelection = getParamFromSystemAndProps('useBoxSelection', props);
  return useBoxSelection;
}

/**
 * 从组件 props 和系统变量中获取 hideAvatar(showAvatar) 的值
 * @param props props
 * @returns boolean
 */
export function getHideAvatarFromSystemAndProps(props?: AnyObj, data?: ListData) {
  const { type } = props || {};
  // 从 props 中获取 hideAvatar
  const propsHideAvatar = getParamFromProps('hideAvatar', props);
  // 从系统配置中获取 showAvatar
  let systemShowAvatar = getParamFromSystem('showAvatar', type);
  // 只有人员相关浏览按钮读取该系统配置
  if (data && TYPESBROWSERKEYS.ENTITYTYPE in data) {
    // 多类型浏览按钮
    if (!personnelOrgOptionKey.includes(data[TYPESBROWSERKEYS.ENTITYTYPE])) {
      systemShowAvatar = undefined;
    }
  } else if (!type || !personnelOrgBrowser.includes(type)) {
    systemShowAvatar = undefined;
  }
  // 结合两者获得 hideAvatar
  // 1. propsHideAvatar 有值直接取 propsHideAvatar
  // 2. systemShowAvatar 有值，取反
  // 3. systemShowAvatar 无值，取 false
  const hideAvatar = typeof propsHideAvatar !== 'undefined'
    ? propsHideAvatar
    : typeof systemShowAvatar === 'undefined'
      ? false
      : systemShowAvatar === '1'
        ? false
        : true;
  return hideAvatar;
}

/**
 * 从组件 props 和系统变量中获取 multiline 的值
 * @param props props
 * @returns boolean
 */
export function getMultilineFromSystemAndProps(props?: AnyObj) {
  // 从 props 中获取 multiline
  const propsMultiline = getParamFromProps('multiline', props);
  // 从系统配置中获取 multiline
  let systemMultiline = getParamFromSystem('multiline');
  // 兼容字符串类型
  if (systemMultiline === 'false') systemMultiline = false;
  if (typeof propsMultiline === 'undefined') {
    return typeof systemMultiline === 'undefined' ? false : systemMultiline;
  } else {
    return propsMultiline;
  }
}

/**
 * 从组件 props 和系统变量中获取 defaultHideAssociativeList 的值
 * @param props props
 * @returns boolean
 */
export function getDHALFromSystemAndProps(props?: AnyObj) {
  // 从 props 中获取 multiline
  const propsDefaultHideAssociativeList = getParamFromProps('defaultHideAssociativeList', props);
  // 从系统配置中获取 multiline
  let systemDefaultHideAssociativeList = getParamFromSystem('defaultHideAssociativeList');
  // 结合两者获得 hideAvatar
  // 1. propsHideAvatar 有值直接取 propsHideAvatar
  // 2. systemShowAvatar 有值，取反
  // 3. systemShowAvatar 无值，取 false
  const defaultHideAssociativeList = typeof propsDefaultHideAssociativeList !== 'undefined'
    ? propsDefaultHideAssociativeList
    : typeof systemDefaultHideAssociativeList === 'undefined'
      ? false
      : systemDefaultHideAssociativeList === '1'
        ? true
        : false;
  return defaultHideAssociativeList;
}

export function parseQuery(url: string) {
  url = url || '';
  var queryObj: AnyObj = {};
  var reg = /[?&]([^=&#]+)=([^&#]*)/g;
  var queryArr = url.match(reg) || [];
  for (var i in queryArr) {
    if (Object.hasOwnProperty.call(queryArr, i)) {
      var query = queryArr[i].split('=');
      var key = query[0].substr(1);
      var value = decodeURIComponent(query[1]);
      // queryObj[key] ? queryObj[key] = ([] as string[]).concat(queryObj[key], value) :
      queryObj[key] = value;
    }
  }
  return queryObj;
}

/** 判断字符是否是 中文符号 */
export const checkCnPunctuation = (value: string) => {
  const reg = /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}
/** 判断字符是否是 英文半角符号 */
export const checkEnPunctuation = (value: string) => {
  const reg = /[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

// 获取全局树形默认展开层级
export function getGlobalDefaultExpandedLevel(type: string) {
  // 人员、部门、分部、收发文单位
  if (['resource', 'department', 'subcompany', 'odocReceiveUnit'].includes(type)) {
    return getParamFromSystem('defaultExpandedLevel');
  }
  return undefined;
}

/* 计算数据高亮 */
export const getEnabledActiveIndex = <VT extends ValueType = ValueType>(options: VT, index: number, offset: number = 1): number => {
  const len = options?.length || 0;

  for (let i = 0; i < len; i += 1) {
    const current = (index + i * offset + len) % len;

    const data = options?.[current];
    if (!data?.disabled) {
      return current;
    }
  }

  return -1;
};

// 已选数据去重
export function dataDeduplication(
  data: SelectedData[] = [],
  getDataKey?: ((data: SelectedData) => string),
) {
  if (!getDataKey) return data;
  let newData: SelectedData[] = [];
  let dataMap: AnyObj = {};
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const key: string = getDataKey(item);
    if (!(key in dataMap)) {
      dataMap[key] = item;
      newData.push(item);
    }
  }
  return newData;
}

/** 节流 */
export function throttle(func: any, wait: any) {
  var previous = Date.now();
  return function (...args: any) {
    let now = Date.now();
    if (now - previous > wait) {
      func(...args);
      previous = now;
    }
  }
}
