
import { lunarInfo, solarMonth, sTermInfo } from './constants'
import { solarTerm, sFtv, lFtv } from './holiday_constants'

// --------------------节日--------------------
//2023-02-03 多语言标签需要放到function中返回，做为常量可能会导致getLabel方法失效
//农历节气 -> 注意节气是循环计算的 故在此处不能做自定义渲染 只能一起隐藏掉

// 公历节日
export const getSFtv = () => sFtv
// 农历节日
export const getLFtv = () => lFtv
// 节气
export const getSolarTerm = () => solarTerm

// --------------------计算农历--------------------
// 判断y年的农历中那个月是闰月,不是闰月返回0
export function leapMonth(y: number) {
  // tslint:disable-next-line: no-bitwise
  return lunarInfo[y - 1900] & 0xf;
}
// 返回农历y年闰月的天数
export function leapDays(y: number) {
  if (leapMonth(y)) {
    // tslint:disable-next-line: no-bitwise
    return lunarInfo[y - 1900] & 0x10000 ? 30 : 29;
  }
  return 0;
}
// 返回农历y年的总天数
export function lYearDays(y: number) {
  let sum = 348;
  // tslint:disable-next-line: no-bitwise
  for (let i = 0x8000; i > 0x8; i >>= 1) {
    // tslint:disable-next-line: no-bitwise
    sum += lunarInfo[y - 1900] & i ? 1 : 0;
  }
  return sum + leapDays(y);
}
// 返回农历y年m月的总天数
export function monthDays(y: number, m: number) {
  // tslint:disable-next-line: no-bitwise
  return lunarInfo[y - 1900] & (0x10000 >> m) ? 30 : 29;
}
// 返回公历y年m+1月的天数
export function solarDays(y: number, m: number) {
  if (m === 1) {
    // tslint:disable-next-line: no-bitwise
    return (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0 ? 29 : 28;
  }
  return solarMonth[m];
}
// 返回某年的第n个节气为几日(从0小寒起算)
export function sTerm(y: number, n: number) {
  const offDate = new Date(
    31556925974.7 * (y - 1900) +
    sTermInfo[n] * 60000 +
    Date.UTC(1900, 0, 6, 2, 5),
  );
  return offDate.getUTCDate();
}
// 修复部分节气错误
export function sTermFix(y: number, m: number) {
  let tmp1 = sTerm(y, m * 2) - 1;
  let tmp2 = sTerm(y, m * 2 + 1) - 1;
  const y1 = y % 100;
  const ly1 = Math.floor(y1 / 4);
  const base = y1 * 0.2422;
  switch (m) {
    case 1:
      tmp2 = parseInt(String(base + 18.73), 10) - ly1 - 1; // 雨水
      if (y === 2026) { tmp2 += 1; }
      break;
    case 5:
      tmp2 = parseInt(String(base + 21.37), 10) - ly1 - 1; // 夏至
      if (y === 1928) { tmp2 += 1; }
      break;
    case 6:
      tmp1 = parseInt(String(base + 7.108), 10) - ly1 - 1; // 小暑
      if (y === 1925 || y === 2016) { tmp1 += 1; }
      tmp2 = parseInt(String(base + 22.83), 10) - ly1 - 1; // 大暑
      if (y === 1922) { tmp2 += 1; }
      break;
    case 7:
      tmp2 = parseInt(String(base + 23.13), 10) - ly1 - 1; // 处暑
      break;
    case 8:
      tmp1 = parseInt(String(base + 7.646), 10) - ly1 - 1; // 白露
      if (y === 1927) { tmp1 += 1; }
      break;
    case 9:
      tmp2 = parseInt(String(base + 23.438), 10) - ly1 - 1; // 霜降
      if (y === 2089) { tmp2 += 1; }
      break;
    case 10:
      tmp1 = parseInt(String(base + 7.438), 10) - ly1 - 1; // 立冬
      if (y === 2089) { tmp1 += 1; }
      tmp2 = parseInt(String(base + 22.36), 10) - ly1 - 1; // 小雪
      break;
    case 11:
      tmp1 = parseInt(String(base + 7.18), 10) - ly1 - 1; // 大雪
      if (y === 1954) { tmp1 += 1; }
      tmp2 = parseInt(String(base + 21.94), 10) - ly1 - 1; // 冬至
      if (y === 1918 || y === 2021) { tmp2 -= 1; }
      break;
    default:
      break;
  }
  return { tmp1, tmp2 };
}

