import { RequestOptionConfig, request } from '@weapp/utils';
import { AnyObj } from '../../../types/common';
import { FormItemProps, FormSingleItemProps } from '../../form-item/index';
import { getUtils } from '../store/common';

const getShowGroupId = (items: FormItemProps) => {
	const showType = (items.showType || items.otherParams?.showType) as unknown as string;
	const showGroupId = (items.showGroupId || items.otherParams?.showGroupId) as unknown as string;
	if (showType === 'follow' || showType === 'collapsible') return showType; // follow、collapsible仅可配置对应分组
	return showGroupId || 'normal'; // 默认分组normal
}

export const isIdEqual = (a: AnyObj, b: AnyObj) => (a?.id || a?.targetId) === (b?.id || b?.targetId);

const updateSortArr = (originArr: AnyObj[], nowArr: AnyObj[]) => {
	if (!originArr || originArr?.length === 0) {
    const nowValue = nowArr?.map((v, index) => ({ ...v, sort: 65536 * (index + 1)}))
		return {
			deleteList: [], updateList: [], createList: nowValue, datas: nowValue,
		}
	}
	// 排除已删除的数据, originArr.length <= nowArr.length
	const delArr = originArr.filter((a) => !nowArr?.find((b) => isIdEqual(a, b)));
	const sortBeforeArr = originArr.filter((a) => nowArr?.find((b) => isIdEqual(a, b)));
	let updateArr: AnyObj[] = [];
	const lengthNow = nowArr.length;
	let beforeSort = 0, pass = true;
	for (let i = 0, j = 0; j < lengthNow; j++) {
		const originData = sortBeforeArr[i];
		const nowData = nowArr[j];
		if (originData) {
			if (isIdEqual(originData, nowData)) {
				// 数据相同，无需调整
				beforeSort = originData.sort;
				i++;
			} else {
				const sort = (originData.sort - beforeSort) / 2 + beforeSort;
				if (sort >= 1 && sort > beforeSort && sort < originData.sort) {
					updateArr.push({
						...nowData,
						sort,
					});
					beforeSort = sort;
				} else {
					pass = false;
					break;
				}
			}
		} else {
			// 超出部分为在末尾新增的数据
			updateArr.push({
				...nowData,
				sort: beforeSort + 65536,
			});
			beforeSort += 65536;
		}
	}
	if (!pass) {
		// 达到设定安全值，全部重新排序
		updateArr = nowArr.map((data, index) => ({
			...data,
			sort: 65536 * (index + 1),
		}))
	}
	const addArr = updateArr?.filter((a) => !originArr?.find((b) => isIdEqual(a, b)));
	const updateResult = updateArr?.filter((a) => originArr?.find((b) => isIdEqual(a, b)));
	return {
		deleteList: delArr, updateList: updateResult, createList: addArr, datas: nowArr?.map((da) => {
			const nowSort = updateArr?.find((a) => a.id === da.id)?.sort;
			return {
				...da,
				sort: nowSort,
			}
		})
	}
}

const browserFetchData = (options: RequestOptionConfig, item: FormSingleItemProps) => {
	return getUtils().then((defaultModule) => defaultModule.browserFetchData(options, item));
} 
export {
	getShowGroupId,
	updateSortArr,
	browserFetchData,
}