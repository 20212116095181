import Loadable from '../../react-loadable';
import { avatarClsPrefix } from '../../constants/index';
import { AvatarType } from './types';

const Avatar = Loadable({name:'Avatar', loader: () => import(
  /* webpackChunkName: "ui_common" */
  './Avatar') }) as AvatarType;

Avatar.defaultProps = {
  prefixCls: avatarClsPrefix,
};

export type { AvatarProps } from './types';

export default Avatar;
