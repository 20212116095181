import Loadable from '../../react-loadable';
import { AudioType } from './types';
import showAudio from './showAudio';
import showMAudio from './showAudio.m'
import { ua } from '@weapp/utils'

const Audio = Loadable({
  name: 'Audio', loader: () => import(
    /* webpackChunkName: "ui_common_other" */
    './Audio')
}) as AudioType;

Audio.showAudio = ua.device === 'PC' ? showAudio : showMAudio

export type { AudioProps } from './types';

export default Audio;
