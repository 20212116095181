import { Loadable, constants } from '../../lib';
import { SelectType } from './types';

const { selectClsPrefix } = constants
const Select = Loadable({ name: 'Select', loader: () => import(
  /* webpackChunkName: 'ui_form' */
  './Select') }) as SelectType;

Select.defaultProps = {
  prefixCls: selectClsPrefix,
  showArrow: true,
};

export type { SelectProps, SelectOptionProps, OptionData as SelectOptionData, ValueType as SelectValueType, SelectEvent, CustomTagProps as SelectCustomTagProps, OptionsType } from './types';

export default Select;
