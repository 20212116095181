import gcoord from 'gcoord';
import { mapType, MapPointType } from '../types';

// 百度经纬度转换具体的报错信息
// const transCodidateMsg = (status: number) => {
//   switch(status) {
//     case 1: return '地图内部错误';
//     case 4: return '转换失败,X→GPS时必现';
//     case 21: return 'from非法';
//     case 22: return 'to非法';
//     case 24: return 'coords格式非法';
//     case 25: return 'coords个数非法，超过限制';
//     case 26: return '参数错误';
//     default: return '';
//   }
// }

/**
 * 坐标转换: 
 * BD09投影坐标系: 百度;  GCJ-02火星坐标系: gaode | tencent;
 * @param from 待转换地图类型
 * @param to 转换地图类型
 * @param converList 待转换坐标数组,经度和纬度用","分割，经度在前，纬度在后
 * @param mapKey 地图的key
 */
//  const coordinateConversion = (from: mapType, to: mapType, converList: MapPointType[]) => {
//   const fromKey = getLocationKey(from);
//   const toKey = getLocationKey(to);
//   const mapKey = getMapKey('baidu');

//   return new Promise((rs, rj) => {
//     const coords = converList.map(point => `${point[0]},${point[1]}`).join(';');
//     const funcName = `coordinateConversion_${getHash()}`;
//     window[funcName] = (result: any) => {
//       if (result.status === 0) {
//         const data = result.result?.map?.((point: any) => {
//           return { lng: point.x, lat: point.y }
//         });
//         rs(data);
//       } else {
//         console?.error?.('地图经纬度转换失败:', result);
//         rj(`${getLabel('77045', '地图经纬度转换失败')}<br/> from: ${from}<br/> to: ${to}<br/> status: ${result.status}<br/> info: ${transCodidateMsg(result.status)}`)
//       }
//       window[funcName] = null;
//     }
//     loadjs(`${window.location.protocol}//api.map.baidu.com/geoconv/v1/?coords=${coords}&from=${fromKey}&to=${toKey}&ak=${mapKey}&callback=${funcName}`, () => { })
//   });
// }


// 不同地图类型对应的坐标 (gcoord插件坐标类型)
const getGcoordTypeByMapType = (mapType: string) => {
  switch(mapType) {
    case 'google': return gcoord.WGS84;
    case 'baidu': return gcoord.BD09;
    default: return gcoord.GCJ02; // gaode | tencent
  }
}

/**
 * 坐标转换: 
 * BD09投影坐标系: 百度;  GCJ-02火星坐标系: gaode | tencent;   WGS84坐标: google
 * @param from 待转换地图类型
 * @param to 转换地图类型
 * @param converList 待转换坐标数组,经度和纬度用","分割，经度在前，纬度在后
 * @param mapKey 地图的key
 */

// 更改之后,经纬度转换是不需要异步的(该方法目前就组件内部使用)
const coordinateConversion = (from: mapType, to: mapType, converList: MapPointType[]) => {
  const fromGcoordType = getGcoordTypeByMapType(from);
  const toGcoordType = getGcoordTypeByMapType(to);

  const resultList: any[] = [];
  converList.map((lanLat: MapPointType) => {
    const result = gcoord.transform(
      [Number(lanLat[0]), Number(lanLat[1])], // 经纬度坐标
      fromGcoordType, // 当前坐标系
      toGcoordType // 目标坐标系
    );
    resultList.push([result[0], result[1]]);
  });
  return resultList;
}

export default coordinateConversion;