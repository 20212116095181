import { moduleRouter } from '@weapp/utils';
import PhotoView from '../../photo-view/index';
import MPhotoView from '../../photo-view/index.m';
import HrmCard from '../../hrm-card';
import Upload from '../../upload';
import { isMobile, getLang, throttle } from '../../../utils';
import { AnyObj } from '../../../types/common';

const SECONDPATCH_BACKEND = window.publicUrlapi || window.publicUrl || '';
const { openHrmCard, openMobileHrmCard } = HrmCard;

// 获取图片预览地址
export const getImagePreviewUrl = (fileid?: string, isMobile?: boolean, loginFree?: boolean) => {
  const previewUrl = loginFree ? '/papi/file/preview' : (isMobile ? '/api/app/file/preview' : '/api/file/preview');
  return `${SECONDPATCH_BACKEND}${previewUrl}?fileId=${fileid}&type=redirect`;
}

// 获取图片上传地址
export const getImageUploadUrl = (isMobile?: boolean, loginFree?: boolean) => {
  const previewUrl = loginFree ? '/papi/file/module/upload' : (isMobile ? '/api/app/file/module/upload' : '/api/file/module/upload');
  return `${SECONDPATCH_BACKEND}${previewUrl}`;
}

// 事项卡片预览
export const previewTypeBrowser = (curATag: any, props: any) => {
  const module = curATag.getAttribute('data-module') || '';
  const dataId = curATag.getAttribute('data-id') || '';
  if (isMobile) { // Mobile: 路由切页
    moduleRouter.open(props, module, dataId, 'link');
  } else { // PC：打开普通的弹框（默认是从中间弹框出）
    moduleRouter.open(props, module, dataId);
  }
}

// 图片预览
export const previewImg = (imgList: any, index: number, loginFree?: boolean) => {
  if (isMobile) {
    MPhotoView.showImage(imgList, index, { loginFree });
  } else {
    PhotoView.showImage(imgList, index, { loginFree });
  }
}

// 附件预览
export const previewFile = (curFile: any, loginFree?: boolean) => {
  const fileId = curFile.getAttribute('data-id') || '';
  const isImage = curFile.getAttribute('isImage') === 'true';
  const previewOptions = {
    fileId,
    loginFree,
    isImage
  };
  Upload.filePreview(previewOptions);
}

// 打开人力卡片 HrmCardType: 'inside' | 'department' | 'group' | 'external'
export const perviewHrmCard = (curAt: any, e: any) => {
  const userId = curAt.getAttribute('data-id') || '';
  const atType = curAt.getAttribute('data-type') || '';
  if (isMobile) {
    // 支持外部人员和内部人员
    if (['user', "inside", 'external'].includes(atType) && userId) {
      const hrmType = atType === 'external' ? 'external' : '';
      openMobileHrmCard(userId, hrmType)
    }
  } else {
    // 除事项成员外都可打卡卡片
    atType !== "targetMember" && openHrmCard(e, userId, atType === "user" ? "inside" : atType, 'bs/hrm', { path: "card" });
  }
}

// 获取系统多语言 对应的 富文本多语言
export const getCkLang = () => {
  const localLang = getLang();
  switch (localLang) {
    case 'zh_CN': return 'zh-cn';
    default: return localLang.split('_')[0];
  }
}

// 匹配lable和value
export const mathLabel = (v: string) => {
  if (typeof v === 'string' && v.indexOf('/') > -1) {
    const arr = v.split('/');
    return {
      label: arr[0],
      value: arr[1],
    }
  }
  return {
    label: v,
    value: v,
  }
}

// 统一封装data值转node,并获取如何条件的nodeList方法
export const getFilterList = (data: string, rule: any) => {
  // const node = document.createElement('div');
  // 换成createHTMLDocument方式 避免处理图片会请求接口
  const doc = document.implementation.createHTMLDocument('');
  doc.body.innerHTML = data;
  const filterNodeList = doc.querySelectorAll ? doc.querySelectorAll(rule) : [];
  return {
    node: doc.body,
    filterNodeList
  };
};

// 图片懒加载 + 给图片绑定点击事件
export const lazyLoadImages = (imgs: NodeListOf<HTMLImageElement>, hasScroll?: boolean) => {
  if (hasScroll) {
    // 当前视口的高度
    const vh = window.innerHeight || document.documentElement.clientHeight;
    // num用于统计避免每次都从第一张图片检查
    let num = 0
    const lazyLoad = () => {
      for (let i = num; i < imgs.length; i++) {
        // 获取该img相对于视口的位置
        let imgTop = imgs[i].getBoundingClientRect().top;
        // 元素距离视口顶端高度小于或等于视口高度，说明元素露出
        if (vh >= imgTop) {
          if (!imgs[i].getAttribute('src')) {
            // 给img的src属性赋值
            imgs[i].src = imgs[i].getAttribute('data-src') || '';
          }
          num = i + 1;
        }
      }
    }
    throttle(lazyLoad, 200)();
  } else {
    // 全量加载
    const loadAllImages = () => {
      for (let i = 0; i < imgs.length; i++) {
        if (!imgs[i].getAttribute('src')) {
          // 给img的src属性赋值
          imgs[i].src = imgs[i].getAttribute('data-src') || '';
        }
      }
    }
    throttle(loadAllImages, 200)();
  }
}

// /**
//  * 向上递归查找是否包含a标签
//  * @param imgNode  img的node节点
//  * @param recurNum  递归次数
//  */
// const containsTagA = (imgNode: HTMLElement | ParentNode, recurNum: number): boolean => {
//   const parentNode: HTMLElement | null = imgNode.parentElement;
//   const parentTagName = (parentNode as HTMLElement)?.tagName;
//   const isContainsTagA = parentNode?.tagName === 'A' || parentNode?.getAttribute('onclick') || parentNode?.getAttribute('href');
//   // 达到递归次数 | 父节点是body标签 | 父节点是a标签 | 不存在父节点的时候递归结束
//   if (recurNum === 0 || parentTagName === 'body' || !parentNode || isContainsTagA) {
//     return !!isContainsTagA;
//   } else {
//     return containsTagA(parentNode, recurNum - 1);
//   }
// };

// 获取需要通过点击事件预览的图片 和 预览组件需要的图片数据
export const getPreviewImageList = (imgs?: NodeListOf<HTMLImageElement>) => {
  const imgPreviewList = [];
  const imgPreviewData = [];
  if (imgs && imgs.length > 0) {
    for (let i = 0; i < imgs.length; i++) {
      const img = imgs[i];
      const src = img.getAttribute('data-src') || '';
      const parentNode = img?.parentElement;
      // 排除表情图片
      const isEmoticon = img.getAttribute('pluginname') === 'emoticon';
      // 排除E9老数据
      const isMigration = src.indexOf('/messager/usericon/') > -1;
      // 排除图片只是作为链接
      const isExcluded = parentNode?.tagName === 'A' || parentNode?.getAttribute('onclick') || parentNode?.getAttribute('href');
      if (!isEmoticon && !isMigration && !isExcluded) {
        // 复制粘贴的情况下没有data-id。直接从src中截取
        const id = img.getAttribute('data-id') || src.split('fileId=')?.[1]?.split('&')?.[0] || '';
        imgPreviewList.push(img);
        imgPreviewData.push({ id, src, fileId: id });
      }
    }
  }
  return { imgPreviewList, imgPreviewData };
}

// 向上查找富文本组件的父节点,直到breackParent结束
const getParentNodes = (breackParent: Element, editorRoot: Element) => {
  const parentNodes = [];
  let parent = editorRoot?.parentElement;
  while (parent) {  // 递归获取父级节点
    parentNodes?.push(parent);
    parent = parent.parentElement;
    if (Object.is(parent, breackParent)) {
      break;
    }
  }
  return parentNodes;
}

// 通过getComputedStyle拿到的样式，需要转换为数字
const transComputedStyle = (value: string) => {
  if (!value) return 0;
  const newValue = value && value.replace(/[^\d]/g, '');
  if (!newValue) return 0;
  return Number(newValue);
}

// 计算所有节点的上线间隙(padding + border)
const calculateNodesInterval = (parentNodes: any[]) => {
  let interval = 0;
  parentNodes.forEach((parent: any) => {
    const { paddingTop, borderTopWidth, paddingBottom, borderBottomWidth } = window.getComputedStyle(parent) || {};
    interval += transComputedStyle(paddingTop) + transComputedStyle(borderTopWidth) + transComputedStyle(paddingBottom) + transComputedStyle(borderBottomWidth);
  });
  return interval;
}

// 获取指定父节点到富文本中间的间隙
export const getInterval = (parent: Element, editorRoot: Element) => {
  const parentNodes = getParentNodes(parent, editorRoot);
  const interval = calculateNodesInterval(parentNodes);
  return interval;
}

// 设置默认字体、行高、颜色、加粗等
export const getDefaultStyle = (ckConfig: AnyObj = {}) => {
  const {
    font_defaultLabel,
    font_defaultColor,
    fontSize_defaultLabel,
    lineHeight_defaultLabel,
    fontWeight_default,
  } = ckConfig;
  const styleObj: any = {};
  const _font_defaultLabel = mathLabel(font_defaultLabel).value;
  const _fontSize_defaultLabel = mathLabel(fontSize_defaultLabel).value;
  if (font_defaultLabel && typeof font_defaultLabel === "string") {
    styleObj["fontFamily"] = _font_defaultLabel;
  }
  if (fontSize_defaultLabel && typeof fontSize_defaultLabel === "string") {
    styleObj["fontSize"] = _fontSize_defaultLabel;
  }
  if (
    lineHeight_defaultLabel &&
    typeof lineHeight_defaultLabel === "string"
  ) {
    styleObj["lineHeight"] = lineHeight_defaultLabel;
  }
  if (fontWeight_default) {
    styleObj["fontWeight"] = fontWeight_default;
  }
  if (font_defaultColor) {
    styleObj["color"] = font_defaultColor;
  }
  return styleObj;
};