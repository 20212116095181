/* getCurrentPosition 获取当前位置API */
const getCurrentPositionAsync = () => import(
  /* webpackChunkName: "map_common" */
  './getCurrentPositionAsync'
);

// 腾讯定位
export const getTencentLocation = (mapKey: string, noNeedAddress?: boolean) => {
  return getCurrentPositionAsync()?.then((defaultModule) => defaultModule?.getTencentLocation(mapKey, noNeedAddress));
}

// 谷歌定位
export const getGoogleLocation = (mapKey: string, noNeedAddress?: boolean) => {
  return getCurrentPositionAsync()?.then((defaultModule) => defaultModule?.getGoogleLocation(mapKey, noNeedAddress));

}

// 再封装一层获取默认地图配置的功能
// const getCurrentPosition = (mapType?: any, getMapLocationWhenSdkFail?: boolean, noNeedAddress?: boolean, showSdkTips?: boolean, sdkReadyParams?: anyObj, loginFree?: boolean, onSDKLocationChanged?: (res: any) => void) => {
//   return getCurrentPositionAsync()?.then((defaultModule) => defaultModule?.default(mapType, getMapLocationWhenSdkFail, noNeedAddress, showSdkTips, sdkReadyParams, loginFree, onSDKLocationChanged))
// }
const getCurrentPosition = (...args: any) => {
  return getCurrentPositionAsync()?.then((defaultModule) => defaultModule?.default(...args))
}

export default getCurrentPosition;