import { mListClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { MListType, MListProps } from './types';

const MList = Loadable({
  name: 'MList',
  loader: () => import(
    /* webpackChunkName: "ui_frequent" */
    './List')
}) as MListType;

const IndexedList = Loadable({
  name: 'MIndexedList',
  loader: () => import(
    /* webpackChunkName: "ui_frequent" */
    './IndexedList')
}) as MListType;
const defaultProps: MListProps = {
  prefixCls: mListClsPrefix,
  direction: 'column',
  sortable: false,
  sortableOptions: {},
  onEndReachedThreshold: 10,
  scrollRenderAheadDistance: 500,
  horizontalScroll: false,
  initialOrder: 0,
  dataKey: 'id',
  groupKey: 'id',
  dataType: 'type',
  groupContent: 'content',
  isMobile: true,
  selectedRowKeys:[],
  // loading:false,
  hasMore:true
}
MList.IndexedList = IndexedList;
MList.IndexedList.defaultProps = defaultProps;

MList.defaultProps = defaultProps;

export type { MListProps, MListItemProps, ListData as MListData, GroupData,GroupOptionData,PaginationType as MListPaginationType } from './types';

export default MList;
