import { recordingClsPrefix } from '../../constants';
import Loadable from '../../react-loadable';
import { MRecordingType } from './types';

const Recording = Loadable({
  name: 'Recording', loader: () => import(
    /* webpackChunkName: "recording" */
    './Recording')
}) as MRecordingType;

Recording.defaultProps = {
  prefixCls: recordingClsPrefix,
  type: "audio",
};
Recording.displayName = "Recording";

export type { RecordingProps } from './types';

export default Recording;