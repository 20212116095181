import { btnClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { ButtonType } from './types';
import _Button from './Button';

const Button = Loadable({
  name: 'Button',
  component: _Button,
  // loader: () => import(
  // /* webpackChunkName: "button" */
  // './Button'),
}) as ButtonType;

Button.defaultProps = {
  prefixCls: btnClsPrefix,
  inline: true
};

export type { ButtonProps, BtnType } from './types';

export default Button;
