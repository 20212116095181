import Loadable from '../../react-loadable';
import { RateType } from './types';
import { rateClsPrefix } from '../../constants/index';

const Rate = Loadable({
  name: 'Rate',
  loader: () => import(
    /* webpackChunkName: 'ui_form' */
    './Rate')
}) as RateType;

Rate.defaultProps = {
  defaultValue: 0,
  count: 5,
  allowHalf: false,
  allowClear: true,
  prefixCls: rateClsPrefix,
  direction: 'ltr',
  readOnlyFilterEmptyRate: false
};

export type { RateProps, RateType, RateOptionProps } from './types';

export default Rate;
