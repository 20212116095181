import Loadable from "../../react-loadable";
import { mMattersAssociatedClsPrefix } from "../../constants/index.m";
import { MMattersAssociatedType } from "./types";

const MMattersAssociated = Loadable({
  name: 'MMMattersAssociated', loader: () => import(
    /* webpackChunkName: "ui_m_common_less" */
    './MattersAssociated.m')
}) as MMattersAssociatedType;


/*------------------------*/
MMattersAssociated.defaultProps = {
  prefixCls: mMattersAssociatedClsPrefix,
  readOnly: false,
};


export type { MMattersAssociatedProps } from "./types";
export default MMattersAssociated;