/* reverseGeolocation 逆地理位置解析 */

// 再封装一层获取默认地图配置的功能
const reverseGeolocation = (mapType?: any, location?: string) => {
  return import(
  	/* webpackChunkName: "map_common" */
    './reverseGeolocationAsync'
  ).then((defaultModule) => defaultModule.default?.(mapType, location));
}

export default reverseGeolocation;