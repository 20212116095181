import { appInfo } from "@weapp/utils";
const { publicUrl } = appInfo('@weapp/ui');

let emojiModule: any = {};

export const getEmojiModuleSync = () => {
  if (Object.keys(emojiModule).length > 0) return emojiModule;

  return import(
    /* webpackChunkName: "emoticon_emojis" */
    './getEmojiModule'
  ).then(defaultModule => defaultModule.emojiModule)
}

/**
 * 异步获取emoji图片地址
*/
export const getEmojiImageSync = async (iconName: string) => {
  if (Object.keys(emojiModule).length > 0) return emojiModule[iconName] || '';

  emojiModule = await getEmojiModuleSync();
  return emojiModule[iconName] || "";
}

/**
 * 同步获取emoji图片地址
*/
export const getEmoticonImgSrc = (iconName: string) => {
  return `${publicUrl}/build/ui/static/media/${iconName}.png`
}