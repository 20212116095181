import Loadable from '../../react-loadable';
import { PortalProp } from './types';

const Portal = Loadable({ name: 'Portal', loader: () => import(
   /* webpackChunkName: "ui_common" */
  './Portal') }) as PortalProp;

export type { PortalProps } from './types';

export default Portal;
