import { bubblyBgClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { BubblyBgType } from './types';

const BubblyBg = Loadable({
  name: 'BubblyBg', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './BubblyBg'
  )
}) as BubblyBgType;

BubblyBg.defaultProps = {
  prefixCls: bubblyBgClsPrefix,
  theme: 1
}

export type { BubblyBgProps, BubblyBgType } from './types';

export default BubblyBg;
