import { getLabel } from '@weapp/utils';
import { getCkLang } from './utils';

export const language = getCkLang();
export const fontFamilys_zh_cn = [
  '微软雅黑/Microsoft YaHei',
  '微软正黑/Microsoft JhengHei',
  '宋体/SimSun',
  '新宋体/NSimSun',
  '仿宋/FangSong',
  '仿宋_GB2312/FangSong_GB2312',
  '方正小标宋简体/FZXiaoBiaoSong-B05S',
  '楷体/KaiTi',
  '楷体_GB2312/KaiTi_GB2312',
  '黑体/SimHei',
  '华文细黑/STXihei',
  '华文楷体/STKaiti',
  '华文宋体/STSong',
  '华文中宋/STZhongsong',
  '华文仿宋/STFangsong',
  '华文彩云/STCaiyun',
  '华文琥珀/STHupo',
  '华文隶书/STLiti',
  '华文行楷/STXingkai',
  '华文新魏/STXinwei',
  '方正舒体/FZShuTi',
  '方正姚体/FZYaoti',
  '细明体/MingLiU',
  '新细明体/PMingLiU',
  'Arial',
  'Comic Sans MS',
  'Courier New',
  'Georgia',
  'Lucida Sans Unicode',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
]
export const fontFamilys_zh = [
  '微軟雅黑/Microsoft YaHei',
  '微軟正黑/Microsoft JhengHei',
  '宋體/SimSun',
  '新宋體/NSimSun',
  '仿宋/FangSong',
  '仿宋_GB2312/FangSong_GB2312',
  '方正小標宋簡體/FZXiaoBiaoSong-B05S',
  '楷體/KaiTi',
  '楷體_GB2312/KaiTi_GB2312',
  '黑體/SimHei',
  '華文細黑/STXihei',
  '華文楷體/STKaiti',
  '華文宋體/STSong',
  '華文中宋/STZhongsong',
  '華文仿宋/STFangsong',
  '華文彩雲/STCaiyun',
  '華文琥珀/STHupo',
  '華文隸書/STLiti',
  '華文行楷/STXingkai',
  '華文新魏/STXinwei',
  '方正舒體/FZShuTi',
  '方正姚體/FZYaoti',
  '細明體/MingLiU',
  '新細明體/PMingLiU',
  'Arial',
  'Comic Sans MS',
  'Courier New',
  'Georgia',
  'Lucida Sans Unicode',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
]
export const fontFamilys_en = [
  'Microsoft YaHei',
  'Microsoft JhengHei',
  'Arial',
  'Comic Sans MS',
  'Courier New',
  'Georgia',
  'Lucida Sans Unicode',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
  'SimSun',
  'NSimSun',
  'FangSong',
  'FangSong_GB2312',
  'FZXiaoBiaoSong-B05S',
  'KaiTi',
  'KaiTi_GB2312',
  'SimHei',
  'STXihei',
  'STKaiti',
  'STSong',
  'STZhongsong',
  'STFangsong',
  'STCaiyun',
  'STHupo',
  'STLiti',
  'STXingkai',
  'STXinwei',
  'FZShuTi',
  'FZYaoti',
  'MingLiU',
  'PMingLiU',
];
// 根据多语言显示不同的字体
const fontFamilys = language === 'zh-cn' ? fontFamilys_zh_cn : language === 'zh' ? fontFamilys_zh : fontFamilys_en;

const fontSize = ['12/12px', '14/14px', '16/16px', '18/18px', '20/20px', '22/22px', '24/24px', '26/26px', '28/28px', '36/36px', '48/48px', '72/72px'];

export const getDefaultConfig = () => {
  const defaultConfig = {
    // allowedContent: true, // 禁用过滤器
    disallowedContent: "colgroup col",
    // extraAllowedContent: 'img', // 复制的时候保留图片
    toolbar: [ // 默认工具栏
      [
        "Undo",
        "Redo",
        "-",
        "Font",
        "FontSize",
        "TextColor",
        "BGColor",
        "FillColor", // 填充色
        "Bold",
        "Italic",
      ]
    ],
    pasteFilter: null, // 复制粘贴过滤条件： 1、null: 不过滤  2、semantic-content: 保留除了div、span的标签和除了style和class的属性 3、plain-text： 纯文本
    // forcePasteAsPlainText: 'allow-word', // 复制word保留style,其他情况都纯文本粘贴
    editorplaceholder: getLabel('12311', '请输入'),
    uploadUrl: '/api/file/module/upload', // 上传地址
    height: 200, // 默认body高度
    language,
    font_names: fontFamilys.join(',').replace(/\,/g, ';'), // 字体选项
    fontSize_sizes: fontSize.join(';'),
    indentOffset: 2, // 每个缩进步骤的缩进单位大小
    indentUnit: 'em', // 缩进单位
    extraLans: { // 额外添加的多语言
      table: {
        limitInfo: getLabel('61356', '超出最大行列数100限制,请重新输入!'),
      },
      link: {
        openLink: getLabel('83960', '打开超链接')
      },
      image2: {
        imagePreview: getLabel('109689', '图像预览')
      },
      colorbutton: {
        fillColorTitle: getLabel('229887', '填充色')
      }
    },
    // plugins: defaultPlugins.join(',')
  };
  return defaultConfig;
};

// 和ck底层打包配置同步,根据不同的插件配置,加载不同的底层js文件(暂不需要)
// export const ckPluginsTypeMap = {
//   // 简易模式插件
//   'simple': [
//     // 编辑必须
//     'wysiwygarea',
//     // 回车键行为
//     'enterkey',
//     // html转义 &nbsp; &gt; 等
//     'entities',
//     // 粘贴板
//     'clipboard',
//     //粘贴链接自动转换为超链接
//     'autolink',
//     // 复制工具
//     'pastetools',
//     // 复制word、excel
//     'pastefromword',
//     // placeholder
//     'editorplaceholder',
//     // 工具栏
//     'toolbar',
//     // 撤销、回退
//     'undo',
//     // 加粗、斜体、下划线、删除线等
//     'basicstyles',
//     // 字体
//     'font',
//     // 文本 颜色 和背景色
//     'colorbutton',
//     // 图片(包括了复制粘贴上传图片、图片缩放等处理)
//     'image2',
//     'uploadimage',
//     // 最大化 (PC端配置工具栏默认显示)
//     'maximize',
//   ],
//   // 文档模式插件：基于simple模式, 加载全部插件
//   'full': [
//     // 源码模式
//     'sourcearea',
//     // 格式
//     'format',
//     'stylescombo',
//     // 首行缩进
//     'textindent',
//     // 行高
//     'lineheight',
//     // 增加 | 减少缩进量
//     'indentlist',
//     'indentblock',
//     // 对齐方式
//     'justify',
//     // 序号
//     'list',
//     'liststyle',
//     // 表格
//     'table',
//     'tableselection',
//     'tabletools',
//     'tableresizerowandcolumn', // 表格拖拽调整宽高
//     // 链接
//     'link',
//     // 特殊标签联想搜索和匹配 (例如输入框中的@)
//     'mentions',
//     // 扩展插件 (结合extendsConfig使用)
//     'customExtend',
//     // 上传视频
//     'html5video',
//     // 页面属性: fullPage我true方可使用
//     'docprops',
//     // 全选
//     'selectall',
//     // 查找替换
//     'find',
//     // 格式刷
//     'copyformatting',
//     // 清楚格式
//     'removeformat',
//     // 特殊字符
//     'specialchar',
//     // 插入水平线
//     'horizontalrule',
//     // 插入水平分页符
//     'pagebreak',
//     // 插入iframe
//     'iframe',
//     // 块引用
//     'blockquote',
//   ]
// }

// ck simple包 对应的所有工具栏: 用来和用户配置的toolbar进行对比
export const simpleToolBar = [
  "Undo", // 撤销
  "Redo", // 回退
  "-",
  "Font", // 字体
  "FontSize", // 字号
  "TextColor", // 颜色
  "BGColor", // 背景色
  "FillColor", // 填充色
  "-",
  "Bold", // 加粗
  "Italic", // 斜体
  "Underline", // 下划线
  "Strike", // 删除线
  "Subscript", // 下标
  "Superscript", // 上标
  "-",
  "Maximize" // 最大化
]
