import Loadable from '../../react-loadable';
import showImage from './showImage';
import showMImage from './showImage.m';
import { PhotoViewType } from './types';
import 'weapp-photoview/dist/index.css';
import { ua } from '@weapp/utils'

const PhotoView = Loadable({
  name: 'PhotoView', loader: () => import(
    /* webpackChunkName: 'photo-view' */
    './PhotoView')
}) as PhotoViewType;

PhotoView.showImage = ua.device === 'PC' ? showImage : showMImage

export type { PhotoViewProps, PhotoViewType, PhotoViewData } from './types';

export { PhotoView };

export default PhotoView;
