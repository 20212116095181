import { classnames } from "@weapp/utils";
import React, { FC, memo, ReactNode, useMemo } from "react";
import { needSync } from '../../utils/index';
import { getHash } from '../../utils/index';
import { SkeletonProps, SkeletonSingleProps } from "./types";


const SkeletonInner: FC<any> = (props) => {
  const { prefixCls } = props;
  return (
    <div className={`${prefixCls}-inner`} />
  )
}

const SkeletonSingle = memo(React.forwardRef<{}, SkeletonSingleProps>(
  (props) => {
    const { height, leftNum = 1, rightNum = 1, avatar, displayType, prefixCls } = props;

    const style = useMemo(() => {
      return { height };
    }, [height]);

    const leftItem = <div className={`${prefixCls}-single-left`} key={getHash()}>
      <SkeletonInner weId={`${props.weId || ''}_lmn9iy`} prefixCls={prefixCls} />
    </div>

    const rightItem = <div className={`${prefixCls}-single-right`} key={getHash()}>
      <SkeletonInner weId={`${props.weId || ''}_cg2q5l`} prefixCls={prefixCls} />
    </div>

    let leftNode: ReactNode = <>{new Array(leftNum).fill(leftItem)}</>;
    let rightNode: ReactNode = <>{new Array(rightNum).fill(rightItem)}</>;
    if(displayType === "row") {
      leftNode = leftNum > 0 && (
        <div className={`${prefixCls}-single-row`}>{leftNode}</div>
      )
      rightNode = rightNum > 0 && (
        <div className={`${prefixCls}-single-row`}>{rightNode}</div>
      )
    }

    const avatarNode = (
      <div className={`${prefixCls}-single-avatar`}>
        <div className={`${prefixCls}-single-avatar-content`} key={getHash()}>
          <SkeletonInner weId={`${props.weId || ''}_qd6y7l`} prefixCls={prefixCls} />
        </div>
      </div>
    )

    return (
      <div className={`${prefixCls}-single`} style={style}>
        {avatar && avatarNode}
        {leftNode}
        {rightNode}
      </div>
    )
  }
))

const Skeleton: FC<SkeletonProps> = memo(React.forwardRef<{}, SkeletonProps>(
  (props, ref) => {
    const { prefixCls, skeletonHeight, style, skeletonItemHeight: _skeletonItemHeight, span, leftNum, rightNum, avatar, displayType, border } = props;

    const skeletonStyle = useMemo(() => {
      return needSync('skeletonHeight', props) ? { ...style, skeletonHeight } : { ...style };
    }, [skeletonHeight, style]);

    let content = useMemo(() => {
      const skeletonItemHeight = _skeletonItemHeight || 40;
      const num = Math.floor(skeletonHeight / skeletonItemHeight);
      let con = [];
      if (span) {
        for (let i = 0; i < num * span; i++) {
          if (span === 2) {
            con.push(<div className={`${prefixCls}-half`} key={`${props.weId || ''}_ev1qtx@Skeleton${i}`}>
              <SkeletonSingle
                weId={`${props.weId || ''}_ev1qtx@Skeleton${i}`}
                height={skeletonItemHeight}
                leftNum={leftNum}
                rightNum={rightNum}
                displayType={displayType}
                avatar={avatar}
                prefixCls={prefixCls}
              />
            </div>)
          } else {
            con.push(
              <SkeletonSingle
                weId={`${props.weId || ''}_ev1qtx@Skeleton${i}`}
                key={`${props.weId || ''}_ev1qtx@Skeleton${i}`}
                height={skeletonItemHeight}
                leftNum={leftNum}
                rightNum={rightNum}
                displayType={displayType}
                avatar={avatar}
                prefixCls={prefixCls}
              />
            )
          }
        }
      }
      return con;
    }, [skeletonHeight, _skeletonItemHeight, span, leftNum, rightNum, props.weId, avatar, displayType]);

    const cls = classnames(prefixCls, {
      [`${prefixCls}-block`]: !span,
      [`${prefixCls}-border`]: border,
    });

    return (
      <div className={cls} style={skeletonStyle}>
        {
          span ? content : (
            <SkeletonInner weId={`${props.weId || ''}_d7vy54`} prefixCls={prefixCls}/>
          )
        }
      </div>
    )
  }
))

export default Skeleton;