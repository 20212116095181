import { mAuthClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { MAuthType, AuthFormType } from './types';

const MAuth = Loadable({ name: 'MAuth', loader: () => import(
  /* webpackChunkName: 'ui_m_common_less' */
  './MAuth') }) as MAuthType;

MAuth.defaultProps = {
  prefixCls: mAuthClsPrefix,
  isMobile: true,
};

const AuthForm = Loadable({ name: 'MAuthForm', loader: () => import(
  /* webpackChunkName: 'ui_m_common_less' */
  './AuthForm') }) as AuthFormType;

AuthForm.defaultProps = {
  prefixCls: mAuthClsPrefix,
};

MAuth.AuthForm = AuthForm;

export type { MAuthType, MAuthProps } from './types';

export default MAuth;
