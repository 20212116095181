import Loadable from '../../react-loadable';

import { PaginationType } from './types';
import { paginationClsPrefix } from '../../constants/index';

const Pagination = Loadable({ name: 'Pagination', loader: () => import(
  /* webpackChunkName: 'ui_common' */
  './Pagination')
}) as PaginationType;

Pagination.defaultProps = {
  prefixCls: paginationClsPrefix,
}

export type { PaginationProps } from './types';

export default Pagination;
