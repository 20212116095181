
import { USESKELETON } from '../../constants/index';
import Loadable, { LoadingType } from '../../react-loadable';
import { MIconSelectionType, IconSelectionProps, RichIconSelectionType } from './types';
import { getIconInfo } from './utils/util';

const MIconSelectionSkeleton: LoadingType<IconSelectionProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MIconSelection = Loadable({
  name: 'MIconSelection', loading: MIconSelectionSkeleton, loader: () => import(
    /* webpackChunkName: 'ui_m_common_less' */
    './IconSelection.m')
}) as MIconSelectionType;

MIconSelection.MIconSelectionSkeleton = MIconSelectionSkeleton;

MIconSelection.defaultProps = {
  defaultValue: 1
}

/** RichIconSelection */
const MRichIconSelection = Loadable({
  name: 'MRichIconSelection', loader: () => import(
    /* webpackChunkName: 'ui_m_common_less' */
    './RichIconSelection/MRichIconSelection')
}) as RichIconSelectionType;

MRichIconSelection.defaultProps = {
  // defaultValue: 1,
  bindTriggerContainer: true
}

MIconSelection.MRichIconSelection = MRichIconSelection;

MIconSelection.getIconInfo = getIconInfo;


export type { IconSelectionProps as MIconSelectionProps } from './types';

export default MIconSelection;
