import Loadable from '../../react-loadable';
import { QuickMenuType } from './types';
import { mQuickMenuClsPrefix } from '../../constants/index.m';

const MQuickMenu = Loadable({
  name: 'MQuickMenu',
  loader: () => import(
    /* webpackChunkName: "m_quick_menu" */
    './QuickMenu')
}) as QuickMenuType;

MQuickMenu.defaultProps = {
  prefixCls: mQuickMenuClsPrefix,
  menuType: 'trigger',
  borderRadius: 50,
  borderRadiusType: '%'
}

export type { QuickMenuType, QuickMenuProps } from "./types";
export default MQuickMenu;
