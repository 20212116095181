import { mRichTextClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { unMatchSensitiveWords, matchSensitiveWords } from './utils/sensitiveWordsMatch';
import { MRichTextType } from './types';

const MRichText = Loadable({ name: 'MRichText', loader: () => import(
  /* webpackChunkName: "rich-text" */
  './components/MRichText') }) as MRichTextType;

MRichText.defaultProps = {
  prefixCls: mRichTextClsPrefix,
};

MRichText.matchSensitiveWords = matchSensitiveWords;
MRichText.unMatchSensitiveWords = unMatchSensitiveWords;

export type { MRichTextProps, MRichTextType, ExtendItemConfig as MRichTextExtendItemConfig, MRichTextButtonItemData } from './types';

export default MRichText;
