import { action, observable, toJS } from "mobx";
import { MListData } from '../../list/index.m'
import { request, RequestOptionConfig } from '@weapp/utils';

export default class Store<Data = any> {
  // 数据展示
  @observable data: MListData[] = []

  // 接口请求参数
  @observable initRequestConfig: RequestOptionConfig = {}
  @observable requestConfig: RequestOptionConfig = {}

  // List列表配置参数
  @observable loading: boolean = false

  // 分页参数
  @observable pageSize: number = 20
  @observable current: number = 1
  @observable total: number = 0;
  @observable hasMore: boolean = false

  @action
  getData = (requestConfig: RequestOptionConfig, isInit: boolean = false, isAdd: boolean = false): any => {
    return import(
      /* webpackChunkName: "ui_m_common" */
      './Store.m.async'
    ).then((defaultModule) => defaultModule.getData(this, requestConfig, isInit, isAdd));
  }

  @action
  setState = (params: any, callback?: () => void) => {
    Object.keys(params).forEach((key) => {
      if (key in this) {
        // @ts-ignore
        this[key] = params[key];
      }
    });

    callback && callback()
  }

  // 请求参数转换
  getRealConfig = (config: RequestOptionConfig) => {
    const newConfig:RequestOptionConfig = {
      url: config.url,
      method: config.method,
    }

    if (config.method === 'get' || config.method === 'GET') {
      newConfig.params = config.params
    } else if (config.method === 'post' || config.method === 'POST') {
      newConfig.data = config.params
    }

    return newConfig
  }
}

export type MStoreType = Pick<Store, keyof Store>