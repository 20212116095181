import { getLabel } from "@weapp/utils";
import { AnyObj } from "../../../types/common";

export const moduleConfig = (): AnyObj => ({
  "task": getLabel('34217', '任务'),
  "document": getLabel('34218', '文档'),
  "mainline": getLabel('34219', '项目'),
  "customer": getLabel('32726', '客户'),
  "clue": getLabel('28908', '线索'),
  "saleChance": getLabel('32863', '商机'),
  "contract": getLabel('32864', '合同'),
  "production": getLabel('29329', '产品'),
  "competitor": getLabel('21533', '对手'),
  "contact": getLabel('32711', '联系人'),
  "marketactivity": getLabel('34221', '市场活动'),
  "formdatareport": getLabel('65572', '表单数据'),
  "frpt_component": getLabel('50100', '表单'),
  "calendar": getLabel('34220', '日程'),
  "kpiFlow": getLabel('65582', '绩效'),
  "workreport": getLabel('15940', '报告'),
  "workflow": getLabel('33193', '流程'),
  "biaoge": getLabel('50100', '表单'),
  "odoc": getLabel('98902', '公文'),
  "orderform": getLabel('34230', '订单')
})

export const associatedBrowserConfig: AnyObj = {
  "calendar": {
    module: "calendar",
    type: "calendarBrowser",
    name: getLabel('34220', '日程'),
  },
  "production": {
    module: "crm",
    type: "production",
    name: getLabel('29329', '产品'),
  },
  "marketactivity": {
    module: "crm",
    type: "marketactivity",
    name: getLabel('34221', '市场活动'),
  },
  "document": {
    module: "doc",
    type: "document",
    name: getLabel('34218', '文档'),
  },
  "mainline": {
    module: "project",
    type: "mainlineBrowser",
    name: getLabel('34219', '项目'),
  },
  "saleChance": {
    module: "crm",
    type: "saleChance",
    name: getLabel('32863', '商机'),
  },
  "contract": {
    module: "crm",
    type: "contract",
    name: getLabel('32864', '合同'),
  },
  "kpiFlow": {
    module: "performance",
    type: "performanceBrowser",
    name: getLabel('65582', '绩效'),
  },
  "frpt_form": {
    module: "formreport",
    type: "frpt_form",
    name: getLabel('50100', '表单'),
  },
  "task": {
    module: "task",
    type: "taskBrowser",
    name: getLabel('34217', '任务'),
  },
  "competitor": {
    module: "crm",
    type: "competitor",
    name: getLabel('21533', '对手'),
  },
  "clue": {
    module: "crm",
    type: "clue",
    name: getLabel('28908', '线索'),
  },
  "contact": {
    module: "crm",
    type: "contact",
    name: getLabel('32711', '联系人'),
  },
  "workreport": {
    module: "plan",
    type: "planBrowser",
    name: getLabel('15940', '报告'),
  },
  "formdatareport": {
    module: "formdatareport",
    type: "frpt_report",
    name: getLabel('65572', '表单数据'),
  },
  "biaoge": {
    module: "formdatareport",
    type: "frpt_form",
    name: getLabel('50100', '表单'),
  },
  "customer": {
    module: "crm",
    type: "customer",
    name: getLabel('32726', '客户'),
  },
  "workflow": {
    module: "workflow/core",
    type: "wfcRequest",
    name: getLabel('33193', '流程'),
  },
  "odoc": {
    module: "odoc",
    type: "odocRequest",
    name: getLabel('98902', '公文'),
  },
  "orderform": {
    module: "crm",
    type: "orderform",
    name: getLabel('34230', '订单'),
  }
}