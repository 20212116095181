import ReactDOM from 'react-dom';
import MAudio from './index.m';
import { MAudioProps } from './types';

export default function showVideo(src?: string, otherParams?: MAudioProps) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function handleClose() {
    ReactDOM.unmountComponentAtNode(div);
    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }
    otherParams?.onClose?.()
  };
  const render = () => {
    ReactDOM.render(<MAudio weId={`liveox`}
      controls
      src={src}
      {...otherParams}
      dialogProps={{
        visible: true,
        destroyOnClose: true,
        ...otherParams?.dialogProps,
        // path: path
      }}
      isDialog
      onClose={handleClose}
    />, div);
  };
  render();
}
