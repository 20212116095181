import { isNil } from '../../../../utils'

export const getWeekStart = (propsWeekStart?: number, stateWeekStart?: number) => {
  let weekStart = 1
  if (!isNil(stateWeekStart)) {
    weekStart = stateWeekStart
  }
  if (!isNil(propsWeekStart)) {
    weekStart = propsWeekStart
  }
  return weekStart
}