import { uploadClsPrefix } from '../../constants';
import { mUploadClsPrefix } from '../../constants/index.m';
import { UploadProps, MUploadProps } from './types';
import { PREVIEWURL } from './constants';
import { AnyObj } from '../../lib';

export const sameFormatTypes = [
  // 文档
  ["doc", "docx", "dot", "dotx", "docm", "dotm"],
  // 表格
  ["xls", "xlt", "xlsx", "xltx", "xlsm", "xltm"],
  // 图片
  ["jpg", "jpeg", "JPEG", "png", "PNG", "gif", "giff", "tiff", "tif", "TIF", "TIFF", "bmp", "BMP", "webp"],
  // 视频
  ["mp4", "m4v", "mov", "avi", "flv", "mkv"],
  // 音频
  ["mp3", "WAV", "WMA", "Flac", "MIDI", "RA", "APE", "AAC", "CDA", "MOV"],
  // ppt
  ["ppt", "pptx", "pptm", "ppsx", "ppsm", "potx", "potm"],
  // 压缩文件
  ["zip", "rar", "7z", "tar"],
]

export const bathDefaultProps: Partial<MUploadProps> = {
  uploadTitle: '',
  buttonType: 'default',
  listType: 'list',
  autoUpload: true,
  showUpload: true,
  multiple: true,
  showListUploaded: true,
  showListUploading: true,

  showDelete: true,
  showEdit: true,
  showRename: true,
  showReUpload: true,
  showPreview: true,
  showDownload: true,
  showSignButtons: true,
  showAppeal: true,
  showEditSecLevel: true,

  showUploader: true,
  showUploadTime: true,
  showFileSize: true,

  isDeleteConfirm: true,
  isDeleteInterface: false,

  multipartSize: 8,

  // 密级
  // 是否开启密级确认框（默认： true）
  secretConfim: true,
  // 是否启用密级（默认： true）
  secretProtect: false,
  // 密级填写辅助信息（默认："保密期限填写格式：3个月、10年、20年、长期…不能是纯数字"）
  secretLevelHelpTip: '',
  // 密级显示格式（1:密级+期限；2:仅密级）
  secLevelDisplayFormat: 1,
  // 缺少密级提示（默认： "请填写密级！")
  secretLevelInfo: '',
  secretLevelValidityInfo: '',
  checkSensitiveWords: true,  // 敏感词检测

  /** 设置附件类型组 */
  sameFormatTypes,

  previewURL: PREVIEWURL,
  displayTip: false, // 展示提示语内容

  /** 缩略图展示类型 */
  thumbnailFormat: 'small',
}

/** PC 端上传按钮默认的 props */
export const defaultProps: UploadProps = {
  ...bathDefaultProps,
  prefixCls: uploadClsPrefix,

  showVersion: true,

  dragUpload: true,
  isListTShowImage: false,
  imgOptionType: 'bottom',
  disabledType: 'hidden',
  type: 'default',

  listOptionType: "optionHover",
  batchDownload: false,
  batchDelete: false,
  imgWidth: 100,
  imgHeight: 100,
  isClearErrorFile: true,  // 上传失败后，是否清理失败的文件
  md5ChunkSize: 50,
  isCompressImg: false,
}

/** 移动端上传按钮默认的 props */
export const mDefaultProps: MUploadProps = {
  ...bathDefaultProps,

  prefixCls: mUploadClsPrefix,

  showVersion: true,

  showFullName: true,  // 是否展示全部名称

  labelSpan: 12,
  // sdk 相关
  sourceType: ["camera", "video", "album", "file"],
  ablumType: "all",
  isAddWaterMark: false,
  isSaveAlbum: true,
  isEditAlbumPhoto: true,
  compressType: 1,

  imgWidth: 70,
  imgHeight: 70,


  acceptUa: {
    os: 'iOS',
    version: '15.0'
  },
}

