import { request } from "@weapp/utils";
import { browserTypeClsPrefix } from '../../constants/index';
import Loadable from "../../react-loadable";
import { IBrowserType, IBrowserTypePanel } from "./types";

const BrowserType = Loadable({
  name: 'BrowserType',
  loader: () => import(/* webpackChunkName: "browser_type" */ './BrowserType'),
}) as IBrowserType;

const BrowserTypePanel = Loadable({
  name: 'BrowserTypePanel',
  loader: () => import(/* webpackChunkName: "browser_type" */ './BrowserTypePanel'),
}) as IBrowserTypePanel;

BrowserType.BrowserTypePanel = BrowserTypePanel;


BrowserTypePanel.defaultProps = {
  prefixCls: browserTypeClsPrefix,
  dataURL: '/api/bcw/relevanceBrowser/getBrowserBean',
  completeURL: '/api/bcw/relevanceBrowser/browserBeanSearch',
  fetchData: request,
  showSearch: true,
}

BrowserType.defaultProps = {
  prefixCls: browserTypeClsPrefix,
  dataURL: '/api/bcw/relevanceBrowser/getBrowserBean',
  completeURL: '/api/bcw/relevanceBrowser/browserBeanSearch',
  fetchData: request,
  showSearch: true,
}

export type { BrowserTypeProps } from './types';

export { BrowserTypePanel } 
export default BrowserType;
