import { triggerClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { MTriggerProp } from './types';

import _MTrigger from './Trigger';

const MTrigger = Loadable({
  name: 'MTrigger',
  component: _MTrigger,
  // loader: () => import(
  //  /* webpackChunkName: "m_trigger" */
  // './Trigger'),
}) as MTriggerProp;

MTrigger.defaultProps = {
  prefixCls: triggerClsPrefix,
}

export type { MTriggerProps, PopupPlacement } from './types';

export default MTrigger;
