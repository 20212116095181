/*   getAround 获取周边位置信息 */
import { anyObj } from '../types';

// 再封装一层获取默认地图配置的功能
const getAround =  (mapType?: any, location?: string, radius?: number, query?: string, otherParams?: anyObj) => {
  return import(
  	/* webpackChunkName: "map_common" */
    './getAroundAsync'
  ).then((defaultModule) => defaultModule.default?.(mapType, location, radius, query, otherParams));
}

export default getAround;