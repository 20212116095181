import ReactDOM from "react-dom";
import { popoverClsPrefixCls } from '../../constants/index';
import { getRandom } from '../../utils/index';
import { PopoverProp, PopoverRenderProps, PopoverType } from "./types";
import { Popover } from './index'

let configs = new Map();
let node: HTMLElement | null;

const destroy = (div: Element | HTMLElement | null) => {
  if (div) {
    const unmount = ReactDOM.unmountComponentAtNode(div);
    if (unmount && div.parentNode) {
      div.parentNode?.removeChild(div);
    }
  }
  document.removeEventListener('DOMNodeRemoved', () => { });
}
class PopoverRender {
  div = document.createElement('div');
  instance: any = null;
  setPopoverRef = (el: PopoverType) => {
    this.instance = el;
    if (node) {
      configs.set(node, {
        ...configs.get(node),
        instance: el,
      })
    }
  }
  reset = (config: PopoverRenderProps) => {
    this.instance.reload({ ...config });
  }
  init = (config: PopoverRenderProps) => {
    if (!config.element) return;
    const div = configs.get(config.element).div;
    if (div) {
      document.body.appendChild(div);
      document.addEventListener('DOMNodeRemoved', (e) => {
        if (e.target === config.element) {
          destroy(div);
        }
      });
      ReactDOM.render(<Popover weId={`${config.weId || ''}_v0dwr7`} {...config} id={configs.get(config.element).id} ref={this.setPopoverRef} />, div);
    }
  }
}

export function create(configProps: PopoverRenderProps) {
  const config: PopoverRenderProps = {
    popoverType: 'tooltip',
    prefixCls: popoverClsPrefixCls,
    placement: 'top',
    triggerProps: {
      target: configProps.element,
    },
    ...configProps,
  }
  if (!config.element) return;
  node = config.element;
  if (configs.has(config.element)) {
    configs.get(config.element).popoverRender.reset(config);
    return;
  }
  if (configs.size === 0) {
    const observer = new MutationObserver((lists) => {
      lists.forEach((list) => {
        if (list.removedNodes && list.removedNodes.length > 0) {
          list.removedNodes.forEach((dom: any) => {
            let subDoms = [];
            if (dom.nodeType === 1) {
              subDoms = dom.querySelectorAll('*');
            } else {
              subDoms = [dom];
            }
            if (subDoms && subDoms.length > 0) {
              subDoms.forEach((sub: any) => {
                if (configs.has(sub)) {
                  destroy(document.getElementById(configs.get(sub).id));
                  destroy(configs.get(sub).div);
                  configs.delete(sub);
                }
              })
            }
            if (configs.size === 0) observer.disconnect();
          })
        }
      });
    });
    observer.observe(window.document.body, { childList: true, subtree: true });
  }
  const div = document.createElement('div');
  const popoverRender = new PopoverRender();
  configs.set(config.element, {
    popoverRender,
    config,
    div,
    id: `popover_create_${getRandom()}`,
  });
  popoverRender.init(config);
}
