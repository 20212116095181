import React, { Component, PureComponent } from 'react';
import { BaseProps } from '../../types/common';

class BreadCrumbItem extends PureComponent<BaseProps> {
  static displayName = 'BreadCrumbItem';
  render() {
    const { prefixCls, style, children } = this.props;
    return (
      <span style={style} className={`${prefixCls}-item`}>
        {children}
      </span>
    );
  }
}



export default BreadCrumbItem;
