import Loadable from '../../react-loadable';
import { SlideTabsType } from './types';

const SlideTabs = Loadable({
  name: 'SlideTabs', loader: () => import(
    /* webpackChunkName: 'ui_common' */
    './SlideTabs')
}) as SlideTabsType;

export type { SlideTabsProps, SlideTabsItemData } from './types';

export default SlideTabs;
