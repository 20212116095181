import { FormItemProps } from '../../../lib';
import { FormCascadeRules, FormDatas } from "../types";

export const getCascadeItems = (initDatas: FormDatas, rule: Array<string>, cascadeRules?: FormCascadeRules, labelId?: string) => {
  let tempRule: Array<string> = [];
  if (!cascadeRules || !initDatas) return tempRule;
  rule.forEach((ru) => {
    tempRule.push(ru);
    if (cascadeRules[ru]) {
      const datakey = labelId ? `${ru}_${labelId}` : ru;
      const initValue = initDatas[datakey];
      if (typeof initValue === 'string' && initValue && cascadeRules[ru][initValue]) {
        tempRule = tempRule.concat(cascadeRules[ru][initValue]?.slice());
      }
    }
  });
  return tempRule;
}

export const getSensitiveWordScanContent = (datas: FormDatas, items: FormItemProps) => {
  const content: string[] = [];
  Object.keys(datas).forEach((dataKey) => {
    const value = datas[dataKey];
    if (value && typeof value === 'string' && ['INPUT', 'TEXTAREA', 'RICHTEXT'].indexOf(items[dataKey]?.itemType) >= 0) {
      content.push(value);
    }
  })
  return content.join(',');
}