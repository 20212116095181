import { action, observable } from "mobx";
import { RequestOptionConfig } from '@weapp/utils';
import { ListViewStore, PaginationProps, ITablePaginationType, AnyObj } from '../../lib';

export type tablePageInfoType = PaginationProps & { paginationType?: ITablePaginationType }

export default class BatchOperatorStore {

  @observable listViewStore = new ListViewStore()

  @observable tableRequestParams?: AnyObj = {};
  @observable requestConfig: RequestOptionConfig = {};
  @observable asyncCounts?: RequestOptionConfig;
  @observable asyncOperates?: RequestOptionConfig;

  @observable requestHeaderParams?: AnyObj = {};

  @observable submitDisabled?: boolean | undefined = undefined;

  @observable pagationInfo: tablePageInfoType = {
    paginationType: 'part',
    value: 1,
    pageSize: 10,
    total: 0,
    pageSizeOptions: [5, 10]
  };

  /** props pagationInfo 缓存记录 */
  @observable _pagationInfo: PaginationProps = {};

  @observable transformedParams: any = {};

  // 全选标识
  @observable isBatchSelect: boolean = false;  // 是否开启批量全选
  @observable isSelectAll: boolean = false;  // 当前批量全选状态（该属性仅开启全选功能后生效）
  @observable firstPageSelected: boolean = false;  // 请求时，第一页数据是否全部选中
  @observable selectedRowKeys: string[] = [];  // 选中的 rowKeys
  @observable selectedRows: any[] = [];  // 选中的 rowDatas
  @observable currentRow: any[] = [];  // 当前选中的 rowDatas
  @observable deSelectedRowKeys: string[] = [];  // 取消选中的 rowKeys（全选模式使用）

  /** 通过触发 props 上的 onSelect 事件（配合 firstPageSelected 使用） */
  @observable needTriggerSelectEvent: boolean = false;
  /** 
   * 当前是否需要选中第一页的数据
   * 1. 没吃打开弹框都选中
   * 2. 批量提交了第一页数据后需要选中
   */
  @observable needFirstPageSelected: boolean = true;
  /** 是否是打开弹框后的第一次请求 */
  @observable isFirstDataAfterOpen: boolean = true;
  /** 第一页数据的 keys */
  @observable firstPageRowKeysAfterOpen: string[] = [];

  // @observable selectedRows: any[] = [];

  @observable visible: boolean = false;  // 弹框显示状态
  @observable scale: boolean = false;  // 弹框缩放状态
  @observable tableScrollHeight: number = 0;  // table 高度
  @observable isPersonalSetting: boolean = false;
  @observable showMessageTip: boolean = false;

  /** 是否在搜索后清空已选数据 */
  @observable isClearCheckedAfterSearch: boolean = true;

  @action
  setState = (params: any, callback?: () => void) => {
    Object.keys(params).forEach((key) => {
      if (key in this) {
        // @ts-ignore
        this[key] = params[key];
        // 同步 listView store 数据
        if (key === 'selectedRowKeys') {
          this.listViewStore.setState({ checkedKeys: params[key] })
        }
      }
    });

    callback && callback()
  }

  @action
  setReqConfig = (
    requestConfig: RequestOptionConfig,
    asyncCounts?: RequestOptionConfig,
    asyncOperates?: RequestOptionConfig,
  ) => {
    this.requestConfig = requestConfig;
    this.asyncCounts = asyncCounts;
    this.asyncOperates = asyncOperates;
    this.getViewList(undefined, undefined);
  }

  @action
  onClearSelected = (callBack?: () => void) => {
    const { setState } = this.listViewStore;
    let newState: AnyObj = {
      selectedRowKeys: [], selectedRows: [], currentRow: []
    }
    if (this.isSelectAll) { // 全选下，切换全选状态
      newState.isSelectAll = false;
    }
    setState({ checkedKeys: [] });
    this.setState(newState, callBack);
  }

  @action
  getViewList = (params?: AnyObj, callback?: () => void) => {
    return import(
      /* webpackChunkName: "batch-operator" */
      './storeAsync'
    ).then((defaultModule) => defaultModule.getViewList(this, params, callback))
  }

  @action
  setPagationInfo = (pagationInfo?: tablePageInfoType, callback?: () => void) => {
    let _pagationInfo = {
      ...this.pagationInfo,
      ...pagationInfo
    }
    this.setState({
      pagationInfo: _pagationInfo
    }, () => {
      callback && callback()
    })
  }
}

export type BatchOperatorStoreType = Pick<BatchOperatorStore, keyof BatchOperatorStore>;
