import Loadable from '../../react-loadable';
import { TransferType, } from './type'

const Transfer = Loadable({
  name: 'Transfer', loader: () => import(
    /* webpackChunkName: "ui_common_small" */
    './Transfer')
}) as TransferType;

export type { TransferProps, transferData } from './type';


export default Transfer;
