import Loadable from '../../react-loadable'
import { MImageCropperType } from './types'
import { mImageCropperClsPrefix } from '../../constants/index.m'

const MImageCropper = Loadable({
  name: 'MImageCropper',
  loader: () =>
    import(
      /* webpackChunkName: "m_image_cropper" */
      './MImageCropper'
    ),
}) as MImageCropperType

MImageCropper.defaultProps = {
  prefixCls: mImageCropperClsPrefix,
  showSettingBtn: false,
  showDelete: true,
  accept: 'image/*',
  aspectRatio: 1,
  defaultShowCrop: true,
  hasLoading: true,
  type: 'avatar',
  uploadFileMaxSize: 2,
}

export type { MImageCropperProps, MImageCropperType } from './types'

export default MImageCropper
