import { shareClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { ShareListType, ShareType } from './types';
import { shareListMiddleware } from './utils/middleware';
import * as shareTools from './utils/tools';

const Share = Loadable({
  name: 'Share', propsMiddleware: shareListMiddleware, loader: () => import(
    /* webpackChunkName: 'share' */
    './Share')
}) as ShareType;

Share.defaultProps = {
  prefixCls: shareClsPrefix,
  cacheAuthCondition: true,
};

const ShareList = Loadable({
  name: 'ShareList', propsMiddleware: shareListMiddleware, loader: () => import(
    /* webpackChunkName: 'share' */
    './ShareList'
  )
}) as ShareListType;

Share.ShareList = ShareList;
Share.shareTools = shareTools;

export { default as ShareListStore } from './store/Store';

export type { ShareProps, ShareType, ShareListProps, ShareListType, ShareBtnOptionsType, OperatesType as ShareOperatesType } from './types';

export default Share;
