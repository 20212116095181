import Loadable from '../../react-loadable';
// import { ScrollerType, IScrollerProps }  from './types';
import Scroller from './Scroller'

// const Scroller = Loadable({ name: 'Scroller', loader: () => import(
//     /* webpackChunkName: "scroller" */
//     './Scroller' ) }) as ScrollerType;
//

export type { ScrollerType, IScrollerProps } from './types';

export default Scroller;
