import { middleware } from "@weapp/utils";
import { Component } from "react";
import { Route, withRouter, Link } from "react-router-dom";
import { uiAppName } from '../../constants/index';
import { formatParentPath } from '../../utils/index';
import CorsComponent from "./CorsComponent";
import { CorsLinkProps, CorsDialogProps } from './types';

@middleware(uiAppName, 'CorsLink')
// @ts-ignore
@withRouter
export default class CorsLink extends Component<CorsLinkProps> {
  static displayName = "CorsLink";

  changeRoute = (visible: boolean) => {
    const { to, history, onChangeRoute } = this.props;
    if(typeof onChangeRoute === 'function') {
      onChangeRoute(visible, to);
    } else {
      if (visible) {
        history?.push(to);
      } else {
        if (history?.length && history.length > 2) {
          history?.go(-1);
        } else {
          const parentPath = formatParentPath(this.props as any);
          history?.push(parentPath);
        }
      }
    }
  }

  handleClose = () => {
    this.changeRoute(false);
  }

  handleRouteChange = () => {
    const { option: { onClose } } = this.props;
    onClose?.();
  }

  renderCorsComponent = () => {
    const { option } = this.props;
    return (
      <CorsComponent<CorsDialogProps>
        weId={`${this.props.weId || ''}_l3c6lt`}
        {...option}
        onClose={this.handleClose}
        unmount={this.handleRouteChange}
        visible={true}
      />
    );
  }

  render() {
    const { to, path, children } = this.props;
    return (
      <>
        <Link weId={`${this.props.weId || ''}_ju75xc`} to={to}>{children}</Link>
        <Route weId={`${this.props.weId || ''}_by7ohg`}
          key={to}
          path={path || to}
          render={this.renderCorsComponent}
        />
      </>
    );
  }
}
