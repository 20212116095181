import Loadable from '../../react-loadable';
import { FilterButtonsType, FilterButtonsProps } from './types';
import { mFilterButtonsClsPrefix } from '../../constants/index.m';
import { ComponentType } from "react"

const MFilterButtons = Loadable({
  name: 'MFilterButtons', loader: () => import(
    /* webpackChunkName: "ui_m_common_less" */
    './FilterButtons')
}) as FilterButtonsType;

MFilterButtons.defaultProps = {
  prefixCls: mFilterButtonsClsPrefix,
  numberPerLine: 4
};
export type MFilterButtonsType = ComponentType<FilterButtonsProps>;
export default MFilterButtons;
export type { DataItemType as MFilterButtonsDataItemType } from './types';