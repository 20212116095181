
import Loadable from '../../react-loadable';
import { SpanWrapType } from './types';

const SpanWrap = Loadable({ name: 'SpanWrap', loader: () => import(
  /* webpackChunkName: 'ui_common_less' */
  './SpanWrap') }) as SpanWrapType;

SpanWrap.defaultProps = {
    text: ''
}

export type { SpanWrapProps } from './types';

export default SpanWrap;
