import { listClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { ListType, ListProps } from './types';

const List = Loadable({
  name: 'List',
  loader: () => import(
    /* webpackChunkName: "ui_frequent" */
    './List')
}) as ListType;

const IndexedList = Loadable({
  name: 'IndexedList',
  loader: () => import(
    /* webpackChunkName: "ui_frequent" */
    './IndexedList')
}) as ListType;

const defaultProps: ListProps = {
  prefixCls: listClsPrefix,
  direction: 'column',
  sortable: false,
  sortableOptions: {},
  onEndReachedThreshold: 10,
  scrollRenderAheadDistance: 500,
  horizontalScroll: false,
  initialOrder: 0,
  dataKey: 'id',
  groupKey: 'id',
  dataType:'type',
  groupContent: 'content',
  selectedRowKeys:[],
  // loading:false,
  hasMore:true
}
List.IndexedList = IndexedList;
List.IndexedList.defaultProps = defaultProps;

List.defaultProps = defaultProps;

export type { ListProps, ListItemProps, ListData, GroupData, GroupOptionData, PaginationType as ListPaginationType } from './types';

export default List;
