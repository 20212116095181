import Loadable from '../../react-loadable';
import { PullToRefreshType } from './types';

const PullToRefresh = Loadable({
  name: 'PullToRefresh',
  loader: () => import(
    /* webpackChunkName: "ui_common_less" */
    './PullToRefresh')
}) as PullToRefreshType;

export type { PullToRefreshProps } from './types';

export default PullToRefresh;
