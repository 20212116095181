import { needRTL } from "../../../utils";
import { Placement } from "../types";

export function transPercentWidth(width: string) {
  const clientWidth = window.document.body.clientWidth;
  return clientWidth * parseInt(width) * 0.01;
}

export function transPercentHeight(height: string) {
  const clientHeight = window.document.body.clientHeight;
  return clientHeight * parseInt(height) * 0.01;
}

export function transWidth(width?: string | number, _placement?: Placement) {
  const clientWidth = window.document.body.clientWidth;
  let placement = _placement;
  if (needRTL?.()) {
    if (placement === 'right') placement = 'left';
    else if (placement === 'left') placement = 'right';
  }
  if (typeof width === 'number') {
    return placement === 'left' ? width : clientWidth - width;
  }
  return width && typeof width === 'string' ?
    width.indexOf('px') >= 0 ?
      placement === 'left' ? parseInt(width.slice(0, width.indexOf('px'))) :
        clientWidth - parseInt(width.slice(0, width.indexOf('px'))) :
      placement === 'left' ? transPercentWidth(width) :
        clientWidth - transPercentWidth(width)
    : width;
}

export function transHeight(height?: string | number, placement?: Placement) {
  const clientHeight = window.document.body.clientHeight;
  if (typeof height === 'number') {
    return placement === 'top' ? height : clientHeight - height;
  }
  return height && typeof height === 'string' ?
    height.indexOf('px') >= 0 ?
      placement === 'top' ? parseInt(height.slice(0, height.indexOf('px'))) :
        clientHeight - parseInt(height.slice(0, height.indexOf('px'))) :
      placement === 'top' ? transPercentHeight(height) :
        clientHeight - transPercentHeight(height)
    : height;
}

export function calcSize(wrap: string, sub: number, max?: string | number, min?: string | number, type?: string) {
  const wrapTemp = wrap.indexOf('%') >= 0 ?
    type === 'height' ? transPercentHeight(wrap.slice(0, wrap.indexOf('%'))) :
      transPercentWidth(wrap.slice(0, wrap.indexOf('%'))) :
    parseInt(wrap.slice(0, wrap.indexOf('px')));
  if (max) {
    const maxTemp = typeof max === 'number' ? max :
      type === 'height' ? transPercentHeight(max.slice(0, max.indexOf('%'))) :
        transPercentWidth(max.slice(0, max.indexOf('%')));
    if (wrapTemp - sub > maxTemp) return `${maxTemp}px`;
  }
  if (min) {
    const minTemp = typeof min === 'number' ? min :
      type === 'height' ? transPercentHeight(min.slice(0, min.indexOf('%'))) :
        transPercentWidth(min.slice(0, min.indexOf('%')));
    if (wrapTemp - sub < minTemp) return `${minTemp}px`;
  }
  return `calc(${wrap} - ${sub}px)`
}

export function calcHeight(wrapHeight: string, subHeight: number, maxHeight?: string | number, minHeight?: string | number) {
  return calcSize(wrapHeight, subHeight, maxHeight, minHeight, 'height');
}

// 禁用 root div user-select
export const disabledUserSelect = () => {
  const root = document.getElementById('root');
  root && (root.style.userSelect = 'none');
}

// 启用 root div user-select
export const enableUserSelect = () => {
  const root = document.getElementById('root');
  root && (root.style.userSelect = 'auto');
}

export const getDefaultDraggable = (propDraggable?: boolean, placement?: Placement) => {
  let draggable = propDraggable;
  if (draggable !== false && (!placement || placement === 'middle')) draggable = true; // 从中间弹出的弹框默认开启draggable
  return draggable;
}