import { mCarouselClsPrefix } from "../../constants/index.m";
import Loadable from "../../react-loadable";
import { MCarouselType } from "./types";

const MCarousel = Loadable({ name: "MCarousel", loader: () => import(
  /* webpackChunkName: "m_carousel" */
  "./Carousel"
) }) as MCarouselType;

MCarousel.defaultProps = {
  prefixCls: mCarouselClsPrefix,
  fillWays: 'backgroundFill'
};

export type { MCarouselProps } from "./types";

export default MCarousel;