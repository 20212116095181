import { watermarkClsPrefix } from '../../constants/index';
import { ResizeObserver } from '@weapp/utils';
import { getHash } from '../../utils/index';
import { WatermarkOptions } from './types';
import { defaultOptions, HighZIndex, DefaultWMWidth, DefaultWMHeight } from './constants';
import { getSecondPathUrl } from "@weapp/utils"

class Watermark {

  type: string = 'text';
  len: number = 0;
  loaded = false;
  prefixCls: string;
  uuid: string;
  options: WatermarkOptions = defaultOptions;
  container: Element = document.documentElement;

  resizeObserver: ResizeObserver | null = null;
  resizeTimer: NodeJS.Timeout | null = null;
  unobserver: (() => void) | null = null;

  constructor(options: WatermarkOptions) {
    this.uuid = getHash();
    this.prefixCls = `${options?.prefixCls || watermarkClsPrefix}-${this.uuid}`;
    this.updateOptions(options);
  }
  
  /**
   * 检测 新的options 是否有修改
   * @param options 新的options
   * @returns 是否有更新
   */
  checkOptions = (options: WatermarkOptions = {}) => {
    let flag = false;
    for (let key in options) {
      if (options.hasOwnProperty(key)) {
        const k = key as keyof WatermarkOptions;
        if (options[k] !== this.options[k]) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  };

  /**
   * 根据 id 删除 dom 元素
   * @param selectorId 
   */
  removeEle = (selectorId: string) => {
    const ele = document.getElementById(selectorId);
    // if (ele && ele.remove) {
    //   ele.remove();
    //   // document.body.remove(ele);
    // }
    if (ele && this.container) {
      this.container.removeChild(ele);
    }
  };

  /**
   * 处理容器大小重载
   */
  handleResize = () => {
    if (this.resizeTimer) clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      if (this.resizeTimer) clearTimeout(this.resizeTimer);
      this.update();
    }, 300);
  }

  /**
   * 更新水印配置
   * @param options 新的水印配置
   * @returns 
   */
   updateOptions = (options: WatermarkOptions = {}) => {
    if (!this.checkOptions(options)) return;
    if (options?.container) {
      this.container = options.container;
    }
    this.options = { ...this.options, ...options };
    try {
      if (this.container.tagName !== 'HTML') {
        // 处理局部水印容器大小变化
        if (this.unobserver) {
          // 清除上次监听
          this.unobserver();
          this.unobserver = null;
        }
        this.resizeObserver = this.resizeObserver || (new ResizeObserver(() => {
          this.handleResize();
        }));
        this.resizeObserver?.observe(this.container);
        this.unobserver = () => {
          this.resizeObserver?.unobserve(this.container);
        };
        (this.container as HTMLElement);
      } else {
        // 处理全局水印容器大小变化
        window.removeEventListener('resize', this.handleResize);
        window.addEventListener('resize', this.handleResize);
      }
      this.update();
    } catch (e) {
      console?.error(e);
    }
  };

  /**
   * 水印销毁方法
   */
     destory = () => {
      const wrapId = `${this.prefixCls}-wrap`;
      this.removeEle(wrapId);
      this.options = defaultOptions;
      window.removeEventListener('resize', this.handleResize);
      if (this.unobserver) {
        this.unobserver();
        this.unobserver = null;
      }
    };

  /**
   * 判断重新生成dom条件
   * @param marks 水印元素
   * @returns 是否已经更新
   */
  isUpdate = (marks: NodeListOf<HTMLElement>) => {
    const { alpha, rotate } = this.options;
    if (!marks || marks.length === 0) {
      // 初始化
      return true;
    } else if (marks.length !== this.len) {
      // 修改宽高的值
      return true;
    } else if (marks[0].style.transform !== `rotate(${rotate}deg)`) {
      // 修改旋转角度
      return true;
    } else if (marks[0].style.opacity !== String(alpha)) {
      // 修改透明度
      return true;
    }
    return false;
  };

  /**
   * 更新水印
   * @param type 
   */
  update = (type?: string) => {
    const { src: originSrc , alpha, rotate, text, fontStyle, zIndex } = this.options;
    let src = originSrc; 
    if (getSecondPathUrl(originSrc)) {
      src = getSecondPathUrl(originSrc)
    }
    let {
      width = DefaultWMWidth, height = DefaultWMHeight,
      containerWidth, containerHeight,
    } = this.options;
    let w = DefaultWMWidth;
    let h = DefaultWMHeight;
    try {
      // 根据容器大小计算水印需要渲染的个数
      w = containerWidth || this.container.clientWidth;
      h = containerHeight || this.container.clientHeight;
    } catch (e) {
    }

    width = Math.max(100, width > 0 ? width > w ? w : width : DefaultWMWidth);
    height = Math.max(100, height > 0 ? height > h ? h : height : DefaultWMHeight);

    // 处理最外层容器
    const wrapId = `${this.prefixCls}-wrap`;
    let wrap = document.getElementById(wrapId);
    if (wrap) {
      // 每次更新时需要将原先的水印节点去掉，重新生成水印
      this.removeEle(wrapId);
    }
    wrap = document.createElement('div');
    wrap.id = wrapId;
    try {
      if (this.container.tagName !== 'HTML') {
        // 处理局部水印容器定位
        (this.container as HTMLElement).style.position = 'relative';
      }
    } catch (e) {
    }
    this.container.appendChild(wrap);
    // document.body.appendChild(wrap);

    // 处理水印布局容器
    const containerId = this.prefixCls;
    let bg = document.getElementById(containerId);
    if (!bg) {
      bg = document.createElement('div');
      bg.id = containerId;
      bg.style.display = 'block';
      bg.style.pointerEvents = 'none';
      bg.style.position = 'absolute';
      bg.style.width = '100%';
      bg.style.height = '100%';
      bg.style.overflow = 'hidden';
      bg.style.zIndex = `${zIndex && zIndex !== 0 ? zIndex : HighZIndex}`;
      bg.style.top = '0px';
      bg.style.left = '0px';
      bg.style.textAlign = 'center';
      wrap.appendChild(bg);
    }

    // 检测图片是否有效
    if (src && type !== 'text') {
      this.type = 'img';
      const imgTestId = `${this.prefixCls}-img-test`;
      let img = document.getElementById(imgTestId) as HTMLImageElement | null;
      if (!img) {
        img = document.createElement('img');
        img.id = imgTestId;
        img.style.display = 'none';
        img.onload = () => {
          this.removeEle(imgTestId);
        }
        img.onerror = () => {
          // this.type = 'text';
          // this.update(this.type);
          this.removeEle(imgTestId);
          this.destory();
        };
        // document.body.appendChild(img);
        this.container.appendChild(img);
      }      
      img.src = src;  
    } else {
      this.type = 'text';
    }

    // 计算需要加载的水印个数
    const col = Math.ceil(w / width);
    const row = Math.ceil(h / height);

    this.len = row * col;

    // 渲染水印
    const markLineCls = `${this.prefixCls}-mark-line`;
    const markCls = `${this.prefixCls}-mark`;
    let marks = document.querySelectorAll(`#${wrapId} .${markCls}`) as NodeListOf<HTMLElement>;
    if (this.isUpdate(marks)) {
      bg.innerHTML = '';
      for (let j = 0; j < row; j ++) {
        let markLine = document.createElement('div');
        markLine.className = markLineCls;
        markLine.style.overflow = 'hidden';
        for (let i = 0; i < col; i++) {
          let mark = document.createElement('div');
          mark.className = markCls;
          mark.style.width = `${width}px`;
          mark.style.height = `${height}px`;
          mark.style.pointerEvents = 'none';
          mark.style.zIndex = '-1';
          mark.style.float = 'left';
          mark.style.overflow = 'visible';
          mark.style.opacity = String(alpha);
          mark.style.transform = `rotate(${rotate}deg)`;
          // mark.style['-ms-transform'] = `rotate(${rotate}deg)`;
          mark.style.position = 'relative';
          markLine.appendChild(mark);
        }
        bg.appendChild(markLine);
      }
      marks = document.querySelectorAll(`#${wrapId} .${markCls}`);
    }

    Array.prototype.forEach.call(marks, (m, i) => {
      if ((i + 1) % col === 0) {
        m.style.marginRight = `-${width}px`;
      } else {
        m.style.marginRight = '0';
      }
      if (this.type === 'text') {
        m.innerHTML = `<div style='width:100%;position:absolute;top:50%;transform:translateY(-50%);'><p style='pointer-events:none;${fontStyle}'>${text}</p></div>`;
      } else {
        m.innerHTML = `<img alt='' src='${src}' style='position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);pointer-events:none;max-width:100%;max-height:100%;'></img>`;
      }
    });
  };
}

export default Watermark;
