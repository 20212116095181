import Loadable from '../../react-loadable';
import { SoundRecordingType } from './types';
import { mSoundRecordingClsPrefix } from '../../constants/index.m';


const MSoundRecording = Loadable({
  name: 'SoundRecording',
  loader: () => import(
    /* webpackChunkName: "recording" */
    './SoundRecording'
  )
}) as SoundRecordingType;

MSoundRecording.defaultProps = {
  prefixCls: mSoundRecordingClsPrefix,
  showNotWeapp: true,
  resultType: 0,
};

export type { SoundRecordingProps as MSoundRecordingProps, SoundRecordingType as MSoundRecordingType } from './types';

export default MSoundRecording;
