import Loadable from '../../react-loadable';
import { AnimateHeightType, AnimateWeightType }  from './types';

const AnimateHeight = Loadable({ name: 'AnimateHeight', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './AnimateHeight') }) as AnimateHeightType;

const AnimateWidth = Loadable({ name: 'AnimateWidth', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './AnimateWidth' ) }) as AnimateWeightType;

export type { AnimateHeightType, IAnimateHeightProps, IAnimateHeightBaseProps, AnimateWeightType, IAnimateWeightProps, IAnimateHeightOverflow } from './types';

export { AnimateWidth };

export default AnimateHeight;
