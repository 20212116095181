import { anyObj } from '../types';

let defaultMapConfig: anyObj = {}; // 储存地图的默认配置 (接口只走一次)

// 获取储存的地图默认配置数据 （组件内部使用）
export const getStoreMapConfig = () => defaultMapConfig;
export const setStoreMapConfig = (_defaultMapConfig: anyObj) => defaultMapConfig = _defaultMapConfig;

const getDefaultMapConfigAsync = () => import(
	/* webpackChunkName: "map_common" */
	'./getDefaultMapConfigAsync'
);

// 获取地图默认配置
export const getDefaultMapConfig = (loginFree?: boolean) => { // loginFree: 是否为免登
	return getDefaultMapConfigAsync().then((defaultModule) => defaultModule.getDefaultMapConfig(loginFree));
}

export default getDefaultMapConfig;