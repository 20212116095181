import { RootLayoutMenuData } from "./types";

export function findMenus<M extends RootLayoutMenuData = RootLayoutMenuData>(menus: M[], finder: ((menuItem: M) => boolean), findAll?: boolean): M[] {
  let resultTab: M[] = [];
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i];
    if (finder?.(menu)) {
      resultTab.push(menu);
    }
    if (!findAll && resultTab.length) return resultTab;
    if (menu?.children?.length) {
      resultTab.concat(findMenus(menu.children as M[], finder, findAll));
      if (!findAll && resultTab.length) return resultTab;
    }
  }
  return resultTab;
}

export function needLayout() {
  return window.location.pathname.indexOf('/splayout') === 0;
}
