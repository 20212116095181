import { IconNames } from '../../../lib';
import { getLabel } from '@weapp/utils';

export const appIcons: IconNames[] = [
  "Icon-@My-new-feedback",
  "Icon-Approval-process",
  "Icon-Business-form",
  "Icon-CRM-statistics",
  "Icon-Cloud-clothing",
  "Icon-Completed-items",
  "Icon-Concerned-colleagues",
  "Icon-Conference-Room",
  "Icon-Document",
  "Icon-Electronic-signature",
  "Icon-Executive-platform",
  "Icon-Female-head",
  "Icon-Group-portrait",
  "Icon-Invoice-cloud",
  "Icon-inc",
  "Icon-Journalism",
  "Icon-Knowledge-document",
  "Icon-Leave-management",
  "Icon-Male-head-portrait",
  "Icon-Marketing-activities",
  "Icon-Message-center",
  "Icon-Mobile-attendance",
  "Icon-My-subordinates",
  "Icon-New-feedback",
  "Icon-Notice",
  "Icon-Personal-collaboration",
  "Icon-Personnel-contract",
  "Icon-Price",
  "Icon-Push-settings",
  "Icon-Reimbursement",
  "Icon-Statistical-chart",
  "Icon-Statistical-report",
  "Icon-Team-sharing",
  "Icon-Team-work-status",
  "Icon-Telephone",
  "Icon-Unfinished-business",
  "Icon-Unread-items",
  "Icon-Voice-Office",
  "Icon-anonymous",
  "Icon-application",
  "Icon-assets",
  "Icon-authentication",
  "Icon-business-opportunity",
  "Icon-capital",
  "Icon-clue",
  "Icon-competitor",
  "Icon-contacts",
  "Icon-contract",
  "Icon-cooperation",
  "Icon-customer-service",
  "Icon-customer",
  "Icon-daily",
  "Icon-demonstration",
  "Icon-department",
  "Icon-gateway",
  "Icon-global-search",
  "Icon-group",
  "Icon-integrate",
  "Icon-investigation",
  "Icon-label",
  "Icon-mail-list",
  "Icon-mail",
  "Icon-more",
  "Icon-my",
  "Icon-newly-build",
  "Icon-order",
  "Icon-performance-appraisal",
  "Icon-personnel-matters",
  "Icon-picture",
  "Icon-product",
  "Icon-project-management",
  "Icon-quoted-price",
  "Icon-relation",
  "Icon-remind",
  "Icon-report-form",
  "Icon-report",
  "Icon-satisfied",
  "Icon-schedule",
  "Icon-set-up",
  "Icon-share",
  "Icon-signal-communication",
  "Icon-task",
  "Icon-to-grant-authorization",
  "Icon-vehicle",
  "Icon-Contact-record",
  "Icon-Duplicate-checking",
  "Icon-international-waters",
  "Icon-Private-arrangements",
  "Icon-Account-security",
  "Icon-upgrade",
  "Icon-Payroll",
  "Icon-position02",
  "Icon-short-message02",
  "Icon-scrm",
  "Icon-market",
  "Icon-WeChat03",
  "Icon-Cloud-reimbursement",
  "Icon-label02",
  "Icon-Cloud-store-o",
  "Icon-Salary-management-o",
  "Icon-ecode-o",
  "Icon-To-do-list-o",
  "Icon-Schedule-meeting-o",
  "Icon-Hrsynchronization-o",
  "Icon-file-management-o",
  "Icon-System-import-and-export-o",
  "Icon-mapping-o",
  "Icon-customerservice2",
  "Icon-Network-IP",
  "Icon-Servicecall-o",
  "Icon-Formengine-o",
  "Icon-set-up"
] as any;

export const usageAppIcons: IconNames[] = [
  "Icon-Ask-every-day",
  "Icon-Audit-log",
  "Icon-Automated-workflow",
  "Icon-Basic-settings",
  "Icon-Configuration-center",
  "Icon-Ctrip",
  "Icon-Development-platform",
  "Icon-Document-management",
  "Icon-ESB-Center",
  "Icon-Import-and-export-o",
  "Icon-Management-by-objectives",
  "Icon-Mobile-management-platform",
  "Icon-Multilingual",
  "Icon-Official-document",
  "Icon-Operation-and-maintenance-platform",
  "Icon-Operation-log",
  "Icon-Organization-Center-Authority",
  "Icon-Problem-reporting",
  "Icon-Third-party-plug-in",
  "Icon-Working-portrait",
  "Icon-crm",
  "Icon-data-security",
  "Icon-e-builder",
  "Icon-financial-budget",
  "Icon-okr",
  "Icon-system-log",
  "Icon-crmHome",
  "Icon-Actuator-management",
  "Icon-Send-a-message",
  "Icon-Change-Password",
  "Icon-add-group",
  "Icon-dispatch",
  "Icon-edit",
  "Icon-Hang-up",
  "Icon-link02",
  "Icon-Message-voice",
  "Icon-News-video",
  "Icon-Recording",
  "Icon-Video-off",
  "Icon-Voice-off",
  "Icon-answer-the-phone",
  "Icon-Documentation",
  "Icon-Groupannouncement"
]

export const cusAppIcons: IconNames[] = [
  "Icon-custom01",
  "Icon-custom02",
  "Icon-custom03",
  "Icon-custom04",
  "Icon-custom05",
  "Icon-custom06",
  "Icon-custom07",
  "Icon-custom08",
  "Icon-custom09",
  "Icon-custom10",
  "Icon-custom11",
  "Icon-custom12",
  "Icon-custom13",
  "Icon-custom14",
  "Icon-custom15",
  "Icon-custom16",
  "Icon-custom17",
  "Icon-custom18",
  "Icon-custom19",
  "Icon-custom20",
  "Icon-custom21",
  "Icon-custom22",
  "Icon-custom23",
  "Icon-custom24",
  "Icon-custom25",
  "Icon-custom26",
  "Icon-custom27",
  "Icon-custom28",
  "Icon-custom29",
  "Icon-custom30",
  "Icon-custom32",
  "Icon-custom31",
  "Icon-custom33",
  "Icon-custom34",
  "Icon-custom35",
  "Icon-custom36",
  "Icon-custom37",
  "Icon-custom38",
  "Icon-custom39",
  "Icon-custom40",
  "Icon-custom41",
  "Icon-custom42",
  "Icon-custom43",
  "Icon-custom44",
  "Icon-custom45",
  "Icon-custom46",
  "Icon-custom47",
  "Icon-custom48",
  "Icon-custom49",
  "Icon-custom50",
  "Icon-custom51",
  "Icon-custom52",
  "Icon-custom53",
  "Icon-custom54",
  "Icon-custom55",
  "Icon-custom56",
  "Icon-custom57",
  "Icon-custom58",
  "Icon-custom59",
  "Icon-custom60",
  "Icon-custom61",
  "Icon-custom62",
  "Icon-custom63",
  "Icon-custom64",
  "Icon-custom65"
]

export const paymentAppIcons: IconNames[] = [
  "Icon-Ali-nail",
  "Icon-Alipay-card-pack",
  "Icon-Batch-reimbursement",
  "Icon-Corporate-payment",
  "Icon-Corresponding-account-of-bill-item",
  "Icon-Cost-standard",
  "Icon-Ctrip-Travel",
  "Icon-Ctrip-order",
  "Icon-Enterprise-Didi",
  "Icon-Invoice---mail",
  "Icon-Inspection-record",
  "Icon-Invoice---my",
  "Icon-Invoice-inspection",
  "Icon-Invoice-not-checked",
  "Icon-Invoice-photo",
  "Icon-Invoice-statistics",
  "Icon-Invoice-title",
  "Icon-Invoiced",
  "Icon-Jingdong-order",
  "Icon-Little-e",
  "Icon-Loan-application",
  "Icon-Loan-records",
  "Icon-Make-a-note-of-it",
  "Icon-My-work-has-been-done",
  "Icon-Manual-input",
  "Icon-Payment-records",
  "Icon-Reimbursement-records",
  "Icon-Reimbursement-account-maintenance",
  "Icon-Repayment-application",
  "Icon-Scan-invoice-code",
  "Icon-To-be-paid",
  "Icon-To-be-reimbursed",
  "Icon-To-be-repaid",
  "Icon-Travel-expenses-reimbursement",
  "Icon-Travel-in-the-same-city",
  "Icon-Travel-pre-application",
  "Icon-Under-approval",
  "Icon-Valid-checked-invoice",
  "Icon-Waiting-for-me-to-apply",
  "Icon-Wechat-card-package",
  "Icon-Wechat-file",
  "Icon-community",
  "Icon-apply",
  "Icon-draft",
  "Icon-form-management",
  "Icon-invoice",
  "Icon-payment-history",
  "Icon-Invoice-reminder"
]

export const richIconMap = appIcons.concat(usageAppIcons, cusAppIcons, paymentAppIcons);

export const transIcon_o = (name: string): IconNames => {
  return name.endsWith("-o") ? name.substring(0, name.length - 2) : `${name}-o` as any;
}

const icons: IconNames[][] = [appIcons, usageAppIcons, cusAppIcons, paymentAppIcons];

export default icons;