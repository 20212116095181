const ls = {
  getItem(key: string, dftVal?: any) {
    const item = localStorage.getItem(key);

    try {
      if (item) {
        return JSON.parse(item);
      }
      return dftVal;
    } catch {
      return item;
    }
  },
  setItem(key: string, value: any) {
    const val = typeof value === "string" ? value : JSON.stringify(value);

    localStorage.setItem(key, val);
  },
  removeItem(key: string) {
    localStorage.removeItem(key);
  },
};

export default ls;
