import { appInfo } from "@weapp/utils";

export const uiAppName = appInfo('@weapp/ui').libraryName;

export const uiPcClsPrefix = "ui";

/* Layout */
export const layoutClsPrefix = `${uiPcClsPrefix}-layout`;
export const LAYOUTWEIGHT = 24; /* 栅格数 */
export const SIDEWEIGHT = 4; /* 侧边栏默认栅格数 */

/* Button */
export const btnClsPrefix = `${uiPcClsPrefix}-btn`;

/* List */
export const listClsPrefix = `${uiPcClsPrefix}-list`;

/* Tree */
export const treeClsPrefix = `${uiPcClsPrefix}-tree`;

/* Trigger */
export const triggerClsPrefix = `${uiPcClsPrefix}-trigger`;
export const POPUPPLACEMENT = 'bottomLeft';

/* Input */
export const inputClsPrefix = `${uiPcClsPrefix}-input`;

/* Textarea */
export const textareaClsPrefix = `${uiPcClsPrefix}-textarea`;

/* Select */
export const selectClsPrefix = `${uiPcClsPrefix}-select`;
export const SELECTALLSIGN = "__SELECT_ALL__";

/* Checkbox */
export const checkboxClsPrefix = `${uiPcClsPrefix}-checkbox`;

/* Radio */
export const radioClsPrefix = `${uiPcClsPrefix}-radio`;

/* Icon */
export const iconClsPrefix = `${uiPcClsPrefix}-icon`;

/* Menu */
export const menuClsPrefix = `${uiPcClsPrefix}-menu`;

/* Map */
export const mapClsPrefix = `${uiPcClsPrefix}-map`;

/* Dialog */
export const dialogClsPrefix = `${uiPcClsPrefix}-dialog`;

/* Title */

export const titleClsPrefix = `${uiPcClsPrefix}-title`;

/* Table */
export const tableClsPrefix = `${uiPcClsPrefix}-table`;

/* ListView */
export const listViewClsPrefix = `${uiPcClsPrefix}-listView`;

/* message */

export const messageClsPrefix = `${uiPcClsPrefix}-message`;

/* confirm */

export const confirmClsPrefix = `${uiPcClsPrefix}-confirm`;

/* switch */
export const switchClsPrefix = `${uiPcClsPrefix}-switch`;

/* scope */
export const scopeClsPrefix = `${uiPcClsPrefix}-scope`;

/* Upload */
export const uploadClsPrefix = `${uiPcClsPrefix}-upload`;

/* slider */
export const sliderClsPrefix = `${uiPcClsPrefix}-slider`;

/* steps */
export const stepsClsPrefix = `${uiPcClsPrefix}-steps`;

/* Popover */
export const popoverClsPrefixCls = `${uiPcClsPrefix}-popover`;

/* Pagination */
export const paginationClsPrefix = `${uiPcClsPrefix}-pagination`;

/* Avatar */
export const avatarClsPrefix = `${uiPcClsPrefix}-avatar`;

/* At */
export const atClsPrefix = `${uiPcClsPrefix}-at`;

/* Browser */
export const browserClsPrefix = `${uiPcClsPrefix}-browser`;
// export const BROWSER_USEBOXSELECTION = false;  // 是否展示外边框

/* Help */
export const helpClsPrefix = `${uiPcClsPrefix}-help`;

/* Tag */
export const tagClsPrefix = `${uiPcClsPrefix}-tag`;

/* PickerView */
export const pickerViewClsPrefix = `${uiPcClsPrefix}-picker-view`;

/* ColorPicker */
export const colorPickerPrefix = `${uiPcClsPrefix}-colorpicker`;

/* TimePicker */
export const timePickerClsPrefix = `${uiPcClsPrefix}-time-picker`;

/* DatePicker */
export const datePickerClsPrefix = `${uiPcClsPrefix}-date-picker`;

/* DateTimePicker */
export const dateTimePickerClsPrefix = `${uiPcClsPrefix}-date-time-picker`;

/* DateQuickFilter*/
export const dateQuickFilterClsPrefix = `${uiPcClsPrefix}-date-quick-filter`;

/* EChart */
export const echartPrefix = `${uiPcClsPrefix}-echarts`;

/* Navigation */

export const navigationClsPrefixCls = `${uiPcClsPrefix}-navigation`;

/* ActionSheet */
export const actionSheetClsPrefixCls = `${uiPcClsPrefix}-actionSheet`;

/* Rate */
export const rateClsPrefix = `${uiPcClsPrefix}-rate`;

/* Form */

export const formClsPrefix = `${uiPcClsPrefix}-form`;

/* FormItem */

export const formItemClsPrefix = `${uiPcClsPrefix}-formItem`;

/* FormSwitch */

export const formSwitchClsPrefix = `${uiPcClsPrefix}-formSwitch`;

/* Collapse */
export const collapsePrefix = `${uiPcClsPrefix}-collapse`;
export const EASE_IN_OUT = 'cubic-bezier(0.5, 0, 0.5, 0.1)';

/* SearchAdvanced */

export const searchAdvancedClsPrefix = `${uiPcClsPrefix}-searchAdvanced`;

/* SearchGroup */

export const searchGroupClsPrefix = `${uiPcClsPrefix}-searchGroup`;

/* EditableTable */
export const editableTableClsPrefix = `${uiPcClsPrefix}-editable-table`;

/* PullToRefresh */
export const pullToRefreshClsPrefix = `${uiPcClsPrefix}-pull-to-refresh`;

/* Carousel */
export const carouselClsPrefix = `${uiPcClsPrefix}-carousel`;

/* MindMap */
export const mindMapClsPrefix = `${uiPcClsPrefix}-mind-map`;

/* Board */
export const boardClsPrefix = `${uiPcClsPrefix}-board`;

/* Spin */
export const spinClsPrefix = `${uiPcClsPrefix}-spin`;

/* Qrcode */
export const qrcodeClsPrefix = `${uiPcClsPrefix}-qrcode`;

/* SlideTabs */
export const slideTabsClsPrefix = `${uiPcClsPrefix}-slide-tabs`;

/* Locale */
export const localeClsPrefix = `${uiPcClsPrefix}-locale`;

/* CodeMirror */
export const codeMirrorClsPrefix = `${uiPcClsPrefix}-code-mirror`;

/* ImageCropper */
export const imageCropperClsPrefix = `${uiPcClsPrefix}-image-cropper`;

/* Empty */
export const emptyClsPrefix = `${uiPcClsPrefix}-empty`;

/* Emoji */
export const emoticonClsPrefix = `${uiPcClsPrefix}-emoticon`;

/* BoxSelection */
export const boxSelectionClsPrefix = `${uiPcClsPrefix}-box-selection`;
/* RichText */
export const richTextClsPrefix = `${uiPcClsPrefix}-rich-text`;

/* HrmCard */
export const hrmCardClsPrefix = `${uiPcClsPrefix}-hrm-card`;
/* RightMenu */
export const rightMenuClsPrefix = `${uiPcClsPrefix}-right-menu`;
/* SwipeAction */
export const swipeActionClsPrefix = `${uiPcClsPrefix}-swipe-action`;

/* PhotoView */
export const photoViewClsPrefix = `${uiPcClsPrefix}-photo-view`;

/* CardDetailForm */
export const cardDetailFormClsPrefix = `${uiPcClsPrefix}-card-detail-form`;
/* Timeline */
export const timelineClsPrefix = `${uiPcClsPrefix}-timeline`;
/* BreadCrumb */
export const breadCrumbClsPrefix = `${uiPcClsPrefix}-bread-crumb`;
/* Comment */
export const commentClsPrefix = `${uiPcClsPrefix}-comment`;

/* Scroller */
export const scrollerClsPrefix = `${uiPcClsPrefix}-scroller`;

/* Signature */
export const SignatureClsPrefix = `${uiPcClsPrefix}-signature`;

/* ResourcePanel */
export const resourcePanelClsPrefix = `${uiPcClsPrefix}-resource-panel`;

/* AnchorList */
export const anchorListPrefix = `${uiPcClsPrefix}-anchor-list`;

/* BackTop */
export const backTopPrefix = `${uiPcClsPrefix}-back-top`;

/* Cascader */
export const cascaderClsPrefix = `${uiPcClsPrefix}-cascader`;

/* IconSelection */
export const iconSelectionClsPrefix = `${uiPcClsPrefix}-icon-selection`;

/* AppIcon */
export const appIconClsPrefix = `${uiPcClsPrefix}-app-icon`;

/* MattersAssociated */
export const mattersAssociatedClsPrefix = `${uiPcClsPrefix}-matters-associated`;

/* Video */
export const videoClsPrefix = `${uiPcClsPrefix}-video`;

/* Audio */
export const audioClsPrefix = `${uiPcClsPrefix}-audio`;

/* MonthDayPicker */
export const monthDayPickerClsPrefix = `${uiPcClsPrefix}-month-day-picker`;

/* layoutAdapt */
export const layoutAdaptClsPrefix = `${uiPcClsPrefix}-layout-adapt`;

/* bubblyBgClsPrefix */
export const bubblyBgClsPrefix = `${uiPcClsPrefix}-bubbly-bg`;

/* BatchOperatorClsPrefix */
export const BatchOperatorClsPrefix = `${uiPcClsPrefix}-batch-operator`;

/* AnchorList */
export const transferPrefix = `${uiPcClsPrefix}-transfer`;

/* shareClsPrefix */
export const shareClsPrefix = `${uiPcClsPrefix}-share`;

/* authClsPrefix */
export const authClsPrefix = `${uiPcClsPrefix}-auth`;

/* Alert */
export const alertClsPrefix = `${uiPcClsPrefix}-alert`;

/* Skeleton */
export const skeletonClsPrefix = `${uiPcClsPrefix}-skeleton`;
export const USESKELETON = false;

/* Watermark */
export const watermarkClsPrefix = `${uiPcClsPrefix}-watermark`;

/* Year */
export const yearViewClsPrefix = `${uiPcClsPrefix}-year-view`

/* Encry */
export const encryClsPrefix = `${uiPcClsPrefix}-encry`;

/* BrowserType */
export const browserTypeClsPrefix = `${uiPcClsPrefix}-browser-type`;

/* CommonPhrase */
export const commonPhraseClsPrefix = `${uiPcClsPrefix}-common-phrase`;

/* FieldTemplateSelect */
export const FieldTemplateSelectClsPrefix = `${uiPcClsPrefix}-field-template-select`;

export const associateListClsPrefix = `${uiPcClsPrefix}-associate-list`;

/* ColorBoard */
export const colorBoardClsPrefix = `${uiPcClsPrefix}-color-board`;

/* SelectGroup */
export const selectGroupClsPrefix = `${uiPcClsPrefix}-select-group-new`;

/* CityPicker */
export const cityPickerClsPrefix = `${uiPcClsPrefix}-city-picker`;

/* SensitiveWordScan */
export const sensitiveWordScanClsPrefix = `${uiPcClsPrefix}-sensitive-word-scan`;

/* WorkStatus */
export const workStatusClsPrefix = `${uiPcClsPrefix}-work-status`;

export const recordingClsPrefix = `${uiPcClsPrefix}-recording`;

/* DateMenu */
export const dateMenuClsPrefix = `${uiPcClsPrefix}-date-menu`;

/* secretSelect */
export const secretSelectClsPrefix = `${uiPcClsPrefix}-secret-select`;

/* RepeatFrequency */
export const repeatFrequencyClsPrefix = `${uiPcClsPrefix}-repeat-frequency`;

/* PhotoEditor */
export const photoEditorClsPrefix = `${uiPcClsPrefix}-photo-editor`;

/* Zoom */
export const zoomClsPrefix = `${uiPcClsPrefix}-zoom`;
