import Loadable from "../../react-loadable";
import { mEChartClsPrefix } from "../../constants/index.m";
import { MEChartsType } from "./types";

const MEChart = Loadable({ name: "MECharts", loader: () => import(
  /* webpackChunkName: "m_echarts" */
  "./EChartsView")  }) as MEChartsType;

MEChart.defaultProps = {
  prefixCls: mEChartClsPrefix,
  districtName: '中国',
  renderer: 'canvas'
}

export type { MEChartsProps, DistrictName as MDistrictName, EchartsInterface as MEchartsInterface } from "./types";
export default MEChart;
