
import Loadable from '../../react-loadable';

import { mSearchAdvancedClsPrefix } from '../../constants/index.m';
import { MSearchAdvancedType } from './types';

const MSearchAdvanced = Loadable({
  name: 'MSearchAdvanced', loader: () => import(
    /* webpackChunkName: "m_searchadvanced" */
    './MSearchAdvanced')
}) as MSearchAdvancedType;

MSearchAdvanced.defaultProps = {
  prefixCls: mSearchAdvancedClsPrefix,
  colSpan: 24,
  disabledCustomDateSaveFormat: true,
  usePanel: false,
  enableBrowserIncludeSub: true,
}

export type { MSearchAdvancedProps } from './types';

export default MSearchAdvanced;
