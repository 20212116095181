import { middleware } from "@weapp/utils";
import { Component } from "react";
import { uiAppName } from "../../constants";
import Layout from "../layout";
import Scroller from "../scroller";
import { AsideContainer, ModuleContentContainer, NavigationContainer } from "./Container";
import { MainLayoutProps } from "./types";

@middleware(uiAppName, 'MainLayout')
export default class MainLayout extends Component<MainLayoutProps> {

  renderNavigation () {
    const { navigation, navigationProps } = this.props;
    let navigationCom = null;

    if (navigation) {
      navigationCom = (
        <Layout.Box weId={`${this.props.weId || ''}_itu64e`} type="header" outer {...navigationProps}>
          <NavigationContainer weId={`${this.props.weId || ''}_0p5eaj`}>{navigation}</NavigationContainer>
        </Layout.Box>
      );
    }

    return navigationCom;
  }

  renderAside () {
    const { aside, asideProps } = this.props;
    let asideCom = null;
  
    if (aside) {
      asideCom = (
        <Layout.Box weId={`${this.props.weId || ''}_xokcr7`} type="side" width={200} {...asideProps}>
          <Scroller style={{ height: "inherit" }} weId={`${this.props.weId || ''}_81gcax`}>
            <AsideContainer weId={`${this.props.weId || ''}_qrbgi2`}>{aside}</AsideContainer>
          </Scroller>
        </Layout.Box>
      );
    }

    return asideCom;
  }

  renderModuleContent () {
    const { moduleContent, contentProps } = this.props;
    let moduleContentCom = null;

    if (moduleContent) {
      moduleContentCom = (
        <Layout.Box weId={`${this.props.weId || ''}_aptg6v`} type="content" {...contentProps}>
          <ModuleContentContainer weId={`${this.props.weId || ''}_607aca`}>{moduleContent}</ModuleContentContainer>
        </Layout.Box>
      );
    }

    return moduleContentCom;
  }

  render() {
    const { children, ...restProps } = this.props;

    return (
      <Layout {...restProps} weId={`${this.props.weId || ''}_nrwkkc`}>
        {this.renderNavigation()}
        {this.renderAside()}
        {this.renderModuleContent()}
        {children}
      </Layout>
    );
  }
}