import { ComponentType } from 'react';
import { MSearchbarProps } from './types';
import { mSearchbarClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';

const MSearchbar = Loadable<MSearchbarProps> ({
  name: 'MSearchbar', loader: () => import(
    /* webpackChunkName: "ui_m_common" */
    './Searchbar')
});

MSearchbar.defaultProps = {
  prefixCls: mSearchbarClsPrefix
};


export type { MSearchbarProps } from './types';

export default MSearchbar;
