import Loadable from '../../react-loadable';
import _RootLayout from './RootLayout';
import * as rootLayoutUtils from './RootLayoutUtils';
import DynamicRenderComponent from './DynamicRenderComponent';

import { RootLayoutType } from './types';

const RootLayout = Loadable({
  name: 'RootLayout',
  component: _RootLayout,
}) as RootLayoutType;

RootLayout.defaultProps = {
  moduleName: '@weapp/layout',
};
export type { RootLayoutProps, DynamicRenderComponentProps, RootLayoutMenuData, RootLayoutMenuDataType } from './types';

export { rootLayoutUtils, DynamicRenderComponent };

export default RootLayout;