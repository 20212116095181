import { ComponentType } from "react";
import { AnyObj, BaseProps, DialogProps, FormProps, MDialogProps } from "../../lib";

/** 保密期限显示状态 */
export enum SecretLevelValidityShowType {
  REQUIRED = 'required',
  EDIT = 'edit',
  READONLY = 'readOnly',
  HIDDEN = 'hidden',
}

export type SecretSelectValueType = {
  // 保密等级
  secretLevel?: string;
  // 保密期限
  secretLevelValidity?: string;
}

export type SecretSelectOptionType = {
  /** 保密等级 */
  id: string,
  /** 密级名称 */
  content: string,
  /** true: 密级和保密期限，false：仅密级 */
  needValidity?: boolean,
  /* 密级标志（needValidity 为 true 时存在）*/
  identifier?: string;
  /** 保密期限显示状态：require：必填，edit：可编辑，readOnly：显示，hidden：隐藏 */
  secretLevelValidityShowType: 'require' | 'edit' | 'readOnly' | 'hidden' | string,
  /** 默认保密期限 */
  defaultSecretLevelValidity?: string,
  /** 是否默认密级 */
  isDefault?: boolean,
  /** 是否封存 false未封存（启用）,true封存（未启用） */
  canceled?: boolean,
}

export interface SecretSelectState {
  /** isInit */
  isInit: boolean;
  // 所有保密等级配置
  options?: SecretSelectOptionType[]
  // 当前可操作的保密等级配置
  secretLevelOptions?: SecretSelectOptionType[];
  // 当前选中的保密等级
  secretLevel?: string;
  // 当前选中的保密等级配置
  secretLevelOption?: Partial<SecretSelectOptionType>;
  // 当前填写的保密期限
  secretLevelValidity?: string;
  /** 保密期限提示 */
  secretLevelValidityInfo?: string;
  /** 密级校验规则（正则） */
  checkValidity?: string;
  // 错误提示
  error?: { [key: string]: string[] };
  /** 密级过滤，仅有低于该等级的密级信息可用 */
  filterLevel?: string;
  /** 密级过滤类型，up 过滤过滤比当前配置的密级等级更高的密级，down 过滤比当前配置的密级等级更低的密级 */
  filterLevelType?: string;
  /** 主次账号 */
  secondEmpId?: string;
}

export interface SecretSelectProps extends BaseProps, FormProps {
  /** 密级值（受控） */
  value?: SecretSelectValueType;
  /** 密级（默认值） */
  defaultValue?: SecretSelectValueType;
  /** 模块自定义密级配置 */
  data?: SecretSelectOptionType[];
  /** 密级校验规则（正则） */
  checkValidity?: string;
  /** 保密期限提示 */
  secretLevelValidityInfo?: string;
  /**  取 secret-select 实例 */
  getInstance?: (secretSelect: any) => void;
  /** 数据改变毁掉 */
  onChange?: (value: SecretSelectValueType, option?: SecretSelectOptionType) => void;
  /** 失焦事件 */
  onBlur?: (value: SecretSelectValueType, option?: SecretSelectOptionType) => void;
  onFocus?: () => void;
  /** 错误提示 */
  error?: { [key: string]: string[] };
  /** 是否失焦时自动校验 */
  autoValidate?: boolean;
  /** 密级过滤，默认仅有低于该等级的密级信息可用 */
  filterLevel?: string;
  /** 密级过滤类型，up 过滤过滤比当前配置的密级等级更高的密级，down 过滤比当前配置的密级等级更低的密级 */
  filterLevelType?: string;
  /** 换行展示 */
  wrapDisplay?: boolean;
  /**
* 设置是否显示 label
* @title 是否显示 label
*/
  withLabel?: boolean;
  /**
  * 设置 FormItem.Item 的标签宽度占比
  * @title 标签宽度占比
  */
  labelSpan?: number,

  /** 接口头部设置 */
  requestHeaderParams?: AnyObj,
}

export type SecretSelectDialogProps = SecretSelectProps & {
  visible: boolean;
  onClose?: () => void,
  onSure?: (value: SecretSelectValueType, option?: SecretSelectOptionType) => void,
  dialogProps?: DialogProps;
};

export type SecretSelectDialogState = {
  // value: SecretSelectValueType,
};

export type SecretSelectType = ComponentType<SecretSelectProps> & {
  doValidate: (
    data?: {
      secretLevel?: string;
      secretLevelValidity?: string;
    },
    props?: {
      checkValidity?: string;
      options?: SecretSelectOptionType[];
      required?: boolean;
      requestHeaderParams?: AnyObj;
    }
  ) => Promise<any>;
  getSecretLevelInfo: (secLevel?: number, config?: {
    params?: AnyObj,
    headers?: AnyObj
  }) => Promise<{
    resourceOptionInfo: SecretSelectOptionType[];
    secretLevelValidityInfo: string;
    isOpenClassification: boolean;
  }>,
  getSecretLevelOption: (secretLevel?: string, filterCanceled?: boolean, params?: {
    noDefault?: boolean;
    options?: SecretSelectOptionType[];
    filterLevel?: string;
    filterLevelType?: string;
    empId?: string;
  }) => Partial<SecretSelectOptionType>;
  SecretSelectDialog: ComponentType<SecretSelectDialogProps>;
};

export interface MSecretSelectProps extends Omit<SecretSelectProps, ''> {

}

export type MSecretSelectType = ComponentType<MSecretSelectProps> & Omit<SecretSelectType, 'MSecretSelectDialog'> & {
  MSecretSelectDialog: ComponentType<MSecretSelectDialogProps>;
};

export type MSecretSelectDialogProps = MSecretSelectProps & {
  visible: boolean;
  onClose?: () => void,
  onSure?: (value: SecretSelectValueType, option?: SecretSelectOptionType) => void,
  dialogProps?: MDialogProps;
}

export type MSecretSelectDialogState = {
  // value: SecretSelectValueType,
};
