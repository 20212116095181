import Loadable, { LoadingType } from '../../react-loadable';
import { MRateType, RateProps } from './types';
import { mRateClsPrefix } from '../../constants/index.m';
import { USESKELETON } from '../../constants/index';

const MRateSkeleton: LoadingType<RateProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MRate = Loadable({
  name: 'MRate',
  loading: MRateSkeleton,
  loader: () => import(
    /* webpackChunkName: 'ui_m_common_form' */
    './Rate')
}) as MRateType;

MRate.MRateSkeleton = MRateSkeleton;

MRate.defaultProps = {
  defaultValue: 0,
  count: 5,
  allowHalf: false,
  allowClear: true,
  prefixCls: mRateClsPrefix,
  direction: 'ltr',
  readOnlyFilterEmptyRate: false
};

export type { RateProps as MRateProps, RateType as MRateType } from './types';

export default MRate;
