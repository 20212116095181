import Loadable from '../../react-loadable';
import { CollapseType, PanelType }  from './types';

const MCollapse = Loadable({ name: 'MCollapse', loader: () => import(
    /* webpackChunkName: "ui_m_common_less" */
    './Collapse.m') }) as CollapseType;

const MPanel = Loadable({ name: 'MPanel', loader: () => import(
    /* webpackChunkName: "ui_m_common_less" */
    './Panel.m') }) as PanelType;

MCollapse.Panel = MPanel;

export type { CollapseType as MCollapseType, PanelType as MPanelType, ICollapseProps as MICollapseProps, ICollapsePanelProps as MICollapsePanelProps } from './types';

export default MCollapse;
