import Loadable from '../../react-loadable';
import { CodeMirrorType } from './types';
import { codeMirrorClsPrefix } from '../../constants/index';

const CodeMirror = Loadable({ name: 'CodeMirror', loader: () => import(
  /* webpackChunkName: "code_mirror" */
  './CodeMirror') }) as CodeMirrorType;

CodeMirror.defaultProps = {
  ...CodeMirror.defaultProps,
  prefixCls: codeMirrorClsPrefix,
};

export type { CodeMirrorProps } from './types';

export default CodeMirror;