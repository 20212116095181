

import Loadable from '../../react-loadable';
import { mindMapClsPrefix } from '../../constants/index';
import { MindMapType } from './types';

const MindMap = Loadable({ name: 'MindMap', loader: () => import(
  /* webpackChunkName: "mindMap" */
  './MindMap') })  as MindMapType;

MindMap.defaultProps = {
  prefixCls: mindMapClsPrefix,
  draggable:false,
  datas: {
    meta:{
      name:"demo",
    },
    format:"node_array",
    data: []
  },
};

export type { MindMapProps, MindMapType,MindFormatType } from './types';

export default MindMap;

