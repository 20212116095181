const prefix = 'modulePublishSuccessToReload';

// 使用 轮询 监听项目构建更新
const blackList = [
  /\/mobile\/em\/watermark/, // app 上消息聊天水印页面
  /\/sp\/em\/popup/,
];
const versionCacheKey = 'weapp_refresh_notice_module_version_cache'; // 缓存字段
const disableRefreshNoticeKey = 'weapp_refresh_notice_disabled'; // 缓存禁用
const neverNoticeKey = 'neverNoticeKey'; // 不再提醒

export {
  prefix,
  blackList,
  neverNoticeKey,
  versionCacheKey,
  disableRefreshNoticeKey,
};
