import { getLabel } from "@weapp/utils";
import { OptionData, OptionsType } from "../types";
import { classUseMemo } from "@weapp/utils";

export const getSortSelectData = () => {
  return classUseMemo(
    "sortSelectData",
    {},
    () => [
      {
        id: "head",
        value: "head",
        label: getLabel("18817", "正数"),
        content: getLabel("18817", "正数"),
      },
      {
        id: "tail",
        value: "tail",
        label: getLabel("11894", "倒数"),
        content: getLabel("11894", "倒数"),
      },
      {
        id: "appoint",
        value: "appoint",
        label: getLabel("222879", "指定"),
        content: getLabel("222879", "指定"),
      },
    ],
    []
  );
};

// 小时
// -> 每小时 -> 用times-picker选择
// -> 指定小时(getHourOptions) -> 用frequency-picker选择
// --------------------
// 获取指定小时
export const getHourOptions = () => {
  return classUseMemo(
    "hourOptions",
    {},
    () => {
      const hourOptions: OptionsType = [];
      for (let i = 0; i < 24; i++) {
        const _i = String(i);
        const optionData: OptionData = {
          id: _i,
          value: _i,
          label: `${i < 10 ? `0${i}` : i}:00`,
          content: `${i < 10 ? `0${i}` : i}:00`,
        };
        hourOptions.push(optionData);
      }
      return hourOptions;
    },
    []
  );
};

// 日
// -> 每几日 -> 用times-picker选择
// --------------------
// 获取指定日（主要用于在月份下级选择，不可单独选择）
export const getDayOptions = () => {
  return classUseMemo(
    "dayOptions",
    {},
    () => {
      const dayOptions: OptionsType = [];
      for (let i = 1; i <= 31; i++) {
        const _i = String(i);
        const optionData: OptionData = {
          id: _i,
          value: _i,
          label: _i,
          content: _i,
        };
        dayOptions.push(optionData);
      }
      return dayOptions;
    },
    []
  );
};

// 获取指定日期（主要用于在月份下级选择，不可单独选择）
export const getDateOptions = () => {
  return classUseMemo(
    "dateOptions",
    {},
    () => {
      const label = getLabel("62966", "日");
      const dateOptions: OptionsType = [];
      for (let i = 1; i <= 31; i++) {
        const _i = String(i);
        const optionData: OptionData = {
          id: _i,
          value: _i,
          label: `${i}${label}`,
          content: `${i}${label}`,
        };
        dateOptions.push(optionData);
      }
      dateOptions.push({
        id: "-1",
        value: "-1",
        label: getLabel("222631", "最后1日"),
        content: getLabel("222631", "最后1日"),
      });
      return dateOptions;
    },
    []
  );
};

// 周
// -> 每几周/每周 -> 指定星期几(可多选 getWeekDayOptions) -> 用frequency-picker选择
// --------------------
// 获取第几周（主要用于在月份下级选择，不可单独选择）
export const getWeekOptions = () => {
  return classUseMemo(
    "weekOptions",
    {},
    () => [
      {
        id: "1",
        value: "1",
        label: getLabel("222605", "第1周"),
        content: getLabel("222605", "第1周"),
      },
      {
        id: "2",
        value: "2",
        label: getLabel("222606", "第2周"),
        content: getLabel("222606", "第2周"),
      },
      {
        id: "3",
        value: "3",
        label: getLabel("222608", "第3周"),
        content: getLabel("222608", "第3周"),
      },
      {
        id: "4",
        value: "4",
        label: getLabel("222609", "第4周"),
        content: getLabel("222609", "第4周"),
      },
      {
        id: "-1",
        value: "-1",
        label: getLabel("222632", "最后1周"),
        content: getLabel("222632", "最后1周"),
      },
    ],
    []
  );
};

// 获取星期几（可单独直接使用，也可用于在周下级选择）
export const getWeekDayOptions = () => {
  return classUseMemo(
    "weekOptions",
    {},
    () => [
      {
        id: "MO",
        value: "MO",
        label: getLabel("13032", "星期一"),
        content: getLabel("13032", "星期一"),
      },
      {
        id: "TU",
        value: "TU",
        label: getLabel("12973", "星期二"),
        content: getLabel("12973", "星期二"),
      },
      {
        id: "WE",
        value: "WE",
        label: getLabel("13027", "星期三"),
        content: getLabel("13027", "星期三"),
      },
      {
        id: "TH",
        value: "TH",
        label: getLabel("13709", "星期四"),
        content: getLabel("13709", "星期四"),
      },
      {
        id: "FR",
        value: "FR",
        label: getLabel("12984", "星期五"),
        content: getLabel("12984", "星期五"),
      },
      {
        id: "SA",
        value: "SA",
        label: getLabel("13390", "星期六"),
        content: getLabel("13390", "星期六"),
      },
      {
        id: "SU",
        value: "SU",
        label: getLabel("15126", "星期日"),
        content: getLabel("15126", "星期日"),
      },
    ],
    []
  );
};

// 月
// -> 每几月/指定月(可多选 getMonthOptions) -> 指定天数(可多选 正数/倒数 getDayOptions) -> 用frequency-picker选择
// -> 每几月/指定月(可多选 getMonthOptions) -> 指定日期(可多选 getDateOptions) -> 用frequency-picker选择
// -> 每几月/指定月(可多选 getMonthOptions) -> 指定第几周(可多选 getWeekOptions) -> 指定星期几(可多选 getWeekDayOptions) -> 用frequency-picker选择
// --------------------
// 获取指定月份
export const getMonthOptions = () => {
  return classUseMemo(
    "monthOptions",
    {},
    () => [
      {
        id: "1",
        value: "1",
        label: getLabel("19366", "1月"),
        content: getLabel("19366", "1月"),
      },
      {
        id: "2",
        value: "2",
        label: getLabel("19383", "2月"),
        content: getLabel("19383", "2月"),
      },
      {
        id: "3",
        value: "3",
        label: getLabel("19401", "3月"),
        content: getLabel("19401", "3月"),
      },
      {
        id: "4",
        value: "4",
        label: getLabel("19410", "4月"),
        content: getLabel("19410", "4月"),
      },
      {
        id: "5",
        value: "5",
        label: getLabel("19424", "5月"),
        content: getLabel("19424", "5月"),
      },
      {
        id: "6",
        value: "6",
        label: getLabel("19437", "6月"),
        content: getLabel("19437", "6月"),
      },
      {
        id: "7",
        value: "7",
        label: getLabel("19451", "7月"),
        content: getLabel("19451", "7月"),
      },
      {
        id: "8",
        value: "8",
        label: getLabel("19461", "8月"),
        content: getLabel("19461", "8月"),
      },
      {
        id: "9",
        value: "9",
        label: getLabel("19473", "9月"),
        content: getLabel("19473", "9月"),
      },
      {
        id: "10",
        value: "10",
        label: getLabel("13042", "10月"),
        content: getLabel("13042", "10月"),
      },
      {
        id: "11",
        value: "11",
        label: getLabel("13047", "11月"),
        content: getLabel("13047", "11月"),
      },
      {
        id: "12",
        value: "12",
        label: getLabel("13056", "12月"),
        content: getLabel("13056", "12月"),
      },
    ],
    []
  );
};

// 季度
// -> 每几季度/指定季度(可多选 getQuarterOptions) -> 指定第几月(可多选 正数/倒数) -> 指定天数(可多选 正数/倒数 getDayOptions) -> 用frequency-picker选择
// -> 每几季度/指定季度(可多选 getQuarterOptions) -> 指定第几月(可多选 正数/倒数) -> 指定日期(可多选 getDateOptions) -> 用frequency-picker选择
// -> 每几季度/指定季度(可多选 getQuarterOptions) -> 指定第几月(可多选 正数/倒数) -> 指定第几周(可多选 getWeekOptions) -> 指定星期几(可多选 getWeekDayOptions) -> 用frequency-picker选择
// --------------------
// 获取指定周
export const getQuarterOptions = () => {
  return classUseMemo(
    "quarterOptions",
    {},
    () => [
      {
        id: "1",
        value: "1",
        label: getLabel("164064", "第1季度"),
        content: getLabel("164064", "第1季度"),
      },
      {
        id: "2",
        value: "2",
        label: getLabel("164065", "第2季度"),
        content: getLabel("164065", "第2季度"),
      },
      {
        id: "3",
        value: "3",
        label: getLabel("164066", "第3季度"),
        content: getLabel("164066", "第3季度"),
      },
      {
        id: "4",
        value: "4",
        label: getLabel("164067", "第4季度"),
        content: getLabel("164067", "第4季度"),
      },
    ],
    []
  );
};

export const getQuarterMonthOptions = () => {
  return classUseMemo(
    "quarterMonthOptions",
    {},
    () => [
      {
        id: "1",
        value: "1",
        label: getLabel("236925", "第$x个月").replace("$x", "1"),
        content: getLabel("236925", "第$x个月").replace("$x", "1"),
      },
      {
        id: "2",
        value: "2",
        label: getLabel("236925", "第$x个月").replace("$x", "2"),
        content: getLabel("236925", "第$x个月").replace("$x", "2"),
      },
      {
        id: "3",
        value: "3",
        label: getLabel("236925", "第$x个月").replace("$x", "3"),
        content: getLabel("236925", "第$x个月").replace("$x", "3"),
      },
    ],
    []
  );
};
