import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { classnames, ua } from "@weapp/utils";
import { ResizeObserver } from '@weapp/utils';
import { DialogContentProps, DialogContentRef, DialogDraggableInstance } from "../types";
import DialogDraggable from './DialogDraggable';
import { calcHeight, calcSize, transHeight, transPercentHeight, transWidth } from '../util/index';
import { needSync, debounce, isNumber, dispatchEvent, needRTL } from '../../../utils/index';
import { AnyObj, EVENT_SET_RIGHTMENU_VISIBLE } from '../../../lib';
import DialogHeader from "./DialogHeader";
import Layout from '../../layout/index';
import {
  DIALOG_MIDDLE_MAX_COEFFICIENT,
  DIALOG_MIDDLE_MIN_WIDTH, DIALOG_MIDDLE_MIN_HEIGHT,
  DIALOG_MIDDLE_DEFAULT_HEIGHT_COEFFICIENT, DIALOG_MIDDLE_DEFAULT_WIDTH_COEFFICIENT,
  DIALOG_SCALE_HEIGHT_95, DIALOG_SCALE_WIDTH_95, DIALOG_SCALE_HEIGHT_100, DIALOG_SCALE_WIDTH_100,
  DIALOG_SCALE_LEFT, DIALOG_SCALE_TOP,
} from '../constant/index';

const DialogContent = React.forwardRef<DialogContentRef, DialogContentProps>(
  (props, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const { wrapCls, onCloseContent, contentCls, innerScale, scale, visible, prefixCls, draggable, canDrag,
      placement, width, height, top, left, right, bottom, zIndex, closeAnimation, resize, closable,
      maxResizeWidth, minResizeWidth, maxResizeHeight, minResizeHeight, destroyOnClose, bodyStyle, doScale,
      dialogId, noTitle, setInnerScale, onResize, keepDialogAlive, isStopPropagation, message, footer, footerStyle, ignoreCalculationContainer, isEnlargeFullScreen, resetDragPosition,
      topExtraContent, leftExtraContent, rightExtraContent, bottomExtraContent,
      topExtraContentProps, bottomExtraContentProps, leftExtraContentProps, rightExtraContentProps,
      customRenderInDialogWrap, innerWrapStyle, getTitleLabel
    } = props;

    let draggableInstance: DialogDraggableInstance = {
      reset: () => { },
      onDragStop: () => { }
    }
    let resizeObserverNode: any = null;
    let innerVisibleTimeout = useRef<NodeJS.Timeout | null>();
    const [innerVisible, setInnerVisible] = useState(false);
    let innerHideTimeout = useRef<NodeJS.Timeout | null>();
    const [hide, setHide] = useState(false);
    const draggableId = `${dialogId}-drag`
    const localWidth = localStorage.getItem(`${draggableId}-width`)
    const localHeight = localStorage.getItem(`${draggableId}-height`)
    const initResizeWidth = keepDialogAlive && localWidth ? Number(localWidth) : -1;
    const initResizeHeight = keepDialogAlive && localHeight ? Number(localHeight) : 0;
    const DIALOG_SCALE_HEIGHT = (isEnlargeFullScreen ? DIALOG_SCALE_HEIGHT_100 : DIALOG_SCALE_HEIGHT_95)
    const DIALOG_SCALE_WIDTH = (isEnlargeFullScreen ? DIALOG_SCALE_WIDTH_100 : DIALOG_SCALE_WIDTH_95)
    const [resizeWidth, setResizeWidth] = useState(initResizeWidth);
    const [resizeHeight, setResizeHeight] = useState(initResizeHeight);
    const [dragging, setDragging] = useState(false);
    const [draggableStyle, setDraggableStyle] = useState<AnyObj>({});

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)
    const resizeUpdate = (e: any) => {
      let h = e.target.innerHeight
      let w = e.target.innerWidth
      setScreenHeight(h)
      setScreenWidth(w)
    }
    const debounceResize = debounce(resizeUpdate, 50)
    useEffect(() => {
      let h = window.innerHeight
      let w = window.innerWidth
      setScreenHeight(h)
      setScreenWidth(w)
      window.addEventListener('resize', debounceResize)
      return () => {
        window.removeEventListener('resize', debounceResize)
      }
    }, [])
    // ========================= Refs =========================
    React.useImperativeHandle(ref, () => ({
      getElement: () => containerRef.current,
      getContentElement: () => contentRef.current,
    }));

    useEffect(() => {
      const dragRef = contentRef.current;
      if (visible) {
        setHide(false);
        if (closeAnimation) setInnerVisible(visible);
        if (!closeAnimation && innerVisibleTimeout && !innerVisibleTimeout.current && !innerVisible) {
          innerVisibleTimeout.current = setTimeout(() => {
            setInnerVisible(visible);
            if (innerVisibleTimeout.current) clearTimeout(innerVisibleTimeout.current);
            innerVisibleTimeout.current = null;
          }, 500);
        }
        if (resetDragPosition) {
          if (dragRef && !resizeObserverNode) {
            resizeObserverNode = new ResizeObserver(() => {
              const { reset, onDragStop } = draggableInstance
              reset();
              onDragStop();
            });
            resizeObserverNode?.observe(dragRef);
          }
        }
      } else {
        setInnerVisible(false);
        if (closeAnimation) setHide(true);
        if (!closeAnimation) {
          innerHideTimeout.current = setTimeout(() => {
            setHide(true);
            if (innerHideTimeout.current) clearTimeout(innerHideTimeout.current);
            innerHideTimeout.current = null;
          }, 500);
        }
        if (dragRef && resizeObserverNode) {
          resizeObserverNode?.unobserve(dragRef);
          resizeObserverNode = null
        }
      }
    }, [visible, innerVisible]);



    const parseParam = useCallback((value: number | string): number => {
      if (isNumber(value)) return value as number;
      if (typeof value === 'string') {
        if (value.indexOf('px') > -1) return parseInt(value.replace('px', ''))
        if (value.indexOf('%') > -1) return parseInt(value.replace('%', '')) / 100 * screenHeight
      }
      return 0
    }, [])

    const judgeSize = useCallback((value: number | string, type: 'WIDTH' | 'HEIGHT') => {
      const max = DIALOG_MIDDLE_MAX_COEFFICIENT
      const min = type === 'WIDTH' ? DIALOG_MIDDLE_MIN_WIDTH : DIALOG_MIDDLE_MIN_HEIGHT
      const def = type === 'WIDTH' ? DIALOG_MIDDLE_DEFAULT_WIDTH_COEFFICIENT : DIALOG_MIDDLE_DEFAULT_HEIGHT_COEFFICIENT
      const screenSize = type === 'WIDTH' ? screenWidth : screenHeight
      let size: number | string = def * screenSize;
      if (typeof value === 'number' || isNumber(value)) {
        size = (value as number) > screenSize * max ? screenSize * max : (value as number) < min ? min : value
      } else {
        if (value.indexOf('%') > -1) {
          let num = parseInt(value.replace('%', '')) / 100
          size = num > max ? screenSize * max : num * screenSize < min ? min : num * screenSize
        }
        if (value.indexOf('px') > -1) {
          let num = parseInt(value.replace('px', ''))
          size = num > screenSize * max ? screenSize * max : num < min ? min : num
        }
      }
      return size + 'px'
    }, [screenHeight, screenWidth]);

    /* -------------------  left/right/top/bottom定位 ------------------- */
    const contentWidth = useMemo(() => {
      if (ignoreCalculationContainer && placement === 'middle') return width || `${DIALOG_MIDDLE_DEFAULT_WIDTH_COEFFICIENT * screenWidth}px`
      if (placement !== 'middle') return '100%';
      if (innerScale) return DIALOG_SCALE_WIDTH;
      if (width) return judgeSize(width, 'WIDTH')
      if (placement === 'middle') return `${DIALOG_MIDDLE_DEFAULT_WIDTH_COEFFICIENT * screenWidth}px`
    }, [ignoreCalculationContainer, placement, width, screenWidth, innerScale, DIALOG_SCALE_WIDTH, judgeSize]);

    const contentHeight = useMemo(() => {
      if (ignoreCalculationContainer && placement === 'middle') return height
      if (placement !== 'middle') return '100%';
      if (innerScale) return DIALOG_SCALE_HEIGHT;
      if (height) return judgeSize(height, 'HEIGHT')
      // if (placement === 'middle') return `${DIALOG_MIDDLE_DEFAULT_HEIGHT_COEFFICIENT * screenHeight}px`
      // if (scale) return `${DIALOG_DEFAULT_WIDTH_MIDDLE}px`; // 可放大/缩小，设置默认高度
    }, [ignoreCalculationContainer, placement, height, innerScale, DIALOG_SCALE_HEIGHT, judgeSize]);

    const contentPositionStyle = useMemo(() => {
      if (!placement || placement === 'middle') {
        const noCenterStyle = needSync('left', props) || needSync('right', props) ? { margin: 0 } : {}; // 自行定位需要去掉默认居中
        // if (innerScale) return { top: DIALOG_SCALE_TOP };
        if (needSync('left', props)) {
          if (!visible) return { bottom, ...noCenterStyle, marginLeft: left }; // middle动画统一处理成从顶部进入
          return { top, bottom, ...noCenterStyle, marginLeft: left };
        } else if (needSync('right', props)) {
          const tempRight = typeof right === 'number' ? `${right}px` : right;
          if (!visible) return { bottom, ...noCenterStyle, marginLeft: `calc(100% - ${tempRight} - ${contentWidth})` };
          return { top, bottom, ...noCenterStyle, marginLeft: `calc(100% - ${tempRight} - ${contentWidth})` };
        }
        /**
         * 默认top值：大屏默认100px，小屏(window.document.body.clientHeight < 600)默认20px。
         */
        // const defaultDialogTop =  window.document.body.clientHeight < 600 ? `${DIALOG_DEFAULT_MIN_TOP_MIDDLE}px` : `${DIALOG_DEFAULT_TOP_MIDDLE}px`;
        /**
         * 1.不设置height或者height默认为number类型，设置默认top为100/20px
         * 2.height类型为百分比的情况，暂未定标准默认top值，设置为0
         */
        // const defaultTop = !height || typeof height === 'number' ? defaultDialogTop : '0px';
        // const defaultTop = contentHeight ? screenHeight / 2 - Number(contentHeight?.replace('px', '')) / 2 : screenHeight * 0.05
        // const defaultTop = 0;
        return { ...noCenterStyle };
      }
      return {};
    }, [placement, props, visible, bottom, left, top, right, contentWidth]);

    const limitStyle = useMemo(() => {
      if (ignoreCalculationContainer) {
        return {
          top
        }
      }
      if (!placement || placement === 'middle') {
        return {
          minHeight: `${DIALOG_MIDDLE_MIN_HEIGHT}px`,
          minWidth: `${DIALOG_MIDDLE_MIN_WIDTH}px`,
          maxHeight: innerScale ? DIALOG_SCALE_HEIGHT : top || top === 0 ? screenHeight - 2 * parseParam(top) : `${DIALOG_MIDDLE_MAX_COEFFICIENT * 100}%`,
          maxWidth: innerScale ? DIALOG_SCALE_WIDTH : `${DIALOG_MIDDLE_MAX_COEFFICIENT * 100}%`,
          overflow: 'hidden',
          top: innerScale ? '' : top
        }
      }
    }, [ignoreCalculationContainer, placement, top, innerScale, DIALOG_SCALE_HEIGHT, screenHeight, parseParam, DIALOG_SCALE_WIDTH])
    /* -------------------  middle 基于wrap定位, width/height 作用于 content ------------------- */

    const wrapHeight = useMemo(() => {
      if (placement !== 'middle') {
        if (innerScale && visible) return DIALOG_SCALE_HEIGHT;
        if (height) return typeof height === 'number' ? `${height}px` : height;
        if (placement === 'left' || placement === 'right') {
          let subTop = '', subBottom = '';
          if (top) {
            subTop = typeof top === 'number' ? `${top}px` : top;
          }
          if (bottom) {
            subBottom = typeof bottom === 'number' ? `${bottom}px` : bottom;
          }
          return `calc(100% - ${subTop || '0px'} - ${subBottom || '0px'})`;
        }
        if (scale) return '300px'; // top/bottom 可放大/缩小，设置默认高度
      }
    }, [placement, innerScale, visible, DIALOG_SCALE_HEIGHT, height, scale, top, bottom]);

    const wrapWidth = useMemo(() => {
      if (placement !== 'middle') {
        if (innerScale && visible) return DIALOG_SCALE_WIDTH;
        if (width) return typeof width === 'number' ? `${width}px` : width;
        if (placement === 'top' || placement === 'bottom') {
          const tempLeft = typeof left === 'number' ? `${left}px` : left;
          const tempRight = typeof right === 'number' ? `${right}px` : right;
          if (left) return `calc(100% - ${tempLeft})`;
          else if (right) return `calc(100% - ${tempRight}px)`;
          return '100%';
        }
        return '50%';
      }
    }, [placement, innerScale, visible, DIALOG_SCALE_WIDTH, width, left, right]);

    const wrapPositionStyle = useMemo(() => {
      if (innerScale && visible && placement !== 'middle') {
        if (placement === 'right') return { top: DIALOG_SCALE_TOP, right: DIALOG_SCALE_LEFT };
        return { top: DIALOG_SCALE_TOP, left: DIALOG_SCALE_LEFT };
      }
      /* ------------------------------  优化隐藏弹框动画效果 ----------------------------------------- */
      if (!innerScale && !visible) return placement === 'right' || placement === 'left' ? {
        top
      } : placement === 'top' || placement === 'bottom' ? {
        left
      } : {
      };
      /* ------------------------------------------------------------------------------------------- */
      if (!innerScale && placement !== 'middle') {
        if (placement === 'bottom' && top) {
          const tempTop = typeof top === 'number' ? `${top}px` : top;
          return scale ? {
            left, right,
            bottom: `calc(100% - ${tempTop} - ${wrapHeight})`,
          } : {
            top, left, right, bottom,
          }
        } else if (placement === 'right' && left) {
          const tempLeft = typeof left === 'number' ? `${left}px` : left;
          return scale ? {
            top, bottom,
            right: `calc(100% - ${tempLeft} - ${wrapWidth})`,
          } : {
            top, left, right, bottom,
          }
        }
        return { top, left, right, bottom }
      }
      return {}
    }, [visible, placement, innerScale, top, left, right, bottom, scale, wrapHeight, wrapWidth])
    const wrapStyle = { width: wrapWidth, height: wrapHeight, ...wrapPositionStyle, zIndex };
    const contentStyle = { width: contentWidth, height: contentHeight, ...contentPositionStyle, ...limitStyle };
    const contentClss = classnames(contentCls, {
      [`${prefixCls}-content-${placement}-fadeIn`]: !innerVisible && visible,
      [`${prefixCls}-content-closeAnimation`]: closeAnimation,
      [`${prefixCls}-content-middle-message`]: placement === 'middle' && message,
    });
    const wrapClss = classnames(wrapCls, {
      [`${prefixCls}-content-${placement}-fadeOut`]: !innerVisible && !visible,
    });

    // initWidth、initHeight为，resize拖拽的可拖范围

    const _initWidth = needSync('width', props) ? width : placement === 'middle' ? contentWidth : wrapWidth;
    const initWidth = useMemo(() => {
      return transWidth(_initWidth, placement);
    }, [_initWidth, placement])

    const _initHeight = needSync('height', props) ? height : placement === 'middle' ? contentHeight : wrapHeight;
    const initHeight = useMemo(() => {
      return transHeight(_initHeight, placement);
    }, [_initHeight, placement])

    const containerStyle = useMemo(() => {
      let maxHeight: string | number = '', minHeight: string = ''
      const footerHeight = footer ? footerStyle?.height ? parseParam(footerStyle.height) : 50 : 0  // 百分比情况不考虑
      if (placement === 'middle') {
        minHeight = '100px'
        maxHeight = innerScale ? screenHeight - 0.1 * screenHeight - footerHeight : screenHeight - 0.1 * screenHeight - 50 - footerHeight
      }
      if ((top || top === 0) && placement === 'middle') {
        maxHeight = innerScale ? screenHeight - 0.1 * screenHeight - footerHeight : screenHeight - parseParam(top) * 2 - 50 - footerHeight
      }
      if (ignoreCalculationContainer) return {}
      return { maxHeight, minHeight }
    }, [footer, footerStyle?.height, parseParam, placement, top, ignoreCalculationContainer, innerScale, screenHeight]);

    let resWrapStyle = useMemo(() => {
      if (resize && !innerScale) {
        if (placement === 'right' || placement === 'left') {
          if (typeof initWidth === 'number' && resizeWidth >= 0 && wrapWidth) {
            let tempWidth = placement === 'right' ? resizeWidth - initWidth : initWidth - resizeWidth;
            if (needRTL?.()) {
              tempWidth = placement === 'right' ? initWidth - resizeWidth : resizeWidth - initWidth;
            }
            const width = calcSize(wrapWidth,
              tempWidth,
              maxResizeWidth, minResizeWidth);
            return dragging ? {
              ...wrapStyle,
              transition: 'none',
              width,
            } : {
              ...wrapStyle,
              width,
            };
          }
          return wrapStyle;
        } else if (placement === 'top' || placement === 'bottom') {
          if (typeof initHeight === 'number' && resizeHeight) {
            let height = resizeHeight;
            const maxHeight = typeof maxResizeHeight === 'string' ? transPercentHeight(maxResizeHeight) : maxResizeHeight;
            const minHeight = typeof minResizeHeight === 'string' ? transPercentHeight(minResizeHeight) : minResizeHeight;
            if (placement === 'top') {
              if (maxHeight && height > maxHeight) height = maxHeight;
              if (minHeight && height < minHeight) height = minHeight;
              return dragging ? {
                ...wrapStyle,
                transition: 'none',
                height,
              } : {
                ...wrapStyle,
                height,
              };
            } else if (wrapHeight) {
              const height = calcHeight(wrapHeight, resizeHeight - initHeight, maxHeight, minHeight);
              return dragging ? {
                ...wrapStyle,
                transition: 'none',
                height,
              } : {
                ...wrapStyle,
                height,
              };
            }
          }
          return wrapStyle;
        }
      }
      return wrapStyle;
    }, [wrapStyle, resizeWidth, initWidth, placement]);

    const draggableCls = classnames({
      [`${prefixCls}-header-draggable`]: draggable,
      [`${prefixCls}-header-draggable-fullscreen`]: innerScale,
      [`${prefixCls}-header-hasRightIcon`]: closable || scale,
      [`${prefixCls}-header-draggable-title-top`]: !getTitleLabel?.(),
    })

    function changeResizeWidth(width: number) {
      setResizeWidth(width);
      keepDialogAlive && resWrapStyle?.width && localStorage.setItem(`${draggableId}-width`, width.toString());
      onResize?.(!placement || placement === 'middle' ? contentStyle : resWrapStyle, containerRef?.current);
    }

    function changeResizeHeight(height: number) {
      setResizeHeight(height);
      keepDialogAlive && resWrapStyle?.height && localStorage.setItem(`${draggableId}-height`, resWrapStyle?.height.toString())
      onResize?.(!placement || placement === 'middle' ? contentStyle : resWrapStyle, containerRef?.current);
    }

    function changeDragging(dragging: boolean) {
      setDragging(dragging);
    }

    function renderFooter() {
      const { prefixCls, footer, footerStyle, placement } = props;
      if (footer) {
        const footerCls = classnames(`${prefixCls}-footer ${prefixCls}-footer-${placement}`);
        return (<div className={footerCls}
          style={footerStyle}>
          {footer}
        </div>)
      }
      return null;
    }

    function onReady(data: DialogDraggableInstance) {
      draggableInstance = {
        ...data
      }
    }
    
    const onTitleClick = useCallback(()=>{
      dispatchEvent(EVENT_SET_RIGHTMENU_VISIBLE, { visible: false });
    },[])

    return (
      <div className={wrapClss} ref={containerRef} onClick={onCloseContent} style={{ ...resWrapStyle, ...innerWrapStyle, ...draggableStyle }} id={dialogId}>
        {customRenderInDialogWrap}
        <DialogDraggable weId={`${props.weId || ''}_nqqhog`}
          draggable={draggable}
          canDrag={canDrag}
          visible={visible}
          resize={resize}
          prefixCls={prefixCls}
          placement={placement}
          width={width}
          height={height}
          left={left}
          top={top}
          changeResizeWidth={changeResizeWidth}
          changeDragging={changeDragging}
          changeResizeHeight={changeResizeHeight}
          destroyOnClose={destroyOnClose}
          isStopPropagation={isStopPropagation}
          innerScale={!!innerScale}
          onReady={onReady}
          setDraggableStyle={placement !== 'middle' ? setDraggableStyle : undefined}
        >
          {
            ({ dragableStyle, resizeStyle, onMouseDown }) => {
              let styleFinal = !innerScale ?
                placement === 'middle' ?
                 { ...contentStyle, ...dragableStyle, ...resizeStyle } :
                 { ...contentStyle, ...resizeStyle } : 
                 contentStyle;
              if (!visible && hide) styleFinal = { ...styleFinal, display: 'none' };
              // if (containerRef && !innerScale) {
              //   const doc = containerRef?.current?.ownerDocument;
              //   const win = doc?.defaultView || window;
              //   const innerHeight = win?.document.body.clientHeight || 0;
              //   const { height: finalHeight, top: finalTop } = styleFinal;
              //   if (finalHeight && finalTop) {
              //     const nowHeight = typeof finalHeight === 'number' ? `${finalHeight}px` : finalHeight;
              //     const nowTop = typeof finalTop === 'number' ? `${finalTop}px` : finalTop;
              //     const heightTemp = parseInt(nowHeight.indexOf('%') >= 0 ? nowHeight.slice(0, nowHeight.length - 1) : nowHeight.slice(0, nowHeight.length - 2));
              //     const topTemp = parseInt(nowTop.indexOf('%') >= 0 ? nowTop.slice(0, nowTop.length - 1) : nowTop.slice(0, nowTop.length - 2));
              //     if (innerHeight && heightTemp + topTemp > innerHeight - 10) {
              //       styleFinal = {
              //         ...styleFinal, height: nowHeight.indexOf('%') >= 0 ?
              //           `calc(${nowHeight} - ${topTemp + heightTemp - innerHeight - 10}px` :
              //           `${innerHeight - 10 - topTemp}px`
              //       };
              //     }
              //   }
              // }
              const layoutCls = classnames(`${prefixCls}-layout`, {
                [`${prefixCls}-layout-ie`]: ua.browser === 'IE',
              })
              return (
                <div className={contentClss} style={styleFinal} ref={contentRef}>
                  {/* <MemoChildren weId={`${props.weId || ''}_bfzv2l`} shouldUpdate={visible}> */}
                  {
                    (!destroyOnClose || (destroyOnClose && visible)) ? (
                      <Layout weId={`${props.weId || ''}_pk6qdu`} className={layoutCls}>
                        { topExtraContent && (
                          <Layout.Box weId={`${props.weId || ''}_nee4hi`} className={`${prefixCls}-layout-outer-header`} type='header' outer {...topExtraContentProps}>
                            {typeof topExtraContent === 'function' ? topExtraContent?.() : topExtraContent }
                          </Layout.Box>
                        ) }
                        {
                          !noTitle && (
                            <Layout.Box weId={`${props.weId || ''}_xvdhuh`} className={`${prefixCls}-layout-header`} type="header">
                              {message}
                              <div
                                className={draggableCls}
                                onMouseDown={onMouseDown}
                                id={`${prefixCls}-header-draggable`}
                                onClick={onTitleClick}
                              >
                                <DialogHeader weId={`${props.weId || ''}_a0k28w`}
                                  {...props}
                                  doScale={doScale}
                                  innerScale={innerScale}
                                  setInnerScale={setInnerScale}
                                />
                              </div>
                            </Layout.Box>
                          )
                        }
                        { leftExtraContent && (
                          <Layout.Box weId={`${props.weId || ''}_u9n4yc`}  className={`${prefixCls}-layout-outer-left`} type='side' outer {...leftExtraContentProps}>
                            {typeof leftExtraContent === 'function' ? leftExtraContent?.() : leftExtraContent }
                          </Layout.Box>
                        ) }
                        <Layout.Box weId={`${props.weId || ''}_xvdhuh`} className={`${prefixCls}-layout-content`} type="content">
                          <div className={`${prefixCls}-body-container`} style={containerStyle}>
                            <div className={`${prefixCls}-body ${prefixCls}-body-${placement}`}
                              style={bodyStyle}
                            >
                              {props.children}
                            </div>
                          </div>
                        </Layout.Box>
                        { rightExtraContent && (
                          <Layout.Box weId={`${props.weId || ''}_y23cl6`} className={`${prefixCls}-layout-outer-right`} type='side' right outer {...rightExtraContentProps}>
                            {typeof rightExtraContent === 'function' ? rightExtraContent?.() : rightExtraContent }
                          </Layout.Box>
                        ) }
                        <Layout.Box weId={`${props.weId || ''}_xvdhuh`} className={`${prefixCls}-layout-footer`} type="footer">
                          {renderFooter()}
                        </Layout.Box>
                        { bottomExtraContent && (
                          <Layout.Box weId={`${props.weId || ''}_9z2dzc`} className={`${prefixCls}-layout-outer-footer`} type='footer' outer {...bottomExtraContentProps}>
                            {typeof bottomExtraContent === 'function' ? bottomExtraContent?.() : bottomExtraContent }
                          </Layout.Box>
                        ) }
                      </Layout>
                    ) : <div />
                  }
                  {/* </MemoChildren> */}
                </div>
              )
            }
          }
        </DialogDraggable>
      </div>
    )
  }
)

export default DialogContent;
