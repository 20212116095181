import ReactDOM from "react-dom";
import { DialogProps, MDialogProps } from "./types";
import Dialog from "./index";
import MDialog from "./index.m";

export interface CreateDialogProps extends DialogProps {
  weId?: string;
}
export function createDialog(props: CreateDialogProps) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function destroy() {
    const unmount = ReactDOM.unmountComponentAtNode(div);
    if (unmount && div.parentNode) {
      div.parentNode.removeChild(div);
    }
    props?.onClose?.();
  }
  ReactDOM.render(<Dialog weId={`${props.weId || ''}_2b7nh1`} closable visible {...props} onClose={destroy} />, div);

  return {
    destroy
  };
}

export function mCreateDialog(props: MDialogProps) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  function destroy() {
    const unmount = ReactDOM.unmountComponentAtNode(div);
    if (unmount && div.parentNode) {
      div.parentNode.removeChild(div);
    }
    props?.onClose?.();
  }
  ReactDOM.render(<MDialog weId={`${props.weId || ''}_2b7nh1`} visible {...props} onClose={destroy} />, div);

  return {
    destroy
  };
}
