
import Loadable from '../../react-loadable';
import { IconSelectionType, RichIconSelectionType } from './types';
import { getIconInfo } from './utils/util';

const IconSelection = Loadable({
  name: 'IconSelection', loader: () => import(
    /* webpackChunkName: 'ui_common_less' */
    './IconSelection')
}) as IconSelectionType;

IconSelection.defaultProps = {
  defaultValue: 1,
  bindTriggerContainer: true
}

/** RichIconSelection */
const RichIconSelection = Loadable({
  name: 'RichIconSelection', loader: () => import(
    /* webpackChunkName: 'ui_common_less' */
    './RichIconSelection/RichIconSelection')
}) as RichIconSelectionType;

RichIconSelection.defaultProps = {
  // defaultValue: 1,
  bindTriggerContainer: true
}

IconSelection.RichIconSelection = RichIconSelection;

IconSelection.getIconInfo = getIconInfo;

/** RichIconSelection 组件的类型声明 */
export type { RichIconSelectionProps, RichIconSelectionType, RichIconSelectionValueType } from './types'

/** IconSelection 组件的类型声明 */
export type { IconSelectionProps, IconSelectionType, } from './types';

export default IconSelection;
