import React, { Component, HTMLAttributes, PureComponent, ReactElement } from 'react';
import { classnames, eventEmitter, ua } from '@weapp/utils';
import ReactDOM from 'react-dom';
import { Point, PopupInnerRef, PopupPlacement, TriggerProps, TriggerState } from './types';
import TriggerContext from './Context';
import Popup from './popup/Popup';
import { addEventListenerWrap, contains, hideBodyOverflow, needSync, resetBodyOverflowCss, setWeappUiOverflowCss, closest, needRTL, getRandom } from '../../utils/index';
import { findDOMNode } from './utils/index';
import { AnyObj, Portal } from '../../lib';
import { POPUPPLACEMENT, triggerClsPrefix, uiAppName } from '../../constants/index';
import { Region, getRegion } from './utils/alignElement';
import { doAlign } from './popup/PopupInner';
import { EVENT_VISIBLE_CHANGE } from './constant/index';
import { dispatchEvent as _dispatchEvent } from '../../utils/index';

function returnDocument(element?: HTMLElement) {
  if (element) {
    return element.ownerDocument;
  }
  return window.document;
}
interface AddEventListerner {
  remove?: () => void;
}

class Trigger extends PureComponent<TriggerProps, TriggerState> {
  static contextType = TriggerContext;
  static displayName = 'Trigger';
  static doAlign = doAlign; // 支持外部调用定位方法

  static defaultProps = {
    prefixCls: triggerClsPrefix,
    popupPlacement: POPUPPLACEMENT,
    getDocument: returnDocument,
    defaultPopupVisible: false,
    action: ['hover'],
    showAction: [],
    hideAction: [],
    mouseEnterDelay: 0,
    mouseLeaveDelay: 0.1,
    mouseScrollRangeAccuracy: 10,
    followScroll: false,
    adaptivPositionHeightOffset: 0,
  }
  triggerRef = React.createRef<React.ReactInstance>();
  popupRef = React.createRef<PopupInnerRef>();
  delayTimer: number | null = null;
  clickOutsideHandler: AddEventListerner | null = null;
  hasPopupMouseDown: boolean | undefined;
  mouseDownTimeout: number | null = null;
  touchOutsideHandler: AddEventListerner | null = null;
  wheelChangeFunc: AddEventListerner | null = null;
  targetDomPosition?: { width: number; height: number; left: number; top: number; };
  triggerId: string = `trigger-${getRandom()}`;
  constructor(props: TriggerProps) {
    super(props);
    this.state = {
      prevPopupVisible: props.defaultPopupVisible || false,
      popupVisible: props.defaultPopupVisible || false,
    }
    setWeappUiOverflowCss();
  }

  componentDidMount() {
    if (this.props.defaultPopupVisible || this.props.popupVisible) {
      this.addOutsideHandler();
    }
    this.dispatchEvent(this.props.popupVisible || false, needSync('popupVisible', this.props));
    this.listenOuterEvent();
  }

  getSnapshotBeforeUpdate(preProps: TriggerProps) {
    const { forceUpdate, popupPlacement, cover } = this.props;
    const { forceUpdate: preForceUpdate } = preProps;
    /**
     * 支持参数配置强制重定位
     * forceUpdate由false变成true之后，重新定位一次，调用时机由使用方控制
     */
    if (!preForceUpdate && forceUpdate) {
      const popupDomContainer = this.popupRef.current?.getElement() || null;
      popupDomContainer && doAlign(popupDomContainer, this.getRootDomNode(), popupPlacement, needSync("getPopupContainer", this.props), cover);
    }
    if (preProps.popupVisible !== this.props.popupVisible) this.dispatchEvent(this.props.popupVisible || false, needSync('popupVisible', this.props));
  }

  componentDidUpdate() {
    const { popupVisible } = this.state;
    const { popupVisible: propPopupVisible } = this.props;
    if ('popupVisible' in this.props) {
      propPopupVisible ? this.addOutsideHandler() : this.clearOutsideHandler();
    } else if (popupVisible) {
      this.addOutsideHandler();
    } else if (!popupVisible) {
      this.clearOutsideHandler();
    }
  }
  componentWillUnmount() {
    this.dispatchEvent(false, needSync('popupVisible', this.props));
    this.clearOutsideHandler();
    this.unlistenOuterEvent();
  }

  // 判断相对定位
  isRelativePostionAlign = () => this.props?.getPopupContainer?.() ? true : false;
  // 跟随滚动
  isFollowScroll = () => this.props.followScroll || (this.isRelativePostionAlign() && needRTL?.());

  // 镜像处理
  getPopupPlacement = (_popupPlacement?: PopupPlacement) => {
    const { popupPlacement: propPopupPlacement } = this.props;
    const popupPlacement = _popupPlacement || propPopupPlacement;
    if (needRTL?.()) {
      switch (popupPlacement) {
        case 'left': return 'right';
        case 'right': return 'left';
        case 'bottomRight': return 'bottomLeft';
        case 'bottomLeft': return 'bottomRight';
        case 'topLeft': return 'topRight';
        case 'topRight': return 'topLeft';
        case 'leftTop': return 'rightTop';
        case 'rightTop': return 'leftTop';
        case 'leftBottom': return 'rightBottom';
        case 'rightBottom': return 'leftBottom';
        default: return popupPlacement;
      }
    }
    return popupPlacement;
  }

  // --------------------- RAF -------------------------------
  initWheelFunc = () => {
    const { getDocument, enableWheelEventEmitter, followScroll } = this.props;
    if (getDocument && enableWheelEventEmitter && !followScroll) {
      this.eventHandler(this.changeWheel)
    }
  }
  eventHandler = (fn: () => void) => {
    const { getDocument, enableWheelEventEmitter, followScroll } = this.props;
    if (getDocument) {
      let currentDocument: HTMLElement;
      currentDocument = getDocument(this.getRootDomNode()).body;
      if (!this.wheelChangeFunc && (this.isClickToHide() || this.isContextMenuToShow()) && !needSync('getPopupContainer', this.props)) {
        const wheelEvent = 'onwheel' in currentDocument ? 'wheel' : 'onmousewheel' in currentDocument ? 'mousewheel' : 'DOMMouseScroll';
        this.wheelChangeFunc = addEventListenerWrap(currentDocument, wheelEvent, fn);
        this.targetDomPosition = getRegion(this.getRootDomNode());
      }
    }
  }
  changeWheel = () => {
    const { mouseScrollRangeAccuracy = 10 } = this.props;
    const nowRegion = getRegion(this.getRootDomNode());
    const nowTop = nowRegion.top;
    const preTop = this.targetDomPosition?.top || 0;
    if (Math.abs(nowTop - preTop) >= mouseScrollRangeAccuracy) {
      this.setPopupVisible(false);
    }
  }
  clearWheelFunc = () => {
    this.wheelChangeFunc?.remove?.();
    this.wheelChangeFunc = null;
  }
  // ---------------------add/clear document click event-------------------------------
  addOutsideHandler = () => {
    const { getDocument } = this.props;
    if (getDocument) {
      let currentDocument: HTMLElement;
      currentDocument = getDocument(this.getRootDomNode()).body;
      if (!this.clickOutsideHandler && (this.isClickToHide() || this.isContextMenuToShow())) {
        this.clickOutsideHandler = addEventListenerWrap(currentDocument, 'mousedown', this.onDocumentClick);
      }
      if (!this.touchOutsideHandler) {
        this.touchOutsideHandler = addEventListenerWrap(currentDocument, 'touchstart', this.onDocumentClick);
      }
    }
    this.changeBodyOverflow(true); // 打开弹层
    this.initWheelFunc();
  }
  clearOutsideHandler = () => {
    if (this.clickOutsideHandler && this.clickOutsideHandler.remove) {
      this.clickOutsideHandler.remove();
      this.clickOutsideHandler = null;
    }
    if (this.touchOutsideHandler && this.touchOutsideHandler.remove) {
      this.touchOutsideHandler.remove();
      this.touchOutsideHandler = null;
    }
    this.changeBodyOverflow(false); // 关闭弹层
    this.clearWheelFunc();
  }
  listenOuterEvent = () => {
    // 监听外部组件的事件
    // 浏览按钮联想搜索框打开
    eventEmitter.on(uiAppName, 'emit_global_trigger_close', this.onBrowserEvent);
  }
  unlistenOuterEvent = () => {
    // 取消监听外部组件的事件
    // 浏览按钮联想搜索框打开
    eventEmitter.off(uiAppName, 'emit_global_trigger_close', this.onBrowserEvent);
  }
  onBrowserEvent = (e: any) => {
    const { usageScope } = this.props;
    const popupVisible = needSync('popupVisible', this.props) ? this.props.popupVisible : this.state.popupVisible;
    // 只针对部分场景关闭Trigger弹框：日期选择、下拉框
    if (popupVisible && usageScope) {
      this.delaySetPopupVisible(false, this.props.mouseLeaveDelay, e);
    }
  }
  onDocumentClick = (event: MouseEvent) => {
    let { mask, maskClosable, onDocumentClick, getPopupContainer } = this.props;
    if (mask && !maskClosable) return;
    const { target } = event;
    const root = this.getRootDomNode();
    // 仅mask关闭
    const closetPhotoViewPortal = closest(event.target, `div[id="PhotoView_Slider"]`);
    if (closetPhotoViewPortal) return;
    const maskElement = this.popupRef.current?.getMaskElement();
    const popupDomContainer = this.popupRef.current?.getElement() || null;

    if (this.inBlackList(event)) return;

    const element = getPopupContainer?.();
    if (((mask && target === maskElement) || (!mask)) && (!contains(root, target) || (element && target === maskElement)) && !contains(popupDomContainer, target) && !this.hasPopupMouseDown) {
      onDocumentClick?.(event)
      this.onMouseLeave(event);
    }
  }

  inBlackList = (event: MouseEvent) => {
    const { target } = event;
    const { blacklist: _blacklist, enableLocationCalculation } = this.props;
    const blacklist = _blacklist ? [..._blacklist, 'div[class~="paste"][id="paste"'] : ['div[class~="paste"][id="paste"]'];
    let flag = false;
    if (target && blacklist) {
      for (let i = 0; i < blacklist.length; i++) {
        const closestDom = closest(event.target, blacklist[i]);
        if (closestDom) {
          flag = true;
          break;
        }
      }
    }
    if (!enableLocationCalculation) return flag;
    // 启用位置计算方案
    const rootDom = this.getRootDomNode();
    const container = this.popupRef.current?.getElement?.();
    if (rootDom && container) {
      const xmin = Number.parseInt(container.style.left);
      const xmax = Number.parseInt(container.style.left) + container.clientWidth;
      const ymin = Number.parseInt(container.style.top);
      const ymax = Number.parseInt(container.style.top) + container.clientHeight;
      if (xmin < event.pageX && event.pageX < xmax && ymin < event.pageY && event.pageY < ymax) return true;
    }
    return flag;
  }

  changeBodyOverflow = (visible: boolean) => {
    const { mask } = this.props;
    if (mask) { // 有蒙层的时候，显示弹层，禁止body上的overflow
      if (visible) {
        hideBodyOverflow();
      } else {
        resetBodyOverflowCss();
      }
    }
  }
  // ----------------------------弹层定位----------------------------
  getRootDomNode = (): HTMLElement => {
    const { getTriggerDOMNode, getPopupContainer, target } = this.props;
    if (target) return target;
    const element = getPopupContainer?.();
    if (element && !needRTL?.()) {
      return element;
    }

    if (getTriggerDOMNode) {
      return getTriggerDOMNode(this.triggerRef.current);
    }

    try {
      if (this.triggerRef.current) {
        const domNode = findDOMNode<HTMLElement>(this.triggerRef.current);
        if (domNode) {
          return domNode;
        }
      }
    } catch (err) {
      // Do nothing
    }
    return ReactDOM.findDOMNode(this) as HTMLElement;
  }

  attachParent = (popupContainer: HTMLDivElement) => {
    const { getPopupContainer, getDocument } = this.props;
    let mountNode: HTMLElement;
    if (!getPopupContainer && getDocument) {
      mountNode = getDocument(this.getRootDomNode()).body;
      mountNode.appendChild(popupContainer);
    } else if (getPopupContainer && getDocument) {
      mountNode = getPopupContainer() && !needRTL?.() ? getPopupContainer() || getDocument(this.getRootDomNode()).body : getDocument(this.getRootDomNode()).body;
      mountNode.appendChild(popupContainer);
    }
  }
  isEnableFixedPosition = () => {
    return this.props.enableFixedPosition && !needSync("getPopupContainer", this.props);
  }
  getContainer = () => {
    const { getDocument } = this.props;
    const enableFixedPosition = this.isEnableFixedPosition();
    let popupContainer: any = undefined;
    if (getDocument) {
      popupContainer = getDocument(this.getRootDomNode()).createElement('div');
      if (!enableFixedPosition) {
        popupContainer.style.position = 'absolute';
        popupContainer.style.top = '0';
        popupContainer.style.left = '0';
        popupContainer.style.width = '100%';
      }
      /* 解决镜像，Trigger首次打开弹层定位不准确的问题 */
      if (needRTL?.()) {
        popupContainer.style.display = 'none';
        const needRTLTimeOut = setTimeout(() => {
          popupContainer.style.display = 'block';
          clearTimeout(needRTLTimeOut);
        }, 0);
      }
      this.attachParent(popupContainer);
    }
    return popupContainer;
  };
  getPopupTransitionName = () => {
    const { popupPlacement, popupTransitionName, closeAnimation } = this.props;
    if (closeAnimation) return '';
    if (popupTransitionName) return popupTransitionName;
    if (!popupPlacement || ['bottom, bottomLeft', 'bottomRight'].indexOf(popupPlacement) >= 0) {
      return 'slide-up';
    }
    if (['top', 'topLeft', 'topRight'].indexOf(popupPlacement) >= 0) {
      return 'slide-down';
    }
    if (['left', 'leftTop', 'leftBottom'].indexOf(popupPlacement) >= 0) {
      return 'slide-right';
    }
    if (['right', 'rightTop', 'rightBottom'].indexOf(popupPlacement) >= 0) {
      return 'slide-left';
    }
    return 'slide-up';
  }
  onAdjustedPlacement = (placement: PopupPlacement, sourceRegion: Region) => {
    const { onAdjustedPlacement } = this.props;
    onAdjustedPlacement?.(placement, this.getRootDomNode(), sourceRegion);
  }
  getComponent = () => {
    const { popupVisible, point } = this.state;
    const { prefixCls, popup, popupClassName, popupId, popupStyle, popupVisible: propPopupVisible,
      zIndex, popupPlacement, forceRender, mask, maskStyle, noAlign, closeAnimation, cover,
      needAnimateHeight, animateHeightProps, followScroll, adaptivPositionHeightOffset,
      childrenHoverBlacklist, stopPopupPropagation, coverOffset,
    } = this.props;
    const enableFixedPosition = this.isEnableFixedPosition();
    const mouseProps: HTMLAttributes<HTMLElement> = {};
    if (this.isMouseEnterToShow()) {
      mouseProps.onMouseEnter = this.onMouseEnter;
    }
    if (this.isMouseLeaveToHide()) {
      mouseProps.onMouseLeave = this.onMouseLeave;
    }
    mouseProps.onMouseDown = this.onPopupMouseDown;
    mouseProps.onTouchStart = this.onPopupMouseDown;
    return (
      <Popup weId={`${this.props.weId || ''}_xk2rxw`}
        ref={this.popupRef}
        prefixCls={prefixCls}
        visible={'popupVisible' in this.props ? propPopupVisible : popupVisible}
        point={point}
        getRootDomNode={this.getRootDomNode}
        className={popupClassName}
        popupId={popupId || this.triggerId}
        style={popupStyle}
        zIndex={zIndex}
        popupPlacement={popupPlacement}
        popupTransitionName={this.getPopupTransitionName()}
        forceRender={forceRender}
        mask={mask}
        maskStyle={maskStyle}
        noAlign={noAlign}
        relativePostionAlign={this.isRelativePostionAlign() && !needRTL?.()}
        enableFixedPosition={enableFixedPosition}
        closeAnimation={closeAnimation}
        cover={cover}
        coverOffset={coverOffset}
        onAdjustedPlacement={this.onAdjustedPlacement}
        needAnimateHeight={needAnimateHeight}
        animateHeightProps={animateHeightProps}
        {...mouseProps}
        followScroll={followScroll}
        adaptivPositionHeightOffset={adaptivPositionHeightOffset}
        childrenHoverBlacklist={childrenHoverBlacklist}
        stopPopupPropagation={stopPopupPropagation}
      >
        {typeof popup === 'function' ? popup() : popup}
      </Popup>
    )
  }
  // ----------------------------判断----------------------------
  isClickToShow() {
    const { action, showAction } = this.props;
    return (
      (action && action.indexOf('click') !== -1) || (showAction && showAction.indexOf('click') !== -1)
    );
  }

  isContextMenuToShow() {
    const { action, showAction } = this.props;
    return (
      (action && action.indexOf('contextMenu') !== -1) ||
      (showAction && showAction.indexOf('contextMenu') !== -1)
    );
  }

  isClickToHide() {
    const { action, hideAction } = this.props;
    return (
      (action && action.indexOf('click') !== -1) || (hideAction && hideAction.indexOf('click') !== -1)
    );
  }

  isMouseEnterToShow() {
    const { action, showAction } = this.props;
    return (
      (action && action.indexOf('hover') !== -1) ||
      (showAction && showAction.indexOf('mouseEnter') !== -1)
    );
  }

  isMouseLeaveToHide() {
    const { action, hideAction } = this.props;
    return (
      (action && action.indexOf('hover') !== -1) ||
      (hideAction && hideAction.indexOf('mouseLeave') !== -1)
    );
  }

  isFocusToShow() {
    const { action, showAction } = this.props;
    return (
      (action && action.indexOf('focus') !== -1) || (showAction && showAction.indexOf('focus') !== -1)
    );
  }

  isBlurToHide() {
    const { action, hideAction } = this.props;
    return (
      (action && action.indexOf('focus') !== -1) || (hideAction && hideAction.indexOf('blur') !== -1)
    );
  }

  // ============================== Visible Handlers ==============================
  onPopupMouseDown = (...args: any) => {
    this.hasPopupMouseDown = true;

    if (this.mouseDownTimeout) clearTimeout(this.mouseDownTimeout);
    this.mouseDownTimeout = window.setTimeout(() => {
      this.hasPopupMouseDown = false;
    }, 0);

    if (this.context) {
      this.context.onPopupMouseDown(...args);
    }
  }
  /* 自定义事件监听 */
  /**
   * 
   * @param visible 弹层是否展示
   * @param enable 启用自定义事件
   * @returns 
   */
  dispatchEvent = (visible: boolean, enable?: boolean) => {
    if (!this.props.enableEventEmitter) return;
    if (!enable) return;
    // const { prefixCls } = this.props;
    // 弹层内移动不触发自定义事件
    // const inPopupDomContainer = closest(event?.target, `div[class="${prefixCls}-popupInter-container"]`);
    // if (inPopupDomContainer) return;
    _dispatchEvent(EVENT_VISIBLE_CHANGE, { visible });
  }
  onMouseEnter = (e: any) => {
    const { mouseEnterDelay } = this.props;
    this.delaySetPopupVisible(
      true,
      mouseEnterDelay,
      e,
    );
  };

  onMouseLeave = (e: any) => {
    if (this.inBlackList(e)) return;
    this.delaySetPopupVisible(false, this.props.mouseLeaveDelay, e);
  }

  onClick = (event: any) => {
    /* 解决问题现象：高级搜索简易模式，弹出层checkbox取消选中失效 */
    /* 原因：checkbox中的input，先触发onClick、再触发onChange事件, 
            而onClick事件触发后，会走到Trigger内部的click事件中，
            阻止冒泡即阻断了checkbox的onChange事件，导致受控失效 */
    /* 解决方案： 新增prohibitPreventDefault参数，高级搜索中设置，减少影响范围 */
    const { prohibitPreventDefault, noClickClose } = this.props;
    if (
      !prohibitPreventDefault &&
      this.isClickToShow() &&
      (this.isClickToHide() || this.isBlurToHide()) &&
      event &&
      event.preventDefault
    ) {
      event.preventDefault();
    }
    const nextVisible = 'popupVisible' in this.props ? !this.props.popupVisible : !this.state.popupVisible;
    if (
      (!noClickClose && this.isClickToHide() && !nextVisible) ||
      (nextVisible && this.isClickToShow())
    ) {
      this.dispatchEvent(nextVisible, !needSync('popupVisible', this.props));
      this.setPopupVisible(nextVisible, event);
    }
  }

  clearDelayTimer() {
    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
      this.delayTimer = null;
    }
  }

  delaySetPopupVisible = (visible: boolean, delayS?: number, event?: MouseEvent) => {
    const delay = delayS ? delayS * 1000 : 100;
    this.clearDelayTimer();
    const point = event ? { pageX: event.pageX, pageY: event.pageY } : { pageX: 0, pageY: 0 };
    if (delay) {
      this.delayTimer = window.setTimeout(() => {
        this.dispatchEvent(visible, !needSync('popupVisible', this.props));
        this.setPopupVisible(visible, point, true);
        this.clearDelayTimer();
      }, delay);
    } else {
      this.dispatchEvent(visible, !needSync('popupVisible', this.props));
      this.setPopupVisible(visible, point, true);
    }
  }

  setPopupVisible = (popupVisible: boolean, point?: { pageX: number; pageY: number }, definedProps?: boolean) => {
    const { onPopupVisibleChange, popupVisible: propPopupVisible } = this.props;

    if (typeof onPopupVisibleChange === 'function') onPopupVisibleChange(definedProps ? popupVisible : !propPopupVisible);

    const { popupVisible: prevPopupVisible } = this.state;
    if (prevPopupVisible !== popupVisible) {
      if (!('popupVisible' in this.props)) {
        this.setState({ popupVisible, prevPopupVisible });
      }
    }
    if (popupVisible && point) {
      this.setPoint(point);
    }
  }

  // 更新弹层位置
  setPoint = (point: Point) => {
    this.setState({
      point: {
        pageX: point.pageX,
        pageY: point.pageY,
      },
    });
  }

  handlePortalUpdate = () => { }
  triggerContext = {
    onPopupMouseDown: this.onPopupMouseDown
  }
  render() {
    const { popupVisible } = this.state;
    const { prefixCls, className, children, customRender, popupVisible: propPopupVisible, forceRender, target, prohibitAddTargetEvents, inFloatButton, destroyOnClose } = this.props;
    const nowChild = React.isValidElement(children) ? children : <span>{children}</span>
    const child = React.Children.only(nowChild) as React.ReactElement;
    const newChildProps: HTMLAttributes<HTMLElement> & { key: string } = {
      key: 'trigger',
    }
    // ============================== Visible Handlers ==============================
    const isMobile = (ua.device === "Mobile")
    // // >>> ContextMenu
    // if (this.isContextMenuToShow()) {
    //   newChildProps.onContextMenu = this.onContextMenu;
    // }
    // // >>> Click
    if (this.isClickToHide() || this.isClickToShow()) {
      if (!inFloatButton) {
        newChildProps.onClick = this.onClick;
      } else {
        // if (!isMobile) {
        //   newChildProps.onClick = this.onClick;
        // } else {
        //   newChildProps.onTouchStart = this.onClick;
        // }
        newChildProps.onClick = this.onClick;
      }
    }

    // >>> Hover(enter)
    if (this.isMouseEnterToShow()) {
      if (!inFloatButton) {
        newChildProps.onMouseEnter = this.onMouseEnter;
      } else {
        if (!isMobile) {
          newChildProps.onMouseEnter = this.onMouseEnter;
        } else {
          newChildProps.onTouchStart = this.onMouseEnter;
        }
      }
    }

    // >>> Hover(leave)
    if (this.isMouseLeaveToHide()) {
      if (!inFloatButton) {
        newChildProps.onMouseLeave = this.onMouseLeave;
      } else {
        if (!isMobile) {
          newChildProps.onMouseLeave = this.onMouseLeave;
        } else {
          newChildProps.onTouchEnd = this.onMouseLeave;
        }
      }
    }

    if (target && !prohibitAddTargetEvents) {
      target.onmouseenter = this.onMouseEnter;
      target.onmouseleave = this.onMouseLeave;
    }
    // =================================== Render ===================================
    const childrenClassName = classnames(
      child && child.props && child.props.className,
      {
        [`${prefixCls}-childrenClassName`]: needSync("getPopupContainer", this.props)
      },
      className,
    );
    if (childrenClassName) {
      newChildProps.className = childrenClassName;
    }
    const cloneProps: any = {
      ...newChildProps
    };
    const trigger = React.cloneElement(child, cloneProps);
    const visible = 'popupVisible' in this.props ? propPopupVisible : popupVisible;
    let portal: ReactElement | null = null;
    const showPortal = destroyOnClose ? visible || forceRender : (visible || this.popupRef.current || forceRender);
    if (showPortal) {
      portal = (
        <Portal weId={`${this.props.weId || ''}_m3vnqn`}
          key="portal"
          getContainer={this.getContainer}
          didUpdate={this.handlePortalUpdate}>
          {this.getComponent()}
        </Portal>
      )
    }
    const dom = (
      <TriggerContext.Provider weId={`${this.props.weId || ''}_5r7uyv`}
        value={this.triggerContext}
      >
        {!target && trigger}
        {portal}
      </TriggerContext.Provider>
    )
    return customRender ? customRender(this, dom) : dom;
  }
}
export default Trigger;