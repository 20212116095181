import Loadable from '../../react-loadable';
import { associateListClsPrefix } from '../../constants/index';
import { AssociateListType } from './types';

const AssociateList = Loadable({name:'AssociateList', loader: () => import(
  /* webpackChunkName: "ui_common_small" */
  './AssociateList') }) as AssociateListType;

AssociateList.defaultProps = {
  prefixCls: associateListClsPrefix,
};

export type { AssociateListProps } from './types';

export default AssociateList;
