import Loadable from '../../react-loadable';
import { getVideoType } from './utils';
import { MVideoType } from './types';
import showVideo from './showVideo';
import showMVideo from './showVideo.m'
import { ua } from '@weapp/utils'

const MVideo = Loadable({
  name: 'MVideo', loader: () => import(
    /* webpackChunkName: "ui_m_common_other" */
    './Video.m')
}) as MVideoType;

MVideo.showVideo = ua.device === 'PC' ? showVideo : showMVideo
MVideo.getVideoType = getVideoType;

export type { MVideoProps } from './types';

export default MVideo;
