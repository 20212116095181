const ss = {
  getItem(key: string, dftVal?: any) {
    const item = sessionStorage.getItem(key);

    try {
      if (item) {
        return JSON.parse(item);
      }
      return dftVal;
    } catch {
      return item;
    }
  },
  setItem(key: string, value: any) {
    const val = typeof value === "string" ? value : JSON.stringify(value);

    sessionStorage.setItem(key, val);
  },
  removeItem(key: string) {
    sessionStorage.removeItem(key);
  },
};

export default ss;
