import { richTextClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { unMatchSensitiveWords, matchSensitiveWords } from './utils/sensitiveWordsMatch';
import { RichTextType } from './types';

const RichText = Loadable({ name: 'RichText', loader: () => import(
  /* webpackChunkName: "rich-text" */
  './components/RichText') }) as RichTextType;

RichText.defaultProps = {
  prefixCls: richTextClsPrefix,
};

RichText.matchSensitiveWords = matchSensitiveWords;
RichText.unMatchSensitiveWords = unMatchSensitiveWords;

export type { RichTextProps, RichTextType, ExtendItemConfig as RichTextExtendItemConfig, PluginData as RichTextPluginData } from './types';

export default RichText;
