import Loadable from '../../react-loadable';
import { SwitchType } from './types';
import { mSwitchClsPrefix } from '../../constants/index.m';

const MSwitch = Loadable({
  name: 'MSwitch', loader: () => import(
    /* webpackChunkName: 'ui_form' */
    './Switch')
}) as SwitchType;

MSwitch.defaultProps = {
  prefixCls: mSwitchClsPrefix,
  size: 'lg',
  isMobile: true
};

export type { SwitchProps as MSwitchProps, SwitchType as MSwitchType, SwitchChangeEventHandler as MSwitchChangeEventHandler, SwitchClickEventHandler as MSwitchClickEventHandler } from './types';

export default MSwitch;
