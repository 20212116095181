import Loadable from '../../react-loadable';
import { mapClsPrefix } from '../../constants/index';
import { MapType } from './types';

const Map = Loadable({
  name: 'Map', loader: () => import(
    /* webpackChunkName: "ui_common_small" */
    './components/Map')
}) as MapType;

Map.defaultProps = {
  prefixCls: mapClsPrefix,
};

export type { MapProps, MapDialogLayoutType, mapType as MapType } from './types';

export default Map;

