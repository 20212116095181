import { ua } from '@weapp/utils';
import Loadable from '../../react-loadable';
import { TriggerProp } from './types';
import { triggerClsPrefix, POPUPPLACEMENT } from '../../constants/index';
import { EVENT_VISIBLE_CHANGE } from './constant/index';
import _Trigger from './Trigger';
import { needRTL } from '../../utils';

const Trigger = Loadable({
  name: 'Trigger',
  component: _Trigger,
  // loader: () => import(
  //  /* webpackChunkName: "trigger" */
  // './Trigger')
}) as TriggerProp;

Trigger.defaultProps = {
  prefixCls: triggerClsPrefix,
  popupPlacement: POPUPPLACEMENT,
  destroyOnClose: false, //无法全局开启，有特殊业务写法，需要在关闭弹层后依旧保留弹层内容(比如关闭弹层，打开浏览框，浏览框写在弹层内部场景)
  closeAnimation: false,
  enableFixedPosition: ua.browser === 'IE',
}

Trigger.EVENT_VISIBLE_CHANGE = EVENT_VISIBLE_CHANGE;
export { getRegion as triggerGetRegion } from './utils/alignElement';

export type { Region as TriggerReginType } from './utils/alignElement';

export type { TriggerProps, PopupPlacement, ActionType, TriggerConfig } from './types';

export default Trigger;
