import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import { classnames, eventEmitter, ua } from '@weapp/utils';
import { PopupInnerRef, PopupPlacement, PopupProps } from '../types';
import { getAlignByPlacement } from '../utils/index';
import { alignElement, getRegion } from '../utils/alignElement';
import AnimateHeight from '../../animate-height/index';
import { EASE_IN_OUT, uiAppName } from '../../../constants/index';
import { needRTL } from '../../../utils';
import { AnyObj } from '../../../types/common';
import { closest } from '../../../utils/index'
/**
 * doAlign 定位方法
 * @param source 弹层容器(真实dom节点)
 * @param target 定位目标容器(真实dom节点)
 * @param popupPlacement 弹层位置
 * @param relativePostionAlign 相对定位标记: 设置getPopupContainer属性的走相对定位或自定义相对定位
 * @param cover 弹出层覆盖内容
 * @returns nowPopupPlacement 当前定位方向(自适应调整后的实际定位方向)
 */
export const doAlign = (source: HTMLElement | null, target: HTMLElement | null, popupPlacement?: PopupPlacement, relativePostionAlign?: boolean, cover?: boolean, otherParams?: AnyObj) => {
  const otherAlignOps = getAlignByPlacement(popupPlacement, relativePostionAlign && !otherParams?.enableFixedPosition);
  const nowPopupPlacement = alignElement(source, target, {
    popupPlacement,
    points: ['cl', 'dt', '', ''],
    ...otherAlignOps,
  }, relativePostionAlign, cover, otherParams);
  return nowPopupPlacement as PopupPlacement;
}
const getTransitionCls = (popupTransitionName?: string | object, prefixCls?: string, visible?: boolean, innerVisible?: boolean, isIE?: boolean) => {
  if (innerVisible || isIE) return '';
  let classname = '';
  if (popupTransitionName) {
    classname = `${prefixCls}-${popupTransitionName} `;
    classname += visible ? `${prefixCls}-${popupTransitionName}-enter` : `${prefixCls}-${popupTransitionName}-leave`;
  }
  return classname;
}
const PopupInner = memo(React.forwardRef<PopupInnerRef, PopupProps>(
  (props, ref) => {
    let { prefixCls, getRootDomNode, visible, forceRender,
      className, popupId, style, zIndex, popupPlacement, popupTransitionName,
      onMouseEnter, onMouseLeave, mask, maskStyle, noAlign, closeAnimation,
      relativePostionAlign, cover, onAdjustedPlacement, needAnimateHeight: propNeedAnimateHeight, animateHeightProps, followScroll,
      adaptivPositionHeightOffset, enableFixedPosition,
      childrenHoverBlacklist, coverOffset
    } = props;
    const needAnimateHeight = popupPlacement && ['bottom', 'bottomLeft', 'bottomRight'].indexOf(popupPlacement) >= 0 ? propNeedAnimateHeight : false; // 仅向下展开才处理height动画
    const [innerVisible, setInnerVisible] = useState(false);
    const [timeOutVis, setTimeOutVis] = useState(closeAnimation ? true : false);
    const containerRef = React.createRef<HTMLDivElement>();
    const childrenRef = useRef(null);
    const maskRef = useRef(null);
    const innerVisibleTimeout = useRef<NodeJS.Timeout | null>(null);
    const isIE = false; // 使用transform方案，代码保留，暂不启用
    // const isIE = ua.browser === 'IE';
    if (visible && !closeAnimation && !timeOutVis && !forceRender) {
      const delayTime = needRTL?.() ? 0 : 100;
      const timeout = setTimeout(() => {
        setTimeOutVis(true);
        clearTimeout(timeout);
      }, delayTime);
    }
    // no.2862083 【e10】修复处理在侧滑框模式下help组件被意外触发的问题
    const onInnerMouseEnter = useCallback((e: any) => {
      let flag = true;
      if (e?.target && childrenHoverBlacklist) {
        for (let i = 0; i < childrenHoverBlacklist.length; i++) {
          const closestDom = closest(e.target, childrenHoverBlacklist[i]);
          if (closestDom) {
            flag = false;
            break;
          }
        }
      }
      if (flag) onMouseEnter?.(e)
    }, [childrenHoverBlacklist, onMouseEnter])
    // no.2862083 【e10】修复处理在侧滑框模式下help组件被意外触发的问题
    const onInnerMouseLeave = useCallback((e: any) => {
      let flag = true;
      if (e?.target && childrenHoverBlacklist) {
        for (let i = 0; i < childrenHoverBlacklist.length; i++) {
          const closestDom = closest(e.target, childrenHoverBlacklist[i]);
          if (closestDom) {
            flag = false;
            break;
          }
        }
      }
      if (flag) onMouseLeave?.(e)
      onMouseLeave?.(e)
    }, [childrenHoverBlacklist, onMouseLeave])

    const transitionCls = closeAnimation ? '' : getTransitionCls(popupTransitionName, prefixCls, visible, innerVisible, isIE);
    const rootDom = getRootDomNode();
    const handleCorrectedPosition = useCallback(() => {
      const nowPopupPlacement = doAlign(containerRef.current, rootDom, popupPlacement, relativePostionAlign, cover, { adaptivPositionHeightOffset, enableFixedPosition, popupId, coverOffset });
      if (containerRef.current && rootDom) {
        const sourceRegion = getRegion(containerRef.current);
        onAdjustedPlacement?.(nowPopupPlacement, sourceRegion);
      }
    }, [containerRef, rootDom, popupPlacement, relativePostionAlign, cover, adaptivPositionHeightOffset, enableFixedPosition, popupId, coverOffset, onAdjustedPlacement])
    const handleScroll = useCallback(() => {
      handleCorrectedPosition()
    }, [handleCorrectedPosition])
    useEffect(() => {
      if (followScroll && visible) {
        eventEmitter.on(uiAppName, 'emit_global_popup_scroll', handleScroll);
        window.addEventListener('scroll', handleScroll, true)
      }
      return () => {
        eventEmitter.off(uiAppName, 'emit_global_popup_scroll', handleScroll);
        window.removeEventListener('scroll', handleScroll, true)
      }
    }, [followScroll, handleScroll, visible])
    useEffect(() => {
      if (visible) {
        if (!noAlign) {
          const alignTimeout = setTimeout(() => {
            handleCorrectedPosition()
            alignTimeout && clearTimeout(alignTimeout);
          }, 0); // 外层元素存在float/display 样式，会引起重排，导致doAlign中getBoundingClientRect获取的元素宽高不一定准确。
        }
      } else {
        setInnerVisible(false);
      }
    }, [visible, noAlign, handleCorrectedPosition]);
    useEffect(() => {
      if (visible) {
        if (closeAnimation) setInnerVisible(true);
        if (!closeAnimation && !innerVisibleTimeout.current && !innerVisible) {
          innerVisibleTimeout.current = setTimeout(() => {
            setInnerVisible(true);
            if (innerVisibleTimeout.current) clearTimeout(innerVisibleTimeout.current);
            innerVisibleTimeout.current = null;
          }, 500);
        }
      }
    }, [closeAnimation, innerVisible, visible]);
    // ========================= Refs =========================
    React.useImperativeHandle(ref, () => ({
      getElement: () => containerRef.current,
      getMaskElement: () => maskRef.current,
    }));
    // ========================= wrap classname =========================
    const clsWrap = classnames(`${prefixCls}-popupInner`, {
      'isIE': isIE,
      [`${prefixCls}-popupInner-noAlign`]: noAlign,
      [`${prefixCls}-popupInner-visible`]: isIE && (forceRender ? visible : (timeOutVis && visible)),
      // [`${prefixCls}-popupInner-hidden`]: popupTransitionName !== 'slider-bottomPopup' && !isIE && (forceRender ? !visible : !(timeOutVis && visible)),
      // [`${prefixCls}-popupInner-visNone`]: popupTransitionName === 'slider-bottomPopup' && !isIE && (forceRender ? !visible : !(timeOutVis && visible)),
      [`${prefixCls}-popupInner-visNone`]: !isIE && (forceRender ? !visible : !(timeOutVis && visible)),
      [`${prefixCls}-popupInner-hidden`]: !visible,
      'enableFixedPosition': enableFixedPosition,
    }, transitionCls, className);
    const maskCls = classnames(`${prefixCls}-popupInner-mask`, {
      [`${prefixCls}-popupInner-mask-hide`]: !visible,
      rtlSafari: needRTL() && ua.browser === 'Safari'
    });
    const styleWrap = 'zIndex' in props ? { ...style, zIndex } : style;
    return (
      <>
        {mask && (<div className={maskCls} ref={maskRef} style={{ ...maskStyle, zIndex }} id={`${popupId}-mask`} />)}
        {
          needAnimateHeight ? (
            <div className={clsWrap} ref={containerRef} style={styleWrap} id={popupId}>
              <AnimateHeight weId={`${props.weId || ''}_yb0zwv`}
                appear={timeOutVis && visible}
                duration={300}
                height={timeOutVis && visible ? 'auto' : 0}
                easing={EASE_IN_OUT}
                {...animateHeightProps}
              >
                <div ref={childrenRef} onMouseEnter={onInnerMouseEnter} onMouseLeave={onInnerMouseLeave}>
                  {props.children}
                </div>
              </AnimateHeight>
            </div>
          ) : (
            <div className={clsWrap} ref={containerRef} style={styleWrap} id={popupId}>
              <div ref={childrenRef} onMouseEnter={onInnerMouseEnter} onMouseLeave={onInnerMouseLeave}>
                {props.children}
              </div>
            </div>
          )
        }
      </>
    )
  }
));

PopupInner.displayName = 'PopupInner';

export default PopupInner;