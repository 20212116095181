import Loadable from '../../react-loadable';
import { EmoticonType, EmoticonItemType } from './types';
import { emoticonClsPrefix } from '../../constants/index';

const Emoticon = Loadable({
  name: 'Emoticon', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './Emoticon')
}) as EmoticonType;

const EmoticonItem = Loadable({
  name: 'EmoticonItem', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './EmoticonItem')
}) as EmoticonItemType;

Emoticon.defaultProps = {
  prefixCls: emoticonClsPrefix,
};

Emoticon.Item = EmoticonItem

export type { EmoticonProps, EmoticonType, EmoticonItemConfigType } from './types';

export default Emoticon;
