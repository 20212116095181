import { mTagClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { MTagType } from './types';

const MTag = Loadable({ name: 'MTag', loader: () => import(
  /* webpackChunkName: "ui_common" */
  './Tag') }) as MTagType;

MTag.defaultProps = {
  prefixCls: mTagClsPrefix
};


export type { MTagProps } from './types';

export default MTag;
