import Loadable from '../../react-loadable';
import { SpinType, type } from './types';
import { start, end, destroy } from './SpinGlobal'

const Spin = Loadable({ name: 'Spin', loader: () => import(
  /* webpackChunkName: 'spin' */
  './Spin') }) as SpinType;

Spin.defaultProps = {
  type: type.CIRCLE
}

Spin.globalSpin = { start, end, destroy }

export type { SpinProps, type } from './types';

export default Spin;
