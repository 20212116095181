import Loadable from '../../react-loadable';
import { CheckboxType } from './types';
import { checkboxClsPrefix } from '../../constants/index';

const Checkbox = Loadable({
  name: 'Checkbox', loader: () => import(
    /* webpackChunkName: 'ui_form' */
    './Checkbox')
}) as CheckboxType;

Checkbox.defaultProps = {
  prefixCls: checkboxClsPrefix,
  vertical: false,
  isMobile: false,
  inForm: false,
  showTitle: true,
};

export type {
  CheckboxProps,
  OptionsType as CheckboxDataType,
  OptionData as CheckboxOptionType,
  ValueType as CheckboxValueType,
  CheckboxEvent,
  CheckboxOptionProps
} from './types';

export default Checkbox;
