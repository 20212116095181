import * as React from 'react';
import classNames from 'classnames';
import { AnchorListContext } from './types';
import { ConfigConsumer, ConfigConsumerProps } from './utils/context';
import AnchorContext from './context';
import { anchorListPrefix } from '../../constants/index';

const prefixCls = anchorListPrefix;

export interface AnchorLinkProps {
  prefixCls?: string;
  href: string;
  target?: string;
  title: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  name?: string
}

class AnchorLink extends React.Component<AnchorLinkProps, any, AnchorListContext> {
  constructor(props: AnchorLinkProps) {
    super(props);

    this.context = {
      activeLink: null, registerLink(link: string): void {
      }, scrollTo(link: string): void {
      }, unregisterLink(link: string): void {
      }
    }
  }

  static defaultProps = {
    href: '#',
  };

  static contextType = AnchorContext;

  context: AnchorListContext;

  componentDidMount() {
    this.context.registerLink(this.props.href);
  }

  componentDidUpdate({ href: prevHref }: AnchorLinkProps) {
    const { href } = this.props;
    if (prevHref !== href) {
      this.context.unregisterLink(prevHref);
      this.context.registerLink(href);
    }
  }

  componentWillUnmount() {
    this.context.unregisterLink(this.props.href);
  }

  handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { scrollTo, onClick } = this.context;
    const { href, title } = this.props;
    if (onClick) {
      onClick(e, { title, href });
    }
    scrollTo(href);
  };

  renderAnchorLink = () => {
    const { href, title, children, className, target, name } = this.props;
    const titleSpan = typeof title === 'string' ? title : name ? name : ''

    if (this.context.searchValue && titleSpan.indexOf(this.context.searchValue) < 0) return null

    const active = this.context.activeLink === href;
    const wrapperClassName = classNames(
      `${prefixCls}-link`,
      {
        [`${prefixCls}-link-active`]: active,
      },
      className,
    );
    const titleClassName = classNames(`${prefixCls}-link-title`, {
      [`${prefixCls}-link-title-active`]: active,
    });
    return (
      <div className={wrapperClassName}>
        <span
          className={titleClassName}
          // href={href}
          title={titleSpan}
          // target={target}
          onClick={this.handleClick}
        >
          {title}
        </span>
        {children}
      </div>
    );
  };

  render() {
    return <ConfigConsumer weId={`${this.props.weId || ''}_0nto9a`}>{this.renderAnchorLink}</ConfigConsumer>;
  }
}

export default AnchorLink;
