import { sensitiveWordScanClsPrefix } from "../../../constants";
import { triggerGetRegion } from "../../trigger";
import { EVENT_SHOW_HIGH_LIGHT } from "../constants";
import { dispatchEvent as _dispatchEvent, isNil } from '../../../utils';
import { AnyObj } from "../../../types/common";
import { request } from "@weapp/utils";

const getDom = (selector: string, contentAround: string): HTMLElement | null => {
  const doms = document.querySelectorAll(selector);
  let dom = null;
  for (let i = 0; i < doms.length && !dom; i++) {
    const domTemp = doms[i] as HTMLElement;
    if (domTemp.innerText.indexOf(contentAround) >= 0) dom = domTemp;
  }
  return dom;
}

export const doScrollIntoView = (doms: any[]) => {
  let firstDom: any, top: any;
  doms?.forEach((dom) => {
    if (!dom) return;
    const domTop = triggerGetRegion(dom)?.top;
    if (isNil(top)) {
      top = domTop;
      firstDom = dom;
    } else if (domTop < top) {
      top = domTop;
      firstDom = dom;
    }
  })
  if (firstDom) {
    firstDom?.focus?.();
    firstDom?.scrollIntoView?.();
  }
}
export const positioning = (contentAround?: string) => {
  if (!contentAround) return;
  const edittableHighlightDom = document.querySelectorAll('[class~="is-high-light-required"');
  const readOnlyHighlightDom = document.querySelectorAll('[class~="rich-text-highlight-active"');
  if (edittableHighlightDom || readOnlyHighlightDom) {
    doScrollIntoView([edittableHighlightDom?.[0], readOnlyHighlightDom?.[0]]);
    return;
  }

  const inputDom = getDom(`div[class~="${sensitiveWordScanClsPrefix}-highlight-input"]`, contentAround);
  const textareaDom = getDom(`div[class~="${sensitiveWordScanClsPrefix}-highlight-textarea"]`, contentAround);
  let focusDom = null;
  if (inputDom && textareaDom) {
    const inputRange = triggerGetRegion(inputDom);
    const textareaRange = triggerGetRegion(textareaDom);
    if (inputRange?.top >= textareaRange?.top) focusDom = textareaDom;
    else focusDom = inputDom;
  } else if (inputDom) {
    focusDom = inputDom;
  } else if (textareaDom) {
    focusDom = textareaDom;
  }
  focusDom?.focus();
  focusDom?.scrollIntoView?.();
}
// 触发HighLight组件渲染并定位
export const dispatchEvent = (visible: boolean, data: any, otherAction?: AnyObj) => {
  _dispatchEvent(EVENT_SHOW_HIGH_LIGHT, { visible, data, ...otherAction });
}

export const showHighLight = (data: any) =>  {
  dispatchEvent(true, data);
}

export const closeHighLight = () =>  {
  dispatchEvent(false, {});
}

export const HighLightService = () => import(
  /* webpackChunkName: 'sensitive_word_scan' */
  './high-light-service'
);

export const getSensitiveHelp = (content: string, url?: string, otherParams?: AnyObj) => {
  return request({
    url: url || '/api/contentmoderation/sensitiveHelp',
    data: { content, ...otherParams },
    method: 'POST',
  })
}

export const getContent = (_content: string | AnyObj) => {
  let content = '';
  if (typeof _content === 'string') {
    content = _content;
  } else if (_content) {
    Object.values(_content)?.forEach((v) => {
      content += v;
    })
  }
  return content;
}

export const sensitiveWordScanUtils = {
  closeHighLight,
  showHighLight,
  dispatchEvent,
  positioning,
  HighLightService,
  getSensitiveHelp,
  getContent,
}