import { parseQuery } from './index';
import { utils } from '../../../lib';
const { getHash } = utils;

export interface IframeInBrowserCallback {
  [callbackFnName: string]: Function | undefined;
}

export type IframeInBrowserCallbackMap = { [iframeInBrowserKey: string]: IframeInBrowserCallback };

// 只允许注册回调事件，如果要传也只能提供get函数去获取，不允许直接传递数据
const iframeInBrowserCallbackMap: IframeInBrowserCallbackMap = {};

// 未找到回调事件时的默认回调事件
const defaultIframeInBrowserCallback = (iframeInBrowserKey: string, callbackFnName: string) => {
  console.error(new Error(`${iframeInBrowserKey} 对应的 Browser 未找到 ${callbackFnName} 回调函数！`));
}

// 生成iframeInBrowserKey
export function genIframeInBrowserKey () {
  let iframeInBrowserKey = getHash(8);
  while (iframeInBrowserCallbackMap[iframeInBrowserKey]) { // 避免重复key
    iframeInBrowserKey = getHash(8);
  }
  return iframeInBrowserKey;
}

export function getIframeUrlKey (url: string) {
  const iframeKey = parseQuery(url)['iframeInBrowserKey'];
  return iframeKey;
}

// 注册回调事件
export function registerIframeInBrowserCallback (iframeInBrowserKey: string, callbacks: IframeInBrowserCallback) {
  if (!iframeInBrowserKey || typeof callbacks !== 'object') return;
  let iframeCallbacks = iframeInBrowserCallbackMap[iframeInBrowserKey] || {};
  Object.assign(iframeCallbacks, callbacks || {});
  iframeInBrowserCallbackMap[iframeInBrowserKey] = iframeCallbacks;
}

// 销毁注册的回调事件
export function unRegisterIframeInBrowserCallback (iframeInBrowserKey: string) {
  if (iframeInBrowserCallbackMap[iframeInBrowserKey]) {
    delete iframeInBrowserCallbackMap[iframeInBrowserKey];
  }
}

// 获取注册的回调事件
function getIframeInBrowserCallback (url: string, callbackFnName: string): Function {
  const iframeInBrowserKey = getIframeUrlKey(url) || '';
  const defaultCallback = () => {
    defaultIframeInBrowserCallback(iframeInBrowserKey, callbackFnName);
  };
  if (iframeInBrowserCallbackMap[iframeInBrowserKey] &&
    typeof iframeInBrowserCallbackMap[iframeInBrowserKey][callbackFnName] === 'function'
  ) {
    return iframeInBrowserCallbackMap[iframeInBrowserKey][callbackFnName] || defaultCallback;
  }
  return defaultCallback;
}

export type GetIframeInBrowserCallback = typeof getIframeInBrowserCallback;

export default getIframeInBrowserCallback;
