import Loadable from '../../react-loadable';
import { mAssociateListClsPrefix } from '../../constants/index.m';
import { MAssociateListType } from './types';

const MAssociateList = Loadable({name:'MAssociateList', loader: () => import(
  /* webpackChunkName: "ui_m_common_small" */
  './AssociateList.m') }) as MAssociateListType;

MAssociateList.defaultProps = {
  prefixCls: mAssociateListClsPrefix,
  maxMenu: 4,
};

export type { MAssociateListProps } from './types';

export default MAssociateList;