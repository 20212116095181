import { sensitiveWordScanClsPrefix } from "../../constants";
import Loadable from "../../react-loadable";
import { EVENT_SHOW_HIGH_LIGHT } from "./constants";
import { HighLightType, SensitiveWordScanContentType, SensitiveWordScanIconType, SensitiveWordScanTitleType, SensitiveWordScanType } from "./types";

const SensitiveWordScan = Loadable({
  name: 'SensitiveWordScan',
  loader: () => import(
    /* webpackChunkName: 'ui_frequent' */
    './components'
  )
}) as SensitiveWordScanType;

SensitiveWordScan.displayName = 'SensitiveWordScan';

SensitiveWordScan.defaultProps = {
  prefixCls: sensitiveWordScanClsPrefix,
  layoutType: 'layout',
  layoutBoxType: 'left',
}

SensitiveWordScan.EVENT_SHOW_HIGH_LIGHT = EVENT_SHOW_HIGH_LIGHT;

const Icon = Loadable({
  name: 'SensitiveWordScanIcon',
  loader: () => import(
    /* webpackChunkName: 'ui_frequent' */
    './components/ScanIcon'
  )
}) as SensitiveWordScanIconType;

Icon.defaultProps = {
  prefixCls: sensitiveWordScanClsPrefix,
  enableLoading: true,
}

SensitiveWordScan.Icon = Icon;

const Content = Loadable({
  name: 'SensitiveWordScanContent',
  loader: () => import(
    /* webpackChunkName: 'ui_frequent' */
    './components/content'
  )
}) as SensitiveWordScanContentType;

Content.defaultProps = {
  prefixCls: sensitiveWordScanClsPrefix,
}

SensitiveWordScan.Content = Content;

const Title = Loadable({
  name: 'SensitiveWordScanTitle',
  loader: () => import(
    /* webpackChunkName: 'ui_frequent' */
    './components/layout/Title'
  )
}) as SensitiveWordScanTitleType;

Title.defaultProps = {
  prefixCls: sensitiveWordScanClsPrefix,
}

SensitiveWordScan.Title = Title;

const HighLight = Loadable({
  name: 'HighLight',
  loader: () => import(
    /* webpackChunkName: 'ui_frequent' */
    './components/HighLight'
  )
}) as HighLightType;

HighLight.defaultProps = {
  prefixCls: `${sensitiveWordScanClsPrefix}-highlight`,
}
SensitiveWordScan.HighLight = HighLight;

export default SensitiveWordScan;
export type {
  SensitiveWordScanType, SensitiveWordScanProps,
  HighLightType, HighLightProps, HighLightComponentType,
  SensitiveWordScanContentRef, SensitiveWordScanContentType, SensitiveWordScanContentProps,
  SensitiveWordScanIconProps, SensitiveWordScanIconType, SensitiveWordScanIconRef,
  ScanTitleProps as SensitiveWordScanTitleProps, SensitiveWordScanTitleType,
} from './types';

export { sensitiveWordScanUtils } from './utils';
export type { HighLightServiceType } from './utils/high-light-service';