import { middleware } from '@weapp/utils';
import React, { HTMLAttributes, PureComponent, RefAttributes } from 'react';
import { uiAppName } from '../../constants/index';
import { AnyObj, BaseProps } from '../../types/common';

export interface BlockProps extends HTMLAttributes<HTMLDivElement>, BaseProps, AnyObj {
  /* 是否隐藏 */
  hidden?: boolean;
}

@middleware(uiAppName, 'Block')
class Block extends PureComponent<BlockProps & RefAttributes<HTMLDivElement>> {
  static displayName = 'Block';

  render() {
    const { hidden, children, customRender } = this.props;
    if (hidden) return null;
    const content = (
      <div {...this.props}>
        {children}
      </div>
    );
    return customRender ? customRender(this, content) : content;
  }
}

export default Block;
