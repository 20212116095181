import { constants, constantsM, Loadable, LoadingType } from '../../lib';
import { InputNumberType, MInputProps, MInputType } from './types';

const { USESKELETON } = constants;
const { mInputClsPrefix, mListClsPrefix } = constantsM;
const MInputSkeleton: LoadingType<MInputProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MInput = Loadable({
  name: 'MInput', loading: MInputSkeleton, loader: () => import(
    /* webpackChunkName: 'ui_m_common_form' */
    './Input.m')
}) as MInputType;

const MInputNumber = Loadable({
  name: 'MInputNumber', loading: MInputSkeleton, loader: () => import(
    /* webpackChunkName: 'ui_m_common_form' */
    './InputNumber.m')
}) as InputNumberType;

MInput.MInputSkeleton = MInputSkeleton;

MInput.defaultProps = {
  prefixCls: mInputClsPrefix,
  prefixListCls: mListClsPrefix,
  autoComplete: "off"
};

MInputNumber.MInputNumberSkeleton = MInputSkeleton;

MInputNumber.defaultProps = {
  prefixCls: `${mInputClsPrefix}-number`,
  hideOps: true,
  step: 1,
  autoComplete: "off"
};

MInput.MInputNumber = MInputNumber;

export type { MInputProps, MValueType } from './types';
// 子组件导出
export { MInputNumber }
export default MInput;

