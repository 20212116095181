import Loadable from '../../react-loadable';
import { BoxSelectionType } from './types';

const BoxSelection = Loadable({
  name: 'BoxSelection', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './BoxSelection')
}) as BoxSelectionType;

export type { BoxSelectionProps, BoxSelectionType } from './types';

export default BoxSelection;
