import { RouteComponentProps } from "react-router-dom";
import { Loadable, LoadingType, utils, constants } from '../../lib';
import { MBrowserDialogRoute, MTypesBrowserSelectedRoute, mDefaultProps, mDefaultTypesProps, mDefaultShareProps } from "./defaultProps";
import { MBrowserProps, MBrowserPanelType, MBrowserType } from "./types";
import { MTypesBrowserProps, MTypesBrowserType } from "./types-browser/types";
import { MShareBrowserProps, MShareBrowserType } from "./share-browser/types";
import { mAutoCompleteMiddleware, mBrowserMiddleware, mTypesBrowserMiddleware, mShareBrowserMiddleware } from "./util/browserMiddleware";
import getIframeInBrowserCallback from './util/iframeInDialogCallback';

const { USESKELETON } = constants;
const { formatParentPath } = utils;
/* 外部控制浏览按钮弹框打开的钩子 */
const openDialog = (props: RouteComponentProps, dialogId: string) => {
  if (!props || !dialogId || !props.history) return;
  const parentPath = formatParentPath(props);
  props.history?.push(`${parentPath}${MBrowserDialogRoute}${dialogId}`);
}

const MBrowserSkeleton: LoadingType<MBrowserProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MBrowser = Loadable({
  name: 'MBrowser', loading: MBrowserSkeleton, propsMiddleware: mBrowserMiddleware, loader: () => import(
    /* webpackChunkName: "m_browser" */
    './MBrowser')
}) as MBrowserType;

MBrowser.MBrowserSkeleton = MBrowserSkeleton;

MBrowser.defaultProps = mDefaultProps;

MBrowser.openDialog = openDialog;

MBrowser.getIframeInBrowserCallback = getIframeInBrowserCallback;

const MAutoCompleteSelected = Loadable({
  name: 'MAutoCompleteSelected', propsMiddleware: mAutoCompleteMiddleware, loader: () => import(
    /* webpackChunkName: "m_browser" */
    './auto-complete/MAssociativeSelected')
});

MAutoCompleteSelected.defaultProps = mDefaultProps;

/** MTypesBrowser */
const openTypesDialog = (props: RouteComponentProps, dialogId: string) => {
  if (!props || !dialogId || !props.history) return;
  const parentPath = formatParentPath(props);
  props.history?.push(`${parentPath}${MTypesBrowserSelectedRoute}${dialogId}`);
}
const MTypesBrowserSkeleton: LoadingType<MTypesBrowserProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MTypesBrowser = Loadable({
  name: 'MTypesBrowser',
  loading: MTypesBrowserSkeleton,
  propsMiddleware: mTypesBrowserMiddleware,
  loader: () => import(
    /* webpackChunkName: 'm_browser' */
    './types-browser/MTypesBrowser'
  )
}) as MTypesBrowserType;

MTypesBrowser.MTypesBrowserSkeleton = MTypesBrowserSkeleton;

MTypesBrowser.defaultProps = mDefaultTypesProps;

MTypesBrowser.openDialog = openTypesDialog;

/** MShareBrowser */
const MShareBrowserSkeleton: LoadingType<MShareBrowserProps> = () => USESKELETON && <div style={{ height: 44 }}></div>

const MShareBrowser = Loadable({
  name: 'MShareBrowser',
  loading: MShareBrowserSkeleton,
  propsMiddleware: mShareBrowserMiddleware,
  loader: () => import(
    /* webpackChunkName: 'm_browser' */
    './share-browser/MShareBrowser'
  )
}) as MShareBrowserType;

MShareBrowser.MShareBrowserSkeleton = MShareBrowserSkeleton;

MShareBrowser.defaultProps = mDefaultShareProps;

/** MAssociativeBrowser */

// @ts-ignore
const MAssociativeBrowser = Loadable({
  name: 'MAssociativeBrowser', propsMiddleware: mBrowserMiddleware, loader: () => import(
    /* webpackChunkName: "m_browser" */
    './types-browser/MAssociativeBrowser')
}) as MTypesBrowserType;


MAssociativeBrowser.defaultProps = mDefaultTypesProps;

const MBrowserPanel = Loadable({
  name: 'MBrowserPanel', propsMiddleware: mBrowserMiddleware, loader: () => import(
    /* webpackChunkName: "m_browser" */
    './MBrowserPanel')
}) as MBrowserPanelType;

MBrowserPanel.defaultProps = mDefaultProps;

MBrowser.MBrowserPanel = MBrowserPanel;

MBrowser.MTypesBrowser = MTypesBrowser;

MBrowser.MAssociativeBrowser = MAssociativeBrowser;

MBrowser.MAutoCompleteSelected = MAutoCompleteSelected;

/* 子组件 */
export { MTypesBrowser, MShareBrowser, MAssociativeBrowser, MBrowserPanel };

/* 工具方法 */
export { openDialog, getIframeInBrowserCallback };

/* 浏览按钮基础类型声明 */
export type { MBrowserProps, TabsData as MBrowserTabsData, ValueType as MBrowserValueType } from './types';

/* 组合浏览按钮类型声明 */
export type { MTypesBrowserProps } from './types-browser/types';
export type { MShareBrowserProps } from './share-browser/types';

export default MBrowser;
