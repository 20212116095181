
import { repeatFrequencyClsPrefix } from '../../constants/index';
import Loadable from '../../react-loadable';
import { RepeatFrequencyType } from "./types";

const RepeatFrequency = Loadable({
  name: 'RepeatFrequency',
  loader: () => import(
    /* webpackChunkName: "repeat_frequency" */
    './RepeatFrequency')
}) as RepeatFrequencyType;


RepeatFrequency.defaultProps = {
  prefixCls: repeatFrequencyClsPrefix
};

export { definitionRule as DefinitionRule } from "./utils"

export type { RepeatFrequencyProps, FrequencyType, RepeatFrequencyDataProps } from './types';

export default RepeatFrequency;
