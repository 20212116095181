import Loadable from "../../react-loadable";
import { datePickerClsPrefix, dateQuickFilterClsPrefix, dateTimePickerClsPrefix, timePickerClsPrefix, monthDayPickerClsPrefix, dateMenuClsPrefix } from "../../constants";
import { DatePickerType, DateTimePickerType, DateQuickFilterType, TimePickerType, MonthDayPickerType, DateMenuType } from "./types";
import {
  getRangeInfoByPrimaryKey,
  getLocaleDate, getServerDate,
  getLocaleDateTime, getServerDateTime,
  getLocaleDateAndTime, getServerDateAndTime,
  getWindowDateShowFormat, getWindowDateTimeShowFormat, getCustomFormatByWindow, getCustomFormatDateByWindow,
  getLocaleDateString, getServerDateString, getDateArrayFromString,
  getWeekOfYear, getWeekBoundary,
  getSeasonDateByYear,
  getCurrentUpYear, getHalYearByYear,
  getUserOffsetName, isStrDateContainUTC,
  isZeroClock, isStrDateContainHMS

} from './utils'
import { dayjs } from '@weapp/utils';

const DatePicker = Loadable({
  name: 'DatePicker', loader: () => import(
    /* webpackChunkName: "ui_common_date" */
    './date-picker/DatePicker')
}) as DatePickerType;

DatePicker.defaultProps = {
  prefixCls: datePickerClsPrefix,
  disabled: false,
  isRange: false,
  // weekStart: 1,
  rangePanelType: 'detail',
  type: 'day',
  bindTriggerContainer: false,
  showDayFooter: true,
  showMultiBtnGroup: true,
  showUnlimit: true,
  selectedKeyPrior: false,
  openInputFormat: false,
  allowClear: true,
  needMaxWidth: true,
};

//----------日期时间选择----------
const DateTimePicker = Loadable({
  name: 'DateTimePicker', loader: () => import(
    /* webpackChunkName: "ui_common_date" */
    './date-time-picker/DateTimePicker')
}) as DateTimePickerType;

DateTimePicker.defaultProps = {
  prefixCls: dateTimePickerClsPrefix,
  disabled: false,
  timeFormat: 'HH:mm',
  isRange: false,
  // weekStart: 1,
  bindTriggerContainer: false,
  showMultiBtnGroup: true,
  showUnlimit: true,
  openDateMode: false,
  showDayFooter: true,
  showNowTimeBtn: true,
  defaultTime: '08:00:00',
  defaultEndTime: '18:00:00',
  isLocale: true,
  allowClear: true,
  needMaxWidth: true,
  // panelType: 'trigger'
};

export type { DateTimePickerProps, DateType as standardDateType } from "./types";
export { DateTimePicker };

//----------日期快捷选择----------
const DateQuickFilter = Loadable({
  name: 'DateQuickFilter', loader: () => import(
    /* webpackChunkName: "ui_common_date" */
    './date-quick-filter/DateQuickFilter')
}) as DateQuickFilterType;

DateQuickFilter.defaultProps = {
  prefixCls: dateQuickFilterClsPrefix,
  type: 'day',
  showDayFooter: true,
  // weekStart: 1,
  allowClear: true,
};

export type { DateQuickFilterProps } from "./types";
export { DateQuickFilter };

//----------时间选择----------
const TimePicker = Loadable({
  name: 'TimePicker', loader: () => import(
    /* webpackChunkName: "ui_common_date" */
    './time-picker/TimePicker')
}) as TimePickerType;

TimePicker.defaultProps = {
  prefixCls: timePickerClsPrefix,
  // panelType: 'detail',
  disabled: false,
  format: 'HH:mm',
  hourStep: 1,
  minuteStep: 1,
  secondStep: 1,
  isRange: false,
  openInputFormat: true, //是否开启input框输入校验
  allowClear: true,
  needMaxWidth: true,
  showFooter: true
};

export type { TimePickerProps } from "./types";
export { TimePicker };

//----------月日选择----------
const MonthDayPicker = Loadable({
  name: 'MonthDayPicker', loader: () => import(
    /* webpackChunkName: "ui_common_date" */
    './month-day-picker/MonthDayPicker')
}) as MonthDayPickerType;

MonthDayPicker.defaultProps = {
  prefixCls: monthDayPickerClsPrefix,
  disabled: false,
  bindTriggerContainer: false,
  year: dayjs().year(),
  allowClear: true,
  needMaxWidth: true,
};

export type { MonthDayPickerProps } from "./types";
export { MonthDayPicker };


//----------日期平铺----------
const DateMenu = Loadable({
  name: 'DateMenu', loader: () => import(
    /* webpackChunkName: "ui_common_date" */
    './date-menu/DateMenu')
}) as DateMenuType;

DateMenu.defaultProps = {
  prefixCls: dateMenuClsPrefix,
  order: 'reverse',
  changeLimit: 'month'
};

export type { DateMenuProps } from "./types";
export { DateMenu };

//----------统一导出----------
//范围快捷选择
DatePicker.getRangeInfoByPrimaryKey = getRangeInfoByPrimaryKey
//日期切割
DatePicker.getDateArrayFromString = getDateArrayFromString
//时区转换
DatePicker.getLocaleDate = getLocaleDate
DatePicker.getServerDate = getServerDate
DatePicker.getLocaleDateTime = getLocaleDateTime
DatePicker.getServerDateTime = getServerDateTime
DatePicker.getLocaleDateAndTime = getLocaleDateAndTime
DatePicker.getServerDateAndTime = getServerDateAndTime
DatePicker.getLocaleDateString = getLocaleDateString
DatePicker.getServerDateString = getServerDateString
//格式转换
DatePicker.getWindowDateShowFormat = getWindowDateShowFormat
DatePicker.getWindowDateTimeShowFormat = getWindowDateTimeShowFormat
DatePicker.getCustomFormatByWindow = getCustomFormatByWindow
DatePicker.getCustomFormatDateByWindow = getCustomFormatDateByWindow
//周工具方法
DatePicker.getWeekOfYear = getWeekOfYear
DatePicker.getWeekBoundary = getWeekBoundary
//季度工具方法
DatePicker.getSeasonDateByYear = getSeasonDateByYear
//半年工具方法
DatePicker.getHalYearByYear = getHalYearByYear
//年份工具方法
DatePicker.getCurrentUpYear = getCurrentUpYear
//时间工具方法
DatePicker.isZeroClock = isZeroClock
DatePicker.isStrDateContainHMS = isStrDateContainHMS
//时区计算工具方法
DatePicker.getUserOffsetName = getUserOffsetName
DatePicker.isStrDateContainUTC = isStrDateContainUTC


export type {
  DatePickerProps,
  PickerType as DatePickerPickerType,
  TitleDateChangeType as DatePickerTitleDateChangeType,
  DateCellValueType as DatePickerDateCellValueType,
  DateType as DatePickerDateType,
  PrimaryKeyType as DatePickerPrimaryKeyType,
  MultiBtnDataType as DatePickerMultiBtnDataType,
  CurrentOperationType as DatePickerCurrentOperationType,
  DateLimitedConfigType as DatePickerDateLimitedConfigType,
  DateTimeFormatInfoType,
  DateTimeTypeAryType,
  DateMenuGroupType
} from "./types";
export { DatePickerSuffix } from './components/index'
export default DatePicker;

