
import { AnyObj } from '@weapp/ui';
/**
 * UI库校验方法类型
 * @param
 * @return errors
 */
export interface ValidatorParams {
  datas: any, // 需校验的数据
  rules: any, // 默认校验规则
  message?: any, // 自定义校验提示信息
  customRegister?: any, // 自定义校验规则
  attributeNames?: AnyObj, // 自定义校验提示信息属性替换内容 :attribute
}

/**
 * UI库校验方法封装
 * @param
 * @return errors
 */

export default function doValidator(validatorParams: ValidatorParams) {
  return import(
    /* webpackChunkName: 'ui_common_utils' */
    './validatorAsync'
  ).then((defaultModule) => defaultModule.default?.(validatorParams));
}