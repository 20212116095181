import Loadable from '../../react-loadable';
import { BackTopType, BackTopProps }  from './types';

const BackTop = Loadable({ name: 'BackTop', loader: () => import(
    /* webpackChunkName: "ui_common" */
    './BackTop') }) as BackTopType;

export type { BackTopProps } from './types';

export default BackTop;
