import { classnames, getSyncHook, ua } from '@weapp/utils';
import React from 'react';
import ReactDOM from 'react-dom';
import MDialog from '../dialog/index.m';
import { resetBodyOverflowCss, setWeappUiOverflowCss } from '../../utils/index';
import { ActionSheetWithOptions, ShareActionSheetWithOptions, ActionCallBack } from './types';
import { mActionSheetClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';

const ActionSheetInner = Loadable({
  name: 'ActionSheetInner',
  loader: () =>
    import(
      /* webpackChunkName: 'ui_common_small' */
      "./ActionSheetInner"
    ),
});

const NORMAL = 'NORMAL';
const SHARE = 'SHARE';
const name = 'ActionSheet';
const CUSTOM = 'CUSTOM';
const GRID = 'GRID';

function noop() { }

const queue: any[] = [];

function createActionSheet(
  flag: string,
  config: ActionSheetWithOptions | ShareActionSheetWithOptions,
  callback: ActionCallBack,
  children?: React.ReactElement<any> | null,
) {
  const props = {
    prefixCls: mActionSheetClsPrefix,
    showSensitive: false,
    ...config,
  };
  const {
    prefixCls,
    className,
    maskClosable = true,
    style,
    options,
    cancelButtonIndex,
  } = props;

  setWeappUiOverflowCss();
  const div = document.createElement('div');
  document.body.appendChild(div);

  queue.push(close);

  function close() {
    if (div) {
      ReactDOM.unmountComponentAtNode(div);
      resetBodyOverflowCss();
      if (div.parentNode) {
        div.parentNode.removeChild(div);
      }
      const index = queue.indexOf(close);
      if (index !== -1) {
        queue.splice(index, 1);
      }
    }
  }

  function cb(index: any = -1, rowIndex = 0) {
    const res = callback(index, rowIndex);
    if (res && res.then) {
      res.then(() => {
        close();
      });
    } else {
      close();
    }
  }

  function handleCancel() {
    cb(cancelButtonIndex || -1);
  }

  // let children: React.ReactElement<any> | null = null;
  let mode = 'normal';
  switch (flag) {
    case NORMAL:
      mode = 'normal';
      children = <ActionSheetInner
        weId={`${props.weId || ''}_cct0lr`}
        {...props}
        prefixCls={prefixCls}
        options={options}
        type="normal"
        callback={callback}
        close={close}
      />
      break;
    case GRID:
      mode = 'grid';
      children = <ActionSheetInner weId={`${props.weId || ''}_xw6hk1`}
        {...props}
        prefixCls={prefixCls}
        options={options}
        type="grid"
        callback={callback}
        close={close}
      />
      break;
    case SHARE:
      mode = 'share';
      children = <ActionSheetInner weId={`${props.weId || ''}_jqu8oy`}
        {...props}
        prefixCls={prefixCls}
        options={options}
        type="share"
        callback={callback}
        close={close}
      />
      break;
    default:
      break;
  }

  const rootCls = classnames(`${prefixCls}-dialog`, {
    [`${prefixCls}-${mode}`]: mode
  }, className);

  ReactDOM.render(
    <MDialog
      weId={`${props.weId || ''}_mgmdx4`}
      mask
      visible
      maskClosable={maskClosable}
      className={rootCls}
      style={style}
      footer={[]}
      placement="bottom"
      onClose={handleCancel}
    >
      {children}
    </MDialog>,
    div,
  );

  return {
    close,
  };
}

function isObject(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

function isFunction(fn: any) {
  return typeof fn === 'function';
}

function renderProps(newProps: object) {
  if (isObject(window.mobileComponentsConfig)) {
    const overwrite = window.mobileComponentsConfig[name];
    if (isObject(overwrite)) {
      const propsFn = overwrite.overwritePropsFn;
      if (isFunction(propsFn)) {
        const overwriteProps = propsFn(newProps, name);
        if (isObject(overwriteProps)) {
          newProps = overwriteProps;
        }
      }
    }
  }
  return newProps;
}

const ActionSheet = {
  showActionSheetWithOptions(
    config: ActionSheetWithOptions,
    callback: ActionCallBack = noop,
    children?: React.ReactElement<any> | null,
  ) {
    let props = { config, children };
    const newProps = renderProps(props);
    props = {
      ...props,
      ...newProps,
    };
    children = props.children || null;
    const hooks = getSyncHook('weappUi', 'ActionSheep.config.overwrite');
    if (typeof hooks === 'function') {
      config = hooks(config) || config;
    }
    createActionSheet(children ? CUSTOM : NORMAL, config, callback, children);
  },
  showShareActionSheetWithOptions(
    config: ShareActionSheetWithOptions,
    callback: ActionCallBack = noop,
    children?: React.ReactElement<any> | null,
  ) {
    let props = { config, children };
    const newProps = renderProps(props);
    props = {
      ...props,
      ...newProps,
    };
    children = props.children || null;
    const hooks = getSyncHook('weappUi', 'ActionSheep.config.overwrite');
    if (typeof hooks === 'function') {
      config = hooks(config) || config;
    }
    createActionSheet(children ? CUSTOM : SHARE, config, callback, children);
  },
  showActionSheetWithGridOptions(
    config: ActionSheetWithOptions,
    callback: ActionCallBack = noop,
    children?: React.ReactElement<any> | null,
  ) {
    let props = { config, children };
    const newProps = renderProps(props);
    props = {
      ...props,
      ...newProps,
    };
    children = props.children || null;
    const hooks = getSyncHook('weappUi', 'ActionSheep.config.overwrite');
    if (typeof hooks === 'function') {
      config = hooks(config) || config;
    }
    createActionSheet(children ? CUSTOM : GRID, config, callback, children);
  },
  close() {
    queue.forEach(q => q());
  },
};

export default ActionSheet;
