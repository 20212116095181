import Loadable from "../../react-loadable";
import { commentClsPrefix } from "../../constants";
import { CommentType, CommentMenuType, CommentContentType, CommentItemType, CommentEditType, CommentLogType, CommentListType, CommentEditTextType, UpvoteType, AddressType, QuoteType, ReplyFloorType, CommentAvatarType, CommentItemContentType, CommentItemFooterType, StatisticType } from "./types";
import { getAtValue } from "./util/common";

const Comment = Loadable({
  name: 'Comment', loader: () => import(
    /* webpackChunkName: "comment" */
    './Comment')
}) as CommentType;

const CommentMenu = Loadable({
  name: 'CommentMenu', loader: () => import(
    /* webpackChunkName: "comment" */
    './CommentMenu')
}) as CommentMenuType;

const CommentContent = Loadable({
  name: 'CommentContent', loader: () => import(
    /* webpackChunkName: "comment" */
    './constants/CommentContent')
}) as CommentContentType;

const CommentItem = Loadable({
  name: 'CommentItem', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/main/CommentItem')
}) as CommentItemType;

const CommentEdit = Loadable({
  name: 'CommentEdit', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/main/CommentEdit')
}) as CommentEditType;

const CommentLog = Loadable({
  name: 'CommentLog', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/main/CommentLog')
}) as CommentLogType;

const CommentList = Loadable({
  name: 'CommentList', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/main/CommentList')
}) as CommentListType;

const CommentEditText = Loadable({
  name: 'CommentEditText', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/main/EditText')
}) as CommentEditTextType;

const Upvote = Loadable({
  name: 'Upvote', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/upvote/Upvote')
}) as UpvoteType;

const Address = Loadable({
  name: 'Address', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/main/Address')
}) as AddressType;

const Quote = Loadable({
  name: 'Quote', loader: () => import(
    /* webpackChunkName: "comment" */
    './constants/Quote')
}) as QuoteType;

const ReplyFloor = Loadable({
  name: 'ReplyFloor', loader: () => import(
    /* webpackChunkName: "comment" */
    './constants/ReplyFloor')
}) as ReplyFloorType;

const CommentAvatar = Loadable({
  name: 'CommentAvatar', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/item/CommentAvatar')
}) as CommentAvatarType;

const CommentItemContent = Loadable({
  name: 'CommentItemContent', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/item/ItemContent')
}) as CommentItemContentType;

const CommentItemFooter = Loadable({
  name: 'CommentItemFooter', loader: () => import(
    /* webpackChunkName: "comment" */
    './component/pc/item/ItemFooter')
}) as CommentItemFooterType;

const Statistic = Loadable({
  name: 'Statistic', loader: () => import(
    /* webpackChunkName: "comment" */
    './constants/Statistic')
}) as StatisticType;

/*------------------------*/
CommentMenu.defaultProps = {
  prefixCls: commentClsPrefix,
  paginationType: "more",
  hasSearch: false,
  menuProps: {},
  module: "share",
  objectModule: "bcw",
  menu: [],
  maxMenu: 3,
  style: {},
  showSource: true,
};

/*------------------------*/
CommentContent.defaultProps = {
  prefixCls: commentClsPrefix,
  style: {},
};
CommentContent.getAtValue = getAtValue;

/*------------------------*/
Comment.defaultProps = {
  prefixCls: commentClsPrefix,
  paginationType: "more",
  module: "share",
  objectModule: "bcw",
  hasSearch: false,
  showEmpty: false,
  autoRefresh: false,
  isHorizontal: false,
  hasEdit: true,
  showHeader: true,
  showSource: true,
  useCache: true,
};

/*------------------------*/
CommentEdit.defaultProps = {
  prefixCls: commentClsPrefix,
  module: "share",  
  objectModule: "bcw",
};

/*------------------------*/
CommentItem.defaultProps = {
  prefixCls: commentClsPrefix,
};

CommentEditText.defaultProps = {
  prefixCls: commentClsPrefix,
  module: "share",
  objectModule: "bcw",
};

Statistic.defaultProps = {
  prefixCls: commentClsPrefix,
  module: "share",
  objectModule: "bcw",
}

/*------------------------*/
Comment.CommentMenu = CommentMenu;
Comment.CommentContent = CommentContent;
Comment.CommentItem = CommentItem;
Comment.CommentEdit = CommentEdit;
Comment.CommentLog = CommentLog;
Comment.CommentList = CommentList;
Comment.CommentEditText = CommentEditText;
Comment.Address = Address;
Comment.ReplyFloor = ReplyFloor;
Comment.CommentAvatar = CommentAvatar;
Comment.CommentItemFooter = CommentItemFooter;
Comment.Quote = Quote;
Comment.CommentItemContent = CommentItemContent;


Upvote.defaultProps = {
  prefixCls: commentClsPrefix,
  isTrigger: true,
};

export type {
  CommentProps,
  CommentListProps,
  CommentType,
  ReplyOption,
  CommentOptionType,
  CommentEditTextSubmitFunc,
  CommentReadOnly,
  CommentAction,
  UpvoteProps,
  ItemRenderObjType
} from "./types";
export { CommentMenu, CommentContent, CommentItem, CommentEdit, CommentList, Upvote, Statistic };
export default Comment;

export {
  getClient as getCommentClient,
  getAssociatedData as getAssociatedFormBrowser,
  getBrowserData as getBrowserFromAssociated,
  getUploadData as getCommentUploadData,
  getRichTextFromText,
  getDesensitizationComment
} from "./util/common";
