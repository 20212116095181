import { SystemWMSetting, WatermarkOptions } from "./types";
import { Watermark, systemWatermarkSetting } from './index';
import { dayjs, ua } from "@weapp/utils";
import { AnyObj } from "../../types/common";
import { HighZIndex, LowZIndex } from './constants';

export const publicUrlapi = window.apiUrlweappUi || window.publicUrlapi || window.publicUrl || '';

// 获取图片预览地址
export const getImagePreviewUrl = (fileid?: string, isMobile?: boolean, loginFree?: boolean) => {
  const previewUrl = loginFree ? '/papi/file/preview' : (isMobile ? '/api/app/file/preview' : '/api/file/preview');
  return `${publicUrlapi}${previewUrl}?fileId=${fileid}&type=redirect${window.ETEAMSID ? `&ETEAMSID=${window.ETEAMSID}` : ''}`;
}

export class WatermarkSDK {
  /* 全局水印实例 */
  globalWatermarkInstance: Watermark | undefined;

  /* 是否存在全局水印 */
  hasGlogbalWM = () => {
    return !!this.globalWatermarkInstance;
  }

  /* 全局系统水印是否开启 */
  isSystemWMOpen = (mobile?: boolean) => {
    if (typeof mobile === 'undefined') {
      mobile = ua.device === 'Mobile';
    }
    const wmSetting = this.getGlobalSetting();
    if (wmSetting) {
      const { wmUseMobile, wmUse } =  wmSetting;
      return (mobile ? wmUseMobile : wmUse) === '1';
    }
    return false;
  }

  /* 获取系统水印配置 */
  getGlobalWMOption = (): undefined | WatermarkOptions => {
    return systemWatermarkSetting?.getSystemSetting?.();
  }

  /* 初始化系统水印 */
  initSystemWM = () => {
    if (this.isSystemWMOpen()) {
      systemWatermarkSetting?.loadSystemWatermark?.();
    } else {
      this.clear();
    }
  }

  /* 获取全局水印配置 */
  getGlobalSetting = () => {
    return systemWatermarkSetting?.getGlobalConfig?.();
  }

  // 处理图片预览接口
  formatWMImg = (wmImg: string) => {
    try {
      let img = JSON.parse(wmImg);
      img = Array.isArray(img) && img.length ? img[0] : img;
      if (img?.fileid) {
        const isMobile = ua.device === 'Mobile';
        const src = getImagePreviewUrl(img?.fileid, isMobile);
        return src;
      }
    } catch (err) {
      console?.error(err);
    }
    return wmImg;
  }

  // 处理文本水印中的插值
  formatWMText = (wmText: string, map?: AnyObj) => {
    let test = wmText;
    if (!test) return test;
    const signMap: AnyObj = {
      '$HRM_Name': window.TEAMS?.currentUser?.name || '', // 当前操作者姓名
      '$HRM_Num': window.TEAMS?.currentUser?.jobNum || '', // 当前操作者编号
      '$HRM_Mobile': window.TEAMS?.currentUser?.mobile || '', // 当前操作者移动电话
      '$HRM_Email': window.TEAMS?.currentUser?.email || '', // 当前操作者电子邮件
      '$HRM_LoginId': window.TEAMS?.loginAccountInfo?.currentLoginAccount || '', // 当前操作者登录名
      '$HRM_Department': window.TEAMS?.currentUser?.department?.name || '', // 当前操作者部门
      '$HRM_SecondDepartment': '', // 当前操作者分部
      '$HRM_CurrentDate': dayjs().format('YYYY-MM-DD') || '', // 当前日期
      '$HRM_CurrentTime': dayjs().format('HH:mm:ss') || '', // 当前时间
      ...map || {},
    };
    type KeyType = keyof typeof signMap;
    Object.keys(signMap).forEach((key) => {
      try {
        test = test.replace(new RegExp(key.replace('$', '\\$'), 'g'), signMap[key as KeyType]);
      } catch (e) {
        console?.error(e);
      }
    });
    return test;
  }

  // 格式化水印配置信息
  formatWMSetting = (wmSetting: SystemWMSetting, signMap?: AnyObj) => {
    const { wmClassify, wmImg, wmText, wmRotate, wmNoTransparent, wmWidth, wmHeight } = wmSetting || {};
    const rotate = - Number(wmRotate);
    const alpha = Number(wmNoTransparent) / 100;
    var options: WatermarkOptions = {
      width: Number(wmWidth) > 0 ? Number(wmWidth) : 200,
      height: Number(wmHeight) > 0 ? Number(wmHeight) : 150,
      rotate,
      alpha,
    };
    if (wmClassify === 'image' && wmImg) {
      options.src = this.formatWMImg(wmImg);
    }
    if (wmClassify === 'text' && wmText) {
      options.text = this.formatWMText(wmText, signMap);
      options.src = ''; // 清空图片地址，防止全局水印已经存在图片水印
    }
    return options;
  }

  /* 设置水印配置 */
  set(options: WatermarkOptions) {
    if (!options) return undefined;
    if (options.container) {
      // 局部水印
      if (this.hasGlogbalWM()) {
        this.globalWatermarkInstance?.updateOptions({ zIndex: LowZIndex });
      }
      return new Watermark(options);
    } else if (this.hasGlogbalWM()) {
      this.globalWatermarkInstance?.updateOptions(options);
      return this.globalWatermarkInstance;
    } else {
      this.globalWatermarkInstance = new Watermark(options);
      return this.globalWatermarkInstance;
    }
  }

  /* 重置全局水印 */
  reset() {
    if (this.hasGlogbalWM()) {
      this.globalWatermarkInstance?.updateOptions({ zIndex: HighZIndex });
    }
  }

  /* 清除全局水印 */
  clear() {
    if (this.hasGlogbalWM()) {
      this.globalWatermarkInstance?.destory();
      this.globalWatermarkInstance = undefined;
    }
  }
}

const watermarkSDK = new WatermarkSDK();

export default watermarkSDK;
