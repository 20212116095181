import React, { Component, Children, ReactNode, ReactElement, cloneElement, FC, useCallback, useRef, useState, CSSProperties } from 'react';
import { classnames } from '@weapp/utils';
import { LAYOUTWEIGHT, SIDEWEIGHT } from '../../constants/index';
import { BoxItemType, BoxProps, BoxType, LayoutProps, LayoutState, LayoutBlocks, LayoutBlock } from './types';
import { LayoutContext } from './RowContext';
import { getRandom, needSync } from '../../utils';
import { findDOMNode } from 'react-dom';

// 禁用 document user-select
export const disabledUserSelect = () => {
  const root = document.documentElement;
  if (root && root.style) {
    root.style.userSelect = 'none';
    root.style['-webkit-user-select' as any] = 'none'; // 兼容ios
  }
}

// 启用 document user-select
export const enableUserSelect = () => {
  const root = document.documentElement;
  if (root && root.style) {
    root.style.userSelect = 'auto';
    root.style['-webkit-user-select' as any] = 'auto'; // 兼容ios
  }
}

// Content Box 最小宽度
const ContentBoxMinWidth = 30;

// 侧边栏最小宽度
const SideBoxMinWidth = 120;

// 侧边栏最大宽度
const SideBoxMaxWidth = 500;

const BoxBlock: FC<any> = (props) => {
  const { prefixCls, type, outer, right, draggable, memoryWidth, getLayout, getLayoutSetKey, updateContentWidth, onDrag: _onDrag, onDragWidthStop: _onDragWidthStop } = props;
  const layout = getLayout(type, outer, right);
  const layoutKey = getLayoutSetKey(type, outer, right);
  let content = null;

  const [dragging, setDragging] = useState(false);
  // 记录鼠标按下瞬间的位置
  const positionX = useRef(0);
  // 记录box按下瞬间的宽度
  const boxWidth = useRef(0);
  // 实时监听鼠标位置
  const positionMoveX = useRef(0);

  const setRef = useCallback((ref: HTMLDivElement) => {
    layout.ref = ref;
    if (!ref) return;
    if (layout.refWidth && type === 'side') {
      layout.ref.style.width = layout.refWidth;
    }
    if (draggable) {
      if (memoryWidth) {
        const lsWidth = Number(localStorage.getItem(`layout-${layout.weId}-width`));
        if (lsWidth) {
          let newWidth = lsWidth;
          if (layout.minWidth && newWidth < layout.minWidth) {
            newWidth = layout.minWidth;
          }
          if (layout.maxWidth && newWidth > layout.maxWidth) {
            newWidth = layout.maxWidth;
          }
          layout.ref.style.width = `${newWidth}px`;
        }
      } else {
        if (layout.memoryedWidth) {
          layout.ref.style.width = `${layout.memoryedWidth}px`;
        }
      }
    }
    if (layout.hidden && type === 'side') {
      layout.ref.style.width = '0px';
    }
  }, [draggable, memoryWidth, layout, type]);

  let draggableCom;
  const onDragWidth = useCallback((event) => {
    positionMoveX.current = event.clientX;
    let mouseMoveX = (right ? -1 : 1) * (positionMoveX.current - positionX.current);
    if (window.weappUtils?.needRTL?.()) {
      // 页面开启翻转后，布局左右拖拽方向需要翻转下
      mouseMoveX *= -1;
    }
    let newWidth = boxWidth.current + mouseMoveX;
    if (newWidth < layout.minWidth) {
      newWidth = layout.minWidth;
    }
    if (newWidth > layout.maxWidth) {
      newWidth = layout.maxWidth;
    }
    if (memoryWidth) {
      localStorage.setItem(`layout-${layout.weId}-width`, String(newWidth));
    } else {
      layout.memoryedWidth = newWidth;
    }
    layout.ref.style.width = `${newWidth}px`;
  }, [right, memoryWidth, layout]);

  const onDragWidthStop = useCallback((event) => {
    const dom = findDOMNode(event.target);
    if (dom && dom.ownerDocument) {
      setDragging(false);
      enableUserSelect();
      positionMoveX.current = 0;
      updateContentWidth?.();
      _onDragWidthStop?.();
      dom.ownerDocument.removeEventListener('mousemove', onDragWidth);
      dom.ownerDocument.removeEventListener('mouseup', onDragWidthStop);
    }
  }, [onDragWidth, _onDragWidthStop, updateContentWidth]);
  const onDrag = useCallback((event) => {
    const dom = findDOMNode(event.target);
    if (dom && dom.ownerDocument) {
      setDragging(true);
      disabledUserSelect();
      positionX.current = event.clientX;
      boxWidth.current = layout.ref.clientWidth;
      _onDrag?.();
      dom.ownerDocument.addEventListener('mousemove', onDragWidth);
      dom.ownerDocument.addEventListener('mouseup', onDragWidthStop);
    }
  }, [layout.ref, _onDrag, onDragWidth, onDragWidthStop]);

  if (!layout || !layout.block) return content;

  if (draggable && type === 'side') {
    const resizeCls = classnames(`${prefixCls}-box-resize`,
      `show-resize-${right ? 'right' : 'left'}`);
    draggableCom = (
      <div
        weId={`${props.weId || ''}_xi8hah`}
        onMouseDown={onDrag}
      >
        <div className={resizeCls} />
      </div>
    );
  }

  let colCls = classnames({
    [`${prefixCls}-${type}`]: type,
    'layout-box-block': type === 'side' || type === 'content',
    'is-dragging': dragging,
  }, `${layoutKey}-col`, layout.className);

  content = layout.block;

  if (needSync('hidden', layout)) {
    // 同步box的hidden状态
    content = React.cloneElement(content, { hidden: layout.hidden });
  }

  content = (
    <div key={layoutKey} weId={`${props.weId || ''}_ev39x1@${layoutKey}`} className={colCls} ref={setRef}>
      {draggableCom}
      {content}
    </div>
  );
  return content;
}

class Layout extends Component<LayoutProps, LayoutState> {
  static displayName = 'Layout';

  state = {
    count: 0
  };

  layoutSet: LayoutBlocks = {};
  maxTimmerCounter = 5;
  updateTimmer: any;
  innerContainerRef: HTMLDivElement | undefined;
  containerRef: HTMLDivElement | undefined;

  componentDidMount() {
    window.addEventListener('resize', this.updateContentWidth, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateContentWidth, false);
  }

  getLayoutSetKey = (type: BoxItemType = 'content', outer: boolean = false, right: boolean = false) => {
    let key = `${type}`;
    if (type !== 'content') {
      key += outer ? '-outer' : '-inner';
    }
    if (type === 'side') {
      key += right ? '-right' : '-left';
    }
    return key;
  }

  getValueFromNode = (child: ReactElement): LayoutBlock => {
    const layoutBox = child as ReactElement<BoxProps>;
    const {
      weId, type = 'content', outer = false, right = false,
      hidden, hiddenControlled, weight, width, maxWidth, minWidth,
    } = layoutBox.props;
    return {
      weId, type, outer, right, hidden, weight, width, maxWidth, minWidth, hiddenControlled,
    };
  }

  getSideLayoutWidth = (type: BoxItemType = 'content', outer: boolean = false, right: boolean = false, width: number) => {
    const { draggable, memoryWidth } = this.props;
    if (!width) return 0;
    const layout = this.getLayout(type, outer, right);
    let layoutWidth = 0;
    if (layout.weId && !layout.hidden) {
      if (draggable) {
        if (memoryWidth) {
          const localWidth = Number(localStorage.getItem(`layout-${layout.weId}-width`));
          if (localWidth) {
            let newWidth = localWidth;
            if (layout.minWidth && newWidth < layout.minWidth) {
              newWidth = layout.minWidth;
            }
            if (layout.maxWidth && newWidth > layout.maxWidth) {
              newWidth = layout.maxWidth;
            }
            layoutWidth = newWidth;
          }
        } else {
          if (layout.memoryedWidth) {
            layoutWidth = layout.memoryedWidth;
          }
        }
      }
      if (!layoutWidth) {
        if (needSync('width', layout)) {
          layoutWidth = layout.width as number;
        } else if (layout.weight) {
          layoutWidth = Math.ceil((layout.weight * width) / LAYOUTWEIGHT);
        }
      }
    }
    layout.refWidth = `${layoutWidth}px`;
    if (layout.ref) {
      layout.ref.style.width = `${layoutWidth}px`;
    }
    return layoutWidth;
  }

  emmitAsyncUpdate = () => {
    this.maxTimmerCounter = this.maxTimmerCounter - 1;
    if (this.updateTimmer) clearTimeout(this.updateTimmer);
    if (this.maxTimmerCounter <= 0) return;
    this.updateTimmer = setTimeout(() => {
      // console.log('timeout');
      if (this.maxTimmerCounter <= 0) {
        clearTimeout(this.updateTimmer);
      } else {
        this.updateContentWidth();
      }
    }, 100);
  }

  updateLayout = () => {
    const { memoryWidth } = this.props;
    if (this.containerRef && this.containerRef.clientWidth) {
      const w = this.containerRef.clientWidth;
      const ol = this.getSideLayoutWidth('side', true, false, w);
      const or = this.getSideLayoutWidth('side', true, true, w);
      const iw = w - ol - or;
      const il = this.getSideLayoutWidth('side', false, false, iw);
      const ir = this.getSideLayoutWidth('side', false, true, iw);
      const cr = this.getLayout('content', false, false);
      if (this.innerContainerRef) {
        this.innerContainerRef.style.width = '100%';
      }
      if (cr.ref && w) {
        cr.ref.style.width = '100%';
      }
      // 宽度更新
      if (ol) {
        const olLayout = this.getLayout('side', true, false);
        olLayout.maxWidth = Math.min(w - or - il - ir - (cr.minWidth || ContentBoxMinWidth), SideBoxMaxWidth);
        olLayout.minWidth = olLayout.minWidth || olLayout.originWidth || SideBoxMinWidth;
        let nol = ol;
        if (olLayout.minWidth && olLayout.minWidth > nol) {
          nol = olLayout.minWidth;
        }
        if (olLayout.maxWidth && olLayout.maxWidth < nol) {
          nol = olLayout.maxWidth;
        }
        if (memoryWidth) {
          localStorage.setItem(`layout-${olLayout.weId}-width`, String(nol));
        } else {
          olLayout.memoryedWidth = nol;
        }
        olLayout.ref!.style.width = nol + 'px';
      }
      if (or) {
        const orLayout = this.getLayout('side', true, true);
        orLayout.maxWidth = Math.min(w - ol - il - ir - (cr.minWidth || ContentBoxMinWidth), SideBoxMaxWidth);
        orLayout.minWidth = orLayout.minWidth || orLayout.originWidth || SideBoxMinWidth;
        let nor = or;
        if (orLayout.minWidth && orLayout.minWidth > nor) {
          nor = orLayout.minWidth;
        }
        if (orLayout.maxWidth && orLayout.maxWidth < nor) {
          nor = orLayout.maxWidth;
        }
        if (memoryWidth) {
          localStorage.setItem(`layout-${orLayout.weId}-width`, String(nor));
        } else {
          orLayout.memoryedWidth = nor;
        }
        orLayout.ref!.style.width = nor + 'px';
      }
      if (il) {
        const ilLayout = this.getLayout('side', false, false);
        ilLayout.maxWidth = Math.min(iw - ir - (cr.minWidth || ContentBoxMinWidth), SideBoxMaxWidth);
        ilLayout.minWidth = ilLayout.minWidth || ilLayout.originWidth || SideBoxMinWidth;
        let nil = il;
        if (ilLayout.minWidth && ilLayout.minWidth > nil) {
          nil = ilLayout.minWidth;
        }
        if (ilLayout.maxWidth && ilLayout.maxWidth < nil) {
          nil = ilLayout.maxWidth;
        }
        if (memoryWidth) {
          localStorage.setItem(`layout-${ilLayout.weId}-width`, String(nil));
        } else {
          ilLayout.memoryedWidth = nil;
        }
        ilLayout.ref!.style.width = nil + 'px';
      }
      if (ir) {
        const irLayout = this.getLayout('side', false, true);
        irLayout.maxWidth = Math.min(iw - il - (cr.minWidth || ContentBoxMinWidth), SideBoxMaxWidth);
        irLayout.minWidth = irLayout.minWidth || irLayout.originWidth || SideBoxMinWidth;
        let nir = ir;
        if (irLayout.minWidth && irLayout.minWidth > nir) {
          nir = irLayout.minWidth;
        }
        if (irLayout.maxWidth && irLayout.maxWidth < nir) {
          nir = irLayout.maxWidth;
        }
        if (memoryWidth) {
          localStorage.setItem(`layout-${irLayout.weId}-width`, String(nir));
        } else {
          irLayout.memoryedWidth = nir;
        }
        irLayout.ref!.style.width = nir + 'px';
      }
    }
  }

  scaleLayout = (newWidth?: number) => {
    const { memoryWidth } = this.props;
    if (!newWidth) return;
    if (this.containerRef && this.containerRef.clientWidth) {
      const w = this.containerRef?.clientWidth;
      const ol = this.getSideLayoutWidth('side', true, false, w) || 0;
      const or = this.getSideLayoutWidth('side', true, true, w) || 0;
      const iw = w - ol - or;
      const il = this.getSideLayoutWidth('side', false, false, iw) || 0;
      const ir = this.getSideLayoutWidth('side', false, true, iw) || 0;
      const cr = this.getLayout('content', false, false) || 0;

      if (this.innerContainerRef) {
        this.innerContainerRef.style.width = '100%';
      }
      if (cr.ref && w) {
        cr.ref.style.width = '100%';
      }
      // 宽度比例计算
      const olPercent = ol / w, orPercent = or / w, ilPercent = il / w, irPercent = ir / w;

      // 新宽度计算
      let nol = olPercent * newWidth, nor = orPercent * newWidth, nil = ilPercent * newWidth, nir = irPercent * newWidth;
      // 宽度更新
      if (ol) {
        const olLayout = this.getLayout('side', true, false);
        olLayout.maxWidth = Math.min(newWidth - nor - nil - nir - (cr.minWidth || ContentBoxMinWidth), SideBoxMaxWidth);
        olLayout.minWidth = olLayout.minWidth || olLayout.originWidth || SideBoxMinWidth;
        if (olLayout.minWidth && olLayout.minWidth > nol) {
          nol = olLayout.minWidth;
        }
        if (olLayout.maxWidth && olLayout.maxWidth < nol) {
          nol = olLayout.maxWidth;
        }
        if (memoryWidth) {
          localStorage.setItem(`layout-${olLayout.weId}-width`, String(nol));
        } else {
          olLayout.memoryedWidth = nol;
        }
        olLayout.ref!.style.width = nol + 'px';
      }
      if (or) {
        const orLayout = this.getLayout('side', true, true);
        orLayout.maxWidth = Math.min(newWidth - nol - nil - nir - (cr.minWidth || ContentBoxMinWidth), SideBoxMaxWidth);
        orLayout.minWidth = orLayout.minWidth || orLayout.originWidth || SideBoxMinWidth;
        if (orLayout.minWidth && orLayout.minWidth > nor) {
          nor = orLayout.minWidth;
        }
        if (orLayout.maxWidth && orLayout.maxWidth < nor) {
          nor = orLayout.maxWidth;
        }
        if (memoryWidth) {
          localStorage.setItem(`layout-${orLayout.weId}-width`, String(nor));
        } else {
          orLayout.memoryedWidth = nor;
        }
        orLayout.ref!.style.width = nor + 'px';
      }
      if (il) {
        const ilLayout = this.getLayout('side', false, false);
        ilLayout.maxWidth = Math.min(newWidth - nol - nor - nir - (cr.minWidth || ContentBoxMinWidth), SideBoxMaxWidth);
        ilLayout.minWidth = ilLayout.minWidth || ilLayout.originWidth || SideBoxMinWidth;
        if (ilLayout.minWidth && ilLayout.minWidth > nil) {
          nil = ilLayout.minWidth;
        }
        if (ilLayout.maxWidth && ilLayout.maxWidth < nil) {
          nil = ilLayout.maxWidth;
        }
        if (memoryWidth) {
          localStorage.setItem(`layout-${ilLayout.weId}-width`, String(nil));
        } else {
          ilLayout.memoryedWidth = nil;
        }
        ilLayout.ref!.style.width = nil + 'px';
      }
      if (ir) {
        const irLayout = this.getLayout('side', false, true);
        irLayout.maxWidth = Math.min(newWidth - nol - nor - nil - (cr.minWidth || ContentBoxMinWidth), SideBoxMaxWidth);
        irLayout.minWidth = irLayout.minWidth || irLayout.originWidth || SideBoxMinWidth;
        if (irLayout.minWidth && irLayout.minWidth > nir) {
          nir = irLayout.minWidth;
        }
        if (irLayout.maxWidth && irLayout.maxWidth < nir) {
          nir = irLayout.maxWidth;
        }
        if (memoryWidth) {
          localStorage.setItem(`layout-${irLayout.weId}-width`, String(nir));
        } else {
          irLayout.memoryedWidth = nir;
        }
        irLayout.ref!.style.width = nir + 'px';
      }
    }
  }

  updateContentWidth = () => {
    if (this.containerRef && this.containerRef.clientWidth) {
      const w = this.containerRef.clientWidth;
      const ol = this.getSideLayoutWidth('side', true, false, w);
      const or = this.getSideLayoutWidth('side', true, true, w);
      const iw = w - ol - or;
      const il = this.getSideLayoutWidth('side', false, false, iw);
      const ir = this.getSideLayoutWidth('side', false, true, iw);
      const cr = this.getLayout('content', false, false);
      if (this.innerContainerRef) {
        this.innerContainerRef.style.width = '100%';
      }
      if (cr.ref && w) {
        cr.ref.style.width = '100%';
      }
      if (ol || or) {
        const olLayout = this.getLayout('side', true, false);
        const orLayout = this.getLayout('side', true, true);
        olLayout.maxWidth = Math.min(w - or - il - ir - (cr.minWidth || ContentBoxMinWidth), olLayout.maxWidth || SideBoxMaxWidth);
        olLayout.minWidth = olLayout.minWidth || olLayout.originWidth || SideBoxMinWidth;
        orLayout.maxWidth = Math.min(w - ol - il - ir - (cr.minWidth || ContentBoxMinWidth), orLayout.maxWidth || SideBoxMaxWidth);
        orLayout.minWidth = orLayout.minWidth || orLayout.originWidth || SideBoxMinWidth;
      }
      if (il || ir) {
        const ilLayout = this.getLayout('side', false, false);
        const irLayout = this.getLayout('side', false, true);
        ilLayout.maxWidth = Math.min(iw - ir - (cr.minWidth || ContentBoxMinWidth), ilLayout.maxWidth || SideBoxMaxWidth);
        ilLayout.minWidth = ilLayout.minWidth || ilLayout.originWidth || SideBoxMinWidth;
        irLayout.maxWidth = Math.min(iw - il - (cr.minWidth || ContentBoxMinWidth), irLayout.maxWidth || SideBoxMaxWidth);
        irLayout.minWidth = irLayout.minWidth || irLayout.originWidth || SideBoxMinWidth;
      }
    } else {
      this.emmitAsyncUpdate();
    }
  }

  setInnerContainerRef = (ref: HTMLDivElement) => {
    this.innerContainerRef = ref;
  }

  setContainerRef = (ref: HTMLDivElement) => {
    this.containerRef = ref;
    this.emmitAsyncUpdate();
  }

  setLayout = (child: ReactElement, oldLayoutSets: LayoutBlocks) => {
    let needUpdate = false;
    const { draggable, memoryWidth, getBoxHidden } = this.props;
    const boxInfo = this.getValueFromNode(child);
    const { weId, type, outer, right, hidden, weight, width, maxWidth, minWidth, hiddenControlled } = boxInfo;
    const key = this.getLayoutSetKey(type, outer, right);
    const layoutSet = this.getLayout(type, outer, right, oldLayoutSets);
    layoutSet.weId = weId || getRandom();
    layoutSet.originWidth = width; // 记录原始宽度
    layoutSet.block = child;
    if (hiddenControlled) {
      let newHidden: boolean | undefined;
      if (getBoxHidden) {
        newHidden = getBoxHidden?.(boxInfo);
      } else {
        newHidden = hidden;
      }
      if (newHidden !== layoutSet.hidden) {
        layoutSet.hidden = newHidden;
        needUpdate = true;
      }
    } else if (hidden !== undefined && layoutSet.hidden === undefined) {
      layoutSet.hidden = hidden;
    }
    let newWeight;
    if (weight !== undefined) {
      newWeight = weight;
    } else if (type === 'side') {
      newWeight = SIDEWEIGHT;
    } else if (type === 'header' || type === 'footer') {
      newWeight = LAYOUTWEIGHT;
    }
    if (layoutSet.weight !== newWeight) {
      layoutSet.weight = newWeight;
      needUpdate = true;
    }
    let newWidth;
    if (draggable) {
      if (memoryWidth) {
        const lsWidth = Number(localStorage.getItem(`layout-${layoutSet.weId}-width`));
        if (lsWidth) {
          newWidth = lsWidth;
        }
      } else {
        if (layoutSet.memoryedWidth) {
          newWidth = layoutSet.memoryedWidth;
        }
      }
    }
    if (maxWidth) {
      layoutSet.maxWidth = maxWidth;
      newWidth = Math.min(maxWidth, newWidth);
    }
    if (minWidth) {
      layoutSet.minWidth = minWidth;
      newWeight = Math.max(minWidth, newWidth);
    }
    if (!newWidth && width !== undefined) {
      newWidth = width;
    }
    if (layoutSet.width !== newWidth) {
      layoutSet.width = newWidth;
      needUpdate = true;
    }
    this.layoutSet[key] = layoutSet;
    return needUpdate;
  }

  getLayout = (type: BoxItemType = 'content', outer: boolean = false, right: boolean = false, layoutSets?: LayoutBlocks) => {
    const { layout } = this.props;
    const key = this.getLayoutSetKey(type, outer, right);
    let layoutSet = (layoutSets || this.layoutSet)[key] || {
      type, outer, right,
    };
    const l = (layout || []).find(item => key === this.getLayoutSetKey(item.type, item.outer, item.right));
    if (l) {
      layoutSet = {
        ...layoutSet,
        ...l
      };
    }
    return layoutSet;
  }

  changeLayoutVisible = (type: BoxItemType = 'content', outer: boolean = false, right: boolean = false, hidden: boolean = false) => {
    const key = this.getLayoutSetKey(type, outer, right);
    const layout = this.getLayout(type, outer, right);
    if (layout) {
      layout.hidden = hidden;
      this.layoutSet[key] = layout;
      this.setState({ count: this.state.count + 1 }, this.updateContentWidth);
      // window.document.dispatchEvent(this.hidePolyfill);
    }
  }

  onDragWidthStop = () => {
    const { onDragWidthStop } = this.props;
    if (typeof onDragWidthStop === 'function') {
      const params = this.getLayoutWidth();
      onDragWidthStop(params)
    }
  }

  getLayoutWidth = () => {
    const w = this.containerRef?.clientWidth || 0;
    const ol = this.getSideLayoutWidth('side', true, false, w) || 0;
    const or = this.getSideLayoutWidth('side', true, true, w) || 0;
    const iw = w - ol - or;
    const il = this.getSideLayoutWidth('side', false, false, iw) || 0;
    const ir = this.getSideLayoutWidth('side', false, true, iw) || 0;
    const cr = this.getLayout('content', false, false).ref?.clientWidth || 0;
    return {
      innerLeftWidth: il,
      innerRightWidth: ir,
      outerLeftWidth: ol,
      outerRigthWidth: or,
      layoutWidth: w,
      contentWidth: cr,
    }
  }

  processBox = (child: ReactNode, oldLayoutSets: LayoutBlocks) => {
    // TODO：过滤无效数据数据
    if (!React.isValidElement(child)) return;
    let cEle = child as ReactElement<BoxProps, BoxType>;
    if (cEle.type.displayName !== 'weappUi.LayoutBox') return;
    const { customChild } = this.props;
    if (customChild) {
      child = customChild(child, this.getValueFromNode(child));
    }
    if (!React.isValidElement(child)) return;
    cEle = child as ReactElement<BoxProps, BoxType>;
    if (cEle.type.displayName !== 'weappUi.LayoutBox') return;
    // 根据type，outer，right属性将子元素划分到不同的区块中
    return this.setLayout(child, oldLayoutSets);
  }

  renderBlock = (type: BoxItemType = 'content', outer: boolean = false, right: boolean = false) => {
    const { prefixCls, draggable, memoryWidth } = this.props;
    return (
      <BoxBlock
        weId={`${this.props.weId || ''}_d74nmg`}
        prefixCls={prefixCls}
        key={this.getLayoutSetKey(type, outer, right)}
        type={type}
        outer={outer}
        right={right}
        draggable={draggable}
        memoryWidth={memoryWidth}
        getLayout={this.getLayout}
        getLayoutSetKey={this.getLayoutSetKey}
        updateContentWidth={this.updateContentWidth}
        onDragWidthStop={this.onDragWidthStop}
      />
    );
  }

  renderLayout = () => {
    const { prefixCls, style, className, children } = this.props;
    // 将box插入到集合中
    const oldLayoutSet = this.layoutSet;
    this.layoutSet = {};
    let needUpdate = false;
    if (oldLayoutSet) {
      Children.forEach(children, (child) => {
        if (this.processBox(child, oldLayoutSet)) {
          needUpdate = true;
        }
      });
      // 对边前后box的长度，如果不一致，说明有box增减，需要重新计算布局
      if (Object.keys(oldLayoutSet).length !== Object.keys(this.layoutSet).length) {
        needUpdate = true;
      }
      if (needUpdate) {
        this.updateContentWidth();
      }
    }

    let content = this.renderBlock('content', false, false);

    const innerHeader = this.renderBlock('header', false, false);
    const innerFooter = this.renderBlock('footer', false, false);
    const innerLeftSide = this.renderBlock('side', false, false);
    const innerRightSide = this.renderBlock('side', false, true);

    const outerHeader = this.renderBlock('header', true, false);
    const outerFooter = this.renderBlock('footer', true, false);
    const outerLeftSide = this.renderBlock('side', true, false);
    const outerRightSide = this.renderBlock('side', true, true);

    const hasInner = innerHeader || innerFooter || innerLeftSide || innerRightSide;
    const hasOuter = outerHeader || outerFooter || outerLeftSide || outerRightSide;

    if (hasInner) {
      if (innerLeftSide || innerRightSide) {
        if (content) {
          content = cloneElement(content, {
            className: classnames(content.props.className, `${prefixCls}-children`)
          });
        }
        content = (
          <div className={classnames(prefixCls, `${prefixCls}-has-side`)} weId={`${this.props.weId || ''}_eo5sp3`} ref={this.setContainerRef}>
            {innerLeftSide}
            {content}
            {innerRightSide}
          </div>
        );
      }
      if (innerHeader || innerFooter) {
        if (content) {
          content = cloneElement(content, {
            className: classnames(content.props.className, `${prefixCls}-children`)
          });
        }
        content = (
          <div className={classnames(prefixCls)}>
            {innerHeader}
            {content}
            {innerFooter}
          </div>
        );
      }
      if (hasOuter && content) {
        content = cloneElement(content, {
          // ref: (outerLeftSide || outerRightSide) ? this.setInnerContainerRef : undefined,
          style: { width: '100%' },
          className: classnames(
            content.props.className,
            { [`${prefixCls}-inner-container`]: outerLeftSide || outerRightSide },
            !(innerHeader || innerFooter || outerHeader || outerFooter) ? `${prefixCls}-has-side` : prefixCls,
          )
        });
      }
    }

    if (hasOuter) {
      if (outerLeftSide || outerRightSide) {
        if (content) {
          content = cloneElement(content, {
            className: classnames(content.props.className, `${prefixCls}-children`)
          });
        }
        content = (
          <div className={classnames(prefixCls, `${prefixCls}-has-side`)} weId={`${this.props.weId || ''}_mvkubn`} ref={this.setContainerRef}>
            {outerLeftSide}
            {content}
            {outerRightSide}
          </div>
        );
      }
      if (outerHeader || outerFooter) {
        if (content) {
          content = cloneElement(content, {
            className: classnames(content.props.className, `${prefixCls}-children`)
          });
        }
        content = (
          <div className={prefixCls}>
            {outerHeader}
            {content}
            {outerFooter}
          </div>
        );
      }
    }

    if (content) {
      const cls = classnames(prefixCls, className);
      content = cloneElement(content, {
        className: `${cls} ${content?.props.className || ''}`,
        style
      });
    }
    return content;
  }

  render() {
    const { customRender } = this.props;
    const content = (this.renderLayout());
    return (
      <LayoutContext.Provider weId={`${this.props.weId || ''}_fo9uh9`} value={this.changeLayoutVisible}>
        {customRender ? customRender(this, content) : content}
      </LayoutContext.Provider>
    );
  }
}

export default Layout;
