import ReactDOM from 'react-dom';
import { PhotoViewData, PhotoViewProps } from './types';
import { PhotoView } from './index';
import { weappSDK, ua } from '@weapp/utils'

export default function showImage(data: Array<PhotoViewData>, photoIndex?: number, otherParams?: PhotoViewProps | any) {
  function renderPhoto() {
    console.info('render WeappUi-PhotoView success')
    const div = document.createElement('div');
    document.body.appendChild(div);
    function handleClose() {
      ReactDOM.unmountComponentAtNode(div);
      if (div.parentNode) {
        div.parentNode.removeChild(div);
      }
    };
    const render = () => {
      ReactDOM.render(<PhotoView weId={`liwxm8`}
        data={data}
        visible={true}
        onClose={handleClose}
        photoIndex={photoIndex}
        {...otherParams}
      />, div);
    };
    render();
  }
  const isWeappPC = ua.browser === 'WeappPC'
  if (isWeappPC) {
    weappSDK.checkApi('independentPhotoView').then(() => {
      weappSDK.exec('independentPhotoView', {
        data: data,
        photoIndex: photoIndex || 0,
        ...otherParams
      }).then((res: any) => {
        console.info(`exec independentPhotoView success`, res);
      }).catch((err: any) => {
        console.error(`exec independentPhotoView fail`, err);
        renderPhoto()
      });
    }).catch((err) => {
      console.error(`check independentPhotoView fail`, err);
      renderPhoto()
    });
  } else {
    renderPhoto()
  }
}
