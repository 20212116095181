import { CustomHidesProps, FormDatas, FormLayoutProps, FormLayoutType, GroupProps } from "./types";
import {
  utils, FormItemFieldType, FormItemProps, AnyObj, FieldProps, SelectValueType, isMultiDatePickerType, DatePicker, DatePickerDateType, DatePickerPrimaryKeyType,
  doValidator, testStringSetLocale, SecretSelect
} from '../../lib';
import { dayjs, ua } from "@weapp/utils";
import { action, isObservableArray, observable, toJS } from "mobx";
const { isNumber } = utils;

const { deepClone, getRandom, isEqual, needSync, isNil, isObject, isValueEmpty } = utils;
const { getRangeInfoByPrimaryKey } = DatePicker;

export type ValidatorRules = {
  [key: string]: string,
}
interface SingleItemProps {
  [key: string]: any,
}
export interface AllDatas {
  data: FormDatas,
  groups: Array<GroupProps>,
  layout: Array<FormLayoutType>,
  items: FormItemProps,
  isMobile?: boolean,
  hoverEdit?: boolean,
  customLayout?: CustomLayoutProps,
  forbidSyncDatasFromItems?: boolean, // 禁止同步items中的数据至data，即默认值完全以data内设置的默认值为准
}
export interface CustomLayoutProps {
  [key: string]: Array<FormLayoutType>;
}
export interface FormStoreProps {
  afterInitForm?: () => void;
  afterChangeData?: () => void;
}
export interface CustomValidateAction {
  hideErrors?: boolean; //隐藏error提示信息
}
export default class FormStore {
  afterInitForm?: () => void;
  afterChangeData?: (value?: FormDatas) => void;

  constructor(props?: FormStoreProps) {
    if (props?.afterInitForm) this.afterInitForm = props?.afterInitForm;
    if (props?.afterChangeData) this.afterChangeData = props?.afterChangeData;
  }
  initDatas: FormDatas = {};
  initLayout: Array<FormLayoutType> = []; // 如有字段联动处理，则需要重置布局
  initItems: FormItemProps = {};
  id: any = `ui-form-store-${getRandom()}`;
  @observable isFormInit: boolean = false;
  @observable datas: FormDatas = {};
  @observable groups: Array<GroupProps> = [];
  @observable layout: Array<FormLayoutType> = [];
  @observable items: FormItemProps = {};
  @observable errors: any = {};

  @observable allFields?: FieldProps[] = [];
  @observable customLayout: CustomLayoutProps = {};
  @observable initCustomLayout: CustomLayoutProps = {};
  /**
   * prohibitCheckLayoutIds: 禁止校验字段layoutId 集合
   * 单开一个参数原因：
   * 1.初始化items无其他分组的字段配置，后续新增数据不在mobx对象监听范围，observe观测不到，导致setItemProps方式更新不生效
   * 2.强制更新items，则导致全量渲染，页面卡顿
   */
  @observable prohibitCheckLayoutIds: Set<string> = new Set(); // 禁止校验字段key集合

  @observable customHides: CustomHidesProps = {}; // 用于自定义隐藏或显示字段，目前高级搜索内部使用，功能同props.customHide方法
  @observable isMobile?: boolean = false; // 移动端标识
  @observable activeKey: string[] = []; // 展开面板id值
  @observable validateHiddenField?: boolean = false; // 校验隐藏字段(layout纬度)，默认不校验
  @observable validateHiddenItems?: boolean = true; // 校验隐藏字段(items纬度)，默认校验
  @observable hoverEdit?: boolean = false; // 鼠标移入编辑模式
  @observable fixedLabelWidth?: boolean = false; // 标签固定宽度模式
  @observable allReadOnly?: boolean = true; // 表单全局只读，用于移动端表单只读字段标识
  @observable forbidSyncDatasFromItems?: boolean = false;

  @observable _overflowStyle: AnyObj = {}; // 展开面板overflow样式，内部使用(处理提示信息被遮挡问题)
  @observable customColSpan: number | undefined = undefined;
  @observable customLabelSpan: number | undefined = undefined;
  itemGroupMapping?: AnyObj;
  ruleGroupMapping?: AnyObj;
  @action
  setProhibitCheckLayoutIds = (val: string) => {
    this.prohibitCheckLayoutIds?.add(val);
  }
  @action
  delProhibitChecklayoutIds = (val: string) => {
    this.prohibitCheckLayoutIds.delete(val);
  }

  @action
  clear = () => {
    this.isFormInit = false;
    this.datas = {};
    this.groups = [];
    this.layout = [];
    this.items = {};
    this.allFields = [];
    this.customLayout = {};
    this.initCustomLayout = {};
  }

  @action
  setState = (params: Partial<FormStoreType>) => {
    Object.keys(params).forEach((key) => {
      const storeKey = key as keyof FormStoreType;
      this[storeKey] = params[storeKey];
    })
  }
  // 设置展开面板
  @action
  setActiveKey = (activeKey: string[]) => {
    this.activeKey = activeKey;
  }

  // 获取表单字段默认值
  @action
  getDefaultValue = (itemType: FormItemFieldType, key: string, empty?: boolean, items?: FormItemProps) => {
    let dataKey = key;
    if (key.indexOf('_customField') >= 0) { // 自定义新增字段
      dataKey = key.slice(0, key.indexOf('_customField'));
    }
    if (itemType === 'PLUGIN' && this.initDatas?.[dataKey]) return this.initDatas?.[dataKey];
    if (!empty && this.initDatas?.[dataKey]) return this.initDatas?.[dataKey];
    const nowItems = items || this.items;
    if (itemType === 'INPUTNUMBER') return '';
    else if (itemType === 'RADIO') return '';
    else if (itemType === 'SCOPE') return { min: '', max: '' };
    else if (itemType === 'CHECKBOX') return nowItems && nowItems[dataKey] && (nowItems[dataKey].data || nowItems[dataKey].otherParams?.data || nowItems[dataKey].options || nowItems[dataKey].otherParams?.options) ? [] : false;
    else if (itemType === 'DATEPICKER') {
      return nowItems && nowItems[dataKey] && (nowItems[dataKey].isRange || nowItems[dataKey].otherParams?.isRange ||
        isMultiDatePickerType(nowItems[dataKey].type) || isMultiDatePickerType(nowItems[dataKey].otherParams?.type)) ? { selectedKey: '', value: [] } : '';
    }
    else if (itemType === 'DATETIMEPICKER') {
      if (this.isMobile) {
        return nowItems && nowItems[dataKey] && (nowItems[dataKey].isRange || nowItems[dataKey].otherParams?.isRange) &&
          (nowItems[dataKey].inSearchAdvanced || nowItems[dataKey].otherParams?.inSearchAdvanced)
          ? { selectedKey: '', value: [] } : '';
      }
      return nowItems && nowItems[dataKey] && (nowItems[dataKey].isRange || nowItems[dataKey].otherParams?.isRange) ? { selectedKey: '', value: [] } : '';
    }
    else if (itemType === 'TIMEPICKER') return nowItems && nowItems[dataKey] && (nowItems[dataKey].isRange || nowItems[dataKey].otherParams?.isRange) ? [] : '';
    else if (itemType === 'BROWSER' || itemType === 'BROWSERWINDOW' || itemType === 'TYPESBROWSER') return [];
    else if (itemType === 'FILTERBUTTONS') return nowItems[key]?.multiple || nowItems[key]?.otherParams?.multiple ? [] : '';
    else if (itemType === 'UPLOAD') return [];
    else if (itemType === 'SELECT' && (nowItems?.[dataKey]?.multiple || nowItems?.[dataKey]?.otherParams?.multiple)) return [];
    return '';
  }

  // 获取表单字段value
  @action
  getValue = (key: string, itemType: FormItemFieldType, items?: FormItemProps, empty?: boolean) => {
    if (empty) { // 仅获取空值
      return this.getDefaultValue(itemType, key, empty, items);
    }
    if (this.datas) {
      return this.datas?.[key] || this.getDefaultValue(itemType, key);
    }
    return this.getDefaultValue(itemType, key);
  }

  /**
  * getFormItemField, 获取当前字段配置信息，SearchAdvanced及Form组件中使用
  */
  @action
  getFormItemField = (labelId?: SelectValueType, layout?: Array<FormLayoutType>, items?: string[]) => {
    let res: FormLayoutProps = { id: '', label: '', items: items || [] };
    if (labelId) {
      layout?.forEach((lay) => {
        lay.forEach((la) => {
          if (labelId === la.id) {
            res = la;
          }
        })
      })
    }
    return res;
  }

  // 日期区间字段值转换
  @action
  transDefaultDate = (startDate: DatePickerDateType, endDate: DatePickerDateType, format: string) => {
    return [dayjs?.(startDate)?.format(format), dayjs?.(endDate)?.format(format)]
  }
  /**
   * isMatchDateRangeRules 是否日期区间字段
   * @param item 
   * @returns 
   */
  @action
  isMatchDateRangeRules = (item: SingleItemProps) => {
    return item && (
      (
        this.isMobile && item.itemType === 'DATETIMEPICKER' && (item.isRange || item.otherParams?.isRange) && (item.inSearchAdvanced || item.otherParams?.inSearchAdvanced)
      ) || (
        !this.isMobile && item.itemType === 'DATETIMEPICKER' && (item.isRange || item.otherParams?.isRange)
      ) || (
        !this.isMobile && item.itemType === 'DATEPICKER' && (item.isRange || item.otherParams?.isRange || isMultiDatePickerType(item.type) || isMultiDatePickerType(item.otherParams?.type))
      )
    );
  }

  @action
  transformRangeValue = (data: any, item: SingleItemProps, revert?: boolean) => {
    if (revert) {
      // 反向解析数据 --- 判断是否包含日期区间组件并且value为对象
      if (item && ['DATEPICKER', 'DATETIMEPICKER'].indexOf(item.itemType) >= 0 && Object.prototype.toString.call(data) === '[object Object]') {
        if ((item.selectedKeyPrior || item.otherParams?.selectedKeyPrior) && data.selectedKey) return data.selectedKey
        return data.value;
      }
      return data;
    }
    if (this.isMatchDateRangeRules(item)) {
      let value: any = {
        value: [],
        selectedKey: item.selectedKey || '',
      };
      const date = getRangeInfoByPrimaryKey(data as DatePickerPrimaryKeyType, item.weekStart || item.otherParams?.weekStart, item.customMultiBtnData || item.otherParams?.customMultiBtnData);
      const format = item.format || item.otherParams?.format || (
        item.itemType === 'DATEPICKER' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'
      )
      let dateProps = {};
      if (date !== -1) { // 除了区间组件的toady等数据，其余均为-1
        const result = this.transDefaultDate(date.startDate as DatePickerDateType, date.endDate as DatePickerDateType, format);
        dateProps = { value: result };
      }
      if (typeof data === "string") {
        value = {
          ...value,
          ...dateProps,
          selectedKey: data,
        }
      } else if (Object.prototype.toString.call(data) === '[object Array]') {
        value = {
          ...value,
          value: data,
        }
      } else if (Object.prototype.toString.call(data) === '[object Object]') {
        value = {
          value: data.value || [],
          ...dateProps,
          selectedKey: data.selectedKey || '',
          selectedName: data.selectedName || '',
        }
      }
      return value;
    }
    return data;
  }

  // 初始化表单-设置字段值 ----  datas默认值更新：保留原datas数据，默认值设置到items内的，更新到datas中。
  @action
  setInitData = (datas: FormDatas, item: FormItemProps, forbidSyncDatasFromItems?: boolean) => {
    const data = datas || {};
    if (item) {
      Object.keys(item).forEach((it) => {
        const value = this.transformRangeValue(item[it].value, item[it]);
        item[it].value = value;
        if (!data[it] && needSync('value', item[it]) && !forbidSyncDatasFromItems) { // 默认值优先级 data > items
          data[it] = value;
        } else {
          data[it] = this.transformRangeValue(data[it], item[it]);
        }
      })
      this.initDatas = data;
      this.datas = data;
    }
  }

  // 初始化表单/更新表单-设置字段配置 -----  datas更新后，将datas的数据更新到items中的value(不可在修改data(新建或修改))
  @action
  setItems = (items: FormItemProps, datas?: FormDatas, needSyncValueToData?: boolean) => {
    if (datas) {
      Object.keys(datas).forEach((da) => {
        const value = this.transformRangeValue(datas[da], items[da]);
        items[da] = {
          ...items[da],
          value,
          error: '',
        }
        if (needSyncValueToData && this.datas) this.datas[da] = value;
      })
    }
    this.items = items;
  }

  // 重置表单-重置字段配置
  @action
  resetItems = () => {
    const data = this.datas;
    if (data) {
      Object.keys(data).forEach((key) => {
        const initData = this.forbidSyncDatasFromItems ?
          needSync(key, this.initDatas) ? this.initDatas?.[key] : this.getDefaultValue(this.items[key].itemType, key) :
          this.initDatas?.[key] || this.getDefaultValue(this.items[key].itemType, key);
        let value = toJS(this.items[key].value);
        if (!isEqual(initData, value)) {
          this.items[key] = {
            ...this.items[key],
            value: initData,
          }
        }
      })
    }
  }

  // 初始化表单
  @action
  initForm = (allDatas: AllDatas) => { // 替换init
    const { data, items, groups, layout, isMobile, hoverEdit, customLayout, forbidSyncDatasFromItems } = deepClone(allDatas);
    this.isMobile = isMobile;
    this.forbidSyncDatasFromItems = forbidSyncDatasFromItems;
    if (hoverEdit) {
      Object.keys(items).forEach((itemKey) => {
        if (
          (!items[itemKey].readOnly && !items[itemKey].otherParams?.readOnly)
          && items[itemKey].itemType
          && ['RADIO', 'SWITCH', 'CHECKBOX', 'RATE', 'UPLOAD'].indexOf(items[itemKey].itemType) < 0
          && !items[itemKey].common
          && items[itemKey].hoverEdit !== false) { // items[itemKey].hoverEdit=false，单个字段关闭hoverEdit模式
          items[itemKey].readOnly = true;
          items[itemKey].hoverEdit = true;
        } else {
          items[itemKey].hoverEdit = false;
        }
      })
    }
    // if (isMobile || this.isMobile) {
    const keys = Object.keys(items);
    const len = keys.length;
    for (let i = 0; i < len; i++) {
      const it = items[keys[i]];
      if (!it.readOnly) {
        this.allReadOnly = false;
        break;
      }
    }
    // }

    //判断form中是否含有多语言组件，如果有的话，需要对多语言数据进行过滤
    // const containLocale = Object.keys(items).some((itemKey) => items[itemKey].itemType === 'LOCALE')
    //处理form的多语言数据
    // if (containLocale) {
    //对于data的处理
    if (!isNil(data)) {
      Object.keys(data).forEach((dataKey) => {
        const value = data[dataKey]
        if (typeof value === 'string') {
          data[dataKey] = testStringSetLocale(value)?.value
        }
      })
    }
    //对于items的处理
    if (!isNil(items)) {
      Object.keys(items).forEach((dataKey) => {
        const { value, itemType } = items[dataKey]
        if (typeof value === 'string') {
          items[dataKey].value = testStringSetLocale(value)?.value
        }
      })
    }
    // }

    this.setInitData(data, items, forbidSyncDatasFromItems);
    this.setItems(items, this.datas); // 用当前的datas修改item内value
    this.initItems = items;
    this.customLayout = deepClone(customLayout);
    this.groups = groups;
    this._overflowStyle = {}; // 置空上次保留的脏数据
    this.activeKey = groups?.filter((groupItem: { visible: boolean; }) => groupItem.visible !== false).map((groupItem: { id: string }) => {
      if (ua?.browser === 'IE') {
        this._overflowStyle[`${groupItem.id}`] = 'visible';
      } else {
        this._overflowStyle[`${groupItem.id}`] = 'unset';
      }
      return groupItem.id;
    });
    this.layout = layout;
    this.initLayout = layout;
    this.isFormInit = true;
    this.hoverEdit = hoverEdit;
    this.afterInitForm?.();
  }

  // 获取表单值
  @action
  getFormDatas = () => {
    const datas = deepClone(this.datas);
    if (datas && Object.prototype.toString.call(datas) === '[object Object]') { // 日期区间字段值，仅处理getFormDatas方法的返回值，不改变this.datas，否则影响受控
      Object.keys(datas).forEach((dataKey) => {
        const item = this.items[dataKey] || {};
        datas[dataKey] = this.transformRangeValue(datas[dataKey], item, true);
        if (item.itemType === 'LOCALE') datas[dataKey] = testStringSetLocale(datas[dataKey])?.value; // 多语言数据 带～～ 特殊处理
      })
    }
    return datas;
  }

  // 更新表单值
  @action
  updateDatas = (value?: FormDatas, needSyncValueToData?: boolean) => {
    this.datas = {
      ...this.datas,
      ...value,
    }
    this.setItems(this.items, value, needSyncValueToData);
  }

  // 重置表单
  @action
  resetForm = () => {
    this.resetItems();
    this.datas = {
      ...this.initDatas
    };
    this.customLayout = {};
    // 存在级联配置，需重置布局
    if (this.layout.find((row) => row.find((col) => needSync('cascadeRules', col) || needSync('cascadeRulesOuter', col)))) {
      this.layout = this.initLayout;
    }
  }

  // 设置自定义布局内容
  @action
  setLayoutProps = (id: string, layout: any) => {
    let row = null;
    for (let rowIndex = 0; rowIndex < this.layout.length; rowIndex++) {
      row = this.layout[rowIndex].find((col) => col.id == id);
      if (row) {
        this.layout[rowIndex].forEach((col) => {
          if (col.id == id) {
            Object.keys(layout).forEach((key, colIndex) => {
              col[key] = layout[key];
            })
          }
        })
        break;
      }
    }
  }

  // 设置自定义字段隐藏/显示
  @action
  setHide = (id: string, hide: boolean) => { // 设置字段显示或隐藏
    this.setLayoutProps(id, { hide });
  }

  // 设置字段配置
  @action
  setItemProps = (key: string, items: SingleItemProps) => {
    this.items[key] = {
      ...this.items[key],
      ...items,
    };
  }

  // 获取布局配置（按照分组）
  @action
  getLayoutConfig = (groupId: string) => {
    return this.layout.filter((row) => {
      return row && row[0].groupId === groupId;
    })
  }

  // 获取所有字段类型 --- custom=true 使用
  @action
  setAllFields = (allFields?: FieldProps[]) => {
    this.allFields = allFields;
  }

  // 初始化设置布局 -----   高级搜索内部使用
  @action
  setAllCustomLayout = (customLayout: CustomLayoutProps, init?: boolean) => {
    this.customLayout = customLayout;
    if (init) this.initCustomLayout = deepClone(customLayout);
  }

  // 获取所有布局 --- custom=true 使用
  @action
  setCustomLayout = (groupId: string, layouts: Array<FormLayoutType>) => {
    this.customLayout = {
      ...this.customLayout,
      [`${groupId}`]: layouts,
    }
  }

  // 设置自定义布局内容
  @action
  setSingleCustomLayout = (groupId: string, labelId: string, layout: any) => {
    let row = null;
    for (let rowIndex = 0; rowIndex < this.customLayout[groupId].length; rowIndex++) {
      row = this.customLayout[groupId][rowIndex].find((col) => col.labelId === labelId);
      if (row) {
        this.customLayout[groupId][rowIndex].forEach((col) => {
          if (col.labelId === labelId) {
            Object.keys(layout).forEach((key) => {
              col[key] = layout[key];
            })
          }
        })
        break;
      }
    }
  }

  // 新增布局 --- custom=true 使用
  @action
  getDefaultLabelValue = () => {
    let defaultVal = '';
    if (this.allFields && this.allFields.length > 0) {
      const temp = this.allFields.find((field) => field.selected && !field.disableCustomConditions);
      const res = this.allFields.find((field) => !field.disableCustomConditions);
      defaultVal = (temp ? temp.id : res?.id || this.allFields[0].id) || '';
    }
    return defaultVal;
  }
  /*
    addCustomLayout: 添加搜索条件
  */
  @action
  addCustomLayout = (groupId: string, colSpan?: number, selectedFieldId?: string, otherParams?: any) => {
    const layIndex = getRandom();
    const layout = this.layout;
    const fieldId: string = selectedFieldId || this.getDefaultLabelValue();
    const layProps = this.getFormItemField(fieldId, layout);
    const labelId = otherParams?.labelId || `customField${layIndex}`;
    // 存在级联，items初始化需取initLayout内的items配置
    const hasCascadeRules = needSync('cascadeRules', layProps) || needSync('cascadeRulesOuter', layProps);
    let initLayProps: AnyObj = {};
    if (hasCascadeRules) {
      initLayProps = this.getFormItemField(fieldId, this.initLayout);
    }
    const field: FormLayoutProps = {
      ...layProps,
      items: hasCascadeRules ? initLayProps?.items : layProps?.items,
      labelId, // 随机数标记，区分layout id重复的字段
      groupId,
      colSpan,
      selectedFieldId: fieldId, // label 下拉框默认选中项
      customId: fieldId, // 自定义组 layout id ---- 不用layout中的id项，原因：label下拉框选中项改变需直接改变当前layout中的id，会导致下拉框受控不对
      labelSpan: (isNumber(this.customLabelSpan) ? this.customLabelSpan : layProps.labelSpan) || 3,
      hide: false,
      disabled: false,
    }; // 从allField中获取
    const layoutNow = this.allFields?.find((row) => row.id === fieldId); // 修复联动字段初始化效果不对的问题，allFields中有items参数则以allFields中备份的设置为准
    if (layoutNow && layoutNow.items) {
      field.items = layoutNow.items;
    }
    const customLayout = this.customLayout || {};
    const layouts = customLayout[groupId] || [];
    const len = layouts?.length || 0;
    if (len === 0 || !colSpan) {
      layouts?.push(
        [field],
      )
    } else if (colSpan && layouts) {
      layouts[len - 1].push(field);
    }
    // if (!this.customLayout) this.customLayout = {};
    // if (!this.customLayout[groupId]) this.customLayout[groupId] = [];
    let resValue: FormDatas = {}; // 新增默认值
    Object.keys(this.initItems).forEach((it) => {
      const value = this.getDefaultValue(this.items[it].itemType, it, !this.items[it].condition); // 条件关系默认值需带入
      /*
        1.items为实时更新的字段配置(添加搜索条件动作前存在通过setItemProps更改配置的行为)
        2.initItems为初始化存储的字段
        3.新增的字段应不受常用分组字段配置更改的影响(因此采用initItems)
      */
      this.items[`${it}_${labelId}`] = {
        ...this.initItems[it],
        value,
      }
      if (field.items && field.items.indexOf(it) >= 0) {
        if (!needSync(`${it}_${labelId}`, resValue)) {
          resValue[`${it}_${labelId}`] = value;
        }
        /**
         * http://10.12.101.12/FRONTEND/weapp-ui/-/issues/2487
         * 自定义新增的字段联动赋值处理, 级联内配置的datas需更新，对比 FormCol/doChangeLayout方法
         */
        const dataKey = value as any;
        const ruleGroup = field.cascadeRules && field.cascadeRules[it];
        const hasCascadeRules = Object.prototype.toString.call(ruleGroup) === '[object Object]' && ruleGroup[dataKey]?.datas;
        if (hasCascadeRules) {
          Object.keys(ruleGroup[dataKey]?.datas).forEach((datakey) => {
            resValue[`${datakey}_${labelId}`] = ruleGroup[dataKey]?.datas[datakey];
          })
        }
      }
    })
    if (typeof otherParams?.changeSAOldData === 'function') {
      const nowData = otherParams?.changeSAOldData(labelId);
      resValue = {
        ...resValue,
        ...nowData,
      }
    }
    // 自定义分组 先更新值，再改布局(渲染优化导致)，items不会更新整个对象
    this.updateDatas(resValue);
    this.setCustomLayout(groupId, layouts);
    return labelId;
  }

  // 改变label选中值 --- custom=true 使用
  @action
  onChangeLabel = (groupId: string, value: SelectValueType, labelId?: string, otherParams?: any) => {
    // 清空上一次标记的labelId集合及数据
    if (otherParams && labelId) {
      const { lastOptions, options } = otherParams;
      if (lastOptions) { // 清空上一次选中的字段数据
        const itemsKeys = lastOptions && lastOptions.items;
        itemsKeys && itemsKeys.forEach((key: string) => {
          delete this.datas[`${key}_${labelId}`];
        })
      }
      if (options) { // 添加本次选中的字段数据
        const itemsKeys = options && options.items;
        let values: FormDatas = {};
        itemsKeys && itemsKeys.forEach((key: string) => {
          if (!needSync(`${key}_${labelId}`, values)) { // 已存在值不需要再次更新数据
            values[`${key}_${labelId}`] = this.getDefaultValue(this.items[key]?.itemType, key, !this.items[key]?.condition); // 条件关系默认值需带入, 其他的默认值不带入
          }
          /**
           * http://10.12.101.12/FRONTEND/weapp-ui/-/issues/2487
           * 自定义新增的字段联动赋值处理, 级联内配置的datas需更新，对比 FormCol/doChangeLayout方法
           */
          const dataKey = values[`${key}_${labelId}`] as any;
          const ruleGroup = options.cascadeRules && options.cascadeRules[key];
          const hasCascadeRules = Object.prototype.toString.call(ruleGroup) === '[object Object]' && ruleGroup[dataKey]?.datas;
          if (hasCascadeRules) {
            Object.keys(ruleGroup[dataKey]?.datas).forEach((datakey) => {
              values[`${datakey}_${labelId}`] = ruleGroup[dataKey]?.datas[datakey];
            })
          }
        });
        this.updateDatas(values);
      }
    }
    // 修复选中常用筛选，切换字段，部分字段(常用条件组默认不包含且其他条件组默认不显示的字段)无法显示的问题
    const nowlayoutsItems = this.getFormItemField(value, this.layout) as FormLayoutProps;
    // 存在级联，items初始化需取initLayout内的items配置
    const hasCascadeRules = needSync('cascadeRules', nowlayoutsItems) || needSync('cascadeRulesOuter', nowlayoutsItems);
    let initLayProps: AnyObj = {};
    if (hasCascadeRules) {
      initLayProps = this.getFormItemField(value, this.initLayout);
    }
    // 1.补充丢失的items配置
    let items = nowlayoutsItems?.items || [];
    // 2.补充丢失的级联相关的items配置
    const cascadeRules = nowlayoutsItems.cascadeRules;
    if (cascadeRules) {
      Object.keys(cascadeRules).forEach((key) => {
        let ruleGroup = cascadeRules[key] || {};
        Object.keys(ruleGroup).forEach((k) => {
          let rule = ruleGroup[k];
          if (Array.isArray(rule) || isObservableArray(rule)) items = items.concat(rule);
          else if (isObject(rule)) items = items.concat(rule?.items || []);
        })
      })
    }

    items?.forEach((itemKey: string) => {
      if (!this.items[`${itemKey}_${labelId}`]?.itemType) {
        const value = this.getDefaultValue(this.items[itemKey]?.itemType, itemKey, !this.items[itemKey]?.condition); // 条件关系默认值需带入, 其他的默认值不带入
        this.items[`${itemKey}_${labelId}`] = {
          ...this.items[itemKey],
          value,
        }
      }
    })

    const res = this.customLayout[groupId].map((row) => {
      return row.map((col) => {
        if (col.labelId === labelId) {
          return {
            ...col,
            items: hasCascadeRules ? initLayProps?.items : nowlayoutsItems.items,
            helpTip: nowlayoutsItems.helpTip,
            cascadeRules: nowlayoutsItems.cascadeRules,
            customId: nowlayoutsItems.id,
            selectedFieldId: nowlayoutsItems.id,
            label: nowlayoutsItems.label,
          }
        }
        return col;
      })
    })
    this.setCustomLayout(groupId, res);
  }

  // 删除字段 --- custom=true 使用
  @action
  delField = (groupId?: string, labelId?: string) => {
    if (groupId) {
      let nowCustomLayout = this.customLayout[groupId].map((row) => {
        return row.filter((col) => {
          return col.labelId !== labelId;
        })
      })
      this.customLayout[groupId] = nowCustomLayout;
      // 删除datas脏数据
      let resDatas = {};
      const datas = this.datas;
      if (datas) {
        Object.keys(datas).forEach((dataKey) => {
          if (dataKey.indexOf('_') < 0 || (labelId && dataKey.indexOf(labelId) < 0)) {
            resDatas = {
              ...resDatas,
              [`${dataKey}`]: datas[dataKey],
            }
          }
        })
      }
      this.datas = resDatas;
    }
  }

  @action
  getCustomHide = (id: string) => {
    return this.customHides[id];
  }

  @action
  setLayout = (layout: Array<FormLayoutType>) => {
    this.layout = layout;
  }

  @action
  setCustomHides = (customHides: CustomHidesProps) => {
    this.customHides = customHides;
  }

  @action
  setValidateHiddenField = (validateHiddenField?: boolean) => {
    this.validateHiddenField = validateHiddenField;
  }

  // 获取校验规则
  @action
  getRules = () => {
    // 默认去掉隐藏域校验，validateHiddenField=true的时候需要校验
    // 必填: required
    const rules: ValidatorRules = {}, ruleGroupMapping: AnyObj = {};
    let itemGroupMapping = this.itemGroupMapping;
    // 获取隐藏域字段
    let hiddenFields: string[] = [], showItems: string[] = [];
    this.layout.forEach((row) => {
      row.forEach((col) => {
        // col.hide 隐藏(通用用法)
        // col.disabled 隐藏(高级搜索浏览框自定义设置)
        if (col.hide || col.disabled || this.prohibitCheckLayoutIds?.has(col.id)) hiddenFields = hiddenFields.concat(col.items.slice());
        else showItems = showItems.concat(col.items.slice());
        if (!this.itemGroupMapping) {
          col.items?.slice()?.forEach((ik) => {
            itemGroupMapping = {
              ...itemGroupMapping,
              [ik]: col.groupId,
            }
          })
          const cascadeRules = col.cascadeRules;
          if (cascadeRules) {
            Object.keys(cascadeRules).forEach((key) => {
              let ruleGroup = cascadeRules[key] || {};
              Object.keys(ruleGroup).forEach((k) => {
                let rule = ruleGroup[k];
                if (Array.isArray(rule) || isObservableArray(rule)) {
                  rule?.forEach((ik) => {
                    itemGroupMapping = {
                      ...itemGroupMapping,
                      [ik]: col.groupId,
                    }
                  })
                }
                else if (isObject(rule)) {
                  (rule?.items || []).forEach((ik: any) => {
                    itemGroupMapping = {
                      ...itemGroupMapping,
                      [ik]: col.groupId,
                    }
                  })
                }
              })
            })
          }
        }
      })
    })
    this.itemGroupMapping = itemGroupMapping;
    let validateItems = { ...this.items };
    if (!this.validateHiddenItems) {
      validateItems = {};
      showItems.forEach((itemKey) => {
        validateItems = {
          ...validateItems,
          [`${itemKey}`]: this.items[itemKey],
        }
      })
    }
    // 获取rules
    Object.keys(validateItems).forEach((itemKey) => {
      if (hiddenFields.indexOf(itemKey) < 0 || this.validateHiddenField) {
        if (validateItems?.[itemKey]?.required) {
          rules[`${itemKey}`] = 'required';
          ruleGroupMapping[`${itemKey}`] = itemGroupMapping?.[`${itemKey}`];
        }
        const tempRules = validateItems?.[itemKey]?.rules || validateItems?.[itemKey]?.otherParams?.rules;
        if (tempRules) {
          rules[`${itemKey}`] = toJS(tempRules);
          ruleGroupMapping[`${itemKey}`] = itemGroupMapping?.[`${itemKey}`];
        }
      }
    })

    // 其他自定义分组校验处理
    this.customLayout && Object.keys(this.customLayout)?.forEach((gk) => {
      this.customLayout?.[gk]?.forEach((row) => {
        row?.forEach((col) => {
          !(col.hide || col.disabled || this.prohibitCheckLayoutIds?.has(col.selectedFieldId || col.id)) && col?.items?.forEach((itemKey) => {
            const k = `${itemKey}_${col.labelId}`;
            const item = this?.items?.[k];
            if (item?.required) {
              rules[k] = 'required';
              ruleGroupMapping[k] = gk;
            }
            const tempRules = item?.rules || item?.otherParams?.rules;
            if (tempRules) {
              rules[k] = tempRules;
              ruleGroupMapping[k] = gk;
            }
          })
        })
      })
    })
    this.ruleGroupMapping = ruleGroupMapping;
    return rules;
  }

  @action
  getAttributeNames = () => {
    // 提示字段名称自定义
    const attributeNames: Record<string, string> = {};
    // 获取隐藏域字段
    let hiddenFields: string[] = [], showItems: string[] = [];
    this.layout.forEach((row) => {
      row.forEach((col) => {
        // col.hide 隐藏(通用用法)
        // col.disabled 隐藏(高级搜索浏览框自定义设置)
        if (col.hide || col.disabled || this.prohibitCheckLayoutIds?.has(col.id)) hiddenFields = hiddenFields.concat(col.items.slice());
        else showItems = showItems.concat(col.items.slice());
      })
    })
    let validateItems = { ...this.items };
    if (!this.validateHiddenItems) {
      validateItems = {};
      showItems.forEach((itemKey) => {
        validateItems = {
          ...validateItems,
          [`${itemKey}`]: this.items[itemKey],
        }
      })
    }
    // 获取 attributeNames
    Object.keys(validateItems).forEach((itemKey) => {
      if (hiddenFields.indexOf(itemKey) < 0 || this.validateHiddenField) {
        const tempAttrNames = validateItems?.[itemKey]?.attributeNames || validateItems?.[itemKey]?.otherParams?.attributeNames;
        if (tempAttrNames) {
          attributeNames[`${itemKey}`] = toJS(tempAttrNames);
        }
      }
    })

    // 其他自定义分组校验处理
    this.customLayout && Object.keys(this.customLayout)?.forEach((gk) => {
      this.customLayout?.[gk]?.forEach((row) => {
        row?.forEach((col) => {
          !(col.hide || col.disabled || this.prohibitCheckLayoutIds?.has(col.selectedFieldId || col.id)) && col?.items?.forEach((itemKey) => {
            const k = `${itemKey}_${col.labelId}`;
            const item = this?.items?.[k];
            const tempAttrNames = item?.attributeNames || item?.otherParams?.attributeNames;
            if (tempAttrNames) {
              attributeNames[k] = tempAttrNames;
            }
          })
        })
      })
    })

    return attributeNames;
  }

  // 设置校验信息
  @action
  showError = (errors: any) => {
    Object.keys(errors.errors).forEach((da) => {
      this.items[da] = {
        ...this.items[da],
        error: errors.errors[da],
      }
    })
    const timeout = setTimeout(() => {
      this.position(errors.errors);
      clearTimeout(timeout);
    }, 0);
  }

  // 校验信息定位
  @action
  position = (errors?: AnyObj) => {
    const dom = document.querySelector(`.showError.${this.id}`);
    const openFirstGroup = false;
    const openGroup = () => {
      const gs: any[] = [];
      if (errors && Object.keys(errors)?.length > 0 && this.ruleGroupMapping) {
        Object.keys(errors).forEach((k) => {
          if (this.ruleGroupMapping?.[k]) {
            !openFirstGroup ? this.activeKey.push(this.ruleGroupMapping?.[k]) : gs.push(this.ruleGroupMapping?.[k]);
          }
        })
        if (openFirstGroup) {
          const openGroup = this.groups.find((g) => gs.indexOf(g.id) >= 0);
          openGroup && this.activeKey.push(openGroup?.id);
        }
      }
    }
    if (dom) {
      // 分组展开逻辑
      const classNames = dom.className;
      const groupId = classNames?.split(' ')?.find?.((a) => a.indexOf('groupId') >= 0)?.split('-')?.[1];

      if (groupId) {
        // 存在分组，则需要展开当前分组
        if (openFirstGroup) {
          this.activeKey.push(groupId);
        } else {
          openGroup();
        }
      } else if (this.groups?.length > 0) {
        // 存在分组，但分组未被展开过，默认不加载FormItem
        openGroup();
      }
      const clientRect = dom.getBoundingClientRect();
      const clientHeight = window?.document?.body?.clientHeight;
      // 超过显示范围处理定位（底部预留100间距）
      if (clientRect?.y < 0 || (clientHeight && clientRect?.y > (clientHeight - 100))) {
        dom?.scrollIntoView?.();
      }
    } else if (errors && Object.keys(errors)?.length > 0 && this.ruleGroupMapping) {
      openGroup();
    }
  }

  // 表单校验
  @action
  validate = async (customRules?: AnyObj, otherParams?: AnyObj, otherAction?: CustomValidateAction) => { // customRules 完全自定义校验规则
    const rules = customRules || this.getRules();
    const mergedAttributeNames = Object.assign(this.getAttributeNames(), otherParams?.attributeNames || {});
    const formDatas = this.getFormDatas();

    // 1. this.items 里面取到组件内部规则
    // 2. 调用组件抛出的校验方法，得到 errors
    let comsErrors: AnyObj = {};
    let comsInnerErrors: AnyObj = {};
    let formErrors: AnyObj = {};
    for (const key in this.items) {
      if (Object.prototype.hasOwnProperty.call(this.items, key)) {
        const item = this.items[key];
        // 密级，需要单独走密级接口（这里通过 PLUGIN 接入组件）
        if (item.itemType === 'PLUGIN' && item.pluginParams?.packageName === '@weapp/ui'
          && (item.pluginParams.compName === 'SecretSelect' || item.pluginParams.compName === 'MSecretSelect')) {
          const data = formDatas[key];
          let props: AnyObj = {
            ...otherParams,
            ...item.pluginParams,
            required: item.required
          };
          if (item.pluginParams.data) {
            props.options = props.data;
          }
          const res = await SecretSelect.doValidate(data, props)
          const errors = res.errors;
          // 如果有校验失败的内容，更新到对应的 item 的 errors 中去
          if (!isValueEmpty(errors)) {
            let itemErrors: string[] = []
            for (const k in errors) {
              if (Object.prototype.hasOwnProperty.call(errors, k)) {
                itemErrors = itemErrors.concat(errors[k]);
              }
            }
            comsInnerErrors[key] = errors;
            comsErrors[key] = itemErrors;
          }
        }
      }
    }

    // 3. 调用 form 的校验方法，得到 errors
    const res: any = await doValidator({
      datas: formDatas,
      rules: rules,
      ...(otherParams || {}),
      attributeNames: mergedAttributeNames,
    })
    if (res && res.errors) {
      formErrors = res.errors;
    }

    // 4. 合并 form 的 errors 和 组件对应的 errors，得到最终校验结果
    const mergedErrors: AnyObj = { ...comsErrors, ...formErrors };
    for (const key in mergedErrors) {
      if (Object.prototype.hasOwnProperty.call(mergedErrors, key)) {
        const comsErrorArr = comsErrors[key] || [];
        const formErrorArr = formErrors[key] || [];
        const mergedArr = [...comsErrorArr, ...formErrorArr];
        const dedupedArr = Array.from(new Set(mergedArr));
        mergedErrors[key] = dedupedArr;
      }
    }
    // 显示还是通过组件显示
    if (!isValueEmpty(mergedErrors) && !otherAction?.hideErrors) {
      this.showError({ errors: { ...comsInnerErrors, ...formErrors } });
    }
    // 返回的报错包括所有的错误
    this.setState({ errors: mergedErrors });
    // !!! 除了errors不能加别的参数，流程模块内有判断空的逻辑（对象转成字符串，判断是否等于 {errors: ''}的逻辑），加东西会导致模块判断失效
    return { errors: mergedErrors };

    // return new Promise((resolve) => {
    //   doValidator({
    //     datas: formDatas,
    //     rules: rules,
    //     ...(otherParams || {})
    //   }).then((errors: any) => {
    //     if (errors && errors.errors) {
    //       this.showError(errors);
    //     }
    //     resolve(errors);
    //   });
    // })
  }

  // 表单校验（返回值新规范）
  @action
  doValidate = async (customRules?: AnyObj, otherParams?: AnyObj, otherAction?: CustomValidateAction) => {
    const result = await this.validate(customRules, otherParams, otherAction);
    const rules = customRules || this.getRules();
    return { ...result, rules, pass: Object.keys(result?.errors || {})?.length <= 0 };
  }

}
export type FormStoreType = Pick<FormStore, keyof FormStore>;
