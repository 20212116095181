import { mRecordingClsPrefix } from '../../constants/index.m';
import Loadable from '../../react-loadable';
import { MRecordingType } from './types';

const MRecording = Loadable({
  name: 'MRecording', loader: () => import(
    /* webpackChunkName: "recording" */
    './MRecording')
}) as MRecordingType;

MRecording.defaultProps = {
  prefixCls: mRecordingClsPrefix,
  type: "audio",
};
MRecording.displayName = "MRecording";

export type { MRecordingProps } from './types';

export default MRecording;