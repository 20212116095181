import Loadable from '../../react-loadable';
import { CommonPhraseType, ContentType, GroupSettingType } from './types';
import { commonPhraseClsPrefix } from '../../constants/index';

const CommonPhrase = Loadable({
  name: 'CommonPhrase', loader: () => import(
    /* webpackChunkName: "common_phrase" */
    './CommonPhrase')
}) as CommonPhraseType;

const Content = Loadable({
  name: 'CommonPhrase_Content', loader: () => import(
    /* webpackChunkName: "common_phrase" */
    './component/pc/Content')
}) as ContentType;

const GroupSetting = Loadable({
  name: 'CommonPhrase_GroupSetting', loader: () => import(
    /* webpackChunkName: "common_phrase" */
    './component/pc/GroupSetting')
}) as GroupSettingType;

const EditPhrase = Loadable({
  name: 'CommonPhrase_editPhrase', loader: () => import(
    /* webpackChunkName: "common_phrase" */
    './component/pc/EditPhrase')
}) as any;

CommonPhrase.defaultProps = {
  prefixCls: commonPhraseClsPrefix,
};

Content.defaultProps = {
  prefixCls:`${commonPhraseClsPrefix}-content`,
};

GroupSetting.defaultProps = {
  prefixCls:`${commonPhraseClsPrefix}-group-setting`,
};

EditPhrase.defaultProps = {
  prefixCls:`${commonPhraseClsPrefix}-edit`,
};

CommonPhrase.EditPhrase=EditPhrase;


export default  CommonPhrase;

export { Content, GroupSetting, EditPhrase };