
import Loadable from '../../react-loadable';
import { SpinType, type } from './types';

const MSpin = Loadable({ name: 'MSpin', loader: () => import(
  /* webpackChunkName: 'm_spin' */
  './Spin') }) as SpinType;

MSpin.defaultProps = {
  type: type.CURVE
}

export type { SpinProps, type } from './types';

export default MSpin;
