import Loadable from '../../react-loadable';
import showImage from './showImage';
import showMImage from './showImage.m';
import { MPhotoViewType } from './types';
import { ua } from '@weapp/utils'

const MPhotoView = Loadable({
  name: 'MPhotoView', loader: () => import(
    /* webpackChunkName: 'm_photo_view' */
    './PhotoView.m')
}) as MPhotoViewType;

MPhotoView.showImage = ua.device === 'PC' ? showImage : showMImage

export type { MPhotoViewProps, MPhotoViewType, MPhotoViewData } from './types';

export { MPhotoView };

export default MPhotoView;
