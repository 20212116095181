import { getLabel } from '@weapp/utils'

// 节气
export const solarTerm = [
  getLabel('92970', '小寒'),
  getLabel('92971', '大寒'),
  getLabel('92975', '立春'),
  getLabel('92976', '雨水'),
  getLabel('92978', '惊蛰'),
  getLabel('92979', '春分'),
  getLabel('92980', '清明'),
  getLabel('92981', '谷雨'),
  getLabel('92982', '立夏'),
  getLabel('92983', '小满'),
  getLabel('92984', '芒种'),
  getLabel('92985', '夏至'),
  getLabel('92986', '小暑'),
  getLabel('92987', '大暑'),
  getLabel('92989', '立秋'),
  getLabel('92990', '处暑'),
  getLabel('92991', '白露'),
  getLabel('92992', '秋分'),
  getLabel('92993', '寒露'),
  getLabel('92994', '霜降'),
  getLabel('92995', '立冬'),
  getLabel('92996', '小雪'),
  getLabel('92997', '大雪'),
  getLabel('92998', '冬至'),
]
// 公历节日
export const sFtv = [
  `0101 ${getLabel('91882', '元旦')}`,
  `0214 ${getLabel('91883', '情人节')}`,
  `0308 ${getLabel('91884', '妇女节')}`,
  `0401 ${getLabel('91888', '愚人节')}`,
  `0501 ${getLabel('91890', '劳动节')}`,
  `0504 ${getLabel('91891', '青年节')}`,
  `0601 ${getLabel('91893', '儿童节')}`,
  `0701 ${getLabel('91894', '建党节')}`,
  `0801 ${getLabel('91895', '建军节')}`,
  `0910 ${getLabel('91896', '教师节')}`,
  `1001 ${getLabel('91899', '国庆节')}`,
  `1224 ${getLabel('91901', '平安夜')}`,
  `1225 ${getLabel('91902', '圣诞节')}`
]
// 农历节日
export const lFtv = [
  `0101 ${getLabel('91904', '春节')}`,
  `0115 ${getLabel('91905', '元宵节')}`,
  `0505 ${getLabel('91906', '端午节')}`,
  `0707 ${getLabel('91907', '七夕')}`,
  `0715 ${getLabel('91908', '中元节')}`,
  `0815 ${getLabel('91909', '中秋节')}`,
  `0909 ${getLabel('91910', '重阳节')}`,
  `1208 ${getLabel('91913', '腊八节')}`,
  `1224 ${getLabel('91914', '小年')}`
]
