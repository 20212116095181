import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { classnames, ua } from '@weapp/utils';
import { getScrollBarWidth, isWebKit } from './utils/index';
import { addResizeListener, removeResizeListener } from './utils/resize.event';
import { needRTL } from '../../utils/index'
import { Bar } from './Bar';
import { IScrollerProps, IScrollerState } from './types';
import { scrollerClsPrefix } from '../../constants/index';

const scrollBarWidth = getScrollBarWidth()

export default class Scroller extends PureComponent<IScrollerProps, IScrollerState> {
  static defaultProps = {
    viewComponent: 'div',
    direction: 'v' as 'h' | 'v' | 'both'
  };

  private resizeDom: HTMLDivElement | null;
  private cleanRAF: (() => void) | undefined;
  private cleanResize: any;
  private wrap: HTMLDivElement | null;
  private verticalNode: HTMLElement | null;
  private horizontalNode: HTMLElement | null;
  private _timer: any
  private updateTimer: any
  private readonly randomScrollbarId: string

  constructor(props: IScrollerProps) {
    super(props);

    this.state = {
      sizeWidth: '0',
      sizeHeight: '0',
      moveX: 0,
      moveY: 0,
    };

    this.resizeDom = null
    this.verticalNode = null
    this.horizontalNode = null
    this.wrap = null
    this.randomScrollbarId = `${new Date().getTime()}_${Math.ceil(Math.random() * 100000)}`
  }

  componentDidMount() {
    if (isWebKit()) return;

    // 生成动画标识 rafId
    let rafId = requestAnimationFrame(this._update)
    this.cleanRAF = () => {
      cancelAnimationFrame(rafId)
    }

    this._timer = setTimeout(() => {
      if (this._timer) {
        clearTimeout(this._timer)
        this._timer = null
      }

      this.resizeDom = ReactDOM.findDOMNode(this.refs.resize) as HTMLDivElement
      this.cleanResize && this.cleanResize();
      addResizeListener(this.resizeDom, this._update)
      this.cleanResize = () => {
        removeResizeListener(this.resizeDom, this._update);
      }
    }, 500)
  }
  //
  // componentDidUpdate() {
  //   if (isWebKit()) return;
  //
  //   this.resizeDom = ReactDOM.findDOMNode(this.refs.resize) as HTMLDivElement
  //   this.cleanResize && this.cleanResize();
  //   addResizeListener(this.resizeDom, this._update)
  //   this.cleanResize = () => {
  //     removeResizeListener(this.resizeDom, this._update);
  //   }
  // }

  componentWillUnmount() {
    this.cleanRAF && this.cleanRAF();
    this.cleanResize && this.cleanResize();

    this.resizeDom = null
    this.wrap = null
    this.verticalNode = null
    this.horizontalNode = null

    if (this._timer) {
      clearTimeout(this._timer)
      this._timer = null
    }

    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
      this.updateTimer = null
    }
  }

  scrollTo = () => {
    // wrap
  }

  handleScroll = (e: any) => {
    const wrap = this.wrap;
    if (wrap && 'scrollTop' in wrap) {
      if (!this.verticalNode) this.verticalNode = document.getElementById(`${this.randomScrollbarId}__v`)
      if (!this.horizontalNode) this.horizontalNode = document.getElementById(`${this.randomScrollbarId}__h`)

      if (this.verticalNode) {
        const translate = `translateY(${((wrap.scrollTop * 100) / wrap.clientHeight)}%)`;

        this.verticalNode.style['transform'] = translate;
        this.verticalNode.style['webkitTransform'] = translate;
      }

      if (this.horizontalNode) {
        const translate = `translateX(${((wrap.scrollLeft * 100) / wrap.clientWidth)}%)`;

        this.horizontalNode.style['transform'] = translate;
        this.horizontalNode.style['webkitTransform'] = translate;
      }

      // this.setState({
      //   moveY: ((wrap.scrollTop * 100) / wrap.clientHeight),
      //   moveX: ((wrap.scrollLeft * 100) / wrap.clientWidth),
      // });
    }

    this.props?.onScroll?.(e)
  }

  // 更新动画
  _update = () => {
    // 不加 setTimeout，动态给 height 时，取到的 wrap.clientHeight 是调整前的
    this.updateTimer = setTimeout(() => {
      if (this.updateTimer) {
        clearTimeout(this.updateTimer)
        this.updateTimer = null
      }

      let heightPercentage = 0, widthPercentage = 0;
      const { wrap } = this;
      if (!wrap) return;

      // ie浏览器下 scrollHeight、clientHeight 计算规则不一致
      // scrollHeight in ie is larger by 1
      const isIE = ua.browser === 'IE';

      if ('clientHeight' in wrap) {
        heightPercentage = (wrap.clientHeight * 100 / (isIE ? wrap.scrollHeight - 1 : wrap.scrollHeight));
      }
      if ('clientWidth' in wrap) {
        widthPercentage = (wrap.clientWidth * 100 / (isIE ? wrap.scrollWidth - 1 : wrap.scrollWidth));
      }

      let sizeHeight = (heightPercentage < 100) ? (heightPercentage + '%') : '';
      let sizeWidth = (widthPercentage < 100) ? (widthPercentage + '%') : '';

      if (!this.verticalNode) this.verticalNode = document.getElementById(`${this.randomScrollbarId}__v`)
      if (!this.horizontalNode) this.horizontalNode = document.getElementById(`${this.randomScrollbarId}__h`)

      if (this.verticalNode) {
        this.verticalNode.style['height'] = sizeHeight;
      }

      if (this.horizontalNode) {
        this.horizontalNode.style['width'] = sizeWidth;
      }
    }, 0);
  }

  render() {
    let {
      wrapStyle,
      children,
      viewComponent,
      wrapClass,
      className,
      height,
      viewStyle,
      viewClass,
      direction = 'v',
      style: comStyle
    } = this.props;
    let { moveX, moveY, sizeWidth, sizeHeight } = this.state;

    let style = wrapStyle
    let gutter = scrollBarWidth
    const _viewStyle: any = {}

    if (gutter) {
      const isIE = ua.browser === 'IE';
      const gutterWith = `-${gutter}px`;

      const _wrapStyle: any = {}
      switch (direction) {
        case 'v':
          // _wrapStyle[needRTL() ? 'marginLeft' : 'marginRight'] = gutterWith;
          _wrapStyle.marginRight = gutterWith;
          _wrapStyle.overflowX = 'hidden'
          _wrapStyle.msOverflowX = 'hidden'

          // if(isIE) _viewStyle.width = `calc(100% - ${gutter}px)`
          break
        case 'h':
          // _wrapStyle[needRTL() ? 'marginLeft' : 'marginRight'] = gutterWith;
          _wrapStyle.marginBottom = gutterWith;
          _wrapStyle.overflowY = 'hidden'
          _wrapStyle.msOverflowY = 'hidden'
          break
        case 'both':
          // _wrapStyle[needRTL() ? 'marginLeft' : 'marginRight'] = gutterWith;
          _wrapStyle.marginRight = gutterWith;
          _wrapStyle.marginBottom = gutterWith

          // if(isIE) _viewStyle.width = `calc(100% - ${gutter}px)`
          break
      }

      if (Array.isArray(wrapStyle)) {
        style = { ...wrapStyle, ..._wrapStyle }
      } else {
        style = Object.assign({}, wrapStyle, _wrapStyle)
      }
    }

    const view = React.createElement(viewComponent || 'div', {
      className: classnames(`${scrollerClsPrefix}__view`, viewClass),
      style: { ...viewStyle, ..._viewStyle },
      ref: 'resize'
    }, children);

    let nodes;
    // webkit内核使用 样式处理
    if (isWebKit()) {
      nodes = [
        (
          <div
            key={0}
            ref="wrap"
            onScroll={this.handleScroll}
            className={classnames(wrapClass, `${scrollerClsPrefix}__wrap`)}
            style={{ ...comStyle, height }}>
            {view}
          </div>
        )
      ]
    } else {
      const wrap = (
        <div
          /* eslint-disable-next-line react/jsx-no-bind */
          ref={(ref) => this.wrap = ref}
          style={{ ...style, height }}
          onScroll={this.handleScroll}
          className={classnames(wrapClass, `${scrollerClsPrefix}__wrap`, gutter ? '' : `${scrollerClsPrefix}__wrap--hidden-default`)}
        >
          {view}
        </div>
      )
      nodes = [
        wrap,
        // eslint-disable-next-line react/jsx-no-bind
        <Bar weId={`${this.props.weId || ''}_j6zkc4@$1`} key={1} move={moveX} randomId={`${this.randomScrollbarId}__h`} size={sizeWidth} getParentWrap={() => this.wrap}></Bar>,
        // eslint-disable-next-line react/jsx-no-bind
        <Bar weId={`${this.props.weId || ''}_y7t1nk@$2`} key={2} move={moveY} randomId={`${this.randomScrollbarId}__v`} size={sizeHeight} getParentWrap={() => this.wrap} vertical={true}></Bar>,
      ]
    }

    return React.createElement('div', { className: classnames(scrollerClsPrefix, className), style: comStyle }, nodes)
  }
}