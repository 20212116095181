import { dayjs, getLabel } from '@weapp/utils'
import {
  getSolarTerm, getSFtv, getLFtv,
  lYearDays, monthDays, leapMonth, leapDays,
  solarDays, sTermFix
} from './utils'
import { nMonth, animals, zodiac_sky, zodiac_land, nStr1, cDay } from './utils/constants'
import { utils } from '../../../../lib'
const { isNil } = utils

// 读取全局变量
const window_sFtv = window?.weapp_ui_sFtv || window?.weapp_ui_solarFestival
const window_lFtv = window?.weapp_ui_lFtv || window?.weapp_ui_lunarFestival
const window_showSolarTerm = window?.weapp_ui_showSolarTerm

// 判断是否展示农历
export const getLang = () => ((window.TEAMS?.locale?.lang) || document.cookie.match(/langType=(.*?)(;|$)/)?.[1] || window.navigator.language || 'zh-CN').replace(/-/g, () => '_');
export const showLunar = (getLang() === 'zh_CN' || getLang() === 'zh_TW')

// 算出当前月第一天的农历日期和当前农历日期下一个月农历的第一天日期
export class Dianaday {
  isLeap: boolean;
  year: number;
  month: number;
  day: number;
  yearCyl: number;
  monCyl: number;
  dayCyl: number;
  constructor(objDate: Date) {
    let i;
    let leap = 0;
    let temp = 0;
    // const baseDate  = dayjs('1900-01-31').tz('Asia/Shanghai')
    const baseDate = new Date(1900, 0, 31);
    // let offset = parseInt(String((objDate.getTime() - baseDate.getTime()) / (24 * 60 * 60 * 1000)), 10);
    // const b = dayjs().set('year',1900).set('month', dayjs().month() + 1).set('date', 0).set('hour',0).set('minute',0).set('second',0)
    // const baseDate = new Date(b.format())
    // let offset = parseInt(String((objDate.getTime() - baseDate.getTime()) / (24 * 60 * 60 * 1000)), 10);
    let offset = dayjs(objDate).diff(baseDate, 'day')
    this.dayCyl = offset + 40;
    this.monCyl = 14;
    for (i = 1900; i < 2050 && offset > 0; i += 1) {
      temp = lYearDays(i);
      offset -= temp;
      this.monCyl += 12;
    }
    if (offset < 0) {
      offset += temp;
      i -= 1;
      this.monCyl -= 12;
    }
    this.year = i;
    this.yearCyl = i - 1864;
    leap = leapMonth(i); // 闰哪个月
    this.isLeap = false;
    for (i = 1; i < 13 && offset > 0; i += 1) {
      if (leap > 0 && i === leap + 1 && this.isLeap === false) {
        // 闰月
        i -= 1;
        this.isLeap = true;
        temp = leapDays(this.year);
      } else {
        temp = monthDays(this.year, i);
      }
      if (this.isLeap === true && i === leap + 1) {
        this.isLeap = false; // 解除闰月
      }
      offset -= temp;
      if (this.isLeap === false) {
        this.monCyl += 1;
      }
    }
    if (offset === 0 && leap > 0 && i === leap + 1) {
      if (this.isLeap) {
        this.isLeap = false;
      } else {
        this.isLeap = true;
        i -= 1;
        this.monCyl -= 1;
      }
    }
    if (offset < 0) {
      offset += temp;
      i -= 1;
      this.monCyl -= 1;
    }
    this.month = i;
    this.day = offset + 1;
  }
}

// 记录公历和农历某天的日期
export class CalElement {
  isLeap: boolean;
  isToday: boolean;
  sYear: number;
  sMonth: number;
  sDay: number;
  week: string;
  lYear: number;
  lMonth: number;
  lDay: number;
  lunarFestival: string;
  solarFestival: string;
  solarTerms: string;
  sDisplay: string;
  lDisplay: string;
  lDisplayArr: string[];
  constructor(sYear: number, sMonth: number, sDay: number, week: string, lYear: number, lMonth: number, lDay: number, isLeap: boolean) {
    this.isToday = false;
    // 公历
    this.sYear = sYear;
    this.sMonth = sMonth;
    this.sDay = sDay;
    this.week = week;
    // 农历
    this.lYear = lYear;
    this.lMonth = lMonth;
    this.lDay = lDay;
    this.isLeap = isLeap; //是否是闰月
    // 节日记录
    this.lunarFestival = ''; // 农历节日
    this.solarFestival = ''; // 公历节日
    this.solarTerms = ''; // 节气
    // 显示内容
    this.sDisplay = ''; // 公历显示
    this.lDisplay = ''; // 农历显示
    this.lDisplayArr = []; // 农历显示内容
  }
}

// 保存y年m+1月的相关信息
let fat = 9;
let mat = 9;
let eve = 0;
export class SolarTerms {
  length: number;
  firstWeek: number;
  days: any[];
  constructor(y: number, m: number, showSolarTerm: boolean) {
    fat = 0;
    mat = 0;
    let sDObj;
    let lDObj;
    let lY;
    let lM;
    let lD = 1;
    let lL;
    let lX = 0;
    const lDPOS = new Array(3);
    let n = 0;
    // let firstLM = 0;
    sDObj = new Date(y, m, 1); // 当月第一天的日期
    // sDObj = new Date(dayjs().year(y).month(m).date(1).tz('Asia/Shanghai').format())
    this.length = solarDays(y, m); // 公历当月天数
    this.firstWeek = sDObj.getDay(); // 公历当月1日星期几
    this.days = [];
    if (m + 1 === 5) {
      fat = sDObj.getDay();
    }
    if (m + 1 === 6) {
      mat = sDObj.getDay();
    }
    const Today = new Date();
    const tY = Today.getFullYear();
    const tM = Today.getMonth();
    const tD = Today.getDate();
    for (let i = 0; i < this.length; i += 1) {
      if (lD > lX) {
        sDObj = new Date(y, m, i + 1); // 当月第一天的日期
        // sDObj = dayjs().year(y).month(m).date(i + 1).tz('Asia/Shanghai')
        lDObj = new Dianaday(sDObj); // 农历
        lY = lDObj.year; // 农历年
        lM = lDObj.month; // 农历月
        lD = lDObj.day; // 农历日
        lL = lDObj.isLeap; // 农历是否闰月
        lX = lL ? leapDays(lY) : monthDays(lY, lM); // 农历当月最后一天
        if (lM === 12) {
          eve = lX;
        }
        // if (n === 0) firstLM = lM;
        lDPOS[n] = i - lD + 1;
        n += 1;
      }
      this.days[i] = new CalElement(
        y,
        m + 1,
        i + 1,
        nStr1[(i + this.firstWeek) % 7],
        lY || 0,
        lM || 0,
        lD || 0,
        lL || false,
      );
      lD += 1;
    }
    // 节气
    const sT = sTermFix(y, m);
    const solarTerm = getSolarTerm()
    if (showSolarTerm) {
      this.days[sT.tmp1].solarTerms = solarTerm[m * 2];
      this.days[sT.tmp2].solarTerms = solarTerm[m * 2 + 1];
    }
    // if ((this.firstWeek + 12) % 7 === 5) {//黑色星期五
    //     this[12].solarFestival += '黑色星期五';
    // }
    if (y === tY && m === tM) {
      this.days[tD - 1].isToday = true; // 今日
    }
  }
}

export default class Lunar {
  lunarCollection: any;
  sFtv: string[];
  lFtv: string[];
  showSolarTerm: boolean;
  constructor(props: any = {}) {
    const { solarFestival, lunarFestival, showSolarTerm } = props
    this.lunarCollection = {};
    this.sFtv = !isNil(window_sFtv) ? window_sFtv : (!isNil(solarFestival) ? solarFestival : getSFtv())
    this.lFtv = !isNil(window_lFtv) ? window_lFtv : (!isNil(lunarFestival) ? lunarFestival : getLFtv())
    this.showSolarTerm = !isNil(window_showSolarTerm) ? window_showSolarTerm : (!isNil(showSolarTerm) ? showSolarTerm : true)
  }
  set solarFestival(sF) {
    this.sFtv = sF;
  }
  get solarFestival() {
    return this.sFtv;
  }
  set lunarFestival(lF) {
    this.lFtv = lF;
  }
  get lunarFestival() {
    return this.lFtv;
  }
  // 在表格中显示公历和农历的日期,以及相关节日
  generatorCld(SY: number, SM: number) {
    let i;
    let s;
    let sD;
    const cld = new SolarTerms(SY, SM, this.showSolarTerm);
    for (i = 0; i < 42; i += 1) {
      let sDisplay = '';
      let lDisplay = '';
      let fullLDisplay = '';
      const lDisplayArr = [];
      sD = i - cld.firstWeek;
      const sDObj = cld.days[sD];
      if (sD > -1 && sD < cld.length) {
        // 日期内
        sDisplay = String(sD + 1);
        lDisplay = cDay(sDObj.lDay); // 显示农历日
        showLunar && lDisplayArr.push(lDisplay);
        if (sDObj.lDay === 1) {
          // 显示农历月
          lDisplay = `${sDObj.isLeap ? '闰' : ''}${nMonth[sDObj.lMonth - 1]}月`;
          showLunar && lDisplayArr.push(lDisplay);
        }
        fullLDisplay = `${sDObj.isLeap ? '闰' : ''}${nMonth[sDObj.lMonth - 1]}月${cDay(sDObj.lDay)}`
        let Slfw = null;
        let Ssfw = null;
        s = sDObj.solarFestival;
        // 公历节日
        for (let ipp = 0; ipp < this.sFtv.length; ipp += 1) {
          if (parseInt(this.sFtv[ipp].substr(0, 2), 10) === SM + 1) {
            if (parseInt(this.sFtv[ipp].substr(2, 4), 10) === sD + 1) {
              lDisplay = this.sFtv[ipp].substr(5);
              Ssfw = this.sFtv[ipp].substr(5);
              lDisplayArr.push(lDisplay);
            }
          }
        }
        if (SM + 1 === 5) {
          // 母亲节
          if (fat === 0) {
            if (sD + 1 === 8) {
              // Ssfw = '母亲节';
              // lDisplay = '母亲节';
              Ssfw = getLabel('91915', '母亲节');
              lDisplay = getLabel('91915', '母亲节');
              lDisplayArr.push(lDisplay);
            }
          } else if (fat < 9) {
            if (sD + 1 === 7 - fat + 8) {
              // Ssfw = '母亲节';
              // lDisplay = '母亲节';
              Ssfw = getLabel('91915', '母亲节');
              lDisplay = getLabel('91915', '母亲节');
              lDisplayArr.push(lDisplay);
            }
          }
        }
        if (SM + 1 === 6) {
          // 父亲节
          if (mat === 0) {
            if (sD + 1 === 14) {
              // Ssfw = '父亲节';
              // lDisplay = '父亲节';
              Ssfw = getLabel('91916', '父亲节');
              lDisplay = getLabel('91916', '父亲节');
              lDisplayArr.push(lDisplay);
            }
          } else if (mat < 9) {
            if (sD + 1 === 7 - mat + 15) {
              // Ssfw = '父亲节';
              // lDisplay = '父亲节';
              Ssfw = getLabel('91916', '父亲节');
              lDisplay = getLabel('91916', '父亲节');
              lDisplayArr.push(lDisplay);
            }
          }
        }
        if (SM + 1 === 11) {
          // 感恩节
          if (sD + 1 === 1 + 21 + ((4 - cld.firstWeek + 7) % 7)) {
            // Ssfw = '感恩节';
            // lDisplay = '感恩节';
            Ssfw = getLabel('91917', '感恩节');
            lDisplay = getLabel('91917', '感恩节');
            lDisplayArr.push(lDisplay);
          }
        }
        // 农历节日
        for (let ipp = 0; ipp < this.lFtv.length; ipp += 1) {
          if (sDObj.lMonth === 12) {
            // 判断是否为除夕
            if (eve === sDObj.lDay) {
              // lDisplay = '除夕';
              // Slfw = '除夕';
              Ssfw = getLabel('91918', '除夕');
              lDisplay = getLabel('91918', '除夕');
              lDisplayArr.push(lDisplay);
            }
          }
          if (parseInt(this.lFtv[ipp].substr(0, 2), 10) === parseInt(sDObj.lMonth, 10)) {
            if (parseInt(this.lFtv[ipp].substr(2, 4), 10) === parseInt(sDObj.lDay, 10)) {
              lDisplay = this.lFtv[ipp].substr(5);
              Slfw = this.lFtv[ipp].substr(5);
              showLunar && lDisplayArr.push(lDisplay);
            }
          }
        }
        // 节气
        if (s.length <= 0) {
          s = sDObj.solarTerms;
          // if (s.length > 0) s = s.fontcolor('limegreen'); // 设置节气的颜色
        }
        if (s.length > 0) {
          lDisplay = s;
          Slfw = s;
          showLunar && lDisplayArr.push(lDisplay);
        }
        if (Slfw !== null && Ssfw !== null) {
          lDisplay = `${Slfw}/${Ssfw}`;
        }
      } else {
        // 非日期
        sDisplay = '';
        lDisplay = '';
      }
      if (sDObj) {
        sDObj.sDisplay = sDisplay;
        sDObj.lDisplay = lDisplay;
        sDObj.lDisplayArr = lDisplayArr;
        //完整农历日期
        if (fullLDisplay) {
          sDObj.fullLDisplay = fullLDisplay
        }
        //新增生肖年
        const remainder = sDObj.lYear % 12
        const animal = animals[remainder]
        if (animal) sDObj.animal = animal

        //新增天干地支年
        //天干
        const y = sDObj.lYear - 3
        const sky_remainder = y % 10
        const sky = zodiac_sky[sky_remainder]
        //地支
        const land_remainder = y % 12
        const land = zodiac_land[land_remainder]
        if (sky && land) {
          sDObj.zodiac = `${sky}${land}`
        }
      }
    }
    return cld;
  }
  // 根据日期获取对应的农历
  getLunarByDate(y: number, m: number, d: number) {
    const yAndM = `${y}-${m}`;
    if (!this.lunarCollection[yAndM]) {
      const cld = this.generatorCld(y, m);
      this.lunarCollection[yAndM] = cld;
    }
    return this.lunarCollection[yAndM].days[d - 1];
  }
}
