import { action, observable } from "mobx";
import { QuikSearchPanelList } from "../types";
import { SearchAdvancedStoreType } from "./Store";
import getUtils from "./common";


export default class HistoryStore {
  parent: SearchAdvancedStoreType;

  constructor(parent: SearchAdvancedStoreType) {
    this.parent = parent;
  }
  @observable datas: QuikSearchPanelList[] = [];
  @observable loading: boolean = false;
  @observable module = 'common';
  @observable conditionId = '';
  @observable isMobile: boolean = false;

  @action
  setState = (params: Partial<HistoryStoreType>) => {
    Object.keys(params).forEach((key) => {
      const storeKey = key as keyof HistoryStoreType;
      // @ts-ignore
      this[storeKey] = params[storeKey];
    })
  }

  @action
  setIsMobile = (isMobile: boolean) => {
    this.isMobile = isMobile;
  }
  // 高级搜索模块接口标识
  @action
  setModule = (module: string) => {
    this.module = module;
  }

  // 高级搜索面板唯一值
  @action
  setConditionId = (conditionId: string) => {
    this.conditionId = conditionId;
  }

  // 获取历史搜索数据
  @action
  queryHistories = () => {
    getUtils().then((defaultModule) => defaultModule.queryHistories(this));
  }

  // 清空历史搜索
  @action
  clearHistory = () => {
    getUtils().then((defaultModule) => defaultModule.clearHistory(this));
  }

  // 删除历史搜索
  @action
  deleteHistory = (id: string) => {
    getUtils().then((defaultModule) => defaultModule.deleteHistory(this, id));
  }

  @action
  addToHistory = (history: QuikSearchPanelList) => {
    getUtils().then((defaultModule) => defaultModule.addToHistory(this, history));
  }
}
export type HistoryStoreType = Pick<HistoryStore, keyof HistoryStore>;
