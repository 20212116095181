import Loadable from '../../react-loadable'
import { ImageCropperType } from './types'
import { imageCropperClsPrefix } from '../../constants/index'

const ImageCropper = Loadable({
  name: 'ImageCropper',
  loader: () =>
    import(
      /* webpackChunkName: "image_cropper" */
      './ImageCropper'
    ),
}) as ImageCropperType

ImageCropper.defaultProps = {
  prefixCls: imageCropperClsPrefix,
  showSettingBtn: true,
  showDelete: true,
  accept: 'image/*',
  aspectRatio: 1,
  defaultShowCrop: true,
  hasLoading: true,
  type: 'avatar',
  uploadType: 'sync'
}

export type { ImageCropperProps, ImageCropperType } from './types'

export default ImageCropper
