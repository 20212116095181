
import Loadable, { LoadingType } from '../../react-loadable';
import { CardDetailFormType, CardDetailFormTitleType, CardDetailFormProps, TitleRenderProps, CardDetailFormSettingType, CardDetailFormSettingContentType } from './types';

import { cardDetailFormClsPrefix, skeletonClsPrefix, USESKELETON } from '../../constants/index';
import { getTemplate } from './template';
import { getShowGroupId } from './utils/index';
import { formSkeletonHeight } from '../form/index';
import Skeleton from '../skeleton/index';

const CardDetailFormkeleton: LoadingType<CardDetailFormProps> = (props) => {
  const layoutLen = props.store?.layout?.length || 0;
  const height = !props.store?.hasInit ? formSkeletonHeight : 40 * layoutLen;
  const skeletonProps = { singleHeight: 40 };
  return USESKELETON && <Skeleton weId={`${props.weId || ''}_ux0c2p`} skeletonHeight={height} span={1} {...skeletonProps} />;
};

const CardDetailForm = Loadable({
  name: 'CardDetailForm', loader: () => import(
    /* webpackChunkName: "card_detail_form" */
    './CardDetailForm'),
  loading: CardDetailFormkeleton,
}) as CardDetailFormType;

const CardDetailFormTitleSkeleton: LoadingType<TitleRenderProps> = (props) => {
  return USESKELETON && (<div style={{ marginBottom: 16 }}>
    <div className={`${skeletonClsPrefix}-inner`} style={{ width: 36, height: 36, borderRadius: '50%', display: 'inline-block' }}></div>
    <div className={`${skeletonClsPrefix}-inner`} style={{ width: 'calc(100% - 36px)', height: 30, display: 'inline-block' }}></div>
  </div>)
}

const Title =  Loadable({
  name: 'CardDetailFormTitle', loader: () => import(
    /* webpackChunkName: "card_detail_form" */
    './Title'),
    loading: CardDetailFormTitleSkeleton,
}) as CardDetailFormTitleType;

Title.defaultProps = {
  prefixCls: cardDetailFormClsPrefix,
  useSkeleton: USESKELETON,
}

const Setting = Loadable({
  name: 'CardDetailFormSetting', loader: () => import(
    /* webpackChunkName: "card_detail_form" */
    './setting/index'
  ),
}) as CardDetailFormSettingType;

Setting.defaultProps = {
  prefixCls: `${cardDetailFormClsPrefix}-setting`,
}

const Content = Loadable({
  name: 'CardDetailFormSettingContent', loader: () => import(
    /* webpackChunkName: "card_detail_form" */
    './setting/Content'
  )
}) as CardDetailFormSettingContentType;
Content.defaultProps = {
  prefixCls: `${cardDetailFormClsPrefix}-setting`,
}

CardDetailForm.SettingContent = Content;

CardDetailForm.Setting = Setting;

CardDetailForm.getTemplate = getTemplate;

CardDetailForm.Title = Title;

CardDetailForm.getShowGroupId = getShowGroupId;

CardDetailForm.defaultProps = {
  prefixCls: cardDetailFormClsPrefix,
  method: 'get',
  useSkeleton: USESKELETON,
  globalReadOnly: false
}

export type { CardDetailFormProps, CardDetailFormType, CardDetailFormItemProps, CardDetailFormSingleItemProps, CardDetailFormTagsType, CardDetailFormTitleType, TitleProps as CardDetailFormTitleProps, CardDetailFormCustomProps, CardDetailFormAllDatas,
  CardDetailFormSettingType, CardDetailFormSettingProps, CardDetailFormSettingContentRef, CardDetailFormSettingContentProps, CardDetailFormSettingContentType, TagSingleProps as CardDetailFormTagSingleProps, TagsProps as CardDetailFormTagsProps } from './types';

export default CardDetailForm;

export { default as CardDetailFormStore } from './store/Store';

export type { CardDetailFormStoreType } from './store/Store';

export { disableModules, checkDisabledModule, isModuleDisabled } from './template';