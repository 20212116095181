import getCurrentPosition from './getCurrentPosition';
import geolocate from './geolacate';
import reverseGeolocation from './reverseGeolocation';
import placeSearch from './placeSearch';
import coordinateConversion from './coordinateConversion';
import getAround from './getAround';
import getDefaultMapConfig from './getDefaultMapConfig';
import getStaticMap from './getStaticMap';
import getCity from './getCity';

// 对外抛出的公共方法
export const MapUtil = {
  getCurrentPosition,
  coordinateConversion,
  reverseGeolocation,
  geolocate,
  placeSearch,
  getStaticMap,
  getAround,
  getDefaultMapConfig,
  getCity
}
  
export default MapUtil;