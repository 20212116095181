import { Component } from 'react';
import Loadable from '../../../react-loadable';
import { RouteLayoutProps } from '../types';
import Portal from '../../portal/index';
import { setTitle, ua } from '@weapp/utils';
import { needSync } from '../../../utils/index';
const QueueAnim = Loadable({
  name: 'QueueAnim', loader: () => import(
    /* webpackChunkName: "queue-anim" */
    'rc-queue-anim')
});

function noop() { return undefined; }
export default class RouteLayout extends Component<RouteLayoutProps> {
  static displayName = 'RouteLayout';

  static defaultProps: RouteLayoutProps = {
    getContainer: noop,
  };

  componentDidMount(): void {
    if (needSync('title', this.props)) {
      if (ua.os === 'iOS') {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          setTitle({ title: this.props.title });
        }, 120);
      } else {
        setTitle({ title: this.props.title });
      }
    }
  }

  container?: HTMLDivElement;
  bodyContainer?: HTMLElement;

  componentWillUnmount() {
    this.props?.onAfterClose?.();
  }
  getContainer = () => {
    const { getContainer } = this.props;
    this.container = window.document.createElement('div');
    this.bodyContainer = getContainer?.() || window.document.body;
    this.bodyContainer.appendChild(this.container);
    return this.container;
  }

  render() {
    const { queueAnimProps = {}, closeQueueAnim } = this.props;
    // 动画库 77.41kb 后面去掉，效果内部处理
    const component = closeQueueAnim ? this.props.children : (
      <QueueAnim weId={`${this.props.weId || ''}_irdh4h`} {...queueAnimProps}>
        {this.props.children}
      </QueueAnim>
    );
    return (<Portal weId={`${this.props.weId || ''}_nrp9k2`}
      key="portalRouteLayout"
      getContainer={this.getContainer}
      didUpdate={this.props.onAfterShow}
    >{component}</Portal>)
  }
}
